import { Box, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import CustomTooltip from '../tooltip/Tooltip';

/**
 *
 * @param {String} margin - dense | normal |
 * @param {String} variant - outlined | contained
 * @param {String} classes - { textField, errorMessage }
 * @returns {TextField}
 */
const CustomTextField = props => {
    const { margin = 'normal', variant = 'outlined', className = '' } = props;
    const useStyle = makeStyles(theme => ({
        inputContainer: {
            width: '100%',
            // margin: '0 0 1rem',
        },
        textField: {
            width: '100%',
            margin: '0',
            // colors on focus
            '& .MuiInputLabel-root': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
            },
            '& .MuiInputBase-input': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
            },
            '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.heather,
                    borderWidth: 2,
                },
                '&.MuiInputLabel-root': {
                    color: theme.palette.primary.heather,
                    fontFamily: theme.typography.body2.fontFamily,
                },
                '& .MuiInputBase-input': {
                    fontFamily: theme.typography.body2.fontFamily,
                    color: theme.palette.primary.heather,
                },
            },
            '& .Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${theme.palette.primary.error} !important`,
                },
                '&.MuiInputLabel-root': {
                    color: `${theme.palette.primary.error} !important`,
                    fontFamily: theme.typography.body2.fontFamily,
                },
            },

            // helper text
            '& .MuiFormHelperText-contained': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
                margin: 0,
                fontSize: 14,
                lineHeight: '20px',
            },
        },

        errorMessage: {
            fontFamily: theme.typography.body2.fontFamily,
            color: theme.palette.primary.error,
            margin: 0,
            fontSize: 14,
            lineHeight: '20px',
        },
        tooltipContainer: {
            marginLeft: 10,
            marginRight: 3,
        },
    }));

    const classes = useStyle();

    return (
        <Box className={`${props?.classes?.container} ${classes.inputContainer}`}>
            <Box style={{ display: 'flex' }}>
                <TextField
                    {...props}
                    className={`${classes.textField} ${props.classes?.textField} `}
                    margin={margin}
                    variant={variant}
                    tooltip=''
                    errorMessage=''
                    classes=''
                    spellCheck={false}
                />
                {props.tooltip && (
                    <Box style={{ marginLeft: 10, marginRight: 3 }}>
                        <CustomTooltip message={props.tooltip} />
                    </Box>
                )}
            </Box>
            {props.errorMessage && (
                <Typography
                    component='p'
                    className={`${classes.errorMessage} ${props.classes?.errorMessage}`}
                >
                    {props.error ? props.errorMessage : ''}
                </Typography>
            )}
        </Box>
    );
};

export default CustomTextField;
