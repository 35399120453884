import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { onload } from './porting.util';
import { useApolloClient } from '@apollo/client';
import ReschedulePortModal from './ReschedulePortModal';
import SchedulePortModal from './SchedulePortModal';
import { MobileContext } from '../global-context';
import { makeStyles } from '@material-ui/core/styles';
import useStyle from './CustomModal/style';
import MyEirErrorHandle from './MyEirErrorHandle';
import CustomUpdatedPopUpModal from './CustomModal/CustomUpdatedPopUpModal';
import { useWindowDimension } from '../../hooks/windowDimensions';

const MyEirPortingContainer = ({
    phoneNumber,
    classes,
    serviceId,
    errorMessage,
    inactiveMobId,
}) => {
    const [open, setOpen] = useState(false);
    // const [status, setStatus] = useState('RESCHEDULE');
    // const token = sessionStorage.getItem('access_token');
    const [updateKeepOldNumLink, setUpdateKeepOldNumLink] = useState(false);
    const [portData, setPortData] = useState();
    const [width, height] = useWindowDimension();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [isApiFail, setisApiFail] = useState(false);
    const [findPort, setFindPort] = useState(false);
    const graphql = useApolloClient();
    const value = useContext(MobileContext);

    // back to eir account
    const backToEirAccount = val => {
        setOpen(false);
    };

    const handleClose = async val => {
        await setOpen(false);
        val && val();
    };

    const handleBack = () => {
        backToEirAccount(true);
        setOpenSuccess(!openSuccess);
    };

    const styles = useStyle();

    const portingOnload = async () => {
        try {
            console.log('porting onload');
            const { data } = await onload({ graphql, session: sessionStorage, serviceId });

            if (data?.state === 'SCHEDULED') {
                setUpdateKeepOldNumLink(false);
                setPortData(data);
            } else if (data?.state === 'IN_PROGRESS') {
                setUpdateKeepOldNumLink(false);
                setisApiFail(true);
                setFindPort(false);
            } else if (data?.state === 'COULDNT_FIND_PORT') {
                setUpdateKeepOldNumLink(true);
                setPortData(null);
                setFindPort(true);
            }
        } catch (error) {
            setisApiFail(true);
            console.error(error);
        }
    };

    useEffect(() => {
        portingOnload();
    }, [open]);

    return (
        <Box id='my-eir-porting'>
            {isApiFail && !findPort ? (
                ''
            ) : updateKeepOldNumLink ? (
                serviceId === inactiveMobId && errorMessage ? (
                    ''
                ) : (
                    <Typography
                        component='span'
                        onClick={() => setOpen(!open)}
                        className={`${classes.link}`}
                    >
                        Keep my old number
                    </Typography>
                )
            ) : (
                <Typography
                    component='span'
                    onClick={() => setOpen(!open)}
                    className={`${classes.link}`}
                >
                    Edit my scheduled move
                </Typography>
            )}

            {Boolean(portData) ? (
                <ReschedulePortModal
                    phoneNumber={phoneNumber}
                    open={open}
                    setOpen={setOpen}
                    portData={portData}
                    handleClose={handleClose}
                    backToEirAccount={backToEirAccount}
                    setPortData={setPortData}
                    serviceId={serviceId}
                    setOpenSuccess={setOpenSuccess}
                />
            ) : (
                <SchedulePortModal
                    phoneNumber={phoneNumber}
                    open={open}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    backToEirAccount={backToEirAccount}
                    setPortData={setPortData}
                    serviceId={serviceId}
                />
            )}
            {openSuccess && (
                <Box maxWidth='md'>
                    <CustomUpdatedPopUpModal
                        open={openSuccess}
                        cta1={{ text: 'Back to my eir account', action: handleBack }}
                        cta2={{ text: '', action: () => {}, disable: false }}
                        toggle={() => setOpenSuccess(!openSuccess)}
                    >
                        <Typography className={styles.textContainer1}>
                            Your move has been successfully cancelled
                        </Typography>
                    </CustomUpdatedPopUpModal>
                </Box>
            )}
        </Box>
    );
};

export default MyEirPortingContainer;
