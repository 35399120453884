import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core';
import { Modal, ModalBody } from 'reactstrap';
import { useDisplayPINPUKStyle } from '../css/useDisplayPINPUKStyle';
import axios from 'axios';
import { triggerAdobeApi } from '../redesign-5.2/redesign.api';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { useApolloClient } from '@apollo/client';

function MyEirDisplayUAN({ fiberUan, voipUan, serviceGroup }) {
    const [isopen, setIsopen] = useState(false);
    const graphql = useApolloClient();
    async function toggle() {
        setIsopen(!isopen);
        if (!isopen) {
            await triggerAdobeApi({ graphql, name: 'uan' });
        }
    }
    const classes = useDisplayPINPUKStyle();

    return (
        <React.Fragment>
            <div className='contracts-topic-change' onClick={toggle}>
                <Typography className='contracts-see-my-uan'>See your UAN</Typography>
            </div>
            <Modal
                className='pinPuk-main'
                // wrapClassName="pinPuk-wrap"
                wrapClassName={classes.PinPukWrapper}
                modalClassName='pinPuk-modal'
                backdropClassName='pinPuk-backdrop'
                contentClassName='pinPuk-content'
                isOpen={isopen}
                toggle={toggle}
                centered
            >
                <ModalBody className='pinPuk-body'>
                    <div className='pinPuk-body-header'>
                        <ArrowBackIcon className='pinPuk-header-back-icon' onClick={toggle} />
                        <div className='pinPuk-header-text'>Universal Account Number</div>
                    </div>
                    <div className='pinPuk-body-content'>
                        {fiberUan != null ? (
                            <div className='pinPuk-pin-info'>
                                <div className='pinPuk-body-content-header'>
                                    {serviceGroup === 'SA_PSTN'
                                        ? 'Your landline UAN'
                                        : serviceGroup === 'DUALPLAY_PSTN'
                                        ? 'Your broadband and landline UAN'
                                        : 'Your broadband UAN'}
                                </div>
                                <div className='pinPuk-body-content-info'>{fiberUan}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {voipUan != null ? (
                            <div className='pinPuk-puk-info'>
                                <div className='pinPuk-body-content-header'>Your landline UAN</div>
                                <div className='pinPuk-body-content-info'>{voipUan}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className='pinPuk-pin-description'>
                            <div className='pinPuk-body-content-header'>What is my UAN?</div>
                            <div className='pinPuk-body-content-description'>
                                UAN stands for Universal Account Number, which is an eight-digit
                                account number that is used to port telephone numbers and switch
                                broadband between service providers.
                                <br></br>
                                <br></br>
                                The UAN is an important piece of information which is used by
                                service providers to confirm a persons authority to request a port
                                or switch. This information is also required by service providers
                                when a customer wishes to change service provider.
                                <br></br>
                                <br></br>
                                Sometimes when switching broadband providers you might instead be
                                asked to provide your account number. This can be viewed by
                                selecting the menu icon next to "My account" on the my eir home
                                page.
                            </div>
                        </div>
                    </div>
                    <div className='pinPuk-body-footer'>
                        <button className='pinPuk-body-footer-cta' onClick={toggle}>
                            Close
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default MyEirDisplayUAN;
