import { makeStyles } from '@material-ui/core/styles';

export const usePaymentCardMethodStyles = makeStyles(theme => ({
    modalWrapper: {
        background: 'white',

        '& *': {
            margin: 0,
        },
        '& .payment-cta': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.5,
            margin: '0 auto',
            marginBottom: '12px !important',
        },
        '& .primary-btn': {
            borderRadius: 40,
            padding: '8px 60px',
            outline: 'none',
            border: 'none',
            textTransform: 'none',
            cursor: 'pointer',
            background: theme.palette.primary.sunset,
            color: theme.palette.primary.white,
            // '&.Mui-disabled': {
            //     background: '#ececec !important',
            //     color: '#888B8D !important',
            // },
            // '&:hover': {
            //     background: '#B10071',
            // },
        },
        '& .back-btn': {
            background: 'transparent',
            color: theme.palette.primary.sunset,
            border: 'none',
            width: 'fit-content',
        },
    },
    modalClassName: {
        // background: 'red'
        background: theme.palette.primary.gray550,
        backdropFilter: 'blur(4.5px)',
        '@media(min-width: 320px) and (max-width: 599px)': {
            // background: '#470A68',
        },
        '& .modal-dialog': {
            maxWidth: 760,
            //height: "100%",
            margin: '0 auto',
            marginTop: '28px !important',
            marginBottom: '28px !important',
            '@media(min-width: 320px) and (max-width: 599px)': {
                top: 0,
                margin: '8px',
                marginBottom: '0px',
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
                borderRadius: '10px 10px 0px 0px',
            },
            '@media(min-width: 600px) and (max-width: 1019px)': {
                top: 0,
                padding: 99,
            },
            '& .modal-content': {
                borderRadius: '10px',
                '@media(min-width: 320px) and (max-width: 599px)': {
                    // height: '100vh',
                    minHeight: '760px',
                },
                '@media(min-width: 600px) and (max-width: 1019px)': {
                    // height: '100vh',
                },
            },
        },
    },
    headerPaymentContainer: {
        maxWidth: '760px',
        marginTop: '28px',
        // marginBottom: '18px',
        // marginLeft: "auto",
        // marginRight: "auto",
        padding: '0px 32px',

        '@media(max-width: 599px)': {
            padding: '0px 15px',
            width: '100%',
            marginTop: '20px',
            marginBottom: '0px',
        },

        '& .payment-header-back-icon': {
            display: 'flex',
            marginLeft: '-4px',
            color: theme.palette.primary.sunset,
            cursor: 'pointer',

            '@media(max-width: 599px)': {
                height: '20px',
                width: '20px',
                marginLeft: '-4px',
            },
        },

        '& .payment-header-text': {
            display: 'flex',
            marginTop: '24px',
            minHeight: '47px',
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '26px',
            color: theme.palette.primary.heather,

            position: 'relative',

            '@media(max-width: 599px)': {
                paddingLeft: '8px',
                marginTop: '14px',
                minHeight: '45px',
                fontSize: theme.typography.h4.fontSize,
            },

            '& .payment-header-line': {
                position: 'absolute',
                left: '0%',
                right: '0%',
                bottom: '3px',
                border: `1px solid ${theme.palette.primary.borderColor}`,
                borderTopWidth: '0px',

                '@media(max-width: 599px)': {
                    bottom: '0px',
                },
            },
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },
    paymentMethod: {
        // background: 'white',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 0',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
        '& *': {
            margin: 0,
        },
        '& .back-btn': {
            background: 'transparent',
            color: theme.palette.primary.sunset,
        },
        '& .payment-btn': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: 40,
            padding: '8px 60px',
            outline: 'none',
            border: 'none',
        },
        '& .payment-method-value': {
            textAlign: 'center',
            '& h4': {
                fontSize: 16,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                lineHeight: 1.5,
                fontFamily: theme.typography.subtitle1.fontFamily,
                marginBottom: 18,
            },
            '& h3': {
                fontSize: 18,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                fontFamily: theme.typography.subtitle1.fontFamily,
                lineHeight: 1.5,
            },
        },

        '& .payment-method-cards-container': {
            // background: 'purple',
            maxWidth: 440,
            width: '100%',
            // margin: '30px 0',
            '& .payment-method-cards-header ': {
                // background: 'yellow',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 16,
                marginTop: '6px',
                '& h4': {
                    fontSize: 14,
                    fontFamily: theme.typography.body2.fontFamily,
                    color: theme.palette.primary.sunset,
                    fontWeight: 500,
                    lineHeight: '157%',
                    textDecoration: 'underline',
                },
                '& h3': {
                    fontSize: 18,
                    fontFamily: theme.typography.subtitle1.fontFamily,
                    color: theme.palette.primary.heather,
                    // fontWeight: 700,
                    lineHeight: '24px',
                },

                '@media(max-width: 599px)': {
                    marginTop: '23px',
                    marginBottom: 13,
                    '& h3': {
                        fontSize: 16,
                    },
                },
            },
            '& .payment-method-cards-list ': {
                // background: 'pink',
                marginBottom: 17,
                '& .radio-payment-card-block': {
                    flex: 1,
                    background: theme.palette.primary.gray250,
                    display: 'flex',
                    marginBottom: 16,
                    maxHeight: 90,
                    alignItems: 'center',
                    // padding: '21px 24px',
                    padding: '21px 15px 21px 24px',
                    border: `1px solid ${theme.palette.primary.gray250}`,
                    borderRadius: 8,
                    position: 'relative',

                    '& .MuiIconButton-root': {
                        color: theme.palette.primary.sunset,
                    },
                    '& .card-manual': {
                        fontSize: theme.typography.body3Medium.fontSize,
                        lineHeight: '16px',
                        fontWeight: 500,
                        fontFamily: theme.typography.body3Medium.fontFamily,
                        color: theme.palette.primary.heather,
                    },
                    '& .card-icon': {
                        // backgroundImage: 'url(../images/master-card.png)',
                        // backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'contain',
                        // height: '48px',
                        width: '53px',
                        marginRight: '38px',
                        background: 'white',
                        padding: '7px 10px',
                        marginLeft: '29px',
                        borderRadius: '4px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& img': {
                            height: 'auto',
                            width: '34px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            height: '36px',
                            width: '36px',
                            marginRight: 20,
                            '& img': {
                                height: 'auto',
                                width: '36px',
                            },
                        },
                    },
                    '& .card-info': {
                        // width: 'fit-content',
                        width: '141px',
                        textAlign: 'left',
                        // background: 'red',
                        '& .card-number': {
                            fontSize: 16,
                            fontFamily: theme.typography.body3Medium.fontFamily,
                            color: theme.palette.primary.heather,
                            fontWeight: 500,
                            lineHeight: 1,
                            // margin: 0,
                            [theme.breakpoints.down('xs')]: {
                                fontSize: 12,
                                lineHeight: 1,
                            },
                        },
                        '& .message': {
                            fontSize: 14,
                            fontFamily: theme.typography.body2.fontFamily,
                            color: theme.palette.primary.heather,
                            fontWeight: 400,
                            lineHeight: '20px',
                            // margin: 0,
                            [theme.breakpoints.down('xs')]: {
                                fontSize: 12,
                                lineHeight: 1,
                            },

                            '&.default': {
                                color: theme.palette.primary.heather,
                            },
                        },

                        '& .card-expired-message': {
                            color: theme.palette.primary.error,

                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: '20px',
                            fontFamily: theme.typography.body2.fontFamily,
                        },
                    },

                    '& .card-invalid-icon': {
                        margin: '0 0 auto 20%',

                        '@media(max-width: 599px)': {
                            margin: '0 0 auto 10%',
                        },
                    },

                    '&.selected': {
                        border: `2px solid ${theme.palette.primary.boxShadow1Dark}`,
                        background: theme.palette.primary.backgroundColor2,
                    },

                    '&.expired': {
                        '&::before': {
                            top: 12,
                            right: 20,
                            backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z' fill='%23E40046'/%3E%3C/svg%3E%0A")`,
                            height: 20,
                            width: 20,
                            content: "' '",
                            position: 'absolute',
                        },
                    },
                    [theme.breakpoints.down('xs')]: {
                        padding: '14px 16px',
                    },
                },
                '& .MuiFormControl-root': {
                    width: '100%',
                    '& .MuiSvgIcon-root': {
                        color: '#',
                    },
                },
            },
            '& .payment-method-cards-add ': {
                // background: 'yellowgreen',
                fontSize: 18,
                color: theme.palette.primary.sunset,
                // fontWeight: 700,
                lineHeight: '27px',
                textAlign: 'left',
                fontFamily: theme.typography.body1.fontFamily,
                marginBottom: '30px',
                cursor: 'pointer',
                [theme.breakpoints.down('xs')]: {
                    fontSize: 16,
                    lineHeight: 1.3,
                    marginTop: '4px',
                    marginBottom: '26px',
                },
            },
            [theme.breakpoints.down('xs')]: {
                flex: 1,
            },
        },

        //setup new direct debit styles

        '& .loader-text': {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: '700',
            fontFamily: theme.typography.body2.fontFamily,
            lineHeight: '24px',
            color: theme.palette.primary.heather,
            marginBottom: '38px',
        },

        '& .setup-container-body': {
            background: theme.palette.primary.white,
            minHeight: 'auto',
            // padding: '24px 32px',
            borderRadius: '10px',
            width: '439px',
            margin: '0',
            marginTop: '85px !important',
            padding: '0',

            // boxShadow: "0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}",

            '@media(max-width: 599px)': {
                // padding: '16px 16px 16px 16px',
                height: '67vh',
                borderRadius: '10px 10px 0px 0px',
                width: '296px',
                marginTop: '20px !important',
                display: 'flex',
                flexDirection: 'column',
            },

            '& .setup-body-contents': {
                marginBottom: '16px',
                margin: '0',
                width: '100%',

                '@media(max-width: 599px)': {
                    marginBottom: '20px',
                },
            },

            '& .setup-details': {
                border: '1px solid rgba(209, 17, 201, 0.1)',
                background: theme.palette.primary.backgroundColor2,
                // height: '84px',
                padding: '20px 16px',

                '& .bank-info': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '7px',

                    '& .info': {
                        fontSize: theme.typography.subtitle2.fontSize,
                        fontWeight: '500',
                        fontFamily: theme.typography.body2.fontFamily,
                        lineHeight: '20px',
                        color: theme.palette.primary.heather,
                    },

                    '& .info-val': {
                        // width:'90px',
                        width: '120px',
                        fontSize: '15px',
                        fontWeight: '500',
                        fontFamily: theme.typography.body2.fontFamily,
                        lineHeight: '20px',
                        color: theme.palette.primary.heather,
                    },
                },
            },

            '& .setup-text': {
                fontSize: theme.typography.subtitle2.fontSize,
                fontWeight: '400',
                fontFamily: theme.typography.body2.fontFamily,
                lineHeight: '22px',
                color: theme.palette.primary.heather,
                // marginTop:'16px',
                marginBottom: '65px',

                '@media(max-width: 599px)': {
                    width: '277px',
                    marginBottom: '5px',
                },
            },
        },

        '& .payment-body-footer': {
            // marginTop: "97px",

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            '@media(max-width: 599px)': {
                minHeight: '94px',

                flexGrow: '1',
                justifyContent: 'flex-end',

                paddingLeft: '24px',
                paddingRight: '24px',
                width: '100%',
            },

            '& .payment-body-footer-cta': {
                background: 'none',
                color: 'inherit',
                border: 'none',
                padding: '0px',
                font: 'inherit',
                outline: 'inherit',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                width: '183px',

                borderRadius: '4100px',

                fontFamily: theme.typography.subtitle1.fontFamily,
                fontStyle: 'normal',
                fontWeight: theme.typography.subtitle1.fontWeight,
                fontSize: theme.typography.subtitle1.fontSize,
                lineHeight: theme.typography.subtitle1.lineHeight,
                textAlign: 'center',
                // color: "#D011C9",

                paddingLeft: '10px',
                paddingRight: '10px',

                '@media(max-width: 599px)': {
                    width: '100%',
                },
            },

            '& .sepa-footer-cta': {
                background: 'none',
                color: 'inherit',
                border: 'none',
                padding: '0px',
                font: 'inherit',
                outline: 'inherit',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                width: '248px',

                borderRadius: '4100px',

                fontFamily: theme.typography.subtitle1.fontFamily,
                fontStyle: 'normal',
                fontWeight: theme.typography.subtitle1.fontWeight,
                fontSize: theme.typography.subtitle1.fontSize,
                lineHeight: theme.typography.subtitle1.lineHeight,
                textAlign: 'center',
                // color: "#D011C9",

                paddingLeft: '10px',
                paddingRight: '10px',
                marginTop: '42px',
                marginBottom: '14px',

                '@media(max-width: 599px)': {
                    width: '264px',
                },
            },

            '& .primary-cta': {
                minHeight: '42px',
                marginBottom: '3px',
                borderColor: 'transparent',
            },
            '& .secondary-cta': {
                minHeight: '41px',
                color: theme.palette.primary.sunset,
                width: 'fit-content',
                '&:hover': {
                    color: theme.palette.primary.sunset30,
                },
            },

            '& .cta-diabled': {
                // background: '#ECECEC',
                color: theme.palette.primary.gray550,

                '@media(max-width: 599px)': {
                    marginBottom: '4px',
                },
            },

            '& .cta-enabled': {
                background: theme.palette.primary.sunset,
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
                color: theme.palette.primary.white,

                '&:hover': {
                    // background: '#B10071',
                },

                '@media(max-width: 599px)': {
                    minHeight: '41px',
                    marginBottom: '53px',
                },
            },
            '& .cta-enabled-setup': {
                background: theme.palette.primary.sunset,
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
                color: theme.palette.primary.white,

                // '&:hover': {
                //     background: "#B10071",
                // },

                '@media(max-width: 599px)': {
                    minHeight: '41px',
                    // marginBottom: "53px",
                },
            },
        },

        '& .sepa-container': {
            width: '35.5rem',
            // maxWidth : '568px',

            '@media(min-width: 320px) and (max-width: 599px)': {
                width: '18.85rem',
            },
            '@media(min-width: 600px) and (max-width: 1019px)': {
                width: '28.5rem',
            },
            '& .sepa-contents': {
                height: '3.5rem',
                display: 'grid',
                // justifyContent: 'center',
                marginBottom: '25px',
                marginTop: '23px',

                '@media(min-width: 320px) and (max-width: 599px)': {
                    height: '4.65rem',
                    marginBottom: '22px',
                    marginTop: '6px',
                    // maxWidth:'301px',
                },
            },

            '& .sepa-content-text': {
                fontSize: theme.typography.body3Medium.fontSize,
                fontFamily: theme.typography.subtitle1.fontFamily,
                // fontWeight: 700,
                color: theme.palette.primary.heather,
                lineHeight: '157%',

                '@media(min-width: 320px) and (max-width: 599px)': {
                    fontSize: theme.typography.subtitle2.fontSize,
                },
            },

            '& .sepa-content-small-text': {
                fontSize: theme.typography.subtitle2.fontSize,
                fontFamily: theme.typography.body3Medium.fontFamily,
                // fontWeight: 500,
                color: theme.palette.primary.heather,
                lineHeight: '111%',
            },

            '& .sepa-pdf': {
                padding: '17.7px 0',
                borderTop: `1px solid ${theme.palette.primary.borderColor}`,
                borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                marginBottom: '25px',

                '@media(min-width: 320px) and (max-width: 599px)': {
                    padding: '22px 0',
                    marginBottom: '22px',
                },
            },

            // '& .pdf-text':{
            //     color:theme.palette.primary.sunset,
            //     fontWeight:'bold',
            // },
            '& .sepa-pdf-link': {
                color: theme.palette.primary.sunset,
                fontWeight: 'bold',
                cursor: 'pointer',
            },
            '& .sepa-email': {
                color: theme.palette.primary.sunset,
                textDecoration: 'underline',
            },
            '& .sepa-content-email-lines': {
                fontSize: theme.typography.subtitle2.fontSize,
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 500,
                color: theme.palette.primary.heather,
                lineHeight: '111%',
                marginBottom: '6px',

                '@media(min-width: 320px) and (max-width: 599px)': {
                    marginBottom: '15px',
                },
            },

            '& .sepa-content-lines': {
                fontSize: theme.typography.subtitle2.fontSize,
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 500,
                color: theme.palette.primary.heather,
                lineHeight: '111%',
                marginBottom: '10px',

                '@media(min-width: 320px) and (max-width: 599px)': {
                    marginBottom: '7px',
                },
            },
        },
    },

    loader: {
        height: '1px',
        zIndex: '1',
        position: 'absolute',
        top: '10vh',
        // left:'8vw',
        width: '439px',
        '@media(min-width: 320px) and (max-width: 599px)': {
            width: '300px',
        },
    },

    blurEffect: {
        filter: 'blur(8px)',
        background: 'rgba(255, 255, 255, 0.05)',
    },

    codes: {
        ' & .text-field-input-label-props-root': {
            color: theme.palette.primary.heather80,
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '19px',
            letterSpacing: '0.15px',
            // paddingTop:'6px',
        },
        '& .text-field-input-label-props-focused, & .text-field-input-label-props-shrink': {
            color: theme.palette.primary.heather,
            // fontSize: "12px",
            lineHeight: '16px',
        },
        '& .text-field-input-label-props-focused-invalid, & .text-field-input-label-props-shrink-invalid': {
            color: theme.palette.primary.error,
        },
        ' & .text-field-input-props-root': {
            width: '439px',

            '@media(max-width: 599px)': {
                width: '296px',
            },

            '& .text-field-input-props-input': {
                color: theme.palette.primary.heather,
                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '19px',
                letterSpacing: '0.15px',

                padding: '19px 12px 18px 12px',
            },
        },
        ' & .text-field-input-props-root-filled': {
            ' & .text-field-input-props-notchedOutline': {
                borderColor: theme.palette.primary.heather,
                borderWidth: '2px',
            },
            ' & .text-field-input-props-notchedOutline-invalid': {
                borderColor: theme.palette.primary.heather,
            },
        },
        ' & .text-field-input-props-focused, & .text-field-input-props-root:hover': {
            ' & .text-field-input-props-notchedOutline': {
                borderColor: theme.palette.primary.heather,
            },
            ' & .text-field-input-props-notchedOutline-invalid': {
                borderColor: theme.palette.primary.error,
            },
        },
    },
}));
