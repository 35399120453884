import ENDPOINT from '../graphql/GraphQLConstants';

export const submitSimReplacement = async ({ graphql,request, token}) => {

    try {
       const { data } = await graphql.mutate({
            mutation: ENDPOINT.SUBMIT_SIM_REPLACEMENT,
            variables:{
                request,
                token,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    } 
};