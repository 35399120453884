import React from 'react';
import { commonRegisterStyles } from '../../css/myEirRegistrationStyles/commonRegisterStyles';
import { Typography } from '@material-ui/core';
import { ArrowBackIcon } from '../../inputs/svg-icons/CustomSvgIcons';

const MyEirRegisterHeader = props => {
    const styleClass = commonRegisterStyles();

    return (
        <div className={styleClass.headerContainer}>
            <div className='register-header-back-icon-div'>
                <ArrowBackIcon
                    className={
                        'register-header-back-icon' + (props.registerScreen == 1 ? ' hide' : '')
                    }
                    onClick={props.handleBackButton}
                />
            </div>
            <Typography variant='h4' className='register-header-text'>
                {props.headerText}
            </Typography>
        </div>
    );
};

export default MyEirRegisterHeader;
