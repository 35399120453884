import { Box, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import AuthorisedUserInfo from './AuthorisedUserInfo';
import { authorisedUserStyle } from './authorisedUserStyle';
import MyEirEditAuthorisedUsers from './MyEirEditAuthorisedUsers';

const ViewAuthorisedUserDetails = ({ user, setStep, index, setSelectedUser }) => {
    const classes = authorisedUserStyle();
    const handleEdit = () => {
        setSelectedUser({ ...user, index });
        setStep('edit');
    };
    const handleDelete = () => {
        setSelectedUser({ ...user, index });
        setStep('delete');
    };
    return (
        <Box className={classes.userContainer}>
            <Box className={classes.userEditBox}>
                <Box component='p' className={classes.userBox}>
                    User&nbsp;{index + 1}
                </Box>

                <Box className={classes.linksWrap}>
                    <Button className={classes.userEdit} onClick={handleEdit}>
                        Edit
                    </Button>
                    <Typography className={classes.userEditDeleteBorder}>|</Typography>
                    <Button className={classes.userDelete} onClick={handleDelete}>
                        Delete
                    </Button>
                </Box>
            </Box>
            <AuthorisedUserInfo user={user} />
        </Box>
    );
};

export default ViewAuthorisedUserDetails;
