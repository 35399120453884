/**
 * Convert the input value to sentence case.
 * @param {*} input
 * @returns sentenceCase value
 */
const toSentenceCase = input => {
    let inputLowerCase = input.toLowerCase();
    return `${inputLowerCase.charAt(0).toUpperCase()}${inputLowerCase.slice(1)}`;
};

/**
 * Format county, where county value should be sentence case and Underscrore should be replaced with ". "
 * @param {county } county
 * @returns countyValue formatedCounty
 */
export function formatCounty(county) {
    let countyValue = county;
    if (county && countyValue.indexOf('. ') === -1) {
        countyValue.split('_').map((countyVal, index) => {
            index == 0
                ? (countyValue = toSentenceCase(countyVal) + '. ')
                : (countyValue = countyValue + toSentenceCase(countyVal));
        });
    }
    return countyValue;
}

/**
 * Format county, where county value should be upper case and ". " should be replaced with underscrore
 * @param {county } county
 * @returns countyValue formatedCounty
 */
export function ReFormatCounty(county) {
    let countyValue = county;
    if (county && countyValue.indexOf('_') === -1) {
        countyValue.split('. ').map((countyVal, index) => {
            index == 0
                ? (countyValue = countyVal.toUpperCase() + '_')
                : (countyValue = countyValue + countyVal.toUpperCase());
        });
    }
    return countyValue;
}
