import React, { useContext, useEffect, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Hidden,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useMyPaymentsStyle } from '../css/useMyPaymentsStyle';
import { useWindowDimension } from '../../hooks/windowDimensions';
import myPaymentsIcon from '../images/myPayments.png';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import Loading from '../../components/loading/Loading';
import MyEirPaymentDetails from './MyEirPaymentDetails';
import { MobileContext } from '../global-context';
import { getNameAndAccountID } from '../getNameAndAccountNumber';

const MyPaymentContainer = () => {
    const [collapse, setcollapse] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const [seeMore, setSeeMore] = useState(true);
    const [showMore, setShowMore] = useState();
    const [showLess, setShowLess] = useState();
    const [moreLess, setMoreLess] = useState();
    const [paymentsLoadingError, setPaymentsLoadingError] = useState(false);
    const [loading, setLoading] = useState(true);
    const classes = useMyPaymentsStyle();
    const [width, height] = useWindowDimension();
    const [paymentDateFrom, setPaymentDateFrom] = useState('');
    const [paymentDateTo, setPaymentDateTo] = useState('');
    const [paymentHistory, setPaymentHistory] = useState([]);

    // console.log("Payment History data", paymentHistory);

    const toggle = () => {
        setcollapse(!collapse);
    };

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const paymentStyle = createMuiTheme(theme => ({
        //Overriding default breakpoints of material UI with custom breakpoints.
        breakpoints: {
            values: {
                xs: 0,
                sm: 599,
                md: 600,
                lg: 1019,
                xl: 1020,
            },
        },
    }));

    const graphql = useApolloClient();

    async function getPaymentHistory() {
        try {
            const token = sessionStorage.getItem('access_token');
            let billingAccountId = sessionStorage.getItem('LoggedinUserAccountNumber');
            if (!billingAccountId) {
                await getNameAndAccountID(graphql);
                billingAccountId = sessionStorage.getItem('LoggedinUserAccountNumber');
            }
            if (token && billingAccountId) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_MY_BALANCE,
                    variables: {
                        token: token,
                        billingAccountId: billingAccountId,
                    },
                });
                if (response.data.getAccountSummary != undefined) {
                    setPaymentHistory(response.data.getAccountSummary);
                }
            } else {
                console.log('Unable to fetch payment history****');
            }
            setLoading(false);
        } catch (error) {
            setPaymentsLoadingError(true);
            console.error('Error in fetching payment history : ', error.message);
        }
    }

    useEffect(() => {
        getPaymentHistory();
    }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Hidden lgDown={theme.breakpoints.down('lg')}>
                    <div className={classes.MyPaymentsWrapper}>
                        <div className='myPaymentsContainer'>
                            <Accordion
                                className='payments-accordion'
                                expanded={width > 1019 ? expanded === 1 : true}
                                onChange={width > 1019 && handleChange(1)}
                                // defaultExpanded={true}
                            >
                                <AccordionSummary
                                    className='payments-header'
                                    onClick={toggle}
                                    classes={{
                                        content: 'payments__header-content',
                                        expanded: 'payments__header-expanded',
                                        expandIcon: 'payments__header-expandIcon',
                                    }}
                                    expandIcon={
                                        <ExpandMoreIcon className={'myPayments-expand-icon'} />
                                    }
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                >
                                    <div className='payments-header-block'>
                                        <img
                                            src={myPaymentsIcon}
                                            alt='my payments icon'
                                            className='payments-header-block-img'
                                        />
                                        My payments{' '}
                                    </div>
                                </AccordionSummary>
                                {loading ? (
                                    <AccordionDetails
                                        className='payment-loader'
                                        style={{ justifyContent: 'center', padding: '20px' }}
                                    >
                                        {paymentsLoadingError ? (
                                            <div className='payments-content-loading'>
                                                Something went wrong, please try again later.
                                                <span
                                                    className='payments-retry-loading'
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                    onClick={() => {
                                                        setPaymentsLoadingError(false);
                                                        getPaymentHistory();
                                                    }}
                                                >
                                                    Retry?
                                                </span>
                                            </div>
                                        ) : (
                                            <Loading className='mypayments-loading-animation' />
                                        )}
                                    </AccordionDetails>
                                ) : (
                                    <>
                                        <AccordionDetails className='payment-transaction-history-container'>
                                            <div className='payment-transaction-history-text'>
                                                See all your transactions history up to 12 months.
                                            </div>
                                        </AccordionDetails>
                                        {paymentHistory.length != 0 ? (
                                            <AccordionDetails className='payment-summary-container'>
                                                <div className='payment-summary'>
                                                    <AccordionDetails className='payment-summary-header'>
                                                        <div className='payment-summary-keys'>
                                                            <div className='payment-date'>Date</div>
                                                            <div className='payment-type'>Type</div>
                                                            <div className='payment-amount'>
                                                                Amount
                                                            </div>
                                                            <div className='payment-balance'>
                                                                Balance
                                                            </div>
                                                        </div>
                                                        <div className='Payment-summary__headerIcon'></div>
                                                    </AccordionDetails>
                                                    <div className='bar-container'>
                                                        <span className='bar' />
                                                    </div>

                                                    {paymentHistory.map((item, index) => (
                                                        <MyEirPaymentDetails
                                                            item={item}
                                                            index={index}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        ) : (
                                            <AccordionDetails className='payment-transaction-history-container'>
                                                <div className='payment-transaction-history-text'>
                                                    Account has no transaction history.
                                                </div>
                                            </AccordionDetails>
                                        )}
                                        {/* <AccordionDetails className='see-more-payment'>
                                            {seeMore ? (
                                                <div
                                                    className='more-payment-button'
                                                    onClick={() => {
                                                        setMoreLess(showLess);
                                                        setSeeMore(!seeMore);
                                                    }}
                                                >
                                                    See more
                                                    <ArrowForwardIcon
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className='less-payment-button'
                                                    onClick={() => {
                                                        setMoreLess(showMore);
                                                        setSeeMore(!seeMore);
                                                    }}
                                                >
                                                    See less
                                                    <ArrowUpwardIcon
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </AccordionDetails> */}
                                    </>
                                )}
                            </Accordion>
                        </div>
                    </div>
                </Hidden>
                <Hidden xlUp={theme.breakpoints.up('xl')}>
                    <div className={classes.MyPaymentsMobileWrapper}>
                        <div className='myPaymentsContainer'>
                            <Accordion defaultExpanded={true} className='payments-accordion'>
                                <AccordionSummary className='payments-header'>
                                    <div className='payments-header-block'>
                                        <img
                                            src={myPaymentsIcon}
                                            alt='my payments icon'
                                            className='payments-header-block-img'
                                        />
                                        My payments{' '}
                                    </div>
                                </AccordionSummary>
                                {loading ? (
                                    <AccordionDetails
                                        className='payment-loader'
                                        style={{ justifyContent: 'center', padding: '20px' }}
                                    >
                                        {paymentsLoadingError ? (
                                            <div className='payments-content-loading'>
                                                Something went wrong, please try again later.
                                                <span
                                                    className='payments-retry-loading'
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                    onClick={() => {
                                                        setPaymentsLoadingError(false);
                                                        getPaymentHistory();
                                                    }}
                                                >
                                                    Retry?
                                                </span>
                                            </div>
                                        ) : (
                                            <Loading className='mypayments-loading-animation' />
                                        )}
                                    </AccordionDetails>
                                ) : (
                                    <>
                                        <AccordionDetails className='payment-transaction-history-container'>
                                            <div className='payment-transaction-history-text'>
                                                See all your transactions <br></br> history up to 12
                                                months.
                                            </div>
                                        </AccordionDetails>
                                        {paymentHistory.length !== 0 ? (
                                            <AccordionDetails className='payment-summary-container'>
                                                <div className='payment-summary'>
                                                    <AccordionDetails className='payment-summary-header'>
                                                        <div className='payment-summary-keys'>
                                                            <div className='payment-date'>Date</div>
                                                            <div className='payment-type'>Type</div>
                                                            <div className='payment-amount'>
                                                                Amount
                                                            </div>
                                                        </div>
                                                        <div className='Payment-summary__headerIcon'></div>
                                                    </AccordionDetails>
                                                    {paymentHistory.map((item, index) => (
                                                        <MyEirPaymentDetails
                                                            item={item}
                                                            index={index}
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        ) : (
                                            <AccordionDetails className='payment-transaction-history-container'>
                                                <div className='payment-transaction-history-text'>
                                                    Account has no transaction history.
                                                </div>
                                            </AccordionDetails>
                                        )}
                                        {/* <AccordionDetails className='see-more-payment'>
                                            {seeMore ? (
                                                <div
                                                    className='more-payment-button'
                                                    onClick={() => {
                                                        setMoreLess(showLess);
                                                        setSeeMore(!seeMore);
                                                    }}
                                                >
                                                    See more
                                                    <ArrowForwardIcon
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className='less-payment-button'
                                                    onClick={() => {
                                                        setMoreLess(showMore);
                                                        setSeeMore(!seeMore);
                                                    }}
                                                >
                                                    See less
                                                    <ArrowUpwardIcon
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </AccordionDetails> */}
                                    </>
                                )}
                            </Accordion>
                        </div>
                    </div>
                </Hidden>
            </ThemeProvider>
        </>
    );
};

export default MyPaymentContainer;
