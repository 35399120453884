import React, { useContext, useEffect, useState } from 'react';
import {
    List,
    ListItem,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMyBillsStyle } from '../css/useMyBillsStyle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useWindowDimension } from '../../hooks/windowDimensions';
import myBillsIcon from '../images/myBills.png';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import Loading from '../../components/loading/Loading';
import MyEirBillDetail from './MyEirBillDetail';
import SeeCurrentUsage from './SeeCurrentUsage';
import { MobileContext } from '../global-context';
import { getNameAndAccountID } from '../getNameAndAccountNumber';
import { getServiceId } from '../getServiceID';
import { getBillingPeriod } from '../getBillingCycle';
import moment from 'moment';
import { getMobileServiceID } from './getMobileServiceID';

function MyEirBillContainer() {
    const DT_INDEX = 1;
    const [collapse, setcollapse] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [billData, setBillData] = useState([]);
    const [numberOfBills, setNumberOfBills] = useState();
    const [seeMore, setSeeMore] = useState(true);
    const [showMore, setShowMore] = useState();
    const [showLess, setShowLess] = useState();
    const [moreLess, setMoreLess] = useState();
    const [billsLoadingError, setBillsLoadingError] = useState(false);
    const [loading, setLoading] = useState(true);
    const classes = useMyBillsStyle();
    const [width, height] = useWindowDimension();

    const [currentUsageLoadingError, setCurrentUsageLoadingError] = useState(false);
    const [currentUsageLoading, setCurrentUsageLoading] = useState(true);
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameId, setServiceNameId] = useState([]);
    const [usageSummary, setUsageSummary] = useState([]);
    const [usageDetails, setUsageDetails] = useState([]);
    const [usageDetailsCharged, setUsageDetailsCharged] = useState([]);
    const [usageDetailsFree, setUsageDetailsFree] = useState([]);
    const [usageDetailsCall, setUsageDetailsCall] = useState([]);
    const [usageDetailsText, setUsageDetailsText] = useState([]);
    const [usageDetailsOLDText, setUsageDetailsOLDText] = useState([]);
    const [usageDetailsData, setUsageDetailsData] = useState([]);
    const [usageAmountAndCost, setUsageAmountAndCost] = useState([]);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);

    const toggle = () => {
        setcollapse(!collapse);
    };

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const graphql = useApolloClient();

    async function getBillingHistory(name) {
        try {
            if (
                sessionStorage.getItem('LoggedinUserAccountID') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountID') == ''
            ) {
                const accountResponse = await getNameAndAccountID(graphql);
            }
            const response = await graphql.query({
                query: ENDPOINT.GET_BILLING_HISTORY,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });
            let data = response.data;

            if (data.getBillingHistory != undefined) {
                setBillData(data.getBillingHistory);

                setShowMore(Math.min(data.getBillingHistory.length, 3));
                setMoreLess(Math.min(data.getBillingHistory.length, 3));

                setNumberOfBills(Math.min(data.getBillingHistory.length, 12));
                setShowLess(Math.min(data.getBillingHistory.length, 12));

                setLoading(false);
                setBillsLoadingError(false);
            }
        } catch (error) {
            setBillsLoadingError(true);

            console.error('error in fetching bills ', error.message);
            if (error.response != undefined && error.response.status != 200) {
                // setBillsLoadingError(true);
            }
            // console.log(error);
        }
    }

    async function getUsageSummary(index) {
        try {
            if (!sessionStorage.getItem('billingPeriod')) {
                await getBillingPeriod(graphql);
            }

            if (!sessionStorage.getItem('MobileServicesFiltered')) {
                await getMobileServiceID(graphql);
            }

            const idIndex = index != undefined ? index : 0;
            let tempSMSDetails = [];
            const token = sessionStorage.getItem('access_token');
            const billingPeriod = sessionStorage.getItem('billingPeriod');
            const serviceId =
                sessionStorage.getItem('MobileServicesFiltered') != null
                    ? JSON.parse(sessionStorage.getItem('MobileServicesFiltered'))[idIndex].id
                    : null;
            if (token && billingPeriod && serviceId) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_USAGE_SUMMARY,
                    variables: {
                        token: token,
                        billingPeriod: billingPeriod,
                        serviceType: 'POSTPAY',
                        serviceId: serviceId,
                    },
                });

                let data = response.data;
                if (data.getMyUsageSummary.usageSummary) {
                    data.getMyUsageSummary.usageSummary.map((item, index) => {
                        if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('SMS') > -1) {
                            const usgTypeData = getUsageDetailsByUsageType(
                                idIndex,
                                item.ratingSubtotalTypeKey
                            );
                            if (usgTypeData[0] == true) {
                                tempSMSDetails = tempSMSDetails.concat(usgTypeData[1]);
                            }
                        }
                    });
                    setUsageSummary(data.getMyUsageSummary.usageSummary);
                }
            } else {
                setCurrentUsageLoadingError(true);
            }
        } catch (error) {
            setCurrentUsageLoadingError(true);
            console.error('Error in fetching usage summary : ', error.message);
        }
    }

    async function getUsageDetails(index) {
        try {
            if (
                sessionStorage.getItem('billingPeriod') == undefined ||
                sessionStorage.getItem('billingPeriod') == ''
            ) {
                const billCycleResponse = await getBillingPeriod(graphql);
                // console.log("BILLINGCYCLE called inside getUsageDetails");
            }
            if (
                sessionStorage.getItem('MobileServicesFiltered') == undefined ||
                sessionStorage.getItem('MobileServicesFiltered') == ''
            ) {
                const serviceResponse = await getMobileServiceID(graphql);
            }

            const idIndex = index != undefined ? index : 0;
            let tempDetails = [];
            if (sessionStorage.getItem('MobileServicesFiltered') != null) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_USAGE_DETAILS,
                    variables: {
                        token: sessionStorage.getItem('access_token'),
                        usageDetailsInput: {
                            serviceId: JSON.parse(sessionStorage.getItem('MobileServicesFiltered'))[
                                idIndex
                            ].id,
                            serviceType: 'POSTPAY',
                            billingPeriod: sessionStorage.getItem('billingPeriod'),
                            // billingPeriod: moment().format('YYYYMM'),
                            usageType: '',
                            pageNumber: 0,
                            size: 1000,
                        },
                    },
                });
                let data = response.data;
                if (data.getMyUsageDetails.usage.content) {
                    if (data.getMyUsageDetails.usage.totalPages > 1) {
                        tempDetails = data.getMyUsageDetails.usage.content;
                        for (
                            let pgNum = 1;
                            pgNum < data.getMyUsageDetails.usage.totalPages;
                            pgNum++
                        ) {
                            const response2 = await graphql.query({
                                query: ENDPOINT.GET_USAGE_DETAILS,
                                variables: {
                                    token: sessionStorage.getItem('access_token'),
                                    usageDetailsInput: {
                                        serviceId: JSON.parse(
                                            sessionStorage.getItem('MobileServicesFiltered')
                                        )[idIndex].id,
                                        serviceType: 'POSTPAY',
                                        billingPeriod: sessionStorage.getItem('billingPeriod'),
                                        // billingPeriod: moment().format('YYYYMM'),
                                        usageType: '',
                                        pageNumber: pgNum,
                                        size: 1000,
                                    },
                                },
                            });
                            let data2 = response2.data;
                            if (data2.getMyUsageDetails.usage.content) {
                                tempDetails = tempDetails.concat(
                                    data2.getMyUsageDetails.usage.content
                                );
                            } else {
                                // console.log("TEMPDETAILS ERROR",tempDetails);
                            }
                        }
                        setUsageDetails(tempDetails);
                        setCurrentUsageLoading(false);
                        setCurrentUsageLoadingError(false);
                    } else {
                        setUsageDetails(data.getMyUsageDetails.usage.content);
                        setCurrentUsageLoading(false);
                        setCurrentUsageLoadingError(false);
                    }
                }
            }
        } catch (error) {
            setCurrentUsageLoadingError(true);

            console.error('error in fetching usage detail ', error.message);
            if (error.response != undefined && error.response.status != 200) {
                // setCurrentUsageLoadingError(true);
            }
            // console.log(error);
        }
    }

    async function getUsageDetailsByUsageType(index, usgType) {
        try {
            if (
                sessionStorage.getItem('billingPeriod') == undefined ||
                sessionStorage.getItem('billingPeriod') == ''
            ) {
                const billCycleResponse = await getBillingPeriod(graphql);
                // console.log("BILLINGCYCLE called inside getUsageDetailsByUsageType");
            }
            if (
                sessionStorage.getItem('MobileServicesFiltered') == undefined ||
                sessionStorage.getItem('MobileServicesFiltered') == ''
            ) {
                const serviceResponse = await getMobileServiceID(graphql);
            }

            const idIndex = index != undefined ? index : 0;
            let tempDetails = [];
            if (sessionStorage.getItem('MobileServicesFiltered') != null) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_USAGE_DETAILS,
                    variables: {
                        token: sessionStorage.getItem('access_token'),
                        usageDetailsInput: {
                            serviceId: JSON.parse(sessionStorage.getItem('MobileServicesFiltered'))[
                                idIndex
                            ].id,
                            serviceType: 'POSTPAY',
                            billingPeriod: sessionStorage.getItem('billingPeriod'),
                            // billingPeriod: moment().format('YYYYMM'),
                            usageType: usgType,
                            pageNumber: 0,
                            size: 1000,
                        },
                    },
                });
                let data = response.data;
                if (data.getMyUsageDetails.usage.content) {
                    if (data.getMyUsageDetails.usage.totalPages > 1) {
                        tempDetails = data.getMyUsageDetails.usage.content;
                        for (
                            let pgNum = 1;
                            pgNum < data.getMyUsageDetails.usage.totalPages;
                            pgNum++
                        ) {
                            const response2 = await graphql.query({
                                query: ENDPOINT.GET_USAGE_DETAILS,
                                variables: {
                                    token: sessionStorage.getItem('access_token'),
                                    usageDetailsInput: {
                                        serviceId: JSON.parse(
                                            sessionStorage.getItem('MobileServicesFiltered')
                                        )[idIndex].id,
                                        serviceType: 'POSTPAY',
                                        billingPeriod: sessionStorage.getItem('billingPeriod'),
                                        // billingPeriod: moment().format('YYYYMM'),
                                        usageType: usgType,
                                        pageNumber: pgNum,
                                        size: 1000,
                                    },
                                },
                            });
                            let data2 = response2.data;
                            if (data2.getMyUsageDetails.usage.content) {
                                tempDetails = tempDetails.concat(
                                    data2.getMyUsageDetails.usage.content
                                );
                            } else {
                                // console.log("TEMPDETAILS ERROR",tempDetails);
                            }
                        }
                        setUsageDetailsText(prev => [...prev, ...tempDetails]);
                        return [true, tempDetails];
                    } else {
                        setUsageDetailsText(prev => [
                            ...prev,
                            ...data.getMyUsageDetails.usage.content,
                        ]);
                        return [true, data.getMyUsageDetails.usage.content];
                    }
                }
            }
        } catch (error) {
            setCurrentUsageLoadingError(true);

            console.error('error in fetching bills ', error.message);
            if (error.response != undefined && error.response.status != 200) {
                // setCurrentUsageLoadingError(true);
            }
            // console.log(error);
            return [false, error];
        }
    }

    const filterDetails = () => {
        setUsageDetailsCharged([]);
        setUsageDetailsFree([]);
        setUsageDetailsCall([]);
        setUsageDetailsText([]);
        setUsageDetailsData([]);
        setChargedTotal(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        setFreeTotal(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        usageDetails.map((item, index) => {
            if (item.invoiceText === 'CALL') {
                setUsageDetailsCall(prev => [...prev, item]);
                if (item.amount === 0) {
                    setUsageDetailsFree(prev => [...prev, item]);
                    setFreeTotal(prevState => ({
                        quantity: prevState.quantity + item.duration,
                        amount: prevState.amount + item.amount,
                    }));
                } else {
                    setUsageDetailsCharged(prev => [...prev, item]);
                    setChargedTotal(prevState => ({
                        quantity: prevState.quantity + item.duration,
                        amount: prevState.amount + item.amount,
                    }));
                }
            } else if (item.invoiceText === 'TEXT') {
                setUsageDetailsOLDText(prev => [...prev, item]);
                if (item.amount === 0) {
                    setUsageDetailsFree(prev => [...prev, item]);
                    setFreeTotal(prevState => ({
                        quantity: prevState.quantity + item.duration,
                        amount: prevState.amount + item.amount,
                    }));
                } else {
                    setUsageDetailsCharged(prev => [...prev, item]);
                    setChargedTotal(prevState => ({
                        quantity: prevState.quantity + item.duration,
                        amount: prevState.amount + item.amount,
                    }));
                }
            } else if (item.invoiceText === 'DATA') {
                setUsageDetailsData(prev => [...prev, item]);
                if (item.amount === 0) {
                    setUsageDetailsFree(prev => [...prev, item]);
                    setFreeTotal(prevState => ({
                        quantity: prevState.quantity + item.duration,
                        amount: prevState.amount + item.amount,
                    }));
                } else {
                    setUsageDetailsCharged(prev => [...prev, item]);
                    setChargedTotal(prevState => ({
                        quantity: prevState.quantity + item.duration,
                        amount: prevState.amount + item.amount,
                    }));
                }
            }
        });
    };
    // const { state, dispatch } = useContext(MobileContext);

    async function servicesInfo() {
        try {
            if (
                sessionStorage.getItem('MobileServicesFiltered') == undefined ||
                sessionStorage.getItem('MobileServicesFiltered') == ''
            ) {
                const serviceResponse = await getMobileServiceID(graphql);
            }

            if (serviceName.length == 0) {
                sessionStorage.getItem('MobileServicesFiltered') != null &&
                    JSON.parse(sessionStorage.getItem('MobileServicesFiltered')).map(
                        (item, index) => {
                            setServiceName(prev => [...prev, item.msisdn]);
                            setServiceNameId(prev => [...prev, item.id]);
                        }
                    );
            }
        } catch (error) {
            setCurrentUsageLoadingError(true);
            console.error('error in seperation of service name and id ', error.message);
            // console.log(error);
        }
    }

    const [chargedTotal, setChargedTotal] = useState({ quantity: 0, amount: 0 });
    const [freeTotal, setFreeTotal] = useState({ quantity: 0, amount: 0 });
    const [callTotal, setCallTotal] = useState({ quantity: 0, amount: 0 });
    const [smsTotal, setSMSTotal] = useState({ quantity: 0, amount: 0 });
    const [dataTotal, setDataTotal] = useState({ quantity: 0, amount: 0 });
    const [dataNormal, setDataNormal] = useState({ quantity: 0, amount: 0 });
    const [dataRomaing, setDataRoaming] = useState({ quantity: 0, amount: 0 });

    function getUsageAmount() {
        setCallTotal(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        setSMSTotal(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        setDataTotal(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        setDataNormal(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        setDataRoaming(prevState => ({
            quantity: 0,
            amount: 0,
        }));
        usageSummary.map((item, index) => {
            if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('CALL') > -1) {
                setCallTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            } else if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('SMS') > -1) {
                setSMSTotal(prevState => ({
                    quantity: prevState.quantity + item.quantity,
                    amount: prevState.amount + item.amount,
                }));
            } else if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('DATA') > -1) {
                setDataTotal(prevState => ({
                    quantity: prevState.quantity + item.quantity,
                    amount: prevState.amount + item.amount,
                }));
                if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('ROAMING') > -1) {
                    setDataRoaming(prevState => ({
                        quantity: prevState.quantity + item.quantity,
                        amount: prevState.amount + item.amount,
                    }));
                } else {
                    setDataNormal(prevState => ({
                        quantity: prevState.quantity + item.quantity,
                        amount: prevState.amount + item.amount,
                    }));
                }
            }
        });
    }

    function retryCurrentUsage() {
        getUsageSummary(selectedServiceIndex);
        getUsageDetails(selectedServiceIndex);
    }

    useEffect(() => {
        getMobileServiceID(graphql);
        getBillingHistory();
    }, []);
    useEffect(() => {
        getUsageSummary(selectedServiceIndex);
        getUsageDetails(selectedServiceIndex);
    }, [selectedServiceIndex]);
    useEffect(() => {
        servicesInfo();
        filterDetails();
    }, [usageDetails]);
    useEffect(() => {
        getUsageAmount();
    }, [usageSummary]);

    return (
        <div className={classes.MyBillsWrapper}>
            <div className='myBillsContainer'>
                <Accordion
                    className='bills-accordion'
                    expanded={width > 1019 ? expanded === 1 : true}
                    onChange={width > 1019 && handleChange(1)}
                >
                    <AccordionSummary
                        className='bills-header'
                        onClick={width > 1019 && toggle}
                        classes={{
                            content: 'bills__header-content',
                            expanded: 'bills__header-expanded',
                            expandIcon: 'bills__header-expandIcon',
                        }}
                        expandIcon={<ExpandMoreIcon className={'myBills-expand-icon'} />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <div className='bills-header-block'>
                            <img
                                src={myBillsIcon}
                                alt='my bills icon'
                                className='bills-header-block-img'
                            />
                            My bills {numberOfBills != undefined && `(${numberOfBills})`}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails
                        className={
                            serviceName.length == 0
                                ? 'hide-current-usage-padding'
                                : 'see-current-usage'
                        }
                    >
                        {/* <div className="current-usage-button">See current usage</div> */}
                        {serviceName.length > 0 && (
                            <SeeCurrentUsage
                                currentUsageLoadingError={currentUsageLoadingError}
                                setCurrentUsageLoadingError={setCurrentUsageLoadingError}
                                currentUsageLoading={currentUsageLoading}
                                setCurrentUsageLoading={setCurrentUsageLoading}
                                retryCurrentUsage={retryCurrentUsage}
                                usageDetailsCharged={usageDetailsCharged}
                                usageDetailsFree={usageDetailsFree}
                                usageDetailsCall={usageDetailsCall}
                                usageDetailsText={usageDetailsText}
                                usageDetailsData={usageDetailsData}
                                dataNormal={dataNormal}
                                dataRomaing={dataRomaing}
                                serviceName={serviceName}
                                serviceNameId={serviceNameId}
                                selectedServiceIndex={selectedServiceIndex}
                                setSelectedServiceIndex={setSelectedServiceIndex}
                                amountAndUsage={[
                                    chargedTotal,
                                    freeTotal,
                                    callTotal,
                                    smsTotal,
                                    dataTotal,
                                ]}
                            />
                        )}
                    </AccordionDetails>
                    {loading ? (
                        <AccordionDetails
                            className='bill-loader'
                            style={{ justifyContent: 'center', padding: '20px' }}
                        >
                            {billsLoadingError ? (
                                <div className='bills-content-loading'>
                                    Something went wrong, please try again later.
                                    <span
                                        className='bills-retry-loading'
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => {
                                            setBillsLoadingError(false);
                                            getBillingHistory();
                                        }}
                                    >
                                        Retry?
                                    </span>
                                </div>
                            ) : (
                                <Loading className='mybills-loading-animation' />
                            )}
                        </AccordionDetails>
                    ) : (
                        <>
                            {numberOfBills != undefined &&
                                numberOfBills > 0 &&
                                billData
                                    .slice(0, moreLess)
                                    .map((item, index) => (
                                        <MyEirBillDetail
                                            key={index}
                                            index={index}
                                            item={item}
                                            width={width}
                                        />
                                    ))}
                            {numberOfBills != undefined && numberOfBills > 3 && (
                                <AccordionDetails className='see-more-bill'>
                                    {seeMore ? (
                                        <div
                                            className='more-bill-button'
                                            onClick={() => {
                                                setMoreLess(showLess);
                                                setSeeMore(!seeMore);
                                            }}
                                        >
                                            See more
                                            <ArrowForwardIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginLeft: '8px',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className='less-bill-button'
                                            onClick={() => {
                                                setMoreLess(showMore);
                                                setSeeMore(!seeMore);
                                            }}
                                        >
                                            See less
                                            <ArrowUpwardIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginLeft: '8px',
                                                }}
                                            />
                                        </div>
                                    )}
                                </AccordionDetails>
                            )}
                        </>
                    )}
                </Accordion>
            </div>
        </div>
    );
}

export default MyEirBillContainer;
