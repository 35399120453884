import { makeStyles } from '@material-ui/core/styles';

export const useRegisterFormStyle = makeStyles(theme => ({
    eirDialog: {
        padding: '8px 0px 40px',
        '@media (max-width: 767px)': {
            fontSize: '18px',
            padding: '0px 0px 18px',
        },
    },
    eirDialogTitle: {
        marginBottom: '0.5rem',
        fontFamily: 'eir-medium',
        // fontWeight: '500',
        lineHeight: '1.2',
        color: '#892ba0',
        fontSize: '24px',
    },
    dialogPaper: {
        overflowY: 'visible !important',
        maxWidth: '500px',
    },
    RegdialogPaper: {
        overflowY: 'visible !important',
        maxWidth: '700px',
    },

    dialogTitle: {
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        right: '-15px',
        top: '-15px',
        fontSize: '18px',
        color: '#892ba0',
        boxShadow: '0px 0px 10px #0000002B',
        opacity: 1,
        outline: 'none',
        background: '#fff',
        '&:focus,&:hover': {
            background: '#fff',
            outline: 'none',
        },
    },
    notification: {
        color: '#323435',
        fontSize: '20px',
        textAlign: 'center',
        padding: '18px 75px 10px',
        fontFamily: ' eir-medium !important',
        '@media (max-width: 767px)': {
            fontSize: '18px',
            padding: '0px',
        },
    },
    pupleBtn: {
        borderRadius: '2rem',
        fontSize: 18,
        textTransform: 'initial',
        padding: '8px 45px',
        border: '1px solid #f217bb',
        background: '#f217bb',
        color: '#fff',
        marginRight: '10px',
        marginBottom: '10px',
        '@media (min-width:280px) and (max-width: 320px)': {
            fontSize: 12,
            padding: '6px 22px',
        },
        '@media (min-width:321px) and (max-width: 767px)': {
            padding: '8px 30px',
            fontSize: 16,
        },
        '@media (min-width:768px) and (max-width: 1024px)': {
            fontSize: 16,
        },
        '&:focus,&:hover': {
            outline: 'none',
            background: '#990c76',
            color: '#fff',
        },
    },
    eirDialogContent: {
        padding: '0px 41px',
        '@media (max-width: 767px)': {
            padding: '8px 8px',
        },
    },
    button: { outline: 'none' },
    cancelBtn: {
        borderRadius: '2rem',
        fontSize: 18,
        textTransform: 'initial',
        padding: '8px 48px',
        border: '1px solid #892ba0',
        background: '#fff',
        color: '#892ba0',
        outline: 'none',
        marginRight: '10px',
        '@media (min-width:280px) and (max-width: 320px)': {
            fontSize: 12,
            padding: '6px 24px',
        },
        '@media (min-width:321px) and (max-width: 767px)': {
            padding: '8px 32px',
            fontSize: 16,
        },
        '@media (min-width:768px) and (max-width: 1024px)': {
            padding: '8px 48px',
            fontSize: 16,
        },
        '&:focus,&:hover': {
            outline: 'none',
        },
    },
    paper: {
        marginTop: ' 0px !important',

        boxShadow: '0px 3px 25px #00000024',
        borderRadius: '9px 9px 9px 9px',
        opacity: '1',
        [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            paddingBottom: theme.spacing(3),
        },
        // '@media  (max-width: 1025px)': {
        //     paddingBottom: '24px',
        // },
    },
    whiteCheckbox: {
        '&:not(:checked) .MuiIconButton-label:after': {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: -1,
        },
        color: 'grey',
        '& .MuiIconButton-label': {
            position: 'relative',
            zIndex: 0,
        },
        [theme.breakpoints.down('sm')]: {
            '&:not(:checked) .MuiIconButton-label:after': {
                content: '""',
                left: 4,
                top: 4,
                height: 14,
                width: 14,
                position: 'absolute',
                backgroundColor: 'white',
                zIndex: -1,
            },
        },
    },
    preferenceName: {
        fontSize: '13px',
        float: 'left',
        paddingLeft: '10px',
        fontFamily: 'gt-walsheim-medium',
        color: 'rgba(0, 0, 0, 0.87);',
        marginTop: '13px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
            // float: 'none',
        },
        '@media(max-width: 320px)': {
            textAlign: 'left',
        },
    },
    button: {
        outline: 'none',
    },
    input: {
        textTransform: 'uppercase',
    },
    margintop: {
        marginTop: theme.spacing(3),
    },
    marginleft: {
        marginLeft: theme.spacing(1),
    },
    table: {
        minWidth: 650,
    },

    prefernceRow: {
        backgroundColor: theme.palette.grey[200],
        minHeight: 42,
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'gt-walsheim-medium',
        fontSize: '1rem',
    },

    tableRow: {
        backgroundColor: theme.palette.grey[200],
        minHeight: 42,
    },
    tableHeader: {
        minHeight: 40,
        fontFamily: 'gt-walsheim-medium',
    },
    prefernceHeader: {
        minHeight: 37,
        fontFamily: 'gt-walsheim-medium',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            marginTop: theme.spacing(1.2),
        },
        '@media (min-width: 959.95px) and (max-width: 1024px)': {
            fontSize: '15px',
        },
        '@media(max - width: 320px) ': {
            padding: '4px 1px !important',
        },
    },

    errorMessage: {
        width: '100%',
        fontFamily: 'gt-walsheim-regular',
        textAlign: 'left',
        marginTop: '.25rem',
        fontSize: 16,
        color: '#e40046',
        display: 'block',
        '@media (max-width: 767px)': {
            textAlign: 'left',
            fontSize: 14,
        },
    },
    iconUsage: {
        color: '#892BA0',
    },

    RegisterForm: {
        background: '#f4f5f9 0% 0% no-repeat',
        position: 'relative',
        '& form': {
            width: '75%',
            margin: 'auto',
            '@media (max-width: 767px)': {
                width: '100%',
            },
        },
        '& .iconUsage': {
            color: '#892BA0',
        },
        '& .paddingTop': {
            paddingTop: '1rem',
            marginBottom: '3rem',
        },
        '& .mobileView': {
            '@media (max-width: 767px)': {
                display: 'block !important',
            },
        },
        '& .mobileShow': {
            display: 'none',
            '@media (max-width: 767px)': {
                display: 'block',
            },
        },

        '& .mobileHide': {
            display: 'block',
            '@media (max-width: 767px)': {
                display: 'none',
            },
        },
        '& .notification': {
            color: '#323435',
            fontSize: '16px !important',
            textAlign: 'justify',
            fontFamily: ' gt-walsheim-regular',
            '@media (max-width: 1024px)': {
                fontSize: '14px !important',
            },
        },
        '& .contactGroupNames': {
            marginLeft: theme.spacing(1),
            fontSize: '16px',
            color: '#25262A',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                marginTop: theme.spacing(1),
                marginLeft: theme.spacing(1),
            },
        },
        '& .noPadding': {
            padding: ' 8px 0px !important',
            '@media  (max-width: 1024px)': {
                padding: '8px !important',
            },
        },
        '& .profileMargin': {
            '@media  (max-width: 1024px)': {
                marginBottom: '10px',
            },
        },
        '& h1': {
            fontFamily: 'eir-medium',
            color: '#470A68',
            marginBottom: '20px',
            '@media  (max-width: 1024px)': {
                fontSize: '30px !important',
            },
        },
        '& button': {
            outline: 'none',
            '&:focus,&:hover': {
                outline: 'none',
            },
        },
        '& .rightAlign': {
            textAlign: 'right',
        },

        '& .formHolder': {
            width: '75%',
            margin: 'auto',
            padding: '3% 5%',
            '@media  (max-width: 767px)': {
                width: '100%',
                padding: '8% 4%',
            },
        },
        '& .regform': {
            width: '92%',
            margin: 'auto',
            padding: '3% 5%',
            '@media (min-width: 959.95px) and (max-width: 1024px)': {
                width: '100%',
                padding: '1% 1%',
            },
            '@media  (max-width: 959.95px)': {
                width: '100%',
                padding: '1% 5%',
            },
            '& .formLogoSection ,& .loginContainer': {
                display: 'none',
            },
            '& .regcontainer': {
                paddingTop: theme.spacing(4),
            },
            '& .profileTitle': {
                '@media  (max-width: 1024px)': {
                    fontSize: '35px !important',
                    marginTop: '8px !important',
                    marginBottom: '0px !important',
                },
            },
            '& iframe': {
                height: '1300px',
                '@media (min-width: 1231px)': {
                    width: '95%',
                },
                '@media (min-width: 320px) and (max-width: 514px)': {
                    height: '1400px',
                },
            },
        },
        '& .boxWidth': { width: '81%', '@media  (max-width: 767px)': { width: '100%' } },

        '& .formLabelColor': {
            fontFamily: 'gt-walsheim-regular !important',
            color: '#25262A!important',
            lineHeight: 1.5,
            marginBottom: 0,
            fontSize: '1rem !important',
            '@media  (max-width: 1024px)': {
                color: '#25262A!important',
                fontSize: '14px !important',
                textAlign: 'left',
                marginTop: '10px',
            },
        },
        '& .fontLarge': {
            width: '30px',
            height: '30px',
            color: '#470A68',
            marginLeft: '10px',
            backgroundImage:
                'url(/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-icon-right_purple.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '35px',
            '@media  (max-width: 767px)': {
                width: '24px',
                height: '24px',
                backgroundSize: '30px',
            },
        },
        '& .greybutton.Mui-disabled ,& .confirmBtn.Mui-disabled': {
            // color: '#323435',
            // border: '1px solid #888B8D',
        },
        '& .accountAddress': {
            '& .marginTop': {
                marginTop: '15px',
            },

            '& p': {
                textAlign: 'left',
                color: '#892BA0',
                fontFamily: 'eir-medium',
                fontSize: '25px',
                '@media  (max-width: 1024px)': {
                    fontSize: '20px',
                },
            },
        },

        '& .formAddress': {
            color: '#25262A !important',
            fontFamily: 'gt-walsheim-regular !important',
            fontSize: '16px !important',
            '@media  (max-width: 1024px)': {
                fontSize: '14px !important',
            },
        },

        '& p': {
            textAlign: 'center',
            color: '#470A68',
            fontFamily: 'eir-medium',
            fontSize: '35px',
            '@media  (max-width: 281px)': {
                fontSize: '18px !important',
            },
            '@media  (min-width: 281px) and (max-width: 767px)': {
                fontSize: '22px !important',
            },
            '@media (min-width: 767px) and (max-width: 1024px)': {
                fontSize: '30px !important',
            },
        },
        '& .eirCodeLink': {
            textDecoration: 'none',
        },
        ' & .button': {
            borderRadius: 20,
            textTransform: 'none',
            textAlign: 'left',
            letterSpacing: '0px',
            fontFamily: 'gt-walsheim-medium',
            fontSize: '16px',
            outline: 'none',
            padding: '5px 20px',
            [theme.breakpoints.down('sm')]: {
                padding: '5px 8px',
                fontSize: '12px',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '5px 8px',
                fontSize: '12px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '5px 20px',
                fontSize: '16px',
            },
        },
        '& .selectLabel': {
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                minHeight: '30px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '14px',
                minHeight: '35px',
            },
        },
        '& .hoverEffect': {
            color: '#fff',
            background: '#892BA0',
        },
        '& .greybutton': {
            marginLeft: theme.spacing(2),
            borderRadius: 20,
            textTransform: 'none',
            textAlign: 'left',
            letterSpacing: '0px',
            fontFamily: 'gt-walsheim-medium',
            fontSize: '1rem',
            outline: 'none',
            padding: '5px 15px',
            [theme.breakpoints.down('sm')]: {
                padding: '5px 8px',
                marginLeft: theme.spacing(1),
                fontSize: '12px',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '5px 8px',
                fontSize: '14px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '5px 15px',
                fontSize: '16px',
            },
            '@media(min-width: 280px) and (max-width: 321px)': {
                marginTop: '10px !important',
            },
        },
        '& .eirCodeLogo': {
            height: '19px',
            paddingLeft: '7px',
            width: 'auto',
            [theme.breakpoints.down('sm')]: {
                height: '14px',
            },
            [theme.breakpoints.up('sm')]: {
                height: '16px',
            },
            [theme.breakpoints.up('md')]: {
                height: '19px',
            },
        },
        '& .regcontainer': {
            width: '100%',
            '& .loginBtn': {
                marginTop: '40px',
                '@media  (max-width: 1025px)': {
                    marginTop: '25px',
                },
            },
        },
        '& .retryBtn': {
            padding: theme.spacing(1, 6),
            marginRight: '10px',
            '@media (max-width: 280px)': {
                padding: '5px 25px !important',
                fontSize: '14px !important',
            },
            '@media (min-width: 760px) and (max-width: 768px)': {
                padding: '8px 48px !important',
            },
        },
        '& .editBtn': {
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            color: '#470A68',
            fontSize: '16px ',
            padding: theme.spacing(1, 4),
            fontFamily: 'gt-walsheim-medium !important',
            borderRadius: '29px',
            float: 'left',
            textTransform: 'inherit',
            '@media  (max-width: 767px)': {
                float: 'none',
            },
            '@media  (max-width: 1025px)': {
                fontSize: '14px ',
                padding: theme.spacing(1, 4),
            },
        },
        '& .tryAgain': {
            '&:hover': {
                cursor: 'pointer',
            },
            '@media  (max-width: 767px)': {
                fontSize: '16px !important',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                fontSize: '18px !important',
            },
            fontFamily: 'gt-walsheim-medium !important',
            color: '#892ba0 !important',
            textDecoration: 'underline',
        },
        '& .errorText': {
            '@media  (max-width: 767px)': {
                fontSize: '16px !important',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                fontSize: '18px !important',
            },
            fontFamily: 'gt-walsheim-regular !important',
        },
        '& .errorNddText': {
            textAlign: 'left',
            color: '#892BA0',
            fontFamily: 'eir-medium',
            fontSize: '25px',
            '@media  (max-width: 1024px)': {
                fontSize: '20px',
            },
            paddingLeft: 20,
        },

        '& h3': {
            textAlign: 'center',
            color: '#470A68',
            marginBottom: '2rem',
            fontFamily: 'eir-medium',
            fontSize: '28px',
            '@media  (max-width: 281px)': {
                fontSize: '16px !important',
            },
            '@media  (min-width: 281px) and (max-width: 767px)': {
                fontSize: '20px !important',
            },
        },
        '& .loginBtn': {
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            marginTop: '30px',
            borderRadius: '29px',
            color: '#470A68',
            fontFamily: 'eir-medium !important',
            outline: 'none',
            fontSize: '20px !important',
            textTransform: 'initial',
            padding: theme.spacing(1, 7),
            marginBottom: '30px',
            '&:hover': {
                border: '2px solid #892BA0',
                background: '#892BA0 0% 0% no-repeat ',
                color: '#FFFFFF',
                '& .fontLarge': {
                    backgroundImage:
                        'url(/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-icon-right-white.png)',
                },
            },
            // '@media (max-width: 320px)': {
            //     fontSize: '14px !important',
            //     padding: theme.spacing(1, 4),
            // },
            '@media  (max-width: 767px)': {
                fontSize: '18px !important',
                padding: theme.spacing(1, 4),
                marginTop: '20px',
                marginBottom: '20px',
            },
        },
        '& .saveBtn': {
            fontSize: '18px !important',
            fontFamily: 'gt-walsheim-medium !important',
            '@media  (max-width: 1025px)': {
                fontSize: '14px !important',
            },
        },
        '& .confirmBtn': {
            padding: theme.spacing(1, 5),
            marginTop: '30px',
            borderRadius: '29px',
            fontFamily: 'eir-medium !important',
            outline: 'none',
            fontSize: '20px !important',
            textTransform: 'initial',

            marginBottom: '30px',
            '@media  (min-width:321px) and (max-width: 767px)': {
                fontSize: '18px !important',
                padding: theme.spacing(1, 3),
                marginTop: '20px',
                marginBottom: '20px',
            },
            '@media (min-width: 281px) and (max-width: 320px)': {
                fontSize: '18px !important',
                padding: '8px 20px',
            },
            '@media (max-width: 280px)': {
                fontSize: '14px !important',
                padding: '5px 20px',
            },
        },

        '& .formLogoSection': {
            height: '100px',
            boxShadow: '0px 3px 25px #00000024',
            borderRadius: '9px 9px 0px 0px',
            background:
                'url(/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/login-header-image.jpg) ',
            '& img': {
                width: '128px',
                marginTop: '8px',
                '@media  (max-width: 1025px)': {
                    width: '115px',
                    marginTop: '15px',
                },
            },
        },

        '& .Icon': {
            color: '#892BA0',
        },

        '& .errorMessage': {
            width: '100%',
            fontFamily: 'gt-walsheim-regular !important',
            textAlign: 'left',
            marginTop: '.25rem',
            fontSize: '16px !important',
            color: '#e40046 !important',
            display: 'block',
            '@media (max-width: 767px)': {
                textAlign: 'left',
                fontSize: '14px !important',
            },
        },
        '& .forRegform': {
            '@media (max-width: 1024px)': {
                paddingBottom: '20px',
            },

            '& h1': {
                textAlign: 'center',
                color: '#470A68',
                fontFamily: 'eir-medium',
                fontSize: '35px',
                '@media  (max-width: 281px)': {
                    fontSize: '25px !important',
                },
                '@media  (min-width: 281px) and (max-width: 767px)': {
                    fontSize: '30px !important',
                },
            },
            '& h2': {
                textAlign: 'center',
                color: '#470A68',
                marginBottom: '2rem',
                fontFamily: 'eir-medium',
                fontSize: '20px',
                '@media  (max-width: 281px)': {
                    fontSize: '16px !important',
                },
                '@media  (min-width: 281px) and (max-width: 767px)': {
                    fontSize: '18px !important',
                },
            },

            '& p': {
                textAlign: 'center',
                color: '#323435',
                fontFamily: 'gt-walsheim-regular !important',
                fontSize: '16px',

                '@media  (max-width: 767px)': {
                    fontSize: '14px !important',
                },
            },
            '& .arrow-right': {
                border: 'solid #470A68',
                borderWidth: '0 2px 2px 0',
                display: 'inline-block',
                padding: '5px',
                transform: 'rotate(135deg)',
                marginRight: '10px',
            },
            '& .link': {
                textDecoration: 'none',
                textAlign: 'center',
                color: '#470A68',
                fontFamily: 'eir-medium',
                fontSize: '20px',
                paddingBottom: '20px',

                '@media  (max-width: 767px)': {
                    fontSize: '18px !important',
                },
            },
        },
    },
}));
