export default {
    validation: {
        amountRequired: 'Please enter a valid amount',
        amountPattern: 'Please enter a valid amount',
        maxLength: "Please enter another amount, your account cannot exceed €100 credit",
        minLength: 'Please enter a valid amount',
        ceasedMsg: 'Please enter the value owed on your final bill',
        getHppErrRes:"you can't have a balance over €100.0",
    },
};
