import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomModal from '../../../eir-porting/CustomModal/CustomModal';
import { useApolloClient } from '@apollo/client';
import { deleteDevice } from '../deviceManagement.util';
import { styles } from '../styles';
import DeviceDetailsDate from './DeviceDetailsDate';
import moment from 'moment';

const DeviceDeleteModal = ({
    open,
    toggle,
    enableDelete,
    deviceId,
    deviceName,
    deviceActivationDate,
    deviceLastUsedDate,
    setDeviceDeleted,
    setisErrorText,
    setHeaderText,
}) => {
    const [cta1, setCTA1] = useState({
        text: enableDelete === false ? 'Close' : 'Delete device',
        action:
            enableDelete === false
                ? () => {
                      toggle();
                  }
                : () => {
                      handleDeleteDevice();
                  },
        disable: false,
    });
    const [cta2, setCTA2] = useState({
        text: enableDelete === false ? '' : 'Cancel',
        action:
            enableDelete === false
                ? () => {}
                : () => {
                      toggle();
                  },
        disable: false,
    });

    const classes = styles();
    const startOfNextMonth = moment().add(1, 'M').startOf('month').format('DD/MM/YYYY');
    const graphql = useApolloClient();

    const handleDeleteDevice = async () => {
        try {
            const data = await deleteDevice(graphql, deviceId);
            if (data === 200) {
                setDeviceDeleted(deviceName);
                setisErrorText(false);
            } else {
                setisErrorText(true);
                setHeaderText('An issue occured while deleting your device, please try again.');
            }
            toggle();
        } catch (error) {
            setisErrorText(true);
            setHeaderText('An issue occured while deleting your device, please try again.');
            console.error(`deleteDevice error ${error}`);
            toggle();
        }
    };

    return (
        <CustomModal
            open={open}
            toggle={toggle}
            cta1={cta1}
            cta2={cta2}
            header={{
                text: 'Delete device',
                center: false,
            }}
        >
            <Box className={classes.deviceManagementContent}>
                <Box className={classes.deviceManagementContentHeader}>
                    {enableDelete === false ? (
                        <>
                            <Typography
                                variant='h5'
                                className={classes.deviceManagementContentHeaderTextDelete}
                            >
                                You cannot remove this device.
                            </Typography>
                            <Typography className={classes.deviceManagementContentHeaderSubText}>
                                You will be able to remove this device from {startOfNextMonth}.
                            </Typography>
                        </>
                    ) : (
                        <Typography
                            variant='h5'
                            className={classes.deviceManagementContentHeaderTextDelete}
                        >
                            Are you sure you want to delete this device?
                        </Typography>
                    )}
                </Box>
                <Box className={classes.deviceManagementDeviceDetailsDelete}>
                    <Box className={classes.deviceManagementDevice}>
                        <Box
                            className={`${classes.deviceManagementDeviceNumberBox}  ${classes.noMinWidth}`}
                        >
                            <Typography
                                className={classes.deviceManagementDeviceNumber}
                            ></Typography>
                        </Box>
                        <Box className={classes.deviceManagementActiveDevice}>
                            <Box className={classes.deviceManagementActiveDeviceNameBox}>
                                <Typography className={classes.deviceManagementActiveDeviceName}>
                                    {deviceName}
                                </Typography>
                            </Box>
                            <DeviceDetailsDate
                                deviceActivationDate={deviceActivationDate}
                                deviceLastUsedDate={deviceLastUsedDate}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.deleteModalBottomPadding}></Box>
            </Box>
        </CustomModal>
    );
};

export default DeviceDeleteModal;
