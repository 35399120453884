import { Box, Typography, TextField, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeviceDetailsDate from './DeviceDetailsDate';
import { styles } from '../styles';

const DeviceDetailsActive = ({
    deviceId,
    deviceName,
    deviceActivationDate,
    deviceLastUsedDate,
    setDeleteDeviceData,
    editDevice,
    renameDevice,
    toggleDelete,
}) => {
    const [editingDeviceName, setEditingDeviceName] = useState(false);
    const [currentDeviceName, setCurrentDeviceName] = useState();
    const [enableEdit, setEnableEdit] = useState(true);
    const [disableRename, setDisableRename] = useState(false);
    const [disableCancel, setDisableCancel] = useState(false);

    const classes = styles();

    const handleDeviceNameChange = event => {
        if (event.target.value.length < 21) {
            setCurrentDeviceName(event.target.value);
        }
        if (event.target.value === deviceName) {
            setDisableRename(true);
        } else {
            setDisableRename(false);
        }
    };
    const handleEdit = () => {
        setEnableEdit(false);
        if (currentDeviceName === deviceName) {
            setDisableRename(true);
        } else {
            setDisableRename(false);
        }
        setEditingDeviceName(!editingDeviceName);
    };
    const handleDelete = () => {
        setDeleteDeviceData({
            deviceId,
            deviceName,
            deviceActivationDate,
        });
        toggleDelete();
    };
    const handleRename = async () => {
        setDisableRename(true);
        setDisableCancel(true);
        if (currentDeviceName !== deviceName) {
            await renameDevice(deviceId, currentDeviceName);
        }
        setEditingDeviceName(false);
        setEnableEdit(true);
        setDisableRename(false);
        setDisableCancel(false);
    };
    const handleCancel = () => {
        setEditingDeviceName(false);
        setEnableEdit(true);
        setCurrentDeviceName(deviceName);
    };

    useEffect(() => {
        setCurrentDeviceName(deviceName);
    }, [deviceName]);

    return (
        <>
            <Box className={classes.deviceManagementActiveDevice}>
                <Box className={classes.deviceManagementActiveDeviceNameBox}>
                    {editingDeviceName ? (
                        <Box>
                            <TextField
                                id='standard-basic'
                                className={classes.deviceManagementEditDeviceTextField}
                                value={currentDeviceName}
                                onChange={handleDeviceNameChange}
                                autoFocus
                                spellCheck={false}
                                disabled={disableRename && disableCancel}
                            />
                        </Box>
                    ) : (
                        <Typography
                            variant='subtitle2'
                            className={classes.deviceManagementActiveDeviceName}
                        >
                            {deviceName}
                        </Typography>
                    )}
                </Box>
                {editingDeviceName && (
                    <Box className={classes.deviceManagementEditRenameDevice}>
                        <Button
                            className={classes.deviceManagementRenameDevice}
                            onClick={handleRename}
                            disabled={disableRename}
                        >
                            Rename
                        </Button>
                        <Button
                            className={classes.deviceManagementCancelDevice}
                            onClick={handleCancel}
                            disabled={disableCancel}
                        >
                            Cancel
                        </Button>
                    </Box>
                )}
                <DeviceDetailsDate
                    deviceActivationDate={deviceActivationDate}
                    deviceLastUsedDate={deviceLastUsedDate}
                />
            </Box>
            <Box className={classes.deviceManagementEditDeleteBox}>
                <Box className={classes.deviceManagementEditDeleteDevice}>
                    <Button
                        className={classes.deviceManagementEditDevice}
                        onClick={handleEdit}
                        disabled={enableEdit === false}
                    >
                        Edit
                    </Button>
                    <Typography className={classes.deviceManagementEditDeleteBorder}></Typography>
                    <Button className={classes.deviceManagementDeleteDevice} onClick={handleDelete}>
                        Delete
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default DeviceDetailsActive;
