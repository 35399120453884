// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const CustomModalHeader = ({ headerText, arrowAction, center, styles }) => {
    return (
        <div className={styles.headerModal}>
            {!center && arrowAction !== undefined && (
                <ArrowBackIcon className={'modal-header-back-icon'} onClick={arrowAction} />
            )}
            <div className='modal-header-text'>
                <p style={center ? { textAlign: 'center', width: '100%' } : {}}>{headerText}</p>
                <div className='modal-header-line'></div>
            </div>
        </div>
    );
};

export default CustomModalHeader;
