import React, { useContext, useEffect, useState } from 'react';
import ENDPOINT from '../graphql/GraphQLConstants';
import { DOMAINS } from '../../components/myeirconstant/myEirConstant';

const { VOIP, FIBER } = DOMAINS;

export async function getContractsDetailsValues(graphql) {
    // msisdn values
    const msisdnValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));
    // const msisdnName = msisdnValue.map(value => value.name);

    const subscriptionDetails = sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID');

    // offer name values
    const offerData = await getOffer(graphql, msisdnValue);
    if (offerData[0] == true) {
        return {
            msisdn: msisdnValue,
            subscriptionDetails: JSON.parse(subscriptionDetails),
            offerVal: offerData[1],
            isError: false,
        };
    } else {
        return {
            msisdn: msisdnValue,
            subscriptionDetails: JSON.parse(subscriptionDetails),
            offerVal: offerData[1],
            isError: true,
        };
    }
}

export async function getUANDetals(graphql) {
    let arrTemp = [];
    const subscriptions = JSON.parse(
        sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
    );
    try {
        for (let itr = 0; itr < subscriptions.length; itr++) {
            const res = await graphql.query({
                query: ENDPOINT.SERVICE_ID,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    subscriptionId: subscriptions[itr].id,
                },
            });
            const data = res.data;
            if (subscriptions[itr].serviceGroup === 'DUALPLAY') {
                if (data.getMyServices) {
                    const voipUan = data.getMyServices.filter(item => item.domain == VOIP);
                    const fiberUan = data.getMyServices.filter(item => item.domain == FIBER);
                    arrTemp = arrTemp.concat({
                        voipUan: voipUan[0].voipUan ? voipUan[0].voipUan : '',
                        fiberUan: fiberUan[0].fiberUan ? fiberUan[0].fiberUan : '',
                        subscriptionId: subscriptions[itr].id,
                        serviceGroup: subscriptions[itr].serviceGroup,
                    });
                } else {
                    throw 'getUANDetals was not returned from API';
                }
            }
            if (subscriptions[itr].serviceGroup === 'DUALPLAY_PSTN') {
                if (data.getMyServices) {
                    const fiberUan = data.getMyServices.filter(item => item.domain == FIBER);

                    arrTemp = arrTemp.concat({
                        fiberUan: fiberUan[0].fiberUan ? fiberUan[0].fiberUan : '',
                        subscriptionId: subscriptions[itr].id,
                        serviceGroup: subscriptions[itr].serviceGroup,
                    });
                } else {
                    throw 'getUANDetals was not returned from API';
                }
            }
            if (subscriptions[itr].serviceGroup === 'SA_PSTN') {
                if (data.getMyServices) {
                    const fiberUan = data.getMyServices.filter(item => item.domain == 'PSTN');

                    arrTemp = arrTemp.concat({
                        fiberUan: fiberUan[0].pstnUan ? fiberUan[0].pstnUan : '',
                        subscriptionId: subscriptions[itr].id,
                        serviceGroup: subscriptions[itr].serviceGroup,
                    });
                } else {
                    throw 'getUANDetals was not returned from API';
                }
            }
        }
    } catch (error) {
        return arrTemp;
    }
    return arrTemp;
}

async function getOffer(graphql, msisdnValue) {
    let arrTemp = [];
    const subscriptionList = JSON.parse(
        sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
    );
    try {
        for (let itr = 0; itr < subscriptionList.length; itr++) {
            const res = await graphql.query({
                query: ENDPOINT.GET_OFFER_DETAILS,
                variables: {
                    catalogOfferCode: subscriptionList[itr].catalogOfferCode,
                },
            });
            const data = res.data;
            if (data.getOfferDetails) {
                for (let i = 0; i < msisdnValue.length; i++) {
                    if (subscriptionList[itr].id === msisdnValue[i].subscriptionId) {
                        arrTemp = arrTemp.concat({
                            ...data.getOfferDetails,
                            subscriptionId: msisdnValue[i].subscriptionId,
                        });
                    }
                }
            } else {
                throw 'getOfferDetails was not returned from API';
            }
        }
    } catch (error) {
        return [false, arrTemp];
    }

    return [true, arrTemp];
}
