import React from 'react';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { usePaymentCardMethodStyles } from '../css/usePaymentCardMethodStyles';

const PaymentCardMethodHeader = props => {
    const styleClass = usePaymentCardMethodStyles();
    const handleToback = () => {
        console.log('current step ', props.currentStep);
        props.handleBack();
    };

    const handleSepaAndDDBack = () => {
        props.setHeader('Payment Details');
        props.handleNext(1);
    };
    return (
        <div className={styleClass.headerPaymentContainer}>
            {!props.center && (
                <ArrowBackIcon
                    className={'payment-header-back-icon'}
                    onClick={
                        props.currentStep === 3 || props.currentStep === 4
                            ? handleSepaAndDDBack
                            : handleToback
                    }
                />
            )}
            <div className='payment-header-text'>
                <p style={props.center ? { textAlign: 'center', width: '100%' } : {}}>
                    {props.paymentHeaderText}
                </p>
                <div className='payment-header-line'></div>
            </div>
        </div>
    );
};

export default PaymentCardMethodHeader;
