import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomModal from './CustomModal/CustomModal';
import CustomUpdatedPopUpModal from './CustomModal/CustomUpdatedPopUpModal';
import PortingCheck from './PortingCheck';
import Porting from './porting-step/Porting';
import PortingConfirmation from './PortingConfirmation';
import useStyle from './CustomModal/style';
import { onload } from './porting.util';
import { useApolloClient } from '@apollo/client';
import MyEirErrorHandle from './MyEirErrorHandle';
import { subscribe } from 'pubsub-js';

const SchedulePortModal = ({ phoneNumber, open, handleClose, serviceId }) => {
    // const [open, setOpenModal] = useState(false);
    const [step, setStep] = useState(0);
    const [portData, setPortData] = useState();
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [apiError, setApierror] = useState('');
    const [element, setElement] = useState('');
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });

    // handle back arrow action
    const backArrowAction = () => {
        if (step === 0) {
            handleClose(false);
        } else {
            setStep(step - 1);
        }
    };

    const resetApiError = () => {
        setApierror('');
    };

    subscribe('RESET_API_ERROR', resetApiError);

    const handleCTA = ({ primary, cta }) => {
        if (primary) {
            setCTA1({ ...cta1, ...cta });
        } else {
            setCTA2({ ...cta2, ...cta });
        }
    };
    const handleScheduleToggle = () => {
        handleClose(() => setStep(0));
    };

    const onloadConfirmation = async () => {
        // TODO - redirect to my eir account comp
        handleCTA({
            primary: true,
            cta: {
                // text: 'Back to my eir account',
                text: 'Close',
                action: () => {
                    // backToEirAccount();
                    handleClose(() => setStep(0));
                },
            },
        });
        handleCTA({
            cta: { text: '' },
        });
    };

    // all steps
    const PortingSteps = [
        <Box>
            <PortingCheck
                handleCTA={handleCTA}
                setStep={setStep}
                phoneNumber={phoneNumber}
                setOpenModal={handleClose}
            />
        </Box>,
        <Porting
            portData={portData}
            handleCTA={handleCTA}
            setApierror={setApierror}
            setStep={setStep}
            setPortData={setPortData}
            reschedule={Boolean(portData)}
            // setOpenPopup={setOpenPopup}
            serviceId={serviceId}
            openError={openErrorPopup}
            setOpenError={setOpenErrorPopup}
            handleClose={handleClose}
        />,
        <PortingConfirmation
            onload={onloadConfirmation}
            phoneNumber={phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
            changedPhoneNumber={portData?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
            scheduledDate={portData?.portStartDateTime?.split('T')[0]}
            scheduledTime={portData?.portStartDateTime?.split('T')[1].substring(0, 5)}
            description={'Your move has been scheduled'}
        />,
    ];

    const styles = useStyle();

    useEffect(() => {
        setElement(PortingSteps[step]);
    }, [step]);

    return (
        <Box>
            <CustomModal
                id='schedule-port'
                cssId='myeir-modal-window-my-porting'
                open={open}
                cta1={cta1}
                cta2={cta2}
                apiError={apiError}
                toggle={handleScheduleToggle}
                header={{ text: 'Keep my old number', arrowAction: backArrowAction, center: false }}
            >
                <Box style={{ paddingBottom: '2rem' }}>{element}</Box>
            </CustomModal>
            {openErrorPopup ? (
                <MyEirErrorHandle
                    open={openErrorPopup}
                    setOpen={() => {
                        setOpenErrorPopup(false);
                        handleClose(() => setStep(0));
                    }}
                />
            ) : (
                ''
            )}{' '}
        </Box>
    );
};

export default SchedulePortModal;
