import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import calendarImg from '../images/calendarImg.png';
import TimeImg from '../images/time.png';
import moment from 'moment';
import theme from '../../theme/MyEirTheme';

const PortingConfirmation = ({
    onload,
    phoneNumber,
    changedPhoneNumber,
    scheduledDate,
    scheduledTime,
    description,
}) => {
    useEffect(() => {
        onload();
    }, []);
    const useStyles = makeStyles(() => ({
        modalBodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '1rem',
        },

        modalClass: {
            fontFamily: theme.typography.h5.fontFamily,
            // fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '26px',
            textAlign: 'center',
            paddingTop: '4rem',
            color: theme.palette.primary.heather,
            width: '100%',

            [theme.breakpoints.between('xs', 'sm')]: {
                paddingTop: '2rem',
                paddingLeft: '2rem',
                paddingBottom: '0rem',
                paddingRight: '2rem',
                margin: '0 auto',
            },
        },

        modalContent: {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: '400',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            paddingBottom: '3rem',
            textAlign: ' center',
            maxWidth: '294px',
            margin: 'auto',
            color: theme.palette.primary.boxShadow4Dark,

            [theme.breakpoints.down('sm')]: {
                // padding: '0rem 0rem 2rem 1.7rem',
                // marginLeft: '-36px',
            },
        },

        dateTime: {
            background: '#F2E9F5',
            border: `2px solid ${theme.palette.primary.boxShadow1Darkest}`,
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem 0',
            flexDirection: 'column',
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: '500',
            fontSize: '.875rem',
            boxShadow: `0px 2px 0px ${theme.palette.primary.boxShadow1Darkest}`,
            margin: '0 auto',
            height: '5.2rem',
            width: '100%',
            maxWidth: '11.625rem',
            [theme.breakpoints.down('sm')]: {
                fontFamily: theme.typography.body3Medium.fontFamily,
                margin: '0 auto',
                fontWeight: '500',
                fontSize: '.875rem',
                width: '13.3125rem',
                height: '5rem',
            },
        },
        dateContainer: {
            fontFamily: theme.typography.subtitle2.fontFamily,
            // fontWeight: '700',
            fontSize: theme.typography.subtitle2.fontSize,
            display: 'flex',
            color: theme.palette.primary.heather,
            justifyContent: 'center',
        },
        timeContainer: {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '400',
            fontSize: theme.typography.body1.fontSize,
            display: 'flex',
            color: theme.palette.primary.heather,
            justifyContent: 'center',
            lineHeight: '1.25rem',
        },
        dateTimeContainer: {
            margin: '0 auto',
        },
        calendarImg: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        TimeImg: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        dividerLine: {
            boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,
            width: '100%',
            border: `2px solid ${theme.palette.primary.boxShadow2Dark}`,
            borderRadius: '3px',
            background: '#F3EAF6',
            margin: '4px 0',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    }));

    const styles = useStyles();

    return (
        <Box className={styles.modalBodyContainer}>
            <Box>
                <Typography className={styles.modalClass}>{description}</Typography>
            </Box>

            <Box>
                <br></br>
                <Typography className={styles.modalContent}>
                    Your existing eir number {phoneNumber} will be replaced with{' '}
                    {changedPhoneNumber}{' '}
                </Typography>
            </Box>

            <Box className={styles.dateTime}>
                <Grid
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 1rem',
                    }}
                >
                    <Box
                        component='img'
                        src={calendarImg}
                        alt='calendar-img'
                        className={styles.calendarImg}
                    />

                    <Typography component='span' className={styles.dateContainer}>
                        Date:
                    </Typography>
                    <Typography
                        component='span'
                        style={{ paddingRight: '11px' }}
                        className={styles.timeContainer}
                    >
                        {moment(scheduledDate).format('DD/MM/YY')}
                    </Typography>
                </Grid>
                <Box className={styles.dividerLine}></Box>
                <Grid
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 1rem',
                        marginBottom: '-6px',
                        marginTop: '6px',
                    }}
                >
                    <Box component='img' src={TimeImg} alt='Time-Img' className={styles.TimeImg} />
                    <Typography component='span' className={styles.dateContainer}>
                        Time:
                    </Typography>
                    <Typography
                        component='span'
                        style={{ paddingRight: '26px' }}
                        className={styles.timeContainer}
                    >
                        {scheduledTime}
                    </Typography>
                </Grid>
            </Box>
        </Box>
    );
};

export default PortingConfirmation;
