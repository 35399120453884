import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    tvPinContainer: {
        width: '312px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: ' 0 auto',
        marginTop: '30px',
        marginBottom: '76px',
        [theme.breakpoints.only('xs')]: {
            marginTop: '25px',
            width: '264px',
        },
    },
    linkStyles: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        textDecoration: 'underline',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
    },
    redesignStyle: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    tvText: {
        fontFamily: theme.typography.h4.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        paddingBottom: '16px',
        width: '312px',
        marginTop: '50px',
        [theme.breakpoints.only('xs')]: {
            width: '264px',
            fontSize: theme.typography.body1.fontSize,
            marginTop: '32px',
        },
    },
    tvInput: {
        width: '48px !important',
        height: '48px !important',
        marginRight: '40px',
        background: theme.palette.primary.backgroundColor,
        padding: '10px 16px',
        borderRadius: '6px',
        border: 'none',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.heather,
        [theme.breakpoints.only('xs')]: {
            marginRight: '24px',
        },
    },
    tvBox: {
        width: '312px',
        [theme.breakpoints.only('xs')]: {
            width: '264px',
        },
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    simCta: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        minWidth: '184px',
        [theme.breakpoints.down('sm')]: {
            height: '41px',
            left: '.69%',
            right: '7.69%',
            borderRadius: '4100px',
            maxWidth: '296px',
            width: '100%',
        },
    },
    simCta1: {
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

        color: theme.palette.primary.white,
        borderRadius: '4rem',
        background: theme.palette.primary.sunset,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
    },
    mb: {
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
        },
    },
    MB: {
        marginBottom: '18px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
        },
    },
    simCta1Disable: {
        // boxShadow:
        //     '0 1px 5px 0 rgba( 208, 208, 208, 1), 0 2px 2px 0 rgba( 208, 208, 208, 1), 0 3px 1px 0 rgba( 208, 208, 208, 1)',
        color: theme.palette.primary.white,
        borderRadius: '4rem',
        background: theme.palette.primary.gray550,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.primary.gray550,
            borderRadius: '4rem',
            background: `${theme.palette.primary.gray550} !important`,
        },
    },
    simCta2: {
        color: theme.palette.primary.sunset,
        textAlign: 'center',
        fontSize: theme.typography.body1.fontSize,
        // marginTop: '12px',
    },
    successPopup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    successImg: {
        marginTop: '27px',
        marginBottom: '43px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '19px',
            marginTop: '67px',
        },
    },

    imgTextAdditional: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '600',
        color: theme.palette.primary.heather,
        marginBottom: '48px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginRight: '30px',
            marginLeft: '30px',
            marginBottom: '45px',
        },
    },
    sadText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },
    imgText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.subtitle1.fontSize,
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },
    focusInput: {
        outline: 'none',
    },
}));

export default useStyles;
