import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import ENDPOINT from '../../graphql/GraphQLConstants';

export async function getAuthorisedUsers({ graphql, setAuthUsers }) {
    try {
        const token = sessionStorage.getItem('access_token');
        let accountId = sessionStorage.getItem('LoggedinUserAccountID');
        if (!accountId) {
            await getNameAndAccountID(graphql);
            accountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && accountId) {
            const response = await graphql.query({
                query: ENDPOINT.GET_AUTHORISED_USER,
                variables: {
                    token: token,
                    accountId: accountId,
                },
            });
            if (response.data.getMyAuthorizedUsers) {
                setAuthUsers(response.data.getMyAuthorizedUsers);
                return response.data.getMyAuthorizedUsers;
            } else {
                throw 'authorised users not received in getAuthorisedUsers api response';
            }
        } else {
            throw 'parameters required to call getAuthorisedUsers missing';
        }
    } catch (err) {
        // console.error('Error while fetching authorised users', err.message);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}
