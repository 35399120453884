import React, { useEffect, useState } from 'react';

import {
    List,
    ListItem,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Hidden,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';

const MyEirPaymentDetails = ({ item, index }) => {
    const [expanded, setExpanded] = useState(false);
    const [formattedAmount, setFormattedAmount] = useState('');
    const [formattedTotalBalance, setFormattedTotalBalance] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedTransactionType, SetFormattedTransactionType] = useState('');
    const [paymentDetail, setPaymentDetail] = useState({});
    const [formattedPaymentTime, setFormattedPaymentTime] = useState('');
    const [formattedPaymentSource, setFormattedPaymentSource] = useState('');
    const [FormattedPartialDetails, setFormattedPartialDetails] = useState('');
    const [error, setError] = useState(false);

    const { amount, dateTime, transactionType, totalBalance, reference } = item;

    const { paymentDatetime, paymentSource, paymentMethod } = paymentDetail;

    // console.log('Payment details state1', paymentDetail);
    // console.log("Payment details state2", formattedDate, date)
    function convertDateFormat(paymentDate) {
        let formattedPaymentDate = paymentDate.split('-');
        let yyyy = formattedPaymentDate[0];
        let mm = formattedPaymentDate[1];
        let dd = formattedPaymentDate[2].split('T')[0];
        return dd + '/' + mm + '/' + yyyy;
    }

    function convertPaymentSource(str) {
        // console.log("STR", str);
        str = str.toLowerCase();
        return str.replace('_', ' ');
    }

    // console.log('Payment source', formattedPaymentSource);

    function convertTimeFormat(dateInput) {
        // console.log('Payment dateinput', dateInput);
        let time = dateInput.getHours() % 12;
        let minutes = dateInput.getMinutes();
        // console.log('Payment Time', time, minutes, dateInput.getHours() >= 12 ? 'PM' : 'AM');
        return `${time}.${minutes < 10 ? '0' + minutes : minutes} ${
            dateInput.getHours() >= 12 ? 'PM' : 'AM'
        }`;
    }

    //Adding € at the beginning for amount and Balance
    function convertAmount(amount) {
        amount = (amount / 100).toFixed(2).toString();
        let amountWithEuro = amount.split('');
        if (amountWithEuro[0] === '-') {
            amountWithEuro.splice(0, 1, ' €');
            return amountWithEuro.join('');
        } else if (amountWithEuro[0] === '0') {
            amountWithEuro.splice(0, 0, ' €');
            return amountWithEuro.join('');
        } else {
            amountWithEuro.splice(0, 0, '-', ' €');
            return amountWithEuro.join('');
        }
    }

    function capitalizeFirstLetter(string) {
        string = string.replace('_', ' ');
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function dataConversion() {
        if (
            amount != undefined &&
            totalBalance != undefined &&
            dateTime != undefined &&
            transactionType != undefined
        ) {
            let formattedAmount = convertAmount(amount);
            setFormattedAmount(formattedAmount);
            let formattedTotalBalance = convertAmount(totalBalance);
            setFormattedTotalBalance(formattedTotalBalance);
            const formattedDate = convertDateFormat(dateTime);
            setFormattedDate(formattedDate);
            let formattedTransactionType = capitalizeFirstLetter(transactionType.toLowerCase());
            SetFormattedTransactionType(formattedTransactionType);
            // if (paymentDetail.paymentSource != undefined) {
            //     let formattedPaymentSource = convertPaymentSource(paymentDetail.paymentSource);
            //     // console.log('Formatted Source', formattedPaymentSource);
            //     setFormattedPaymentSource(formattedPaymentSource);
            // }
            // if (paymentDatetime != undefined) {
            //     // console.log('Payment date Time', paymentDatetime);
            //     let formattedPaymentTimeTemp = convertTimeFormat(new Date(paymentDatetime));
            //     // console.log('Payment formatted date Time', formattedPaymentTimeTemp);
            //     setFormattedPaymentTime(formattedPaymentTimeTemp);
            // }
            // if (paymentMethod != undefined) {
            //     setFormattedPartialDetails(
            //         paymentMethod.partialDigits === null ? false : paymentMethod.partialDigits
            //     );
            // }
        }
    }

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const theme = createMuiTheme({
        //Overriding default breakpoints of material UI with custom breakpoints.
        breakpoints: {
            values: {
                xs: 0,
                sm: 599,
                md: 600,
                lg: 1019,
                xl: 1020,
            },
        },
    });

    const graphql = useApolloClient();

    async function getPaymentDetails(reference) {
        // console.log('Payment reference', reference);
        try {
            const response = await graphql.query({
                query: ENDPOINT.GET_PAYMENT_DETAILS,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    transactionReference: reference,
                },
            });

            let data = response.data;
            // console.log('Payment Data', data);
            if (data.getPaymentDetails != undefined) {
                setPaymentDetail(data.getPaymentDetails);
            }
            // console.log("Payment Details data", data);
            // if (data.getAccountSummary.transactionsPage != undefined) {
            //     setPaymentHistory(data.getAccountSummary.transactionsPage);
            // }
            // setLoading(false);
        } catch (error) {
            // setPaymentsLoadingError(true);
            // console.log('error in fetching Payment Details ', error.message);
            if (error.response != undefined && error.response.status != 200) {
            }
            setError(true);
            // console.log(error);
            // setUserNameError(error.message);
            // return "error";
        }
    }
    // useEffect(() => {
    //     // console.log('items', item);
    //     getPaymentDetails(item.reference);
    // }, []);
    useEffect(() => {
        dataConversion();
    }, []);
    return (
        <>
            <ThemeProvider theme={theme}>
                <Hidden lgDown={theme.breakpoints.down('lg')}>
                    <div className='payment-summary-accordion'>
                        <Accordion
                            key={index}
                            className='payment-summary-card'
                            // expanded={
                            //     formattedTransactionType === 'Payment' ||
                            //     formattedTransactionType === 'Adjustment'
                            //         ? expanded === index
                            //         : false
                            // }
                            expanded={false}
                            onChange={handleChange(index)}
                        >
                            <AccordionSummary
                                className='payment-summary-card__header'
                                classes={{
                                    content: 'payment-summary-card__header-content',
                                    // expanded: classes.expanded,
                                    expandIcon: 'payment-summary-card__header-expandIcon',
                                }}
                                id={'name-header'}
                                aria-controls={'name-content'}
                                // expandIcon={
                                //     formattedTransactionType === 'Payment' ||
                                //     formattedTransactionType === 'Adjustment' ? (
                                //         <ExpandMoreIcon className={'payment-summary-expand-icon'} />
                                //     ) : (
                                //         <></>
                                //     )
                                // }
                            >
                                <AccordionDetails className='payment-summary-data-container'>
                                    <div className='summary-date'>
                                        <div className='date'>{formattedDate}</div>
                                    </div>
                                    <div className='summary-type'>
                                        <div className='type'>{formattedTransactionType}</div>
                                    </div>

                                    <div className='summary-amount'>
                                        <div
                                            className={`${
                                                formattedAmount.charAt(0) === '-' ||
                                                formattedAmount.charAt(2) === '0'
                                                    ? 'amount'
                                                    : 'negative'
                                            }`}
                                        >
                                            {formattedAmount}
                                        </div>
                                    </div>
                                    <div className='summary-balance'>
                                        <div
                                            className={`${
                                                formattedTotalBalance.charAt(0) === '-' ||
                                                formattedTotalBalance.charAt(2) === '0'
                                                    ? 'balance'
                                                    : 'negative'
                                            }`}
                                        >
                                            {formattedTotalBalance}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </AccordionSummary>
                            <AccordionDetails className='payment-summary-card__body'>
                                <List className='payment-summary-card__menu'>
                                    <ListItem className='payment-summary-list'>
                                        <div className='payment-summary'>
                                            <AccordionDetails className='summary-data-container'>
                                                {error ? (
                                                    <p>Couldn't fetch data</p>
                                                ) : FormattedPartialDetails == false ? (
                                                    <p>
                                                        Payment on {' ' + formattedDate} at{' '}
                                                        {formattedPaymentTime} generated from
                                                        {' ' + formattedPaymentSource}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Payment from
                                                        <span className='bold'>
                                                            {' '}
                                                            {FormattedPartialDetails}{' '}
                                                        </span>
                                                        on {' ' + formattedDate} at{' '}
                                                        {formattedPaymentTime} generated from
                                                        {' ' + formattedPaymentSource}
                                                    </p>
                                                )}
                                            </AccordionDetails>
                                        </div>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Hidden>
                <Hidden xlUp={theme.breakpoints.up('xl')}>
                    <div className='payment-mobile-summary-accordion'>
                        <Accordion
                            key={index}
                            className='payment-summary-card'
                            // expanded={
                            //     formattedTransactionType === 'Payment' ||
                            //     formattedTransactionType === 'Adjustment'
                            //         ? expanded === index
                            //         : false
                            // }
                            expanded={false}
                            onChange={handleChange(index)}
                        >
                            <AccordionSummary
                                className='payment-summary-card__header'
                                classes={{
                                    content: 'payment-summary-card__header-content',
                                    // expanded: classes.expanded,
                                    expandIcon: 'payment-summary-card__header-expandIcon',
                                }}
                                id={'name-header'}
                                aria-controls={'name-content'}
                                // expandIcon={
                                //     formattedTransactionType === 'Payment' ||
                                //     formattedTransactionType === 'Adjustment' ? (
                                //         <ExpandMoreIcon className={'payment-summary-expand-icon'} />
                                //     ) : (
                                //         <></>
                                //     )
                                // }
                            >
                                <AccordionDetails className='payment-summary-data-container'>
                                    <div className='summary-date'>
                                        <div className='date'>{formattedDate}</div>
                                    </div>
                                    <div className='summary-type'>
                                        <div className='type'>{formattedTransactionType}</div>
                                    </div>
                                    <div className='summary-amount'>
                                        <div
                                            className={`${
                                                formattedAmount.charAt(0) === '-' ||
                                                formattedAmount.charAt(2) === '0'
                                                    ? 'amount'
                                                    : 'negative'
                                            }`}
                                        >
                                            {formattedAmount}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </AccordionSummary>
                            <AccordionDetails className='payment-summary-card__body'>
                                <List className='payment-summary-card__menu'>
                                    <ListItem className='payment-summary-list'>
                                        {error ? (
                                            <p>Couldn't fetch data</p>
                                        ) : FormattedPartialDetails == false ? (
                                            <p>
                                                Payment on {' ' + formattedDate} <br></br> at
                                                {' ' + formattedPaymentTime} generated from
                                                {' ' + formattedPaymentSource}
                                            </p>
                                        ) : (
                                            <p>
                                                Payment from
                                                <span className='bold'>
                                                    {FormattedPartialDetails}
                                                </span>
                                                on {' ' + formattedDate} <br></br> at
                                                {' ' + formattedPaymentTime} generated from
                                                {' ' + formattedPaymentSource}
                                            </p>
                                        )}
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Hidden>
            </ThemeProvider>
        </>
    );
};
export default MyEirPaymentDetails;
