import { makeStyles } from '@material-ui/core/styles';

export const useFooterStyle = makeStyles(theme => ({
    footer: {
        '& .titleText': {
            fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
            '& span': {
                fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
            },
        },
        // '& #titleText1': {
        //     fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
        //     '& span': {
        //         fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
        //     },
        // },
        position: 'relative',
        backgroundColor: '#f4f5f9',
        '& .footer-desktop': {
            display: 'none',
            '@media(min-width: 768px)': {
                display: 'block',
            },
            '& .MuiGrid-grid-md-3': {
                '@media(min-width: 960px)': {
                    maxWidth: '20% !important',
                },
            },
        },
        '& .itemRight': {
            '@media(min-width: 1025px)': {
                right: '12% ',
            },
        },

        '& a': {
            color: theme.palette.primary.heather,
        },
        '& .footer-menu': {
            paddingLeft: '0',
            textAlign: 'left',

            '& .footer-list': {
                padding: '0',
                border: '0',
                color: theme.palette.primary.heather,
                fontFamily: theme.typography.subtitle1.fontFamily,
                fontSize: theme.typography.subtitle1.fontSize,
                '& #titleText2': {
                    fontFamily: `${theme.typography.subtitle1.fontFamily}`,
                },
                '& #titleText1': {
                    fontFamily: `${theme.typography.subtitle1.fontFamily}`,
                },
                // '& div span': {
                //     fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
                // },

                backgroundColor: '#f4f5f9 !important',
                '& a': {
                    '&:hover': {
                        '& span': {
                            color: theme.palette.primary.heather,
                        },
                    },
                },
                '& span': {
                    fontFamily: theme.typography.body2.fontFamily,
                },
            },
        },
        '& p': {
            fontFamily: theme.typography.body2.fontFamily,
            color: theme.palette.primary.heather,
            padding: '0 21%',
            '@media(max-width: 768px)': {
                padding: '0',
            },
        },
        '& .footer-title': {
            marginBottom: '2em',
            fontWeight: 'bold',
            marginTop: '1em',
            cursor: 'pointer',
            '@media(min - width: 768px)': {
                cursor: 'default',
            },
            '& span': {
                fontFamily: 'gt-walsheim-medium !important',
            },
        },

        '& .footer-links': {
            textDecoration: 'none',
            display: 'block',
            paddingTop: '.1em',
            paddingBottom: '.1em',
            fontFamily: 'gt-walsheim-light',
            textAlign: 'left',

            '&:hover, &:focus, &:active': {
                textDecoration: 'underline',
            },
        },
        '& .footer-links-bottom': {
            columnCount: '0',
            columnGap: '20px',
            fontSize: '1rem',
            fontWeight: '700',
            lineHeight: '1.5',
            padding: '1em',
            textAlign: 'center',
            textDecoration: 'none !important',
            overflow: 'hidden',
            '& a': {
                fontFamily: theme.typography.body2.fontFamily,

                '&:hover': {
                    textDecoration: 'underline !important',
                    color: theme.palette.primary.heather,
                },
            },

            '@media(max-width: 767.98px)': {
                columnCount: '2',
                textAlign: 'left',
                // maxWidth: '89%',
            },
            '& li': {
                '@media(max-width: 767.98px)': {
                    width: '100%',
                },
            },
        },
        '& .icon-arrowup--black': {
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '1rem!important',
            height: '1rem!important',
            backgroundSize: 'contain',
            backgroundImage:
                'url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20viewBox%3D%220%200%2012.9%208%22%20style%3D%22enable-background%3Anew%200%200%2012.9%208%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23000000%3B%7D%3C%2Fstyle%3E%3Cpolygon%20class%3D%22st0%22%20points%3D%220%2C6.5%201.5%2C8%206.5%2C3%2011.4%2C8%2012.9%2C6.5%206.5%2C0%20%22%2F%3E%3C%2Fsvg%3E)',
            backgroundRepeat: 'no-repeat',
        },

        '& .a-no-style': {
            fontSize: '1rem',
            padding: '1em',
            border: 'none',
            outline: 'none',
            fontFamily: theme.typography.body2.fontFamily,
            background: 'transparent',
            textDecoration: 'none!important',
            color: theme.palette.primary.heather,
        },
        '& .social-links': {
            marginBottom: '1em',
            marginTop: '3em',

            '& .social-links-menu': {
                textalign: 'center',
            },

            '& img': {
                height: '32px',
                width: '32px',
                marginLeft: '1rem',
                marginRight: '1rem',

                '&:hover': {
                    opacity: '0.75',
                },
            },
            '& .icon-facebook--black': {
                backgroundImage:
                    'url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%225.29mm%22%20height%3D%2239.987%22%20viewBox%3D%220%200%2015%2030%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23010101%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3EAsset%201%3C%2Ftitle%3E%3Cg%20id%3D%22Layer_2%22%20data-name%3D%22Layer%202%22%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M10%2010V7c0-1.3.3-2%202.4-2H15V0h-4C6%200%204%203.3%204%207v3H0v5h4v15h6V15h4.4l.6-5z%22%20id%3D%22Layer_1-2%22%20data-name%3D%22Layer%201%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E)',
                backgroundRepeat: 'no-repeat',
            },
            '& .icon-youtube--black': {
                backgroundImage:
                    "url('data:image/svg+xml,%3C%3Fxml version=%221.0%22 encoding=%22utf-8%22%3F%3E%3Csvg version=%221.1%22 id=%22Layer_1%22 xmlns=%22http://www.w3.org/2000/svg%22 xmlns:xlink=%22http://www.w3.org/1999/xlink%22 x=%220px%22 y=%220px%22 viewBox=%220 0 72 72%22 style=%22enable-background:new 0 0 72 72;%22 xml:space=%22preserve%22%3E%3Ctitle%3Eflogo-RGB-HEX-Blk-72%3C/title%3E%3Cpath d=%22M57.5,13.5h-43c-6.1,0-11,4.9-11,11v23c0,6.1,4.9,11,11,11h43c6.1,0,11-4.9,11-11v-23C68.5,18.4,63.6,13.5,57.5,13.5z M48.7,36.4l-19.5,10c-0.2,0.1-0.5,0-0.7-0.2c0-0.1-0.1-0.1-0.1-0.2V26c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.2,0,0.2,0.1l19.5,10 c0.2,0.1,0.3,0.4,0.2,0.7C48.9,36.3,48.8,36.4,48.7,36.4L48.7,36.4z%22/%3E%3C/svg%3E')",
                backgroundRepeat: 'no-repeat',
            },

            ' & .icon-twitter--black ': {
                backgroundImage:
                    'url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cstyle%3E.st0%7Bfill%3A%23010101%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M10.1%2029c12.1%200%2018.7-10%2018.7-18.7v-.8c1.2-1%202.3-2.1%203.2-3.4-1.2.5-2.4.9-3.8%201%201.4-.8%202.4-2.1%202.9-3.6-1.3.8-2.7%201.3-4.2%201.6C25.7%203.8%2024%203%2022.2%203c-3.6%200-6.6%202.9-6.6%206.6%200%20.5.1%201%20.2%201.5-5.5-.3-10.3-2.9-13.6-6.9-.6%201-.9%202.1-.9%203.3%200%202.3%201.2%204.3%202.9%205.5-1.1%200-2.1-.3-3-.8v.1c0%203.2%202.3%205.8%205.3%206.4-.6.2-1.1.2-1.7.2-.4%200-.8%200-1.2-.1.8%202.6%203.3%204.5%206.1%204.6-2.2%201.8-5.1%202.8-8.2%202.8-.5%200-1.1%200-1.6-.1%203%201.8%206.5%202.9%2010.2%202.9%22%2F%3E%3C%2Fsvg%3E)',
                backgroundRepeat: 'no-repeat',
            },
            ' & .eir-icon': {
                display: 'inline-block',
                verticalAlign: 'middle',
                width: '1.5rem',
                height: '1.5rem',
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '1.5rem 1.5rem',
                textIndent: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
        },
        '& .footer-mobile': {
            display: 'block',
            padding: '0px 18px',

            '@media(min-width: 768px)': {
                display: 'none',
            },
            '& .footer-list': {
                padding: '0px !important',
                color: theme.palette.primary.heather,
                fontFamily: theme.typography.subtitle1.fontFamily,
                fontSize: theme.typography.subtitle1.fontSize,
            },
            '& .footer-card': {
                border: '0',
                boxShadow: 'none',
                backgroundColor: '#f4f5f9',
                '&.MuiAccordion-root:before': {
                    backgroundColor: 'transparent',
                },

                '& .footer-card__header': {
                    fontFamily: theme.typography.body2.fontFamily,
                    cursor: 'pointer',
                    maxHeight: '60px',
                    padding: '0.675rem',
                    color: theme.palette.primary.heather,
                    textAlign: 'left',
                    borderBottom: '2px dotted hsla(0,0%,78%,.4)',
                },
            },

            '& .footer-card__links': {
                border: '0',
                paddingLeft: '0',
                textAlign: 'left',
            },

            '& .footer-mobile__body': {
                backgroundColor: '#f4f5f9',
            },
        },
        '& .eir-icon.eir-icon--sm': {
            width: '1rem!important',
            height: '1rem!important',
            backgroundSize: 'contain',
        },
        '& .icon-arrowup--dark': {
            backgroundImage:
                'url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20viewBox%3D%220%200%2012.9%208%22%20style%3D%22enable-background%3Anew%200%200%2012.9%208%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23666666%3B%7D%3C%2Fstyle%3E%3Cpolygon%20class%3D%22st0%22%20points%3D%220%2C6.5%201.5%2C8%206.5%2C3%2011.4%2C8%2012.9%2C6.5%206.5%2C0%20%22%2F%3E%3C%2Fsvg%3E)',
            backgroundRepeat: 'no-repeat',
        },
        '& .icon-arrowdown--dark': {
            backgroundImage:
                'url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20viewBox%3D%220%200%2012.9%208%22%20style%3D%22enable-background%3Anew%200%200%2012.9%208%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23666666%3B%7D%3C%2Fstyle%3E%3Cpolygon%20class%3D%22st0%22%20points%3D%2212.9%2C1.5%2011.4%2C0%206.4%2C5%201.5%2C0%200%2C1.5%206.4%2C8%20%22%2F%3E%3C%2Fsvg%3E)',
            backgroundRepeat: 'no-repeat',
        },
        '@media(max-width: 768px)': {
            '& .list-group-item': {
                padding: '0',
                border: '0',
            },
            ' & .list-group-item': {
                position: 'relative',
                display: 'block',
                marginBottom: ' -1px',
                backgroundColor: '#f4f5f9',
            },
        },
    },
}));

//Footer Style for MyEir Redesign

// const color = {
//     primary: theme.palette.primary.heather,
//     secondary: '#892BA0',
//     default: '#6e6e6f',
// };

export const useMyEirFooterStyle = makeStyles(theme => ({
    footer: {
        '& .titleText': {
            fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
            '& span': {
                fontFamily: `${theme.typography.subtitle1.fontFamily} !important`,
            },
        },
        padding: '0 0 20px',
        background: '#f2f4f8',
        fontFamily: theme.typography.subtitle1.fontFamily,
        // [theme.breakpoints.down('sm')]: {
        //     // padding: '32px 0',
        // },
        '& .scroll-back-to-top': {
            maxWidth: 1140,
            textAlign: 'right',
            margin: '0 auto',
            '@media(min-width:600px) and (max-width:1019px)': {
                textAlign: 'center',
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
            '& .icon-arrowup--black': {
                display: 'inline-block',
                verticalAlign: 'middle',
                width: '1rem!important',
                height: '1rem!important',
                backgroundSize: 'contain',
                backgroundImage:
                    'url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20viewBox%3D%220%200%2012.9%208%22%20style%3D%22enable-background%3Anew%200%200%2012.9%208%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23000000%3B%7D%3C%2Fstyle%3E%3Cpolygon%20class%3D%22st0%22%20points%3D%220%2C6.5%201.5%2C8%206.5%2C3%2011.4%2C8%2012.9%2C6.5%206.5%2C0%20%22%2F%3E%3C%2Fsvg%3E)',
                backgroundRepeat: 'no-repeat',
            },
            '& .a-no-style': {
                fontSize: '1rem',
                padding: '1em',
                border: 'none',
                outline: 'none',
                fontFamily: theme.typography.subtitle1.fontFamily,
                background: 'transparent',
                textDecoration: 'none!important',
                color: theme.palette.primary.heather,
            },
        },
        '& .footer-primary-links': {
            margin: `0 auto 16px`,
            maxWidth: 1140,
            padding: 0,
            '& .primary-link-item': {
                flex: 1,
                padding: '0px 6px',
                '& .footer-list': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    color: theme.palette.primary.heather,
                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontSize: theme.typography.subtitle1.fontSize,
                    '& .MuiListItemText-root,.MuiListItemText-primary': {
                        margin: '0 0 0 4px',
                        lineHeight: '18px',
                        marginBottom: '4px',
                        color: theme.palette.primary.heather,
                        fontFamily: theme.typography.subtitle2.fontFamily,
                        '& .span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock': {
                            color: theme.palette.primary.heather,
                            fontFamily: theme.typography.subtitle2.fontFamily,
                        },
                    },
                },
                '& .footer-title': {
                    marginBottom: 10,
                    '& .MuiListItemText-primary': {
                        fontWeight: theme.typography.subtitle2.fontWeight,
                        color: theme.palette.primary.heather,
                        fontFamily: `${theme.typography.subtitle2.fontFamily} !important`,
                    },
                },
            },
        },
        '& .footer-mb-primary-links': {
            margin: '0 1rem',
            '& .footer-card': {
                background: '#f2f4f8',
                boxShadow: 'none',
                // marginBottom: 16,s
                '& .footer-card__header': {
                    borderBottom: `2px dotted ${theme.palette.primary.heather80}`,
                    maxHeight: '60px',
                    padding: '0.675rem',
                    '& .MuiAccordionSummary-content': {},
                },
                '& .footer-card__menu': {
                    padding: '0px',
                    fontFamily: theme.typography.body1.fontFamily,
                    '& .footer-list': {
                        color: theme.palette.primary.heather,
                        fontFamily: theme.typography.subtitle1.fontFamily,
                        fontSize: theme.typography.subtitle1.fontSize,
                        padding: 0,
                        '& .footer-links': {
                            color: theme.palette.primary.heather,
                        },
                    },
                },
            },
        },
        '& .footer-social-links': {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
            '& .footer-icon': {
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: 48,
                width: 48,
                '@media(min-width:600px) and (max-width:1019px)': {
                    height: 32,
                    width: 32,
                },
                display: 'flex',
                [theme.breakpoints.down('xs')]: {
                    height: 32,
                    width: 32,
                },
                '& a': {
                    color: 'transparent',
                    width: '100%',
                    height: '100%',
                },
            },
            '& .youtube-icon': {
                backgroundImage: `url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2000_9323)"><g clip-path="url(%23clip1_2000_9323)"><path d="M19.166 5H4.83268C2.79935 5 1.16602 6.63333 1.16602 8.66667V16.3333C1.16602 18.3667 2.79935 20 4.83268 20H19.166C21.1993 20 22.8327 18.3667 22.8327 16.3333V8.66667C22.8327 6.63333 21.1993 5 19.166 5ZM16.2327 12.6333L9.73268 15.9667C9.66602 16 9.56602 15.9667 9.49935 15.9C9.49935 15.8667 9.46601 15.8667 9.46601 15.8333V9.16667C9.46601 9.06667 9.53268 9 9.63268 9C9.66601 9 9.69935 9 9.69935 9.03333L16.1993 12.3667C16.266 12.4 16.2993 12.5 16.266 12.6C16.2993 12.6 16.266 12.6333 16.2327 12.6333Z" fill="%23493552"/></g></g><defs><clipPath id="clip0_2000_9323"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath><clipPath id="clip1_2000_9323"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>')`,
            },
            '& .facebook-icon': {
                backgroundImage: `url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2000_9318)"><g clip-path="url(%23clip1_2000_9318)"><path d="M12.9999 9.33984V7.53984C12.9999 6.75984 13.1799 6.33984 14.4399 6.33984H15.9999V3.33984H13.5999C10.6 3.33984 9.39998 5.31984 9.39998 7.53984V9.33984H7V12.3398H9.39998V21.3398H12.9999V12.3398H15.6399L15.9999 9.33984H12.9999Z" fill="%23493552"/></g></g><defs><clipPath id="clip0_2000_9318"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath><clipPath id="clip1_2000_9318"><rect width="9" height="18" fill="white" transform="translate(7 3.33984)"/></clipPath></defs></svg>')`,
            },
            '& .twitter-icon': {
                backgroundImage: `url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2000_9327)"><g clip-path="url(%23clip1_2000_9327)"><g clip-path="url(%23clip2_2000_9327)"><path d="M7.57578 22.25C16.6508 22.25 21.6008 14.75 21.6008 8.225V7.625C22.5008 6.875 23.3258 6.05 24.0008 5.075C23.1008 5.45 22.2008 5.75 21.1508 5.825C22.2008 5.225 22.9508 4.25 23.3258 3.125C22.3508 3.725 21.3008 4.1 20.1758 4.325C19.2758 3.35 18.0008 2.75 16.6508 2.75C13.9508 2.75 11.7008 4.925 11.7008 7.7C11.7008 8.075 11.7758 8.45 11.8508 8.825C7.72578 8.6 4.12578 6.65 1.65078 3.65C1.20078 4.4 0.975781 5.225 0.975781 6.125C0.975781 7.85 1.87578 9.35 3.15078 10.25C2.32578 10.25 1.57578 10.025 0.900781 9.65V9.725C0.900781 12.125 2.62578 14.075 4.87578 14.525C4.42578 14.675 4.05078 14.675 3.60078 14.675C3.30078 14.675 3.00078 14.675 2.70078 14.6C3.30078 16.55 5.17578 17.975 7.27578 18.05C5.62578 19.4 3.45078 20.15 1.12578 20.15C0.750781 20.15 0.300781 20.15 -0.0742188 20.075C2.17578 21.425 4.80078 22.25 7.57578 22.25Z" fill="%23493552"/></g></g></g><defs><clipPath id="clip0_2000_9327"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath><clipPath id="clip1_2000_9327"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath><clipPath id="clip2_2000_9327"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>')`,
            },
        },
        '& .footer-secondary-links': {
            columnCount: '0',
            columnGap: '20px',
            fontSize: theme.typography.subtitle1.fontSize,
            fontWeight: 700,
            textAlign: 'center',
            textDecoration: 'none !important',
            overflow: 'hidden',
            '& a': {
                fontFamily: theme.typography.subtitle1.fontFamily,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
            },
            '@media(min-width:600px) and (max-width:1019px)': {
                paddingRight: '50px',
                paddingLeft: '50px',
            },
            '@media(max-width: 767.98px)': {
                columnCount: '2',
                textAlign: 'left',
                // maxWidth: '89%',
            },
            '& li': {
                padding: '4px 16px',
                '@media(max-width: 767.98px)': {
                    width: '100%',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: 14,
                    lineHeight: '22px',
                },
            },
            '& li.list-inline-item:nth-child(5), li.list-inline-item:nth-child(6), li.list-inline-item:nth-child(7), li.list-inline-item:nth-child(8)': {
                '@media(max-width: 320px)': {
                    marginLeft: '25px',
                },
                '@media (min-width: 321px) and (max-width: 375px)': {
                    marginLeft: '50px',
                },
                '@media (min-width: 376px) and (max-width: 425px)': {
                    marginLeft: '70px',
                },
            },
        },
        '& .footer-cookie-btn': {
            marginBottom: 30,
            '&  #ot-sdk-btn': {
                padding: '4px 16px',
                // fontFamily: theme.typography.body3Medium.fontFamily,
                // // fontWeight: '700',
                fontFamily: theme.typography.subtitle1.fontFamily,
                color: theme.palette.primary.heather,
                borderWidth: '2px',
                backgroundColor: 'transparent',
                borderStyle: 'solid',
                // color: color.secondary,
                borderRadius: '6px',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '22px',
                outline: 'none',
                borderColor: 'initial',
            },
        },
        '& .footer-disclaimer': {
            maxWidth: 1140,
            margin: '0 auto',
            '& .footer-disclaimer-content': {
                maxWidth: 730,
                margin: '0 auto',
                fontFamily: theme.typography.subtitle1.fontFamily,
                color: theme.palette.primary.heather,
                // fontWeight: 600,
                [theme.breakpoints.down('xs')]: {
                    padding: '0 8.5px',
                    fontSize: 10,
                    lineHeight: '14px',
                },
            },
            p: {
                margin: 0,
            },
        },
    },
}));
