import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { authorisedUserStyle } from './authorisedUserStyle';
import AddAuthorisedUserInput from './AddAuthorisedUserInput';

function MyEirAddAuthorisedUsers(props) {
    const classes = authorisedUserStyle();
    return (
        <Box>
            <Typography className={classes.userNumber}>{props.user}</Typography>
            <AddAuthorisedUserInput
                securityQuestions={props.securityQuestions}
                setdisableCTA={props.setdisableCTA}
            />
        </Box>
    );
}

export default MyEirAddAuthorisedUsers;
