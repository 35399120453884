import React, { useState, useRef, useEffect } from 'react';
import { usePaymentFlowStyles } from '../css/usePaymentFlowStyles';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import errormessages from './errorMessages';
import { Grid, TextField, InputAdornment, Button, Container } from '@material-ui/core';
import EirButton from '../../theme/EirButton';
import { ACCOUNT_STATUS } from '../../components/myeirconstant/myEirConstant';

export default function PaymentAmount(props) {
    const { validation } = errormessages;
    const [disable, setDisable] = useState(true);
    const [amount, setAmount] = useState(props.confirmAmount);
    const classes = usePaymentFlowStyles();
    const paymentAmountRef = useRef();
    const [isNewAmount, setIsNewAmount] = useState(true);
    const { control, errors, handleSubmit, setValue, setError } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const [ceasedAmountError, setCeasedAmountError] = useState('');
    const userStatus = sessionStorage.getItem('Account_status');

    const onSubmitAmount = data => {
        const signSymbol = Math.sign(props?.balance) === 1 ? '-' : '';
        const balanceCondition = parseInt(signSymbol + (Math.abs(props?.balance)/ 100).toFixed(2));
        console.log('onSubmitAmount', data);
        if (`${data.paymentAmount}`.indexOf('.') === -1) {
            data.paymentAmount = Number.parseInt(data.paymentAmount).toFixed(2);
        }
        if (disable) {
            props.handleNext();
        } else if (data.paymentAmount > balanceCondition + 100) {
            setError('paymentAmount', 'manual', validation.maxLength);
        } else if (
            (userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                userStatus === ACCOUNT_STATUS.UNBILLABLE) &&
            data.paymentAmount > parseFloat(props.defaultAmount)
        ) {
            setError('paymentAmount', 'manual', validation.ceasedMsg);
        } else {
            props.setAmount(data.paymentAmount);
            props.handleNext();
        }
    };

    useEffect(() => {
        setValue('paymentAmount', amount, { shouldDirty: true });
    }, [amount, disable]);

    useEffect(() => {
        props.setHeader('Payment summary');
    }, []);

    const enableInputField = async () => {
        // console.log(paymentAmountRef)
        await setDisable(false);
        if (isNewAmount) {
            setAmount('');
            setValue('paymentAmount', '', { shouldDirty: true });
            setIsNewAmount(false);
        }
        paymentAmountRef.current.focus();
    };

    return (
        <React.Fragment>
            <form style={{ flex: 1 }}>
                <Container id={`payment-amount`} className={classes.paymentAmount}>
                    <Grid container spacing={2} className={`paymentAmountInput `}>
                        <Grid item xs={12} sm={7} className={`paymentAmountValue `}>
                            <h4>Pay:</h4>
                        </Grid>
                        <Grid item xs={12} sm={7} className={`payVal `}>
                            <Controller
                                as={
                                    <TextField
                                        inputRef={paymentAmountRef}
                                        onClick={enableInputField}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>€ </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                                control={control}
                                fullWidth
                                id='paymentAmount'
                                name='paymentAmount'
                                label='Amount'
                                variant='outlined'
                                size='small'
                                className='payVal'
                                onClick={enableInputField}
                                disabled={amount != null && disable}
                                InputLabelProps={{ shrink: true }}
                                required
                                defaultValue={amount}
                                // helperText='or enter a different amount'
                                onChange={([event]) => {
                                    console.log(disable, amount, event.target.value);
                                    if (disable) props.setAmount(props.defaultAmount);
                                    else {
                                        if (
                                            userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                        ) {
                                            if (
                                                event.target.value < props.defaultAmount ||
                                                event.target.value == props.defaultAmount
                                            ) {
                                                setCeasedAmountError('');
                                                props.setAmount(event.target.value);
                                            } else {
                                                setCeasedAmountError(
                                                    'Please enter the value owed on your final bill'
                                                );
                                            }
                                        } else {
                                            props.setAmount(event.target.value);
                                        }
                                    }
                                    return event.target.value;
                                }}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validation.amountRequired,
                                    },
                                    minLength: { value: 1, message: validation.minLength },
                                    // validate: { value: 8, message: validation.maxLength },
                                    pattern: {
                                        value: /^(0?[1-9]\d*\.\d{1,2}|\d+)$/,
                                        message: validation.amountPattern,
                                    },
                                }}
                            />
                            <p className='helperText'>or enter a different amount</p>

                            {errors?.paymentAmount ? (
                                <ErrorMessage errors={errors} name='paymentAmount'>
                                    {({ message }) => <p className='errorMessage'>{message}</p>}
                                </ErrorMessage>
                            ) : (
                                ceasedAmountError !== '' && (
                                    <div>
                                        <p className='errorMessage'>{ceasedAmountError}</p>
                                    </div>
                                )
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={`confirmAmount `}>
                        <EirButton
                            style={{ padding: '0.5rem 2.5rem' }}
                            variants='primaryButtonMedium'
                            // className={`payment-cta primary-btn`}
                            onClick={handleSubmit(onSubmitAmount)}
                        >
                            {' '}
                            Confirm amount{' '}
                        </EirButton>
                    </Grid>
                </Container>
            </form>
        </React.Fragment>
    );
}
