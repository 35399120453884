import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';

const useStyle = makeStyles({
    tooltipContainer: {
        color: '#892ba0',
    },
    tooltipIcon: {
        fontSize: '1.1rem',
        color: '#892ba0',
    },
});

const CustomTooltip = props => {
    const classes = useStyle();

    return (
        <Tooltip
            title={props.message}
            styles={props.styles}
            className={`${classes.tooltipContainer} ${props.tooltipContainer}`}
        >
            <InfoOutlined className={`${classes.tooltipIcon} ${props.tooltipIcon}`} />
        </Tooltip>
    );
};

export default CustomTooltip;
