import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { useMyBillsStyle } from '../css/useMyBillsStyle';
import {
    FormControl,
    InputLabel,
    NativeSelect,
    InputBase,
    withStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useWindowDimension } from '../../hooks/windowDimensions';
import Loading from '../../components/loading/Loading';
import { getBillingPeriod } from '../getBillingCycle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    formControl: {
        // minHeight: "56px",
    },
    input: {
        minHeight: '56px',
        border: `1px solid ${theme.palette.primary.heather}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: theme.palette.primary.heather,

        position: 'relative',
        backgroundColor: theme.palette.background.paper,

        padding: '18px 43px 17px 11px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
}))(InputBase);

function SeeCurrentUsage(props) {
    const [width, height] = useWindowDimension();
    const [isopen, setIsOpen] = useState(false);

    const graphql = useApolloClient();

    const toggle = () => setIsOpen(!isopen);
    const [valueComputed, setValueComputed] = useState(true);
    const [usageBillingPeriod, setUsageBillingPeriod] = useState('');

    const [tabIndex, setTabIndex] = useState(0);

    const [age, setAge] = useState(`068 27 377`);
    const handleServiceIDChange = event => {
        setUsageQuantity(0);
        setUsageUnit('');
        setUsageCost(0);
        props.setCurrentUsageLoading(true);
        props.setCurrentUsageLoadingError(false);
        props.setSelectedServiceIndex(event.target.value);
    };

    async function handleBillingPeriod() {
        try {
            if (
                sessionStorage.getItem('lastInvoiceDate') == undefined ||
                sessionStorage.getItem('lastInvoiceDate') == ''
            ) {
                const billCycleResponse = await getBillingPeriod(graphql);
                // console.log("BILLINGCYCLE called inside handleBillingPeriod");
            }

            setUsageBillingPeriod(convertDateFormat(sessionStorage.getItem('lastInvoiceDate')));
        } catch (error) {
            console.error('error while fetching BILLINGPERIOD', error);
        }
    }

    const classes = useMyBillsStyle();

    useEffect(() => {
        if (props.usageDetailsText.length != 0) {
            const tempArr = props.usageDetailsText.reduce(function (a, b) {
                return { amount: a.amount + b.amount }; // returns object with property x
            });
        } else {
            // console.log("NO TEMPORARY",props.usageDetailsText.length);
        }
    }, [props.selectedServiceIndex, props.usageDetailsText]);

    function hideAndUnhideSummary() {
        // console.log("HIDING SUMMARY");
        setValueComputed(true);
        setTimeout(() => {
            // console.log("UNHIDING SUMMARY");
            setValueComputed(false);
        }, 500);
    }

    function convertDateAndTime(str) {
        const dateTimeArray = str.split('T');
        const newDate = convertDateFormat(dateTimeArray[0]);
        const newTime = convertTimeFormat(dateTimeArray[1]);
        return `${newDate}   ${newTime}`;
    }

    function convertDateOnly(str) {
        const dateTimeArray = str.split('T');
        const newDate = convertDateFormat(dateTimeArray[0]);
        const newTime = convertTimeFormat(dateTimeArray[1]);
        return newDate;
    }

    function convertTimeOnly(str) {
        const dateTimeArray = str.split('T');
        const newDate = convertDateFormat(dateTimeArray[0]);
        const newTime = convertTimeFormat(dateTimeArray[1]);
        return newTime;
    }

    function convertDateFormat(str) {
        try {
            const dateArr = str.split('-');
            const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
            const myMonth = myDate.toLocaleString('default', { month: 'short' });
            // console.log(`${dateArr[1]}-${myMonth}-${dateArr[0]}`);
            return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
        } catch (error) {
            console.log('error while converting date to required format', error);
            return '';
        }
    }

    function convertTimeFormat(str) {
        return str.slice(0, 5);
    }

    function convertDuration(sec) {
        // const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds

        // if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return `${hours}hrs ${minutes}mins ${seconds}s`; // Return is HH : MM : SS
    }

    function convertPhoneNumber(num) {
        const strNum = String(num);
        if (strNum.slice(0, 2) == '00') {
            return `+${strNum.slice(2)}`;
        }
        return strNum;
    }

    function convertDataUsage(val) {
        if (val == 0) {
            return (val / 1073741824).toFixed(1) + ' GB';
        } else {
            return (val / 1073741824).toFixed(3) + ' GB';
        }

        // if(val>=1048576){
        //     if(val>=1073741824){
        //         return((val / 1073741824).toFixed(3)+" GB");
        //     }
        //     else{
        //         return((val / 1048576).toFixed(3)+" MB");
        //     }
        // }
        // else{
        //     if(val==0){
        //         return((val / 1073741824).toFixed(1)+" GB");
        //     }
        //     else{
        //         return((val / 1024).toFixed(3)+" KB");
        //     }
        // }

        // if(val>=1024){
        //     if(val>=1048576){
        //         if(val>=1073741824){
        //             return((val / 1073741824).toFixed(2)+" GB");
        //         }
        //         else{
        //             return((val / 1048576).toFixed(2)+" MB");
        //         }
        //     }
        //     else{
        //         return((val / 1024).toFixed(2)+" KB");
        //     }
        // }
        // else{
        //     return(val+" Byte");
        // }
    }

    const [usageQuantity, setUsageQuantity] = useState(0);
    const [usageUnit, setUsageUnit] = useState('');
    const [usageCost, setUsageCost] = useState(0);

    function handleUsageQuantity() {
        if (tabIndex == 0) {
            if (props.amountAndUsage[0].quantity >= 60) {
                setUsageQuantity((props.amountAndUsage[0].quantity / 60).toFixed(2));
                setUsageUnit('min');
            } else {
                setUsageQuantity(props.amountAndUsage[0].quantity);
                setUsageUnit('sec');
            }
        } else if (tabIndex == 1) {
            if (props.amountAndUsage[1].quantity >= 60) {
                setUsageQuantity((props.amountAndUsage[1].quantity / 60).toFixed(2));
                setUsageUnit('min');
            } else {
                setUsageQuantity(props.amountAndUsage[1].quantity);
                setUsageUnit('sec');
            }
        } else if (tabIndex == 2) {
            if (props.amountAndUsage[2].quantity >= 60) {
                setUsageQuantity((props.amountAndUsage[2].quantity / 60).toFixed(2));
                setUsageUnit('min');
            } else {
                setUsageQuantity(props.amountAndUsage[2].quantity);
                setUsageUnit('sec');
            }
        } else if (tabIndex == 3) {
            setUsageQuantity(props.amountAndUsage[3].quantity);
            setUsageUnit('sms');
        } else if (tabIndex == 4) {
            if (props.amountAndUsage[4].quantity >= 1048576) {
                if (props.amountAndUsage[4].quantity >= 1073741824) {
                    setUsageQuantity((props.amountAndUsage[4].quantity / 1073741824).toFixed(2));
                    setUsageUnit('GB');
                } else {
                    setUsageQuantity((props.amountAndUsage[4].quantity / 1048576).toFixed(2));
                    setUsageUnit('MB');
                }
            } else {
                if (props.amountAndUsage[4].quantity == 0) {
                    setUsageQuantity((props.amountAndUsage[4].quantity / 1073741824).toFixed(2));
                    setUsageUnit('GB');
                } else {
                    setUsageQuantity((props.amountAndUsage[4].quantity / 1024).toFixed(2));
                    setUsageUnit('KB');
                }
            }

            // if(props.amountAndUsage[4].quantity>=1024){
            //     if(props.amountAndUsage[4].quantity>=1048576){
            //         if(props.amountAndUsage[4].quantity>=1073741824){
            //             setUsageQuantity(((props.amountAndUsage[4].quantity) / 1073741824).toFixed(2));
            //             setUsageUnit("GB");
            //         }
            //         else{
            //             setUsageQuantity(((props.amountAndUsage[4].quantity) / 1048576).toFixed(2));
            //             setUsageUnit("MB");
            //         }
            //     }
            //     else{
            //         setUsageQuantity(((props.amountAndUsage[4].quantity) / 1024).toFixed(2));
            //         setUsageUnit("KB");
            //     }
            // }
            // else{
            //     setUsageQuantity(props.amountAndUsage[4].quantity);
            //     setUsageUnit("Byte");
            // }
        }
    }
    useEffect(() => {
        handleBillingPeriod();
        hideAndUnhideSummary();
    }, [props.selectedServiceIndex]);
    useEffect(() => {
        handleUsageQuantity();
        setUsageCost(props.amountAndUsage[tabIndex].amount);
    }, [tabIndex, props.selectedServiceIndex, props.amountAndUsage]);

    return (
        <React.Fragment>
            <div className={'current-usage-button' + (isopen ? ' open' : '')} onClick={toggle}>
                See current usage
            </div>
            <Modal
                className='currentUsagemain'
                // wrapClassName="currentUsagewrap"
                wrapClassName={classes.CurrentUsageWrapper}
                modalClassName='currentUsagemodal'
                backdropClassName='currentUsagebackdrop'
                contentClassName='currentUsagecontent'
                isOpen={isopen}
                toggle={toggle}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <ModalBody className='currentUsagebody'>
                    <div className='currentUsage-header'>
                        <div className='currentUsage-backButtonWrapper'>
                            <ArrowBackIcon className='currentUsage-backButton' onClick={toggle} />
                        </div>
                        <div className='currentUsage-titleUsage'>
                            <div className='currentUsage-headerTitle'>Current usage</div>
                            <div
                                className='currentUsage-headerUsage'
                                style={
                                    props.currentUsageLoadingError ||
                                    props.currentUsageLoading ||
                                    valueComputed
                                        ? { visibility: 'hidden' }
                                        : {}
                                }
                            >
                                <div className='currentUsage-headerUsageCharge'>
                                    {tabIndex == 1 ? 'Free' : '€' + (usageCost / 100).toFixed(2)}
                                </div>
                                <div
                                    className='currentUsage-headerUsageTotal'
                                    style={
                                        tabIndex == 0 || tabIndex == 1
                                            ? { visibility: 'hidden' }
                                            : {}
                                    }
                                >
                                    <span className='currentUsage-headerUsageValue'>
                                        {usageQuantity}
                                    </span>
                                    <span className='currentUsage-headerUsageProperty'>
                                        &nbsp; {usageUnit}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='currentUsage-headerUnbilledAndAccounts'>
                            <div className='currentUsage-headerUnbilled'>
                                <div className='currentUsage-headerUnbilledPadding'>
                                    {!!usageBillingPeriod &&
                                        !(usageBillingPeriod.indexOf('undefined ') > -1) && (
                                            <>Unbilled usage since {usageBillingPeriod}</>
                                        )}
                                </div>
                            </div>
                            {props.serviceName.length != 0 && (
                                <div className='currentUsage-headerAccounts'>
                                    <div className='currentUsage-headerAccountsFor'>For:</div>
                                    <FormControl
                                        disabled={props.serviceName.length > 1 ? false : true}
                                        className='currentUsage-headerAccountsDropdown'
                                    >
                                        <Select
                                            classes={{
                                                select: 'currentUsage-dropdownSelect',
                                                icon: 'currentUsage-dropdownIcon',
                                                disabled: 'currentUsage-disabled',
                                            }}
                                            id='demo-customized-select-native'
                                            value={props.selectedServiceIndex}
                                            // defaultValue={10}
                                            onChange={handleServiceIDChange}
                                            input={<BootstrapInput />}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            {props.serviceName.map((item, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.MenuItemsWrapper}
                                                        classes={{ selected: classes.DisplayNone }}
                                                        value={index}
                                                    >
                                                        {convertPhoneNumber(item)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                        </div>
                    </div>
                    {props.currentUsageLoading ? (
                        <div
                            className='currentUsage-loader'
                            style={{
                                justifyContent: 'center',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            {props.currentUsageLoadingError ? (
                                <div className='currentUsage-content-loading'>
                                    Something went wrong, please try again later.
                                    <span
                                        className='currentUsage-retry-loading'
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => {
                                            props.setCurrentUsageLoadingError(false);
                                            props.retryCurrentUsage();
                                        }}
                                    >
                                        Retry?
                                    </span>
                                </div>
                            ) : (
                                <Loading className='currentUsage-loading-animation' />
                            )}
                        </div>
                    ) : (
                        <Tabs
                            className='currentUsage-tabs'
                            selectedTabClassName='currentUsage-categorySelected'
                            selectedIndex={tabIndex}
                            onSelect={index => {
                                hideAndUnhideSummary();
                                setTabIndex(index);
                            }}
                        >
                            <TabList className='currentUsage-categorySelection'>
                                <Tab className='currentUsage-categories'>Charged</Tab>
                                <Tab className='currentUsage-categories'>Free</Tab>
                                <Tab className='currentUsage-categories'>Calls</Tab>
                                <Tab className='currentUsage-categories'>SMS</Tab>
                                <Tab className='currentUsage-categories'>Data</Tab>
                            </TabList>

                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {props.usageDetailsCharged.length != 0 ? (
                                            <>
                                                <div className='currentUsage-tabHeadings'>
                                                    <div className='currentUsage-dateTime'>
                                                        Date & time
                                                    </div>
                                                    <div className='currentUsage-destination'>
                                                        Destination
                                                    </div>
                                                    <div className='currentUsage-duration'>
                                                        Duration/Quantity
                                                    </div>
                                                    <div className='currentUsage-cost'>Cost</div>
                                                </div>
                                                {props.usageDetailsCharged.map((item, index) => {
                                                    return (
                                                        <div className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <div className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-duration'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </div>
                                                            <div className='currentUsage-cost'>
                                                                €{(item.amount / 100).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No charged usage since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {props.usageDetailsCharged.length != 0 ? (
                                            props.usageDetailsCharged.map((item, index) => {
                                                return (
                                                    <div className='currentUsage-tabHeadingsAndtabData'>
                                                        <div className='currentUsage-mobDateAndTime'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDestination'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDuration'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Duration/Quantity
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobCost'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                €{(item.amount / 100).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No charged usage since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {props.usageDetailsFree.length != 0 ? (
                                            <>
                                                <div className='currentUsage-tabHeadings'>
                                                    <div className='currentUsage-dateTime'>
                                                        Date & time
                                                    </div>
                                                    <div className='currentUsage-destination'>
                                                        Destination
                                                    </div>
                                                    <div className='currentUsage-duration'>
                                                        Duration/Quantity
                                                    </div>
                                                    <div className='currentUsage-cost'>Cost</div>
                                                </div>
                                                {props.usageDetailsFree.map((item, index) => {
                                                    return (
                                                        <div className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <div className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-duration'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </div>
                                                            <div className='currentUsage-cost'>
                                                                Free
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No free rated usage since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {props.usageDetailsFree.length != 0 ? (
                                            props.usageDetailsFree.map((item, index) => {
                                                return (
                                                    <div className='currentUsage-tabHeadingsAndtabData'>
                                                        <div className='currentUsage-mobDateAndTime'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDestination'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDuration'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Duration/Quantity
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobCost'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                Free
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No free rated usage since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {props.usageDetailsCall.length != 0 ? (
                                            <>
                                                <div className='currentUsage-tabHeadings'>
                                                    <div className='currentUsage-dateTime'>
                                                        Date & time
                                                    </div>
                                                    <div className='currentUsage-destination'>
                                                        Destination
                                                    </div>
                                                    <div className='currentUsage-duration'>
                                                        Duration
                                                    </div>
                                                    <div className='currentUsage-cost'>Cost</div>
                                                </div>
                                                {props.usageDetailsCall.map((item, index) => {
                                                    return (
                                                        <div className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <div className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-duration'>
                                                                {convertDuration(item.duration)}
                                                            </div>
                                                            <div className='currentUsage-cost'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No calls made since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {props.usageDetailsCall.length != 0 ? (
                                            props.usageDetailsCall.map((item, index) => {
                                                return (
                                                    <div className='currentUsage-tabHeadingsAndtabData'>
                                                        <div className='currentUsage-mobDateAndTime'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDestination'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDuration'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Duration
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDuration(item.duration)}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobCost'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No calls made since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {props.usageDetailsText.length != 0 ? (
                                            <>
                                                <div className='currentUsage-tabHeadings'>
                                                    <div className='currentUsage-dateTime'>
                                                        Date & time
                                                    </div>
                                                    <div className='currentUsage-destination'>
                                                        Destination
                                                    </div>
                                                    <div className='currentUsage-duration'>
                                                        Quantity
                                                    </div>
                                                    <div className='currentUsage-cost'>Cost</div>
                                                </div>
                                                {props.usageDetailsText.map((item, index) => {
                                                    return (
                                                        <div className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <div className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                            <div className='currentUsage-duration'>
                                                                {item.quantity}
                                                            </div>
                                                            <div className='currentUsage-cost'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No SMS sent since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {props.usageDetailsText.length != 0 ? (
                                            props.usageDetailsText.map((item, index) => {
                                                return (
                                                    <div className='currentUsage-tabHeadingsAndtabData'>
                                                        <div className='currentUsage-mobDateAndTime'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDestination'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobDuration'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Quantity
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.quantity}
                                                            </div>
                                                        </div>
                                                        <div className='currentUsage-mobCost'>
                                                            <div className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </div>
                                                            <div className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <div className='currentUsage-tabData-emptyText'>
                                                    No SMS sent since the last bill
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {!(
                                    props.dataNormal.quantity == 0 &&
                                    props.dataRomaing.quantity == 0
                                ) ? (
                                    <div className='currentUsage-tabHeadingsAndData'>
                                        <div className='currentUsage-regularData'>
                                            <div className='currentUsage-tabHeadingsAndData-heading'>
                                                Regular data
                                            </div>
                                            <div className='currentUsage-tabHeadingsAndData-data'>
                                                {convertDataUsage(props.dataNormal.quantity)}
                                            </div>
                                        </div>
                                        <div className='currentUsage-roamingData'>
                                            <div className='currentUsage-tabHeadingsAndData-heading'>
                                                Roaming data
                                            </div>
                                            <div className='currentUsage-tabHeadingsAndData-data'>
                                                {convertDataUsage(props.dataRomaing.quantity)}
                                            </div>
                                        </div>
                                        <div className='currentUsage-dataCost'>
                                            <div className='currentUsage-tabHeadingsAndData-heading'>
                                                Cost
                                            </div>
                                            <div className='currentUsage-tabHeadingsAndData-data'>
                                                {usageCost == 0
                                                    ? 'Free'
                                                    : '€' + (usageCost / 100).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='currentUsage-tabData-empty'>
                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                        <div className='currentUsage-tabData-emptyText'>
                                            No data used since the last bill
                                        </div>
                                    </div>
                                )}
                                {/* <div className="currentUsage-historicalUsageData">View historical usage</div> */}
                            </TabPanel>
                        </Tabs>
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default SeeCurrentUsage;
