import ENDPOINT from '../graphql/GraphQLConstants';

export const getSimCardCharges = async ({ graphql, token, serviceId }) => {

    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_SIM_CHARGES,
            variables: {
                token: token,
                serviceId: serviceId,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    } 
};