import { makeStyles } from '@material-ui/core/styles';
export const usePaymentStatus = makeStyles(theme => ({
    alreadyRegisteredWrapper: {
        padding: '0px',
    },

    alreadyRegistredMain: {
        marginLeft: 'auto',
        marginRight: 'auto',
        // marginTop: '140px',
        padding: '0 30px',
        maxWidth: '820px',

        '@media(max-width: 1019px)': {
            padding: '0 99px',
            maxWidth: '858px',
        },
        '@media(max-width: 599px)': {
            // maxWidth: '344px',
            marginTop: '8px',
            padding: '0 8px',
            height: '100%',
            // marginLeft: '16px',
        },
    },

    alreadyRegistredMainWrapper: {
        background: 'white',
    },

    alreadyRegistredMainModal: {
        background: 'rgba(51, 51, 51, 0.5)',
        backdropFilter: 'blur(4.5px)',
        overflowY: 'hidden !important',
        // top: '53px',
    },

    alreadyRegistredMainBackDrop: {},

    alreadyRegistredMainContent: {
        border: 'none',
        borderRadius: '10px',

        '@media(max-width: 599px)': {
            height: '100%',
        },

        '@media(min-width: 1020px) and (max-width: 1439px)': {
            transform: 'scale(0.75)',
            bottom: '100px',
        },
        '@media(min-width: 1440px)': {
            top: '100px',
            bottom: '0px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            // transform: 'scale(0.8)',
            bottom: '10px',
        },
    },

    alreadyRegistredModalBody: {
        minHeight: '760px',
        padding: '24px 32px',

        '@media(max-width: 599px)': {
            padding: '16px 16px 16px 16px',
        },
    },

    registerTitleContainer: {
        padding: '68px 30px 0 32px',
        textAlign: 'center',

        '@media (min-width: 0px) and (max-width: 1019px)': {
            padding: '51px 30px 0 32px',
        },
    },

    registerPhone: {
        paddingBottom: '14px',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            paddingBottom: '13px',
        },
    },

    registerTitle: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '26px',
        color: theme.palette.primary.heather,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            display: 'none',
        },
    },

    registerTitleMobile: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '26px',
        color: theme.palette.primary.heather,

        '@media (min-width: 1019px)': {
            display: 'none',
        },
    },

    alreadyRegisteredBody: {
        width: '60%',
        margin: '0 auto',
        padding: '30px 0',
        textAlign: '-webkit-center',
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',

        '@media (max-width: 599px)': {
            width: '100%',
            padding: '57px 0',
            justifyContent: 'center',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            width: '58%',
            padding: '48px 0',
            justifyContent: 'center',
        },
    },

    tickWrapper: {
        maxWidth: '100%',
        paddingBottom: '1rem',
    },

    tickCircle: {},

    tick: {
        width: '64px',
    },

    newsTextWrapper: {
        marginBottom: '1rem',

        '@media (max-width: 599px)': {
            marginTop: '19px',
            marginBottom: '11px',
        },
    },

    newsText: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            fontSize: '20px',
            // fontWeight: '700',
        },
    },

    emailVerifiedWrapper: {},

    emailVerifiedText: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            display: 'none',
        },
    },

    emailVerifiedTextMobile: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,

        '@media (min-width: 1019px)': {
            display: 'none',
        },
    },

    passwordInstructionWrapper: {
        marginTop: '8px',
        // paddingTop: '2.5rem',
        // '@media (min-width: 0px) and (max-width:1019px)': {
        //     marginTop: '22px',
        // },
    },

    passwordInstructionPara: {
        maxWidth: '80%',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,

        '@media (max-width: 599px)': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: '600',
            fontSize: theme.typography.subtitle2.fontSize,
            maxWidth: '82%',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '310px',
        },
    },

    alreadyRegisteredFooter: {
        minHeight: '80px',
        marginTop: '1.6rem',
        paddingBottom: '3.6rem',
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 0) and (max-width: 599px)': {
            marginTop: 'auto',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            marginTop: '8px',
        },
        '& .payment-cta': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.5,
            margin: '0 auto',
            marginBottom: '12px !important',
        },
        '& .primary-btn': {
            borderRadius: 40,
            padding: '8px 60px',
            outline: 'none',
            border: 'none',
            textTransform: 'none',
            cursor: 'pointer',
            background: '#d011c9',
            color: theme.palette.primary.white,
            '&.Mui-disabled': {
                background: '#ececec !important',
                color: '#888B8D !important',
            },
            '&:hover': {
                background: '#B10071',
            },
        },
        '& .back-btn': {
            background: 'transparent',
            color: '#d011c9',
            border: 'none',
            width: 'fit-content',
        },
    },

    primaryCta: {
        minHeight: '42px',
        marginBottom: '3px',
        borderColor: 'transparent',
    },

    secondaryCta: {
        minHeight: '41px',
        color: theme.palette.primary.sunset,
    },
}));
