import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme/MyEirTheme';

export const useTopNavStyle = makeStyles(() => ({
    TopNav: {
        '& .bg-light ': {
            zIndex: '99',
        },
        '& .rightNav': {
            '& li:first-child': {
                background: theme.palette.primary.white,
                paddingLeft: '1rem !important ',
                paddingRight: '1rem !important ',
                '@media(min-width:322px) and (max-width:360px)': {
                    paddingLeft: '30px !important',
                    paddingRight: '30px !important',
                },
                '@media(min-width:361px) and (max-width:378px)': {
                    paddingLeft: '38px !important',
                    paddingRight: '38px !important',
                },
                '@media(min-width:378px) and (max-width:767px)': {
                    paddingLeft: '10px !important',
                    paddingRight: '10px !important',
                },
                '@media(min-width:279px) and (max-width:321px)': {
                    width: '61px !important',
                    paddingLeft: '5px !important',
                    paddingRight: '5px !important',
                },
            },
        },
        '& .show': {
            '@media(max-width:767px)': {
                display: 'block',
                marginRight: '10px',
            },
        },
        '& .hide': {
            '@media(max-width:767px)': {
                display: 'none',
            },
        },
        '& .dropdown-toggle': {
            marginTop: '2px',
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            '@media(max-width:767px)': {
                marginRight: '8px',
            },
        },
        '& .dropdown-toggle::after': {
            marginTop: '6px',
            position: 'absolute',
            color: theme.palette.primary.sunset,
            content: '" "',
            border: 'solid',
            borderWidth: '0 3px 3px 0 !important',
            transform: 'rotate(45deg)',
            marginLeft: '8px',
            padding: '4px',
        },
        '& .arrow-down': {
            width: '0',
            height: '0',
            position: 'absolute',
            marginTop: '-30px',
            left: '73px',
            zIndex: '2',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: ' 8px solid transparent',
            borderBottom: `8px solid ${theme.palette.primary.sunset}`,
            '@media(max-width:768px)': {
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderTop: '8px solid transparent',
                borderBottom: `8px solid ${theme.palette.primary.sunset}`,
                right: '30px',
                left: 'auto',
            },
        },
        '& .arrow-right': {
            border: `solid ${theme.palette.primary.sunset}`,
            borderWidth: '0 2px 2px 0',
            display: 'inline-block',
            padding: '2px',
            transform: 'rotate(-45deg)',
            marginRight: '4px',
        },

        '& .navbar-nav .dropdown-menu': {
            boxShadow: `0px 3px 30px ${theme.palette.primary.boxShadow1Dark}`,
            borderTop: `8px solid ${theme.palette.primary.sunset}`,
            outline: 'none',
            border: 'none',
            width: '170px',

            '@media(max-width: 768px)': {
                float: 'right',
                width: 'auto',
                left: 'auto',
                right: '0',
            },
            '& .title': {
                fontSize: theme.typography.body1.fontSize,
                padding: '6px 0px',
                fontFamily: 'gt-walsheim-regular !important',
                '@media(max-width: 1024px)': {
                    fontSize: theme.typography.subtitle2.fontSize,
                },
            },
        },
        '& .megaNav': {
            height: '100px',
            fontFamily: theme.typography.h6.fontFamily,
            background: `${theme.palette.primary.white} !important`,
            '& .nav-link': {
                fontSize: theme.typography.body3Medium.fontSize,
                '@media(min-width: 767px) and (max-width: 999px)': {
                    fontSize: theme.typography.subtitle2.fontSize,
                },
                '@media(min-width: 999px) and (max-width: 1024px)': {
                    fontSize: theme.typography.body1.fontSize,
                },
            },
            '& .navbar-toggler': {
                border: `2px solid ${theme.palette.primary.heather}`,
                borderRadius: '9px',
                outline: 'none',
                '&:focus': {
                    border: `2px solid ${theme.palette.primary.heather}`,
                    borderRadius: '9px',
                    outline: 'none',
                },
            },
            '& .bg-light ': {
                background: `${theme.palette.primary.white}!important`,
            },
            '& a': {
                color: `${theme.palette.primary.heather} !important`,

                '&:hover': {
                    textDecoration: 'none',
                },
            },
            '& .activeLi': {},

            '& .active': {
                borderBottom: `2px solid ${theme.palette.primary.heather} !important`,

                color: `${theme.palette.primary.heather} !important`,
                '@media(max-width: 767px)': {
                    maxWidth: '80px',
                    float: 'right',
                    marginBottom: '8px',
                },
            },
            '& .topnav': {
                height: '100px',

                background: `${theme.palette.primary.white} !important`,
            },
        },
        '& .eir-navbar-brand': {
            height: '84px',
            width: '130px',
            backgroundImage:
                'url("/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-logo-purple-132x84.png") ',
        },
        '& .mobilepadding': {
            '@media(max-width: 767px)': {
                padding: '0px !important',
            },
        },
        '& .topnav': {
            fontSize: '.875rem',
            fontFamily: theme.typography.body3Medium.fontFamily,

            background: '#f4f5f9',
            '@media(max-width: 767px)': {
                // display: 'none',
            },
            '& .text-left': {
                color: `${theme.palette.primary.heather} !important`,
                fontFamily: theme.typography.h6.fontFamily,
            },
            '& .text-right': {
                fontFamily: theme.typography.h6.fontFamily,
            },
            '& .icon': {
                color: theme.palette.primary.sunset,
            },
            '& .container': {
                maxWidth: '100%',
                // ' @media(min - width: 768px)': {
                //     maxWidth: '1250px',
                //     color: '#000',
                //     paddingRight: '1.25rem',
                //     paddingLeft: '1.25rem',
                // },
            },

            '& .nav-item': {
                '& a': {
                    padding: '0',
                    color: theme.palette.primary.heather,
                    textDecoration: 'none',

                    '&:hover': {
                        textDecoration: 'underline',
                        color: theme.palette.primary.heather,
                    },
                },
            },

            '& .eir-icon': {
                right: '3rem',
                backgroundSize: '15px',
            },

            '& .eir-top-nav-link': {
                paddingLeft: '2rem',
            },
        },

        '& .eir-top-nav-link': {
            marginTop: '10px',
            marginBottom: '10px',
        },
    },
}));
