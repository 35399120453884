import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { usePaymentCardMethodStyles } from '../css/usePaymentCardMethodStyles';
import PaymentCardMethodHeader from './PaymentCardMethodHeader';
import PaymentCardDetails from '../payment-cards/PaymentCardDetails';
import InternationalSEPACard from '../payment-cards/InternationalSEPACard';
import ChooseDefaultMethod from '../payment-cards/ChooseDefaultMethod';
import AddCardIframeContainer from './AddCardIframeContainer';
import SwitchDefaultCardPopUp from './SwitchDefaultCardPopUp';
import SetupNewDirectDebitCard from './SetupNewDirectDebitCard';

const PaymentAddCardFlow = props => {
    const [paymentHeader, setPaymentHeader] = useState({
        title: 'Payment details',
        center: false,
    });
    const [step, setStep] = useState(0);
    const [ibanText, setIbanText] =useState(false);
    const classes = usePaymentCardMethodStyles();

    const setHeader = (title, center = false) => setPaymentHeader({ title: title, center: center });

    const resetStep = () => {
        if (step !== 0) {
            setStep(0);
            //props.toggle2();
            console.log('step', step);
        }
    };

    const resetStepToChooseDefaultScreen = () => {
        console.log('step cancel enetr', step)
        if (step !== 0) {
            setStep(1);
            //props.toggle2();
            console.log('step cancel', step)
        }
    }

    const resetToInternationalSepa=()=>{
        if (step !== 0) {
            setStep(3);
            //props.toggle2();
            console.log('international sepa', step)
        }
    }

    const handleBack = () => {
        
        if (step == 0) {
            props.toggle2();
            return;
        }
        setStep(step - 1);
        setHeader('Payment Details');
    };

    const handleNext = (jumpTo = undefined) => {
        if (jumpTo != undefined) setStep(jumpTo);
        else {
            setStep(step + 1);
        }
        console.log('next handled');
    };

    const handleIbanText =(val)=>{
        setIbanText(val);
    }
    return (
        <React.Fragment>
            <Modal
                modalClassName={classes.modalClassName}
                wrapClassName={classes.modalWrapper}
                isOpen={props.isOpen2}
                toggle={props.toggle2}
                onClosed={resetStep}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <PaymentCardMethodHeader
                    paymentHeaderText={paymentHeader.title}
                    handleBack={handleBack}
                    currentStep={step}
                    handleNext={handleNext}
                    center={paymentHeader.center}
                    setHeader={setHeader}
                />
                {
                    {
                        0: (
                            <PaymentCardDetails
                                handleNext={handleNext}
                                handleBack={handleBack}
                                resetStep={resetStep}
                                setHeader={setHeader}
                                handleIbanText={handleIbanText}
                            />
                        ),

                        3: <InternationalSEPACard handleNext={handleNext} setHeader={setHeader} />,
                        1: <ChooseDefaultMethod handleNext={handleNext} setHeader={setHeader} />,
                        2: (
                            <AddCardIframeContainer
                                handleBack={handleBack}
                                handleNext={handleNext}
                                setHeader={setHeader}
                                resetStep={resetStep}
                                toggle4={props.toggle}
                            />
                        ),
                        4: (
                            <SetupNewDirectDebitCard
                                handleBack={handleBack}
                                // handleNext={handleNext}
                                setHeader={setHeader}
                                resetStep={resetStep}
                                chooseDefaultScreen= {resetStepToChooseDefaultScreen}
                                chooseInternationalSepa = {resetToInternationalSepa}
                                ibanText={ibanText}
                                // toggle4={props.toggle}
                            />
                        ),
                    }[step]
                }
            </Modal>
        </React.Fragment>
    );
};

export default PaymentAddCardFlow;
