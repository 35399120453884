import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import ENDPOINT from '../../graphql/GraphQLConstants';

export async function getActiveDevices(graphql) {
    try {
        if (
            sessionStorage.getItem('LoggedinUserAccountID') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountID') == ''
        ) {
            const accountIDResponse = await getNameAndAccountID(graphql);
        }

        const { data } = await graphql.query({
            query: ENDPOINT.GET_ZATTOO_DEVICES,
            variables: {
                accountId: parseInt(sessionStorage.getItem('LoggedinUserAccountID')),
                // accountId: 41588625,
            },
        });
        if (data.getZattooDevices) {
            return data.getZattooDevices;
        } else {
            throw 'something went wrong while fetching activeDevices';
        }
    } catch (err) {
        console.error('Error while fetching activeDevices', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}

export async function editDeviceName(graphql, deviceId, deviceName) {
    try {
        if (
            sessionStorage.getItem('LoggedinUserAccountID') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountID') == ''
        ) {
            const accountIDResponse = await getNameAndAccountID(graphql);
        }

        const { data } = await graphql.mutate({
            mutation: ENDPOINT.EDIT_ZATTOO_DEVICE_NAME,
            variables: {
                account: parseInt(sessionStorage.getItem('LoggedinUserAccountID')),
                // account: 41588625,
                deviceId: parseInt(deviceId),
                deviceName,
            },
        });
        if (data.renameZattooDevice) {
            return data.renameZattooDevice;
        } else {
            throw 'something went wrong while renaming device';
        }
    } catch (err) {
        console.error('Error while renaming device', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}

export async function deleteDevice(graphql, deviceId) {
    try {
        if (
            sessionStorage.getItem('LoggedinUserAccountID') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountID') == ''
        ) {
            const accountIDResponse = await getNameAndAccountID(graphql);
        }

        const { data } = await graphql.mutate({
            mutation: ENDPOINT.DELETE_ZATTOO_DEVICE,
            variables: {
                accountId: parseInt(sessionStorage.getItem('LoggedinUserAccountID')),
                // accountId: 41588625,
                deviceId: parseInt(deviceId),
            },
        });
        if (data.deleteZattooDevice) {
            return data.deleteZattooDevice;
        } else {
            throw 'something went wrong while deleting device';
        }
    } catch (err) {
        console.error('Error while deleting device', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}
