import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Controller, useForm, ErrorMessage, FormData, formState } from 'react-hook-form';
import { usePaymentCardMethodStyles } from '../css/usePaymentCardMethodStyles';
import { TextField } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import GreenTick from '../images/tick.png';
import MyEirChangeSuccessModal from '../my-account/MyEirChangeSuccessModal';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import Loading from '../../components/loading/Loading';
import { useWindowDimension } from '../../hooks/windowDimensions';
import EirButton from '../../theme/EirButton';

const SetupNewDirectDebitCard = props => {
    const { handleSubmit } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    });

    const [loading, setLoading] = useState(false);
    const [width, height] = useWindowDimension();
    // const [errors, setErrors] = useState({ loading: false });
    const [validName, setValidName] = useState(false);
    const [name, setName] = useState('');

    const [validIBAN, setValidIBAN] = useState(false);
    const [iban, setIban] = useState('');

    const [showBankDetails, setShowBankDetails] = useState(false);

    const [isPopUp1, setIsPopUp1] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);

    const classes = usePaymentCardMethodStyles();
    const graphql = useApolloClient();

    const togglePopUp1 = () => {
        setIsPopUp1(!isPopUp1);
    };

    async function handleValidateAccountClick() {
        setLoading(true);
        try {
            // togglePopUp1();

            const response = await graphql.mutate({
                mutation: ENDPOINT.VALIDATE_IBAN_DETAILS,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    iban: '{"iban": "' + iban + '"}',
                },
            });

            const data = response.data;
            if (data.validateIBANDetails) {
                console.log('success Iban');
                setLoading(false);
                if (
                    data.validateIBANDetails.bankName != undefined &&
                    data.validateIBANDetails.iban != undefined
                ) {
                    sessionStorage.setItem('iban_bank_name', data.validateIBANDetails.bankName);
                    sessionStorage.setItem('iban_bic', data.validateIBANDetails.bic);
                    sessionStorage.setItem('iban_branch_name', data.validateIBANDetails.branchName);
                    sessionStorage.setItem('iban_value', data.validateIBANDetails.iban);

                    const ibanVal = data.validateIBANDetails.iban.replace(/\s/g, '');
                    const accIbanNum = ibanVal.slice(ibanVal.length - 8, ibanVal.length);
                    sessionStorage.setItem('iban', accIbanNum);

                    const paymentIdWithIban = ibanVal.slice(ibanVal.length - 4, ibanVal.length);
                    sessionStorage.setItem('iban_payment_id', paymentIdWithIban);

                    setShowBankDetails(true);
                } else if (
                    data.validateIBANDetails.bankName == null &&
                    data.validateIBANDetails.branchName == null &&
                    data.validateIBANDetails.bic == null
                ) {
                    props.chooseInternationalSepa();
                }

                // setIsSuccess(true);
                // togglePopUp1();
                // props.toggle();
            } else {
                setIsSuccess(false);
                togglePopUp1();
                // props.toggle();
            }
        } catch (error) {
            console.log('catch ');
            props.chooseInternationalSepa();
            console.log('catch error', error);
            // setIsSuccess(false);
            // togglePopUp1();
        }
    }

    const handleDisableClick = () => {
        console.log('button is diabled');
    };

    const onSubmit = () => {
        const updatePaymentDetails = {
            accountOwner: name,
            bankName: sessionStorage.getItem('iban_bank_name'),
            bic: sessionStorage.getItem('iban_bic'),
            branchName: sessionStorage.getItem('iban_branch_name'),
            iban: sessionStorage.getItem('iban_value'),
            paymentMethodType: 'DIRECT_DEBIT',
        };
        handleConfirmClick(JSON.stringify(updatePaymentDetails));
    };
    async function handleConfirmClick(updatePaymentDetails) {
        console.log('confirm is clicked', updatePaymentDetails);

        try {
            const response = await graphql.mutate({
                mutation: ENDPOINT.UPDATE_PAYMENT_METHOD_DETAILS,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    updatedPayment: updatePaymentDetails,
                    account_id: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });
            const data = response.data;
            if (data.updatePaymentMethod === '201') {
                console.log('card is succesfully updated');
                setIsSuccess(true);
                togglePopUp1();
                //   props.toggle();

                // props.resetStep();
                // props.toggle();
            } else {
                console.log('card is not updated');
                setIsSuccess(false);
                togglePopUp1();
                //  props.toggle();
            }
        } catch (error) {
            console.log('switch error', error);
            setIsSuccess(false);
            togglePopUp1();
        }
    }

    const handleArrow = () => {
        console.log('cancel');
        props.chooseDefaultScreen();
    };

    //validate name
    const validNameFormat = /^[A-Z][a-z]*(\s[A-Z][a-z]*)+$/;
    //const validNameFormat = /^[a-zA-Z]+ [a-zA-Z]+$/;

    function validateName(valFun, val) {
        if (validNameFormat.test(val)) {
            valFun(true);
        } else {
            valFun(false);
        }
    }

    const handleValidName = event => {
        validateName(setValidName, event.target.value);
        setName(event.target.value);
    };

    // validate IBAN

    const validIbanValue = /^([A-Z]{2})(\d{2})([A-Z]*[\d]+)$/;
    // const validIbanValue = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{6}[0-9]{5}([a-zA-Z0-9]?){0,22}/g;

    function validateIban(valFunc, value) {
        if (validIbanValue.test(value)) {
            valFunc(true);
        } else {
            valFunc(false);
        }
    }
    const handleValidIBAN = event => {
        validateIban(setValidIBAN, event.target.value);
        setIban(event.target.value);
    };

    useEffect(() => {
        props.setHeader('Setup new Direct Debit');
    }, []);

    return (
        <React.Fragment>
            <ModalBody className={classes.paymentMethod}>
                <form
                    style={{ position: 'relative' }}
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    {loading ? (
                        <div className={classes.loader}>
                            <div>
                                <p className='loader-text' style={{ textAlign: 'center' }}>
                                    Verifying with your bank
                                </p>
                                <Loading className='contracts-loading-animation' />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className={`setup-container-body ${loading ? classes.blurEffect : ''}`}>
                        <div>
                            <div className='setup-body-contents'>
                                <TextField
                                    className={`${classes.codes}`}
                                    id='account-name'
                                    type='text'
                                    label="Account holder's name*"
                                    variant='outlined'
                                    color='primary'
                                    onChange={handleValidName}
                                    value={name}
                                    //onClick={handleValidName}
                                    // inputProps={{
                                    //     inputMode: 'string',
                                    //     pattern: '/^[A-Za-z]+$/',
                                    // }}
                                    InputLabelProps={{
                                        classes: {
                                            root: 'text-field-input-label-props-root',
                                            shrink:
                                                'text-field-input-label-props-shrink' +
                                                (validName
                                                    ? ''
                                                    : name
                                                    ? ' text-field-input-label-props-shrink-invalid'
                                                    : ''),
                                            focused:
                                                'text-field-input-label-props-focused' +
                                                (validName
                                                    ? ''
                                                    : name
                                                    ? ' text-field-input-label-props-focused-invalid'
                                                    : ''),
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root:
                                                'text-field-input-props-root' +
                                                (!!name
                                                    ? ' text-field-input-props-root-filled'
                                                    : ''),
                                            focused: 'text-field-input-props-focused',
                                            notchedOutline:
                                                'text-field-input-props-notchedOutline' +
                                                (validName
                                                    ? ''
                                                    : name
                                                    ? ' text-field-input-props-notchedOutline-invalid'
                                                    : ''),
                                            input: 'text-field-input-props-input',
                                        },

                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {' '}
                                                {showBankDetails ? (
                                                    <img src={GreenTick} alt='green-tick' />
                                                ) : (
                                                    ''
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className='setup-body-contents'>
                                <TextField
                                    className={`${classes.codes}`}
                                    id='IBAN-id'
                                    type='text'
                                    label='IBAN*'
                                    variant='outlined'
                                    color='primary'
                                    onChange={handleValidIBAN}
                                    value={iban}
                                    // onClick={handleValidName}
                                    // inputProps={{
                                    //     inputMode: 'numeric',
                                    //     pattern: '[0-9]*',
                                    //     maxLength: 6,
                                    // }}
                                    InputLabelProps={{
                                        classes: {
                                            root: 'text-field-input-label-props-root',
                                            shrink:
                                                'text-field-input-label-props-shrink' +
                                                (validIBAN
                                                    ? ''
                                                    : iban
                                                    ? ' text-field-input-label-props-shrink-invalid'
                                                    : ''),
                                            focused:
                                                'text-field-input-label-props-focused' +
                                                (validIBAN
                                                    ? ''
                                                    : iban
                                                    ? ' text-field-input-label-props-focused-invalid'
                                                    : ''),
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root:
                                                'text-field-input-props-root' +
                                                (!!iban
                                                    ? ' text-field-input-props-root-filled'
                                                    : ''),
                                            focused: 'text-field-input-props-focused',
                                            notchedOutline:
                                                'text-field-input-props-notchedOutline' +
                                                (validIBAN
                                                    ? ''
                                                    : iban
                                                    ? ' text-field-input-props-notchedOutline-invalid'
                                                    : ''),
                                            input: 'text-field-input-props-input',
                                        },

                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {showBankDetails ? (
                                                    <img src={GreenTick} alt='green-tick' />
                                                ) : (
                                                    ''
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            {/* Account holder's details */}

                            {showBankDetails ? (
                                <>
                                    <div className='setup-details'>
                                        <div className='bank-info'>
                                            <div className='info'>Bank name</div>
                                            <div className='info-val'>
                                                {sessionStorage.getItem('iban_bank_name')}
                                            </div>
                                        </div>

                                        <div className='bank-info'>
                                            <div className='info'>Account number</div>
                                            <div className='info-val'>
                                                {sessionStorage.getItem('iban')}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ''
                            )}

                            <div className='setup-text'>
                                {props.ibanText ? (
                                    width < 600 ? (
                                        <>
                                            <p>Only one IBAN can be stored.</p>
                                            <p>The new IBAN will replace the old one.</p>
                                        </>
                                    ) : (
                                        <p>
                                            Only one IBAN can be stored. The new IBAN will replace
                                            the old one.
                                        </p>
                                    )
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div className='payment-body-footer'>
                            {showBankDetails ? (
                                <EirButton
                                    // className={
                                    //     'payment-body-footer-cta primary-cta' +
                                    //     (true ? ' cta-enabled-setup' : ' cta-diabled')
                                    // }
                                    style={{ padding: '0.5rem 1.875rem' }}
                                    variants='primaryButtonMedium'
                                    disabled={false}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Confirm
                                </EirButton>
                            ) : (
                                <button
                                    className={
                                        'payment-body-footer-cta primary-cta' +
                                        (validName && validIBAN
                                            ? ' cta-enabled-setup'
                                            : ' cta-diabled')
                                    }
                                    onClick={
                                        validName && validIBAN
                                            ? handleValidateAccountClick
                                            : handleDisableClick
                                    }
                                >
                                    Validate account
                                </button>
                            )}
                            <div
                                className='payment-body-footer-cta secondary-cta footer-cta'
                                style={{ marginBottom: '10px' }}
                            >
                                <span style={{ cursor: 'pointer' }} onClick={handleArrow}>
                                    Back
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalBody>

            <MyEirChangeSuccessModal
                isOpen={isPopUp1}
                //toggle={togglePopUp}
                toggle1={togglePopUp1}
                resetStep={() => props.resetStep()}
                successText={
                    isSuccess
                        ? `Your account ending ${sessionStorage.getItem(
                              'iban_payment_id'
                          )} has been successfully added. It will now be your default payment method.`
                        : 'Something went wrong. Please try again later'
                }
                codeStatus={isSuccess}
                buttonText='Close'
            />
        </React.Fragment>
    );
};

export default SetupNewDirectDebitCard;
