import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { usePaymentFlowStyles } from '../css/usePaymentFlowStyles';
import Loading from '../../components/loading/Loading';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../../mobilecomponents/graphql/GraphQLConstants';
import { RealexHpp } from '../../utils/realexPayments';
import { useWindowDimension } from '../../hooks/windowDimensions';
import { publish } from 'pubsub-js';
import errormessages from './errorMessages';

const PaymentIframeContainer = props => {
    const graphql = useApolloClient();
    const classes = usePaymentFlowStyles();
    const [isLoading, setIsLoading] = useState(false);
    const payButtonRef = useRef(null);
    // const [isError, setIsError] = useState(false);
    const token_payment = sessionStorage.getItem('access_token');
    const [error, setError] = useState({
        iframe: null,
        payment: null,
    });
    const [openDialog, setDialog] = useState(false);
    const [width, height] = useWindowDimension();
    useEffect(() => {
        //setLoading(true);
        // loadHpp();
        props.setHeader('Payment summary');
        const fetchData = async () => {
            await getHPPRequest();
            payButtonRef.current.click();
            setIsLoading(false);
        };
        fetchData();
    }, []);
    const handleCallback = async response => {
        console.log('Callback');
        setIsLoading(true);
        try {
            // Check if response contains valid HPP JSON
            if (!response.startsWith('{')) {
                throw new Error(response);
            }
            const { data } = await graphql.mutate({
                mutation: ENDPOINT.VALIDATE_PAYMENT,
                variables: {
                    token: token_payment,
                    HppResponse: { encoded: true, hppResponse: response },
                },
            });
            // console.log('data', data)
            if (data) {
                // On successful payment, will clear data
                //sessionStorage.removeItem('prospectUuid')
                // move to next screen and set success true
                // console.log('Successfull', data)
                props.setStatus(true);
                props.setPaidAmount(props.payableAmount);
                publish('UPDATE_BALANCE');
                props.handleNext();
            }
            setIsLoading(false);
        } catch (err) {
            //setIsLoading(true);
            console.error(err);
            setError({ iframe: '', payment: err.message });
            setDialog(false);
            props.setStatus(false);
            setIsLoading(false);
            props.handleNext();
            // move to next screen and set success False
        }
    };
    const launchHPP = async myHppRequest => {
        console.log('inside launch hpp');
        //setIsLoading(true);
        RealexHpp.embedded.init('pay-button', 'targetIframe', handleCallback, myHppRequest);
    };

    // const testPaidAmount = () => {
    //     props.setStatus(true);
    //     props.setPaidAmount(props.payableAmount);
    //     publish('UPDATE_BALANCE');
    //     props.handleNext();
    // };

    const getHPPRequest = async () => {
        // setIsLoading(true);
        // console.log('hpppppp');
        try {
            console.log('loading------');
            setIsLoading(true);
            if (!token_payment) {
                throw new Error('token_payment not found');
            }
            const { data } = await graphql.query({
                query: ENDPOINT.GET_HPP_REQUEST,
                variables: {
                    token: token_payment,
                    amount: Math.round(props.payableAmount * 100),
                    //amount:props.payableAmount,
                },
            });

            console.log('resp', data.myHppRequest);
            if (data.myHppRequest) {
                console.log('data hpp', data.myHppRequest);
                // // Elavon complaints when null values are sent so filtering them out
                let filteredHppRequest = Object.assign({}, data.myHppRequest);
                Object.keys(filteredHppRequest).forEach(
                    key =>
                        (filteredHppRequest[key] == null ||
                            filteredHppRequest[key] == 'undefined') &&
                        delete filteredHppRequest[key]
                );
                await launchHPP(filteredHppRequest);
            }
        } catch (err) {
            console.error('gethpp', err);
            setIsLoading(false);
            if(err.message.includes(errormessages?.validation?.getHppErrRes)){
                setError({ iframe: errormessages?.validation?.maxLength, payment: 'No error' });
                setDialog(true);
            }else{
                setError({ iframe: err.message, payment: 'No error' });
                setDialog(true);
            }
        }
    };
    const handleDialog = () => {
        console.log('retrying....');
        setDialog(false);
        props.toggle();
    };

    return (
        <React.Fragment>
            <div className={classes.PaymentIframeModal}>
                {/* <button onClick={testPaidAmount}>success</button> */}
                {/* <button onClick={testFail}>fail</button> */}
                <div className={classes.payAmountContainer}>
                    <div className={`payment-method-value `}>
                        <h4>Pay: {` € ${props.payableAmount}`}</h4>
                    </div>
                </div>

                {
                    <div className={classes.iframeContainer}>
                        {true && error.iframe == null && (
                            <div className={classes.loadingBox}>{<Loading />}</div>
                        )}
                        <>
                            {error.iframe != null && error.iframe != '' ? (
                                <Dialog
                                    open={openDialog}
                                    onClose={handleDialog}
                                    disableBackdropClick='true'
                                    aria-labelledby='alert-dialog-title'
                                    aria-describedby='alert-dialog-description'
                                    id='alert-iframe-error'
                                    className={classes.DialogBox}
                                >
                                    <DialogTitle id='alert-dialog-title'>Payment error</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id='alert-dialog-description'>
                                            {error.iframe}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.eirPaymentDialog}>
                                        <Button
                                            className={classes.eirButton}
                                            onClick={handleDialog}
                                            // color='primary'
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            ) : (
                                <iframe
                                    id='targetIframe'
                                    frameBorder='0'
                                    scrolling='auto'
                                    // height={!isLoading || width < 600 ? '480px' : '280px'}
                                    height={'380px'}
                                    sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation'
                                />
                            )}
                            <Button id='pay-button' ref={payButtonRef} />
                        </>
                    </div>
                }

                <div className={classes.paymentIframeFooter}>
                    <div className={`payment-method-button `}>
                        <button className={`payment-cta back-btn`} onClick={props.handleBack}>
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default PaymentIframeContainer;
