import ENDPOINT from '../graphql/GraphQLConstants';
import END_POINT from '../../components/graphql/GraphQLConstants';

export const getPortingTimes = async ({ graphql }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.PORTING_TIME,
        });
        return data.portingTime;
    } catch (error) {
        console.log(error);
    }
};

// sendotp
export const sendOtpAPI = async ({ graphql, msisdn }) => {
    try {
        const { data } = await graphql.query({
            query: END_POINT.GET_OTP,
            variables: { msisdn },
        });
        return data.OTP;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// validate otp
export const validateOtpAPI = async ({ graphql, otpUuid, otp }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: END_POINT.VALIDATE_OTP,
            variables: { otpUuid, otp },
        });
        return data.validateOTP;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// schedule move
export const scheduleMoveAPI = async ({ graphql, portIn, token }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.SCHEDULE_MOVE_PORT,
            variables: { token, portIn },
        });
        return data.portExistingCustomer;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
export const getOperatorApi = async ({ graphql, msisdn }) => {
    try {
        const { data } = await graphql.query({
            query: END_POINT.GET_OPERATOR,
            variables: { msisdn },
        });
        return data.operator;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deletePortAPI = async ({ graphql, deleteMnpId, token }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.DELETE_PORT_METHOD,
            variables: {
                token,
                deleteMnpId,
            },
        });
        return data.deletePort;
    } catch (error) {
        console.error(error);
    }
};

//reshedule move
export const rescheduleMoveAPI = async ({ graphql, updatePort, token }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.RESCHEDULE_MOVE_PORT,
            variables: { token, updatePort },
        });
        return data.updateNotStartedPort;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// porting check on load
export const onload = async ({ graphql, session, serviceId }) => {
    try {
        // console.log(session.getItem('access_token'));   
        const { data } = await graphql.query({
            query: ENDPOINT.PORTING_CHECK,
            variables: {
                token: session.getItem('access_token'),
                serviceId: parseInt(serviceId),
            },
        });
        console.log('oonload ==>', data);
        if (data.getPortDetails) {
            return { data: data.getPortDetails };
        }
    } catch (error) {
        console.log(error);
       throw error;
    }
};

export const getAccountTypes = () => {
    return {
        BILL_PAY_SINGLE_LINE: 'Postpay single line',
        BILL_PAY_MULTI_LINE: 'Postpay multiline',
        PAYG_REGISTERED: 'Prepay registered',
        PAYG_UNREGISTERED: 'Prepay unregistered',
        PAYG_MULTI_LINE: 'Prepay multiline',
    };
};
