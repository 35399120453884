import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CustomSelect from '../../../sharedcomponents/inputs/select/CustomSelect';
import CustomTextField from '../../../sharedcomponents/inputs/text-field/CustomTextField';
import { getAccountTypes } from '../porting.util';
import useStyle from './style';
import { publish } from 'pubsub-js';

const AccountInfo = ({ brand, handleCTA, handleAccountInfo, setEnable, portIn }) => {
    const [accountType, setAccountType] = useState();
    const [accountNumber, setAccountNumber] = useState('');
    const [error, setErrors] = useState('');
    const accountList = getAccountTypes();

    // on account type selection
    const handleAccountSelection = e => {
        console.log('account selection', e.target.value);
        publish('RESET_API_ERROR');
        setAccountType(e.target.value);
        portIn.setData({ ...portIn.value, type: e.target.value });
        // when account type is PREPAY or account number is added
        if (e.target.value.indexOf('PAYG') >= 0 || Boolean(accountNumber))
            setEnable({ step3: true });
        // when the account type is BILLPAY or account number is not added.
        else setEnable({ step3: false });
    };

    const regex = /^[0-9a-zA-Z]{6,24}$/;
    // on account number is changed/added
    const handleAccountNumber = e => {
        publish('RESET_API_ERROR');
        setAccountNumber(e.target.value);
       portIn.setData({ ...portIn.value, accountNumber: e.target.value });
        // when account number is removed
        if (e.target.value === '') {
            setErrors('Please enter account number');
           setEnable({ step3: false });
        }
         //Change as part of [EIRDIGITAL-3001]
         else if(regex.test(e.target.value)){
            setErrors('');
            setEnable({ step3: true });
        }
        
       // when account number is more than 24
        else if (e.target.value.length > 24) {
            setErrors('Please enter a valid account number');
            setEnable({ step3: false });
        }
        // when account number is correct
        else {
            setErrors('Please enter a valid account number');
            setEnable({ step3: false });
           // setEnable({ step3: true });
        }
    };

    const classes = useStyle();

    return (
        <Box style={{ width: '100%', padding: '0 0 0 1rem' }}>
            <Box style={{ width: '100%', padding: '0px 31px 0px 0rem' }}>
                <CustomTextField
                    id='porting-current-provider'
                    label='Current provider'
                    value={brand}
                    classes={{
                        container: classes.textField,
                    }}
                    // tooltip="Tell us if you're Prepay or Postpay (pay by bill). If Postpay let us know if there's just one (single) or multiple users on the account"
                    readOnly
                    required
                    // error={Boolean(error)}
                    errorMessage={error}
                />
            </Box>

            <CustomSelect
                id='porting-acount-type'
                data={accountList}
                value={accountType}
                onChange={handleAccountSelection}
                placeholder='Account type'
                tooltip="Tell us if you're Prepay or Postpay (pay by bill). If Postpay let us know if there's just one (single) or multiple users on the account"
                required
            />
            {accountType?.indexOf('BILL') === 0 && (
                <CustomTextField
                    id='porting-account-number'
                    label='Current provider account number'
                    onChange={handleAccountNumber}
                    classes={{
                        container: `${classes.textField}`,
                    }}
                    tooltip='You will find this on your current provider account bill.'
                    error={Boolean(error)}
                    errorMessage={error}
                    required
                />
            )}
        </Box>
    );
};

export default AccountInfo;
