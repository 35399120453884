import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useButtonVariant = makeStyles(theme => ({
    primaryButtonSmall: {
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        textTransform: 'none',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.sunset,
        color: theme.palette.primary.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset30,
            cursor: 'pointer',
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: theme.palette.primary.gray550,
            cursor: 'default',
            color: theme.palette.primary.white,
        },
    },
    primaryButtonMedium: {
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        textTransform: 'none',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.sunset,
        color: theme.palette.primary.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset30,
            cursor: 'pointer',
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: theme.palette.primary.gray550,
            cursor: 'default',
            color: theme.palette.primary.white,
        },
    },
    primaryButtonLarge: {
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        textTransform: 'none',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.sunset,
        color: theme.palette.primary.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset30,
            cursor: 'pointer',
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: theme.palette.primary.gray550,
            color: theme.palette.primary.white,
            cursor: 'default',
        },
    },
    secondaryButtonSmall: {
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        textTransform: 'none',
        cursor: 'pointer',
        border: `0.188rem solid ${theme.palette.primary.sunset}`,
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.sunset,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset,
            color: theme.palette.primary.white,
            cursor: 'pointer',
        },
        '&.MuiButton-root.Mui-disabled': {
            border: `0.188rem solid ${theme.palette.primary.gray550}`,
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.gray550,
            cursor: 'default',
        },
    },
    secondaryButtonMedium: {
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        textTransform: 'none',
        cursor: 'pointer',
        border: `0.188rem solid ${theme.palette.primary.sunset}`,
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.sunset,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset,
            color: theme.palette.primary.white,
            cursor: 'pointer',
        },
        '&.MuiButton-root.Mui-disabled': {
            border: `0.188rem solid ${theme.palette.primary.gray550}`,
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.gray550,
            cursor: 'default',
        },
    },
    secondaryButtonLarge: {
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        textTransform: 'none',
        cursor: 'pointer',
        border: `0.188rem solid ${theme.palette.primary.sunset}`,
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.sunset,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset,
            color: theme.palette.primary.white,
            cursor: 'pointer',
        },
        '&.MuiButton-root.Mui-disabled': {
            border: `0.188rem solid ${theme.palette.primary.gray550}`,
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.gray550,
            cursor: 'default',
        },
    },
}));

function EirButton(props) {
    const classes = useButtonVariant();
    const { children, variants, ...other } = props;

    return (
        <Button className={classes[variants]} {...other}>
            {children}
        </Button>
    );
}

export default EirButton;
