import { Box, Typography, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PortingConfirmation from './PortingConfirmation';
import CustomUpdatedPopUpModal from './CustomModal/CustomUpdatedPopUpModal';
import CustomModal from './CustomModal/CustomModal';
import sadsmily from '../images/sad.png';
import calendarImg from '../images/calendar.png';
import useStyle from './CustomModal/style';
import { useApolloClient } from '@apollo/client';
import Porting from './porting-step/Porting';
import { deletePortAPI } from './porting.util';
import MyEirErrorHandle from './MyEirErrorHandle';
import { subscribe } from 'pubsub-js';
import EirButton from '../../theme/EirButton';

const ReschedulePortModal = ({
    phoneNumber,
    open,
    handleClose,
    portData,
    setOpen,
    backToEirAccount,
    setPortData,
    serviceId,
    setOpenSuccess,
}) => {
    const [step, setStep] = useState(0);

    const graphql = useApolloClient();
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [element, setElement] = useState('');
    const [openErrormodal, setErrormodal] = useState(false);

    const [isSuccess, setIsSuccess] = useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
    const [openPopupError, setOpenPopupError] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const [changedPhoneNumber, setChangedPhoneNumber] = useState('');
    const [portDateTime, setPortDateTime] = useState({ time: '', date: '' });
    const [apiError, setApierror] = useState('');
    // handle back arrow action
    const backArrowAction = () => {
        if (step === 0) {
            handleClose(false);
        } else {
            setStep(step - 1);
        }
    };

    const resetApiError = () => {
        setApierror('');
    };

    subscribe('RESET_API_ERROR', resetApiError);

    const handleCloseError = async () => {
        setOpenPopupError(false);
    };

    const handleDeletePort = async () => {
        try {
            const response = await deletePortAPI({
                graphql,
                deleteMnpId: portData.mnpId,
                token: sessionStorage.getItem('access_token'),
            });

            if (response === 204) {
                console.log('port is succesfully deleted');
                handleClose(false);
                setOpenCancelConfirm(false);
                setOpenPopupError(false);
                setOpenSuccess(true);
            } else {
                console.log('port is not deleted');
                setErrormodal(true);
                handleClose(false);

                setOpenCancelConfirm(false);
                setOpenPopupError(true);
                setIsSuccess(false);
                setOpenSuccess(false);
            }
        } catch (error) {
            console.error(error);
            setErrormodal(true);
            handleClose(false);

            setOpenCancelConfirm(false);
            setOpenPopupError(true);
        }
    };

    const handleMoveCancel = () => {
        setOpenCancelConfirm(!openCancelConfirm);
        setIsSuccess(false);
        setErrormodal(false);
    };

    const handleCancelConfirm = () => {
        setOpenCancelConfirm(false);
    };
    const handleRescheduleToggle = () => {
        handleClose(() => setStep(0));
    };

    const handleCTA = ({ primary, cta }) => {
        if (primary) {
            setCTA1({ ...cta1, ...cta });
        } else {
            setCTA2({ ...cta2, ...cta });
        }
    };

    // all steps
    const PortingSteps = [
        <PortingConfirmation
            onload={() => {
                handleCTA({
                    primary: true,
                    cta: { text: 'Update my move', action: () => setStep(1), disable: false },
                });
                handleCTA({
                    primary: false,
                    cta: {
                        text: 'Cancel my move',
                        action: handleMoveCancel,
                        disable: false,
                    },
                });
            }}
            phoneNumber={phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
            changedPhoneNumber={portData?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
            setChangedPhoneNumber={setChangedPhoneNumber}
            scheduledDate={portData?.portStartDateTime?.split('T')[0]}
            scheduledTime={portData?.portStartDateTime?.split('T')[1].substring(0, 5)}
            description={'You have a pending move scheduled'}
        />,

        <Porting
            portData={portData} // for reschedule only
            handleCTA={handleCTA} // set CTA
            setApierror={setApierror}
            reschedule // reschedule true
            setStep={setStep}
            setPortData={setPortData}
            serviceId={serviceId}
            openError={openErrorPopup}
            setOpenError={setOpenErrorPopup}
            handleClose={handleClose}
            // phoneNumber={phoneNumber}
            // newPhoneNumber={newPhoneNumber}
            // setChangedPhoneNumber={setChangedPhoneNumber}
        />,

        <PortingConfirmation
            onload={() => {
                handleCTA({
                    primary: true,
                    cta: {
                        text: 'Back to my eir account',
                        action: () => {
                            // TODO move to my eir account
                            setOpen(false);
                            backToEirAccount(true);
                            handleClose(() => setStep(0));
                        },
                        disable: false,
                    },
                });
                handleCTA({
                    primary: false,
                    cta: { text: '' },
                });
            }}
            phoneNumber={phoneNumber}
            // setChangedPhoneNumber={setChangedPhoneNumber}
            changedPhoneNumber={portData?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
            scheduledDate={portDateTime.date}
            scheduledTime={portDateTime.time}
            description={'Your move has been rescheduled'}
        />,
    ];

    useEffect(() => {
        setElement(PortingSteps[step]);
    }, [step]);

    useEffect(() => {
        setPortDateTime({
            date: portData?.portStartDateTime?.split('T')[0],
            time: portData?.portStartDateTime?.split('T')[1].substring(0, 5),
        });
    }, [portData?.portStartDateTime]);

    const styles = useStyle();

    return (
        <Box>
            <CustomModal
                open={open}
                cta1={cta1}
                cta2={cta2}
                apiError={apiError}
                toggle={handleRescheduleToggle}
                header={{
                    text: 'Keep my old number',
                    arrowAction: backArrowAction,
                    center: false,
                }}
            >
                <Box style={{ paddingBottom: '1rem' }}>{element}</Box>
            </CustomModal>

            <Box className='errorModal' maxWidth='md' id='myeir-modal-window-error'>
                {openErrormodal ? (
                    <>
                        <CustomUpdatedPopUpModal
                            open={openPopupError}
                            cta1={''}
                            cta2={''}
                            toggle={() => setOpenPopupError(false)}
                        >
                            <Typography className={styles.imgText}>
                                Oops something went wrong!
                            </Typography>
                            <Box className='styles.imgcontent'>
                                <Box
                                    component='img'
                                    src={sadsmily}
                                    alt='sad-smily'
                                    className={styles.sadImg}
                                />
                            </Box>
                            <Box>
                                <Typography className={styles.sadText1}>
                                    Please try again later.
                                </Typography>
                                <EirButton
                                    variants='primaryButtonMedium'
                                    style={{ padding: '0.5rem 1rem' }}
                                    // className={styles.cancelBtn}
                                    onClick={handleCloseError}
                                >
                                    Close
                                </EirButton>
                            </Box>
                        </CustomUpdatedPopUpModal>
                    </>
                ) : (
                    <>
                        <CustomUpdatedPopUpModal
                            open={openCancelConfirm}
                            toggle={() => setOpenCancelConfirm(false)}
                            cta1={{
                                text: 'Yes',
                                action: () => {
                                    handleDeletePort();
                                },
                            }}
                            cta2={{
                                text: 'No, dont cancel',
                                action: () => {
                                    handleCancelConfirm();
                                },
                            }}
                        >
                            <>
                                <Typography className={styles.cancelText}>
                                    Cancel my move
                                </Typography>
                                <Box className='styles.imgcontent'>
                                    <Box
                                        component='img'
                                        src={calendarImg}
                                        alt='calendar-img'
                                        className={styles.calendarImg}
                                    />
                                </Box>
                                <Typography className={styles.textContainer}>
                                    Are you sure you want to cancel this move?
                                </Typography>
                            </>
                        </CustomUpdatedPopUpModal>
                    </>
                )}
            </Box>

            {openErrorPopup ? (
                <MyEirErrorHandle
                    open={openErrorPopup}
                    setOpen={() => {
                        setOpenErrorPopup(false);
                        handleClose(() => setStep(0));
                    }}
                />
            ) : (
                ''
            )}
        </Box>
    );
};

export default ReschedulePortModal;
