import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { useBillDetails } from '../css/useBillDetails';
import PDFlogo from '../images/pdf.png';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { MobileContext } from '../global-context';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import PaymentFlowModal from '../payment-flow/PaymentFlowModal';
import { saveAs } from 'file-saver';
import EirButton from '../../theme/EirButton';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

function BillDetails(props) {
    const classes = useBillDetails();
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const graphql = useApolloClient();
    const [isopen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isopen);
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
    const { state, dispatch } = useContext(MobileContext);
    const [error, setError] = useState(false);
    const [accountNum, setAccountNum] = useState();
    const [issueDate, setIssueDate] = useState('NA');
    const [recurringCharges, setRecurringCharges] = useState('NA');
    const [oneOff, setOneOff] = useState('NA');
    const [usageCharges, setUsageCharges] = useState('NA');
    const [totalBill, setTotalBill] = useState('NA');
    const [hidePDF, setHidePDF] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);

    useEffect(() => {
        setAccountNum(sessionStorage.getItem('LoggedinUserAccountNumber'));
        setIssueDate(props.invoiceDate);
        recurringAm();
    }, []);

    const recurringAm = async () => {
        try {
            const res = await graphql.query({
                query: ENDPOINT.GET_MY_BILL_DETAILS,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    invoiceId: `${props.invoiceNumber}`,
                    billingAccountId: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });
            if (
                res.data.getMyBillDetails &&
                res.data.getMyBillDetails.bills != null &&
                res.data.getMyBillDetails.bills.length > 0
            ) {
                setRecurringCharges(res.data.getMyBillDetails.bills[0].billAmount.recurringAmount);
                setOneOff(res.data.getMyBillDetails.bills[0].billAmount.oneOffAmount);
                setUsageCharges(res.data.getMyBillDetails.bills[0].billAmount.usageAmount);
                setTotalBill(
                    res.data.getMyBillDetails.bills[0].billAmount.invoiceAmount //invoice amount includes VAT amount
                );

                setError(false);
            }
        } catch (err) {
            setError(true);
        }
    };

    async function downloadInvoicePDF(invoiceNum) {
        try {
            setHidePDF(true);
            const response = await graphql.query({
                query: ENDPOINT.GET_INVOICE_PDF,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    invoiceNumber: parseFloat(`${invoiceNum}`),
                    accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });

            const data = await response.data;
            console.log(data);
            //decoding and saving here
            const blob = await base64ToBlob(data.getInvoicePDF);
            saveAs(blob, `${invoiceNum}.pdf`);

            setHidePDF(false);
        } catch (error) {
            setHidePDF(false);
            console.error('Error while fetching invoice PDF', error);
        }
    }

    function base64ToBlob(base64, type = 'application/octet-stream') {
        return new Promise((resolve, reject) => {
            try {
                const binStr = atob(base64);
                const len = binStr.length;
                const arr = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }
                resolve(new Blob([arr], { type: type }));
            } catch (err) {
                reject(err);
            }
        });
    }

    function convertDate(str) {
        const dateArr = str.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    }
    function totalPriceMonthandYear(str) {
        const dateArr = str.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${myMonth} ${dateArr[0]}`;
    }

    const openPayment = () => {
        if (isopen) {
            togglePayment();
            dispatch({ type: 'TOGGLE_PAYMENT_MODAL', payload: true });
        }
    };

    const togglePayment = () => {
        console.log('toggle payment');
        setPaymentModal(!paymentModal);
    };

    return (
        <>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            {/* <div className='billDetails' onClick={handleOpen}>See bill details</div> */}
            <div className={'billDetails' + (isopen ? ' open' : '')} onClick={toggle}>
                See bill details
            </div>
            <Modal
                className={classes.billDetailsModal}
                isOpen={isopen}
                toggle={toggle}
                modalTransition={{ timeout: 300 }}
                wrapClassName={classes.WrapBillDetails}
                modalClassName={classes.billDetailsmodal}
            >
                <ModalBody className={classes.wrapperstyle}>
                    {/* <ArrowBackIcon style={{ color: theme.palette.primary.sunset }} /> */}
                    <ArrowBackIcon className={classes.BackIcon} onClick={toggle} />
                    {/* <img className={classes.BackIcon} src={Back} alt="back icon"></img> */}
                    {/* modal-title */}
                    <div className={classes.billAttribute}>
                        <div className={classes.modalTitle}>
                            <div className={classes.billDetails}>Bill details</div>
                            <div className={classes.billDetailImage}>
                                <img
                                    className={classes.PDFIcon + (hidePDF ? ' hide' : ' ')}
                                    src={PDFlogo}
                                    alt='pdf icon'
                                    onClick={() => {
                                        downloadInvoicePDF(props.invoiceNumber);
                                    }}
                                ></img>
                            </div>
                        </div>
                    </div>

                    {/* modal-description */}
                    <div className={classes.modalDescription}>
                        <Grid container>
                            <Grid item className={classes.topGrid} sm={12} xs={12} container>
                                <Grid item className={classes.title} sm={6} xs={6}>
                                    Account number
                                </Grid>
                                <Grid item className={classes.value} sm={6} xs={6}>
                                    {accountNum}
                                </Grid>
                            </Grid>

                            <Grid item className={classes.topGrid} sm={12} xs={12} container>
                                <Grid item className={classes.title} sm={6} xs={6}>
                                    Issued on
                                </Grid>
                                <Grid item className={classes.valueBold} sm={6} xs={6}>
                                    {convertDate(issueDate)}
                                </Grid>
                            </Grid>

                            <Grid item className={classes.topGrid} sm={12} xs={12} container>
                                <Grid item className={classes.title} sm={6} xs={6}>
                                    Amount
                                </Grid>
                                <Grid item className={classes.value} sm={6} xs={6}>
                                    {!isNaN(totalBill) && <> €{(totalBill / 100).toFixed(2)} </>}
                                </Grid>
                            </Grid>

                            {!isPaymentCompleted &&
                                props.index === 0 &&
                                (JSON.parse(sessionStorage.getItem('myBalance')) / 100).toFixed(2) <
                                    0 && (
                                    <Grid
                                        item
                                        className={classes.topGrid}
                                        sm={12}
                                        xs={12}
                                        container
                                    >
                                        <Grid item className={classes.titleRed} sm={12} xs={12}>
                                            Direct debit due on {convertDate(props.dueDate)}
                                        </Grid>
                                    </Grid>
                                )}
                            {!isPaymentCompleted &&
                                props.index === 0 &&
                                (JSON.parse(sessionStorage.getItem('myBalance')) / 100).toFixed(2) <
                                    0 && (
                                    <div className={classes.makePayment}>
                                        <EirButton
                                            style={{ width: '11rem' }}
                                            variants='primaryButtonMedium'
                                            onClick={openPayment}
                                        >
                                            Make a payment
                                        </EirButton>
                                    </div>
                                )}

                            <Grid
                                item
                                className={classes.descriptionAttributeComplete}
                                sm={12}
                                xs={12}
                                container
                            >
                                <Grid item className={classes.titleBold} sm={6} xs={6}>
                                    Recurring charges
                                </Grid>
                                <Grid item className={classes.valueAttribute} sm={6} xs={6}>
                                    {!isNaN(recurringCharges) && (
                                        <> €{(recurringCharges / 100).toFixed(2)} </>
                                    )}
                                </Grid>
                                <Grid item className={classes.subAttribute} sm={12} xs={8}>
                                    Includes monthly fees, add ons and part period charges if
                                    applicable
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                className={classes.descriptionAttribute}
                                sm={12}
                                xs={12}
                                container
                            >
                                <Grid item className={classes.titleBold} sm={6} xs={6}>
                                    One off charges
                                </Grid>
                                <Grid item className={classes.valueAttribute} sm={6} xs={6}>
                                    {!isNaN(oneOff) && <> €{(oneOff / 100).toFixed(2)} </>}
                                </Grid>
                                <Grid item className={classes.subAttribute} sm={12} xs={12}>
                                    Includes one off add ons
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                className={classes.descriptionAttributeUsage}
                                sm={12}
                                xs={12}
                                container
                            >
                                <Grid item className={classes.titleBold} sm={6} xs={6}>
                                    Usage charges
                                </Grid>
                                <Grid item className={classes.valueAttribute} sm={6} xs={6}>
                                    {!isNaN(usageCharges) && (
                                        <> €{(usageCharges / 100).toFixed(2)} </>
                                    )}
                                </Grid>
                                <Grid item className={classes.subAttribute} sm={12} xs={12}>
                                    Out of bundle charges
                                </Grid>
                            </Grid>

                            <Grid item className={classes.topAttribute} sm={12} xs={12} container>
                                <Grid item className={classes.totalBold} sm={6} xs>
                                    Total for {totalPriceMonthandYear(issueDate)} bill
                                </Grid>
                                <Grid item className={classes.totalValue} sm={6} xs={3}>
                                    {!isNaN(totalBill) && <> €{(totalBill / 100).toFixed(2)} </>}
                                </Grid>
                                <Grid item className={classes.vatValue} sm={12} xs={12}>
                                    VAT included
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <PaymentFlowModal
                        amount={Math.abs(
                            JSON.parse(sessionStorage.getItem('myBalance')) / 100
                        ).toFixed(2)}
                        open={paymentModal}
                        toggle={togglePayment}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}

export default BillDetails;
