import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import ImageContainer from './ImageContainer';
import KeepOldNum from '../images/KeepOldNum.png';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme/MyEirTheme';

const PortingCheck = ({ handleCTA, setStep, setOpenModal, phoneNumber }) => {
    useEffect(() => {
        handleCTA({
            primary: true,
            cta: { text: 'Confirm', action: () => setStep(1), disable: false },
        });
        handleCTA({ cta: { text: 'Cancel', action: () => setOpenModal(false) } });
    }, []);

    const useStyles = makeStyles(theme => ({
        modalBodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '1rem',
        },

        modalImage: {
            display: 'flex',
            justifyContent: 'center',
            padding: '1.5rem 3.5rem 2rem 3.5rem',

            '@media(max-width: 599px)': {
                padding: '3.5rem 4.5rem 2.3rem 4.5rem',
            },
        },

        modalClass: {
            fontFamily: theme.typography.h5.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: '20px',
            lineHeight: '26px',
            /* or 130% */
            textAlign: 'center',
            maxWidth: '230px',
            margin: 'auto',
            color: theme.palette.primary.heather,
        },
        modalContent: {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            /* or 150% */
            textAlign: ' center',
            maxWidth: '262px',
            margin: 'auto',
            color: theme.palette.primary.heather,
            marginTop: '1rem',
        },
        numBold: {
            fontWeight: 'bold',
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            display: 'inline',
            color: theme.palette.primary.heather,
        },
    }));

    const styles = useStyles();

    return (
        <React.Fragment>
            <div className={styles.modalBodyContainer}>
                <div className={styles.modalImage}>
                    <ImageContainer img={KeepOldNum} />
                </div>

                <div className={styles.modalClass}>
                    <p>Do you want to move your old number to eir?</p>
                </div>

                <Typography className={styles.modalContent}>
                    This move will replace your existing eir number
                    <Box className={styles.numBold}>
                        {' '}
                        {phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
                    </Box>
                </Typography>
            </div>
        </React.Fragment>
    );
};

export default PortingCheck;
