import { Box, Typography } from '@material-ui/core';
import React from 'react';
import DeviceDetailsActive from './DeviceDetailsActive';
import DeviceDetailsInactive from './DeviceDetailsInactive';
import { styles } from '../styles';

const DeviceDetails = ({
    active,
    deviceSequence,
    deviceId,
    deviceName,
    deviceActivationDate,
    deviceLastUsedDate,
    setDeleteDeviceData,
    editDevice,
    renameDevice,
    toggleDelete,
}) => {
    const classes = styles();

    return (
        <Box className={classes.deviceManagementDevice}>
            <Box className={classes.deviceManagementDeviceNumberBox}>
                <Typography className={classes.deviceManagementDeviceNumber}>
                    {deviceSequence}
                </Typography>
            </Box>
            {active ? (
                <DeviceDetailsActive
                    deviceId={deviceId}
                    deviceName={deviceName}
                    deviceActivationDate={deviceActivationDate}
                    deviceLastUsedDate={deviceLastUsedDate}
                    setDeleteDeviceData={setDeleteDeviceData}
                    editDevice={editDevice}
                    renameDevice={renameDevice}
                    toggleDelete={toggleDelete}
                />
            ) : (
                <DeviceDetailsInactive />
            )}
        </Box>
    );
};

export default DeviceDetails;
