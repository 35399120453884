import React, { useEffect, useState, useContext, useRef } from 'react';
import { useDefaultMethodStyle } from '../css/useDefaultMethodStyle';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import { Button } from 'reactstrap';
import { Modal, ModalBody } from 'reactstrap';
import Loading from '../../components/loading/Loading';
import PaymentCardMethodHeader from './PaymentCardMethodHeader';
import { usePaymentCardMethodStyles } from '../css/usePaymentCardMethodStyles';
import Grid from '@material-ui/core/Grid';
import { RealexHpp } from '../../utils/realexPayments';

function ChooseDefaultMethod(props) {
    const graphql = useApolloClient();
    const [isLoading, setIsLoading] = useState(false);
    const addCardButtonRef = useRef(null);
    const add_card_token = sessionStorage.getItem('access_token');
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({ loading: false });

    const styleClasses = usePaymentCardMethodStyles();

    const [error, setError] = useState({
        iframe: null,
        payment: null,
    });

    const handleSepaClick = () => {
        props.handleNext(3);
    };

    const handleClick = () => {
        props.handleNext();
    };

    const handleSetupDDClick=()=>{
        props.handleNext(4);
    }

    const handleBack = () => {
        console.log('handleback default method');
    };

    const classes = useDefaultMethodStyle();

    return (
        <React.Fragment>
            {/* <Modal
                modalClassName={styleClasses.modalClassName}
                wrapClassName={styleClasses.modalWrapper}
                isOpen={props.isOpen2}
                toggle={props.toggle2}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <PaymentCardMethodHeader
                    paymentHeaderText='Payment details'
                    handleBack={handleBack}
                    center={false}
                /> */}
            <ModalBody className={classes.paymentMethod}>
                <Grid container className={classes.defaultMethodParent}>
                    <div className={classes.chooseDefaultMethodTitle}>
                        Choose new default method
                    </div>
                    <Grid container className={classes.defaultMethodOptions}>
                        <Grid container className={classes.directDebitParent} sm={12} xs={12}>
                            <Grid item className={classes.debitIcon} xs={1} />
                            <Grid item className={classes.directDebitPlusMethod} xs={10}>
                                <Grid item className={classes.methodName} onClick={handleSetupDDClick}>
                                    Direct Debit
                                </Grid>
                                <Grid item className={classes.setupMethodName} onClick={handleSetupDDClick}>
                                    Setup a new Direct Debit Account
                                </Grid>
                            </Grid>
                            <Grid item className={classes.arrowIcon} xs={1} />
                        </Grid>
                        <Grid container className={classes.directDebitParent} sm={12} xs={12}>
                            <Grid item className={classes.creditIcon} xs={2} />
                            <Grid item className={classes.directDebitPlusMethod} xs={10}>
                                <Grid item className={classes.methodName} onClick={handleClick}>
                                    Credit Card
                                </Grid>
                                <Grid
                                    item
                                    className={classes.setupMethodName}
                                    onClick={handleClick}
                                >
                                    Pay with Mastercard or Visa
                                </Grid>
                            </Grid>
                            <Grid item className={classes.arrowIcon} xs={1} />
                        </Grid>
                        <Grid container className={classes.directDebitParent} sm={12} xs={12}>
                            <Grid item className={classes.sepaIcon} xs={1} />
                            <Grid item className={classes.directDebitPlusMethod} xs={10}>
                                <Grid item className={classes.methodName} onClick={handleSepaClick}>
                                    International SEPA Mandate
                                </Grid>
                                <Grid
                                    item
                                    className={classes.setupMethodName}
                                    onClick={handleSepaClick}
                                >
                                    Pay with a foreign account
                                </Grid>
                            </Grid>
                            <Grid item className={classes.arrowIcon} xs={1} onClick={handleClick} />
                        </Grid>
                    </Grid>
                </Grid>
            </ModalBody>
            {/* </Modal> */}
        </React.Fragment>
    );
}
export default ChooseDefaultMethod;
