import { makeStyles } from '@material-ui/core/styles';
export const useAlreadyRegistered = makeStyles(theme => ({
    alreadyRegisteredWrapper: {
        padding: '0px',
    },

    alreadyRegistredMain: {
        marginLeft: 'auto',
        marginRight: 'auto',
        // marginTop: '140px',
        // padding: '0 30px',
        maxWidth: '760px',

        '@media(max-width: 1019px)': {
            padding: '0 99px',
            // maxWidth: '858px',
        },
        '@media(max-width: 599px)': {
            // maxWidth: '344px',
            marginTop: '8px',
            padding: '0 8px',
            height: '100%',
            // marginLeft: '16px',
        },
    },

    alreadyRegistredMainWrapper: {
        background: 'white',
    },

    alreadyRegistredMainModal: {
        background: theme.palette.primary.gray550,
        backdropFilter: 'blur(4.5px)',
    },

    alreadyRegistredMainBackDrop: {},

    alreadyRegistredMainContent: {
        border: 'none',
        borderRadius: '10px',

        '@media(max-width: 599px)': {
            height: '100%',
        },

        '@media (min-width: 600px) and (max-width: 1019px)': {
            width: '560px',
        },
    },

    alreadyRegistredModalBody: {
        minHeight: '760px',
        padding: '24px 32px',

        '@media(max-width: 599px)': {
            padding: '16px 16px 16px 16px',
        },
    },

    registerTitleContainer: {
        padding: '51px 32px 0 32px',
        textAlign: 'center',

        '@media (max-width: 599px)': {
            padding: '50px 28px 0 28px',
        },
    },

    registerPhone: {
        paddingBottom: '14px',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            paddingBottom: '13px',
        },
    },

    registerTitle: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            display: 'none',
        },
    },

    registerTitleMobile: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,
        '@media (min-width: 600px) and (max-width: 1019px)': {
            fontSize: theme.typography.h4.fontSize,
        },
        '@media (min-width: 1019px)': {
            display: 'none',
        },
    },

    alreadyRegisteredBody: {
        width: '60%',
        margin: '0 auto',
        paddingTop: '3rem',
        textAlign: '-webkit-center',
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',

        '@media (max-width: 599px)': {
            width: '100%',
            paddingTop: '52px',
            justifyContent: 'center',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            width: '65%',
            paddingTop: '3rem',
            justifyContent: 'center',
        },
    },

    tickWrapper: {
        maxWidth: '100%',
    },

    tickCircle: {},

    tick: {
        width: '64px',
    },

    newsTextWrapper: {
        marginTop: '1rem',
        marginBottom: '2rem',

        '@media (max-width: 599px)': {
            marginTop: '16px',
            marginBottom: '32px',
        },
    },

    newsText: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,
        '@media (min-width: 0px) and (max-width: 1019px)': {
            fontSize: theme.typography.h4.fontSize,
            // fontWeight: '700',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            fontSize: theme.typography.h5.fontSize,
            // fontWeight: '700',
        },
    },

    emailVerifiedWrapper: {},

    emailVerifiedText: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,

        '@media (min-width: 0px) and (max-width: 1019px)': {
            display: 'none',
        },
    },

    emailVerifiedTextMobile: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,

        '@media (min-width: 1019px)': {
            display: 'none',
        },
    },
    emailVerifiedUsername: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25.12px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        marginTop: '4px',
        '@media (max-width: 599px)': {
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },

    passwordInstructionWrapper: {
        marginTop: '8px',

        '@media (max-width:599px)': {
            marginTop: '7px',
        },
    },

    passwordInstructionPara: {
        maxWidth: '80%',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,

        '@media (max-width: 599px)': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: '600',
            fontSize: theme.typography.subtitle2.fontSize,
            maxWidth: '82%',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '363px',
        },
    },

    alreadyRegisteredFooter: {
        minHeight: '80px',
        marginTop: '3.5rem',
        marginBottom: '3.6rem',

        '@media (max-width: 599px)': {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            marginTop: '144px',
            paddingBottom: '33px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            // marginTop: '3.5rem',
            // paddingBottom: '3.6rem',
        },

        '& .footer-cta': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '184px',
            borderRadius: '4100px',
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.subtitle1.fontWeight,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight,
            textAlign: 'center',

            '@media (min-width:0px) and (max-width: 1019px)': {
                width: '264px',
            },
        },

        '& .primary-cta': {
            minHeight: '42px',
            borderColor: 'transparent',
            color: theme.palette.primary.white,
            background: theme.palette.primary.sunset,

            '&:hover': {
                background: theme.palette.primary.sunset30,
            },
        },

        '& .secondary-cta': {
            // minHeight: '41px',
            marginTop: '12px',
            color: theme.palette.primary.sunset,
        },
    },

    primaryCta: {
        minHeight: '42px',
        marginBottom: '3px',
        borderColor: 'transparent',
    },

    secondaryCta: {
        minHeight: '41px',
        color: theme.palette.primary.sunset,
    },
}));
