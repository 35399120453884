import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
    accordion: {
        margin: 0,
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        '&.Mui-expanded ': {
            margin: 0,
            // minHeight: 'none',
        },
        '&.Mui-disabled': {
            backgroundColor: 'white',
            fontWeight: '700',
            color: theme.palette.primary.gray550,
            '& .accordion-title ': {
                color: theme.palette.primary.heather,
            },
        },
        '&::before': {
            content: 'none',
        },
    },
    accordionScheduleMove: {
        marginBottom: '40px',
    },

    accordionTitle: {
        display: 'flex',
        fontSize: 18,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        // fontWeight: 700,
        fontFamily: theme.typography.subtitle1.fontFamily,
        lineHeight: '1.5rem',
        color: theme.palette.primary.heather,
        alignItems: 'center',
        marginBottom: '.3rem',
        padding: '1rem 0',
        '@media(max-width: 599px)': {
            fontSize: 14,
        },
        '& span': {
            fontWeight: 500,
            fontFamily: theme.typography.body3Medium.fontFamily,
            paddingRight: '.5rem',
        },
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content ': {
            margin: 0,
            '&.Mui-expanded ': {
                margin: 0,
                minHeight: 0,
            },
        },
    },

    dateTimecontainer: {
        padding: '0px 3rem 0px 3rem',
        '& .MuiGrid-root ': {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 1.875rem 0 1rem',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'unset',
        },
        '& .MuiInputAdornment-positionEnd': {
            margin: 0,
            paddingRight: 2,
        },
    },

    stepCompleted: {
        position: 'relative',
        '&::before': {
            content: '',
            postion: 'absolute',
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.67413 8.73427L1.18735 5.29175L0 6.45576L4.67413 11.0706L14.708 1.16402L13.5291 0L4.67413 8.73427Z' fill='%2343B02A'/%3E%3C/svg%3E")`,
            height: 11,
            width: 14,
        },
    },
    textContext: {
        color: theme.palette.primary.sunset,
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
    },
    textField: {
        margin: '0',
        marginRight: '1rem',
        marginBottom: '1rem',
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.heather80,
    },
    textFieldOtp: {
        marginBottom: '0rem',
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.heather80,
    },
    didntGetCode: {
        color: theme.palette.primary.heather80,
        fontSize: 14,
        // textAlign: 'right',
        // top: 204,
        // left: 428,
        // position: 'absolute',
        fontWeight: 500,
        fontFamily: theme.typography.body2.fontFamily,
        '@media(max-width: 599px)': {
            left: 33,
        },
        '& span': {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: theme.palette.primary.sunset,
            marginLeft: '8px',
        },
    },
    resendText: {
        color: theme.palette.primary.sunset,
        display: 'inline',
        fontSize: 14,
        fontWeight: 500,
        fontFamily: theme.typography.body3Medium.fontFamily,
        textDecoration: 'underline',
        cursor: 'pointer',

        '& span': {
            display: 'inline',
            fontSize: 12,
            fontWeight: 500,
            fontFamily: theme.typography.body3Medium.fontFamily,
        },
    },

    clockIcon: {
        fontSize: '1rem',
        color: theme.palette.primary.sunset,
    },
}));

export default useStyle;
