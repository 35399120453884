import React, { useState, useEffect, Fragment } from 'react';
import { useMyEirFooterStyle } from './css/useFooterStyle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FooterMobileAccordion from './FooterMobileAccordion';
import { List, ListItem, ListItemText, Grid, Container, Hidden, Box } from '@material-ui/core';

function MyEirFooterMenu(props) {
    const [footerMenuItems, setfooterMenuItems] = useState(props.footerMenuData);
    // const [footerDisclaimer, setFooterDisclaimer] = useState('');

    const classes = useMyEirFooterStyle();
    useEffect(() => {
        setfooterMenuItems(props.footerMenuData);
        // setFooterDisclaimer(disclaimer)
    }, [props.footerMenuData]);
    const renderContent = content => {
        return { __html: content };
    };
    const scrollBackToTop = () => {
        let scrollStep = -window.pageYOffset / (1000 / 15);
        let scrollInterval = setInterval(function () {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            } else clearInterval(scrollInterval);
        }, 15);
    };
    const theme = createMuiTheme({
        // typography: {
        //     fontFamily: 'eir-medium',
        // },
        //Overriding default breakpoints of material UI with custom breakpoints.
        breakpoints: {
            values: {
                xs: 0,
                sm: 599,
                md: 600,
                lg: 1019,
                xl: 1020,
            },
        },
    });
    var disclaimer = props.footerMenuData.disclaimer
        ? props.footerMenuData.disclaimer.replace(
              '${--var-disclaimer-year}',
              new Date().getFullYear()
          )
        : '';
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <footer className={classes.footer}>
                    {/* scroll to top */}
                    {/* <Hidden xsUp> */}
                    <Box className='scroll-back-to-top'>
                        <span className='eir-icon eir-icon--sm icon-arrowup--black mr-1' />
                        <button onClick={scrollBackToTop} className='a-no-style'>
                            Back to the top
                        </button>
                    </Box>
                    {/* </Hidden> */}

                    {/* DT Primary Links */}
                    {/* this needs to be hidden in tab and mobile view mdDown */}
                    <Hidden lgDown={theme.breakpoints.down('lg')}>
                        <Box component='div' className='footer-primary-links'>
                            <Grid container>
                                {footerMenuItems.primaryNav !== undefined &&
                                    footerMenuItems.primaryNav.children.map((item, index) => {
                                        return (
                                            <Grid item key={index} className='primary-link-item'>
                                                <List className='footer-menu'>
                                                    <ListItem
                                                        key={index}
                                                        className='footer-list list-first footer-title'
                                                    >
                                                        <ListItemText
                                                            className='titleText'
                                                            primary={item.title}
                                                        />
                                                    </ListItem>
                                                    {item.children.map((navItem, index) => {
                                                        return (
                                                            <ListItem
                                                                key={index}
                                                                className='footer-list'
                                                                // component='a'
                                                                href={navItem.link.link}
                                                            >
                                                                <ListItemText
                                                                    className='titleText'
                                                                    primary={navItem.link.title}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                            {/* </Row> */}
                        </Box>
                    </Hidden>

                    {/* mobile Primary Links Accordian*/}
                    {/* this is required in mobile and tablet so lgUp is used */}
                    <Hidden xlUp={theme.breakpoints.up('xl')}>
                        <Box className='footer-mb-primary-links'>
                            {footerMenuItems.primaryNav !== undefined &&
                                footerMenuItems.primaryNav.children.map((item, index) => {
                                    return (
                                        <FooterMobileAccordion
                                            title={item.title}
                                            key={index}
                                            children={item.children}
                                        />
                                    );
                                })}
                        </Box>
                    </Hidden>

                    {/* Social Links */}
                    <Box className='footer-social-links'>
                        {footerMenuItems.socialNav !== undefined &&
                            footerMenuItems.socialNav.children.map((navItem, index) => {
                                return (
                                    <div className={'footer-icon ' + navItem.icon + '-icon'}>
                                        <a
                                            title={navItem.link.title}
                                            target={navItem.link.target}
                                            href={navItem.link.link}
                                        >
                                            {navItem.link.text}
                                        </a>
                                    </div>
                                );
                            })}
                    </Box>

                    {/* Secondary links */}
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12}>
                                <ul className='list-inline footer-secondary-links'>
                                    {footerMenuItems.secondaryNav !== undefined &&
                                        footerMenuItems.secondaryNav.children.map(
                                            (navItem, index) => {
                                                return (
                                                    <li
                                                        className='list-inline-item'
                                                        key={'list-inline-item' + index}
                                                    >
                                                        <a
                                                            title={navItem.link.title}
                                                            target={navItem.link.target}
                                                            href={navItem.link.link}
                                                            className={
                                                                'eir-icon icon-' +
                                                                navItem.icon +
                                                                '--black'
                                                            }
                                                        >
                                                            {navItem.link.text}
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </Grid>
                        </Grid>
                    </Container>

                    {/* Cookie Button */}
                    <Box className='footer-cookie-btn'>
                        <p
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <button id='ot-sdk-btn' className='ot-sdk-show-settings' style={{}}>
                                Cookies Settings
                            </button>
                        </p>
                    </Box>

                    {/* Disclaimer */}
                    <Box className='footer-disclaimer'>
                        {footerMenuItems.disclaimer !== undefined && (
                            <Box
                                className='footer-disclaimer-content'
                                dangerouslySetInnerHTML={renderContent(disclaimer)}
                            ></Box>
                        )}
                    </Box>
                </footer>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirFooterMenu;
