import { makeStyles } from '@material-ui/core/styles';

export const commonRegisterStyles = makeStyles(theme => ({
    registrationContainer: {
        maxWidth: '754px',
        marginTop: '40px',
        marginBottom: '120px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px 30px',

        '@media(max-width: 599px)': {
            padding: '0px 24px',
            width: '100%',
            marginTop: '48px',
            marginBottom: '0px',
        },

        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: theme.palette.primary.heather,
        },
    },
    cardContainer: {
        width: '100%',
        height: '100%',
        paddingLeft: '32px',
        paddingRight: '30px',
        paddingTop: '24px',
        paddingBottom: '24px',

        background: theme.palette.primary.white,
        boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        borderRadius: '10px',

        '@media(max-width: 1019px)': {
            maxWidth: '570px',
            margin: '0 auto',
        },

        '@media(max-width: 599px)': {
            background: theme.palette.primary.white,
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
            borderRadius: '10px 10px 0px 0px',

            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '17.5px',
            paddingBottom: '17.5px',

            maxWidth: 'calc(312 / 360 * 100vw)',
            margin: '0 auto',
        },
    },
    headerContainer: {
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

        '& .register-header-back-icon-div': {
            display: 'flex',
            marginLeft: '-4px',
            justifyContent: 'flex-start',

            width: '100%',
            position: 'relative',

            '@media(max-width: 599px)': {
                marginLeft: '-4px',
            },
        },

        '& .register-header-back-icon': {
            color: theme.palette.primary.sunset,
            cursor: 'pointer',

            '@media(max-width: 599px)': {
                height: '20px',
                width: '20px',
            },
        },

        '& .register-header-text': {
            display: 'flex',
            marginTop: '19px',
            minHeight: '43px',
            // fontFamily: "eir-medium",
            // fontStyle: "normal",
            // // fontWeight: 700,
            // fontSize: "24px",
            // lineHeight: "26px",
            // color: "#892BA0",
            color: theme.palette.primary.heather,

            position: 'relative',

            '@media(max-width: 599px)': {
                paddingLeft: '8px',
                marginTop: '13.5px',
                minHeight: '44px',
                // fontSize:theme.typography.h4.fontSize,
            },

            '& .register-header-line': {
                position: 'absolute',
                left: '0%',
                right: '0%',
                bottom: '3px',
                border: `1px solid ${theme.palette.primary.heather80}`,
                borderTopWidth: '0px',

                '@media(max-width: 599px)': {
                    bottom: '0px',
                },
            },
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },
    footerContainer: {
        textAlign: 'center',
        minHeight: '99px',
        '@media(max-width: 599px)': {
            minHeight: '94.5px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },

        '& .footer-cta': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '183px',

            // borderRadius: '4100px',

            fontFamily: theme.typography.subtitle1.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.subtitle1.fontWeight,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight,
            textAlign: 'center',

            '@media(max-width: 599px)': {
                width: '100%',
            },
        },

        '& .primary-cta': {
            minHeight: '42px',
            // marginBottom: "3px",
            borderColor: 'transparent',
        },

        '& .secondary-cta': {
            minHeight: '41px',
            color: theme.palette.primary.sunset,

            '&:hover': {
                color: theme.palette.primary.sunset,
            },
        },

        '& .cta-diabled': {
            // background: '#ECECEC',
            color: theme.palette.primary.gray550,

            '@media(max-width: 599px)': {
                minHeight: '41px',
                // marginBottom: "4px",
            },
        },

        '& .cta-enabled': {
            background: theme.palette.primary.sunset,
            boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
            color: theme.palette.primary.white,

            '&:hover': {
                // background: '#B10071',
            },

            '@media(max-width: 599px)': {
                minHeight: '41px',
                // marginBottom: "5px",
            },
        },
    },
    footerContainer1: {
        '@media(min-width: 600px)': {
            minHeight: '56px',
        },
    },
    footerContainerNone: {
        '@media(min-width: 600px)': {
            minHeight: '0px',
            marginTop: '-16px',
        },
    },
}));
