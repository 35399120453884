import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme/MyEirTheme';

export const usePrepayFormStyle = makeStyles(() => ({
    textField: {
        '& label.MuiFormLabel-root': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,

            //  '& [theme.breakpoints.down('xs')]': {
            //     fontSize:theme.typography.inputLabel.fontSize,
            //     lineHeight: '12px',
            // },
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.heather,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.heather,
        },
        '& .MuiOutlinedInput-root': {
            padding: '0',
            '& input:-webkit-autofill': {
                backgroundColor: `${theme.palette.primary.white} !important`,
            },
            '& .MuiInputBase-input': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                height: '20px',
                padding: '16px 12px',
                fontFamily: theme.typography.body2.fontFamily,
            },
            '& fieldset': {
                borderColor: theme.palette.primary.heather,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.heather,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.heather,
            },
        },
        // '& .MuiDialogContent-root': {
        //     '& .MuiPickersDatePickerRoot-toolbar': {
        //         backgroundColor: 'red !important',
        //     },
        // },
        // '& .MuiButton-textPrimary': {
        //     color: 'red',
        // },

        '& .MuiToolbar-root': {
            backgroundColor: 'red !important',
        },
    },

    '& .MuiToolbar-root': {
        backgroundColor: 'red !important',
    },

    '& .MuiPickersToolbar-toolbar': {
        backgroundColor: 'red !important',
    },

    marketingCheckboxColor: {
        '& .Mui-che`cked': {
            color: `${theme.palette.primary.heather} !important`,
        },
    },

    datePicker: {
        color: 'red',
        headerColor: 'green',
        selectColor: 'yellow',
        selectTextColor: 'yellow',
    },

    menuSecurityItem: {
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        lineHeight: '24px',
    },
    logoField: {
        color: theme.palette.primary.heather,
        marginRight: '6px',
    },

    iconUsage: {
        color: theme.palette.primary.sunset,
        marginRight: '11px',
    },
    iconUsageTwo: {
        color: theme.palette.primary.sunset,
        paddingTop: '5px',
        // left: '19rem',
    },

    prepayFormAddress: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px ',
            paddingRight: '0px',
        },
    },
    prepayFormMarketing: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '7px !important ',
            paddingRight: '7px !important',
        },
    },
    prepayEircode: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '24px !important ',
            paddingRight: '24px !important',
        },
    },
    prepayFormBody: {
        paddingLeft: '72px',
        paddingRight: '72px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0',
            paddingRight: '0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    customerDetailsHeader: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: '48px',
        marginBottom: '36px',
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0',
            paddingRight: '0',
            marginTop: '26px',
            marginBottom: '26px',
        },
    },
    customerDetailsLabel: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '12px',
        alignItems: 'center',
        display: 'flex',
        color: theme.palette.primary.heather,
    },

    addressDetailsHeader: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: '48px',
        marginBottom: '16px',
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            marginTop: '36px',
            marginBottom: '16px',
        },
    },
    addressFinderContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px',
    },

    yourAddressContainer: {
        color: 'red',
    },
    eirCodeLink: {
        color: 'none',
        textDecoration: 'none',
        backgroundColor: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    addressFinderButton: {
        padding: '6px 24px 5px 24px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        color: theme.palette.primary.white,
        borderRadius: '4100px',
        border: `3px solid ${theme.palette.primary.sunset30}`,
        boxSizing: 'border-box',
        textTransform: 'none',
        marginRight: '32px',

        '&:hover': {
            background: `${theme.palette.primary.sunset30} !important`,
            color: `${theme.palette.primary.white} !important`,
            textDecoration: 'none',
        },

        [theme.breakpoints.down('xs')]: {
            // padding: '8px 62px 8px 62px',
            marginRight: '0',
            marginBottom: '20px',
            width: '94%',
        },
    },
    addressConfirmButton: {
        padding: '8px 24px 8px 24px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        // border: '3px solid #D111C9',
        lineHeight: '24px',
        textAlign: 'center',
        borderRadius: '4100px',
        backgroundColor: `${theme.palette.primary.sunset} !important`,
        color: `${theme.palette.primary.white} !important`,
        textTransform: 'none',

        '&:hover': {
            background: `${theme.palette.primary.sunset30} !important`,
            // border: "3px solid #B10071",
        },

        [theme.breakpoints.down('xs')]: {
            // padding: '8px 70px 8px 70px',
            width: '94%',
        },
    },
    sameAddressDetails: {
        display: 'none',
    },
    securityDetailsHeader: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: '28px',
        marginBottom: '26px',
        color: theme.palette.primary.heather,
    },
    marketingPreferencesHeader: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: '47px',
        marginBottom: '26px',
        color: theme.palette.primary.heather,

        [theme.breakpoints.down('xs')]: {
            marginTop: '36px',
        },
    },
    marketingPreferencesCopy: {
        textAlign: 'left',
        marginBottom: '0',
    },
    errorMessage: {
        width: '100%',
        fontFamily: theme.typography.body2.fontFamily,
        textAlign: 'left',
        marginTop: '.25rem',
        fontSize: theme.typography.inputLabel.fontSize,
        fontWeight: '400',
        letterSpacing: '0.4px',
        color: theme.palette.primary.error,
        display: 'block',
        marginBottom: '0',
    },

    marketingPreferencesHeader: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: '47px',
        marginBottom: '26px',
        color: theme.palette.primary.heather,
    },
    marketingPreferencesPrepayCopy: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '20px',
        textAlign: 'left',
        color: theme.palette.primary.heather,
        width: '101%',
        marginBottom: '0',

        [theme.breakpoints.down('xs')]: {
            width: '97%',
        },
    },
    marketingPreferencesCopy: {
        textAlign: 'left',
        marginBottom: '0',
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 'bold',
    },
    /***********Marketing Preferences **********/

    prefernceRow: {
        backgroundColor: theme.palette.primary.gray250,
        minHeight: 42,
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontSize: '1rem',
    },

    tableRow: {
        backgroundColor: theme.palette.primary.gray250,
        minHeight: 42,
    },
    tableHeader: {
        minHeight: 40,
        fontFamily: theme.typography.body3Medium.fontFamily,
    },

    contactGroupNames: {
        marginLeft: theme.spacing(1),
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
    formLabelColor: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        paddingTop: '5px',
        paddingLeft: '15px',
        marginBottom: '0',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '9px',
            fontSize: theme.typography.body1.fontSize,
        },
    },
    preferenceName: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '24px',
        display: 'flex',
        color: theme.palette.primary.heather,
        paddingTop: '5px',
        paddingLeft: '15px',
        marginBottom: '0',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '9px',
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
    prefName: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '24px',
        display: 'flex',
        color: theme.palette.primary.heather,
        paddingTop: '10px',
        paddingLeft: '10px',
        marginBottom: '0',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
        },
    },
    prefernceHeader: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 'normal',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        display: 'flex',
        color: theme.palette.primary.heather,
        paddingLeft: '16px !important',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '4px !important',
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
    preferenceContainer: {
        position: 'relative',
        left: '2rem',
        paddingTop: '4px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-14px',
        },
    },
    marginleft: {
        marginLeft: theme.spacing(1),
    },
    prepayButton: {
        padding: '8px 60.5px 8px 60.5px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        borderRadius: '4100px',
        backgroundColor: `${theme.palette.primary.sunset} !important`,
        color: `${theme.palette.primary.white}  !important`,
        textTransform: 'none',
        marginBottom: '38px',
        marginTop: '66px',

        '&:hover': {
            background: `${theme.palette.primary.sunset} !important`,
        },

        [theme.breakpoints.down('xs')]: {
            marginBottom: '52px',
            marginTop: '54px',
            // padding: '8px 101.5px 8px 101.5px',
            width: '95%',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            marginBottom: '38px',
            marginTop: '66px',
        },
    },
}));
