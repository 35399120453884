import React, { Fragment, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import AuthorisedUserInfo from './AuthorisedUserInfo';
import { authorisedUserStyle } from './authorisedUserStyle';

const MyEirDeleteAuthorisedUsers = ({ deleteUser, setdisableCTA }) => {
    const classes = authorisedUserStyle();

    useEffect(() => {
        setdisableCTA(false);
    });

    return (
        <Fragment>
            <Box className={classes.deleteContainer}>
                <AuthorisedUserInfo user={deleteUser} />
            </Box>

            <Typography className={classes.deleteConfirmText}>
                Are you sure you want to delete this authorised user?
            </Typography>
        </Fragment>
    );
};

export default MyEirDeleteAuthorisedUsers;
