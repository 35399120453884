import React, { Fragment, useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CustomTextField from '../../sharedcomponents/inputs/text-field/CustomTextField';
import useStyles from './style';
import errormesages from './errorsCopy';
import { useApolloClient } from '@apollo/client';
import { getAddressList } from './replacement.util';
import { formatCounty } from '../addressFormat';
import SimAddressForm from './SimAddressForm';
import SimBlockMyService from './SimBlockMyService';
import EirButton from '../../theme/EirButton';

const SimEircode = ({
    ContactDetails,
    register,
    control,
    errors,
    setValue,
    submitFormAction,
    submitFormData,
    setSubmitFormData,
    setIsEnabled,
    setIsTicked,
    reorderForm,
    flag,
}) => {
    const classes = useStyles();

    const { validation, errormessage } = errormesages;
    const [defaultValue, setDefaultValue] = useState('');
    const graphql = useApolloClient();
    const [addressList, setAddressList] = useState([]);
    const [singleAddress, setSingleAddress] = useState([]);
    const [addressData, setAddressData] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [eirCode, setEirCode] = useState('');
    const [eirCodeEmpty, setEirCodeEmpty] = useState(false);
    const [pasteVal, setPasteVal] = useState(false);

    const handlePaste = () => {
        setPasteVal(true);
    };

    const handleEirCodeChange = event => {
        setSingleAddress([]);
        setAddressList([]);
        setAddressData(null);

        if (/^[a-zA-Z0-9]+$/.test(event.target.value)) {
            setIsEnabled(false);
            setEirCodeEmpty(false);
            setEirCode(event.target.value);
            if (!pasteVal) {
                setDefaultValue(event.target.value);
                setEirCodeEmpty(true);
            } else {
                setEirCodeEmpty(false);
                setEirCode(event.target.value);
            }
            if (event.target.value.length == 7) {
                setEirCodeEmpty(!eirCodeEmpty);
                setDefaultValue(event.target.value);
            } else {
                setEirCodeEmpty(false);
            }
        }
    };

    const getAddresses = async value => {
        try {
            const data = await getAddressList({ graphql, eirCode: value });
            if (data.length == 0) {
                setAddressData(null);
                setIsConfirm(true);
                setSubmitFormData({
                    address: {
                        addressLine1: '',
                        addressLine2: '',
                        addressLine3: '',
                        town: '',
                        county: '',
                    },
                    email: '',
                    phoneNum: '',
                    firstName: '',
                    lastName: '',
                });
                setIsEnabled(false);
            } else if (data.length == 1) {
                submitFormAction({
                    address: {
                        addressLine1: data[0]?.addressLine1,
                        addressLine2: data[0]?.addressLine2,
                        addressLine3: data[0]?.addressLine3,
                        code: data[0]?.code,
                        town: data[0]?.town,
                        county: formatCounty(data[0]?.county),
                    },
                });
                setIsEnabled(true);
                setIsConfirm(false);
                setSingleAddress(data);
            } else {
                setIsConfirm(false);
                console.log('address list length', data);
                setAddressList(data);

                if (addressList.length > 1) {
                    // handleNext();
                }
            }
        } catch (err) {
            console.error('address error:', err);
        }
    };

    const handleConfirm = event => {
        getAddresses(defaultValue);
    };

    return (
        <Fragment>
            <Box className={classes.eirContainer}>
                <Box className={classes.delivery}>Delivery Address</Box>
                <Grid container className={classes.eircodeContainer} spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            id='Eircode'
                            label='Eircode'
                            defaultValue={defaultValue}
                            // onPaste={handlePaste}
                            onChange={event => {
                                handleEirCodeChange(event);
                                return event.target.value;
                            }}
                            onPaste={handlePaste}
                            required
                            error={isConfirm}
                            errorMessage={errormessage.noAddressFoundforEirCode}
                            className={`eircode`}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.ctaGrid}>
                        <EirButton
                            // className={`${!eirCodeEmpty ? classes.ctaDisable : classes.cta} `}
                            style={{ padding: '0.5rem 2.7rem' }}
                            variants='primaryButtonMedium'
                            disabled={!eirCodeEmpty}
                            onClick={defaultValue => {
                                handleConfirm(defaultValue);
                                return defaultValue;
                            }}
                        >
                            Confirm Eircode
                        </EirButton>
                    </Grid>
                    <Typography
                        className={classes.link}
                        style={{ marginTop: isConfirm ? '10px' : '' }}
                        onClick={() => {
                            window.open(`https://finder.eircode.ie/#`, '_blank');
                        }}
                    >
                        Don't know your eircode?
                    </Typography>
                </Grid>

                <SimAddressForm
                    addressList={addressList}
                    setAddressList={setAddressList}
                    addressData={addressData}
                    setAddressData={setAddressData}
                    singleAddress={singleAddress}
                    setSingleAddress={setSingleAddress}
                    isConfirm={isConfirm}
                    eircode={eirCode}
                    ContactDetails={ContactDetails}
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    submitFormAction={submitFormAction}
                    submitFormData={submitFormData}
                    setIsEnabled={setIsEnabled}
                    reorderForm={reorderForm}
                    flag={flag}
                />
                <SimBlockMyService setIsTicked={setIsTicked} />
            </Box>
        </Fragment>
    );
};

export default SimEircode;
