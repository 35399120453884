import { DOMAINS } from '../../components/myeirconstant/myEirConstant';
import { getServiceId } from '../getServiceID';

const { VOIP, MOBILE } = DOMAINS;

export async function getMobileServiceID(graphql) {
    try {
        console.log('getMobileServiceID invoked');
        if (
            sessionStorage.getItem('LoggedinServices') == undefined ||
            sessionStorage.getItem('LoggedinServices') == ''
        ) {
            const serviceResponse = await getServiceId(graphql);
        }

        if (
            sessionStorage.getItem('LoggedinServices') != undefined ||
            sessionStorage.getItem('LoggedinServices') != ''
        ) {
            const mobileService = JSON.parse(sessionStorage.getItem('LoggedinServices')).filter(
                (item, index) => {
                    if (item.domain === MOBILE) {
                        return true;
                    } else return false;
                }
            );
            if (mobileService.length > 0)
                sessionStorage.setItem('MobileServicesFiltered', JSON.stringify(mobileService));
        } else {
            throw new Error('LoggedinServices is undefined/empty');
        }
    } catch (err) {
        console.error('Error while filtering mobile service ID', err);
        return [false, err.message];
    }
}

/**
 * @name getMobileVobbServiceID - to get both mobile & Vobb numbers from service Ids
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} graphql - appoloclient graphql constant
 */
export async function getMobileVobbServiceID(graphql) {
    try {
        if (
            sessionStorage.getItem('LoggedinServices') == undefined ||
            sessionStorage.getItem('LoggedinServices') == ''
        ) {
            const serviceResponse = await getServiceId(graphql);
        }

        if (
            sessionStorage.getItem('LoggedinServices') != undefined ||
            sessionStorage.getItem('LoggedinServices') != ''
        ) {
            const mobileVobbService = JSON.parse(sessionStorage.getItem('LoggedinServices')).filter(
                (item, index) => {
                    if (item.domain === VOIP || item.domain === MOBILE) {
                        return true;
                    } else return false;
                }
            );

            if (mobileVobbService.length > 0)
                sessionStorage.setItem(
                    'MobileVobbServicesFiltered',
                    JSON.stringify(mobileVobbService)
                );
        } else {
            throw new Error('LoggedinServices is undefined/empty');
        }
    } catch (err) {
        console.error('Error while filtering mobile service ID', err);
        return [false, err.message];
    }
}

/**
 * @name getMobileVobbPSTNServiceID - to get mobile, Vobb & PSTN  numbers from service Ids
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} graphql - appoloclient graphql constant
 */
export async function getMobileVobbPSTNServiceID(graphql) {
    try {
        if (
            sessionStorage.getItem('LoggedinServices') == undefined ||
            sessionStorage.getItem('LoggedinServices') == ''
        ) {
            const serviceResponse = await getServiceId(graphql);
        }

        if (
            sessionStorage.getItem('LoggedinServices') != undefined ||
            sessionStorage.getItem('LoggedinServices') != ''
        ) {
            const mobileVobbService = JSON.parse(sessionStorage.getItem('LoggedinServices')).filter(
                (item, index) => {
                    if (item.domain === VOIP || item.domain === MOBILE || item.domain === 'PSTN') {
                        return true;
                    } else return false;
                }
            );

            if (mobileVobbService.length > 0)
                sessionStorage.setItem(
                    'MobileVobbPSTNServiceFiltered',
                    JSON.stringify(mobileVobbService)
                );
        } else {
            throw new Error('LoggedinServices is undefined/empty');
        }
    } catch (err) {
        console.error('Error while filtering mobile service ID', err);
        return [false, err.message];
    }
}
