import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { useAlreadyRegistered } from '../../sharedcomponents/css/useAlreadyRegistered';
import alreadyRegisteredIcon from '../../sharedcomponents/images/alreadyRegisteredIcon.png';
import failureIcon from '../../sharedcomponents/images/failureIcon.png';
// import { usePaymentFlowStyles } from '../css/usePaymentFlowStyles';
import { usePaymentStatus } from './usePaymentStatus';
import { MobileContext } from '../global-context';
import EirButton from '../../theme/EirButton';
const PaymentStatusModal = props => {
    const classes = usePaymentStatus();
    const value = useContext(MobileContext);
    const toggle = () => props.toggle();
    const handleTryAgain = () => {
        props.handleBack();
    };
    const backToAccount = async () => {
        const [actionType, payload] = 10000 < 600 ? ['slideTo', 0] : ['scrollTo', 4];
        toggle();
        value.dispatch({ type: actionType, payload: payload });
    };

    useEffect(() => {
        props.success
            ? props.setHeader('Payment successful', true)
            : props.setHeader('Payment failed', true);
    }, [props.success]);

    return (
        <React.Fragment>
            <div className={classes.alreadyRegisteredBody}>
                <div className={classes.tickWrapper}>
                    <img
                        className={classes.tick}
                        src={props.success ? alreadyRegisteredIcon : failureIcon}
                        alt='Payment Status Icon'
                    />
                </div>
                <div className={classes.newsTextWrapper}>
                    <div className={classes.newsText}>
                        {props.success ? 'Thanks!' : 'Sorry something went wrong!'}
                    </div>
                </div>
                <div className={classes.emailVerifiedWrapper}>
                    <div className={classes.emailVerifiedText}>
                        {props.success
                            ? `You’ve paid €${props.paidAmount}`
                            : 'No payment has been taken'}
                    </div>
                    <div className={classes.emailVerifiedTextMobile}>
                        {props.success
                            ? `You’ve paid €${props.paidAmount}`
                            : 'No payment has been taken'}
                    </div>
                </div>

                <div className={classes.passwordInstructionWrapper}>
                    <div className={classes.passwordInstructionPara}>
                        {props.success
                            ? 'It may take up to 24 hours for your payment to appear on your account balance.'
                            : 'Please ensure your details are correct and try again, or try using another card.'}
                    </div>
                </div>
            </div>
            <div
                className={classes.alreadyRegisteredFooter}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
                <EirButton
                    style={{ padding: '0.5rem 2.5rem', width: 'fit-content' }}
                    variants='primaryButtonMedium'
                    onClick={props.success ? toggle : handleTryAgain}
                >
                    {props.success ? 'Close' : 'Try again'}
                </EirButton>

                <div className='back-btn payment-cta'>
                    <span style={{ cursor: 'pointer' }} onClick={backToAccount}>
                        {props.success ? '' : 'Back to my eir account'}
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PaymentStatusModal;
