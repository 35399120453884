import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    none: {
        display: 'none',
    },
    mainContainer: {
        width: '100%',
        height: 'fit-content',
        minHeight: '52px',
        background: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        marginTop: '2px',
        marginBottom: '2px',
        padding: '3px 20px',
    },
    imageIcon: {
        width: '24px',
        height: '24px',
        margin: '12px 26px 10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textInfo: {
        width: '100%',
        textAlign: 'left',
    },
    alignCenter: {
        textAlign: 'center',
    },
    alignRight: {
        textAlign: 'right',
    },
    boldText: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: theme.typography.inputLabel.fontSize,
        color: theme.palette.primary.heather,
        lineHeight: '16px',
        marginBottom: '3px',
    },
    lightText: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '16px',
        color: theme.palette.primary.heather,
    },
    terminatedMain: {
        width: 'fit-content',
        height: '40px',
        padding: '8px 12px',
        borderRadius: '4px',
        background: 'linear-gradient(0deg, #FCE5EC 0%, #FCE5EC 100%), #2196F3',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '4px',
        '@media(max-width: 599px)': {
            height: '54px',
            marginTop: '2px',
            marginBottom: '2px',
        },
    },

    terminatedFlagMain: {
        width: 'fit-content',
        maxWidth: '376px',
        height: '52px',
        padding: '8px 12px',
        borderRadius: '4px',
        background: 'linear-gradient(0deg, #FCE5EC 0%, #FCE5EC 100%), #2196F3',
        display: 'flex',
        alignItems: 'start',
        marginBottom: '4px',
        textAlign: 'start',
        margin: '0 auto',
        '@media(max-width: 599px)': {
            height: '66px',
        },
    },
    terminatedText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',

        fontSize: '12px',
        fontWeight: 300,
        // lineHeight: '157%',
        '@media(max-width: 599px)': {
            textAlign: 'left',
        },
    },
    terminatedIconWrap: {
        marginRight: '8px',
    },
}));

export default useStyles;
