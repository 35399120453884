import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import '../sass/mobile-header.scss';
import myeirLogo from './images/LOGO.png';
import myeirLogoMobile from './images/LOGOM.png';
import hamMenuBackground from './images/navigation_M.png';
import { useWindowDimension } from '../hooks/windowDimensions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../mobilecomponents/graphql/GraphQLConstants';
import { MobileContext } from '../mobilecomponents/global-context';
import { getNameAndAccountID } from '../mobilecomponents/getNameAndAccountNumber';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import EirButton from '../theme/EirButton';

function MobileHeader(props) {
    const [width, height] = useWindowDimension();
    const [isopen, setIsOpen] = useState(false);
    const [isLoggedIn, setLogIn] = useState(true);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [retryAPIcall, setRetryAPIcall] = useState(4);
    const value = useContext(MobileContext);
    const graphql = useApolloClient();
    const history = useHistory();
    const toggle = () => {
        setIsOpen(!isopen);
    };
    const toggleLogIn = () => setLogIn(!isLoggedIn);

    const onNavClick = (navItem, actionType) => {
        navItem >= 0 && value.dispatch({ type: actionType, payload: navItem });
        if (!(location.href.indexOf('/account/dashboard') > -1)) {
            history.push('/account/dashboard');
        }

        setIsOpen(!isopen);
    };

    async function getNameAndAccountNumber() {
        // console.log('number', sessionStorage.getItem('LoggedinUserAccountNumber'));
        // console.log(
        //     'is number empty?==',
        //     sessionStorage.getItem('LoggedinUserAccountNumber') == ''
        // );
        // console.log(
        //     'is number undefined?==',
        //     sessionStorage.getItem('LoggedinUserAccountNumber') == undefined
        // );
        // console.log(
        //     'is fname undefined?==',
        //     sessionStorage.getItem('LoggedinUserFirstName') == undefined
        // );
        // console.log(
        //     'is lname undefined?==',
        //     sessionStorage.getItem('LoggedinUserLastName') == undefined
        // );

        if (
            sessionStorage.getItem('LoggedinUserFirstName') == undefined ||
            sessionStorage.getItem('LoggedinUserLastName') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == ''
        ) {
            const response = await getNameAndAccountID(graphql);
            // console.log('response[0]', response[0]);
            if (
                sessionStorage.getItem('LoggedinUserFirstName') == undefined ||
                sessionStorage.getItem('LoggedinUserLastName') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountNumber') == ''
            ) {
                setTimeout(function () {
                    // console.log('retrying to fetch Name and Account number');
                    // console.log('number of retries left', retryAPIcall - 1);
                    setRetryAPIcall(retryAPIcall - 1);
                }, 10000);
            }
            if (
                sessionStorage.getItem('LoggedinUserAccountNumber') != undefined &&
                sessionStorage.getItem('LoggedinUserAccountNumber') != ''
            ) {
                setAccountNumber(String(sessionStorage.getItem('LoggedinUserAccountNumber')));
            }

            if (sessionStorage.getItem('LoggedinUserFirstName') != undefined) {
                setFirstName(sessionStorage.getItem('LoggedinUserFirstName'));
            }

            if (sessionStorage.getItem('LoggedinUserLastName') != undefined) {
                setLastName(sessionStorage.getItem('LoggedinUserLastName'));
            }
        } else {
            setAccountNumber(String(sessionStorage.getItem('LoggedinUserAccountNumber')));
            setFirstName(sessionStorage.getItem('LoggedinUserFirstName'));
            setLastName(sessionStorage.getItem('LoggedinUserLastName'));
        }
    }

    useEffect(() => {
        // console.log('mobile header useeffect 1')
        if (props.loginSuccess == true) {
            getNameAndAccountNumber();
        }
    }, [props.loginSuccess]);

    useEffect(() => {
        // console.log('mobile header useeffect 2')
        if (retryAPIcall > 0 && retryAPIcall < 4) {
            getNameAndAccountNumber();
        }
    }, [retryAPIcall]);

    return (
        <div className='my-eir-header-container'>
            <div className='my-eir-header'>
                <div
                    onClick={() => {
                        // location.href = 'https://my.eir.ie';
                        window.location.href = '/';
                    }}
                    className='my-eir-logo'
                >
                    <picture>
                        <source media='(max-width: 599px)' srcSet={myeirLogoMobile} />
                        <img src={myeirLogo} alt='MyEirLogo' />
                    </picture>
                </div>
                {props.loginSuccess && window.location.href.indexOf('account/dashboard') > -1 && (
                    <div className='modal-test'>
                        {width >= 1020 && (
                            <div className='account-information'>
                                <Typography variant='h6' className='account-name'>
                                    {!!firstName && <>{firstName}&nbsp;</>}
                                    {!!lastName && lastName}
                                </Typography>
                                <div className='account-number'>
                                    {!!accountNumber && <>Account number: {accountNumber}</>}
                                </div>
                            </div>
                        )}
                        <React.Fragment>
                            <div
                                className={'burger-menu' + (isopen ? ' open' : '')}
                                onClick={toggle}
                            >
                                <div className='burger-menu-button'></div>
                            </div>
                            <Modal
                                className='hamBmain'
                                wrapClassName='hamBwrap'
                                modalClassName='hamBmodal'
                                backdropClassName='hamBbackdrop'
                                contentClassName='hamBcontent'
                                isOpen={isopen}
                                toggle={toggle}
                                modalTransition={{ timeout: 300 }}
                            >
                                <ModalBody
                                    className='hamBbody'
                                    // style={{
                                    //     background: `url(${hamMenuBackground}) no-repeat`,
                                    //     backgroundSize: `cover`,
                                    // }}
                                >
                                    <div className='hamburger-menu'>
                                        <div
                                            className={'burger-menu' + (!isopen ? ' open' : '')}
                                            onClick={toggle}
                                        >
                                            <div className='burger-menu-button'></div>
                                        </div>
                                        <div className='account-information'>
                                            <div className='account-name'>
                                                {!!firstName && <>{firstName}&nbsp;</>}
                                                {!!lastName && lastName}
                                            </div>
                                            <div className='account-number'>
                                                {!!accountNumber && (
                                                    <>Account number: {accountNumber}</>
                                                )}
                                            </div>
                                        </div>

                                        <div className='account-navigations'>
                                            {width >= 600 && (
                                                <div
                                                    className='account-navigations-eir-account'
                                                    onClick={() => onNavClick(4, 'scrollTo')}
                                                >
                                                    <span style={{ cursor: 'pointer' }}>
                                                        My account
                                                    </span>
                                                </div>
                                            )}
                                            {width < 600 && (
                                                <React.Fragment>
                                                    <div
                                                        className='account-navigations-account'
                                                        onClick={() => onNavClick(0, 'slideTo')}
                                                    >
                                                        <span style={{ cursor: 'pointer' }}>
                                                            My account
                                                        </span>
                                                    </div>
                                                    <div
                                                        className='account-navigations-bill'
                                                        onClick={() => onNavClick(1, 'slideTo')}
                                                    >
                                                        <span style={{ cursor: 'pointer' }}>
                                                            My bill
                                                        </span>
                                                    </div>
                                                    {/* <div
                                                        className='account-navigations-plan'
                                                        onClick={() => onNavClick(2, 'slideTo')}
                                                    >
                                                        <span style={{ cursor: 'pointer' }}>
                                                            My plan
                                                        </span>
                                                    </div> */}
                                                </React.Fragment>
                                            )}

                                            <div
                                                className='account-navigations-help'
                                                onClick={() => {
                                                    toggle();
                                                    window.open(
                                                        'https://www.eir.ie/support/',
                                                        '_blank'
                                                    );
                                                }}
                                            >
                                                <span style={{ cursor: 'pointer' }}>
                                                    Help {`&`} support
                                                </span>
                                            </div>
                                        </div>

                                        <EirButton
                                            variants='primaryButtonMedium'
                                            style={{ padding: '0.5rem 1.8rem' }}
                                            // className='sign-out-button'
                                            onClick={() => {
                                                sessionStorage.clear();
                                                window.location.href = '/';
                                            }}
                                        >
                                            Sign out
                                            {/* <ArrowForwardIcon className='sign-out-arrow' /> */}
                                        </EirButton>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </React.Fragment>
                    </div>
                )}
            </div>
        </div>
    );
}
export default MobileHeader;
