import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import {
    MenuItem,
    MenuList,
    Divider,
    Paper,
    Container,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { useTopNavStyle } from './css/useTopNavStyle';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';

function TopNav(props) {
    const [headerSecondaryItems, setheaderSecondaryItems] = useState(props.headerSecondaryItems);
    const [path,setPath]= useState(window.location.pathname);
    const classes = useTopNavStyle();
    useEffect(() => {
        setheaderSecondaryItems(props.headerSecondaryItems);
    }, [props.headerSecondaryItems]);

    

    useEffect(()=> {  
       setPath(window.location.pathname); 
    }, [window.location.pathname]);
    return (
        <div className={classes.TopNav}>
            <Navbar className='topnav p-md-0 navbar-expand '>
                <Container>
                    <div className='container-fluid'>
                        {headerSecondaryItems.secondaryNavLeft !== undefined && (
                            <Nav className='navbar-nav list-inline' navbar>
                                {headerSecondaryItems.secondaryNavLeft.children.map(
                                    (item, index) => {
                                        return (
                                            <NavItem
                                                key={index}
                                                className='d-none d-md-block p-0 pr-5'
                                            >
                                                <NavLink
                                                    className='eir-top-nav-link p-0 position-relative text-left'
                                                    href={item.link.link}
                                                >
                                                    {item.link.title}
                                                </NavLink>
                                            </NavItem>
                                        );
                                    }
                                )}
                            </Nav>
                        )}
                        {!props.loginSuccess &&
                            headerSecondaryItems.secondaryNavRightBeforeLogin !== undefined && (
                                <Nav className='navbar-nav list-inline rightNav' navbar>
                                    {headerSecondaryItems.secondaryNavRightBeforeLogin.children.map(
                                        (item, index) => {
                                            if (item.icon === undefined) {
                                                return (
                                                    <NavItem
                                                        key={index}
                                                        className='d-none d-md-block p-0 pr-3'
                                                    >
                                                        <NavLink
                                                            className='eir-top-nav-link position-relative text-right'
                                                            href={item.link.link}
                                                        >
                                                            {item.link.title}
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            } else {
                                                // eir-1086
                                                return( path!='/' ? (
                                                    <NavItem
                                                        key={index}
                                                        className='p-0 pl-4 pl-lg-5'
                                                    >
                                                        <NavLink
                                                            className='eir-top-nav-link position-relative p-0 text-right'
                                                            href={item.link.link}
                                                            target={
                                                                item.link.target ? '_blank' : ''
                                                            }
                                                        >
                                                            {(item.icon =='PersonOutlineOutlinedIcon' ) &&
                                                                <PersonOutlineOutlinedIcon
                                                                    fontSize='small'
                                                                    className='icon'
                                                                />
                                                            }
                                                            {item.icon == 'EmailOutlinedIcon' && (
                                                                <EmailOutlinedIcon
                                                                    fontSize='small'
                                                                    className='icon'
                                                                />
                                                            )}
                                                            {item.icon == 'SmsOutlinedIcon' && (
                                                                <SmsOutlinedIcon
                                                                    fontSize='small'
                                                                    className='icon'
                                                                />
                                                            )}{' '}
                                                            {item.link.title}
                                                            <div
                                                                className={
                                                                    'eir-icon position-absolute d-none d-lg-inline-flex icon-' +
                                                                    item.icon +
                                                                    '--black'
                                                                }
                                                            />
                                                        </NavLink>
                                                    </NavItem>
                                                ):(
                                                (item.icon == 'EmailOutlinedIcon' || item.icon == 'SmsOutlinedIcon') &&
                                                <NavItem
                                                key={index}
                                                  className='p-0 pl-4 pl-lg-5'
                                            >
                                                <NavLink
                                                    className='eir-top-nav-link position-relative p-0 text-right'
                                                    href={item.link.link}
                                                    target={
                                                        item.link.target ? '_blank' : ''
                                                    }
                                                >
                
                                                    {item.icon == 'EmailOutlinedIcon' && (
                                                        <>
                                                        <EmailOutlinedIcon
                                                            fontSize='small'
                                                            className='icon'
                                                        />
                                                        {item.link.title}
                                                    <div
                                                        className={
                                                            'eir-icon position-absolute d-none d-lg-inline-flex icon-' +
                                                            item.icon +
                                                            '--black'
                                                        }
                                                    /></>
                                                    )}
                                                    {item.icon == 'SmsOutlinedIcon' && (
                                                        <>
                                                        <SmsOutlinedIcon
                                                            fontSize='small'
                                                            className='icon'
                                                        />
                                                        {item.link.title}
                                                    <div
                                                        className={
                                                            'eir-icon position-absolute d-none d-lg-inline-flex icon-' +
                                                            item.icon +
                                                            '--black'
                                                        }
                                                    /></>
                                                    )}
                                                    
                                                </NavLink>
                                            </NavItem>)
                                                );
                                            }
                                        }
                                    )}
                                </Nav>
                            )}
                    </div>
                </Container>
            </Navbar>
        </div>
    );
}

export default TopNav;
