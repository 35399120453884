import React, { useState, useEffect, useRef } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { useTopNavStyle } from '../../sharedcomponents/css/useTopNavStyle';
import { Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MY_EIR_CONST } from '../myeirconstant/myEirConstant';

function DashboardHeader(props) {
    const [headerPrimaryItems, setheaderPrimaryItems] = useState(props.headerPrimaryItems);
    const classes = useTopNavStyle();
    const wrappersRef = useRef(null);
    const [isopen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isopen);

    useEffect(() => {
        setheaderPrimaryItems(props.headerPrimaryItems);
        function handleClickOutsideMenu(event) {
            if (wrappersRef.current && !wrappersRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClickOutsideMenu);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutsideMenu);
        };
    }, [props.headerPrimaryItems, wrappersRef]);
    useEffect(() => {
        var temp = window.location.pathname;

        for (let i = 0; i < document.querySelectorAll('a').length; i++) {
            document.querySelectorAll('a')[i].classList.remove('active');
        }

        if (document.querySelector('a[href="' + temp + '"]')) {
            if (
                document.querySelector('a[href="' + temp + '"]').parentNode.parentNode.parentNode
                    .parentNode.parentNode.parentNode != null &&
                document
                    .querySelector('a[href="' + temp + '"]')
                    .parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains(
                        'dropdown'
                    )
            ) {
                document
                    .querySelector('a[href="' + temp + '"]')

                    .parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[0].classList.add(
                        'active'
                    );
            } else {
                document.querySelector('a[href="' + temp + '"]').classList.add('active');
            }
        }
    });
    const logout = () => {
        sessionStorage.clear();
        window.location.href = MY_EIR_CONST.url.REDIRECT_URL;
    };
    return (
        <div className={classes.TopNav} ref={wrappersRef}>
            <div className='megaNav'>
                <Container className='mobilepadding'>
                    <Navbar color='light' light expand='md'>
                        <NavbarBrand
                            className='eir-navbar-brand text-left'
                            href='https://my.eir.ie'
                        ></NavbarBrand>
                        <NavbarToggler onClick={toggle} className={isopen ? 'hide' : 'show'} />
                        <NavbarToggler onClick={toggle} className={isopen ? 'show' : 'hide'}>
                            <CloseIcon fontSize='large' />
                        </NavbarToggler>
                        <Collapse isOpen={isopen} navbar>
                            <Nav className='ml-auto' navbar>
                                {headerPrimaryItems.primaryNav !== undefined &&
                                    headerPrimaryItems.primaryNav.children.map((navItem, index) => {
                                        if (navItem.children.length > 0) {
                                            return (
                                                <UncontrolledDropdown
                                                    nav
                                                    inNavbar
                                                    key={index}
                                                    className='p-0 pl-4 pl-lg-5'
                                                >
                                                    <DropdownToggle
                                                        nav
                                                        caret
                                                        className='position-relative text-right'
                                                    >
                                                        {navItem.link.title}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <span
                                                            id='triangleSpan'
                                                            className='arrow-down'
                                                        ></span>
                                                        <Container>
                                                            <div>
                                                                {navItem.children.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className='dropdown-items '
                                                                            >
                                                                                <div className='title'>
                                                                                    <i className='arrow-right'></i>{' '}
                                                                                    <a
                                                                                        target={
                                                                                            item
                                                                                                .link
                                                                                                .target
                                                                                        }
                                                                                        href={
                                                                                            item
                                                                                                .link
                                                                                                .link
                                                                                        }
                                                                                    >
                                                                                        {' '}
                                                                                        {
                                                                                            item
                                                                                                .link
                                                                                                .title
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                                {item.children.map(
                                                                                    (
                                                                                        child,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                className='dropdown-item'
                                                                                            >
                                                                                                <a
                                                                                                    href={
                                                                                                        child
                                                                                                            .link
                                                                                                            .link
                                                                                                    }
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            child
                                                                                                                .link
                                                                                                                .title,
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </Container>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            );
                                        } else {
                                            if (navItem.icon === undefined) {
                                                return (
                                                    <NavItem
                                                        key={index}
                                                        className='d-none d-md-block p-0 pr-3'
                                                    >
                                                        <NavLink
                                                            className='eir-top-nav-link position-relative text-right'
                                                            href={navItem.link.link}
                                                        >
                                                            {navItem.link.title}
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            } else {
                                                return (
                                                    <NavItem
                                                        key={index}
                                                        className='p-0 pl-4 pl-lg-5'
                                                    >
                                                        <NavLink
                                                            className='eir-top-nav-link position-relative p-0 text-right'
                                                            href={navItem.link.link}
                                                            target={
                                                                navItem.link.target ? '_blank' : ''
                                                            }
                                                        >
                                                            {navItem.link.title}
                                                            <div
                                                                className={
                                                                    'eir-icon position-absolute d-none d-lg-inline-flex icon-' +
                                                                    navItem.icon +
                                                                    '--black'
                                                                }
                                                            />
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            }
                                        }
                                    })}
                                <NavItem className='p-0 pl-4 pl-lg-5'>
                                    <NavLink
                                        className='eir-top-nav-link position-relative p-0 text-right'
                                        href='/prepay/logout'
                                        onClick={logout}
                                    >
                                        Log out
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );
}
export default DashboardHeader;
