import ENDPOINT from '../graphql/GraphQLConstants';

export const getSimEligibilityCheck = async ({ graphql, token, serviceId }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_SIM_ELIGIBILITY_CHECK,
            variables: {
                token: token,
                serviceId: serviceId,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const activateSimAPI = async ({ token, graphql, activateSim }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.SIM_ACTIVATE,
            variables: {
                token,
                activateSim
            },
        });
        return data.activateSim;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
