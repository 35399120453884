import { makeStyles } from '@material-ui/core/styles';
export const useDisplayPINPUKStyle = makeStyles(theme => ({
    PinPukWrapper: {
        background: 'white',

        '& .pinPuk-modal': {
            // background: "rgba(51, 51, 51, 0.5)",
            // backdropFilter: "blur(4.5px)",

            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow3Medium,
            },

            '@media(max-width: 599px)': {
                maxWidth: '100vw',
            },
        },

        '& .pinPuk-main': {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '0 30px',
            maxWidth: '754px',

            '@media(max-width: 1019px)': {
                padding: '0 99px',
                maxWidth: '858px',
            },
            '@media(max-width: 599px)': {
                marginTop: '8px',
                marginBottom: '0px',
                // marginLeft: "0px",
                // marginRight: "0px",
                padding: '0 8px',
            },
        },

        '& .pinPuk-content': {
            // padding: "0 30px",
            border: 'none',
            borderRadius: '10px',
            // wordWrap: "break-word",

            '@media(max-width: 599px)': {
                minHeight: 'calc(max(100vh , 640px) - 8px)',
                borderRadius: '10px 10px 0px 0px',
                wordWrap: 'break-word',
            },
        },

        '& .pinPuk-body': {
            background: theme.palette.primary.white,
            padding: '24px 32px',
            borderRadius: '10px',

            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,

            '@media(max-width: 599px)': {
                padding: '16px 16px 16px 16px',
                minHeight: '632px',
                borderRadius: '10px 10px 0px 0px',
            },

            '& .pinPuk-body-header': {
                borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                paddingTop: '1.42px',

                '@media(max-width: 599px)': {},

                '& .pinPuk-header-back-icon': {
                    display: 'flex',
                    marginLeft: '-4px',
                    color: theme.palette.primary.sunset,
                    cursor: 'pointer',

                    '@media(max-width: 599px)': {
                        height: '20px',
                        width: '20px',
                        marginLeft: '-4px',
                    },
                },

                '& .pinPuk-header-text': {
                    display: 'flex',
                    marginTop: '15.58px',
                    minHeight: '45px',
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.h4.fontSize,
                    lineHeight: '26px',
                    color: theme.palette.primary.heather,

                    position: 'relative',

                    '@media(max-width: 599px)': {
                        paddingLeft: '8px',
                        marginTop: '13.58px',
                        minHeight: '44px',
                        fontSize: theme.typography.h4.fontSize,
                    },

                    '& .pinPuk-header-line': {
                        position: 'absolute',
                        left: '0%',
                        right: '0%',
                        bottom: '3px',
                        border: `1px solid ${theme.palette.primary.borderColor}`,
                        borderTopWidth: '0px',

                        '@media(max-width: 599px)': {
                            bottom: '0px',
                        },
                    },
                },

                '& .hide': {
                    visibility: 'hidden',
                },
            },

            '& .pinPuk-body-content': {
                maxWidth: '406px',
                margin: '48px auto 0px',

                '@media(max-width: 599px)': {
                    margin: '26px auto 0px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                },

                '& .pinPuk-pin-info, & .pinPuk-puk-info, & .pinPuk-pin-description, & .pinPuk-puk-description': {
                    marginTop: '24px',
                },

                '& .pinPuk-body-content-header': {
                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                    color: theme.palette.primary.heather,
                },

                '& .pinPuk-body-content-info': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 600,
                    fontSize: theme.typography.h5.fontSize,
                    lineHeight: '24px',
                    letterSpacing: '8px',
                    color: theme.palette.primary.gray550,

                    marginTop: '5px',
                },

                '& .pinPuk-body-content-description': {
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.subtitle2.fontSize,
                    lineHeight: '22px',
                    color: theme.palette.primary.heather,

                    marginTop: '8px',
                },
            },

            '& .pinPuk-body-footer': {
                marginTop: '3.5rem',
                marginBottom: '2.1rem',
                display: 'flex',
                justifyContent: 'center',

                '& .pinPuk-body-footer-cta': {
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    padding: '0px',
                    font: 'inherit',
                    outline: 'inherit',

                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.subtitle1.fontWeight,
                    fontSize: theme.typography.subtitle1.fontSize,
                    lineHeight: theme.typography.subtitle1.lineHeight,
                    textAlign: 'center',
                    color: theme.palette.primary.sunset,

                    paddingLeft: '10px',
                    paddingRight: '10px',
                },
            },
        },
    },
    redesignLink: {
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'left',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
