import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomModal from '../../eir-porting/CustomModal/CustomModal';
import DeviceDetails from './device-details/DeviceDetails';
import { useApolloClient } from '@apollo/client';
import { styles } from './styles';
import { getActiveDevices, editDeviceName } from './deviceManagement.util';
import DeviceNotification from './DeviceNotification';
import DeviceInfoText from './DeviceInfoText';

const DeviceManagementModal = ({
    openDevice,
    toggle,
    deviceDeleted,
    setDeviceDeleted,
    toggleDelete,
    setDeleteDeviceData,
    setEnableDelete,
    setOpenDeviceManagement,
    headerText,
    setHeaderText,
    setisErrorText,
    isErrorText,
    setOpenError,
    openError,
}) => {
    const [activeDevices, setActiveDevices] = useState([]);
    const [editDevice, setEditDevice] = useState();
    const [deviceFetched, setDeviceFetched] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });

    const classes = styles();
    const graphql = useApolloClient();

    const backArrowAction = () => {
        toggle();
        setisErrorText(false);
    };

    const getDevices = async () => {
        try {
            const data = await getActiveDevices(graphql);
            setDeviceFetched(true);
            setActiveDevices(data?.devices);
            setEditDevice(data?.editDevice);
            setEnableDelete(data?.editDevice);
        } catch (error) {
            setOpenDeviceManagement(false);
            setOpenError(true);
            console.error(`getDevices error ${error}`);
        }
    };

    const renameDevice = async (deviceId, deviceName) => {
        try {
            const data = await editDeviceName(graphql, deviceId, deviceName);
            if (data === 200) {
                await getDevices();
                setisErrorText(false);
            } else {
                setisErrorText(true);
                setHeaderText('An issue occured while editing your device, please try again.');
            }
        } catch (error) {
            setisErrorText(true);
            setHeaderText('An issue occured while editing your device, please try again.');
            console.error(`renameDevice error ${error}`);
        }
    };

    useEffect(() => {
        getDevices();
    }, []);
    useEffect(() => {
        if (!!deviceDeleted && deviceFetched) {
            setHeaderText(`The device ‘${deviceDeleted}’ was deleted.`);
            setDeviceDeleted('');
            setDeviceFetched(false);
        } else if (isErrorText) {
            //  setisErrorText(true);
        } else if (activeDevices.length === 1) {
            setHeaderText('You currently have 1 device activated to use your eir TV Plus.');
        } else if (activeDevices.length > 1) {
            setHeaderText('You currently have 2 devices activated to use your eir TV Plus.');
        } else {
            setHeaderText(
                'You currently do not have any devices activated to use your eir TV Plus.'
            );
        }
    }, [activeDevices, isErrorText]);

    return (
        <CustomModal
            open={openDevice}
            cta1={cta1}
            cta2={cta2}
            toggle={toggle}
            header={{
                text: 'Manage your devices',
                arrowAction: backArrowAction,
                center: false,
            }}
        >
            <Box className={classes.deviceManagementContent}>
                <DeviceNotification />
                <DeviceInfoText
                    headerText={headerText}
                    editDevice={editDevice}
                    isErrorText={isErrorText}
                />
                <Box className={classes.deviceManagementDeviceDetails}>
                    <Box>
                        <DeviceDetails
                            // active={true}
                            // deviceSequence='1'
                            // deviceName='eir_ios'
                            // deviceActivationDate='10/11/20'
                            // deviceLastUsedDate='11/09/21'
                            active={activeDevices.length > 0}
                            deviceSequence='1'
                            deviceId={activeDevices[0]?.deviceId}
                            deviceName={activeDevices[0]?.deviceName}
                            deviceActivationDate={activeDevices[0]?.deviceCreated}
                            setDeleteDeviceData={setDeleteDeviceData}
                            editDevice={editDevice}
                            renameDevice={renameDevice}
                            toggleDelete={toggleDelete}
                        />
                    </Box>
                    <Box>
                        <DeviceDetails
                            active={activeDevices.length > 1}
                            deviceSequence='2'
                            deviceId={activeDevices[1]?.deviceId}
                            deviceName={activeDevices[1]?.deviceName}
                            deviceActivationDate={activeDevices[1]?.deviceCreated}
                            setDeleteDeviceData={setDeleteDeviceData}
                            editDevice={editDevice}
                            renameDevice={renameDevice}
                            toggleDelete={toggleDelete}
                        />
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default DeviceManagementModal;
