import React, { Component } from 'react';

class Loading extends Component {
    render() {
        return (
            <div className={'eir-loading-container d-block text-center ' + this.props.className}>
                <span className='bg-second'></span>
                <span className='bg-second'></span>
                <span className='bg-second'></span>
            </div>
        );
    }
}

export default Loading;
