import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_MY_BALANCE: gql`
        query getMyPaymentHistory($token: String!, $billingAccountId: String!) {
            getAccountSummary(token: $token, billingAccountId: $billingAccountId) {
                amount
                reference
                totalBalance
                transactionType
                dateTime
            }
        }
    `,
    GET_FAQ: gql`
        query getMyFaqs {
            getMyFaqs {
                name
                description
            }
        }
    `,
    GET_BRAND: gql`
        query getBrands($emailAddress: String!) {
            getBrands(emailAddress: $emailAddress) {
                brand {
                    system
                    mobileNumber
                    billType
                    title
                }
            }
        }
    `,
    GET_BRAND_FOR_LOGIN: gql`
        query getBrandsForLogin($emailAddress: String!, $key: String) {
            getBrandsForLogin(emailAddress: $emailAddress, key: $key) {
                brand {
                    system
                    mobileNumber
                    billType
                    title
                }
            }
        }
    `,
    GET_BRAND_FROM_NUMBER: gql`
        query getBrands($number: String!) {
            getBrands(number: $number) {
                statusCode
            }
        }
    `,
    VALIDATE_ACCOUNT_LOGIN: gql`
        query multiBrandLogin($emailAddress: String!, $brand: String!, $password: String!) {
            multiBrandLogin(emailAddress: $emailAddress, brand: $brand, password: $password) {
                access_token
                refresh_token
            }
        }
    `,
    GET_BILLING_HISTORY: gql`
        query getBillingHistory($token: String!, $accountId: String!) {
            getBillingHistory(token: $token, accountId: $accountId) {
                amount
                dueDate
                invoiceDate
                invoiceNumber
            }
        }
    `,
    GET_SUBSCRIPTION_ID_AND_CONTACT: gql`
        query getMySubscriptionsAndContactDetails($token: String!) {
            getMySubscriptions(token: $token) {
                content {
                    id
                    accountId
                    type
                    catalogOfferCode
                    serviceGroup
                    dependsOn
                    terminatedAt
                }
            }
            getMyContactDetails(token: $token) {
                #createdAt
                #idLastCheckedAt
                #gdprValidatedAt
                #gdprLanguage
                #communicationPreference
                #allowThirdParties
                uuid
                #identityDocuments
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                    code
                    type
                    id
                }
                #title
                firstName
                lastName
                #birthDate
                phoneNumbers {
                    phoneNumber
                    type
                    id
                }
                emails {
                    email
                }
            }
        }
    `,

    ADD_CARD: gql`
        query getHppRequestAddCard($token: String!) {
            getHppRequestAddCard(token: $token) {
                ACCOUNT
                AMOUNT
                AUTO_SETTLE_FLAG
                BILLING_CO
                BILLING_CODE
                CARD_PAYMENT_BUTTON
                CARD_STORAGE_ENABLE
                COMMENT1
                COMMENT2
                CURRENCY
                CUST_NUM
                DCC_ENABLE
                HPP_ADDRESS_MATCH_INDICATOR
                HPP_BILLING_CITY
                HPP_BILLING_COUNTRY
                HPP_BILLING_POSTALCODE
                HPP_BILLING_STATE
                HPP_BILLING_STREET1
                HPP_BILLING_STREET2
                HPP_BILLING_STREET3
                HPP_CHALLENGE_REQUEST_INDICATOR
                HPP_CUSTOMER_EMAIL
                HPP_CUSTOMER_PHONENUMBER_MOBILE
                HPP_FRAUDFILTER_MODE
                HPP_LANG
                HPP_SELECT_STORED_CARD
                HPP_SHIPPING_CITY
                HPP_SHIPPING_COUNTRY
                HPP_SHIPPING_POSTALCODE
                HPP_SHIPPING_STATE
                HPP_SHIPPING_STREET1
                HPP_SHIPPING_STREET2
                HPP_SHIPPING_STREET3
                HPP_VERSION
                MERCHANT_ID
                OFFER_SAVE_CARD
                ORDER_ID
                PAYER_EXIST
                PAYER_REF
                PMT_REF
                PROD_ID
                RETURN_TSS
                SHA1HASH
                SHIPPING_CO
                SHIPPING_CODE
                TIMESTAMP
                VALIDATE_CARD_ONLY
                VAR_REF
            }
        }
    `,

    GET_OFFER_DETAILS: gql`
        query getOfferDetails($catalogOfferCode: String!) {
            getOfferDetails(catalogOfferCode: $catalogOfferCode) {
                description
            }
        }
    `,

    GET_OFFER_ELIGIBILITY: gql`
        query getOfferElligibilty($token: String!, $subscriptionId: Int!) {
            getOfferEligibilityDetails(token: $token, subscriptionId: $subscriptionId) {
                eligibleAt
                inContract
                inGracePeriod
            }
        }
    `,

    GET_ACCOUNT_NUMBER: gql`
        query getMyAccountID($token: String!, $accountId: String!) {
            getMyAccountID(token: $token, accountId: $accountId) {
                billingAccountId
                invoiceDeliveryMethod
            }
        }
    `,
    GET_AUTHORISED_USER: gql`
        query getMyAuthorizedUser($token: String!, $accountId: String!) {
            getMyAuthorizedUsers(token: $token, accountId: $accountId) {
                firstName
                lastName
            }
        }
    `,
    GET_MY_CONTRACTS: gql`
        query getContracts($token: String!, $subscriptionId: Int!) {
            getMyServiceContract(token: $token, subscriptionId: $subscriptionId) {
                id
                startAt
                endedAt
            }
        }
    `,
    GET_MY_PAYMENT_METHOD_DETAILS: gql`
        query getPaymentMethod($token: String!, $account_id: String!) {
            getPaymentMethod(token: $token, account_id: $account_id) {
                accountId
                brand
                payerContactUuid
                payerRef
                paymentMethods {
                    canceled
                    canceledAt
                    default
                    expirationDate
                    partialDigits
                    paymentMethodId
                    paymentMethodType
                    status
                    providerReference
                    type
                    iban
                }
            }
        }
    `,
    GET_PAYMENT_DETAILS: gql`
        query getPaymentDetails($token: String!, $transactionReference: String!) {
            getPaymentDetails(token: $token, transactionReference: $transactionReference) {
                paymentSource
                paymentMethod {
                    partialDigits
                    paymentMethodId
                }
                paymentDatetime
            }
        }
    `,

    SERVICE_ID: gql`
        query getMyServices($token: String!, $subscriptionId: Int!) {
            getMyServices(token: $token, subscriptionId: $subscriptionId) {
                name
                id
                status
                domain
                subscriptionId
                msisdn
                sipNumber
                bbdsl
                dsl
                fiberUan
                voipUan
                phoneNumber
                pstnUan
            }
        }
    `,
    GET_POST_VERIFY_ION: gql`
        query postVerifyIonAccount($mobileNumber: String!, $account: String!) {
            postVerifyIonAccount(mobileNumber: $mobileNumber, account: $account) {
                statusCode
                emailStatus
                emailAddress
            }
        }
    `,
    GET_USAGE_SUMMARY: gql`
        query getMyUsageSummary(
            $token: String!
            $billingPeriod: String!
            $serviceType: String!
            $serviceId: Int!
        ) {
            getMyUsageSummary(
                token: $token
                billingPeriod: $billingPeriod
                serviceType: $serviceType
                serviceId: $serviceId
            ) {
                serviceId
                usageSummary {
                    amount
                    duration
                    eventCount
                    measuringUnit
                    quantity
                    ratingSubtotalId
                    ratingSubtotalTypeId
                    ratingSubtotalTypeKey
                    ratingSubtotalTypeLevel
                    ratingSubtotalTypeDisplayName
                    ratingSubtotalTypeId
                }
            }
        }
    `,
    GET_USAGE_DETAILS: gql`
        query getMyUsageDetails($token: String!, $usageDetailsInput: UsageDetailsInput!) {
            getMyUsageDetails(token: $token, usageDetailsInput: $usageDetailsInput) {
                usage {
                    pageable {
                        pageNumber
                        pageSize
                    }
                    first
                    last
                    number
                    numberOfElements
                    size
                    totalPages
                    totalElements
                    sort {
                        sorted
                    }
                    content {
                        chargedUsageId
                        amount
                        chargedNumber
                        chargeStartDateTime
                        destinationCountryCode
                        duration
                        invoiceText
                        quantity
                        destinationNumber
                        measuringUnit
                    }
                }
            }
        }
    `,
    GET_USERNAME_FROM_OTP: gql`
        query verifyOTP(
            $billType: String!
            $mobileNumber: String!
            $otpuuid: String!
            $otpcode: String!
        ) {
            verifyOTP(
                billType: $billType
                mobileNumber: $mobileNumber
                otpuuid: $otpuuid
                otpcode: $otpcode
            ) {
                username
                status
            }
        }
    `,
    SEND_VERIFICATION_EMAIL: gql`
        mutation sendVerificationEmail($email: String!) {
            sendVerificationEmail(email: $email)
        }
    `,
    CHANGE_EMAIL: gql`
        mutation changeIonUsername($oldEmailAddress: String!, $newEmailAddress: String!) {
            changeIonUsername(
                oldEmailAddress: $oldEmailAddress
                newEmailAddress: $newEmailAddress
            ) {
                statusCode
            }
        }
    `,

    CHANGE_CONTACT: gql`
        mutation updateContactNumber(
            $token: String!
            $contactUuid: String!
            $phoneNumberId: Int!
            $updatedPhoneNumber: String!
        ) {
            updateContactNumber(
                token: $token
                contactUuid: $contactUuid
                phoneNumberId: $phoneNumberId
                updatedPhoneNumber: $updatedPhoneNumber
            )
        }
    `,
    SUBMIT_SIM_REPLACEMENT: gql`
        mutation simReplacement($request: PublicSimOrderRequest!, $token: String!) {
            orderSim(request: $request, token: $token)
        }
    `,
    GET_BILLING_CYCLE: gql`
        query getBillingCycle($token: String!, $accountId: String!) {
            getBillingCycle(token: $token, accountId: $accountId) {
                firstInvoiceDate
                lastInvoiceDate
                nextInvoiceDate
            }
        }
    `,
    GET_MY_BILL_DETAILS: gql`
        query getMyBillDetails($token: String!, $invoiceId: String!, $billingAccountId: String!) {
            getMyBillDetails(
                token: $token
                invoiceId: $invoiceId
                billingAccountId: $billingAccountId
            ) {
                bills {
                    billAmount {
                        invoiceAmount
                        oneOffAmount
                        recurringAmount
                        usageAmount
                        vatAmount
                    }
                    billPeriod
                    effectiveDate
                    invoiceId
                }
            }
        }
    `,
    GET_BALANCE: gql`
        query getBalance($token: String!, $billingAccountId: String!) {
            getBalanceDetails(token: $token, billingAccountId: $billingAccountId) {
                nonOverdueAmount
                overdueAmount
                lastBillOverdueDate
                date
            }
        }
    `,
    GET_HPP_REQUEST: gql`
        query myHppRequest($token: String!, $amount: Int!) {
            myHppRequest(token: $token, amount: $amount) {
                MERCHANT_ID
                ACCOUNT
                ORDER_ID
                AMOUNT
                CURRENCY
                TIMESTAMP
                SHA1HASH
                AUTO_SETTLE_FLAG
                OFFER_SAVE_CARD
                PAYER_REF
                PMT_REF
                PAYER_EXIST
                HPP_VERSION
                HPP_SELECT_STORED_CARD
                HPP_CUSTOMER_EMAIL
                HPP_CUSTOMER_PHONENUMBER_MOBILE
                HPP_BILLING_STREET1
                HPP_BILLING_STREET2
                HPP_BILLING_STREET3
                HPP_BILLING_CITY
                HPP_BILLING_POSTALCODE
                HPP_BILLING_COUNTRY
                HPP_CHALLENGE_REQUEST_INDICATOR
                MERCHANT_RESPONSE_URL
                BA_ID
                PAYMENT_REASON
                PAYMENT_SOURCE
            }
        }
    `,
    VALIDATE_PAYMENT: gql`
        mutation myValidatePayment($token: String!, $HppResponse: HppResponse!) {
            myValidatePayment(token: $token, HppResponse: $HppResponse)
        }
    `,
    GET_MY_DETAILS: gql`
        query getMyDetails($token: String!, $serviceId: String!) {
            getMyDetails(token: $token, serviceId: $serviceId) {
                customerOfferDTO {
                    offerName
                    offerStatus
                    activationDate
                    msisdn
                }
                pin
                puk
            }
        }
    `,
    GET_PIN_PUK_DETAILS: gql`
        query getPinPukDetails($token: String!, $serviceId: String!) {
            getPinPukDetails(token: $token, serviceId: $serviceId) {
                imsiNumber
                pin1Code
                puk1Code
                serviceId
            }
        }
    `,
    GET_INVOICE_PDF: gql`
        query getInvoicePDF($token: String!, $invoiceNumber: Float!, $accountId: String!) {
            getInvoicePDF(token: $token, invoiceNumber: $invoiceNumber, accountId: $accountId)
        }
    `,
    RESET_PASSWORD: gql`
        mutation resetPassword($emailAddress: String!, $brand: BillingSystem) {
            resetPassword(emailAddress: $emailAddress, brand: $brand) {
                message
            }
        }
    `,
    GET_ADDRESSLIST: gql`
        query getAddress($eirCode: String!) {
            addressList(eirCode: $eirCode) {
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                }
            }
        }
    `,
    UPDATE_ADDRESS: gql`
        mutation updateAddress($token: String!, $updatedAddress: String!) {
            updateAddress(token: $token, updatedAddress: $updatedAddress)
        }
    `,
    GET_HPP_REQUEST_ADD_CARD: gql`
        query getHppRequestAddCard($token: String!) {
            getHppRequestAddCard(token: $token) {
                ACCOUNT
                AMOUNT
                AUTO_SETTLE_FLAG
                BILLING_CO
                BILLING_CODE
                CARD_PAYMENT_BUTTON
                CARD_STORAGE_ENABLE
                COMMENT1
                COMMENT2
                CURRENCY
                CUST_NUM
                DCC_ENABLE
                HPP_ADDRESS_MATCH_INDICATOR
                HPP_BILLING_CITY
                HPP_BILLING_COUNTRY
                HPP_BILLING_POSTALCODE
                HPP_BILLING_STATE
                HPP_BILLING_STREET1
                HPP_BILLING_STREET2
                HPP_BILLING_STREET3
                HPP_CHALLENGE_REQUEST_INDICATOR
                HPP_CUSTOMER_EMAIL
                HPP_CUSTOMER_PHONENUMBER_MOBILE
                HPP_FRAUDFILTER_MODE
                HPP_LANG
                HPP_SELECT_STORED_CARD
                HPP_SHIPPING_CITY
                HPP_SHIPPING_COUNTRY
                HPP_SHIPPING_POSTALCODE
                HPP_SHIPPING_STATE
                HPP_SHIPPING_STREET1
                HPP_SHIPPING_STREET2
                HPP_SHIPPING_STREET3
                HPP_VERSION
                MERCHANT_ID
                OFFER_SAVE_CARD
                ORDER_ID
                PAYER_EXIST
                PAYER_REF
                PMT_REF
                PROD_ID
                RETURN_TSS
                SHA1HASH
                SHIPPING_CO
                SHIPPING_CODE
                TIMESTAMP
                VALIDATE_CARD_ONLY
                VAR_REF
                BA_ID
                PAYMENT_REASON
                PAYMENT_SOURCE
                MERCHANT_RESPONSE_URL
                HPP_STORED_CREDENTIAL_INITIATOR
                HPP_STORED_CREDENTIAL_SEQUENCE
                HPP_STORED_CREDENTIAL_TYPE
            }
        }
    `,
    VALIDATE_HPP_RESPONSE_ADD_CARD: gql`
        mutation validateHppResponseAddCard($token: String!, $HppResponse: HppResponse!) {
            validateHppResponseAddCard(token: $token, HppResponse: $HppResponse)
        }
    `,
    UPDATE_EMAIL: gql`
        mutation updateEmail($token: String!, $newEmailAddress: String!) {
            updateEmail(token: $token, newEmailAddress: $newEmailAddress)
        }
    `,
    UPDATE_DEFAULT_PAYMENT_METHOD: gql`
        mutation updateDefaultPaymentMethod(
            $token: String!
            $paymentMethodId: Int!
            $account_id: String!
        ) {
            updateDefaultPaymentMethod(
                token: $token
                paymentMethodId: $paymentMethodId
                account_id: $account_id
            )
        }
    `,
    UPDATE_EXPIRY_DATE: gql`
        mutation updateExpiryDate(
            $token: String!
            $card_id: String!
            $expirationDate: String!
            $account_id: String!
        ) {
            updateExpiryDate(
                token: $token
                card_id: $card_id
                expirationDate: $expirationDate
                account_id: $account_id
            )
        }
    `,
    DELETE_PAYMENT_METHOD: gql`
        mutation deleteCard($token: String!, $paymentMethodId: Int!, $account_id: String!) {
            deleteCard(token: $token, paymentMethodId: $paymentMethodId, account_id: $account_id)
        }
    `,
    VALIDATE_IBAN_DETAILS: gql`
        mutation validateIBANDetails($token: String!, $iban: String!) {
            validateIBANDetails(token: $token, iban: $iban) {
                iban
                bic
                bankName
                branchName
            }
        }
    `,
    UPDATE_PAYMENT_METHOD_DETAILS: gql`
        mutation updatePaymentMethod(
            $token: String!
            $updatedPayment: String!
            $account_id: String!
        ) {
            updatePaymentMethod(
                token: $token
                updatedPayment: $updatedPayment
                account_id: $account_id
            )
        }
    `,
    PORTING_TIME: gql`
        {
            portingTime(brand: "EIR", channel: "MYACCOUNT") {
                portingHours {
                    date
                    startTime
                    endTime
                }
            }
        }
    `,
    PORTING_CHECK: gql`
        query getPortDetails($token: String!, $serviceId: Int!) {
            getPortDetails(token: $token, serviceId: $serviceId) {
                direction
                phoneNumber
                portStartDateTime
                otherNetworkAccountNumber
                otherNetworkId
                otherNetworkName
                rejectCode
                serviceId
                state
                type
                status
                mnpId
            }
        }
    `,
    SCHEDULE_MOVE_PORT: gql`
        mutation portExistingCustomer($token: String!, $portIn: CreatePortInput!) {
            portExistingCustomer(token: $token, portIn: $portIn)
        }
    `,

    RESCHEDULE_MOVE_PORT: gql`
        mutation updatePort($token: String!, $updatePort: UpdatePortInput!) {
            updateNotStartedPort(token: $token, updatePort: $updatePort)
        }
    `,

    DELETE_PORT_METHOD: gql`
        mutation deletePort($token: String!, $deleteMnpId: Int!) {
            deletePort(token: $token, deleteMnpId: $deleteMnpId)
        }
    `,

    GET_AMAZON_PRIME_CONTENTS: gql`
        query getAmazonPrimeContents($customerType: String!) {
            getAmazonPrimeContents(customerType: $customerType) {
                customerType
                desktopTile {
                    imageAtlText
                    imageURL
                }
                mobileTile {
                    imageAtlText
                    imageURL
                }
                positionOfTile
                desktopBanner {
                    imageAtlText
                    imageURL
                }
                mobileBanner {
                    imageAtlText
                    imageURL
                }
                marketingText
                marketingTextColor
                additionalInfo
                additionalInfoDesktopBanner {
                    imageAtlText
                    imageURL
                }
                additionalInfoMobileBanner {
                    imageAtlText
                    imageURL
                }
                overlayText
                overlayTextColor
            }
        }
    `,
    UPDATE_PORT: gql`
        mutation updateNotStartedPort($token: String!, $serviceId: Int!) {
            updateNotStartedPort(token: $token, serviceId: $serviceId) {
                brand
                direction
                otherNetworkAccountNumber
                otherNetworkId
                phoneNumber
                portStartDateTime
                serviceId
                type
            }
        }
    `,
    GET_APV_TV_SERVICE_ID: gql`
        query getTVServiceId($account: String!, $token: String!) {
            getTVServiceId(account: $account, token: $token) {
                serviceId
                hasTV
                hasAPV
                addonId
            }
        }
    `,
    CHECK_APV_ADDON_ELIGIBILITY: gql`
        mutation amazonPrimeMutation($serviceId: String!, $token: String!, $channelCode: String!) {
            isEligibleForApv(serviceId: $serviceId, token: $token, channelCode: $channelCode) {
                status
                statusCode
                offerCode
            }
        }
    `,
    GET_SIM_CHARGES: gql`
        query getSimSwapCharges($serviceId: Int!, $token: String!) {
            getSimSwapCharges(serviceId: $serviceId, token: $token) {
                charges {
                    billingType
                    catalogChargeCode
                    catalogPricePlanCode
                    defaultPriceVatIncluded
                    description
                }
                ongoingRequest
            }
        }
    `,

    SIM_ACTIVATE: gql`
        mutation activateSim($token: String!, $activateSim: ActivateSimRequest!) {
            activateSim(token: $token, activateSim: $activateSim)
        }
    `,

    GET_SIM_ELIGIBILITY_CHECK: gql`
        query getSimCardEligibilty($token: String!, $serviceId: Int!) {
            getSimCardEligibilty(token: $token, serviceId: $serviceId) {
                serviceId
                waived
                barServices
                iccid
                imsi
                status
                type
                simSwapAddonCode
                charges {
                    catalogChargeCode
                    catalogPricePlanCode
                    description
                    billingType
                    defaultPriceVatIncluded
                }
                firstName
                lastName
                email
                phoneNumber
                appliedFees
                updatedAt
                createdAt
                address {
                    addressLine1
                    addressLine2
                    town
                    county
                    code
                }
            }
        }
    `,

    GET_ZATTOO_DEVICES: gql`
        query getDevices($accountId: Int!) {
            getZattooDevices(accountId: $accountId) {
                devices {
                    deviceId
                    deviceName
                    deviceCreated
                    deviceUpdated
                }
                editDevice
            }
        }
    `,
    EDIT_ZATTOO_DEVICE_NAME: gql`
        mutation editDeviceName($account: Int!, $deviceId: Int!, $deviceName: String!) {
            renameZattooDevice(account: $account, deviceId: $deviceId, deviceName: $deviceName)
        }
    `,
    GET_TVIS_PIN: gql`
        query getTvisPin($accountId: Int!, $token: String!) {
            getTvisPin(accountId: $accountId, token: $token) {
                pin
            }
        }
    `,
    DELETE_ZATTOO_DEVICE: gql`
        mutation deleteZattooDevice($accountId: Int!, $deviceId: Int!) {
            deleteZattooDevice(accountId: $accountId, deviceId: $deviceId)
        }
    `,
    SET_TVIS_PIN: gql`
        mutation createOrUpdateTvisPin($accountId: Int!, $newPin: UpdateTvisPin!) {
            createOrUpdateTvisPin(accountId: $accountId, newPin: $newPin)
        }
    `,
    GET_TVIS_ACCOUNT_NUM: gql`
        query getTvisAccountNumber($accountId: Int!, $token: String!) {
            getTvisAccountNumber(accountId: $accountId, token: $token) {
                number
                user
            }
        }
    `,
    GET_TVIS_FBS_PP: gql`
        mutation getJWTActivationToken(
            $identifier: String!
            $offerCode: String!
            $billingSystem: BillingSystem!
            $user: String!
        ) {
            getJWTActivationToken(
                identifier: $identifier
                offerCode: $offerCode
                billingSystem: $billingSystem
                user: $user
            ) {
                token
            }
        }
    `,
    CANCEL_APV: gql`
        mutation cancelAPV($identifier: String!, $reason: String!, $user: Int!) {
            cancelAPV(identifier: $identifier, reason: $reason, user: $user)
        }
    `,
};

export default ENDPOINT;
