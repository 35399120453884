import { createMuiTheme } from '@material-ui/core/styles';

// const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
    typography: {
        h1Large: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '3.375rem',
            lineHeight: '3.875rem',
            '@media (max-width: 600px)': {
                fontSize: '2.331rem',
                lineHeight: '2.831rem',
            },
        },
        h1: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '2.5rem',
            lineHeight: '3rem',
            '@media (max-width: 600px)': {
                fontSize: '1.726rem',
                lineHeight: '2.225rem',
            },
        },
        h2Large: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '3.375rem',
            lineHeight: '3.875rem',
            '@media (max-width: 600px)': {
                fontSize: '2.331rem',
                lineHeight: '2.831rem',
            },
        },
        h2: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '2rem',
            lineHeight: '2.5rem',
            '@media (max-width: 600px)': {
                fontSize: '1.536rem',
                lineHeight: '2.038rem',
            },
        },
        h3: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '1.75rem',
            lineHeight: '2.25',
            '@media (max-width: 600px)': {
                fontSize: '1.441rem',
                lineHeight: '1.938rem',
            },
        },
        h4: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '1.5rem',
            lineHeight: '2rem',
            '@media (max-width: 600px)': {
                fontSize: '1.345rem',
                lineHeight: '1.844rem',
            },
        },
        h5: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            '@media (max-width: 600px)': {
                fontSize: '1.25rem',
                lineHeight: '1.75rem',
            },
        },
        h6: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            '@media (max-width: 600px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
            },
        },

        subtitle1: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            '@media (max-width: 600px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
            },
        },
        subtitle2: {
            fontFamily: 'eirsans-bold',
            fontWeight: 'inherit',
            fontSize: '0.875rem',
            lineHeight: '1.375rem',
            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
                lineHeight: '1.375rem',
            },
        },

        body1: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '1rem',
            lineHeight: '1.5rem',

            '@media (max-width: 600px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
            },
        },
        body2: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '0.875rem',
            lineHeight: '1.375rem',
            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
                lineHeight: '1.375rem',
            },
        },
        body3: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '1.125rem',
            lineHeight: '1.625rem',
            '@media (max-width: 600px)': {
                fontSize: '1.125rem',
                lineHeight: '1.625rem',
            },
        },
        body3Medium: {
            fontFamily: 'eirsans-regular',
            fontWeight: 'inherit',
            fontSize: '1.125rem',
            lineHeight: '1.625rem',
            '@media (max-width: 600px)': {
                fontSize: '1.125rem',
                lineHeight: '1.625rem',
            },
        },

        caption: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '0.813rem',
            lineHeight: '1.25rem',
            '@media (max-width: 600px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
            },
        },
        overline: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '0.75rem',
            lineHeight: '1.375rem',
            '@media (max-width: 600px)': {
                fontSize: '0.75rem',
                lineHeight: '1.375rem',
            },
        },

        avatarLetter: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            '@media (max-width: 600px)': {
                fontSize: '1.25rem',
                lineHeight: '1.75rem',
            },
        },
        inputLabel: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '0.75rem',
            lineHeight: '0.75rem',
            '@media (max-width: 600px)': {
                fontSize: '0.75rem',
                lineHeight: '0.75rem',
            },
        },
        helperText: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '0.875rem',
            lineHeight: '1.375rem',
            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
                lineHeight: '1.375rem',
            },
        },
        inputText: {
            fontFamily: 'eirsans-light',
            fontWeight: 'inherit',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            '@media (max-width: 600px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
            },
        },

        tooltip: {
            fontFamily: 'eirsans-regular',
            fontWeight: 'inherit',
            fontSize: '0.625rem',
            lineHeight: '1.125rem',
            '@media (max-width: 600px)': {
                fontSize: '0.625rem',
                lineHeight: '1.125rem',
            },
        },
    },
    palette: {
        primary: {
            main: '#FF5733',
            heather: '#493553',
            heather80: '#6D5D75',
            sunset: '#DD1D78',
            sunset30: '#9B1454',
            white: '#FFFFFF',
            black: '#000000',
            success: '#43B02A',
            error: '#E40046',
            gray100: '#FBFBFC',
            gray200: '#F6F5F6',
            gray250: '#EDEBEE',
            gray300: '#DBD7DD',
            gray400: '#C8C2CB',
            gray500: '#B6AEBA',
            gray550: '#888B8D',
            gray600: '#A49AA9',
            gray700: '#928698',
            gray800: '#807286',
            gray900: '#64536D',
            gray950: '#5B4964',
            gray1000: '#523F5C',
            borderColor: '#E0E0E0',
            backgroundColor: '#F5EBDA80',
            backgroundColor2: '#F5EBDA',
            backgroundColor3: '#EDEBEE',
            boxShadow1Lite: '#d111c91f',
            boxShadow1Medium: '#d111c924',
            boxShadow1Dark: '#d111c933',
            boxShadow1Darkest: '#d111c966',
            boxShadow2Lite: '#888b8d1f',
            boxShadow2Medium: '#888b8d24',
            boxShadow2Dark: '#888b8d33',
            boxShadow3: '#ffffff00',
            boxShadow3Lite: '#ffffff9e',
            boxShadow3Medium: '#ffffffcc',
            boxShadow3Dark: '#ffffffe6',
            boxShadow4: '#00000000',
            boxShadow4Lite: '#00000033',
            boxShadow4Medium: '#0000004d',
            boxShadow4Dark: '#000000b3',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                fontSize: '16px',
                height: '61px',
                fontFamily: 'eirsans-light !important',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                color: 'white', // if you also want to change the color of the input, this is the prop you'd use
            },
        },
        MuiFormControlLabel: {
            label: {
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },
        MuiSelect: {
            icon: {
                color: '#892BA0',
            },
        },
        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (min-width:768px) and (max-width: 1024px)': {
                    fontSize: '14px',
                },
                '@media (min-width:322px) and (max-width: 415px)': {
                    fontSize: '12px',
                },
                '@media (min-width:281px) and (max-width: 321px)': {
                    fontSize: '10px',
                },
                '@media (min-width:279px) and (max-width: 280px)': {
                    fontSize: '8px',
                },
            },
            shrink: {
                fontSize: '16px',
                '@media (min-width:320px) and (max-width: 541px)': {
                    fontSize: '14px',
                },
                '@media (min-width:280px) and (max-width: 321px)': {
                    fontSize: '12px',
                },
            },
        },
    },
    largeFont: {
        fontSize: {
            xxxl: '5rem',
            xxl: '3.5rem',
        },
    },
});

export default theme;
