import React, { Fragment, useState, useRef } from 'react';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import { useChangeBillingAddress } from '../css/useChangeBillingAddress';
import { useApolloClient } from '@apollo/client';
import errormesages from './errormessages';
import ENDPOINT from '../graphql/GraphQLConstants';
import { formatCounty } from '../addressFormat';
import MyEirRegisterHeader from '../../sharedcomponents/MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from '../../sharedcomponents/MyEirPostpayRegistration/reusable/MyEirRegisterFooter';
import MyEirChangeBillingAddress from './MyEirChangeBillingAddress';
import MyEirAddressDetails from '../../components/my-eir-register/MyEirAddressDetails';

export const MyEirChangeBillingAddressFinder = ({
    isDeliveryAddress,
    setManualAddress,
    handleBackButton,
    handleNext,
    setAddressData,
}) => {
    const classes = useChangeBillingAddress();
    const graphql = useApolloClient();
    const { register, control, errors, handleSubmit, setValue, setError, clearError } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const defaultValue = '';
    const prefixAddress = isDeliveryAddress ? 'deliveryAddress' : 'billingAddress';
    const { validation, errormessage } = errormesages;
    const [isAddressLoading, setIsAddressLoading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [eirCode, setEirCode] = useState('');
    const [eirCodeEmpty, setEirCodeEmpty] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showManualAddress, setShowManualAddress] = useState(false);
    const eirCodeInputRef = useRef();
    const [isError, setIsError] = useState(false);

    const handleBack = () => {
        handleBackButton();
    };
    const handleNextButton = () => {
        handleNext();
    };
    const [isOpen, setIsopen] = useState(false);
    function toggle() {
        setIsopen(!isOpen);
    }

    const handleEirCodeChange = event => {
        setAddressList([]);
        setShowAddress(false);
        clearError(`${prefixAddress}EirCode`);
        if (/^[a-zA-Z0-9]+$/.test(event.target.value)) {
            console.log('length', event.target.value);
            setEirCode(event.target.value);
            if (event.target.value.length == 7) {
                setEirCodeEmpty(true);
            } else {
                setEirCodeEmpty(false);
            }
        }
    };
    const onClick = data => {
        if (eirCode.trim().length > 0) {
            clearError(`${prefixAddress}EirCode`);
            getAddressList();
        }
    };

    const getAddressList = async () => {
        try {
            const response = await graphql.query({
                query: ENDPOINT.GET_ADDRESSLIST,
                variables: {
                    eirCode: eirCode,
                },
            });
            let data = response.data.addressList.addresses;

            console.log('sou', data);

            if (data.length == 0) {
                //No address found for this Eircode
                setAddressData(null);
                setError(
                    `${prefixAddress}EirCode`,
                    'manual',
                    errormessage.noAddressFoundforEirCode
                );
                setIsError(true);
            } else if (data.length == 1) {
                setAddressData(data[0]);
                handleNext();
            } else {
                setAddressList(data);
                if (addressList.length > 1) {
                    handleNext();
                }
            }
        } catch (err) {
            setError(`${prefixAddress}EirCode`, 'manual', errormessage.addressFinderNetworkError);
            setIsError(true);
        } finally {
            setIsAddressLoading(false);
        }
    };

    return (
        <Fragment>
            <form className={classes.formAddressFinderContainer}>
                <Grid container className={classes.addressContainer}>
                    <Grid className={classes.addressEircode} item xs={12} sm={12}>
                        <Controller
                            as={
                                <TextField
                                    className={classes.textField}
                                    inputRef={eirCodeInputRef}
                                />
                            }
                            control={control}
                            fullWidth
                            id={prefixAddress + 'EirCode'}
                            name={prefixAddress + 'EirCode'}
                            label='Eircode'
                            variant='outlined'
                            size='small'
                            required
                            defaultValue={defaultValue}
                            error={isError}
                            rules={{
                                required: { value: true, message: validation.eircodeRequired },
                            }}
                            inputProps={{
                                maxLength: 7,
                            }}
                            onChange={([event]) => {
                                handleEirCodeChange(event);
                                return event.target.value;
                            }}
                            onFocus={() => {
                                eirCodeInputRef.current.focus();
                            }}
                        />
                        <ErrorMessage errors={errors} name={`${prefixAddress}EirCode`}>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item className={classes.addressUniqueEircode} xs={12} sm={12}>
                        {addressList.length > 1 && (
                            <FormControl
                                className={classes.textField}
                                variant='outlined'
                                fullWidth
                                size='small'
                            >
                                <InputLabel required>Please select your address</InputLabel>
                                <Controller
                                    control={control}
                                    name={prefixAddress + 'addressSelect'}
                                    id={prefixAddress + 'addressSelect'}
                                    inputRef={register}
                                    defaultValue={defaultValue}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validation.addressSelectRequired,
                                        },
                                    }}
                                    as={
                                        <Select
                                            id={prefixAddress + 'addresses'}
                                            label='Please select your address *'
                                            fullWidth
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        width: 250,
                                                    },
                                                },
                                                variant: 'menu',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            {addressList.map((address, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={index}
                                                    className={classes.selectLabel}
                                                >
                                                    {`${address.addressLine1} - ${
                                                        address.town
                                                    } - ${formatCounty(address.county)}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    }
                                    onChange={([event]) => {
                                        console.log(event.target.value);
                                        setAddressData(addressList[parseInt(event.target.value)]);
                                        return event.target.value;
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={prefixAddress + 'addressSelect'}
                                >
                                    {({ message }) => (
                                        <p className={classes.errorMessage}>{message}</p>
                                    )}
                                </ErrorMessage>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>

                <Grid className={classes.footerContainerAddress}>
                    <MyEirRegisterFooter
                        button1Text={'Lookup address'}
                        handleButton1Click={handleSubmit(onClick)}
                        button1Enabled={eirCodeEmpty}
                        button2Text='Back'
                        handleButton2Click={handleBack}
                    />
                </Grid>
            </form>
        </Fragment>
    );
};
