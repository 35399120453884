import { Box, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import SecurityIcon from '../../images/securityuserIcon.png';
import UserIcon from '../../images/userIcon1.png';
import ImageAndInfo from '../../reusable-components/ImageAndInfo';
import { authorisedUserStyle } from './authorisedUserStyle';

const AuthorisedUserInfo = ({ user }) => {
    const classes = authorisedUserStyle();

    return (
        <Box>
            <ImageAndInfo
                img={UserIcon}
                textBold={`${user.firstName ? user.firstName : ''} ${
                    user.lastName ? user.lastName : ''
                }`}
            />
            <ImageAndInfo
                img={SecurityIcon}
                textBold='Security Question'
                textLight={`${user.securityPhrase ? user.securityPhrase : ''}`}
            />
        </Box>
    );
};
export default AuthorisedUserInfo;
