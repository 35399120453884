import React, { useState,useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomModal from '../eir-porting/CustomModal/CustomModal';
import useStyles from './style';
import SimReplacementContainer from '../eir-sim-replacement/SimReplacementContainer';

const InactiveSim=({open,setOpenSimModal,setOpenCustomPopup,setOpen,
     linkName, handleSubmitSimRequest})=>{

    const classes = useStyles();
    const [openInactive, setOpenInactive] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });

    const handleOpenLink = () => {
        setOpenInactive(true);
         setCTA1({ text: 'Confirm', action: handleConfirm, disable: false })
         setCTA2({ text: 'Cancel', action: handleInactiveCancel })
        
    };

    const handleInactiveCancel =()=>{
        setOpenInactive(false);
        setOpenSimModal(false);
        setOpen(false);
    }

    const handleToggleInactive=()=>{
        setOpenInactive(!openInactive);
        setOpenSimModal(true);
        setOpen(false);
    }

    const handleConfirm=()=>{
        handleSubmitSimRequest();
        setOpen(false);
        setOpenInactive(false); 
        setOpenCustomPopup(true);
             
    }
    useEffect(()=>{
        open && handleOpenLink();
    },[open])

    return(
        <Box id='inactive-sim'>
            <Box className={classes.linkStyle}>
                <Typography component='span' onClick={handleOpenLink} className={classes.linkStyles}>
                    {linkName}    
                </Typography>
            </Box>
            
            <CustomModal
                open={openInactive}
                toggle={handleToggleInactive}
                cta1={cta1}
                cta2={cta2}
                header={{ text: 'Inactive SIM', arrowAction: handleInactiveCancel, center: false }}
            >
                <Box className={classes.inactiveContainer}>
                    <Typography className={classes.inactiveText}>Previous replacement SIM is not activated. This will cancel the previous order.</Typography>
                    <Typography className={classes.inactiveText}>Do you wish to continue?</Typography>
                </Box>                    
            </CustomModal>
         </Box>
    )
}

export default InactiveSim;