import { useApolloClient } from '@apollo/client';
import ENDPOINT from './graphql/GraphQLConstants';
import { getNameAndAccountID } from './getNameAndAccountNumber';

const token = sessionStorage.getItem('access_token');
let accountId = sessionStorage.getItem('LoggedinUserAccountID');

export async function getBillingPeriod(graphql) {
    try {
        if (!accountId) {
            await getNameAndAccountID(graphql);
            accountId = sessionStorage.getItem('LoggedinUserAccountID');
        }

        const res = await graphql.query({
            query: ENDPOINT.GET_BILLING_CYCLE,
            variables: {
                token: sessionStorage.getItem('access_token'),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
            },
        });
        if (res.data.getBillingCycle) {
            sessionStorage.setItem('firstInvoiceDate', res.data.getBillingCycle.firstInvoiceDate);
            sessionStorage.setItem('lastInvoiceDate', res.data.getBillingCycle.lastInvoiceDate);
            sessionStorage.setItem('nextInvoiceDate', res.data.getBillingCycle.nextInvoiceDate);
            sessionStorage.setItem(
                'billingPeriod',
                `${res.data.getBillingCycle.lastInvoiceDate.slice(
                    0,
                    4
                )}${res.data.getBillingCycle.lastInvoiceDate.slice(5, 7)}`
            );
            return [
                true,
                res.data.getBillingCycle.lastInvoiceDate,
                res.data.getBillingCycle.nextInvoiceDate,
            ];
        } else {
            throw new Error('Unable to fetch billing cycle due to a falsy accountId!');
        }
    } catch (err) {
        console.error('Error in fetching billing cycle : ', err.message);
        return [false, err.message];
    }
}
