import { makeStyles } from '@material-ui/core/styles';
export const styles = makeStyles(theme => ({
    deviceManagementToggleText: {
        // fontFamily: theme.typography.body2.fontFamily,
        // fontStyle: 'normal',
        // fontWeight: 400,
        // fontSize:theme.typography.subtitle2.fontSize,
        // lineHeight: '22px',
        textDecorationLine: 'underline',
        color: theme.palette.primary.heather,
        opacity: 0.9,
        cursor: 'pointer',
    },
    redesignDeviceText: {
        // fontFamily: theme.typography.body2.fontFamily,
        // fontStyle: 'normal',
        // fontWeight: 400,
        // fontSize: '13.9px',
        // lineHeight: '22px',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    deviceManagementContent: {
        padding: '8px 30px 0px 32px',
        [theme.breakpoints.down('xs')]: { padding: '3px 0px 0px 0px' },
    },
    deviceManagementContentNotification: {
        marginTop: '20px',
        minHeight: '108px',
        padding: '24px 29px',
        background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('xs')]: {
            marginTop: '13px',
            minHeight: 'unset',
            padding: '8px 16px 16px 16px',
        },
    },
    deviceManagementContentNotificationIcon: {
        width: '32px',
        height: '32px',
        color: theme.palette.primary.sunset,
        [theme.breakpoints.down('xs')]: { width: '24px', height: '24px' },
    },
    deviceManagementContentNotificationText: {
        // fontFamily: theme.typography.body2.fontFamily,
        // fontStyle: 'normal',
        // fontWeight: 400,
        // fontSize:theme.typography.body1.fontSize,
        // lineHeight: '18px',
        color: theme.palette.primary.heather,
        // letterSpacing: "0.05px",

        maxWidth: '300px',
        minHeight: '19px',
        height: 'fit-content',
        marginLeft: '35px',
        marginTop: '8px',

        flexGrow: '1',

        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.helperText.fontSize,
            lineHeight: theme.typography.helperText.lineHeight,
            marginLeft: '8px',
            marginTop: '2px',
            marginRight: '6px',
            maxWidth: 'unset',
        },
    },
    deviceManagementContentNotificationCloseBox: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    deviceManagementContentNotificationClose: {
        width: '27.43px',
        height: '27.43px',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',

        marginRight: '-2.7px',
        marginTop: '-8.5px',

        [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '20px',
            marginRight: '3px',
            marginTop: '-0.79px',
        },
    },
    deviceManagementContentHeader: {
        marginTop: '30px',
        [theme.breakpoints.down('xs')]: {
            margin: '21px 8px 0px 8px',
        },
    },
    deviceManagementContentHeaderText: {
        fontFamily: theme.typography.h4.fontFamily,
        // fontSize: '18px',
        // lineHeight: '28px',
        // fontWeight: 700,
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
        },
    },
    deviceManagementDeleteHeaderText: {
        color: theme.palette.primary.error,
        fontFamily: theme.typography.h4.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.body3.fontSize,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.helperText.fontSize,
            lineHeight: '22px',
        },
    },
    deviceManagementContentHeaderSubText: {
        // fontFamily: theme.typography.helperText.fontFamily,
        // fontSize:theme.typography.subtitle2.fontSize,
        // lineHeight: '22px',
        // fontWeight: 500,
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            // fontSize:theme.typography.subtitle2.fontSize,
            // lineHeight: '22px',
        },
    },
    deviceManagementDeviceDetails: {
        marginTop: '29px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '34px',
        },
    },
    deviceManagementDevice: {
        width: '100%',
        minHeight: '104px',
        background: theme.palette.primary.backgroundColor2,
        border: `1px solid${theme.palette.primary.backgroundColor2}`,
        marginTop: '16px',
        padding: '10px 12px',
        columnGap: '4px',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
        },
    },
    deviceManagementDeviceNumberBox: { minWidth: '16px' },
    deviceManagementDeviceNumber: {
        fontFamily: theme.typography.helperText.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.helperText.fontWeight,
        fontSize: theme.typography.helperText.fontSize,
        lineHeight: theme.typography.helperText.lineHeight,
        // lineHeight: '24px',
        color: theme.palette.primary.heather,
    },
    deviceManagementActiveDevice: { flexGrow: 1 },
    deviceManagementActiveDeviceNameBox: {
        marginBottom: '1px',
        maxWidth: '334px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(100% - 90px)',
        },
    },
    deviceManagementEditRenameDevice: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '14px',
        marginTop: '8px',
        marginBottom: '17px',
    },
    deviceManagementEditDeviceTextField: {
        maxWidth: '112px',
        // wordBreak: 'break-word',

        '& .MuiInputBase-root': {
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,
        },

        '& .MuiInputBase-input': {
            height: 'unset',
            padding: '0 0 6px',
        },
        '& .MuiInput-input': {
            color: theme.palette.primary.heather,
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        },
    },
    deviceManagementRenameDevice: {
        fontFamily: theme.typography.helperText.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.overline.fontWeight,
        fontSize: theme.typography.overline.fontSize,
        lineHeight: theme.typography.overline.lineHeight,
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,

        minWidth: '49px',
        padding: 0,
        textTransform: 'none',

        '&:hover': {
            color: theme.palette.primary.sunset30,
            backgroundColor: 'unset',
        },

        '& .MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
    deviceManagementCancelDevice: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        textTransform: 'none',
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,

        padding: 0,
        minWidth: '49px',

        '&:hover': {
            color: theme.palette.primary.sunset,
            backgroundColor: 'unset',
        },

        '& .MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
    deviceManagementActiveDeviceName: {
        // fontFamily: theme.typography.subtitle1.fontFamily,
        // fontStyle: 'normal',
        // // fontWeight: 700,
        // fontSize:theme.typography.subtitle2.fontSize,
        // lineHeight: '24px',
        color: theme.palette.primary.heather,
        wordBreak: 'break-word',
    },
    deviceManagementActiveDeviceDetails: {
        display: 'flex',
        marginTop: '6px',
        alignItems: 'center',
    },
    deviceManagementActiveDeviceDetailsType: {
        fontFamily: theme.typography.tooltip.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.helperText.fontWeight,
        fontSize: theme.typography.helperText.fontSize,
        lineHeight: theme.typography.helperText.lineHeight,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.heather,
        minWidth: '88px',
    },
    deviceManagementActiveDeviceDetailsValue: {
        fontFamily: theme.typography.helperText.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.helperText.fontWeight,
        fontSize: theme.typography.helperText.fontSize,
        lineHeight: theme.typography.helperText.lineHeight,
        lineHeight: '20px',
        color: theme.palette.primary.heather,
        marginLeft: '16px',
    },
    deviceManagementEditDeleteBox: { position: 'absolute', top: '11px', right: '16px' },
    deviceManagementEditDeleteDevice: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    deviceManagementEditDevice: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,
        opacity: 0.9,
        minWidth: '35px',
        // cursor: 'pointer',
        padding: 0,
        textTransform: 'none',

        '&:hover': {
            color: theme.palette.primary.sunset30,
            backgroundColor: 'unset',
        },

        '& .MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
    deviceManagementEditDeleteBorder: { width: '1px', height: '16px', background: '#D0D0D0' },
    deviceManagementDeleteDevice: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,
        opacity: 0.9,
        minWidth: '50px',
        textAlign: 'right',
        // cursor: 'pointer',
        padding: 0,
        textTransform: 'none',

        '&:hover': {
            color: theme.palette.primary.sunset30,
            backgroundColor: 'unset',
        },

        '& .MuiButton-label': {
            justifyContent: 'flex-end',
        },
    },
    deviceManagementInActiveDevice: { flexGrow: 1 },
    deviceManagementInActiveDeviceText: {
        // fontFamily: theme.typography.subtitle1.fontFamily,
        // fontStyle: 'normal',
        // // fontWeight: 700,
        // fontSize:theme.typography.subtitle2.fontSize,
        // lineHeight: '24px',
        color: theme.palette.primary.heather,
    },
    deviceManagementInActiveDeviceLink: {
        fontFamily: theme.typography.helperText.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,
        marginTop: '25px',
        // cursor: 'pointer',

        '&:hover': {
            color: theme.palette.primary.sunset30,
        },
    },
    deviceManagementDeviceDetailsDelete: {
        marginTop: '23px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '32px',
        },
    },
    deviceManagementContentHeaderTextDelete: {
        // fontFamily: theme.typography.subtitle1.fontFamily,
        // fontSize: '18px',
        // lineHeight: '28px',
        // fontWeight: 700,
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
        },
    },
    deleteModalBottomPadding: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: '74px',
        },
    },
    noMinWidth: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '0px',
        },
    },
}));
