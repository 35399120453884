import React, { useEffect, useState } from 'react';
import { isMobile, isMobileOnly, isTablet, isBrowser } from 'react-device-detect';
import { useApolloClient } from '@apollo/client';

import { getNameAndAccountID } from './getNameAndAccountNumber';
import { useRTMBanner } from './css/useRTMBanner';
import { useWindowDimension } from '../hooks/windowDimensions';

function MyEirRTMBanner(props) {
    const [retryAPIcall, setRetryAPIcall] = useState(4);
    const [accountNumber, setAccountNumber] = useState();
    const [rtmBanner, setRtmBanner] = useState(null);
    const [width, height] = useWindowDimension();
    const graphql = useApolloClient();

    const classes = useRTMBanner();

    let currentDate = new Date();
    let currentTime = currentDate.getTime();

    async function getAccountNumber() {
        // console.log('number from RTM', sessionStorage.getItem('LoggedinUserAccountNumber'));
        if (
            sessionStorage.getItem('LoggedinUserFirstName') == undefined ||
            sessionStorage.getItem('LoggedinUserLastName') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == ''
        ) {
            const response = await getNameAndAccountID(graphql);
            // console.log('response[5]', response);
            // console.log(sessionStorage.getItem('LoggedinUserAccountNumber'));
            if (
                sessionStorage.getItem('LoggedinUserFirstName') == undefined ||
                sessionStorage.getItem('LoggedinUserLastName') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountNumber') == ''
            ) {
                setTimeout(function () {
                    // console.log('retrying to fetch Name and Account number');
                    // console.log('number of retries left', retryAPIcall - 1);
                    setRetryAPIcall(retryAPIcall - 1);
                }, 10000);
            }
            if (
                sessionStorage.getItem('LoggedinUserAccountNumber') != undefined &&
                sessionStorage.getItem('LoggedinUserAccountNumber') != ''
            ) {
                setAccountNumber(String(sessionStorage.getItem('LoggedinUserAccountNumber')));
            }
        } else {
            setAccountNumber(String(sessionStorage.getItem('LoggedinUserAccountNumber')));
        }
    }

    useEffect(() => {
        // console.log('mobile header useeffect 1')
        if (props.loginSuccess == true) {
            getAccountNumber();
        }
    }, [props.loginSuccess]);

    useEffect(() => {
        // console.log('mobile header useeffect 2')
        if (retryAPIcall > 0 && retryAPIcall < 4) {
            getAccountNumber();
        }
    }, [retryAPIcall]);

    useEffect(() => {
        if (width < 600) {
            // console.log('mobile');
            setRtmBanner(
                'https://m.rtm.eir.ie/webApp/MyOfferMobileView?msisdn=0&clientid=' +
                    String(sessionStorage.getItem('LoggedinUserAccountNumber')) +
                    '&ts=' +
                    currentTime
            );
        } else if (width < 1020) {
            // console.log('tablet');
            setRtmBanner(
                'https://m.rtm.eir.ie/webApp/MyOfferTabletView?msisdn=0&clientid=' +
                    String(sessionStorage.getItem('LoggedinUserAccountNumber')) +
                    '&ts=' +
                    currentTime
            );
        } else {
            // console.log('desktop');
            setRtmBanner(
                'https://m.rtm.eir.ie/webApp/MyOfferOneCall?msisdn=0&clientid=' +
                    String(sessionStorage.getItem('LoggedinUserAccountNumber')) +
                    '&ts=' +
                    currentTime
            );
        }
    }, [width]);
    return (
        <>
            <div className={classes.rtmWrapper}>
                <div className={classes.rtmBanner}>
                    <iframe
                        id='rtmIFrame'
                        src={rtmBanner}
                        width='100%'
                        height={height}
                        scrolling='no'
                        frameBorder='0'
                    ></iframe>
                </div>
            </div>
            {/* old rtm banner */}
            {/* <div className={classes.rtm}>
                <div className='rtm-banner'>
                    {rtmBanner ? (
                        <iframe
                            src={rtmBanner}
                            scrolling='no'
                            style={{ overflow: 'hidden' }}
                        ></iframe>
                    ) : (
                        ''
                    )}
                </div>
            </div> */}
        </>
    );
}

export default MyEirRTMBanner;
