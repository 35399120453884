import React from 'react';
import EirButton from '../../../theme/EirButton';
import { commonRegisterStyles } from '../../css/myEirRegistrationStyles/commonRegisterStyles';

const MyEirRegisterFooter = props => {
    const styleClass = commonRegisterStyles();

    return (
        <div
            className={
                styleClass.footerContainer +
                ' ' +
                (props.button2Text == undefined || props.button1Text == undefined
                    ? styleClass.footerContainer1
                    : '') +
                ' ' +
                (props.button2Text == undefined && props.button1Text == undefined
                    ? styleClass.footerContainerNone
                    : '')
            }
        >
            {props.button1Text != undefined && (
                <EirButton
                    // className={
                    //     'primary-cta footer-cta' +
                    //     (props.button1Enabled ? ' cta-enabled' : ' cta-diabled')
                    // }
                    style={{ padding: '0.5rem 1.875rem' }}
                    variants='primaryButtonMedium'
                    disabled={!props.button1Enabled}
                    onClick={props.handleButton1Click}
                >
                    {props.button1Text}
                </EirButton>
            )}
            {props.button2Text != undefined && (
                <div className='secondary-cta footer-cta'>
                    <span style={{ cursor: 'pointer' }} onClick={props.handleButton2Click}>
                        {props.button2Text}
                    </span>
                </div>
            )}
        </div>
    );
};

export default MyEirRegisterFooter;
