import { makeStyles } from '@material-ui/core/styles';
export const useRTMBanner = makeStyles(theme => ({
    rtm: {
        '& .rtm-banner': {
            maxWidth: '1144px',
            width: '100%',
            // height: '157px',
            padding: '0',
            margin: 'auto',
            // marginLeft: '50px',
            textAlign: 'center',
            // border-top: 10px solid #d0d0d0;
            overflow: 'hidden',
            // padding-top: 78.12%;
            position: 'relative',
            marginBottom: '32px',
            marginTop: '32px',
            borderRadius: '10px',
            '@media(min-width:1020px) and (max-width:1200px)': {
                paddingLeft: '15px',
                paddingRight: '15px',
            },
            '@media(min-width:600px) and (max-width:1019px)': {
                maxWidth: '640px',
                paddingLeft: '15px',
                paddingRight: '15px',
            },
            '@media(max-width: 599px)': {
                marginLeft: '0px',
                padding: '0px 10px',
            },
        },
        // '@media(min-width:600px) and (max-width:1019px)': { height: '105px', marginLeft: '0px' },

        '& iframe': {
            width: '100%',
            height: 'auto',
            minHeight: '170px',
            border: 'none',
            borderRadius: '10px',
            '@media only screen and (max-width:650px) and (orientation: landscape)': {
                minHeight: '470p',
            },
            '@media only screen and (max-width:825px) and  (min-width: 665px) and (orientation: landscape)': {
                minHeight: '500px',
            },
            '@media only screen and (max-width:650px)and (orientation: portrait)': {
                minHeight: '250px',
            },
            '@media screen and (max-width: 768px) and (min-width: 670px)': {
                height: '87px',
            },
        },
    },
    rtmWrapper: {
        marginTop: '40px',
        marginBottom: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',

        '@media(min-width:1020px)': {
            maxWidth: '1204px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '@media(max-width: 1019px)': {
            paddingLeft: '30px',
            paddingRight: '30px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            maxWidth: '630px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '@media(max-width: 599px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
            marginTop: '6px',
            marginBottom: '24px',
        },
    },
    rtmBanner: {
        maxWidth: '1144px',
        width: '100%',
        height: '0',
        paddingBottom: '15%',
        position: 'relative',
        margin: 'auto',

        '@media(min-width:600px) and (max-width:1019px)': {
            maxWidth: '570px',
            paddingBottom: '11.305%',
        },
        '@media(max-width: 599px)': {
            maxWidth: 'calc(312 / 360 * 100vw)',
            paddingBottom: '67.69vw',
        },
        '@media(max-width: 469px)': {
            paddingBottom: '78.125%',
        },

        '& iframe': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            display: 'block',
            top: '0',
            left: '0',
            borderRadius: '10px',
        },
    },
}));
