import { Box, IconButton } from '@material-ui/core';
import close from '../../images/cross-icon.png'
import React from 'react';

const CustomCloseIcon = ({ closeIconAction, styles }) => {
    return (
        <Box >
            <Box component='img' src={close} alt='close-icon' className={styles.crossCloseIcon} onClick={closeIconAction}/>
            </Box>
    );
};

export default CustomCloseIcon;
