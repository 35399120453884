import React, { useEffect, useState } from 'react';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import moment from 'moment';
import CustomTextField from '../../../sharedcomponents/inputs/text-field/CustomTextField';
import DateField from '../../../sharedcomponents/inputs/date/DateField';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getPortingTimes, scheduleMoveAPI } from '../porting.util';
import { useApolloClient } from '@apollo/client';
import useStyle from './style';
import { publish } from 'pubsub-js';

const ScheduleMove = ({
    handleCTA,
    handleScheduleMove,
    portIn,
    portData,
    setEnable,
    reschedule,
}) => {
    const [date, setDate] = useState(null);
    const [time, setTime] = useState('');
    const [dateTimeProps, setDateTimeProps] = useState({
        maxDate: '',
        minDate: '',
        range: [],
        timeError: '',
        dateError: '',
    });

    const enableCTA = enable =>
        handleCTA({
            primary: true,
            cta: {
                text: 'Confirm',
                action: () => handleScheduleMove({ dateTime: date.toISOString() }),
                disable: !enable,
            },
        });

    // handle time change
    const handleTime = (timeValue, dateValue = date) => {
        // setTime(timeValue);
        console.log('selected time => ', timeValue);
        console.log('date => ', dateValue);
        publish('RESET_API_ERROR');
        setTime(timeValue);
        // get date
        // find date from rangege
        const range = dateTimeProps.range.filter(
            d => new Date(d.date).toDateString() === dateValue?.toDateString()
        );

        // time date value
        const newTime = new Date(dateValue);
        newTime.setHours(timeValue.split(':')[0]);
        newTime.setMinutes(timeValue.split(':')[1]);
        newTime.setSeconds('0');

        // check the time is in between range or not
        const from = new Date(range[0]?.startTime);
        from.setHours(range[0]?.startTime.split('T')[1].substring(0, 2));
        from.setMinutes(range[0]?.startTime.split('T')[1].substring(3, 5));
        from.setSeconds('0');

        const to = new Date(range[0]?.endTime);
        to.setHours(range[0]?.endTime.split('T')[1].substring(0, 2));
        to.setMinutes(range[0]?.endTime.split('T')[1].substring(3, 5));
        to.setSeconds('0');

        console.log('range check:', {
            range: range[0],
            newTime: newTime,
            from: from,
            to: to,
        });

        // checking time range
        if (newTime >= from && newTime <= to) {
            console.log('time in range');
            setDateTimeProps({ ...dateTimeProps, timeError: '' });
        } else {
            console.log('time in out of range');

            const dayType = newTime.getDay();
            const fromTime = from.toTimeString().substring(0, 5);
            const toTime = to.toTimeString().substring(0, 5);
            setDateTimeProps({
                ...dateTimeProps,
                timeError: `Must be after ${fromTime} and before ${toTime}`,
            });
        }
    };

    // handle date change
    const handleDate = val => {
        console.log('selected date => ', val);
        console.log('time => ', time);
        publish('RESET_API_ERROR');
        if (val == null) {
            setDate(null);
        } else {
            setDate(val);
        }

        if (val >= dateTimeProps.minDate && val <= dateTimeProps.maxDate) {
            setDateTimeProps({
                ...dateTimeProps,
                dateError: '',
            });
        } else {
            setDateTimeProps({
                ...dateTimeProps,
                dateError: 'Please enter a date within the next 30 days',
            });
        }
        Boolean(time) && handleTime(time, val);
    };

    const graphql = useApolloClient();

    const onload = async () => {
        try {
            const data = await getPortingTimes({ graphql });
            const totalAvailDates = data?.portingHours.length;
            setDateTimeProps({
                minDate: new Date(data.portingHours[0].date),
                maxDate: new Date(data.portingHours[totalAvailDates - 1].date),
                range: data.portingHours,
            });

            if (reschedule) {
                setDate(new Date(portData?.portStartDateTime?.split('T')[0]));
                setTime(portData?.portStartDateTime?.split('T')[1]?.substring(0, 5));
            } else {
                setTime('');
            }
            time && handleTime(time);
        } catch (error) {
            setDateTimeProps({
                ...dateTimeProps,
                timeError: 'Something went wrong!',
            });
            console.log(error);
        }
    };

    const getTime = () => {
        const dateTime = new Date(date);
        dateTime.setHours(time.split(':')[0]);
        dateTime.setMinutes(time.split(':')[1]);
        dateTime.setSeconds('0');
        return dateTime.toISOString().split('T')[0] + 'T' + time + ':00Z';
    };

    useEffect(() => {
        onload();
    }, []);

    useEffect(() => {
        setDateTimeProps({
            ...dateTimeProps,
        });
    }, [portData]);

    useEffect(() => {
        if (!Boolean(dateTimeProps.dateError) && !Boolean(dateTimeProps.timeError)) {
            if (Boolean(time) && Boolean(date)) {
                portIn.setData({ ...portIn.value, portStartDateTime: getTime() });
                setEnable({ step4: true });
            }
        } else {
            setEnable({ step4: false });
        }
    }, [time, date]);

    const classes = useStyle({});

    return (
        <Grid container spacing={2} className={classes.dateTimecontainer}>
            <Grid item md={6} xs={6}>
                <DateField
                    id='schedule-move-date'
                    label='Date'
                    onChange={handleDate}
                    maxDate={dateTimeProps.maxDate}
                    minDate={dateTimeProps.minDate}
                    value={
                        date != null && date != 'null'
                            ? moment(date).isValid()
                                ? moment(date)
                                : null
                            : null
                    }
                    error={Boolean(dateTimeProps.dateError)}
                    errorMessage={dateTimeProps.dateError}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <CustomTextField
                    id='schedule-move-time'
                    label='Time'
                    value={time}
                    type={date && date != 'null' ? 'time' : 'text'}
                    disabled={date && date != 'null' ? false : true}
                    InputLabelProps={{
                        shrink: date && date != 'null' ? true : false,
                    }}
                    InputProps={{
                        endAdornment:
                            date && date != 'null' ? (
                                ''
                            ) : (
                                <InputAdornment position='end'>
                                    <AccessTimeIcon className={classes.clockIcon} />
                                </InputAdornment>
                            ),
                    }}
                    required
                    onChange={e => handleTime(e.target.value)}
                    error={Boolean(dateTimeProps.timeError)}
                    errorMessage={dateTimeProps.timeError}
                    // className={`otp-input ${classes.textField}`}
                />
            </Grid>
        </Grid>
    );
};

export default ScheduleMove;
