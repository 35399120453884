import { Box } from '@material-ui/core';
import React from 'react';
import EirButton from '../../../theme/EirButton';
import EirButtonLink from '../../../theme/EirButtonLink';

const CustomModalFooter = ({ cta1, cta2, apiError, link, setLinkStatus, styles }) => {
    return (
        <Box className={`${styles.footer} ${Boolean(cta2.text) ? '' : styles.MB}`}>
            <Box className={`${styles.apiError}`}>{apiError}</Box>
            {Boolean(link?.text) && <EirButtonLink {...link} setLinkStatus={setLinkStatus} />}
            {Boolean(cta1.text) && (
                <EirButton
                    // className={`${styles.cta} ${!cta1.disable ? styles.cta1 : styles.cta1Disable}`}
                    style={{ padding: '0.5rem 1.5rem' }}
                    variants='primaryButtonMedium'
                    disabled={cta1.disable}
                    onClick={cta1.action}
                >
                    {cta1.text}
                </EirButton>
            )}
            {Boolean(cta1.text) && Boolean(cta2.text) && (
                <Box className={`${styles.cta} ${styles.cta2}`}>
                    <span
                        style={{
                            cursor: 'pointer',
                            height: Boolean(cta2.text) ? '' : '24px',
                            display: 'block',
                        }}
                        onClick={cta2.action}
                    >
                        {cta2.text}
                    </span>
                </Box>
            )}
        </Box>
    );
};

export default CustomModalFooter;
