import React, { useState, useEffect } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import CustomModal from '../eir-porting/CustomModal/CustomModal';
import { useWindowDimension } from '../../hooks/windowDimensions';
import useStyles from './styles';
import failure from '../images/sad.png';
import ArrowToRight from '../images/arrow-to-right.png';
import success from '../images/Large-icon-Success.png';
import CustomUpdatedPopUpModal from '../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import { cancelAPV } from '../my-plan/manage-tv-pin/tvisPinValue.util';
import { useApolloClient } from '@apollo/client';
import MyEirErrorHandle from '../eir-porting/MyEirErrorHandle';

const CancelAPVPopup = ({ linkName, flag, numCards, isActiveTv }) => {
    const classes = useStyles();
    const graphql = useApolloClient();
    const [openCancel, setOpenCancel] = useState(false);
    const [openErrorApv, setOpenErrorApv] = useState(false);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const [width, height] = useWindowDimension();

    const handleOpenLink = () => {
        setOpenCancel(true);
        setCTA1({ text: 'Yes, cancel Amazon Prime', action: handleConfirm, disable: false });
        setCTA2({ text: "No, i'll keep it", action: handleInactiveCancel });
    };

    const handleInactiveCancel = () => {
        setOpenCancel(false);
    };

    const handleToggleCancel = () => {
        setOpenCancel(!openCancel);
    };

    const handleConfirm = () => {
        cancelAPVRequest();
    };

    const handleToggleSuccessPopup = () => {
        setOpenSuccessPopup(!openSuccessPopup);
    };

    const handleAltSuccessCta = () => {
        setOpenSuccessPopup(false);
    };

    //close icon
    const handleClose = () => {
        setOpenCancel(false);
    };

    const cancelAPVRequest = async () => {
        try {
            const data = await cancelAPV({
                graphql,
            });
            if (data.cancelAPV && data.cancelAPV === 202) {
                setOpenCancel(false);
                setOpenSuccessPopup(!openSuccessPopup);
            } else {
                setOpenCancel(false);
                setOpenErrorApv(!openErrorApv);
            }
        } catch (error) {
            setOpenCancel(false);
            setOpenErrorApv(!openErrorApv);
            console.error('cancel APV error', error);
        }
    };

    return (
        <Box id='inactive-sim'>
            <Box className={classes.linkStyle}>
                <Typography
                    component='span'
                    className={
                        flag && flag === 'redesign' ? classes.redesignCancelLink : classes.primeLink
                    }
                    style={
                        isActiveTv
                            ? numCards == 2 && width > 1020
                                ? {}
                                : {
                                      width: 'max-content',
                                      position: 'absolute',
                                      height: '49px',
                                      bottom: '4px',
                                      display: 'flex',
                                      alignItems: 'baseline',
                                  }
                            : {}
                    }
                >
                    <Typography
                        onClick={handleOpenLink}
                        className={
                            flag && flag === 'redesign'
                                ? classes.redesignCancelLink
                                : classes.primeLink
                        }
                    >
                        {linkName}
                    </Typography>
                    {isActiveTv && (
                        <Typography component='span' className={classes.pipe}>
                            |
                        </Typography>
                    )}
                    {/* <Box component='img' src={ArrowToRight} alt='arrow' className={classes.arrow} /> */}
                </Typography>
            </Box>

            <CustomModal
                open={openCancel}
                toggle={handleToggleCancel}
                cta1={cta1}
                cta2={cta2}
                header={{ text: 'Cancel Amazon Prime', arrowAction: () => {}, center: true }}
            >
                <Box className={classes.inactiveContainer}>
                    <Box className={classes.imgSad}>
                        <Typography component='img' src={failure} alt='failure' />
                    </Box>
                    <Typography className={classes.inactiveText}>
                        Are you sure you want to cancel your Amazon Prime membership?
                    </Typography>
                    <Link
                        href={`https://www.eir.ie/.content/pdf/terms/Amazon-TCs-010323-Updated.pdf`}
                        target='_blank'
                    >
                        <Typography className={classes.inactiveTextLight}>
                            Terms & Conditions
                        </Typography>
                    </Link>
                </Box>
            </CustomModal>
            <Box id='my-eir-pop-up'>
                <CustomUpdatedPopUpModal
                    open={openSuccessPopup}
                    toggle={handleToggleSuccessPopup}
                    cta1={{ text: 'Close', action: handleAltSuccessCta, disable: false }}
                    cta2={{ text: '', action: () => {} }}
                >
                    <Box className={classes.successPopup}>
                        <Box className={classes.successImg}>
                            <Typography component='img' src={success} alt='success' />
                        </Box>

                        <Typography className={classes.imgTextAdditional}>
                            Your request was submitted and your Amazon Prime membership will be cancelled shortly.
                        </Typography>
                    </Box>
                </CustomUpdatedPopUpModal>
            </Box>
            {openErrorApv ? (
                <MyEirErrorHandle
                    open={openErrorApv}
                    setOpen={setOpenErrorApv}
                    handleClose={handleClose}
                />
            ) : (
                ''
            )}
        </Box>
    );
};

export default CancelAPVPopup;
