import { makeStyles } from '@material-ui/core/styles';
export const useFaqStyle = makeStyles(theme => ({
    faq: {
        backgroundColor: theme.palette.primary.backgroundColor,
        '& .faq-title': {
            // fontFamily: theme.typography.body3Medium.fontFamily,
            // fontStyle: 'normal',
            // // fontWeight: 700,
            // fontSize: '30px',
            // lineHeight: '38px',
            // display: "flex",
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.25px',
            color: theme.palette.primary.heather,
            padding: '32px',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.h4.fontSize,
                lineHeight: '32px',
                padding: '17px 32px 8px 32px',
            },
        },
        '& .faq-content-loading': {
            '& .faq-retry-loading': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        '& .faq-accordion': {
            display: 'block',
            padding: '0px 32px',
            maxWidth: '952px',
            margin: 'auto',

            '@media(max-width: 599px)': {
                padding: '0px 32px',
            },

            // '@media(min-width: 768px)': {
            //     display: 'none',
            // },
            '& .faq-list': {
                padding: '0px !important',

                '@media(max-width: 599px)': {
                    padding: '0px !important',
                },
            },
            '& .faq-card': {
                border: '0',
                borderRadius: '0',
                background: 'inherit',
                minHeight: '55px',
                marginTop: '0px',
                marginBottom: '1px',
                boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,

                '@media(max-width: 599px)': {},

                '&.MuiAccordion-root:before': {
                    backgroundColor: 'transparent',
                },

                '& .faq-card__header': {
                    // fontFamily: theme.typography.body1.fontFamily,
                    cursor: 'pointer',
                    minHeight: '55px',
                    padding: '0 10px',
                    color: theme.palette.primary.heather,
                    textAlign: 'left',
                    // borderBottom: '2px dotted hsla(0,0%,78%,.4)',
                    // boxShadow: "0px 1px 0px #E0E0E0",
                    position: 'relative',

                    '@media(max-width: 599px)': {
                        padding: '0 8.5px',
                    },

                    '& .faq-card__header-content': {
                        fontFamily: theme.typography.body3Medium.fontFamily,
                        // fontStyle: 'normal',
                        fontWeight: theme.typography.body3Medium.fontWeight,
                        fontSize: theme.typography.body3Medium.fontSize,
                        lineHeight: theme.typography.body3Medium.lineHeight,
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.primary.heather,

                        // paddingLeft: "10px",
                        // paddingRight: "90px",
                        // paddingBottom: "10px",

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.subtitle2.fontSize,
                            lineHeight: '18px',
                        },
                    },

                    '& .Mui-expanded': {
                        '@media(max-width: 599px)': {
                            marginTop: '16px',
                            marginBottom: '8px',
                        },
                    },

                    '& .faq-card__header-expandIcon': {
                        // position: 'absolute',
                        padding: 0,
                        margin: 0,
                        // right: '-5px',
                        // top: "50%",
                    },

                    '& .faq-expand-icon': {
                        height: '21px',
                        width: '21px',
                        color: theme.palette.primary.sunset,
                    },
                },
            },

            '& .faq-card__links': {
                border: '0',
                paddingLeft: '0',
                textAlign: 'left',
            },

            '& .faq-card__body': {
                padding: '0px 10px 17px 10px',

                '@media(max-width: 599px)': {
                    padding: '0px 24.5px 16px 8.5px',
                },

                '& .faq-card__menu': {
                    padding: 0,

                    '& .faq-list': {
                        fontFamily: theme.typography.body3.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: theme.typography.body3.fontWeight,
                        fontSize: theme.typography.body3.fontSize,
                        lineHeight: theme.typography.body3.lineHeight,
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.primary.heather,

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.inputLabel.fontSize,
                            lineHeight: theme.typography.inputLabel.lineHeight,
                        },
                    },
                },
            },

            '& .faq-mobile__body': {
                backgroundColor: theme.palette.primary.backgroundColor,
            },
        },
        '& .faq-footer': {
            // minWidth: "233px",
            // minHeight: "94px",
            // background: "grey",
            display: 'inline-flex',
            // padding: "40px 0px",
            position: 'relative',
            marginTop: '40px',
            marginBottom: '68px',

            '@media(max-width: 599px)': {
                marginTop: '40px',
                marginBottom: '58px',
            },

            '& .faq-footer-divider': {
                width: '113px',
                position: 'absolute',
                border: `1px solid ${theme.palette.primary.borderColor}`,
                borderBottom: '0px',
                top: '60%',
                left: '51%',
                transform: 'translate(-50%, -50%) rotate(90deg)',

                '@media(max-width: 599px)': {
                    width: '94px',
                    top: '52%',
                    left: '53.5%',
                },
            },

            '& .faq-help, & .faq-contact': {
                // minWidth: "98px",
                minHeight: '121px',
                // fontFamily: theme.typography.body3Medium.fontFamily,
                // fontStyle: 'normal',
                // // fontWeight: 700,
                // fontSize:theme.typography.body1.fontSize,
                // lineHeight: '24px',
                color: theme.palette.primary.heather,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',

                cursor: 'pointer',

                '@media(max-width: 599px)': {
                    minHeight: '91px',
                    fontSize: theme.typography.subtitle2.fontSize,
                    lineHeight: '24px',
                },

                '& .faq-image': {
                    // background: "pink",
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                },

                '& .faq-help-text, & .faq-contact-text': {
                    marginTop: '2px',
                    marginBottom: '2px',
                },
            },

            '& .faq-help': {
                // background: "red",
                marginRight: '65px',

                '@media(max-width: 599px)': {
                    marginRight: '31.5px',
                },
            },

            '& .faq-contact': {
                // background: "blue",
                marginLeft: '65px',

                '@media(max-width: 599px)': {
                    marginLeft: '31.5px',
                },
            },
        },

        // '@media(max-width: 599px)': {
        //     '& .faq-title': {
        //         fontSize: "24px",
        //         lineHeight: "32px",
        //         padding: "17px 32px 1px 32px",
        //     },
        //     '& .faq-accordion': {
        //         padding: '0px 32px',

        //         '& .faq-list': {
        //             padding: '0px !important',
        //         },
        //         '& .faq-card': {
        //             '& .faq-card__header': {
        //                 padding: '0 8.5px',

        //                 '& .faq-card__header-content': {
        //                     fontSize: "14px",
        //                     lineHeight: "18px",
        //                 }
        //             },
        //         },

        //         '& .faq-card__links': {
        //         },

        //         '& .faq-mobile__body': {
        //         },
        //     },
        //     '& .faq-footer': {
        //         padding: "40px 0px 50px 0px",

        //         '& .faq-footer-divider': {
        //             width: '94px',
        //         },

        //         '& .faq-help, & .faq-contact': {
        //             // minWidth: "98px",
        //             minHeight:"91px",
        //             fontSize: "14px",
        //             lineHeight: "24px",
        //         },

        //         '& .faq-help': {
        //             // background: "red",
        //             marginRight:"31.5px",
        //         },

        //         '& .faq-contact': {
        //             // background: "blue",
        //             marginLeft: "31.5px",
        //         },

        //         // '& .faq-help-text, & .faq-contact-text': {
        //         //     marginBottom: "0",
        //         //     paddingBottom: "2px",
        //         // },

        //     },
        // },
    },
}));
