import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DeviceDeleteModal from './device-details/DeviceDeleteModal';
import DeviceManagementModal from './DeviceManagementModal';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import { styles } from './styles';

const DeviceManagement = props => {
    const [openDeviceManagement, setOpenDeviceManagement] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [isErrorText, setisErrorText] = useState(false);
    const [headerText, setHeaderText] = useState('');
    const [deviceDeleted, setDeviceDeleted] = useState('');
    const [deleteDeviceData, setDeleteDeviceData] = useState({
        deviceId: '',
        deviceName: '',
        deviceActivationDate: '',
    });
    const [enableDelete, setEnableDelete] = useState();

    const classes = styles();

    const toggleDeviceManagement = () => {
        setOpenDeviceManagement(!openDeviceManagement);
        if (openError) {
            setOpenDeviceManagement(true);
            setOpenError(false);
        }
        setisErrorText(false);
    };

    const toggleDelete = () => {
        setOpenDeviceManagement(!openDeviceManagement);
        setOpenDelete(!openDelete);
    };

    return (
        <>
            <Typography
                variant='body2'
                className={
                    props.flag && props.flag === 'redesign'
                        ? classes.redesignDeviceText
                        : classes.deviceManagementToggleText
                }
                onClick={toggleDeviceManagement}
            >
                Manage my devices
            </Typography>
            {openDeviceManagement && (
                <DeviceManagementModal
                    openDevice={openDeviceManagement}
                    toggle={toggleDeviceManagement}
                    deviceDeleted={deviceDeleted}
                    setDeviceDeleted={setDeviceDeleted}
                    toggleDelete={toggleDelete}
                    setDeleteDeviceData={setDeleteDeviceData}
                    setEnableDelete={setEnableDelete}
                    setOpenDeviceManagement={setOpenDeviceManagement}
                    setHeaderText={setHeaderText}
                    headerText={headerText}
                    setisErrorText={setisErrorText}
                    isErrorText={isErrorText}
                    setOpenError={setOpenError}
                    openError={openError}
                />
            )}
            {openDelete && (
                <DeviceDeleteModal
                    open={openDelete}
                    toggle={toggleDelete}
                    enableDelete={enableDelete}
                    deviceId={deleteDeviceData?.deviceId}
                    deviceName={deleteDeviceData?.deviceName}
                    deviceActivationDate={deleteDeviceData?.deviceActivationDate}
                    setDeviceDeleted={setDeviceDeleted}
                    setHeaderText={setHeaderText}
                    setisErrorText={setisErrorText}
                />
            )}
            {openError ? <MyEirErrorHandle open={openError} setOpen={setOpenError} /> : ''}
        </>
    );
};

export default DeviceManagement;
