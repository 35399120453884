import { makeStyles } from '@material-ui/core/styles';

export const useMyPlanStyle = makeStyles(theme => ({
    myPlanParent: {
        paddingLeft: '30px',
        paddingRight: '30px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
            marginTop: '0px',
            marginBottom: '36px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            margin: '40px !important',
        },
    },

    [theme.breakpoints.down('xs')]: {
        myPlanContainerInactive: {
            minHeight: '500px',
            // maxWidth: 'calc(312 / 360 * 100vw)',
            opacity: '0',
        },

        myPlanWrapperInactive: {
            maxHeight: '500px !important',
            background: 'white !important',
            borderRadius: '10px',
            // marginLeft: '30px',
            maxWidth: 'calc(312 / 360 * 100vw)',
            marginLeft: 'auto',
            marginRight: 'auto',
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        },
    },

    myPlanWrapper: {
        marginTop: '40px !important',
        marginBottom: '40px !important',
        margin: 'auto',
        maxWidth: '1144px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0px !important',
            // paddingLeft: '24px',
            // paddingRight: '24px',
            // marginBottom: '36px',
            maxWidth: 'calc(312 / 360 * 100vw)',
        },

        '@media(min-width:600px) and (max-width:1019px)': {
            maxWidth: '570px',
            margin: '0 auto',
        },
    },

    myPlanContainer: {
        margin: 'auto',
        background: theme.palette.primary.white,
        minHeight: '558px',
        maxWidth: '552px',
        boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        borderRadius: '10px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            minHeight: '500px',
            // maxWidth: 'calc(312 / 360 * 100vw)',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            maxWidth: '570px',
        },
    },

    myPlanTitle: {
        fontFamily: theme.typography.h5.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: theme.typography.h3.fontSize,
        lineHeight: '38px',
        textAlign: 'center',
        alignItems: 'center',
        color: theme.palette.primary.heather,
        //marginTop: '44px',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
            //marginTop: '17px',
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '32px',
        },
    },

    titleAndIcon: {
        paddingTop: '44px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '17px',
        },
    },

    planIcon: {
        background: `url("data:image/svg+xml,%3Csvg width='30' height='36' viewBox='0 0 30 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 34.1837L28.75 27.9337V15.4337L15 21.6837L1.25 15.4337V27.9337L15 34.1837Z' stroke='%23D111C9' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 12.5938L1.25 15.4337' stroke='%23D111C9' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M28.75 15.4337L22.5 12.5938' stroke='%23D111C9' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 21.6838V34.1838' stroke='%23D111C9' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 11.9999V0.999878' stroke='%23D111C9' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 7.29089L15 12.2909L10 7.29089' stroke='%23D111C9' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") `,
        display: 'block',
        height: '35px',
        width: '30px',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        bottom: '-3px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    myPlanModalBody: {
        width: '100%',
        margin: '42px auto 0',
        maxWidth: 392,
        [theme.breakpoints.down('xs')]: {
            margin: '12px 0 0',
            padding: '0 16px',
        },
    },

    accountNumber: {
        display: 'flex',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '8px',
            justifyContent: 'center',
            //  fontSize:theme.typography.body1.fontSize,
        },
    },

    accountNum: {
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.body1.fontSize,
        },
    },

    myPlanItem: {
        maxWidth: 390,
        margin: '0 auto',
        padding: '16px 0',
    },

    myPlanItemLast: {
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    },

    mobile: {
        marginTop: '8px',
        marginBottom: '35px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '24px',
        },
    },

    mobileSecond: {
        marginTop: '24px',
        marginBottom: '35px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0px !important',
            marginBottom: '24px',
        },
    },

    contractMobile: {
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '4px',
        },
    },

    contractPlan: {
        marginBottom: '1px',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        display: 'flex',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            //marginBottom: '8px',
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
        },
    },
    wrapper: {
        // width: '312px',
        '&:last-child .myPlanItem': {
            // background: 'pink',
            border: 'none',
        },
    },

    broadband: {
        marginTop: '26px',
        display: 'flex',
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        //marginTop: '24px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '24px !important',
            fontSize: theme.typography.body1.fontSize,
            //lineHeight: '22px'
        },
    },
    wrapperClass: {
        width: '22px',
    },

    modalBroadbandImage: {
        alignItems: 'flex-end',
        display: 'flex',
    },
    broadbandLandline: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        // fontWeight: 700,
        color: theme.palette.primary.heather,
        textAlign: 'left',
        padding: '0.3rem 5.6rem, 0.1rem, 1.9rem',
        marginLeft: '9.7px',
    },
    broadbandDescription: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        // fontWeight: 700,
        lineheight: '18px',
        color: theme.palette.primary.heather,
        textAlign: 'left',
        //padding: '0.7rem 0rem 1rem 0rem',
        paddingTop: '0.6rem',
        marginLeft: '2px',
    },
    bandNumber: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: 600,
        textAlign: 'left',
        color: theme.palette.primary.heather,
        marginTop: '6px',
        // [theme.breakpoints.down('sm')]: {borderLineAbove
    },
    borderLineAbove: {
        color: theme.palette.primary.white,
        boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,
        width: '100%',
        height: '1px',
        marginTop: '10px',
        background: theme.palette.primary.boxShadow2Dark,
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    borderLineBelow: {
        color: theme.palette.primary.white,
        boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,
        width: '150%',
        height: '1px',
        marginTop: '29px',
        background: theme.palette.primary.boxShadow2Dark,
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    modalTvImage: {
        alignItems: 'flex-end',
        display: 'flex',
        marginTop: '10px',
    },
    TV: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        // fontWeight: 700,
        color: theme.palette.primary.heather,
        textAlign: 'left',
        // padding:'0rem 5.6rem 0.1rem 1.9rem',
        marginLeft: '9.7px',
        marginTop: '10px',
    },
    TvDescription: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        // fontWeight: 700,
        lineheight: '18px',
        color: theme.palette.primary.heather,
        textAlign: 'left',
        padding: '0.7rem 1rem 1rem 0rem',
    },

    modalMobileImage: {
        alignItems: 'flex-end',
        display: 'flex',
    },
    Mobile: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        // fontWeight: 700,
        color: theme.palette.primary.heather,
        textAlign: 'left',
        // padding:'0rem 5.6rem 0.1rem 1.9rem',
        marginLeft: '14px',
        marginTop: '14px',
    },
    mobileDescription: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        // fontWeight: 700,
        lineheight: '18px',
        color: theme.palette.primary.heather,
        textAlign: 'left',
        padding: '0.7rem 1rem 1rem 0rem',
    },
    mblNumber: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: 600,
        color: theme.palette.primary.heather,
        textAlign: 'left',
        lineHeight: '0.1px',
    },

    broadbandSecond: {
        marginTop: '12px',
        display: 'flex',
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        //marginTop: '24px',
        [theme.breakpoints.down('xs')]: {
            //marginTop: '58px',
            fontSize: theme.typography.body1.fontSize,
            //lineHeight: '22px'
        },
    },

    vectorIcon: {
        paddingLeft: '11.33px',
    },

    pinAndNumber: {
        display: 'flex',
        //marginTop: '2px'
    },

    pinAndPuk: {
        //display: 'flex',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        color: theme.palette.primary.sunset,
        opacity: '0.9',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'left',
        // marginTop: '19px',
        display: 'flex',
        alignItems: 'center',

        //borderRight: '1px solid #D0D0D0',   to be shown after all APIs are done
        // paddingRight: '16px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
        },
    },
    verticalLine: {
        color: theme.palette.primary.boxShadow2Dark,
        padding: '0     10px',
    },

    oldNumber: {
        //display: 'flex',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '27px',
        color: theme.palette.primary.sunset,
        opacity: '0.9',
        textDecoration: 'underline',
        cursor: 'pointer',
        // paddingLeft: '16px',
        // display: 'none',
        textAlign: 'left',
        // [theme.breakpoints.down('xs')]: {
        //     fontSize:theme.typography.subtitle2.fontSize,
        //     lineHeight: '22px',
        // },
    },

    amazonPrime: {
        fontFamily: theme.typography.h6.fontFamily,
        cursor: 'pointer',
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '39px',
        color: theme.palette.primary.sunset,
        opacity: '0.9',
        textDecoration: 'none',
        //paddingLeft: '16px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            //fontSize:theme.typography.subtitle2.fontSize,
            lineHeight: '29px',
        },
    },
    myPlanItemLink: {},
    mobileLink: { display: 'flex' },
    eirTVPin: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 600,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '2px',
        color: theme.palette.primary.sunset,
        opacity: '0.9',
        textDecoration: 'underline',
        //paddingLeft: '16px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '1px',
        },
    },

    moreDetails: {
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.typography.h6.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.sunset,
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex', //was inline-block before
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '38px',
        '& p': {
            margin: 0,
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 16px',
        },
    },

    DownIcon: {
        color: theme.palette.primary.sunset,
        //position: 'absolute',
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: '12px',
    },

    seeMore: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '58px',
        },
    },

    errorMessage: {
        margin: '0 auto',
        paddingTop: '20px',
    },

    contractsLoader: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    contractsContentLoading: {
        margin: '0 auto',
        paddingTop: '50px',
    },

    contractsLoadingAnimation: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    myPlanTVLinks: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '10px',
    },
    manageMyPIN: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,
        opacity: 0.9,
        cursor: 'pointer',
    },
    myPlanTVLinkSeperator: {
        width: '1px',
        height: '16px',
        background: theme.palette.primary.boxShadow2Dark,
    },
}));
