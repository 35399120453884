import React, { useEffect, useState } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import { useMyAccountStyle } from '../css/useMyAccountStyle';
import mySimIcon from '../images/mySimIcon.png';
import SimReplacementContainer from '../eir-sim-replacement/SimReplacementContainer';
import { getSimEligibilityCheck } from '../eir-sim-replacement/simEligibilityCheck.util';
import { useApolloClient } from '@apollo/client';
import { getSimCardCharges } from '../eir-sim-replacement/simCardCharges.util';
import ActivateSimReplacementContainer from '../eir-sim-replacement/ActivateSimReplacementContainer';

const SimReplacementTile = ({ ContactDetails, msisdn, serviceId }) => {
    const classes = useMyAccountStyle();
    const graphql = useApolloClient();
    const [updateOrderLink, setUpdateOrderLink] = useState('hide');
    const [linkFlag, setLinkFlag] = useState(false);
    const [simCost, setSimCost] = useState(0);
    const [reorderForm, setReorderForm] = useState();
    const [reorderCost, setReorderCost] = useState(0);

    //order OR re-order link

    const getSimEligibilityResponse = async () => {
        try {
            const data = await getSimEligibilityCheck({
                graphql,
                token: sessionStorage.getItem('access_token'),
                serviceId: serviceId,
            });
            const datas = data.getSimCardEligibilty;

            if (
                data.getSimCardEligibilty.length === 0 ||
                data?.getSimCardEligibilty[0]?.status === 'DONE' ||
                data?.getSimCardEligibilty[0]?.status === 'ERROR'
            ) {
                setUpdateOrderLink('Order');
            } else if (data?.getSimCardEligibilty[0]?.status === 'IN_DELIVERY') {
                setUpdateOrderLink('Re-order');
                setLinkFlag(true);
                setReorderForm(data.getSimCardEligibilty[0]);
                setReorderCost(data.getSimCardEligibilty[0].charges[0].defaultPriceVatIncluded);
            }else if (
                data?.getSimCardEligibilty[0]?.status === 'IN_ACTIVATION' ||
                data?.getSimCardEligibilty[0]?.status === 'VALIDATED' ||
                data?.getSimCardEligibilty[0]?.status === 'CANCELED'
            ) {
                setUpdateOrderLink('hide');
            }
        } catch (error) {
            console.log('Sim eligibility error', error);
        }
    };

    //cost charges for sim

    const getSimChargesResponse = async () => {
        try {
            const data = await getSimCardCharges({
                graphql,
                token: sessionStorage.getItem('access_token'),
                serviceId: serviceId,
            });
            setSimCost(data.getSimSwapCharges.charges[0].defaultPriceVatIncluded);
        } catch (error) {
            console.log('Sim charges error', error);
        }
    };

    useEffect(() => {
        getSimEligibilityResponse();
        getSimChargesResponse();
    }, [msisdn]);

    return (
        <Box
            className={
                `simTile ` +
                `${linkFlag ? ` sim-link ` : ''}` +
                `${updateOrderLink === 'Re-order' ? 'block' : ''}`
            }
        >
            <Box className='sim'>
                <Box component='img' src={mySimIcon} alt='mysim' />
                <Box className='phonenumber'>{msisdn}</Box>
            </Box>
            <Grid
                item
                className={
                    `details-topic-order ` + `${updateOrderLink === 'Re-order' ? 'no-flex' : ''}`
                }
            >
                {updateOrderLink === 'Order' ? (
                    <SimReplacementContainer
                        linkName={'Order'}
                        getSimEligibilityResponse={getSimEligibilityResponse}
                        ContactDetails={ContactDetails}
                        serviceId={serviceId}
                        simCost={simCost}
                        flag={false}
                    />
                ) : updateOrderLink === 'Re-order' ? (
                    <>
                        <Box item xs={12} md={12} component='span' className='Activate-SIM'>
                            <ActivateSimReplacementContainer serviceId={serviceId} />
                        </Box>
                        <Box component='span' className='verticalLine'>
                            |
                        </Box>
                        <Box className='tile-reorder'>
                            <SimReplacementContainer
                                linkName={'Re-order'}
                                reorderForm={reorderForm}
                                ContactDetails={ContactDetails}
                                serviceId={serviceId}
                                reorderCost={reorderCost}
                                flag={true}
                                getSimEligibilityResponse={getSimEligibilityResponse}
                            />
                        </Box>
                    </>
                ) : (updateOrderLink === 'hide' ? '' : (
                    <Box item xs={12} md={12} component='span' className='Activate-SIM'>
                        <ActivateSimReplacementContainer serviceId={serviceId} />
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};

export default SimReplacementTile;
