import CustomTextField from '../../../sharedcomponents/inputs/text-field/CustomTextField';
import CustomSelect from '../../../sharedcomponents/inputs/select/CustomSelect';
import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { validate } from 'graphql';
import { authorisedUserStyle } from './authorisedUserStyle';

const AddAuthorisedUserInput = ({ securityQuestions, setdisableCTA }) => {
    const [firstname, setValidFirstName] = useState('');
    const [lastname, setValidLastName] = useState('');
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [answer, setAnswer] = useState('');
    const [answerError, setAnswerError] = useState(false);
    const [sequrityQuestion, setSecurityQuestion] = useState('');
    const [error, setErrors] = useState('');
    const classes = authorisedUserStyle();

    const validFirstNameFormat = /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒšŽ \\s`&,./()0-9-\"]+$/;
    const validLastNameFormat = /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒšŽ \\s`&,./()0-9-\"]+$/;

    useEffect(() => {
        if (firstname == '' || lastname == '' || sequrityQuestion == '' || answer == '') {
            setdisableCTA(true);
        } else {
            if (validFirstNameFormat.test(firstname) && validLastNameFormat.test(lastname)) {
                setdisableCTA(false);
            } else {
                setdisableCTA(true);
            }
        }
    }, [firstname, lastname, sequrityQuestion, answer]);

    function validateLastName(valFun1, val1) {
        if (validLastNameFormat.test(val1)) {
            valFun1(false);
            setdisableCTA(true);
        } else {
            setdisableCTA(false);
            valFun1(true);
        }
    }
    function validateName(valFun, val) {
        if (validFirstNameFormat.test(val)) {
            valFun(false);
            setdisableCTA(true);
        } else {
            setdisableCTA(false);
            valFun(true);
        }
    }

    const handleFirstName = event => {
        validateName(setFirstNameError, event.target.value);
        setValidFirstName(event.target.value);
    };

    const handleLastName = event => {
        validateLastName(setLastNameError, event.target.value);
        setValidLastName(event.target.value);
    };

    const handleAnswer = e => {
        if (e.target.value === '') {
            setAnswerError(true);
        } else {
            setAnswerError(false);
        }
        setAnswer(e.target.value);
    };

    const handleSequrityQuestion = e => {
        setSecurityQuestion(e.target.value);
    };
    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
                <Box id='porting-number' className={classes.textFields}>
                    <CustomTextField
                        id='first-name'
                        label='First name'
                        onChange={handleFirstName}
                        value={firstname}
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        error={firstnameError}
                        errorMessage='Please enter valid first name'
                        required
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box id='porting-number' className={classes.textFields}>
                    <CustomTextField
                        id='last-name'
                        label='Last name'
                        onChange={handleLastName}
                        value={lastname}
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        error={lastnameError}
                        errorMessage='Please enter valid last name'
                        required
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box id='porting-number' className={classes.textFields}>
                    <CustomSelect
                        id='security-question'
                        data={securityQuestions}
                        className={`${classes.selectField}`}
                        value={sequrityQuestion}
                        onChange={handleSequrityQuestion}
                        placeholder='Security question'
                        required
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box id='porting-number' className={classes.textFields}>
                    <CustomTextField
                        id='answer'
                        label='Answer'
                        onChange={handleAnswer}
                        value={answer}
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        error={answerError}
                        errorMessage='Please enter a security answer'
                        required
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default AddAuthorisedUserInput;
