import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import { getNameAndAccountID } from '../getNameAndAccountNumber';

export async function getOffer(graphql, catalogOfferCode) {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_OFFER_DETAILS,
            variables: {
                catalogOfferCode: catalogOfferCode,
            },
        });
        const data = res.data;
        if (data.getOfferDetails) {
            return data.getOfferDetails;
        } else {
            throw 'getOfferDetails was not returned from API';
        }
    } catch (error) {
        console.log('Error while fetching offer details');
    }
}

export async function getEligibility(graphql, subscriptionId) {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_OFFER_ELIGIBILITY,
            variables: {
                token: sessionStorage.getItem('access_token'),
                subscriptionId: subscriptionId,
            },
        });
        const data = res.data;
        if (data.getOfferEligibilityDetails) {
            return data.getOfferEligibilityDetails;
        } else {
            throw 'getOfferEligibilityDetails was not returned from API';
        }
        console.log('eligible', data);
    } catch (error) {
        console.log(error);
    }
}

export async function getPinPuk(graphql, serviceId) {
    try {
        const response = await graphql.query({
            query: ENDPOINT.GET_MY_DETAILS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                serviceId: serviceId.toString(),
            },
        });
        const data = response.data;
        console.log('msidnand offers ==> ', data);
        if (data.getMyDetails) {
            return data.getMyDetails;
        } else {
            // totalServices += 1;
            throw 'getMyDetails was not returned from API';
        }
    } catch (error) {
        console.log('error while fetching getOfferAndPinPuk for service id', serviceId);
    }
}
export async function getApvTVServiceId(graphql) {
    try {
        if (
            sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == ''
        ) {
            const accountIDResponse = await getNameAndAccountID(graphql);
        }

        const { data } = await graphql.query({
            query: ENDPOINT.GET_APV_TV_SERVICE_ID,
            variables: {
                account: sessionStorage.getItem('LoggedinUserAccountNumber'),
                token: sessionStorage.getItem('access_token'),
            },
        });
        if (data.getTVServiceId) {
            return data.getTVServiceId;
        } else {
            throw 'something went wrong while fetching getApvTVServiceId';
        }
    } catch (err) {
        console.error('Error while fetching getApvTVServiceId', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}

export async function checkApvEligibility(graphql, serviceId) {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.CHECK_APV_ADDON_ELIGIBILITY,
            variables: {
                serviceId: `${serviceId}`,
                token: sessionStorage.getItem('access_token'),
                channelCode: 'MYEIR',
            },
        });
        if (data.isEligibleForApv) {
            return data.isEligibleForApv;
        } else {
            throw 'something went wrong while checking apvEligibility';
        }
    } catch (err) {
        console.error('Error while checking apvEligibility', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}
