import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useEmailChangeStyle } from '../css/useEmailChangeStyle';
import alreadyRegisteredIcon from '../../sharedcomponents/images/alreadyRegisteredIcon.png';
import EirButton from '../../theme/EirButton';

function MyEirChangePasswordAndEmailToggle(props) {
    const classes = useEmailChangeStyle();
    return (
        <>
            <React.Fragment>
                <Modal
                    className={'emailSent-main'}
                    // wrapClassName="changeEmail-wrap"
                    wrapClassName={classes.ChangeEmailWrapper}
                    modalClassName={'emailSent-modal'}
                    backdropClassName={'emailSent-backdrop'}
                    contentClassName={'emailSent-content'}
                    isOpen={props.isopen}
                    toggle={props.toggle}
                    centered
                >
                    <ModalBody className='emailSent-body'>
                        <div className='emailSent-body-header'>
                            <div className='emailSent-header-text'>{props.heading}</div>
                        </div>
                        <div className='emailSent-body-content'>
                            <div className='emailSent-body-content-image'>
                                <img
                                    className='checkbox-image'
                                    src={alreadyRegisteredIcon}
                                    alt='Success icon'
                                />
                            </div>
                            <div className='emailSent-body-content-title'>
                                {props.newsContentText}
                            </div>
                            {props.subtext != '' ? (
                                <div className='emailSent-body-content-text'>
                                    {props.subtext}{' '}
                                    <span style={{ color: theme.palette.primary.heather }}>
                                        {props.email}
                                    </span>
                                    .
                                </div>
                            ) : (
                                ''
                            )}
                            <div className='emailSent-body-content-text'>
                                {props.subtextContinued}
                            </div>
                        </div>
                        <div className='emailSent-body-footer'>
                            <EirButton
                                style={{ padding: '0.5rem 1.875rem' }}
                                variants='primaryButtonMedium'
                                // className={
                                //     'emailSent-body-footer-cta primary-cta' +
                                //     (true ? ' cta-enabled' : ' cta-diabled')
                                // }
                                disabled={false}
                                onClick={() => {
                                    props.toggle();
                                }}
                            >
                                {props.buttonText}
                            </EirButton>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        </>
    );
}

export default MyEirChangePasswordAndEmailToggle;
