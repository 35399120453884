import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { useDisplayPINPUKStyle } from '../css/useDisplayPINPUKStyle';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function MyEirDisplayPINPUK(props) {
    const [isopen, setIsopen] = useState(false);
    function toggle() {
        setIsopen(!isopen);
    }
    const classes = useDisplayPINPUKStyle();

    return (
        <React.Fragment>
            <div
                className={
                    props.flag && props.flag === 'redesign'
                        ? classes.redesignLink
                        : 'details-topic-PinPuk'
                }
                onClick={toggle}
            >
                PIN & PUK
            </div>
            <Modal
                className='pinPuk-main'
                // wrapClassName="pinPuk-wrap"
                wrapClassName={classes.PinPukWrapper}
                modalClassName='pinPuk-modal'
                backdropClassName='pinPuk-backdrop'
                contentClassName='pinPuk-content'
                isOpen={isopen}
                toggle={toggle}
                centered
            >
                <ModalBody className='pinPuk-body'>
                    <div className='pinPuk-body-header'>
                        <ArrowBackIcon className='pinPuk-header-back-icon' onClick={toggle} />
                        <div className='pinPuk-header-text'>My PIN and PUK</div>
                    </div>
                    <div className='pinPuk-body-content'>
                        <div className='pinPuk-pin-info'>
                            <div className='pinPuk-body-content-header'>Your PIN is</div>
                            <div className='pinPuk-body-content-info'>{props.pin}</div>
                        </div>
                        <div className='pinPuk-puk-info'>
                            <div className='pinPuk-body-content-header'>Your PUK is</div>
                            <div className='pinPuk-body-content-info'>{props.puk}</div>
                        </div>
                        <div className='pinPuk-pin-description'>
                            <div className='pinPuk-body-content-header'>What is my PIN?</div>
                            <div className='pinPuk-body-content-description'>
                                We set the above PIN (Personal Identifcation Number) code to protect
                                your SIM card. It may nolonger be the PIN if you changed it on your
                                device.
                            </div>
                        </div>
                        <div className='pinPuk-puk-description'>
                            <div className='pinPuk-body-content-header'>What is my PUK?</div>
                            <div className='pinPuk-body-content-description'>
                                If you enter a wrong PIN too many times your phone will be locked.
                                You can use your PUK (Personal Unblocking Key) code to unlock your
                                phone.
                            </div>
                        </div>
                    </div>
                    <div className='pinPuk-body-footer'>
                        <button className='pinPuk-body-footer-cta' onClick={toggle}>
                            Close
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default MyEirDisplayPINPUK;
