import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Controller, useForm, FormContext } from 'react-hook-form';
import { useChangeBillingAddress } from '../css/useChangeBillingAddress';
import { useHistory } from 'react-router-dom';
import MyEirRegisterHeader from '../../sharedcomponents/MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import { MyEirChangeBillingAddressFinder } from './MyEirChangeBillingAddressFinder';
import MyEirChangeBillingAddress from './MyEirChangeBillingAddress';

function MyEirChangeAddressContainer(props) {
    const [isOpen, setIsopen] = useState(false);
    const classes = useChangeBillingAddress();
    function toggle() {
        setIsopen(!isOpen);
    }
    const [step, setStep] = useState(0);
    const [addressData, setAddressData] = useState(false);
    const [manualaddress, setManualAddress] = useState(false);

    const resetStep = () => {
        if (step !== 0) {
            setStep(0);
            props.toggle();
        }
    };

    const handleBack = () => {
        if (step == 0) {
            props.toggle();
            return;
        }
        setStep(step - 1);
    };

    const handleNext = (jumpTo = undefined) => {
        if (jumpTo != undefined) setStep(jumpTo);
        else {
            setStep(step + 1);
        }
    };

    const resetToDashboard = () => {
        if (step !== 0) {
            setStep(0);
        }
    };

    return (
        <React.Fragment>
            <Modal
                className='changeAddress-main'
                wrapClassName={classes.changeAddressWrapper}
                modalClassName='changeAddress-modal'
                backdropClassName='changeAddress-backdrop'
                contentClassName='changeAddress-content'
                isOpen={props.isOpen}
                toggle={props.toggle}
                onClosed={resetToDashboard}
                centered
            >
                <ModalBody className='changeAddress-body'>
                    <div className='changeAddress-body-content'>
                        <MyEirRegisterHeader
                            headerText={'Change billing address'}
                            handleBackButton={handleBack}
                        />

                        {
                            {
                                0: (
                                    <MyEirChangeBillingAddressFinder
                                        handleBackButton={handleBack}
                                        handleNext={handleNext}
                                        setAddressData={setAddressData}
                                        setManualAddress={setManualAddress}
                                    />
                                ),

                                1: (
                                    <MyEirChangeBillingAddress
                                        handleBackButton={handleBack}
                                        handleNext={handleNext}
                                        resetStep={resetStep}
                                        addressData={addressData}
                                        isManual={manualaddress}
                                        toggle={props.toggle}
                                    />
                                ),
                            }[step]
                        }
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default MyEirChangeAddressContainer;
