import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_MY_PAYMENT_METHOD_DETAILS: gql`
        query getPaymentMethod($token: String!, $account_id: String!) {
            getPaymentMethod(token: $token, account_id: $account_id) {
                accountId
                brand
                payerContactUuid
                payerRef
                paymentMethods {
                    canceled
                    canceledAt
                    default
                    expirationDate
                    partialDigits
                    paymentMethodId
                    paymentMethodType
                    status
                    providerReference
                    type
                    iban
                }
            }
        }
    `,
};

export const getPaymentMethodApi = async ({ graphql }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_MY_PAYMENT_METHOD_DETAILS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                account_id: sessionStorage.getItem('LoggedinUserAccountID'),
            },
        });
        return res;
    } catch (error) {
        throw new Error(error);
    }
};
