import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    enabledLink: {
        padding: '0.5rem 1.5rem',
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        textTransform: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.sunset,
        color: theme.palette.primary.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.sunset30,
            cursor: 'pointer',
        },
    },
    disabledLink: {
        padding: '0.5rem 1.5rem',
        borderRadius: '0.375rem',
        fontFamily: 'eirsans-bold',
        fontWeight: 'inherit',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        textTransform: 'none',
        backgroundColor: theme.palette.primary.gray550,
        cursor: 'default',
        color: theme.palette.primary.white,
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

const EirButtonLink = props => {
    const styles = useStyles();

    const handleClick = () => {
        props.setLinkStatus(true);
    };

    return (
        <>
            {props.state ? (
                <Box className={styles.disabledLink}>{props.text}</Box>
            ) : (
                <a
                    href={props.value}
                    target='_blank'
                    onClick={() => {
                        handleClick();
                    }}
                    className={styles.link}
                >
                    <Box className={styles.enabledLink}>{props.text}</Box>
                </a>
            )}
        </>
    );
};

export default EirButtonLink;
