import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import alreadyRegisteredIcon from '../../sharedcomponents/images/alreadyRegisteredIcon.png';
import { useChangeSuccessModal } from '../css/useChangeSuccessModal';
import failureIcon from '../../sharedcomponents/images/failureIcon.png';
import EirButton from '../../theme/EirButton';

export default function MyEirChangeSuccessModal(props) {
    const classes = useChangeSuccessModal();

    const resetStepAndToggle = () => {
        props.resetStep();
        props.toggle1();
    };

    const confirmSwitch = () => {
        console.log(' isUpdated confirm switch', props.isUpdated);
        props.cardUpdate(!props.isUpdated);
        props.toggle1();
    };

    return (
        <React.Fragment>
            <Modal
                className={classes.changeSuccessMain}
                wrapClassName={classes.changeSuccessMainWrapper}
                modalClassName={classes.changeSuccessMainModal}
                backdropClassName={classes.changeSuccessMainBackDrop}
                contentClassName={classes.changeSuccessMainContent}
                isOpen={props.isOpen}
                toggle={resetStepAndToggle}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <ModalBody className={classes.changeSuccessWrapper}>
                    <div className={classes.changeSuccessBody}>
                        <div className={classes.tickWrapper}>
                            <img
                                className={classes.tick}
                                src={props.codeStatus ? alreadyRegisteredIcon : failureIcon}
                                alt='already Registered icon'
                            />
                        </div>
                        <div className={classes.changeSuccessTextWrapper}>
                            <div className={classes.changeSuccessText}>{props.successText}</div>
                        </div>
                        <div className={classes.changeSuccessButton}>
                            <EirButton
                                // className={'primary-cta footer-cta'}
                                style={{ padding: '0 1rem' }}
                                variants='primaryButtonMedium'
                                onClick={
                                    props.unique == undefined ? resetStepAndToggle : confirmSwitch
                                }
                            >
                                {props.buttonText}
                            </EirButton>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
