import React, { Fragment, useState, useRef } from 'react';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    InputAdornment,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import { useRegisterFormStyle } from './useRegisterFormStyle';
import { usePrepayFormStyle } from '../../sharedcomponents/css/usePrepayFormStyle';
// import { ErrorMessage } from '@hookform/error-message';
import HelpIcon from '@material-ui/icons/Help';
import { useApolloClient } from '@apollo/client';
import errormesages from './errormessages';
import {
    EIRCODE_FINDER_URL,
    EIRCODE_LOGO_IMAGE_PATH,
    ADDRESS_FINDER_URL,
    TOOLTIP_TEXT,
} from './constants';
import axios from 'axios';
import ENDPOINT from '../graphql/GraphQLConstants';
import Loading from '../shared/Loading';

export const MyEirAddressFinder = ({ isDeliveryAddress }) => {
    // const classes = useRegisterFormStyle();
    const classes = usePrepayFormStyle();
    const graphql = useApolloClient();
    const { register, errors, control, setValue, setError, clearError } = useFormContext();
    const defaultValue = '';
    const prefixAddress = isDeliveryAddress ? 'deliveryAddress' : 'billingAddress';
    const { validation, errormessage } = errormesages;
    const [isAddressLoading, setIsAddressLoading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [eirCode, setEirCode] = useState('');
    const [eirCodeEmpty, setEirCodeEmpty] = useState(true);
    const [showAddress, setShowAddress] = useState(false);
    const eirCodeInputRef = useRef();
    const handleEirCodeChange = event => {
        const eirCodeValue = event.target.value;
        setEirCode(eirCodeValue.replace(/ /g, ''));
        setAddressList([]);
        setShowAddress(false);
        clearError(`${prefixAddress}EirCode`);
        if (event.target.value.trim().length != 0) {
            setEirCodeEmpty(false);
        } else {
            setEirCodeEmpty(true);
        }
    };
    const handleOnClickEircodeConfirm = () => {
        if (eirCode.trim().length > 0) {
            clearError(`${prefixAddress}EirCode`);
            getAddressList();
        }
    };
    const toSentenceCase = input => {
        let inputLowerCase = input.toLowerCase();
        return `${inputLowerCase.charAt(0).toUpperCase()}${inputLowerCase.slice(1)}`;
    };
    const splitAddress = address => {
        let newAddress = '';
        address.split(' ').map(addressVal => {
            newAddress = newAddress + toSentenceCase(addressVal) + ' ';
        });
        return newAddress.trim();
    };
    const setAddressFields = address => {
        setTimeout(() => {
            setShowAddress(true);
            let addressLine = `${splitAddress(address.addressLine1)}\n`;
            addressLine =
                address.addressLine2 != null
                    ? `${addressLine}${splitAddress(address.addressLine2)}\n`
                    : addressLine;
            addressLine =
                address.addressLine3 != null
                    ? `${addressLine}${splitAddress(address.addressLine3)}\n`
                    : addressLine;
            let county = '';
            address.county.split('_').map((countyVal, index) => {
                index == 0
                    ? (county = county + toSentenceCase(countyVal) + '. ')
                    : (county = county + toSentenceCase(countyVal));
            });
            addressLine = `${addressLine}${toSentenceCase(address.town)}\n${county}`;
            setValue(`${prefixAddress}yourAddress`, addressLine, { shouldDirty: true });
            setValue(`${prefixAddress}addressLine1`, address.addressLine1, { shouldDirty: true });
            setValue(`${prefixAddress}addressLine2`, address.addressLine2, { shouldDirty: true });
            setValue(`${prefixAddress}addressLine3`, address.addressLine3, { shouldDirty: true });
            setValue(`${prefixAddress}town`, address.town, { shouldDirty: true });
            setValue(`${prefixAddress}county`, address.county, { shouldDirty: true });
        }, 300);
    };

    const getAddressList = async () => {
        setIsAddressLoading(true);
        try {
            const response = await graphql.query({
                query: ENDPOINT.GET_ADDRESSLIST,
                variables: {
                    eirCode: eirCode,
                },
            });
            let data = response.data.addressList.addresses;
            setAddressList(data);

            if (data.length == 0) {
                //No address found for this Eircode
                setError(
                    `${prefixAddress}EirCode`,
                    'manual',
                    errormessage.noAddressFoundforEirCode
                );
            } else if (data.length == 1) {
                setAddressFields(data[0]);
            }
        } catch (err) {
            // try {
            //     const response = await axios.get(ADDRESS_FINDER_URL, { params: { eircode: eirCode } });

            //     if (response.status == 200) {
            //         let data = response.data.addresses;
            //         setAddressList(data);
            //         if (data.length == 0) {
            //             //No address found for this Eircode
            //             setError(
            //                 `${prefixAddress}EirCode`,
            //                 'manual',
            //                 errormessage.noAddressFoundforEirCode
            //             );
            //         } else if (data.length == 1) {
            //             setAddressFields(data[0]);
            //         }
            //     } else {
            //         setError(
            //             `${prefixAddress}EirCode`,
            //             'manual',
            //             errormessage.addressFinderNetworkError
            //         );
            //     }
            // }
            setError(`${prefixAddress}EirCode`, 'manual', errormessage.addressFinderNetworkError);
        } finally {
            setIsAddressLoading(false);
        }
    };

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid className={classes.prepayEircode} item xs={12} sm={12}>
                    <Controller
                        as={<TextField className={classes.textField} inputRef={eirCodeInputRef} />}
                        control={control}
                        fullWidth
                        id={prefixAddress + 'EirCode'}
                        name={prefixAddress + 'EirCode'}
                        label='Eircode'
                        variant='outlined'
                        size='small'
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Tooltip
                                        enterTouchDelay={20}
                                        leaveTouchDelay={50000}
                                        title={
                                            'Your Eircode is unique to your address. If you do not know your eircode use the Find your eircode option.'
                                        }
                                        size='small'
                                    >
                                        <IconButton className={classes.iconUsage} size='small'>
                                            <HelpIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                        defaultValue={defaultValue}
                        rules={{
                            required: { value: true, message: validation.eircodeRequired },
                        }}
                        onChange={([event]) => {
                            handleEirCodeChange(event);
                            return event.target.value;
                        }}
                        onFocus={() => {
                            eirCodeInputRef.current.focus();
                        }}
                    />
                    <ErrorMessage errors={errors} name={`${prefixAddress}EirCode`}>
                        {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                    </ErrorMessage>
                </Grid>
                {!isAddressLoading && (
                    <Grid item xs={12} sm={12}>
                        <Grid container className={classes.addressFinderContainer}>
                            <Grid item>
                                <a
                                    href={EIRCODE_FINDER_URL}
                                    target='_blank'
                                    className={classes.eirCodeLink}
                                >
                                    <Button variant='outlined' className={classes.addressFinderButton} >
                                        Find your eircode
                                        {/* <img
                                            className='eirCodeLogo'
                                            src={EIRCODE_LOGO_IMAGE_PATH}
                                        /> */}
                                    </Button>
                                </a>
                                <Button
                                    variant='contained'
                                    // className={
                                    //     eirCodeEmpty ? 'greybutton' : 'greybutton hoverEffect'
                                    // }
                                    className={classes.addressConfirmButton}
                                    disabled={eirCodeEmpty}
                                    color={eirCodeEmpty ? 'default' : 'primary'}
                                    onClick={event => {
                                        handleOnClickEircodeConfirm(event);
                                    }}
                                >
                                    Confirm eircode
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {isAddressLoading && (
                    <Grid item xs={12}>
                        <Loading />
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    {addressList.length > 1 && (
                        <FormControl variant='outlined' fullWidth size='small'>
                            <InputLabel required>Please select your address</InputLabel>
                            <Controller
                                control={control}
                                name={prefixAddress + 'addressSelect'}
                                id={prefixAddress + 'addressSelect'}
                                inputRef={register}
                                defaultValue={defaultValue}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validation.addressSelectRequired,
                                    },
                                }}
                                as={
                                    <Select
                                        id={prefixAddress + 'addresses'}
                                        label='Please select your address *'
                                    >
                                        {addressList.map((address, index) => (
                                            <MenuItem
                                                key={index}
                                                value={index}
                                                className={classes.selectLabel}
                                            >
                                                {`${address.addressLine1} - ${address.town} - ${address.county}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                onChange={([event]) => {
                                    setAddressFields(addressList[parseInt(event.target.value)]);
                                    return event.target.value;
                                }}
                            />
                            <ErrorMessage errors={errors} name={prefixAddress + 'addressSelect'}>
                                {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                            </ErrorMessage>
                        </FormControl>
                    )}
                </Grid>
                {showAddress && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    as={<TextField className={classes.textField} />}
                                    control={control}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    id={prefixAddress + 'yourAddress'}
                                    name={prefixAddress + 'yourAddress'}
                                    label='Your address'
                                    variant='outlined'
                                    size='small'
                                    inputRef={register}
                                    disabled
                                    multiline
                                    rowsMax={6}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <input
                type='hidden'
                ref={register}
                id={prefixAddress + 'addressLine1'}
                name={prefixAddress + 'addressLine1'}
            />
            <input
                type='hidden'
                ref={register}
                id={prefixAddress + 'addressLine2'}
                name={prefixAddress + 'addressLine2'}
            />
            <input
                type='hidden'
                ref={register}
                id={prefixAddress + 'addressLine3'}
                name={prefixAddress + 'addressLine3'}
            />
            <input
                type='hidden'
                ref={register}
                id={prefixAddress + 'town'}
                name={prefixAddress + 'town'}
            />
            <input
                type='hidden'
                ref={register}
                id={prefixAddress + 'county'}
                name={prefixAddress + 'county'}
            />
        </Fragment>
    );
};
