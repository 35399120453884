import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { usePaymentFlowStyles } from '../css/usePaymentFlowStyles';
import Loading from '../../components/loading/Loading';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import { RealexHpp } from '../../utils/realexPayments';
import { useWindowDimension } from '../../hooks/windowDimensions';
import { publish } from 'pubsub-js';
import MyEirChangeSuccessModal from '../my-account/MyEirChangeSuccessModal';

const AddCardIframeContainer = props => {
    const graphql = useApolloClient();
    const classes = usePaymentFlowStyles();
    const [isLoading, setIsLoading] = useState(false);
    const addCardButtonRef = useRef(null);
    // const [isError, setIsError] = useState(false);
    const token_add_card = sessionStorage.getItem('access_token');
    const [error, setError] = useState({
        iframe: null,
        addCard: null,
    });
    const [openDialog, setDialog] = useState(false);
    const [width, height] = useWindowDimension();
    const [isopen, setIsopen] = useState(false);
    const [cardAdded, setCardAdded] = useState(false);
    const [isOpen4, setIsopen4] = useState(false);
    function toggle4() {
        setIsopen4(!isOpen4);
    }
    // const resetStep = () => {
    //     props.resetStep();
    //     // console.log('reset');
    //     // toggle();
    //     // console.log('reset done');
    // };

    useEffect(() => {
        //setLoading(true);
        // loadHpp();
        props.setHeader('Add new card');
        const fetchData = async () => {
            await getAddCardHppRequest();
            addCardButtonRef.current.click();
            setIsLoading(false);
        };
        fetchData();
    }, []);
    const handleCallback = async response => {
        console.log('Callback');
        setIsLoading(false);
        try {
            // Check if response contains valid HPP JSON
            if (!response.startsWith('{')) {
                throw new Error(response);
            }
            const { data } = await graphql.mutate({
                mutation: ENDPOINT.VALIDATE_HPP_RESPONSE_ADD_CARD,
                variables: {
                    token: token_add_card,
                    HppResponse: { hppResponse: response },
                },
            });
            // console.log('data', data)
            if (data.validateHppResponseAddCard === 'Card created') {
                publish('UPDATE_CARD');
                console.log('card created');
                setIsLoading(false);
                setCardAdded(true);
                toggle();
                // On successful payment, will clear data
                //sessionStorage.removeItem('prospectUuid')
                // move to next screen and set success true
                // console.log('Successfull', data)
                // props.setStatus(true);
            } else {
                setCardAdded(false);
                toggle();
            }
        } catch (err) {
            //setIsLoading(true);
            console.error(err);
            setError({ iframe: '', addCard: err.message });
            setDialog(false);
            setCardAdded(false);
            toggle();
            // props.setStatus(false);
            setIsLoading(false);
            // move to next screen and set success False
        }
    };
    const launchHPP = async myHppRequest => {
        console.log('inside add card launch hpp');
        //setIsLoading(true);
        RealexHpp.embedded.init('add-button', 'targetIframe', handleCallback, myHppRequest);
    };

    const getAddCardHppRequest = async () => {
        try {
            console.log('loading------');
            setIsLoading(true);
            if (!token_add_card) {
                throw new Error('token_add_card not found');
            }
            const { data } = await graphql.query({
                query: ENDPOINT.GET_HPP_REQUEST_ADD_CARD,
                variables: {
                    token: token_add_card,
                },
            });

            console.log('resp add card', data.getHppRequestAddCard);
            if (data.getHppRequestAddCard) {
                console.log('data add card hpp', data.getHppRequestAddCard);
                // // Elavon complaints when null values are sent so filtering them out
                let filteredHppRequest = Object.assign({}, data.getHppRequestAddCard);
                Object.keys(filteredHppRequest).forEach(
                    key =>
                        (filteredHppRequest[key] == null ||
                            filteredHppRequest[key] == 'undefined') &&
                        delete filteredHppRequest[key]
                );
                await launchHPP(filteredHppRequest);
            }
        } catch (err) {
            console.error('getAddCardHppRequest', err);
            setIsLoading(false);
            setError({ iframe: err.message, addCard: 'No error' });
            setDialog(true);
        }
    };
    const handleDialog = () => {
        console.log('retrying....');
        setDialog(false);
        // props.toggle();
    };

    function toggle() {
        setIsopen(!isopen);
    }

    return (
        <React.Fragment>
            <div className={classes.PaymentIframeModal}>
                {
                    <div className={classes.iframeContainer}>
                        {true && error.iframe == null && (
                            <div className={classes.loadingBox}>{<Loading />}</div>
                        )}
                        <>
                            {error.iframe != null && error.iframe != '' ? (
                                <Dialog
                                    open={openDialog}
                                    onClose={handleDialog}
                                    disableBackdropClick='true'
                                    aria-labelledby='alert-dialog-title'
                                    aria-describedby='alert-dialog-description'
                                    id='alert-iframe-error'
                                    className={classes.DialogBox}
                                >
                                    <DialogTitle id='alert-dialog-title'>
                                        Add Card error
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id='alert-dialog-description'>
                                            {error.iframe}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.eirPaymentDialog}>
                                        <Button
                                            className={classes.eirButton}
                                            onClick={handleDialog}
                                            // color='primary'
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            ) : (
                                <iframe
                                    id='targetIframe'
                                    frameBorder='0'
                                    scrolling='auto'
                                    // height={!isLoading || width < 600 ? '480px' : '280px'}
                                    height={'380px'}
                                    sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation'
                                />
                            )}
                            <Button id='add-button' ref={addCardButtonRef} />
                        </>
                    </div>
                }

                <div className={classes.paymentIframeFooter}>
                    <div className={`payment-method-button `}>
                        <button className={`payment-cta back-btn`} onClick={props.handleBack}>
                            Back
                        </button>
                    </div>
                </div>
            </div>
            <MyEirChangeSuccessModal
                isOpen={isopen}
                toggle={toggle}
                toggle1={toggle4}
                resetStep={() => props.resetStep()}
                successText={
                    cardAdded
                        ? 'Your new card has been successfully added. It will now be your default payment method'
                        : 'Something went wrong and the card wasn’t added. Please try again later'
                }
                codeStatus={cardAdded}
                buttonText='Close'
            />
        </React.Fragment>
    );
};
export default AddCardIframeContainer;
