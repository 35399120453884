import ENDPOINT from '../graphql/GraphQLConstants';

export const getAddressList = async ({ graphql, eirCode }) => {

    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_ADDRESSLIST,
            variables: {
                eirCode: eirCode,
            },
        });

        return data.addressList.addresses;
    } catch (error) {
        throw new Error(error);
    } 
};