import React, { useState, useEffect, Fragment } from 'react';
import { useFooterStyle } from './css/useFooterStyle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FooterMobileAccordion from './FooterMobileAccordion';
import { List, ListItem, ListItemText, Grid, Container } from '@material-ui/core';
const theme = createMuiTheme({
    // typography: {
    //     fontFamily: theme.typography.body2.fontFamily,
    // },
});

function FooterMenu(props) {
    const [footerMenuItems, setfooterMenuItems] = useState(props.footerMenuData);
    // const [footerDisclaimer, setFooterDisclaimer] = useState('');

    const classes = useFooterStyle();
    useEffect(() => {
        setfooterMenuItems(props.footerMenuData);
        // setFooterDisclaimer(disclaimer)
    }, [props.footerMenuData]);
    const renderContent = content => {
        return { __html: content };
    };
    const scrollBackToTop = () => {
        let scrollStep = -window.pageYOffset / (1000 / 15);
        let scrollInterval = setInterval(function () {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            } else clearInterval(scrollInterval);
        }, 15);
    };
    var disclaimer = props.footerMenuData.disclaimer
        ? props.footerMenuData.disclaimer.replace(
              '${--var-disclaimer-year}',
              new Date().getFullYear()
          )
        : '';

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <footer className={classes.footer}>
                    <div className='col-sm-12 col-md-12 text-md-right text-center itemRight'>
                        <span className='eir-icon eir-icon--sm icon-arrowup--black mr-1' />
                        <button onClick={scrollBackToTop} className='a-no-style'>
                            Back to the top
                        </button>
                    </div>
                    <Container className='footer-desktop'>
                        <Grid container spacing={2}>
                            {footerMenuItems.primaryNav !== undefined &&
                                footerMenuItems.primaryNav.children.map((item, index) => {
                                    return (
                                        <Grid item md={3} key={index}>
                                            <List className='footer-menu'>
                                                <ListItem
                                                    key={index}
                                                    className='footer-list list-first footer-title'
                                                >
                                                    <ListItemText
                                                        className='titleText'
                                                        primary={item.title}
                                                    />
                                                </ListItem>
                                                {item.children.map((navItem, index) => {
                                                    return (
                                                        <ListItem
                                                            key={index}
                                                            className='footer-list'
                                                            component='a'
                                                            href={navItem.link.link}
                                                        >
                                                            <ListItemText
                                                                className='titleText'
                                                                primary={navItem.link.title}
                                                            />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                        {/* </Row> */}
                    </Container>

                    <div className='footer-mobile'>
                        {footerMenuItems.primaryNav !== undefined &&
                            footerMenuItems.primaryNav.children.map((item, index) => {
                                return (
                                    <FooterMobileAccordion
                                        title={item.title}
                                        key={index}
                                        children={item.children}
                                    />
                                );
                            })}
                    </div>

                    <Container>
                        <Grid container spacing={2} className='social-links'>
                            <Grid item md={12} xs={12}>
                                <ul className='list-inline text-center'>
                                    {footerMenuItems.socialNav !== undefined &&
                                        footerMenuItems.socialNav.children.map((navItem, index) => {
                                            return (
                                                <li
                                                    className='list-inline-item'
                                                    key={'list-inline-item' + index}
                                                >
                                                    <a
                                                        title={navItem.link.title}
                                                        target={navItem.link.target}
                                                        href={navItem.link.link}
                                                        className={
                                                            'eir-icon icon-' +
                                                            navItem.icon +
                                                            '--black'
                                                        }
                                                    >
                                                        {navItem.link.text}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <ul className='list-inline footer-links-bottom'>
                                    {footerMenuItems.secondaryNav !== undefined &&
                                        footerMenuItems.secondaryNav.children.map(
                                            (navItem, index) => {
                                                return (
                                                    <li
                                                        className='list-inline-item'
                                                        key={'list-inline-item' + index}
                                                    >
                                                        <a
                                                            title={navItem.link.title}
                                                            target={navItem.link.target}
                                                            href={navItem.link.link}
                                                            className={
                                                                'eir-icon icon-' +
                                                                navItem.icon +
                                                                '--black'
                                                            }
                                                        >
                                                            {navItem.link.text}
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <p
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <button
                                id='ot-sdk-btn'
                                className='ot-sdk-show-settings'
                                style={{
                                    fontFamily: theme.typography.body2.fontFamily,
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    borderColor: 'rgb(0, 0, 0)',
                                    fontWeight: 'bold',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    color: 'rgb(0, 0, 0)',
                                    borderRadius: '50px',
                                    padding: '0.8em 2em',
                                    fontSize: '0.8em',
                                    lineHeight: '1.2',
                                    outline: 'none',
                                }}
                            >
                                Cookies Settings
                            </button>
                        </p>
                    </Container>
                    <Container>
                        {footerMenuItems.disclaimer !== undefined && (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={12}
                                    dangerouslySetInnerHTML={renderContent(disclaimer)}
                                />
                            </Grid>
                        )}
                    </Container>
                </footer>
            </ThemeProvider>
        </Fragment>
    );
}

export default FooterMenu;
