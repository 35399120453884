import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';

const ImageAndInfo = props => {
    const classes = useStyles();
    return (
        <Box
            id='image-and-info'
            className={
                props?.img || props?.textBold || props?.textLight
                    ? classes.mainContainer
                    : classes.none
            }
        >
            {/* Icon image */}
            {props?.img && (
                <Box className={classes.imageIcon}>
                    <Box component='img' src={props.img} alt='icon-img' />
                </Box>
            )}

            {/* Text & sub-text */}
            <Box
                className={
                    classes.textInfo +
                    ` ${
                        props?.aligned === 'center'
                            ? classes.alignCenter
                            : props?.aligned === 'right'
                            ? classes.alignRight
                            : ''
                    } `
                }
            >
                <Typography className={classes.boldText}>{props?.textBold}</Typography>
                <Typography className={classes.lightText}>{props?.textLight}</Typography>
            </Box>
        </Box>
    );
};

export default ImageAndInfo;
