import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import CustomTextField from '../../sharedcomponents/inputs/text-field/CustomTextField';
import { formatCounty } from '../addressFormat';
import useStyles from './style';
import errormesages from './errorsCopy';
import { useApolloClient } from '@apollo/client';

const SimAddressForm = ({
    addressList,
    setAddressList,
    addressData,
    setAddressData,
    isConfirm,
    eircode,
    ContactDetails,
    singleAddress,
    setSingleAddress,
    register,
    control,
    errors,
    setValue,
    submitFormAction,
    submitFormData,
    setIsEnabled,
    reorderForm,
    flag,
}) => {
    const classes = useStyles();
    const val = '';
    const { validation, errormessage } = errormesages;
    const [selectedAddress, setSelectedAddress] = useState(-1);
    const [phone, setPhone] = useState('');
    const [billingAddress, setBillingAddress] = useState(ContactDetails);
    const graphql = useApolloClient();

    const handlePhoneNumber = num => {
        setPhone(num);
    };

    useEffect(() => {
        if (addressList == []) {
            setSelectedAddress(-1);
        }
    }, [addressList]);

    return (
        <Box>
            <Grid container className={classes.addressContainer}>
                <Grid
                    item
                    className={`${classes.addressContainer} ${
                        eircode == '' ? '' : classes.addresslineOne
                    }`}
                    xs={12}
                    sm={12}
                >
                    <FormControl
                        className={classes.textField}
                        variant='outlined'
                        fullWidth
                        size='small'
                    >
                        {eircode == '' ? (
                            <CustomTextField
                                id='addressLine1'
                                name='addressLine1'
                                label='Address Line 1'
                                value={
                                    flag
                                        ? reorderForm.address.addressLine1
                                        : billingAddress?.addresses[0]?.addressLine1
                                }
                                {...register('addressLine1')}
                                InputLabelProps={{ shrink: true }}
                                disabled
                                className={`addressLine1`}
                                classes={{
                                    container: ` ${classes.textField} ${classes.textFieldOtp}`,
                                }}
                            />
                        ) : singleAddress.length == 1 ? (
                            <CustomTextField
                                id='addressLine1'
                                name='addressLine1'
                                label='Address Line 1'
                                value={singleAddress[0].addressLine1}
                                InputLabelProps={{ shrink: true }}
                                {...register('addressLine1')}
                                disabled
                                className={`addressLine1`}
                                classes={{
                                    container: ` ${classes.textField} ${classes.textFieldOtp}`,
                                }}
                            />
                        ) : (
                            <>
                                <InputLabel required>Address line 1</InputLabel>
                                <Controller
                                    control={control}
                                    name='addressSelect'
                                    id='addressSelect'
                                    inputRef={register}
                                    defaultValue={val}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validation.addressSelectRequired,
                                        },
                                    }}
                                    as={
                                        <Select
                                            id='addresses'
                                            name='addresses'
                                            label='Address line 1'
                                            {...register('addresses')}
                                            fullWidth
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        width: 250,
                                                    },
                                                },
                                                variant: 'menu',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            InputLabelProps={{
                                                shrink: Boolean(addressData?.addressLine1),
                                            }}
                                        >
                                            {
                                                addressList.map((address, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={index}
                                                        className={classes.selectLabel}
                                                    >
                                                        {`${address?.addressLine1}`}
                                                    </MenuItem>
                                                ))

                                                // <MenuItem
                                                //         // key={index}
                                                //         value={addressList.addressLine1}
                                                //         className={classes.selectLabel}
                                                //     >
                                                //         {`${addressList.addressLine1} - ${addressList.town} - ${addressList.county}`}
                                                //     </MenuItem>
                                            }
                                        </Select>
                                    }
                                    value={selectedAddress}
                                    onChange={([event]) => {
                                        console.log(event.target.value);
                                        //  setAddressList(addressList[parseInt(event.target.value)]);
                                        setSelectedAddress(parseInt(event.target.value));
                                        setAddressData(addressList[parseInt(event.target.value)]);
                                        submitFormAction({
                                            address: addressList[parseInt(event.target.value)],
                                        });
                                        setIsEnabled(true);
                                        console.log('address list set', addressList);
                                        return event.target.value;
                                    }}
                                />
                            </>
                        )}
                        <ErrorMessage errors={errors} name={'addressSelect'}>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </FormControl>
                </Grid>

                <Grid container spacing={8}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className={`${classes.addressContainer} ${
                            eircode == ''
                                ? flag
                                    ? reorderForm.address.addressLine2 == undefined
                                        ? classes.hideTextField
                                        : ''
                                    : billingAddress?.addresses[0]?.addressLine2 == null
                                    ? classes.hideTextField
                                    : ''
                                : singleAddress.length == 1
                                ? singleAddress[0].addressLine2 !== null
                                    ? ''
                                    : classes.hideTextField
                                : !isConfirm && addressData !== null
                                ? addressData.addressLine2 !== null
                                    ? ''
                                    : classes.hideTextField
                                : ''
                        }`}
                    >
                        <CustomTextField
                            id='addressLine2'
                            name='addressLine2'
                            label='Address Line 2'
                            {...register('addressLine2')}
                            value={
                                eircode == ''
                                    ? flag
                                        ? reorderForm.address.addressLine2
                                        : billingAddress?.addresses[0]?.addressLine2
                                    : singleAddress.length == 1
                                    ? singleAddress[0].addressLine2 !== null
                                        ? singleAddress[0].addressLine2
                                        : ''
                                    : addressData?.addressLine2
                                    ? addressData.addressLine2
                                    : ''
                            }
                            InputLabelProps={{
                                shrink:
                                    eircode == ''
                                        ? flag
                                            ? reorderForm.address.addressLine2
                                            : billingAddress?.addresses[0]?.addressLine2
                                        : singleAddress[0]
                                        ? singleAddress[0].addressLine2
                                        : Boolean(addressData?.addressLine2),
                            }}
                            disabled
                            className={`addressLine2`}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className={`${classes.addressContainer} ${
                            eircode == ''
                                ? flag
                                    ? reorderForm.address.addressLine3 == undefined
                                        ? classes.hideTextField
                                        : ''
                                    : billingAddress?.addresses[0]?.addressLine3 == null
                                    ? classes.hideTextField
                                    : ''
                                : singleAddress.length == 1
                                ? singleAddress[0].addressLine3 !== null
                                    ? ''
                                    : classes.hideTextField
                                : !isConfirm && addressData !== null
                                ? addressData.addressLine3 !== null
                                    ? ''
                                    : classes.hideTextField
                                : ''
                        }`}
                    >
                        <CustomTextField
                            id='addressLine3'
                            name='addressLine3'
                            label='Address Line 3'
                            value={
                                eircode == ''
                                    ? flag
                                        ? ''
                                        : billingAddress?.addresses[0]?.addressLine3
                                    : singleAddress.length == 1
                                    ? singleAddress[0].addressLine3 !== null
                                        ? singleAddress[0].addressLine3
                                        : ''
                                    : addressData?.addressLine3
                                    ? addressData.addressLine3
                                    : ''
                            }
                            InputLabelProps={{
                                shrink:
                                    eircode == ''
                                        ? flag
                                            ? ''
                                            : billingAddress?.addresses[0]?.addressLine3
                                        : singleAddress[0]
                                        ? singleAddress[0].addressLine3
                                        : Boolean(addressData?.addressLine3),
                            }}
                            disabled
                            {...register('addressLine3')}
                            className={`addressLine3`}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} className={classes.paddingLeftTown}>
                        <CustomTextField
                            id='town'
                            name='town'
                            label='Town/City *'
                            value={
                                eircode == ''
                                    ? flag
                                        ? reorderForm.address.town
                                        : billingAddress?.addresses[0]?.town
                                    : singleAddress.length == 1
                                    ? singleAddress[0].town !== null
                                        ? singleAddress[0].town
                                        : ''
                                    : addressData?.town
                                    ? addressData.town
                                    : ''
                            }
                            InputLabelProps={{
                                shrink:
                                    eircode == ''
                                        ? flag
                                            ? reorderForm.address.town
                                            : billingAddress?.addresses[0]?.town
                                        : singleAddress[0]
                                        ? singleAddress[0].town
                                        : Boolean(addressData?.town),
                            }}
                            disabled
                            {...register('towm')}
                            className={`town`}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} className={classes.addressContainer}>
                        <CustomTextField
                            id='county'
                            name='county'
                            label='County'
                            value={
                                eircode == ''
                                    ? flag
                                        ? formatCounty(reorderForm.address.county)
                                        : formatCounty(billingAddress?.addresses[0]?.county)
                                    : singleAddress.length == 1
                                    ? singleAddress[0].county !== null
                                        ? formatCounty(singleAddress[0].county)
                                        : ''
                                    : formatCounty(addressData?.county)
                                    ? formatCounty(addressData.county)
                                    : ''
                            }
                            InputLabelProps={{
                                shrink:
                                    eircode == ''
                                        ? flag
                                            ? reorderForm.address.town
                                            : billingAddress?.addresses[0]?.county
                                        : singleAddress[0]
                                        ? singleAddress[0].county
                                        : Boolean(addressData?.county),
                            }}
                            disabled
                            {...register('county')}
                            className={`county`}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className={classes.paddingLeftTown}>
                        <CustomTextField
                            id='phoneNumber'
                            name='phoneNumber'
                            label='Phone Number *'
                            value={billingAddress?.phoneNumbers[0]?.phoneNumber}
                            InputLabelProps={{
                                shrink: billingAddress?.phoneNumbers[0]?.phoneNumber,
                            }}
                            disabled
                            {...register('phoneNumber')}
                            className={`phoneNumber`}
                            onChange={e => handlePhoneNumber(e.target.value)}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SimAddressForm;
