import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Loading from '../../components/loading/Loading';
import EirButton from '../../theme/EirButton';
import { usePaymentCardMethodStyles } from '../css/usePaymentCardMethodStyles';
import SepaPdf from '../images/sepa-pdf.png';

const InternationalSEPACard = props => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({ loading: false });

    const classes = usePaymentCardMethodStyles();

    const handleNextClick = () => {
        window.location.href = '/';
    };

    const handlePdfClick = () => {
        console.log('pdf clicked');
        window.location.href = 'www.eir.ie/.content/download/sepa_international_direct_debit.pdf';
    };

    useEffect(() => {
        props.setHeader('Create international SEPA');
    }, []);

    return (
        <React.Fragment>
            <ModalBody className={classes.paymentMethod}>
                <div className='sepa-container'>
                    <div className='sepa-contents'>
                        <p className='sepa-content-text'>Set up an international SEPA mandate</p>
                        <p className='sepa-content-small-text'>
                            To complete the process, please download the mandate form PDF and
                            complete it
                        </p>
                    </div>
                    <div className='sepa-pdf'>
                        <a
                            href='https://www.eir.ie/.content/download/sepa_international_direct_debit.pdf'
                            // download='sepa_international_direct_debit.pdf'
                            target='_blank'
                            className='sepa-pdf-link'
                        >
                            <img src={SepaPdf} className='pdf-image' />
                            <span className='pdf-text'>PDF</span>
                        </a>
                    </div>
                    <div>
                        <p className='sepa-content-email-lines'>
                            Forms can be returned via{' '}
                            <span className='sepa-email'>directdebit@eir.ie</span>
                        </p>

                        <p className='sepa-content-lines'>Or sent to:</p>
                        <p className='sepa-content-lines'>Eir Post</p>
                        <p className='sepa-content-lines'>Capita</p>
                        <p className='sepa-content-lines'>Unit B </p>
                        <p className='sepa-content-lines'>West Cork Technology Park</p>
                        <p className='sepa-content-lines'>Clonakilty</p>
                        <p className='sepa-content-lines'>Co. Cork</p>
                    </div>
                </div>

                <div className='payment-body-footer'>
                    <EirButton
                        style={{ padding: '0.5rem 1.875rem' }}
                        variants='primaryButtonMedium'
                        // className={
                        //     'sepa-footer-cta primary-cta' + (true ? ' cta-enabled' : ' cta-diabled')
                        // }
                        disabled={false}
                        onClick={handleNextClick}
                    >
                        Back to my eir account
                    </EirButton>
                </div>
            </ModalBody>
        </React.Fragment>
    );
};

export default InternationalSEPACard;
