import React, { Component } from 'react';

function Loading() {
    return (
        <div className='eir-loading-container d-block text-center '>
            <span className='bg-second'></span>
            <span className='bg-second'></span>
            <span className='bg-second'></span>
        </div>
    );
}

export default Loading;
