import React, { useState } from 'react';
import { AccordionDetails } from '@material-ui/core';
import pdflogo from '../images/pdf.png';
import BillDetails from '../bill-details/BillDetails';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { saveAs } from 'file-saver';

function MyEirBillDetail({ index, item, width }) {
    const [hidePDF, setHidePDF] = useState(false);

    const graphql = useApolloClient();

    function convertDateFormat(str) {
        // console.log(str,Date(str + "Z").toLocaleString("en-us", { month: "short" }));
        // return Date(str + "Z").toLocaleString("en-us", { month: "short" });

        const dateArr = str.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        // console.log(`${dateArr[1]}-${myMonth}-${dateArr[0]}`);
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    }
    function base64ToBlob(base64, type = 'application/octet-stream') {
        return new Promise((resolve, reject) => {
            try {
                const binStr = atob(base64);
                const len = binStr.length;
                const arr = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }
                // saveAs(blob, `${invoiceNum}.pdf`);
                resolve(new Blob([arr], { type: type }));
            } catch (err) {
                reject(err);
            }
        });
    }
    async function downloadInvoicePDF(invoiceNum) {
        try {
            setHidePDF(true);

            const response = await graphql.query({
                query: ENDPOINT.GET_INVOICE_PDF,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    invoiceNumber: parseFloat(`${invoiceNum}`),
                    accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });

            const data = await response.data;
            console.log(data);
            //decoding and saving here
            const blob = await base64ToBlob(data.getInvoicePDF);
            saveAs(blob, `${invoiceNum}.pdf`);

            setHidePDF(false);
        } catch (error) {
            setHidePDF(false);
            console.error('Error while fetching invoice PDF', error);
        }
    }

    return (
        <AccordionDetails className={'bills-summary bill-' + (index + 1)}>
            <div className='issuedOn'>
                <div className='issuedOnProp'>{width > 1019 ? 'Issued on' : 'Bill date'}</div>
                <div className='issuedOnValue'>{convertDateFormat(item.invoiceDate)}</div>
            </div>
            <div className='amountDue'>
                <div className='amountDueProp'>Amount due</div> {/*due or not based on paid info*/}
                <div className='amountDueValue'>€{(item.amount / 100).toFixed(2)}</div>
            </div>
            {/* display either due message or paid message based on API data */}
            {(JSON.parse(sessionStorage.getItem('myBalance')) / 100).toFixed(2) < 0 && (
                <div className='dueMessage'>
                    {index === 0 && <>Direct debit due on {convertDateFormat(item.dueDate)}</>}
                </div>
            )}
            {/* <div className='dueMessage paidMessage'>Paid by direct debit on {convertDateFormat(item.paidDate)}</div> */}
            {width > 1019 ? (
                <>
                    {/* <div className='billDetails'>See bill details</div> */}
                    <BillDetails
                        invoiceNumber={item.invoiceNumber}
                        invoiceDate={item.invoiceDate}
                        dueDate={item.dueDate}
                        index={index}
                    />
                    <div
                        className={'pdfDownload' + (hidePDF ? ' hide' : ' ')}
                        onClick={() => {
                            downloadInvoicePDF(item.invoiceNumber);
                        }}
                    >
                        <img className='pdfDownloadImage' src={pdflogo} alt='PDF download' />
                    </div>
                </>
            ) : (
                <div className='billDetailsPDF'>
                    {/* <div className='billDetails'>See bill details</div> */}
                    <BillDetails
                        invoiceNumber={item.invoiceNumber}
                        invoiceDate={item.invoiceDate}
                        dueDate={item.dueDate}
                        index={index}
                    />
                    <div
                        className={'pdfDownload' + (hidePDF ? ' hide' : ' ')}
                        onClick={() => {
                            downloadInvoicePDF(item.invoiceNumber);
                        }}
                    >
                        <img className='pdfDownloadImage' src={pdflogo} alt='PDF download' />
                    </div>
                </div>
            )}
        </AccordionDetails>
    );
}

export default MyEirBillDetail;
