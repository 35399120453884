import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import CustomModalFooter from './CustomModalFooter';
import CustomModalHeader from './CustomModalHeader';
import useStyle from './style';
import CustomCloseIcon from './CustomCloseIcon';

const CustomModal = props => {
    const [open, setOpen] = useState(props.open);

    const toggle = () => {
        setOpen(!open);
        props.toggle && props.toggle();
    };
    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const styles = useStyle();

    return (
        <Modal
            id={props.id}
            isOpen={open}
            toggle={toggle}
            modalTransition={{ timeout: 300 }}
            wrapClassName={styles.modalWrapper}
            modalClassName={styles.modalClass}
            centered
        >
            <Box className={styles.modalBoxStyles}>
                <CustomModalHeader
                    headerText={props.header?.text}
                    center={props.header?.center}
                    arrowAction={props.header?.arrowAction}
                    styles={styles}
                />
                <Box>
                    {props.closeIconAction && (
                        <CustomCloseIcon closeIconAction={props.closeIconAction} styles={styles} />
                    )}
                </Box>

                <Box style={{ flex: 1, position: 'relative' }} className={'custom-modal-body'}>
                    {props.children}
                </Box>

                <CustomModalFooter
                    cta1={props.cta1}
                    cta2={props.cta2}
                    link={props.link}
                    setLinkStatus={props.setLinkStatus}
                    apiError={props.apiError}
                    styles={styles}
                />
            </Box>
        </Modal>
    );
};

export default CustomModal;
