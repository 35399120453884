import { makeStyles } from '@material-ui/core/styles';
export const useLoggedInStyle = makeStyles(theme => ({
    body: {
        height: '100%',
        background: '#f6f7fd',
        overflow: 'auto',
    },

    boxx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.boxShadow3Medium,
        backdropFilter: '10px',
        color: theme.palette.primary.sunset,
        fontSize: '28px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '700',
        fontStyle: 'normal',
        lineHeight: '36px',
        zIndex: '1',
        position: 'absolute',
        paddingTop: '7px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '40px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '40px',
        },
    },
    wereloading: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.h5.fontFamily,
        marginBottom: '46px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '34px',
            fontSize: theme.typography.h5.fontSize,
            lineHeight: '36px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            marginBottom: '34px',
        },
    },

    greeting: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.h5.fontFamily,
        marginTop: '14px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '22px',
            fontSize: '22px !important',
            lineHeight: '36px !important',
        },
        '@media(min-width:600px) and (max-width:1019px)': {},
    },
}));
