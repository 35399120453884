import { makeStyles } from '@material-ui/core/styles';
export const usePaymentFlowStyles = makeStyles(theme => ({
    modalWrapper: {
        background: 'white',

        '& *': {
            margin: 0,
        },
        '& .payment-cta': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.5,
            margin: '0 auto',
            marginBottom: '12px !important',
        },
        '& .primary-btn': {
            borderRadius: 40,
            padding: '8px 60px',
            outline: 'none',
            border: 'none',
            textTransform: 'none',
            cursor: 'pointer',
            background: theme.palette.primary.sunset,
            color: theme.palette.primary.white,
            // '&.Mui-disabled': {
            //     background: '#ececec !important',
            //     color: '#888B8D !important',
            // },
            // '&:hover': {
            //     background: '#B10071',
            // },
        },
        '& .back-btn': {
            background: 'transparent',
            color: theme.palette.primary.sunset,
            border: 'none',
            width: 'fit-content',
        },
    },
    modalClassName: {
        // background: 'red'
        background: theme.palette.primary.gray550,
        backdropFilter: 'blur(4.5px)',
        '@media(min-width: 320px) and (max-width: 599px)': {
            // background: '#470A68',
        },
        '& .modal-dialog': {
            maxWidth: 760,
            height: '100%',
            margin: '0 auto',
            '@media(min-width: 320px) and (max-width: 599px)': {
                top: 0,
                margin: '8px',
                marginBottom: '0px',
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

                borderRadius: '10px 10px 0px 0px',
            },
            '@media(min-width: 600px) and (max-width: 1019px)': {
                top: 0,
                padding: 99,
            },
            '& .modal-content': {
                borderRadius: '10px',
                '@media(min-width: 320px) and (max-width: 599px)': {
                    height: '100vh',
                },
                '@media(min-width: 600px) and (max-width: 1019px)': {
                    // height: '100vh',
                },
            },
        },
    },

    paymentAmount: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 0',
        height: '100%',
        justifyContent: 'space-between',
        minHeight: 370,
        '@media(min-width: 600px) and (max-width: 1019px)': {
            minHeight: 490,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 16,
            maxWidth: '90vw',
            justifyContent: 'space-between',
            height: '100%',
        },
        '& .paymentAmountValue': {
            textAlign: 'center',
            '& h4': {
                fontSize: 16,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                lineHeight: 1.5,
                fontFamily: theme.typography.subtitle1.fontFamily,
                marginBottom: 20,
            },
        },
        '& .payVal': {
            '& .MuiInputBase-root': {
                cursor: 'pointer',

                '& .MuiInputBase-input': {
                    cursor: 'pointer',
                },
            },
        },
        '& .paymentAmountInput': {
            justifyContent: 'center',
            cursor: 'pointer',
            // marginBottom: '250px',

            '& .MuiFormLabel-root.Mui-focused': {
                color: theme.palette.primary.heather,
            },

            '& .MuiInputAdornment-root ': {
                fontFamily: theme.typography.body2.fontFamily,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.heather,
            },

            '& .helperText': {
                // cursor: 'pointer',
                fontSize: 12,
                fontFamily: theme.typography.subtitle1.fontFamily,
                color: theme.palette.primary.heather,
            },
            '& .errorMessage ': {
                color: theme.palette.primary.error,
                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '20px',
            },
        },
        '& .confirmAmount': {
            justifyContent: 'center',
            minHeight: 92,
            flex: 0,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 16,
            },
            '& .primary-btn': {
                // width: '184px',
                borderRadius: '25px',
                textTransform: 'none',
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

                '@media(min-width: 320px) and (max-width: 599px)': {
                    // width: '264px',
                },
            },
        },
    },

    paymentMethod: {
        // background: 'white',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 0',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
        '& *': {
            margin: 0,
        },
        '& .back-btn': {
            background: 'transparent',
            color: theme.palette.primary.sunset,
        },
        '& .payment-btn': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: 40,
            padding: '8px 60px',
            outline: 'none',
            border: 'none',
        },
        '& .payment-method-value': {
            textAlign: 'center',
            '& h4': {
                fontSize: 16,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                lineHeight: 1.5,
                fontFamily: theme.typography.subtitle1.fontFamily,
                marginBottom: 18,
            },
            '& h3': {
                fontSize: 18,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                fontFamily: theme.typography.subtitle1.fontFamily,
                lineHeight: 1.5,
            },
        },

        '& .payment-method-cards-container': {
            // background: 'purple',
            maxWidth: 440,
            width: '100%',
            margin: '30px 0',
            '& .payment-method-cards-header ': {
                // background: 'yellow',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 16,
                '& h4': {
                    fontSize: 14,
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    color: theme.palette.primary.heather,
                    fontWeight: 600,
                    lineHeight: 1.5,
                    textDecoration: 'underline',
                },
                '& h3': {
                    fontSize: 16,
                    fontFamily: theme.typography.subtitle1.fontFamily,
                    color: theme.palette.primary.heather,
                    // fontWeight: 700,
                    lineHeight: '22px',
                },
            },
            '& .payment-method-cards-list ': {
                // background: 'pink',
                marginBottom: 28,
                '& .radio-payment-card-block': {
                    flex: 1,
                    background: theme.palette.primary.gray250,
                    display: 'flex',
                    marginBottom: 16,
                    maxHeight: 90,
                    alignItems: 'center',
                    padding: '21px 24px',
                    border: `1px solid ${theme.palette.primary.gray250}`,
                    borderRadius: 8,
                    position: 'relative',

                    '& .MuiIconButton-root': {
                        color: theme.palette.primary.sunset,
                    },
                    '& .card-icon': {
                        // backgroundImage: 'url(../images/master-card.png)',
                        // backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'contain',
                        // height: '48px',
                        width: '48px',
                        marginRight: 48,
                        '& img': {
                            height: 'auto',
                            width: '48px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            height: '36px',
                            width: '36px',
                            marginRight: 20,
                            '& img': {
                                height: 'auto',
                                width: '36px',
                            },
                        },
                    },
                    '& .card-info': {
                        width: 'fit-content',
                        textAlign: 'left',
                        // background: 'red',
                        '& .card-number': {
                            fontSize: 16,
                            fontFamily: theme.typography.body3Medium.fontFamily,
                            color: theme.palette.primary.heather,
                            fontWeight: 500,
                            lineHeight: 1,
                            // margin: 0,
                            [theme.breakpoints.down('xs')]: {
                                fontSize: 12,
                                lineHeight: 1,
                            },
                        },
                        '& .message': {
                            fontSize: 14,
                            fontFamily: theme.typography.body2.fontFamily,
                            color: theme.palette.primary.heather,
                            fontWeight: 400,
                            lineHeight: '20px',
                            // margin: 0,
                            [theme.breakpoints.down('xs')]: {
                                fontSize: 12,
                                lineHeight: 1,
                            },
                        },

                        '& .card-expired-message': {
                            color: theme.palette.primary.error,

                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: '20px',
                            fontFamily: theme.typography.body2.fontFamily,
                        },
                    },

                    '&.selected': {
                        border: `2px solid ${theme.palette.primary.boxShadow1Dark}`,
                        background: theme.palette.primary.backgroundColor2,
                    },

                    '&.expired': {
                        '&::before': {
                            top: 12,
                            right: 20,
                            backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z' fill='%23E40046'/%3E%3C/svg%3E%0A")`,
                            height: 20,
                            width: 20,
                            content: "' '",
                            position: 'absolute',
                        },
                    },
                    [theme.breakpoints.down('xs')]: {
                        padding: '14px 16px',
                    },
                },
                '& .MuiFormControl-root': {
                    width: '100%',
                    '& .MuiSvgIcon-root': {
                        color: '#',
                    },
                },
            },
            '& .payment-method-cards-add ': {
                // background: 'yellowgreen',
                fontSize: 18,
                color: theme.palette.primary.sunset,
                // fontWeight: 700,
                lineHeight: 1.5,
                textAlign: 'left',
                fontFamily: theme.typography.body3Medium.fontFamily,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 16,
                    lineHeight: 1.5,
                },
            },
            [theme.breakpoints.down('xs')]: {
                flex: 1,
            },
        },

        '& .payment-method-button': {
            display: 'flex',
            flexDirection: 'column',
            '& .pay-now': {
                color: 'white',
                background: theme.palette.primary.sunset,
            },

            [theme.breakpoints.down('xs')]: {
                width: '100%',
                padding: '0 24px',
            },
        },
    },

    headerPaymentContainer: {
        maxWidth: '760px',
        marginTop: '28px',
        marginBottom: '18px',
        // marginLeft: "auto",
        // marginRight: "auto",
        padding: '0px 32px',

        '@media(max-width: 599px)': {
            padding: '0px 15px',
            width: '100%',
            marginTop: '20px',
            marginBottom: '0px',
        },

        '& .payment-header-back-icon': {
            display: 'flex',
            marginLeft: '-4px',
            color: theme.palette.primary.sunset,
            cursor: 'pointer',

            '@media(max-width: 599px)': {
                height: '20px',
                width: '20px',
                marginLeft: '-4px',
            },
        },

        '& .payment-header-text': {
            display: 'flex',
            marginTop: '19px',
            minHeight: '47px',
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '26px',
            color: theme.palette.primary.heather,

            position: 'relative',

            '@media(max-width: 599px)': {
                paddingLeft: '8px',
                marginTop: '14px',
                minHeight: '45px',
                fontSize: theme.typography.h4.fontSize,
            },

            '& .payment-header-line': {
                position: 'absolute',
                left: '0%',
                right: '0%',
                bottom: '3px',
                border: `1px solid ${theme.palette.primary.borderColor}`,
                borderTopWidth: '0px',

                '@media(max-width: 599px)': {
                    bottom: '0px',
                },
            },
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },
    payAmountContainer: {
        '& .payment-method-value': {
            textAlign: 'center',
            '& h4': {
                fontSize: 16,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                lineHeight: 1.5,
                fontFamily: theme.typography.subtitle1.fontFamily,
            },
            '& h3': {
                fontSize: 18,
                color: theme.palette.primary.heather,
                // fontWeight: 700,
                fontFamily: theme.typography.subtitle1.fontFamily,
                lineHeight: 1.5,
            },
        },
    },

    iframeContainer: {
        minHeight: '190px',
        paddingTop: '35px',
        position: 'relative',
        '@media(max-width: 599px)': {
            flex: 1,
        },
        '& iframe': {
            //display: 'inline-block',
            //displayContent: '5rem',
            zIndex: 3,
            width: '100%',
            align: 'centre',
            // minHeight: '52rem',
            position: 'relative',
            //marginTop: '1rem',
            '@media (min-width:1019px) and (max-width:1365px)': {
                maxHeight: '480px',
                height: '45.5vh',
            },
            '@media (max-width: 767px)': {
                textAlign: 'left',
                fontSize: 14,
                // minHeight: '55rem',
            },
        },
        '& iframe.afterLoad': {
            minHeight: '52rem',
            '@media (max-width: 767px)': {
                minHeight: '55rem',
            },
        },
        '& .errorMessage': {
            width: '100%',
            marginTop: '.25rem',
            fontSize: 16,
            color: theme.palette.primary.error,
            display: 'block',
            '@media (max-width: 767px)': {
                textAlign: 'left',
                fontSize: 14,
            },
        },

        '& #pay-button': {
            visibility: 'hidden',
        },

        eirButton: {
            borderRadius: '0.375rem',
            fontSize: 18,
            textTransform: 'initial',
            padding: '8px 45px',
            border: `1px solid ${theme.palette.primary.sunset}`,
            background: theme.palette.primary.sunset,
            color: theme.palette.primary.white,
            '@media (min-width:280px) and (max-width: 320px)': {
                fontSize: 12,
                padding: '6px 22px',
            },
            '@media (min-width:321px) and (max-width: 767px)': {
                padding: '8px 30px',
                fontSize: 16,
            },
            '@media (min-width:768px) and (max-width: 1024px)': {
                fontSize: 16,
            },
            '&:focus,&:hover': {
                outline: 'none',
                background: theme.palette.primary.sunset30,
                color: theme.palette.primary.white,
            },
        },
        eirPaymentDialog: { justifyContent: 'center' },
    },
    DialogBox: {
        '& #alert-dialog-title h2': {
            fontFamily: theme.typography.h6.fontFamily,
            color: theme.palette.primary.heather,
        },
        '& #alert-dialog-description': {
            fontFamily: theme.typography.body2.fontFamily,
            color: theme.palette.primary.error,
        },
        '& .MuiDialogActions-spacing ': {
            padding: '12px 0 26px',
            '& .MuiButtonBase-root ': {
                height: 40,
                background: theme.palette.primary.sunset,
                color: 'white',
                padding: '0 50px',
                textTransform: 'capitalize',
                borderRadius: 40,
                margin: '0 auto',
            },
        },
    },
    PaymentIframeModal: {
        padding: '15px',
        '@media(max-width: 599px)': {
            // minHeight:"93.5px",
            //paddingLeft: "8px",
            // paddingRight: "8px",
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    },
    paymentIframeFooter: {
        //minHeight: '116px',
        '& .payment-method-button': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                padding: '0 24px',
            },
        },
        '@media(max-width: 599px)': {
            minHeight: '93.5px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },

    addCard: {
        minHeight: 440,
        '& .add-card-body': {
            maxWidth: 440,
            margin: '60px auto',
            [theme.breakpoints.down('xs')]: {
                padding: ' 0 16px',
            },
            '& .add-card-title': {
                fontSize: 18,
                lineHeight: 1.5,
                fontFamily: theme.typography.subtitle1.fontFamily,
                margin: '0 0 16px',
                color: theme.palette.primary.boxShadow4Dark,
                [theme.breakpoints.down('xs')]: {
                    padding: '0 0 0 8px',
                    fontSize: 16,
                },
            },
            '& .add-card-type': {
                cursor: 'pointer',
                display: 'flex',
                height: '72px',
                alignItems: 'center',
                justifyContent: 'space-around',
                boxShadow: `0 1px 0 ${theme.palette.primary.boxShadow2Dark}`,
                marginBottom: '10px',
                '& .card-type-details': {
                    [theme.breakpoints.down('xs')]: {
                        margin: '0 60px 0 0',
                    },
                    '& .card-type-title': {
                        fontSize: 14,
                        lineHeight: 1,
                        fontFamily: theme.typography.body3Medium.fontFamily,
                        color: theme.palette.primary.boxShadow4Dark,
                        marginBottom: 3,
                        [theme.breakpoints.down('xs')]: {
                            // padding: '0 0 0 8px',
                            fontSize: 12,
                        },
                    },
                    '& .card-type-description': {
                        fontSize: 14,
                        lineHeight: '20px',
                        fontFamily: theme.typography.body2.fontFamily,
                        color: theme.palette.primary.boxShadow4Dark,
                        [theme.breakpoints.down('xs')]: {
                            // padding: '0 0 0 8px',
                            fontSize: 12,
                        },
                    },
                },
                '& .card-logo': {
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='28' height='20' viewBox='0 0 28 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='6' width='20' height='2' rx='1' fill='%23892BA0' fill-opacity='0.5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21 1C21 0.447715 20.5523 0 20 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H24C26.2091 20 28 18.2091 28 16V4C28 1.79086 26.2091 0 24 0V0C23.4477 0 23 0.447715 23 1V1C23 1.55228 23.4477 2 24 2V2C25.1046 2 26 2.89543 26 4V16C26 17.1046 25.1046 18 24 18H4C2.89543 18 2 17.1046 2 16V4C2 2.89543 2.89543 2 4 2H20C20.5523 2 21 1.55228 21 1V1Z' fill='%23892BA0' fill-opacity='0.5'/%3E%3C/svg%3E%0A")`,
                    height: 28,
                    width: 28,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                },
                '& .right-arrow': {
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10.6L1.4 12L7.4 6L1.4 0L0 1.4L4.6 6L0 10.6Z' fill='%23D011C9'/%3E%3C/svg%3E")`,
                    height: 12,
                    width: 12,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                },
            },
        },
    },
    loadingBox: {
        position: 'absolute',
        zIndex: 2,
        top: 50,
        bottom: 0,
        left: 0,
        right: 0,
        heigth: 'fit-content',
    },
}));
