import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import {
    Grid,
    Tooltip,
    FormControl,
    InputBase,
    withStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useMyAccountStyle } from '../css/useMyAccountStyle';
import ENDPOINT from '../graphql/GraphQLConstants';
import Service from '../../components/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { useLoggedInStyle } from '../css/useLoggedInStyle';
import Loading from '../../components/loading/Loading';
import DirectoryOptions from './directory-options/DirectoryOptions';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    formControl: {
        // minHeight: "56px",
    },
    input: {
        minHeight: '56px',
        border: `1px solid ${theme.palette.primary.heather}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: theme.palette.primary.heather,

        position: 'relative',
        backgroundColor: theme.palette.background.paper,

        padding: '18px 43px 17px 11px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
}))(InputBase);

function MyEirAccountPreferences({ width }) {
    const classes = useMyAccountStyle();
    const loaderClasses = useLoggedInStyle();
    const [loading, setLoading] = useState(false);
    const token = sessionStorage.getItem('access_token');
    const serviceId = sessionStorage.getItem('LoggedinServices')
        ? JSON.parse(sessionStorage.getItem('LoggedinServices'))[0].id
        : null;
    const [nddNumbers, setNddNumbers] = useState([]);
    const [selectedServiceId, setselectedServiceId] = useState('');
    const [directoryPreferences, setDirectoryPreferences] = useState({});
    const { VOIP, MOBILE } = DOMAINS;

    const [state, setState] = React.useState({
        activeCustomer_email: true,
        activeCustomer_sms: true,
        activeCustomer_phone: true,
        activeCustomer_ftf: true,
        activeCustomer_directMail: true,
        nonactiveCustomer_email: true,
        nonactiveCustomer_sms: true,
        nonactiveCustomer_phone: true,
        nonactiveCustomer_ftf: true,
        nonactiveCustomer_directMail: true,
        allowThirdParty: true,
        invoiceDeliveryType: false,
    });

    const graphql = useApolloClient();

    const handlesetServiceId = id => {
        localStorage.setItem('NDDServiceId', id);
    };

    const handleChangeNDDPreference = e => {
        setLoading(true);
        console.log('handleChangeNDDPreference', e);
        handleToggleChange2(e);
    };

    // Directory Options - on select of a mobile service
    const handleServiceIDChange = async event => {
        setLoading(true);
        setselectedServiceId(event.target.value);
        await getNDDForNumber(
            nddNumbers.filter(number => number.serviceId === event.target.value)[0].phoneNo,
            Number(event.target.value)
        );
    };

    const handleToggleChange2 = async value => {
        if (
            token &&
            parseInt(localStorage.getItem('NDDServiceId')) &&
            parseInt(selectedServiceId)
        ) {
            try {
                await graphql.mutate({
                    mutation: Service.UPDATE_NDDPREFERENCE,
                    variables: {
                        token: token,
                        serviceId: parseInt(localStorage.getItem('NDDServiceId')),
                        nddPreference: '{ "nddPreference": "' + value + '"}',
                    },
                    refetchQueries: [
                        {
                            query: Service.GET_NDDPREFERENCE,
                            variables: {
                                token: token,
                                serviceId: parseInt(selectedServiceId),
                            },
                        },
                    ],
                });
                await getNDDForNumber(
                    nddNumbers.filter(
                        number => number.serviceId === localStorage.getItem('NDDServiceId')
                    )[0].phoneNo,
                    parseInt(localStorage.getItem('NDDServiceId'))
                );
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        } else {
            setLoading(false);
            console.log('Unable to update NDD Preference');
        }
    };

    const handleChange = event => {
        event.preventDefault();
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        var allow_third_party = event.target.name == 'allowThirdParty' ? true : false;
        setLoading(true);
        const permissionGroups = [
            {
                permissionGroup: 'ACTIVE_CUSTOMER',
                permissions: [
                    {
                        enabled:
                            event.target.name == 'activeCustomer_email'
                                ? !state.activeCustomer_email
                                : state.activeCustomer_email,
                        permission: 'ALLOW_EMAIL_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_sms'
                                ? !state.activeCustomer_sms
                                : state.activeCustomer_sms,
                        permission: 'ALLOW_SMS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_phone'
                                ? !state.activeCustomer_phone
                                : state.activeCustomer_phone,
                        permission: 'ALLOW_PHONE_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_ftf'
                                ? !state.activeCustomer_ftf
                                : state.activeCustomer_ftf,
                        permission: 'ALLOW_FOTS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_directMail'
                                ? !state.activeCustomer_directMail
                                : state.activeCustomer_directMail,
                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                    },
                ],
            },
            {
                permissionGroup: 'NO_LONGER_CUSTOMER',
                permissions: [
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_email'
                                ? !state.nonactiveCustomer_email
                                : state.nonactiveCustomer_email,
                        permission: 'ALLOW_EMAIL_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_sms'
                                ? !state.nonactiveCustomer_sms
                                : state.nonactiveCustomer_sms,
                        permission: 'ALLOW_SMS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_phone'
                                ? !state.nonactiveCustomer_phone
                                : state.nonactiveCustomer_phone,
                        permission: 'ALLOW_PHONE_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_ftf'
                                ? !state.nonactiveCustomer_ftf
                                : state.nonactiveCustomer_ftf,
                        permission: 'ALLOW_FOTS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_directMail'
                                ? !state.nonactiveCustomer_directMail
                                : state.nonactiveCustomer_directMail,
                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                    },
                ],
            },
        ];
        updateProfileDetails(permissionGroups, allow_third_party);
    };

    const updateProfileDetails = async (updatedpermissionGroups, allow_third_party) => {
        if (token && allow_third_party != null && updatedpermissionGroups) {
            try {
                const resp = await graphql.mutate({
                    mutation: Service.UPDATE_CONTACT_PERMISSION_GROUP,
                    variables: {
                        token: token,
                        allowThirdParty: allow_third_party
                            ? !state.allowThirdParty
                            : state.allowThirdParty,
                        permissionGroups: updatedpermissionGroups,
                    },

                    refetchQueries: [
                        {
                            query: Service.GET_PERMISSION,
                            variables: {
                                token: token,
                                serviceId: serviceId,
                            },
                        },
                    ],
                });
                if (resp) {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error in updateProfileDetails : ', error);
            }
        } else {
            setLoading(false);
            console.log('Unable to update profile details!');
        }
    };

    const updateBillDeliveryType = async event => {
        setLoading(true);
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        var invoiceMethod;
        if (!state.invoiceDeliveryType) {
            invoiceMethod = 'ONLINE';
        } else {
            invoiceMethod = 'POSTAL';
        }
        try {
            const resp = await graphql.mutate({
                mutation: Service.UPDATE_BILLDELIVERYTYPE,
                variables: {
                    token: token,
                    accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                    invoiceDeliveryMethod: '{ "invoiceDeliveryMethod": "' + invoiceMethod + '"}',
                },
            });

            if (resp) {
                setLoading(false);
            }
        } catch (error) {
            console.log(
                'error while updating billing type',
                sessionStorage.getItem('LoggedinUserAccountID')
            );
            setLoading(false);
        }
    };

    const getNDDNumbers = async () => {
        const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        let ndd_array = [];
        for (let itr = 0; itr < services.length; itr++) {
            if (
                services[itr].status === 'ACTIVE' &&
                (services[itr].domain === MOBILE || services[itr].domain === VOIP)
            ) {
                var service = {
                    phoneNo: String(
                        services[itr].domain === MOBILE
                            ? services[itr].msisdn
                            : services[itr].domain === VOIP
                            ? services[itr].sipNumber
                            : ''
                    ),
                    serviceId: String(services[itr].id),
                };
                ndd_array.push(service);
            }
        }
        setNddNumbers(ndd_array);
        if (ndd_array.length > 0) {
            setselectedServiceId(ndd_array[0].serviceId);
            await getNDDForNumber(ndd_array[0].phoneNo, Number(ndd_array[0].serviceId));
        }
    };

    // Directory Options - getting NDD for number
    const getNDDForNumber = async (number, serviceID) => {
        if (token && serviceID) {
            try {
                console.log('getNDDForNumber arguments [num, serviceId] => ', number, serviceID);
                const { data } = await graphql.query({
                    query: Service.GET_PROFILE_DETAILS,
                    variables: {
                        token: token,
                        serviceId: serviceID,
                    },
                    errorPolicy: 'all',
                });
                console.log('getNDDForNumber getProfileDetails => ', data);
                if (data.getMyNDDPreference) {
                    var ndd = {
                        phoneNo: String(number),
                        serviceId: String(serviceID),
                        NDDPreference: data.getMyNDDPreference.nddPreference,
                    };
                    console.log('getNDDForNumber setDirectoryPref =>', ndd);
                    localStorage.setItem('NDDServiceId', ndd.serviceId);
                    setDirectoryPreferences(ndd);
                } else {
                    throw 'NDD was not returned from API';
                }
            } catch (error) {
                console.log('error while fetching NDD for service id', serviceID);
            }
        } else {
            console.log('Unable to fetch NDD for Service ID', serviceID);
        }
        setLoading(false);
    };

    const fetchProfileDetails = async () => {
        if (token && serviceId && sessionStorage.getItem('LoggedinUserAccountID')) {
            var invoice_Delivery_Method;
            try {
                const res = await graphql.query({
                    query: Service.GET_PROFILE_DETAILS,
                    variables: {
                        token: token,
                        serviceId: serviceId,
                    },
                    errorPolicy: 'all',
                });
                try {
                    setLoading(true);
                    const response2 = await graphql.query({
                        query: ENDPOINT.GET_ACCOUNT_NUMBER,
                        variables: {
                            token: token,
                            accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                        },
                    });
                    let data2 = response2.data;
                    if (data2.getMyAccountID.invoiceDeliveryMethod != undefined) {
                        invoice_Delivery_Method = data2.getMyAccountID.invoiceDeliveryMethod;
                    }
                } catch (error) {
                    console.log('error in fetchinginvoiceDeliveryMethod ', error.message);
                    setLoading(false);
                }
                if (res.data.getMyPermissionDetails) {
                    var active_permission =
                        res.data.getMyPermissionDetails.permissionGroupResponse[0];
                    var nonactive_permission =
                        res.data.getMyPermissionDetails.permissionGroupResponse[1];
                    setState({
                        ...state,
                        activeCustomer_email: active_permission.permissions[0].enabled,
                        activeCustomer_sms: active_permission.permissions[1].enabled,
                        activeCustomer_phone: active_permission.permissions[2].enabled,
                        activeCustomer_ftf: active_permission.permissions[3].enabled,
                        activeCustomer_directMail: active_permission.permissions[4].enabled,
                        nonactiveCustomer_email: nonactive_permission.permissions[0].enabled,
                        nonactiveCustomer_sms: nonactive_permission.permissions[1].enabled,
                        nonactiveCustomer_phone: nonactive_permission.permissions[2].enabled,
                        nonactiveCustomer_ftf: nonactive_permission.permissions[3].enabled,
                        nonactiveCustomer_directMail: nonactive_permission.permissions[4].enabled,
                        allowThirdParty:
                            res.data.getMyPermissionDetails.allowThirdParty === null
                                ? false
                                : res.data.getMyPermissionDetails.allowThirdParty,
                        invoiceDeliveryType: invoice_Delivery_Method == 'POSTAL' ? false : true,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.log('Error in fetchProfileDetails : ', err);
                setLoading(false);
            }
        } else {
            console.log(
                'Unable to fetch fetchProfileDetails : Invalid Token or Service ID or Account ID'
            );
        }
    };

    const tooltipText = (
        <div class='p-3 pt-4 pb-4' style={{ fontSize: '12px', lineHeight: '15px' }}>
            <strong>Listed:</strong> Available from directory enquiries and the on-line phonebook{' '}
            <br />
            <br />
            <strong>Unlisted:</strong> Available from directory enquiries but not in the on-line
            phonebook <br />
            <br />
            <strong>Ex-directory:</strong> Not in National Directory Database and not in the
            Phonebook
        </div>
    );

    const tooltipTextMobile = (
        <div class='p-2' style={{ fontSize: '12px', lineHeight: '15px' }}>
            <strong>Listed:</strong> Available from directory
            <br /> enquiries and the on-line
            <br /> phonebook <br />
            <br />
            <strong>Unlisted:</strong> Available from
            <br /> directory enquiries but
            <br /> not in the on-line phonebook <br />
            <br />
            <strong>Ex-directory:</strong> Not in National
            <br /> Directory Database and
            <br /> not in the Phonebook
        </div>
    );

    function convertPhoneNumber(num) {
        const strNum = String(num);
        if (strNum.slice(0, 2) == '00') {
            return `+${strNum.slice(2)}`;
        }
        return strNum;
    }

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    useEffect(() => {
        getNDDNumbers();
    }, []);

    return (
        // <ThemeProvider theme={theme}>
        <Grid container className={classes.PreferenceWrapper}>
            {loading && (
                <Box className={loaderClasses.boxx}>
                    <div className={'loader-position ' + loaderClasses.loader}>
                        <Loading />
                    </div>
                </Box>
            )}
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={width >= 1020 ? 6 : 12}
                className='details-grid-first-half'
            >
                <div className='details-topic'>
                    <div className='details-topic-title'>Paperless billing</div>
                    <div className='details-topic-title-change'>
                        <div className='details-topic-content mr-4'>
                            Receive bills digitally, not via post.
                            {/* If you choose to receive paper
                            bills you will incur a charge of €5.99 */}
                        </div>
                        <div className='details-topic-change'>
                            <Switch
                                name={'invoiceDeliveryType'}
                                onChange={updateBillDeliveryType}
                                checked={state.invoiceDeliveryType}
                            />
                        </div>
                    </div>
                </div>
                <div className='vertical-divider'></div>
                {
                    <div className='details-topic'>
                        <div className='details-topic-title'>Marketing preferences</div>
                        <div className='details-topic-content'>
                            <div className='details-topic-content-heading'>
                                {' '}
                                As an eir customer{' '}
                            </div>
                            From time to time we'd like to contact you to keep you updated on our
                            promotions, products, services and market research. Please let us know
                            how you wish to be contacted.
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Email</div>
                                <div className='details-topic-change'>
                                    {' '}
                                    <Switch
                                        onChange={handleChange}
                                        name={'activeCustomer_email'}
                                        checked={!!state.activeCustomer_email}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>SMS</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'activeCustomer_sms'}
                                        checked={!!state.activeCustomer_sms}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Phone</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        defaultChecked
                                        onChange={handleChange}
                                        name={'activeCustomer_phone'}
                                        checked={!!state.activeCustomer_phone}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Face to face</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'activeCustomer_ftf'}
                                        checked={state.activeCustomer_ftf}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Direct mail</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'activeCustomer_directMail'}
                                        checked={state.activeCustomer_directMail}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='details-topic-content'>
                            <div className='details-topic-content-heading'>
                                No longer an eir customer{' '}
                            </div>
                            Because we are always adding to our range of products and services, if
                            you ever leave us we hope that you will consider us again in the future.
                            To keep up to date on our offers let us know how you wish to be
                            contacted.
                        </div>

                        <div className='mt-3 mb-3'>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Email</div>
                                <div className='details-topic-change'>
                                    {' '}
                                    <Switch
                                        onChange={handleChange}
                                        name={'nonactiveCustomer_email'}
                                        checked={state.nonactiveCustomer_email}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>SMS</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'nonactiveCustomer_sms'}
                                        checked={state.nonactiveCustomer_sms}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Phone</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'nonactiveCustomer_phone'}
                                        checked={state.nonactiveCustomer_phone}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Face to face</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'nonactiveCustomer_ftf'}
                                        checked={state.nonactiveCustomer_ftf}
                                    />
                                </div>
                            </div>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-sub-title'>Direct mail</div>
                                <div className='details-topic-change'>
                                    <Switch
                                        onChange={handleChange}
                                        name={'nonactiveCustomer_directMail'}
                                        checked={state.nonactiveCustomer_directMail}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={width >= 1020 ? 6 : 12}
                className='details-grid-second-half'
            >
                {width >= 1020 && <div className='divider'></div>}

                <div className='details-topic'>
                    <div className='details-topic-title'>Allow third party use</div>
                    <div className='details-topic-title-change'>
                        <div className='details-topic-content mr-4'>
                            From time to time allow our partners to send you details about their
                            products or services
                        </div>
                        <div className='details-topic-change'>
                            <Switch
                                name={'allowThirdParty'}
                                onChange={handleChange}
                                checked={state.allowThirdParty}
                            />
                        </div>
                    </div>
                </div>

                <div className='vertical-divider'></div>

                <div className='details-topic ndd'>
                    <div className='details-topic-title'>
                        <p>Directory options</p>
                        <Tooltip
                            enterTouchDelay={20}
                            leaveTouchDelay={15000}
                            title={width < 600 ? tooltipTextMobile : tooltipText}
                            style={{ overflow: 'visible' }}
                            placement={width < 600 ? 'bottom-end' : 'right'}
                            arrow
                        >
                            <IconButton>
                                <div className='iconUsage'></div>
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div className='details-topic-title-change'>
                        <div className='details-topic-content directory-options'>
                            <div>
                                You can decide whether your phone numbers will be available in the
                                directory
                            </div>
                        </div>
                    </div>
                    {nddNumbers.length > 0 && (
                        <>
                            <div className='details-topic-content mt-3'>
                                <div className='details-topic-content-heading pr-2'>
                                    <FormControl
                                        disabled={nddNumbers.length > 1 ? false : true}
                                        className='details-headerAccountsDropdown'
                                    >
                                        <Select
                                            classes={{
                                                select: 'details-dropdownSelect',
                                                icon: 'details-dropdownIcon',
                                                disabled: 'details-disabled',
                                            }}
                                            id='demo-customized-select-native'
                                            value={selectedServiceId}
                                            onChange={handleServiceIDChange}
                                            input={<BootstrapInput />}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            {nddNumbers.map((item, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.MenuItemsWrapper}
                                                        classes={{
                                                            selected: classes.DisplayNone,
                                                        }}
                                                        value={item.serviceId}
                                                    >
                                                        {convertPhoneNumber(item.phoneNo)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </>
                    )}
                    <DirectoryOptions
                        directoryPreferences={directoryPreferences}
                        handleChangeNDDPreference={handleChangeNDDPreference}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default MyEirAccountPreferences;
