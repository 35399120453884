import { Box, Button } from '@material-ui/core';
import React from 'react';
import EirButton from '../../../theme/EirButton';
import EirButtonLink from '../../../theme/EirButtonLink';

const CustomPopUpFooter = ({ cta1, cta2, link, setLinkStatus, styles }) => {
    return (
        <Box className={`${styles.popupFooter} ${Boolean(cta2.text) ? '' : styles.mb}`}>
            {Boolean(link?.text) && <EirButtonLink {...link} setLinkStatus={setLinkStatus} />}
            {Boolean(cta1.text) && (
                <EirButton
                    style={{ padding: '0.5rem 1.5rem' }}
                    variants='primaryButtonMedium'
                    // className={`${styles.cta} ${!cta1.disable ? styles.cta1 : styles.cta1Disable}`}
                    disabled={cta1.disable}
                    onClick={cta1.action}
                >
                    {cta1.text}
                </EirButton>
            )}
            {Boolean(cta1.text) && Boolean(cta2.text) && (
                <Box className={`${styles.cta} ${styles.cta2}`}>
                    <Box
                        component='span'
                        style={{
                            cursor: 'pointer',
                            height: Boolean(cta2.text) ? '' : '24px',
                            display: 'block',
                        }}
                        onClick={cta2.action}
                    >
                        {cta2.text}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CustomPopUpFooter;
