import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import Sim from '../images/sim.png'
import useStyles from './style';

const SimCostDisplay=({cost})=>{

    const classes = useStyles();
    return(
            <Box className={classes.mainContainer}>
                <Box className={classes.simImg}>
                    <Typography component='img' src={Sim} alt='sim' />
                </Box>
                    <Typography className={classes.textBold}>Free replacement SIM</Typography>
                    <Typography className={classes.text}>This SIM will work in all phones types</Typography>
                    <Box className={classes.cost}>Cost: €{cost}</Box>
            </Box>
    )
}

export default SimCostDisplay;