export default {
    validation: {
        eircodeRequired: 'Please enter your eircode.',
        addressLine1Required: 'Please enter your address.',
        addressSelectRequired: 'Please select your address.',
        
    },
    errormessage: {
        noAddressFoundforEirCode: 'Address not found for the eircode entered',
        addressFinderNetworkError: 'Network error. Please try later.',
        eircodeNotConfirmed: 'Please confirm eircode.',
    },
};
