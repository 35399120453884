import ENDPOINT from '../../graphql/GraphQLConstants';

export const getTvisAccountNum = async ({ graphql }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_TVIS_ACCOUNT_NUM,
            variables: {
                accountId: parseInt(sessionStorage.getItem('LoggedinUserAccountID')),
                token: sessionStorage.getItem('access_token'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getTvisPinVal = async ({ graphql, accountId }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_TVIS_PIN,
            variables: {
                accountId: accountId,
                token: sessionStorage.getItem('access_token'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const updateTvisPinVal = async ({ graphql, accountId, newPin }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.SET_TVIS_PIN,
            variables: {
                accountId: accountId,
                newPin,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getTvisFbsPp = async ({ graphql, offerCode }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.GET_TVIS_FBS_PP,
            variables: {
                identifier: sessionStorage.getItem('access_token'),
                offerCode: offerCode,
                user: sessionStorage.getItem('LoggedinUserAccountNumber'),
                billingSystem: 'ION',
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const cancelAPV = async ({ graphql }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.CANCEL_APV,
            variables: {
                identifier: sessionStorage.getItem('access_token'),
                reason: 'customer_opt_out',
                user: parseInt(sessionStorage.getItem('LoggedinUserAccountNumber')),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};
