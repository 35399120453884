import { useApolloClient } from '@apollo/client';
import ENDPOINT from './graphql/GraphQLConstants';
import { getNameAndAccountID } from './getNameAndAccountNumber';

export async function getServiceId(graphql) {
    try {
        if (
            sessionStorage.getItem('LoggedinUserSubscriptionID') == undefined ||
            sessionStorage.getItem('LoggedinUserSubscriptionID') == ''
        ) {
            const subscriptionResponse = await getNameAndAccountID(graphql);
        }

        if (
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID') == undefined ||
            sessionStorage.getItem('LoggedinUserSubscriptionID') == ''
        ) {
            const subscriptionResponse = await getNameAndAccountID(graphql);
        }

        let serviceArr = [];

        if (
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID') != undefined ||
            sessionStorage.getItem('LoggedinUserSubscriptionID') != ''
        ) {
            if (
                JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID'))
                    .length > 1
            ) {
                for (
                    let itr = 0;
                    itr <
                    JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID'))
                        .length;
                    itr++
                ) {
                    const res = await graphql.query({
                        query: ENDPOINT.SERVICE_ID,
                        variables: {
                            token: sessionStorage.getItem('access_token'),
                            subscriptionId: parseInt(
                                JSON.parse(
                                    sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
                                )[itr].id
                            ),
                        },
                    });
                    console.log('serv 1', res);
                    if (res.data.getMyServices) {
                        serviceArr = serviceArr.concat(res.data.getMyServices);
                    } else {
                        throw 'something went wrong while fetching service IDs';
                    }
                }
                sessionStorage.setItem('LoggedinServices', JSON.stringify(serviceArr));
                return [true, serviceArr];
            } else {
                const res = await graphql.query({
                    query: ENDPOINT.SERVICE_ID,
                    variables: {
                        token: sessionStorage.getItem('access_token'),
                        subscriptionId: parseInt(
                            sessionStorage.getItem('LoggedinUserSubscriptionID')
                        ),
                    },
                });
                if (res.data.getMyServices) {
                    sessionStorage.setItem(
                        'LoggedinServices',
                        JSON.stringify(res.data.getMyServices)
                    );
                    return [true, res.data.getMyServices];
                }
                return [false, 'something went wrong'];
            }
        } else {
            const res = await graphql.query({
                query: ENDPOINT.SERVICE_ID,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    subscriptionId: parseInt(sessionStorage.getItem('LoggedinUserSubscriptionID')),
                },
            });
            if (res.data.getMyServices) {
                sessionStorage.setItem('LoggedinServices', JSON.stringify(res.data.getMyServices));
                return [true, res.data.getMyServices];
            }
            return [false, 'something went wrong'];
        }
    } catch (err) {
        console.error('Error while fetching service ID', err);
        return [false, err.message];
    }
}
