import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useChangeContactStyle } from '../css/useChangeContactStyle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextField } from '@material-ui/core';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { publish } from 'pubsub-js';
import MyEirRegisterHeader from '../../sharedcomponents/MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import MyEirChangeSuccessModal from './MyEirChangeSuccessModal';
import EirButton from '../../theme/EirButton';

function MyEirChangeContactNumber(props) {
    const graphql = useApolloClient();
    const [isopen, setIsopen] = useState(false);
    const [contactChanged, setContactChanged] = useState(false);
    const [number, setNumber] = useState('');
    const [validNumber, setValidNumber] = useState(false);
    const [validConfirmNumber, setValidConfirmNumber] = useState(false);
    const [numbersMatch, setNumbersMatch] = useState(false);
    const [showNumberLabel, setShowNumberLabel] = useState(false);
    const [confirmNumber, setConfirmNumber] = useState('');
    const [showConfirmNumberLabel, setShowConfirmNumberLabel] = useState(false);
    const [isContactError, setIsContactError] = useState(false);

    const [numberChanged, setNumberChanged] = useState(false);
    const [isOpen3, setIsopen3] = useState(false);
    function toggle3() {
        setIsopen3(!isOpen3);
    }
    const resetStep = () => {
        console.log('reset');
        toggle();
        console.log('reset done');
    };

    const validPrimaryNumberFormat = /^08[0-9]{8}$/;
    const validSecondaryNumberFormat = /^(01|021|022|023|024|025|026|027|028|029|0402|0404|041|042|043|044|045|046|047|049|0504|0505|051|052|053|056|057|058|059|061|062|063|064|065|066|067|068|069|071|074|076|090|091|093|094|095|096|097|098|099|085|086|087|083|088|089)[0-9]{5,11}/;

    function validateNumber(valFun, val) {
        if (props?.phoneNumberType == 'MOBILE') {
            if (validPrimaryNumberFormat.test(val)) {
                valFun(true);
            } else {
                valFun(false);
            }
        } else {
            valFun(false);
            if (validSecondaryNumberFormat.test(val)) {
                valFun(true);
            } else {
                valFun(false);
            }
        }
    }

    const handleNumberChange = event => {
        validateNumber(setValidNumber, event.target.value);
        checkNumberMatching(event.target.value, confirmNumber);
        setNumber(event.target.value);
    };

    const handleConfirmNumberChange = event => {
        validateNumber(setValidConfirmNumber, event.target.value);
        checkNumberMatching(number, event.target.value);
        setConfirmNumber(event.target.value);
    };

    const handleConfirmNumberLabel = val => {
        setShowConfirmNumberLabel(val);
    };

    function checkNumberMatching(val1, val2) {
        if (val1 === val2) {
            setNumbersMatch(true);
        } else if (numbersMatch) {
            setNumbersMatch(false);
        }
    }

    const handleNumberLabel = val => {
        setShowNumberLabel(val);
    };
    function toggle() {
        setIsopen(!isopen);
    }

    function handleCloseClick() {
        toggle3();
    }

    function toggleNumber() {
        setNumberChanged(!numberChanged);
    }

    const handleBack = () => {
        setNumber('');
        setConfirmNumber('');
        setValidNumber(false);
        setValidConfirmNumber(false);
        checkNumberMatching('', '');
        setNumberChanged(false);
        toggle3();
    };

    const handleChangeClick = () => {
        setNumber('');
        setConfirmNumber('');
        setValidNumber(false);
        setValidConfirmNumber(false);
        checkNumberMatching('', '');
        setNumberChanged(false);
        toggle3();
    };

    const classes = useChangeContactStyle();

    async function handleUpdateNumber() {
        try {
            const response = await graphql.mutate({
                mutation: ENDPOINT.CHANGE_CONTACT,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    contactUuid: sessionStorage.getItem('contactUUID'),
                    phoneNumberId: props.phoneNumberId,
                    updatedPhoneNumber: JSON.stringify({
                        phoneNumber: number,
                        type: props.phoneNumberType,
                    }),
                },
            });
            const data = response.data;
            if (data.updateContactNumber === 204) {
                publish('updateContactNumber', number, props.phoneNumberType);
                console.log('Your number is now updated!');
                setNumberChanged(true);
                toggle();
            } else {
                console.log('Not able update your number. Please try again!');
                setNumberChanged(false);
                toggle();
            }
        } catch (err) {
            console.log('Not able update your number. Please try again!');
            console.log(err);
            setNumberChanged(false);
            toggle();
        }
    }

    return (
        <React.Fragment>
            <div
                className='details-topic-change'
                onClick={() => {
                    handleChangeClick();
                }}
            >
                Change
            </div>
            <Modal
                className={contactChanged ? 'contactSent-main' : 'changeContact-main'}
                // wrapClassName="changeEmail-wrap"
                wrapClassName={classes.ChangeContactWrapper}
                modalClassName={contactChanged ? 'ContactSent-modal' : 'changeContact-modal'}
                backdropClassName={
                    contactChanged ? 'contactSent-backdrop' : 'changeContact-backdrop'
                }
                contentClassName={contactChanged ? 'contactSent-content' : 'changeContact-content'}
                isOpen={isOpen3}
                toggle={toggle3}
                centered
            >
                <ModalBody className='changeContact-body'>
                    <MyEirRegisterHeader
                        headerText={'Change contact number'}
                        handleBackButton={handleBack}
                    />
                    <div className='changeContact-body-content'>
                        <div className='currentContact'>
                            <div className='currentContact-title'>Current number</div>
                            <div className='currentContact-content'>{props.phoneNumber}</div>
                        </div>
                        <div className='newNumber'>
                            <TextField
                                className='number-input-text-field'
                                id='new-number'
                                label={
                                    showNumberLabel || !!number ? 'New number' : 'Enter new number'
                                }
                                type='tel'
                                variant='outlined'
                                color='primary'
                                value={number}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink-tempRemoval' +
                                            (!validNumber && !!number
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (!validNumber && !!number
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!number ? ' text-field-input-props-root-filled' : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            (showNumberLabel
                                                ? 'text-field-input-props-notchedOutline'
                                                : 'text-field-input-props-notchedOutline-notFocus') +
                                            (!validNumber && !!number
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                                onFocus={() => {
                                    handleNumberLabel(true);
                                }}
                                onBlur={() => {
                                    handleNumberLabel(false);
                                }}
                                onChange={handleNumberChange}
                            />
                            {!validNumber && !!number && (
                                <div className='invalid-number-format'>
                                    Please enter a valid number
                                </div>
                            )}
                        </div>
                        <div className='confirmNumber'>
                            <TextField
                                className='number-input-text-field'
                                id='confirm-new-number'
                                label={
                                    showConfirmNumberLabel || !!confirmNumber
                                        ? 'Confirm new number'
                                        : 'Confirm new number'
                                }
                                type='tel'
                                variant='outlined'
                                color='primary'
                                value={confirmNumber}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink-tempRemoval' +
                                            (validNumber && validConfirmNumber && !numbersMatch
                                                ? ' text-field-input-label-props-shrink-nonMatch'
                                                : '') +
                                            (!validConfirmNumber && !!confirmNumber
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (!validConfirmNumber && !!confirmNumber
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!confirmNumber
                                                ? ' text-field-input-props-root-filled'
                                                : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            (showConfirmNumberLabel
                                                ? 'text-field-input-props-notchedOutline'
                                                : 'text-field-input-props-notchedOutline-notFocus') +
                                            ((!validConfirmNumber && !!confirmNumber) ||
                                            (validNumber && validConfirmNumber && !numbersMatch)
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                                onFocus={() => {
                                    handleConfirmNumberLabel(true);
                                }}
                                onBlur={() => {
                                    handleConfirmNumberLabel(false);
                                }}
                                onChange={handleConfirmNumberChange}
                            />
                            {!validConfirmNumber && !!confirmNumber && (
                                <div className='invalid-number-format'>
                                    Please enter a valid number
                                </div>
                            )}
                            {validNumber && validConfirmNumber && !numbersMatch && (
                                <div className='invalid-number-format'>Numbers do not match</div>
                            )}
                        </div>
                    </div>

                    <div className='changeNumber-body-footer'>
                        <EirButton
                            // className={
                            //     'changeNumber-body-footer-cta primary-cta' +
                            //     (validNumber && validConfirmNumber && numbersMatch
                            //         ? ' cta-enabled'
                            //         : ' cta-diabled')
                            // }
                            style={{ width: '11.4rem' }}
                            variants='primaryButtonMedium'
                            disabled={!(validNumber && validConfirmNumber && numbersMatch)}
                            onClick={handleUpdateNumber}
                        >
                            Save
                        </EirButton>
                        <button
                            className='changeNumber-body-footer-cta secondary-cta'
                            onClick={handleBack}
                        >
                            <span>Cancel</span>
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <MyEirChangeSuccessModal
                isOpen={isopen}
                toggle={toggle}
                toggle1={toggle3}
                resetStep={resetStep}
                successText={
                    numberChanged
                        ? 'Your contact number has been updated'
                        : 'Something went wrong and the contact number wasn’t updated. Please try again later'
                }
                codeStatus={numberChanged}
                buttonText='Close'
            />
        </React.Fragment>
    );
}

export default MyEirChangeContactNumber;
