import {getPaymentMethodApi} from './payment.api';

export const getPaymentMethod = async({graphql,setLoading, setCards, setValue, setIsDefaultCard, props, setErrors}) =>{
    setLoading(true);
        try {
            const resp = await getPaymentMethodApi({graphql});
            const data = await resp.data;

            if (data.getPaymentMethod.paymentMethods) {
                setCards(
                    data.getPaymentMethod.paymentMethods.filter(
                        card => card.paymentMethodType !== 'ANONYMOUS_CARD'
                    )
                );
                setValue(`${data.getPaymentMethod.paymentMethods[0].paymentMethodId}`);
                if (data.getPaymentMethod.paymentMethods.length > 1) {
                    const defaultCard = data.getPaymentMethod.paymentMethods.filter(
                        card => card.default
                    );
                    setValue(`${defaultCard[0].paymentMethodId}`);
                    setIsDefaultCard(defaultCard[0]);

                    if (defaultCard[0].paymentMethodType == 'DIRECT_DEBIT') {
                        props.handleIbanText(true);
                    } else {
                        props.handleIbanText(false);
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setErrors({ loading: true });
        }
}