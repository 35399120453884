import React, { useState, Fragment } from 'react';
import { useRegisterFormStyle } from './useRegisterFormStyle';
import { usePrepayFormStyle } from '../../sharedcomponents/css/usePrepayFormStyle';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, FormControlLabel, Checkbox, FormLabel, Container } from '@material-ui/core';
import { MyEirAddressFinder } from './MyEirAddressFinder';


function MyEirAddressDetails() {
    const { register } = useFormContext();
    // const classes = useRegisterFormStyle();
    const classes = usePrepayFormStyle();
    const [sameDeliveryAddress, setSameDeliveryAddress] = useState(true);

    return (
        <Fragment>
            <Container className={classes.prepayFormAddress}>
                <div className='accountAddress'>
                    <p className={classes.addressDetailsHeader}>Address</p>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* <p className='formAddress'>
                                {' '}
                                Please enter your eircode to autofill your address.
                            </p> */}
                        </Grid>
                        <Grid item xs={12}>
                            <MyEirAddressFinder />
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.sameAddressDetails}>
                    <Grid item xs={12} className='text-left'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sameDeliveryAddress}
                                    color='primary'
                                    id='sameDeliveryAddress'
                                    inputRef={register}
                                    name='sameDeliveryAddress'
                                    onChange={() =>
                                        setSameDeliveryAddress(previousState => !previousState)
                                    }
                                />
                            }
                            label='Delivery address is the same as account address.'
                        />
                    </Grid>
                    {!sameDeliveryAddress && (
                        <Grid item xs={12}>
                            <p>Delivery address</p>
                            <MyEirAddressFinder isDeliveryAddress />
                        </Grid>
                    )}
                </div>
            </Container>
        </Fragment>
    );
}

export default MyEirAddressDetails;
