import { Box, TextField, Tooltip, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CustomTooltip from '../tooltip/Tooltip';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import theme from '../../../theme/MyEirTheme';

/**
 *
 * @param {String} margin - dense | normal |
 * @param {String} variant - outlined | contained
 * @param {String} classes - { textField, errorMessage }
 * @returns {TextField}
 */
const DateField = props => {
    const { margin = 'normal', variant = 'outlined', className = '' } = props;
    const useStyle = makeStyles({
        dateField: {
            width: '100%',
            // margin: '0 0 1rem',
            'MuiInputAdornment-root ': {},
            '& .MuiInputBase-input': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
            },
            // colors on focus
            '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.heather,
                    fontFamily: theme.typography.body2.fontFamily,
                    borderWidth: 2,
                },
                '&.MuiInputLabel-root': {
                    fontFamily: theme.typography.body2.fontFamily,
                    color: theme.palette.primary.heather,
                },
                '& .MuiInputBase-input': {
                    fontFamily: theme.typography.body2.fontFamily,
                    color: theme.palette.primary.heather,
                },
            },
            '& .MuiTypography-body2': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
            },

            // helper text
            '& .MuiFormHelperText-contained': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.error,
                margin: 0,
                fontSize: 14,
                lineHeight: '20px',
            },

            '& .MuiIconButton-root': {
                padding: 0,
            },

            '& .MuiInputAdornment-positionEnd': {
                margin: 0,
                paddingRight: 2,
            },
        },
        backdrop: {
            backdropFilter: `blur(15px)`,
        },

        errorMessage: {
            fontFamily: theme.typography.body2.fontFamily,
            color: 'red',
            margin: 0,
            fontSize: 14,
            lineHeight: '20px',
        },
        calendar: {
            backgroundColor: 'pink',
        },
    });

    const [selectedDate, setSelectedDate] = useState(new Date());
    const classes = useStyle();

    useEffect(() => {
        // console.log('props.value', props.value);
        setSelectedDate(props.value);
    }, [props.value]);

    return (
        <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex' }}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    {/* <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        required
                        leftArrowButtonProps={{
                            disabled: true,
                        }}
                        DialogProps={{
                            BackdropProps: {
                                classes: { root: classes.backdrop },
                            },
                        }}
                        className={`${classes.dateField}`}
                        // variant='inline'
                        inputVariant={variant}
                        format='DD/MM/YYYY'
                        id={props.id}
                        label={props.label}
                        onChange={e => {
                            console.log('date selected', e);
                            // props.onChange(e);
                            window.mom = e;
                            setSelectedDate(e);
                            props.onChange(e._d);
                        }}
                        value={selectedDate}
                        error={props.error}
                        minDate={props.minDate}
                        maxDate={props.maxDate} //{moment().add(30, 'day')}
                        InputLabelProps={{
                            shrink: Boolean(selectedDate),
                        }}
                        errorMessage=''
                    /> */}
                    <DatePicker
                        disableToolbar
                        autoOk
                        required
                        leftArrowButtonProps={{
                            disabled: true,
                        }}
                        DialogProps={{
                            BackdropProps: {
                                classes: { root: classes.backdrop },
                            },
                        }}
                        className={`${classes.dateField}`}
                        inputVariant={variant}
                        format='DD/MM/YYYY'
                        id={props.id}
                        label={props.label}
                        onChange={e => {
                            console.log('date selected', e);
                            // props.onChange(e);
                            window.mom = e;
                            setSelectedDate(e);
                            props.onChange(e._d);
                        }}
                        value={selectedDate}
                        error={props.error}
                        minDate={props.minDate}
                        maxDate={props.maxDate} //{moment().add(30, 'day')}
                        InputLabelProps={{
                            shrink: Boolean(selectedDate),
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <CalendarTodayIcon />
                                </InputAdornment>
                            ),
                        }}
                        errorMessage=''
                    />
                </MuiPickersUtilsProvider>
                {props.tooltip && <CustomTooltip message={props.tooltip} />}
            </Box>
        </Box>
    );
};

export default DateField;
