import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { authorisedUserStyle } from './authorisedUserStyle';
import EditAuthorisedUserInput from './EditAuthorisedUserInput';

function MyEirEditAuthorisedUsers({
    authUsers,
    setStep,
    selectedUser,
    securityQuestions,
    setdisableCTA,
    disableCTA,
}) {
    const classes = authorisedUserStyle();
    return (
        <>
            <Box className={classes.editContainer}>
                <Box className={classes.userEditContent}>
                    <Box component='p' className={classes.editUser}>
                        User&nbsp;{selectedUser.index + 1}
                    </Box>
                    <Typography
                        className={classes.UserDeleteContent}
                        onClick={() => {
                            setStep('delete');
                        }}
                    >
                        Delete
                    </Typography>
                </Box>

                <Box>
                    <EditAuthorisedUserInput
                        user={selectedUser}
                        securityQuestions={securityQuestions}
                        setdisableCTA={setdisableCTA}
                        disableCTA={disableCTA}
                    />
                </Box>

                <Typography className={classes.editUserText}>
                    The password will be requested over the phone by our staff to confirm your
                    identity.
                </Typography>
            </Box>
        </>
    );
}

export default MyEirEditAuthorisedUsers;
