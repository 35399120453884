import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useEmailChangeStyle } from '../css/useEmailChangeStyle';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextField, Typography } from '@material-ui/core';
// import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import alreadyRegisteredIcon from '../../sharedcomponents/images/alreadyRegisteredIcon.png';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import MyEirChangePasswordAndEmailToggle from './MyEirChangePasswordAndEmailToggle';
import MyEirErrorHandle from '../eir-porting/MyEirErrorHandle';
import EirButton from '../../theme/EirButton';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

function MyEirChangeEmail(props) {
    const [isopen, setIsopen] = useState(false);
    const [emailChanged, setEmailChanged] = useState(false);
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [confirmEmail, setConfirmEmail] = useState('');
    const [validConfirmEmail, setValidConfirmEmail] = useState(false);
    const [emailsMatch, setEmailsMatch] = useState(false);
    const [showEmailLabel, setShowEmailLabel] = useState(false);
    const [showConfirmEmailLabel, setShowConfirmEmailLabel] = useState(false);
    const [apiError, setApiError] = useState(false);
    const graphql = useApolloClient();
    const validEmailFormat = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

    function validateEmail(valFun, val) {
        if (validEmailFormat.test(val)) {
            valFun(true);
        } else {
            valFun(false);
        }
    }

    const handleEmailChange = event => {
        validateEmail(setValidEmail, event.target.value);
        checkEmailMatching(event.target.value, confirmEmail);
        setEmail(event.target.value);
    };
    const handleConfirmEmailChange = event => {
        validateEmail(setValidConfirmEmail, event.target.value);
        checkEmailMatching(email, event.target.value);
        setConfirmEmail(event.target.value);
    };

    const handleEmailLabel = val => {
        setShowEmailLabel(val);
    };
    const handleConfirmEmailLabel = val => {
        setShowConfirmEmailLabel(val);
    };

    function checkEmailMatching(val1, val2) {
        if (val1.toUpperCase() === val2.toUpperCase()) {
            setEmailsMatch(true);
        } else if (emailsMatch) {
            setEmailsMatch(false);
        }
    }

    function handleChangeClick() {
        setEmail('');
        setConfirmEmail('');
        setValidEmail(false);
        setValidConfirmEmail(false);
        checkEmailMatching('', '');
        setEmailChanged(false);
        toggle();
    }

    //change
    async function handleUpdateEmail() {
        console.log('Updating your email id ...');
        try {
            const response = await graphql.mutate({
                mutation: ENDPOINT.UPDATE_EMAIL,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    newEmailAddress: email,
                },
            });
            const data = response.data;
            if (data.updateEmail == '') {
                console.log('Your email id is now updated!');
                setEmailChanged(true);
                setApiError(false);
            } else {
                console.log('Not able update your email. Please try again!');
                setEmailChanged(false);
                setIsopen(false);
                setApiError(true);

                throw '';
            }
        } catch (err) {
            console.log('Not able update your email. Please try again!');
            console.log(err);
            setEmailChanged(false);
            setIsopen(false);
            setApiError(true);
        }
    }

    function handleCloseClick() {
        toggle();
    }
    const handleClose = () => {
        setIsopen(false);
    };
    function toggle() {
        setIsopen(!isopen);
    }
    function toggleEmail() {
        setEmailChanged(!emailChanged);
    }
    const classes = useEmailChangeStyle();

    // const theme = createTheme({
    //     palette: {
    //         primary: {
    //             main: '#D011C9'
    //         },
    //         secondary: {
    //             main: '#E40046'
    //         }
    //     }
    // });

    return (
        <React.Fragment>
            <div
                className='details-topic-change'
                onClick={() => {
                    handleChangeClick();
                }}
            >
                Change
            </div>

            {!emailChanged ? (
                <Modal
                    className={'changeEmail-main'}
                    // wrapClassName="changeEmail-wrap"
                    wrapClassName={classes.ChangeEmailWrapper}
                    modalClassName={'changeEmail-modal'}
                    backdropClassName={'changeEmail-backdrop'}
                    contentClassName={'changeEmail-content'}
                    isOpen={isopen}
                    toggle={toggle}
                    centered
                >
                    <ModalBody className='changeEmail-body'>
                        <div className='changeEmail-body-header'>
                            <ArrowBackIcon
                                className='changeEmail-header-back-icon'
                                onClick={toggle}
                            />
                            <div className='changeEmail-header-text'>Change email</div>
                        </div>
                        <div className='changeEmail-body-content'>
                            <div className='currentEmail'>
                                <div className='currentEmail-title'>Current email</div>
                                <div className='currentEmail-content'>{props.loginEmail}</div>
                            </div>
                            <div className='newEmail'>
                                {/* <MuiThemeProvider theme={theme}> */}
                                {/* <TextField className="newEmail-input" label="New email" color='primary'/> */}
                                <TextField
                                    className='email-input-text-field'
                                    id='new-email'
                                    label={
                                        showEmailLabel || !!email ? 'New Email' : 'Enter new email'
                                    }
                                    type='email'
                                    variant='outlined'
                                    color='primary'
                                    value={email}
                                    InputLabelProps={{
                                        classes: {
                                            root: 'text-field-input-label-props-root',
                                            shrink:
                                                'text-field-input-label-props-shrink-tempRemoval' +
                                                (!validEmail && !!email
                                                    ? ' text-field-input-label-props-shrink-invalid'
                                                    : ''),
                                            focused:
                                                'text-field-input-label-props-focused' +
                                                (!validEmail && !!email
                                                    ? ' text-field-input-label-props-focused-invalid'
                                                    : ''),
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root:
                                                'text-field-input-props-root' +
                                                (!!email
                                                    ? ' text-field-input-props-root-filled'
                                                    : ''),
                                            focused: 'text-field-input-props-focused',
                                            notchedOutline:
                                                (showEmailLabel
                                                    ? 'text-field-input-props-notchedOutline'
                                                    : 'text-field-input-props-notchedOutline-notFocus') +
                                                (!validEmail && !!email
                                                    ? ' text-field-input-props-notchedOutline-invalid'
                                                    : ''),
                                            input: 'text-field-input-props-input',
                                        },
                                    }}
                                    onFocus={() => {
                                        handleEmailLabel(true);
                                    }}
                                    onBlur={() => {
                                        handleEmailLabel(false);
                                    }}
                                    onChange={handleEmailChange}
                                />
                                {!validEmail && !!email && (
                                    <Typography
                                        variant='inputLabel'
                                        className='invalid-email-format'
                                    >
                                        Please enter a valid email ID
                                    </Typography>
                                )}
                                {/* </MuiThemeProvider> */}
                            </div>
                            <div className='confirmEmail'>
                                {/* <MuiThemeProvider theme={theme}> */}
                                {/* <TextField className="confirmEmail-input" label="Confirm email" color='primary'/> */}
                                <TextField
                                    className='email-input-text-field'
                                    id='confirm-new-email'
                                    label={
                                        showConfirmEmailLabel || !!confirmEmail
                                            ? 'Confirm new email'
                                            : 'Confirm new email'
                                    }
                                    type='email'
                                    variant='outlined'
                                    color='primary'
                                    value={confirmEmail}
                                    InputLabelProps={{
                                        classes: {
                                            root: 'text-field-input-label-props-root',
                                            shrink:
                                                'text-field-input-label-props-shrink-tempRemoval' +
                                                (validEmail && validConfirmEmail && !emailsMatch
                                                    ? ' text-field-input-label-props-shrink-nonMatch'
                                                    : '') +
                                                (!validConfirmEmail && !!confirmEmail
                                                    ? ' text-field-input-label-props-shrink-invalid'
                                                    : ''),
                                            focused:
                                                'text-field-input-label-props-focused' +
                                                (!validConfirmEmail && !!confirmEmail
                                                    ? ' text-field-input-label-props-focused-invalid'
                                                    : ''),
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root:
                                                'text-field-input-props-root' +
                                                (!!confirmEmail
                                                    ? ' text-field-input-props-root-filled'
                                                    : ''),
                                            focused: 'text-field-input-props-focused',
                                            notchedOutline:
                                                (showConfirmEmailLabel
                                                    ? 'text-field-input-props-notchedOutline'
                                                    : 'text-field-input-props-notchedOutline-notFocus') +
                                                ((!validConfirmEmail && !!confirmEmail) ||
                                                (validEmail && validConfirmEmail && !emailsMatch)
                                                    ? ' text-field-input-props-notchedOutline-invalid'
                                                    : ''),
                                            input: 'text-field-input-props-input',
                                        },
                                    }}
                                    onFocus={() => {
                                        handleConfirmEmailLabel(true);
                                    }}
                                    onBlur={() => {
                                        handleConfirmEmailLabel(false);
                                    }}
                                    onChange={handleConfirmEmailChange}
                                />
                                {!validConfirmEmail && !!confirmEmail && (
                                    <Typography
                                        variant='inputLabel'
                                        className='invalid-email-format'
                                    >
                                        Please enter a valid email ID
                                    </Typography>
                                )}
                                {validEmail && validConfirmEmail && !emailsMatch && (
                                    <Typography
                                        variant='inputLabel'
                                        className='invalid-email-format'
                                    >
                                        Email addresses do not match
                                    </Typography>
                                )}
                                {/* </MuiThemeProvider> */}
                            </div>
                        </div>
                        <div className='changeEmail-body-footer'>
                            <EirButton
                                variants='primaryButtonMedium'
                                style={{ width: '11.4rem' }}
                                // className={
                                //     'changeEmail-body-footer-cta primary-cta' +
                                //     (validEmail && validConfirmEmail && emailsMatch
                                //         ? ' cta-enabled'
                                //         : ' cta-diabled')
                                // }
                                disabled={!(validEmail && validConfirmEmail && emailsMatch)}
                                onClick={handleUpdateEmail}
                            >
                                Save
                            </EirButton>

                            <button
                                className='changeEmail-body-footer-cta secondary-cta'
                                onClick={toggle}
                            >
                                <span>Cancel</span>
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                <MyEirChangePasswordAndEmailToggle
                    toggle={toggle}
                    email={email}
                    isopen={isopen}
                    heading={'Change email'}
                    headingMobile={'Change email'}
                    newsContentText={'Please, confirm your email!'}
                    verifiedText={''}
                    verifiedTextMobile={''}
                    subtext={'An email has been sent to'}
                    subtextContinued={
                        'You need to click on the link provided to change to your new email address.'
                    }
                    buttonText={'Close'}
                    footerText={''}
                />
            )}
            {apiError ? (
                <MyEirErrorHandle open={apiError} setOpen={setApiError} handleClose={handleClose} />
            ) : (
                ''
            )}
        </React.Fragment>
    );
}

export default MyEirChangeEmail;
