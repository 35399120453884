import React, { useState } from 'react';
import { List, ListItem, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function FooterMobileAccordion(props) {
    const [collapse, setcollapse] = useState(false);

    const toggle = () => {
        setcollapse(!collapse);
    };

    return (
        <Accordion className='footer-card'>
            <AccordionSummary
                onClick={toggle}
                className='footer-card__header'
                id={props.title + '-header'}
                aria-controls={props.title + '-content'}
                expandIcon={<ExpandMoreIcon />}
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails className='footer-card__body' {...collapse}>
                <List className='footer-card__menu'>
                    {props.children.map((navItem, index) => {
                        return (
                            <ListItem key={index} className='footer-list'>
                                <a href={navItem.link.link} className='footer-links'>
                                    {navItem.link.title}
                                </a>
                            </ListItem>
                        );
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    );
}

export default FooterMobileAccordion;
