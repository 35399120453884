import { makeStyles } from '@material-ui/core/styles';
export const useMyPaymentsStyle = makeStyles(theme => ({
    MyPaymentsWrapper: {
        // background: "grey",
        marginTop: '40px',
        marginBottom: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',

        '& .myPaymentsContainer': {
            maxWidth: '1144px',
            // background: "#FFFFFF",
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
            borderRadius: '10px',
            margin: 'auto',

            '& .payments-accordion': {
                borderRadius: '10px',
                background: theme.palette.primary.white,

                '& .payments-header': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.h3.fontSize,
                    lineHeight: '33px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    letterSpacing: '0.25px',
                    color: theme.palette.primary.heather,

                    minHeight: '120px',
                    paddingTop: '43px',
                    alignItems: 'flex-start',

                    '& .payments-header-block': {
                        display: 'flex',

                        '& .payments-header-block-img': {
                            marginRight: '19.5px',
                        },
                    },
                },

                '& .payments__header-expandIcon': {
                    margin: '0px',
                    padding: '0px',
                },

                '& .payments-header.payments__header-expanded': {
                    minHeight: '34px',
                },

                '& .payments__header-content': {
                    flexGrow: '0',
                    margin: '0',
                },

                '& .myPayments-expand-icon': {
                    width: '36px',
                    height: '36px',
                    color: theme.palette.primary.sunset,
                },
            },

            '& .payment-loader': {
                '& .payments-content-loading': {
                    '& .payments-retry-loading': {},
                },

                '& .mypayments-loading-animation': {
                    padding: '0px!important',
                    '@media(max-width: 1019px)': {
                        padding: '0px!important',
                    },
                    '@media(max-width: 599px)': {
                        padding: '0px!important',
                    },
                },
            },

            '& .payment-transaction-history-container': {
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '32px',
                paddingRight: '37px',
                paddingTop: '18px',
                paddingBottom: '27px',

                '& .payment-transaction-history-text': {
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                    color: theme.palette.primary.heather,
                },
            },

            '& .payment-summary-container': {
                maxWidth: '1016px',

                padding: '0px',
                margin: '0px auto',
                paddingBottom: '50px',

                '& .payment-summary': {
                    width: '100%',
                    maxWidth: '654px',
                    margin: '0px auto',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                    '& .payment-summary-header': {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0px',
                        // marginLeft: '176px',

                        '& .payment-summary-keys': {
                            maxWidth: '600px',
                            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            paddingBottom: '12px',

                            '& div': {
                                width: '93px',
                                textAlign: 'center',
                            },
                        },

                        '& .Payment-summary__headerIcon': {
                            flex: '1',
                        },

                        '& .payment-date, & .payment-type, & .payment-amount, & .payment-balance': {
                            fontFamily: theme.typography.subtitle1.fontFamily,
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '24px',
                        },
                    },

                    '& .bar-container': {
                        display: 'none',
                        width: '670px',
                        height: '0px',
                        border: `1px solid ${theme.palette.primary.borderColor}`,
                        marginLeft: '15rem',
                        marginBottom: '6px',
                    },

                    '& .payment-summary-accordion': {
                        // display: 'block',
                        // padding: '0px 32px',
                        // maxWidth: '952px',
                        // margin: 'auto',
                        maxWidth: '654px',
                        width: '100%',

                        '@media(max-width: 599px)': {
                            padding: '0px 32px',
                        },

                        '& .payment-summary-card': {
                            // width: '80%',
                            // marginLeft: '167px',
                            border: '0',
                            borderRadius: '0',
                            background: theme.palette.primary.white,
                            minHeight: '55px',
                            marginTop: '0px',
                            // marginBottom: '1px',
                            boxShadow: `0px 1px 0px ${theme.palette.primary.white}`,

                            '@media(max-width: 599px)': {},

                            '&.MuiAccordion-root:before': {
                                backgroundColor: 'transparent',
                            },
                            // Accordian Header
                            '& .payment-summary-card__header': {
                                fontFamily: theme.typography.body2.fontFamily,
                                cursor: 'pointer',
                                minHeight: '55px',
                                color: '#666',
                                position: 'relative',
                                // marginRight: '16rem',
                                padding: '0px',
                                justifyContent: 'space-between',

                                '@media(max-width: 599px)': {
                                    padding: '0 8.5px',
                                },
                                //Accordian header Content
                                '& .payment-summary-card__header-content': {
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                    fontFamily: theme.typography.body3Medium.fontFamily,
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: theme.typography.body3Medium.fontSize,
                                    lineHeight: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: theme.palette.primary.heather,
                                    maxWidth: '600px',
                                    margin: '0px',
                                    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

                                    '& .payment-summary-data-container': {
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0px',

                                        '& .summary-amount': {
                                            '& .invoice-amount': {
                                                color: theme.palette.primary.error,
                                            },
                                        },

                                        '& .summary-balance': {
                                            '& .invoice-balance': {
                                                color: theme.palette.primary.error,
                                            },
                                        },

                                        '& div': {
                                            textAlign: 'center',
                                        },
                                    },

                                    // paddingLeft: "10px",
                                    // paddingRight: "90px",
                                    // paddingBottom: "10px",

                                    '@media(max-width: 599px)': {
                                        fontSize: theme.typography.subtitle2.fontSize,
                                        lineHeight: '18px',
                                    },
                                },

                                '& .Mui-expanded': {
                                    '@media(max-width: 599px)': {
                                        marginTop: '16px',
                                        marginBottom: '8px',
                                    },
                                },

                                '& .payment-summary-card__header-expandIcon': {
                                    // position: 'absolute',
                                    padding: 0,
                                    margin: 0,
                                    bottom: '8px',
                                    right: '5px',
                                },

                                '& .payment-summary-expand-icon': {
                                    height: '21px',
                                    width: '21px',
                                    color: theme.palette.primary.sunset,
                                },
                            },
                            //Accordian Body
                            '& .payment-summary-card__body': {
                                // padding: '0px 10px 17px 10px',
                                paddingLeft: '9px',
                                padding: '0px',

                                '@media(max-width: 599px)': {
                                    padding: '0px 24.5px 16px 8.5px',
                                },

                                '& .payment-summary-card__menu': {
                                    padding: 0,
                                    right: '9px',
                                    background: '#F3F5F9',
                                    width: '93.2%',

                                    '& .payment-summary-list': {
                                        fontFamily: theme.typography.body2.fontFamily,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: theme.typography.subtitle2.fontSize,
                                        lineHeight: '22px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: theme.palette.primary.heather,

                                        '& .bold': {
                                            fontWeight: '600',
                                        },

                                        '@media(max-width: 599px)': {
                                            fontSize: theme.typography.inputLabel.fontSize,
                                            lineHeight: '18px',
                                        },

                                        '& .summary-data-container': {
                                            width: '101%',
                                            height: '32px',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginLeft: '0rem',

                                            '& p': {
                                                margin: '0px',
                                            },

                                            '& .summary-amount': {
                                                '& .amount': {
                                                    color: theme.palette.primary.error,
                                                },
                                            },

                                            '& .summary-balance': {
                                                paddingLeft: '18px',

                                                '& .balance': {
                                                    color: theme.palette.primary.error,
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },

            '& .see-more-payment': {
                paddingTop: '20px',
                paddingBottom: '52.35px',

                '@media(max-width: 1019px)': {
                    paddingTop: '24px',
                    paddingBottom: '31px',
                },
                '@media(max-width: 599px)': {
                    paddingTop: '15px',
                    paddingBottom: '24px',
                },

                '& .more-payment-button, & .less-payment-button': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.body3Medium.fontSize,
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: theme.palette.primary.sunset,

                    display: 'flex',
                    alignItems: 'center',

                    margin: 'auto',

                    cursor: 'pointer',

                    '&:hover': {
                        color: '#B10071',
                    },

                    '@media(max-width: 599px)': {
                        fontSize: theme.typography.body1.fontSize,
                    },
                },
            },
        },

        '& .summary-date, & .summary-type, & .summary-amount, & .summary-balance': {
            width: '122px',
        },

        // '& .summary-date': {
        //     paddingRight: '11px',
        // },

        // '& .summary-type': {
        //     paddingRight: '30px',
        // },

        // '& .summary-amount': {
        //     paddingRight: '30px',
        // },

        '& .summary-balance': {
            paddingLeft: '20px',
        },

        '& .date, & .type, & .amount, & .balance, & .invoice-amount, & .invoice-balance': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '16px',
        },
        '& .negative': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '16px',
            color: `${theme.palette.primary.error} !important`,
        },
    },

    MyPaymentsMobileWrapper: {
        // background: "grey",
        marginTop: '40px',
        marginBottom: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',
        '@media(max-width: 599px)': {
            padding: '0px 24px',
        },
        '& .myPaymentsContainer': {
            maxWidth: '1144px',
            // background: "#FFFFFF",
            '@media(max-width: 1019px)': {
                maxWidth: '570px',
            },
            '@media(max-width: 599px)': {
                maxWidth: 'calc(312 / 360 * 100vw)',
            },
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
            borderRadius: '10px',
            margin: 'auto',

            '& .payments-accordion': {
                borderRadius: '10px',
                background: theme.palette.primary.white,

                '& .payments-header': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.h3.fontSize,
                    lineHeight: '38px',

                    letterSpacing: '0.25px',
                    color: theme.palette.primary.heather,
                    minHeight: '75px',
                    alignItems: 'flex-start',

                    '& .payments-header-block': {
                        lineHeight: '32px',
                        margin: '0 auto',

                        '& .payments-header-block-img': {
                            marginRight: '19.5px',

                            '@media(max-width: 599px)': {
                                display: 'none',
                            },
                        },

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.h4.fontSize,
                        },
                    },
                },

                '& .payments__header-expandIcon': {
                    margin: '0px',
                    padding: '0px',
                },

                '& .payments-header.payments__header-expanded': {
                    minHeight: '34px',
                },

                '& .payments__header-content': {
                    flexGrow: '0',
                    margin: '0',
                },

                '& .myPayments-expand-icon': {
                    width: '36px',
                    height: '36px',
                    color: theme.palette.primary.sunset,
                },
            },

            '& .payment-loader': {
                '& .payments-content-loading': {
                    '& .payments-retry-loading': {},
                },

                '& .mypayments-loading-animation': {
                    padding: '0px!important',
                    '@media(max-width: 1019px)': {
                        padding: '0px!important',
                    },
                    '@media(max-width: 599px)': {
                        padding: '0px!important',
                    },
                },
            },

            '& .payment-transaction-history-container': {
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '32px',
                paddingRight: '37px',
                paddingBottom: '27px',
                paddingTop: '0px',

                '& .payment-transaction-history-text': {
                    width: '225px',
                    minHeight: '48px',
                    textAlign: 'center',
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                    color: theme.palette.primary.heather,
                },
            },

            '& .payment-summary-container': {
                maxWidth: '530px',
                paddingBottom: '50px',
                padding: '0px',
                margin: '0px auto',

                '& .payment-summary': {
                    width: '100%',
                    maxWidth: '654px',
                    margin: '0px auto',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                    '& .payment-summary-header': {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: '0px',

                        '& .payment-summary-keys': {
                            maxWidth: '90%',
                            //  borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            //  paddingBottom:"12px",

                            '& div': {
                                width: '93px',
                                textAlign: 'center',
                            },
                        },

                        '& .Payment-summary__headerIcon': {
                            flex: '1',
                        },

                        '& .payment-date, & .payment-type, & .payment-amount, & .payment-balance': {
                            fontFamily: theme.typography.subtitle1.fontFamily,
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '24px',
                        },
                    },

                    '& .bar-container': {
                        display: 'none',
                        width: '670px',
                        height: '0px',
                        border: `1px solid ${theme.palette.primary.borderColor}`,
                        marginLeft: '15rem',
                        marginBottom: '6px',
                    },

                    '& .payment-mobile-summary-accordion': {
                        // display: 'block',
                        // padding: '0px 32px',
                        // maxWidth: '952px',
                        // margin: 'auto',
                        maxWidth: '654px',
                        width: '100%',

                        '@media(max-width: 599px)': {
                            //  padding: '0px 32px',
                        },

                        '& .payment-summary-card': {
                            // width: '80%',
                            // marginLeft: '167px',
                            border: '0',
                            borderRadius: '0',
                            background: theme.palette.primary.white,
                            minHeight: '55px',
                            marginTop: '0px',
                            // marginBottom: '1px',
                            boxShadow: `0px 1px 0px ${theme.palette.primary.white}`,

                            '@media(max-width: 599px)': {},

                            '&.MuiAccordion-root:before': {
                                backgroundColor: 'transparent',
                            },
                            // Accordian Header
                            '& .payment-summary-card__header': {
                                fontFamily: theme.typography.body2.fontFamily,
                                cursor: 'pointer',
                                minHeight: '55px',
                                color: theme.palette.primary.heather,
                                position: 'relative',
                                // marginRight: '16rem',
                                padding: '0px',
                                justifyContent: 'space-between',

                                '@media(max-width: 599px)': {
                                    padding: '0 8.5px',
                                },
                                //Accordian header Content

                                '& .payment-summary-card__header-content': {
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    fontFamily: theme.typography.body3Medium.fontFamily,
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: theme.typography.body3Medium.fontSize,
                                    lineHeight: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: theme.palette.primary.heather,
                                    maxWidth: '93%',
                                    margin: '0px',

                                    '@media (min-width:600px) and (max-width:1019px)': {
                                        maxWidth: '90% !important',
                                    },

                                    '& .payment-summary-data-container': {
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0px',

                                        '& .summary-amount': {
                                            '& .invoice-amount': {
                                                color: theme.palette.primary.error,
                                            },
                                        },

                                        '& .summary-balance': {
                                            '& .invoice-balance': {
                                                color: theme.palette.primary.error,
                                            },
                                        },

                                        '& div': {
                                            textAlign: 'center',
                                        },
                                    },
                                },

                                '& .Mui-expanded': {
                                    '@media(max-width: 599px)': {
                                        margin: '0px',
                                    },
                                },

                                '& .payment-summary-card__header-expandIcon': {
                                    // position: 'absolute',
                                    padding: 0,
                                    margin: 0,
                                    bottom: '6px',
                                    right: '5px',
                                },

                                '& .payment-summary-expand-icon': {
                                    height: '21px',
                                    width: '21px',
                                    color: theme.palette.primary.sunset,
                                },
                            },
                            //Accordian Body
                            '& .payment-summary-card__body': {
                                width: '100%',
                                // paddingLeft: '9px',
                                padding: '0px',

                                '@media(max-width: 599px)': {
                                    // padding: '0px 24.5px 16px 8.5px',
                                },

                                '& .payment-summary-card__menu': {
                                    width: '100%',
                                    padding: 0,
                                    // right: '9px',
                                    background: '#F3F5F9',

                                    '& .payment-summary-list': {
                                        fontFamily: theme.typography.body2.fontFamily,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: theme.typography.subtitle2.fontSize,
                                        lineHeight: '22px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: theme.palette.primary.heather,
                                        textAlign: 'center',
                                        padding: '16px 15px',

                                        '& p': {
                                            width: '100%',
                                            marginBottom: '0px',
                                        },

                                        '& .bold': {
                                            fontWeight: '600',
                                        },

                                        '@media(max-width: 599px)': {
                                            fontSize: theme.typography.subtitle2.fontSize,
                                            lineHeight: '22px',
                                        },

                                        '& .summary-data-container': {
                                            width: '87%',
                                            height: '32px',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginLeft: '0rem',

                                            '& .summary-amount': {
                                                '& .amount': {
                                                    color: theme.palette.primary.error,
                                                },
                                            },

                                            '& .summary-balance': {
                                                paddingLeft: '18px',

                                                '& .balance': {
                                                    color: theme.palette.primary.error,
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },

            '& .see-more-payment': {
                paddingTop: '35.65px',
                paddingBottom: '52.35px',

                '@media(max-width: 1019px)': {
                    paddingTop: '24px',
                    paddingBottom: '31px',
                },
                '@media(max-width: 599px)': {
                    paddingTop: '15px',
                    paddingBottom: '24px',
                },

                '& .more-payment-button, & .less-payment-button': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.body3Medium.fontSize,
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: theme.palette.primary.sunset,
                    display: 'flex',
                    alignItems: 'center',
                    margin: 'auto',
                    cursor: 'pointer',

                    '&:hover': {
                        color: theme.palette.primary.sunset30,
                    },

                    '@media(max-width: 599px)': {
                        fontSize: theme.typography.body1.fontSize,
                    },
                },
            },
        },

        '& .summary-date, & .summary-type, & .summary-amount, & .summary-balance': {
            width: '93px',
        },

        '& .summary-type': {
            '@media (min-width:600px) and (max-width:1019px)': {
                width: '122px',
            },
        },

        // '& .summary-date': {
        //     paddingRight: '11px',
        // },

        // '& .summary-type': {
        //     paddingRight: '30px',
        // },

        // '& .summary-amount': {
        //     paddingRight: '30px',
        // },

        // '& .summary-balance': {
        //     paddingLeft: '0px',
        // },

        '& .date, & .type, & .amount, & .balance, & .invoice-amount, & .invoice-balance': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
        },
        '& .negative': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '16px',
            color: `${theme.palette.primary.error} !important`,
        },
    },

    DisplayNone: {
        display: 'none',
    },
}));
