import { Box, Grid, Hidden } from '@material-ui/core';
import { useMyAccountStyle } from '../css/useMyAccountStyle';
import mySimIcon from '../images/mySimIcon.png';
import InactiveSim from '../eir-sim-replacement/InactiveSim';
import React, { useEffect, useState } from 'react';
import ActivateSimReplacementContainer from '../eir-sim-replacement/ActivateSimReplacementContainer';
import { useApolloClient } from '@apollo/client';
import { getContractsDetailsValues } from '../my-account/getContractsDetailsValues';
import SimReplacementTile from './SimReplacementTile';

const MyEirAccountSimreplacement = ({ ContactDetails }) => {
    const classes = useMyAccountStyle();
    const graphql = useApolloClient();
    const [msisdnVal, setMsisdnVal] = useState([]);

    async function getMsisdns() {
        const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        let temp = [];
        for (let i = 0; i < services.length; i++) {
            if (services[i].domain === 'MOBILE') {
                temp.push(services[i]);
            }
        }
        setMsisdnVal(temp);
    }

    useEffect(() => {
        getMsisdns();
    }, []);

    return (
        <Grid container spacing={2} className={classes.SimreplacementWrapper}>
            <Grid item>
                <Box className='details-topic-title'>Select your SIM:</Box>
            </Grid>

            {msisdnVal.map((item, index) => {
                return (
                    <Grid item className={`sim-img `}>
                        <SimReplacementTile
                            msisdn={item.msisdn}
                            serviceId={item.id}
                            ContactDetails={ContactDetails}
                        />
                        <Box className='divider-line'></Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default MyEirAccountSimreplacement;
