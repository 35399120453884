import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Hidden } from '@material-ui/core';
import { useMyAccountStyle } from '../css/useMyAccountStyle';
import { subscribe } from 'pubsub-js';
import myBillsIcon from '../images/myBills.png';
import { useWindowDimension } from '../../hooks/windowDimensions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import myAccountLogo from '../images/myAccountLogo.svg';
import MyEirAccountDetails from './MyEirAccountDetails';
import { MobileContext } from '../global-context';
import MyEirAccountPreferences from './MyEirAccountPreferences';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { getServiceId } from '../getServiceID';
import { getNameAndAccountID } from '../getNameAndAccountNumber';
import Loading from '../../components/loading/Loading';
import MyEirAccountContracts from './MyEirAccountContracts';
import MyEirAccountSimreplacement from './MyEirAccountSimreplacement';

function MyEirAccountContainer({ expandOnNavClick }) {
    const DT_INDEX = 4;
    const [expanded, setExpanded] = useState(false);
    const [expandedSecondary, setExpandedSecondary] = useState('details');
    const [contactDetails, setContactDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [retry, setRetry] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const [payDetails, setPayDetails] = useState(null);
    const [contracts, setContracts] = useState([]);

    const classes = useMyAccountStyle();
    const [width, height] = useWindowDimension();

    const { state, dispatch } = useContext(MobileContext);
    const graphql = useApolloClient();

    const [loadingContracts, setLoadingContracts] = useState(true);
    const [msisdnLoadingError, setMsisdnLoadingError] = useState(false);
    const [msisdnAndOffer, setMsisdnAndOffer] = useState([]);
    const [catalogOffer, setCatalogOffer] = useState([]);

    async function getOffer() {
        const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const subscriptionList = JSON.parse(
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
        );
        let arrTemp = [];
        try {
            for (let itr = 0; itr < subscriptionList.length; itr++) {
                const res = await graphql.query({
                    query: ENDPOINT.GET_OFFER_DETAILS,
                    variables: {
                        catalogOfferCode: subscriptionList[itr].catalogOfferCode,
                    },
                });
                const data = res.data;
                if (data.getOfferDetails) {
                    for (let i = 0; i < services.length; i++) {
                        if (subscriptionList[itr].id === services[i].subscriptionId) {
                            arrTemp = arrTemp.concat(data.getOfferDetails);
                        }
                    }
                } else {
                    throw 'getOfferDetails was not returned from API';
                }
            }
        } catch (error) {
            console.log('Error while fetching offer details');
        }

        setCatalogOffer(arrTemp);
    }

    useEffect(() => {
        getOffer();
    }, []);

    async function getMsisdnAndOffer() {
        try {
            if (
                sessionStorage.getItem('LoggedinServices') == undefined ||
                sessionStorage.getItem('LoggedinServices') == ''
            ) {
                const serviceResponse = await getServiceId(graphql);
            }

            let tempArr = [];
            let totalServices = 0;
            for (
                let itr = 0;
                itr <
                JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')).length;
                itr++
            ) {
                try {
                    const response = await graphql.query({
                        query: ENDPOINT.GET_MY_DETAILS,
                        variables: {
                            token: sessionStorage.getItem('access_token'),
                            serviceId: String(
                                JSON.parse(sessionStorage.getItem('LoggedinServices'))[itr].id
                            ),
                        },
                    });
                    const data = response.data;
                    if (data.getMyDetails) {
                        tempArr = tempArr.concat(data.getMyDetails);
                    } else {
                        throw 'getMyDetails was not returned from API';
                    }
                } catch (error) {
                    totalServices += 1;
                    setMsisdnLoadingError(true);
                    console.log(
                        'error while fetching getMyDetails',
                        JSON.parse(sessionStorage.getItem('LoggedinServices'))[itr].id
                    );
                }
            }

            // setMsisdnAndOffer(tempArr);
            // setLoading(false);
            // setPinPukLoadingError(false);

            if (
                totalServices ==
                JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')).length
            ) {
                setMsisdnLoadingError(true);
            } else {
                setMsisdnAndOffer(tempArr);
                setLoadingContracts(false);
                setMsisdnLoadingError(false);
            }
        } catch (error) {
            setMsisdnLoadingError(true);
            console.error(
                'Error while fetching MSISDN and offer of contracts (getMyDetails) :',
                error.message
            );
        }
    }

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleChangeSecondary = panel => (event, isExpanded) => {
        setExpandedSecondary(isExpanded ? panel : false);
        // window.scrollTo(0, isExpanded.offsetTop-100);
    };

    const getData = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            if (token) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_SUBSCRIPTION_ID_AND_CONTACT,
                    variables: {
                        token: token,
                    },
                });
                const data = await response.data;
                const mySubscriptions = data.getMySubscriptions;
                const myContactDetails = data.getMyContactDetails;
                sessionStorage.setItem('contactUUID', myContactDetails.uuid);
                setContactDetails(myContactDetails);
                setLoading(false);
            } else {
                console.log('Unable to fetch Contact Details : Invalid Token');
                setLoading(false);
                setRetry('api-error');
            }
        } catch (err) {
            setRetry('api-error');
            console.error('Error in fetching Contact Details : ' + err.message);
        }
    };

    useEffect(() => {
        setLoading(true);
        getData();
        subscribe('updateAddress', getData);
        subscribe('updateContactNumber', getData);
    }, []);

    useEffect(() => {
        getMsisdnAndOffer();
    }, []);

    useEffect(() => {
        const getAuthUser = async () => {
            try {
                const token = sessionStorage.getItem('access_token');
                let accountId = sessionStorage.getItem('LoggedinUserAccountID');
                if (!accountId) {
                    await getNameAndAccountID(graphql);
                    accountId = sessionStorage.getItem('LoggedinUserAccountID');
                }
                if (token && accountId) {
                    const response = await graphql.query({
                        query: ENDPOINT.GET_AUTHORISED_USER,
                        variables: {
                            token: token,
                            accountId: accountId,
                        },
                    });
                    setAuthUser(response.data.getMyAuthorizedUsers);
                }
            } catch (err) {
                console.error('Error in fetching authorized users : ', err.message);
            }
        };
        getAuthUser();
    }, []);

    useEffect(() => {
        const getPaymentDetailsData = async () => {
            try {
                const response = await graphql.query({
                    query: ENDPOINT.GET_MY_PAYMENT_METHOD_DETAILS,
                    variables: {
                        token: sessionStorage.getItem('access_token'),
                        account_id: sessionStorage.getItem('LoggedinUserAccountID'),
                    },
                });
                const data = await response.data;
                // window.data = data;
                setPayDetails(data.getPaymentMethod.paymentMethods);
            } catch (err) {
                console.error('err in payment:', err);
            }
        };
        getPaymentDetailsData();

        subscribe('UPDATE_CARD', (msg, data) => {
            console.log('inside pubsub-subscriber');
            getPaymentDetailsData();
        });
    }, []);

    useEffect(() => {
        if (width < 1020) {
            setExpanded(1);
        }
        if (state.currentSlideIndex != 0) {
            setExpandedSecondary(false);

            // this is temporary change only for contact details.
            // ** Must remove once all APIs are ready for Account Pannel.
            setExpanded(false);
        } else {
            // this is temporary change only for contact details.
            // ** Must remove once all APIs are ready for Account Pannel.
            setExpanded(1);
            setExpandedSecondary('details');
        }
        if (width > 600) {
            setExpandedSecondary('details');
        }
        expandOnNavClick && setExpanded(1);
    }, [state.currentSlideIndex, expandOnNavClick]);

    async function getAllContracts() {
        try {
            if (
                sessionStorage.getItem('LoggedinServices') == undefined ||
                sessionStorage.getItem('LoggedinServices') == ''
            ) {
                // const serviceResponse = await getServiceId(graphql);
                const subscriptionResponse = await getNameAndAccountID(graphql);
            }

            let tempArr = [];
            let totalServices = 0;
            const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
            const subscriptionList = JSON.parse(
                sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
            );
            for (let itr = 0; itr < subscriptionList.length; itr++) {
                try {
                    const response = await graphql.query({
                        query: ENDPOINT.GET_MY_CONTRACTS,
                        variables: {
                            token: sessionStorage.getItem('access_token'),
                            subscriptionId: parseInt(subscriptionList[itr].id),
                        },
                    });

                    const data = response.data;
                    if (data.getMyServiceContract) {
                        for (let i = 0; i < services.length; i++) {
                            if (subscriptionList[itr].id === services[i].subscriptionId) {
                                tempArr = tempArr.concat({
                                    ...data.getMyServiceContract,
                                    subscriptionId: services[i].subscriptionId,
                                });
                            }
                        }
                    } else {
                        totalServices += 1;
                        throw 'getMyDetails was not returned from API';
                    }
                } catch (error) {
                    console.error('Error while fetching contracts :', error.message);
                }
            }
            setContracts(tempArr);

            if (
                totalServices ==
                JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')).length
            ) {
                console.log('total services are equal to LoggedinUserSubscriptionIDandAccountID ');
            } else {
                setContracts(tempArr);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAllContracts();
    }, []);

    return (
        <div className={classes.MyAccountWrapper} id='my-eir-account'>
            <div className='accountContainer'>
                <Accordion
                    className='account-accordion'
                    classes={{ expanded: 'account-accordion-expanded' }}
                    expanded={width > 1019 ? expanded === 1 : true}
                    onChange={width > 1019 && handleChange(1)}
                >
                    <AccordionSummary
                        className='account-header'
                        // onClick={width>1019 && toggle}
                        classes={{
                            content: 'account__header-content',
                            expanded: 'account__header-expanded',
                            expandIcon: 'account__header-expandIcon',
                        }}
                        expandIcon={<ExpandMoreIcon className={'account-expand-icon'} />}
                        // aria-controls="panel1a-content"
                        id='panel1a-header'
                    >
                        <div className='account-header-block'>
                            <div className='account-header-block-img-div'>
                                <img
                                    src={myAccountLogo}
                                    alt='account icon'
                                    className='account-header-block-img'
                                />
                            </div>
                            My account
                        </div>
                    </AccordionSummary>
                    {loading ? (
                        retry == 'api-error' ? (
                            <div class='api-error'>
                                Something went wrong, please try again later.
                                <span>Retry?</span>
                            </div>
                        ) : (
                            <Loading />
                        )
                    ) : (
                        contactDetails !== null &&
                        authUser !== null &&
                        payDetails !== null && (
                            <>
                                <AccordionDetails className='account-number'>
                                    <div className='account-number-normal'>
                                        Account number:{' '}
                                        <span className='account-number-bold'>
                                            {sessionStorage.getItem('LoggedinUserAccountNumber')}
                                        </span>
                                    </div>
                                    {/* <div className='account-number-normal'>
                            Contract:{' '}
                            <span className='account-number-medium'>02 Jun 2021 - 02 Jun 2023</span>
                        </div> */}
                                </AccordionDetails>
                                <AccordionDetails className='account-details'>
                                    <Accordion
                                        className='account-secondary-accordion'
                                        expanded={expandedSecondary === 'details'}
                                        onChange={handleChangeSecondary('details')}
                                    >
                                        <AccordionSummary
                                            className='account-secondary-header'
                                            classes={{
                                                content: 'account-secondary__header-content',
                                                expanded: 'account-secondary__header-expanded',
                                                expandIcon: 'account-secondary__header-expandIcon',
                                            }}
                                            expandIcon={
                                                <ExpandMoreIcon
                                                    className={'account-expand-icon-secondary'}
                                                />
                                                // <Hidden xsUp>
                                                //     <ExpandMoreIcon
                                                //         className={'account-expand-icon-secondary'}
                                                //     />
                                                // </Hidden>
                                            }
                                            //  onClick={toggleSecondary}
                                        >
                                            Details
                                        </AccordionSummary>
                                        {/* <AccordionDetails className="account-details-content">Account Details</AccordionDetails> */}
                                        <MyEirAccountDetails
                                            width={width}
                                            contactDetails={contactDetails}
                                            authUser={authUser}
                                            payDetails={payDetails}
                                            catalogOffer={catalogOffer}
                                        />
                                    </Accordion>
                                </AccordionDetails>
                                <AccordionDetails className='account-contracts'>
                                    <Accordion
                                        className='account-secondary-accordion'
                                        expanded={expandedSecondary === 'contracts'}
                                        onChange={handleChangeSecondary('contracts')}
                                    >
                                        <AccordionSummary
                                            className='account-secondary-header'
                                            classes={{
                                                content: 'account-secondary__header-content',
                                                expanded: 'account-secondary__header-expanded',
                                                expandIcon: 'account-secondary__header-expandIcon',
                                            }}
                                            expandIcon={
                                                <ExpandMoreIcon
                                                    className={'account-expand-icon-secondary'}
                                                />
                                            }
                                            //  onClick={toggleSecondary}
                                        >
                                            Contracts
                                        </AccordionSummary>
                                        {/* <AccordionDetails className='account-contracts-content'>
                                                Account Contracts
                                            </AccordionDetails> */}

                                        <MyEirAccountContracts
                                            width={width}
                                            msisdnAndOffer={msisdnAndOffer}
                                            contracts={contracts}
                                            loadingContracts={loadingContracts}
                                            msisdnLoadingError={msisdnLoadingError}
                                            catalogOffer={catalogOffer}
                                        />
                                    </Accordion>
                                </AccordionDetails>
                                <AccordionDetails className='account-preferences'>
                                    <Accordion
                                        className='account-secondary-accordion'
                                        expanded={expandedSecondary === 'preferences'}
                                        onChange={handleChangeSecondary('preferences')}
                                    >
                                        <AccordionSummary
                                            className='account-secondary-header'
                                            classes={{
                                                content: 'account-secondary__header-content',
                                                expanded: 'account-secondary__header-expanded',
                                                expandIcon: 'account-secondary__header-expandIcon',
                                            }}
                                            expandIcon={
                                                <ExpandMoreIcon
                                                    className={'account-expand-icon-secondary'}
                                                />
                                            }
                                        >
                                            Preferences
                                        </AccordionSummary>
                                        {/* <AccordionDetails className='account-preferences-content'>
                            Account Preferences
                        </AccordionDetails> */}
                                        <MyEirAccountPreferences
                                            width={width}
                                            contactDetails={contactDetails}
                                            authUser={authUser}
                                            payDetails={payDetails}
                                        />
                                    </Accordion>
                                </AccordionDetails>
                                <AccordionDetails className='account-simreplacement'>
                                    <Accordion
                                        className='account-secondary-accordion'
                                        expanded={expandedSecondary === 'simreplacement'}
                                        onChange={handleChangeSecondary('simreplacement')}
                                    >
                                        <AccordionSummary
                                            className='account-secondary-header'
                                            classes={{
                                                content: 'account-secondary__header-content',
                                                expanded: 'account-secondary__header-expanded',
                                                expandIcon: 'account-secondary__header-expandIcon',
                                            }}
                                            expandIcon={
                                                <ExpandMoreIcon
                                                    className={'account-expand-icon-secondary'}
                                                />
                                            }
                                        >
                                            SIM replacement
                                        </AccordionSummary>
                                        <MyEirAccountSimreplacement
                                            ContactDetails={contactDetails}
                                        />
                                    </Accordion>
                                </AccordionDetails>
                            </>
                        )
                    )}
                </Accordion>
            </div>
        </div>
    );
}

export default MyEirAccountContainer;
