import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { styles } from './styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';

const DeviceNotification = () => {
    const [displayNotification, setDisplayNotification] = useState(false);

    const classes = styles();

    const handleNotificationClose = () => {
        setDisplayNotification(!displayNotification);
    };

    return (
        <>
            {displayNotification && (
                <Box className={classes.deviceManagementContentNotification}>
                    <InfoOutlinedIcon className={classes.deviceManagementContentNotificationIcon} />
                    <Typography
                        variant='body1'
                        className={classes.deviceManagementContentNotificationText}
                    >
                        Your device ‘eir_ios’ was successfully activated on your account on 10/11/20
                    </Typography>
                    <Box className={classes.deviceManagementContentNotificationCloseBox}>
                        <CloseIcon
                            onClick={handleNotificationClose}
                            className={classes.deviceManagementContentNotificationClose}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default DeviceNotification;
