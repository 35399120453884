import { Box, Grid, Typography } from '@material-ui/core';
import { publish } from 'pubsub-js';
import React, { useEffect, useState } from 'react';
import CustomTextField from '../../../sharedcomponents/inputs/text-field/CustomTextField';
import useStyle from './style';

const ValidateNumber = ({
    portingStep,
    portIn,
    setEnable,
    // updateFooterButtons,
    // handleCTA,
    getOtp,
    setErrorMessage,
    errorMessage,
    // validateOtp,
    // phoneNumber = undefined,
}) => {
    const [isMsisdnInvalid, setIsMsisdnInvalid] = useState(false);
    const [invalidOtp, setInvalidOtp] = useState('');
    // const [errorMessage, setErrorMessage] = useState('');
    // const [msisdn, setMsisdn] = useState(portIn.phoneNumber);

    const regExp = {
        msisdn: /^08[0-9]{8}$/,
    };

    // send Otp
    const handleMsisdn = e => {
        e.persist();
        publish('RESET_API_ERROR');
        setEnable({ step1: false });
        if (e.target.value === '') {
            setIsMsisdnInvalid('Please enter your phone number');
            // setIsMsisdnInvalid(false);
        } else if (regExp.msisdn.test(e.target.value)) {
            portIn.setData({ ...portIn.value, phoneNumber: e.target.value });
            setIsMsisdnInvalid(false);
            setEnable({ step1: true });
        } else {
            setIsMsisdnInvalid('Must start with 08 and be 10 digits long');
        }
    };
    const handleOtp = () => {
        getOtp(portIn?.value?.phoneNumber);
    };

    // validate Otp
    const handleValidateOtp = e => {
        publish('RESET_API_ERROR');
        const otpValue = e.target.value;
        setEnable({ step2: false });
        // when input is empty
        if (otpValue === '') {
            setInvalidOtp('Please enter verification code');
        }
        // when input is less or greater than 6
        else if (otpValue.length > 6 || otpValue.length < 6) {
            setInvalidOtp('Must be 6 characters long');
        }
        // when input is valid
        else {
            setInvalidOtp('');
            portIn.setData({ ...portIn.value, otp: otpValue });
            setEnable({ step2: true });
            // setErrorMessage('');
        }
    };

    useEffect(() => {
        if (errorMessage) {
            setInvalidOtp(errorMessage);
        } else {
            setInvalidOtp('');
        }
    }, [errorMessage]);

    const classes = useStyle({});

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box id='porting-number' style={{ padding: '0 1.875rem 0 1rem' }}>
                    <CustomTextField
                        id='porting-number-input'
                        label='Phone number'
                        onChange={handleMsisdn}
                        required
                        className={`msisdn-input`}
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        defaultValue={portIn?.value?.phoneNumber}
                        // style={styles.textField}
                        error={Boolean(isMsisdnInvalid)}
                        errorMessage={isMsisdnInvalid}
                    />
                </Box>
            </Grid>
            {portingStep === 1 && (
                <Grid item xs={12}>
                    <Box id='porting-validation' style={{ padding: '0 1.875rem 0 1rem' }}>
                        <CustomTextField
                            id='porting-validation-input'
                            label='Verification code'
                            //helperText='Check you phone for your code'
                            onChange={handleValidateOtp}
                            required
                            error={Boolean(invalidOtp)}
                            errorMessage={invalidOtp}
                            className={`otp-input`}
                            classes={{
                                container: ` ${classes.textField} ${classes.textFieldOtp}`,
                            }}
                        />
                        <Typography className={`porting-resend-code ${classes.didntGetCode}`}>
                            Didn't get your code?
                            <Typography
                                component='span'
                                onClick={handleOtp}
                                className={classes.resendText}
                            >
                                Resend
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default ValidateNumber;
