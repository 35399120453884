import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    scrollbar: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        minHeight: '700px',
        [theme.breakpoints.only('xs')]: {
            minHeight: 'unset',
            height: '95% !important',
        },
    },

    delivery: {
        textAlign: 'center',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        paddingBottom: '14px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: 700,
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,
    },
    marginBlock: {
        marginTop: '21px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
        },
    },

    eircodeContainer: {
        '& .MuiInputLabel-outlined.Mui-error': {
            color: theme.palette.primary.error,
        },
        width: '464px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '32px',
        marginBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            '& .MuiGrid-item': {
                padding: '8px',
            },
            width: '100%',
            marginTop: '28px',
        },
    },
    addresslineOne: {
        '& .MuiInputLabel-root': {
            marginTop: '5px',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            marginTop: '0',
        },
    },

    addressContainer: {
        width: '440px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    hideTextField: {
        opacity: '0',
        height: '0',
        zIndex: '-1',
        paddingTop: '0 !important',
    },

    ctaGrid: {
        [theme.breakpoints.down('sm')]: {
            order: 3,
        },
    },

    cta: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        minWidth: '208px',
        margin: '0 auto ',
        background: theme.palette.primary.sunset,
        borderRadius: '4100px',
        color: theme.palette.primary.white,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
        [theme.breakpoints.down('sm')]: {
            // width: '264px',
            width: '100%',
            height: '41px',
            // top: '214px',
            left: '.69%',
            right: '7.69%',
        },
    },

    cta1: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        background: theme.palette.primary.sunset,
        width: '100%',
        maxWidth: '200px',
        borderRadius: '4100px',
        color: theme.palette.primary.white,
        '&:hover': {
            background: '#B10071',
        },
        [theme.breakpoints.down('md', 'sm')]: {
            maxWidth: '200px',
            width: '100%',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '296px',
            width: '100%',
            height: '41px',
            left: '.69%',
            right: '7.69%',
        },
    },

    ctaDisable1: {
        '&.Mui-disabled': {
            color: theme.palette.primary.gray550,
            borderRadius: '4100px',
            background: '#ececec !important',
            textTransform: 'none',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '1rem',
            fontFamily: theme.typography.body1.fontFamily,
            lineHeight: '1.5',
            padding: '.5rem 2rem',
            maxWidth: '200px',
            width: '100%',
            margin: '0 auto ',
            marginTop: '10px',
            [theme.breakpoints.down('md', 'sm')]: {
                maxWidth: '200px',
                width: '100%',
            },
            [theme.breakpoints.only('xs')]: {
                maxWidth: '296px',
                width: '100%',
                height: '41px',
                left: '.69%',
                right: '7.69%',
            },
        },
    },

    textContainer: {
        lineHeight: '157%',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'center',
        margin: '0 auto ',
        color: theme.palette.primary.heather,
        paddingTop: '28px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '296px',
            paddingTop: '1rem',
        },
    },

    activateSim: {
        fontSize: '.875rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        color: theme.palette.primary.sunset,
    },
    textContext: {
        color: theme.palette.primary.sunset,
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
    },

    simLink: {
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '400',
        color: theme.palette.primary.sunset,
    },

    textFieldotp: {
        margin: '2.5rem 0px 1.5rem 0px',
        padding: '0px 13.36rem 0rem',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            margin: '2rem 0 1rem',
            padding: '0px 0.47rem 0rem',
            maxWidth: '296px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },

    ctaDisable: {
        '&.Mui-disabled': {
            // boxShadow:
            //     '0 1px 5px 0 rgba( 208, 208, 208, 1), 0 2px 2px 0 rgba( 208, 208, 208, 1), 0 3px 1px 0 rgba( 208, 208, 208, 1)',
            color: theme.palette.primary.gray550,
            borderRadius: '4100px',
            background: '#ececec !important',
            textTransform: 'none',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '1rem',
            fontFamily: theme.typography.body1.fontFamily,
            lineHeight: '1.5',
            padding: '.5rem 2rem',
            minWidth: '208px',
            margin: '0 auto ',
            [theme.breakpoints.down('sm')]: {
                // width: '264px',
                width: '100%',
                height: '41px',
                // top: '214px',
                left: '.69%',
                right: '7.69%',
            },
        },
    },

    textField: {
        width: '100%',
        height: '56px',
        margin: '0',
        '& .MuiInputBase-root': { height: '56px' },
        // colors on focus
        '& .MuiInputLabel-root': { color: theme.palette.primary.heather },
        '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.heather80,
                borderWidth: 2,
            },
            '&.MuiInputLabel-root': {
                color: theme.palette.primary.heather80,
            },
        },
        '& .Mui-error': {
            color: theme.palette.primary.error,
        },

        // helper text
        '& .MuiFormHelperText-contained': {
            fontFamily: theme.typography.body2.fontFamily,
            color: theme.palette.primary.heather,
            margin: 0,
            fontSize: 14,
            lineHeight: '20px',
        },

        '& .MuiInputLabel-outlined.Mui-error': {
            color: theme.palette.primary.error,
        },
    },

    simImg: {
        position: 'relative',
        marginTop: '29px',
        marginBottom: '3.5px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '14.5px',
        },
    },

    textBold: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,
    },

    text: {
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '600',
        color: theme.palette.primary.heather,
        marginTop: '3.5px',
    },

    link: {
        fontSize: theme.typography.inputLabel.fontSize,
        cursor: 'pointer',
        lineHeight: '20px',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        color: theme.palette.primary.sunset,
        marginTop: '-7px',
        letterSpacing: '0.4px',
        textDecoration: 'underline',
        width: '437px',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            width: '95%',
            marginBottom: '12px',
            order: 2,
        },
        [theme.breakpoints.only('sm')]: {
            width: '95%',
            marginBottom: '14px',
            order: 2,
            textAlign: 'center',
        },
    },

    cost: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '700',
        fontSize: '48px',
        lineHeight: '32px',
        color: theme.palette.primary.heather,
        marginTop: '23px',
        marginBottom: '28px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            marginTop: '7px',
            marginBottom: ' 18px',
        },
    },
    errorMessage: {
        width: '100%',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        letterSpacing: '0.4px',
        color: theme.palette.primary.error,
        marginTop: '3px',
    },
    blockText: {
        width: '440px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '23px',
        [theme.breakpoints.down('sm')]: {
            width: '92%',
            marginTop: '12px',
        },
    },
    serviceText: {
        fontSize: theme.typography.inputLabel.fontSize,
    },

    checkBlock: {
        width: '254px',
        margin: '0 auto',
        display: 'flex',
        textAlign: 'left',
        marginTop: '21px',
        height: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '21px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '14px',
            marginBottom: '14px',
        },
    },
    checkbox: {
        '& .MuiIconButton-label': {
            width: '16px',
            height: '16px',
        },
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette.primary.heather,
        },
    },
    checkText: {
        textDecoration: 'none',
        fontSize: theme.typography.inputLabel.fontSize,
        // fontWeight: 700,
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.subtitle1.fontFamily,
    },

    successPopup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    successImg: {
        marginTop: '27px',
        marginBottom: '43px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '19px',
            marginTop: '67px',
        },
    },

    imgTextAdditional: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '600',
        color: theme.palette.primary.heather,
        marginBottom: '48px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginRight: '30px',
            marginLeft: '30px',
            marginBottom: '45px',
        },
    },
    err: {
        textAlign: 'center',
        fontFamily: theme.typography.body1.fontFamily,
        marginBottom: '20px',
    },

    linkStyle: {
        width: 'max-content',
    },

    linkStyles: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.subtitle2.fontSize,
    },

    inactiveContainer: {
        marginTop: '26px',
        marginBottom: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            margin: '0 auto',
            marginTop: '21px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            marginBottom: '50px',
        },
    },

    inactiveText: {
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        color: theme.palette.primary.heather,
        marginBottom: '16px',
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    simCta: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        minWidth: '184px',
        [theme.breakpoints.down('sm')]: {
            height: '41px',
            left: '.69%',
            right: '7.69%',
            borderRadius: '4100px',
            maxWidth: '296px',
            width: '100%',
        },
    },
    simCta1: {
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        color: theme.palette.primary.white,
        borderRadius: '4rem',
        background: theme.palette.primary.sunset,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
    },
    mb: {
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
        },
    },
    MB: {
        marginBottom: '18px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
        },
    },
    simCta1Disable: {
        // boxShadow:
        //     '0 1px 5px 0 rgba( 208, 208, 208, 1), 0 2px 2px 0 rgba( 208, 208, 208, 1), 0 3px 1px 0 rgba( 208, 208, 208, 1)',
        color: theme.palette.primary.white,
        borderRadius: '4rem',
        background: theme.palette.primary.gray550,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.primary.white,
            borderRadius: '4rem',
            background: `${theme.palette.primary.gray550} !important`,
        },
    },
    simCta2: {
        color: theme.palette.primary.sunset,
        textAlign: 'center',
        fontSize: theme.typography.body1.fontSize,
        // marginTop: '12px',
    },
}));

export default useStyles;
