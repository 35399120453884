const GET_DOMAIN = window.location.href;
const LOCAL_ENV = {
    url: {
        REDIRECT_URL: 'http://localhost:8099/',
        ACQ_URL: 'http://localhost:3002/',
        KEYCLOAK_CLIENT: 'eir-myaccount-frontend',
        KEYCLOAK_TOKEN_URL:
            'https://keycloak.e2e.ion.comhar.local/auth/realms/customer/protocol/openid-connect/token',
        REALEX_HPP_URL: 'https://pay.sandbox.elavonpaymentgateway.com/pay',
    },
};
const DEV_ENV = {
    url: {
        REDIRECT_URL: 'http://myeir-dev.eir.ie/',
        ACQ_URL: 'https://testwww04.eir.ie/',
        KEYCLOAK_CLIENT: 'eir-myaccount-frontend',
        KEYCLOAK_TOKEN_URL:
            'https://keycloak.e2e.ion.comhar.local/auth/realms/customer/protocol/openid-connect/token',
        REALEX_HPP_URL: 'https://pay.sandbox.elavonpaymentgateway.com/pay',
    },
};
const TEST_ENV = {
    url: {
        REDIRECT_URL: 'http://myeir-test02.eir.ie/',
        ACQ_URL: 'https://testwww03.eir.ie/',
        KEYCLOAK_CLIENT: 'eir-myaccount-frontend',
        KEYCLOAK_TOKEN_URL:
            'https://keycloak.e2e.ion.comhar.local/auth/realms/customer/protocol/openid-connect/token',
        REALEX_HPP_URL: 'https://pay.sandbox.elavonpaymentgateway.com/pay',
    },
};
const PRE_PROD = {
    url: {
        REDIRECT_URL: 'http://myeir-preprod.eir.ie/',
        ACQ_URL: 'https://testwww03.eir.ie/',
        KEYCLOAK_CLIENT: 'eir-myaccount-frontend',
        KEYCLOAK_TOKEN_URL:
            'https://keycloak.e2e.ion.comhar.local/auth/realms/customer/protocol/openid-connect/token',
        REALEX_HPP_URL: 'https://pay.sandbox.elavonpaymentgateway.com/pay',
    },
};
const PROD_ENV = {
    url: {
        REDIRECT_URL: 'https://my.eir.ie/',
        ACQ_URL: 'https://eir.ie/',
        KEYCLOAK_CLIENT: 'eir-myaccount-frontend',
        KEYCLOAK_TOKEN_URL:
            'https://keycloak.e2e.ion.comhar.local/auth/realms/customer/protocol/openid-connect/token',
        REALEX_HPP_URL: 'https://pay.elavonpaymentgateway.com/pay',
    },
};

// export const MY_EIR_CONST =
//     GET_DOMAIN.indexOf('mytt') > 0 || GET_DOMAIN.indexOf('myeir-test') > 0
//         ? TEST_ENV // if test env
//         : GET_DOMAIN.indexOf('localhost') > 0
//         ? LOCAL_ENV // change DEV_ENV for development env or PROD_ENV for production
//         : GET_DOMAIN.indexOf('myeir-dev') > 0
//         ? DEV_ENV
//         : PROD_ENV; // if prod env

export const ACQUISITION = {
    url: {
        REDIRECT_URL: 'https://eir.ie',
    },
};

/* My eir to eir.ie */
export const MY_EIR_CONST = (() => {
    switch (true) {
        case GET_DOMAIN.indexOf('myeir-preprod') > 0:
            return PRE_PROD;
        case GET_DOMAIN.indexOf('mytt') > 0 || GET_DOMAIN.indexOf('myeir-test') > 0:
            return TEST_ENV;
        case GET_DOMAIN.indexOf('localhost') > 0:
            return LOCAL_ENV;
        case GET_DOMAIN.indexOf('myeir-dev') > 0:
            return DEV_ENV;
        default:
            return PROD_ENV;
    }
})();

export const DOMAINS = {
    VOIP: 'VOIP',
    FIBER: 'FIBER',
    DSL: 'DSL',
    MOBILE: 'MOBILE',
    IPTV: 'IPTV',
};
export const SERVICE_GROUPS = {
    MOBILE: 'MOBILE',
    MBB: 'MBB',
    TV: 'TV',
    DUALPLAY: 'DUALPLAY',
    SA_PSTN: 'SA_PSTN',
    DUALPLAY_PSTN: 'DUALPLAY_PSTN',
};
export const ACCOUNT_STATUS = {
    BILLABLE: 'BILLABLE',
    INITIAL: 'INITIAL',
    BILLABLE_EMPTY: 'BILLABLE_EMPTY',
    UNBILLABLE: 'UNBILLABLE',
};
export const ADDON_STATUS = {
    ONGOING_TERMINATION: 'ONGOING_TERMINATION',
    NO_ONGOING_REQUEST: 'NO_ONGOING_REQUEST',
    ONGOING_ADDITION: 'ONGOING_ADDITION',
};

export const CHANNEL = 'MYEIR';

export const RECAPTCHA_KEY_V3 = '6Le2qgUqAAAAABvwuaR9zreIQJqB9R6_TIozGGHQ';
