import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { styles } from './styles';

const DeviceInfoText = ({ headerText, editDevice, isErrorText }) => {
    const classes = styles();
    const startOfNextMonth = moment().add(1, 'M').startOf('month').format('DD/MM/YYYY');

    return (
        <Box className={classes.deviceManagementContentHeader}>
            {isErrorText ? (
                <Typography className={classes.deviceManagementDeleteHeaderText}>
                    {headerText}
                </Typography>
            ) : (
                <Typography varaint='h5' className={classes.deviceManagementContentHeaderText}>
                    {headerText}
                </Typography>
            )}

            <Typography variant='body2' className={classes.deviceManagementContentHeaderSubText}>
                You can have two devices registered to your account at once.
            </Typography>
            {editDevice === false ? (
                <Typography
                    variant='body2'
                    className={classes.deviceManagementContentHeaderSubText}
                >
                    You will be able to change an existing device from {startOfNextMonth}.
                </Typography>
            ) : (
                <Typography
                    variant='body2'
                    className={classes.deviceManagementContentHeaderSubText}
                >
                    You have one remaining change this month.
                </Typography>
            )}
        </Box>
    );
};

export default DeviceInfoText;
