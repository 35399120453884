import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import ViewAuthorisedUserDetails from './ViewAuthorisedUserDetails';
import { authorisedUserStyle } from './authorisedUserStyle';
import addIcon from '../../images/addUserIcon.png';

const MyEirViewAuthorisedUsers = ({ authUsers, setStep, setSelectedUser }) => {
    const classes = authorisedUserStyle();

    const handleAddUser = () => {
        setStep('add');
    };
    return (
        <Box>
            <Box className={classes.userContent}></Box>

            {authUsers.map((user, index) => (
                <Box>
                    <ViewAuthorisedUserDetails
                        setStep={setStep}
                        key={index}
                        index={index}
                        user={user}
                        setSelectedUser={setSelectedUser}
                    />
                </Box>
            ))}
            <Box>
                <Button className={classes.addUserContent} onClick={handleAddUser}>
                    Add users
                    <Box component='img' src={addIcon} alt='add-Icon' className={classes.addIcon} />
                </Button>
            </Box>
        </Box>
    );
};

export default MyEirViewAuthorisedUsers;
