import { makeStyles } from '@material-ui/core/styles';
export const useEmailChangeStyle = makeStyles(theme => ({
    ChangeEmailWrapper: {
        background: 'white',

        '& .changeEmail-modal': {
            // background: "rgba(51, 51, 51, 0.5)",
            // backdropFilter: "blur(4.5px)",

            display: 'flex !important',

            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },

            '@media(max-width: 599px)': {
                maxWidth: '100vw',
            },
        },

        '& .emailSent-modal': {
            // background: "rgba(51, 51, 51, 0.5)",
            // backdropFilter: "blur(4.5px)",

            display: 'flex !important',

            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },

            '@media(max-width: 599px)': {
                maxWidth: '100vw',
            },
        },

        '& .changeEmail-main': {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '219px',

            padding: '0 30px',

            maxWidth: '754px',
            width: 'calc(min(100% , 754px))',
            margin: 'auto !important',

            '@media(max-width: 1019px)': {
                padding: '0 99px',
                maxWidth: '768px',
                width: 'calc(min(100% , 768px))',
            },
            '@media(max-width: 599px)': {
                marginTop: '8px !important',
                marginBottom: '0px !important',
                // marginLeft: "0px",
                // marginRight: "0px",
                padding: '0 8px',
            },
        },

        '& .emailSent-main': {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '231px',

            padding: '0 30px',

            maxWidth: '820px',
            width: 'calc(min(100% , 820px))',
            margin: 'auto !important',

            '@media(max-width: 1019px)': {
                padding: '0 99px',
                maxWidth: '768px',
                width: 'calc(min(100% , 768px))',
            },
            '@media(max-width: 599px)': {
                marginTop: '8px !important',
                marginBottom: '0px !important',
                // marginLeft: "0px",
                // marginRight: "0px",
                padding: '0 8px',
            },
        },

        '& .changeEmail-content': {
            // padding: "0 30px",
            border: 'none',
            borderRadius: '10px',
            // wordWrap: "break-word",

            '@media(max-width: 599px)': {
                minHeight: 'calc(max(100% , 550px) - 8px)',
                borderRadius: '10px 10px 0px 0px',
                wordWrap: 'break-word',
            },
        },

        '& .emailSent-content': {
            // padding: "0 30px",
            border: 'none',
            borderRadius: '10px',
            // wordWrap: "break-word",

            '@media(max-width: 599px)': {
                minHeight: 'calc(max(100vh , 490px) - 8px)',
                borderRadius: '10px 10px 0px 0px',
                wordWrap: 'break-word',
            },
        },

        '& .changeEmail-body': {
            background: theme.palette.primary.white,
            minHeight: 'auto',
            padding: '24px 32px',
            borderRadius: '10px',

            // boxShadow: "0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}",

            '@media(max-width: 599px)': {
                padding: '16px 16px 16px 16px',
                minHeight: '542px',
                borderRadius: '10px 10px 0px 0px',

                display: 'flex',
                flexDirection: 'column',
            },

            '& .changeEmail-body-header': {
                borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                // paddingTop: "1.42px",

                '@media(max-width: 599px)': {
                    paddingTop: '1.42px',

                    flexGrow: '0',
                },

                '& .changeEmail-header-back-icon': {
                    display: 'flex',
                    marginLeft: '-4px',
                    color: theme.palette.primary.heather,
                    cursor: 'pointer',

                    '@media(max-width: 599px)': {
                        height: '20px',
                        width: '20px',
                        marginLeft: '-4px',
                    },
                },

                '& .changeEmail-header-text': {
                    display: 'flex',
                    marginTop: '17px',
                    minHeight: '45px',
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,,
                    fontSize: theme.typography.h4.fontSize,
                    lineHeight: '26px',
                    color: theme.palette.primary.heather,

                    position: 'relative',

                    '@media(max-width: 599px)': {
                        paddingLeft: '8px',
                        marginTop: '13.58px',
                        minHeight: '44px',
                        fontSize: theme.typography.h4.fontSize,
                    },

                    '& .changeEmail-header-line': {
                        position: 'absolute',
                        left: '0%',
                        right: '0%',
                        bottom: '3px',
                        border: `1px solid ${theme.palette.primary.borderColor}`,
                        borderTopWidth: '0px',

                        '@media(max-width: 599px)': {
                            bottom: '0px',
                        },
                    },
                },

                '& .hide': {
                    visibility: 'hidden',
                },
            },

            '& .changeEmail-body-content': {
                maxWidth: '376px',
                minHeight: '300px',
                margin: '48px auto 0px',

                '@media(max-width: 599px)': {
                    margin: '50px auto 20px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    flexGrow: '0',
                    width: 'calc(min(100% , 376px))',
                    minHeight: 'unset',
                },

                '& .currentEmail': {
                    '& .currentEmail-title': {
                        fontFamily: theme.typography.subtitle1.fontFamily,
                        // fontWeight: 700,
                        fontStyle: 'normal',
                        fontSize: theme.typography.body3Medium.fontSize,
                        lineHeight: '24px',
                        color: theme.palette.primary.heather,

                        marginBottom: '2px',

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.body1.fontSize,
                        },
                    },

                    '& .currentEmail-content': {
                        fontFamily: theme.typography.body2.fontFamily, //made it regular instead of medium because text/i's were looking weird
                        fontWeight: 600,
                        fontStyle: 'normal',
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '25px',
                        color: theme.palette.primary.heather,
                    },
                },

                // '& .newEmail, & .confirmEmail': {
                //     // minHeight: "79px",
                //     // marginTop: "35px",
                //     // marginBottom: "41px",
                // },

                '& .newEmail': {
                    marginTop: '35px',

                    '@media(max-width: 599px)': {
                        marginTop: '50px',
                    },
                },

                '& .confirmEmail': {
                    marginTop: '41px',

                    '@media(max-width: 599px)': {
                        marginTop: '27px',
                    },
                },

                ' & .email-input-text-field': {
                    width: '100%',

                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,

                    ' & .text-field-input-label-props-root': {
                        color: theme.palette.primary.heather80,
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '19px',
                        letterSpacing: '0.15px',
                    },
                    '& .text-field-input-label-props-focused, & .text-field-input-label-props-shrink': {
                        color: theme.palette.primary.heather,
                        // fontSize: "12px",
                        lineHeight: '16px',
                    },
                    '& .text-field-input-label-props-focused-invalid, & .text-field-input-label-props-shrink-invalid': {
                        color: theme.palette.primary.error,
                    },
                    '& .text-field-input-label-props-shrink-nonMatch': {
                        color: theme.palette.primary.error,
                    },
                    ' & .text-field-input-props-root': {
                        '& .text-field-input-props-input': {
                            color: theme.palette.primary.heather,
                            fontFamily: theme.typography.body2.fontFamily,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '19px',
                            letterSpacing: '0.15px',
                            padding: '19px 12px 18px 12px',
                        },
                    },
                    ' & .text-field-input-props-root-filled': {
                        ' & .text-field-input-props-notchedOutline': {
                            borderColor: 'unset',
                            borderColor: theme.palette.primary.heather,
                            borderWidth: '2px',
                        },
                        ' & .text-field-input-props-notchedOutline-invalid': {
                            borderColor: 'unset',
                            borderColor: theme.palette.primary.error,
                            borderWidth: '2px',
                        },
                    },
                    ' & .text-field-input-props-focused, & .text-field-input-props-root:hover': {
                        ' & .text-field-input-props-notchedOutline': {
                            borderColor: 'unset',
                            borderColor: theme.palette.primary.heather,
                        },
                        ' & .text-field-input-props-notchedOutline-invalid': {
                            borderColor: 'unset',
                            borderColor: theme.palette.primary.error,
                        },
                    },
                    '& .text-field-input-props-root:hover': {
                        ' & .text-field-input-props-notchedOutline-notFocus': {
                            borderColor: 'unset',
                            borderColor: theme.palette.primary.heather,
                        },
                    },
                },

                '& .invalid-email-format': {
                    fontFamily: theme.typography.inputLabel.fontFamily,
                    // fontStyle: 'normal',
                    // fontWeight: 400,
                    // fontSize:theme.typography.inputLabel.fontSize,
                    // lineHeight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    letterSpacing: '0.4px',
                    color: theme.palette.primary.error,
                    marginTop: '3px',
                },

                '& .newEmail-input, & .confirmEmail-input': {
                    width: '100%',
                },
            },

            '& .changeEmail-body-footer': {
                // marginTop: "97px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                '@media(max-width: 599px)': {
                    // minHeight: "93px",
                    flexGrow: '1',
                    justifyContent: 'flex-end',

                    paddingLeft: '24px',
                    paddingRight: '24px',
                },

                '& .changeEmail-body-footer-cta': {
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    padding: '0px',
                    font: 'inherit',
                    outline: 'inherit',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    width: '183px',

                    borderRadius: '4100px',

                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontWeight: theme.typography.subtitle1.fontWeight,
                    fontSize: theme.typography.subtitle1.fontSize,
                    lineHeight: theme.typography.subtitle1.lineHeight,
                    textAlign: 'center',
                    textAlign: 'center',
                    // color: "#D011C9",

                    paddingLeft: '10px',
                    paddingRight: '10px',

                    '@media(max-width: 599px)': {
                        width: '100%',
                    },
                },

                '& .primary-cta': {
                    minHeight: '42px',
                    marginBottom: '3px',
                    borderColor: 'transparent',
                },
                '& .secondary-cta': {
                    minHeight: '41px',
                    color: theme.palette.primary.sunset,
                    width: 'fit-content',
                    '&:hover': {
                        color: theme.palette.primary.sunset30,
                    },
                },

                '& .cta-diabled': {
                    // background: '#ECECEC',
                    color: theme.palette.primary.gray550,
                    '@media(max-width: 599px)': {
                        marginBottom: '4px',
                    },
                },
                '& .cta-enabled': {
                    background: theme.palette.primary.heather,
                    boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
                    color: theme.palette.primary.white,

                    '&:hover': {
                        // background: '#B10071',
                    },

                    '@media(max-width: 599px)': {
                        minHeight: '41px',
                        marginBottom: '5px',
                    },
                },

                // '@media(max-width: 599px)': {
                //     display: "none",
                // },
            },
        },

        '& .emailSent-body': {
            background: theme.palette.primary.white,
            // minHeight: "660px",
            padding: '24px 32px',
            borderRadius: '10px',

            // boxShadow: "0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}",

            '@media(max-width: 599px)': {
                padding: '16px 16px 16px 16px',
                minHeight: '482px',
                borderRadius: '10px 10px 0px 0px',

                display: 'flex',
                flexDirection: 'column',
            },

            '& .emailSent-body-header': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginTop: '27px',
                // minHeight: "90px",
                borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

                '@media(max-width: 599px)': {
                    minHeight: '80px',

                    flexGrow: '0',
                },

                '& .emailSent-header-text': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,,
                    fontSize: theme.typography.h4.fontSize,
                    lineHeight: '26px',
                    color: theme.palette.primary.heather,

                    minHeight: '32px',
                    marginBottom: '13px',
                    '@media(max-width: 599px)': {
                        fontSize: theme.typography.h4.fontSize,
                        marginBottom: '12px',
                    },
                },
            },

            '& .emailSent-body-content': {
                maxWidth: '377px',
                minHeight: 'auto',
                margin: '48px auto 0px',

                '@media(max-width: 599px)': {
                    margin: '57px auto 0px',
                    paddingLeft: '4px',
                    paddingRight: '4px',

                    minHeight: 'unset',
                    flexGrow: '0',
                },

                '& .emailSent-body-content-image': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    '& .checkbox-image': {
                        width: '64px',
                        height: '64px',
                    },
                },

                '& .emailSent-body-content-title': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,,
                    fontSize: theme.typography.h5.fontSize,
                    lineHeight: '26px',
                    color: theme.palette.primary.heather,

                    textAlign: 'center',

                    marginTop: '1rem',
                    marginBottom: '2rem',

                    '@media(max-width: 599px)': {
                        fontSize: theme.typography.h4.fontSize,
                        marginTop: '19px',
                        marginBottom: '20px',
                    },
                },

                '& .emailSent-body-content-text': {
                    fontFamily: theme.typography.body2.fontFamily,
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '25px',
                    color: theme.palette.primary.heather,

                    textAlign: 'center',

                    '@media(max-width: 599px)': {
                        fontFamily: theme.typography.body3Medium.fontFamily,
                        fontWeight: 600,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '22px',
                    },
                },
            },

            '& .emailSent-body-footer': {
                // marginTop: "97px",

                // display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '3.5rem',
                marginBottom: '2.1rem',
                textAlign: 'center',

                '@media(max-width: 599px)': {
                    display: 'flex',
                    minHeight: '94px',

                    flexGrow: '1',
                    justifyContent: 'flex-end',

                    paddingLeft: '24px',
                    paddingRight: '24px',
                },

                '& .emailSent-body-footer-cta': {
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    padding: '0px',
                    font: 'inherit',
                    outline: 'inherit',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    width: '183px',

                    borderRadius: '4100px',

                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.subtitle1.fontWeight,
                    fontSize: theme.typography.subtitle1.fontSize,
                    lineHeight: theme.typography.subtitle1.lineHeight,
                    textAlign: 'center',
                    // color: "#D011C9",

                    paddingLeft: '10px',
                    paddingRight: '10px',

                    '@media(max-width: 599px)': {
                        width: '100%',
                    },
                },

                '& .primary-cta': {
                    minHeight: '42px',
                    marginBottom: '3px',
                    borderColor: 'transparent',
                },
                '& .cta-enabled': {
                    background: theme.palette.primary.heather,
                    boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
                    color: theme.palette.primary.white,

                    '&:hover': {
                        // background: '#B10071',
                    },

                    '@media(max-width: 599px)': {
                        minHeight: '41px',
                        marginBottom: '53px',
                    },
                },
            },
        },
    },
}));
