import { Box, Typography, Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { styles } from '../styles';

const DeviceDetailsInactive = () => {
    const [tvURL, setTvURL] = useState('https://tv.eir.ie');

    const classes = styles();

    useEffect(() => {
        if (
            window.location.hostname === 'my.eir.ie' ||
            window.location.hostname === 'mypp00.eir.ie'
        ) {
            setTvURL('https://tv.eir.ie');
        } else {
            setTvURL('https://eir-qa.zattoo.com');
        }
    }, []);

    return (
        <Box className={classes.deviceManagementInActiveDevice}>
            <Typography variant='subtitle2' className={classes.deviceManagementInActiveDeviceText}>
                No device activated
            </Typography>
            <Link
                className={classes.deviceManagementInActiveDeviceLink}
                // onClick={handleDeviceActivation}
                href={tvURL}
                target='_blank'
                underline='always'
            >
                Watch TV now
            </Link>
        </Box>
    );
};

export default DeviceDetailsInactive;
