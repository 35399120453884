import { useApolloClient } from '@apollo/client';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core';

import { Done, ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import {
    scheduleMoveAPI,
    sendOtpAPI,
    validateOtpAPI,
    getOperatorApi,
    rescheduleMoveAPI,
} from '../porting.util';
import AccountInfo from './AccountInfo';
import ScheduleMove from './ScheduleMove';
import useStyle from './style';
import ValidateNumber from './ValidateNumber';
import { onload } from '../porting.util';
import theme from '../../../theme/MyEirTheme';

const Porting = ({
    portData = {},
    handleCTA,
    setApierror,
    reschedule = false,
    setStep,
    setPortData,
    serviceId,
    handleClose,
    setOpenError,
    // mnpId,
    // openError,
}) => {
    const [portingStep, setPortingStep] = useState(reschedule ? 3 : 0);
    const [errorMessage, setErrorMessage] = useState('');
    const [errMsg, seterrMsg] = useState({ currentProvider: '' });
    const [enable, setEnable] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
    });
    const [updatePort, setUpdatePort] = useState({
        brand: '',
        type: '',
        accountNumber: '',
        portStartDateTime: '',
        phoneNumber: '',
        direction: '',
        oldId: '',
        otherNetworkAccountNumber: '',
        otherNetworkId: '',
    });
    const [portIn, setData] = useState({
        phoneNumber: '',
        otpUuid: '',
        otp: '',
        brand: '',
        type: '',
        accountNumber: '',
        portStartDateTime: '',
    }); // to store all the input data
    const graphql = useApolloClient();

    const handleBackButton = () => {
        if (portingStep === 2) {
            setPortingStep(0);
            setEnable({ step1: true });
        } else {
            setPortingStep(portingStep - 1);
        }
        setErrorMessage('');
    };

    const updateFooterButtons = () => {
        switch (portingStep) {
            // account info
            case 0:
                console.log('update footer step 0', portIn);
                handleCTA({
                    cta: {
                        text: 'Text me my code',
                        action: () => getOtp(portIn.phoneNumber),
                        disable: !Boolean(enable.step1),
                    },
                    primary: true,
                });
                handleCTA({ cta: { text: '', action: () => {} } });
                break;
            // validate code
            case 1:
                console.log('update footer step 1', portIn.otp, enable);
                handleCTA({
                    primary: true,
                    cta: {
                        text: 'Confirm',
                        action: () => validateOtp(),
                        disable: !Boolean(enable.step2), //&& Boolean(portIn.otpUuid))),
                    },
                });
                handleCTA({ cta: { text: 'Back to last step', action: handleBackButton } });
                break;
            // account info
            case 2:
                console.log('update footer step 2');
                handleCTA({
                    primary: true,
                    cta: {
                        text: 'Confirm',
                        action: () => setPortingStep(3),
                        disable: !Boolean(enable.step3),
                    },
                });
                handleCTA({ cta: { text: 'Back to last step', action: handleBackButton } });
                break;
            case 3:
                console.log('update footer step 3');
                handleCTA({
                    primary: true,
                    cta: {
                        text: reschedule ? 'Update my move' : 'Confirm',
                        action: () => (reschedule ? handleRescheduleMove() : handleScheduleMove()),
                        disable: !Boolean(enable.step4),
                    },
                });
                handleCTA({
                    cta: { text: reschedule ? '' : 'Back to last step', action: handleBackButton },
                });
                break;
            default:
                break;
        }
    };

    const portingOnload = async () => {
        if (reschedule && portData) {
            setData(portData);
            await getOpeartor(portData.phoneNumber);
            setEnable({ step4: true });
        }
    };

    // step 1 : API Integration
    const getOtp = async msisdn => {
        console.log('get otp api call', msisdn, portIn.phoneNumber);
        try {
            const data = await sendOtpAPI({ graphql, msisdn });
            if (data) {
                setData({ ...portIn, otpUuid: data?.otpUuid });
                setPortingStep(1);
                setErrorMessage('');
            }
        } catch (error) {
            setOpenError(true);
            handleClose();
            console.error(error);
        }
    };

    // step 2 : API Integration
    const validateOtp = async () => {
        console.log('validate api call', portIn.otp);
        try {
            const data = await validateOtpAPI({
                graphql,
                otp: portIn.otp,
                otpUuid: portIn.otpUuid,
            });
            getOpeartor(portIn.phoneNumber);
            if (data) {
                setPortingStep(2);
                setErrorMessage('');
            }
        } catch (error) {
            console.log(error);
            setErrorMessage(
                'Please enter a valid code to move to the next step. This code will expire in 1 minute.'
            );
        }
    };

    // Operator API Integration
    const getOpeartor = async msisdn => {
        try {
            const data = await getOperatorApi({ graphql, msisdn: msisdn });
            //  !!! - HARDCODED FOR TESTING
            // const data = { network: { networkName: 'Meteor' } };
            // If internal MSISDN
            if (
                data.network.networkName === 'Meteor' ||
                data.network.networkName === 'Eircom Mobile'
            ) {
                // If found in ION brand will be set
                // If its not set, assume its R6
                setData({
                    ...portIn,
                    brand: data.brand || 'EIR',
                    otherNetworkId: data?.network.id,
                    networkName: data?.network.networkName,
                });
            }
            // If external MSISDN
            else {
                setData({
                    ...portIn,
                    brand: data?.network.networkName,
                    otherNetworkId: data?.network.id,
                    networkName: data?.network.networkName,
                });
            }
        } catch (error) {
            if (
                error.graphQLErrors.length > 0 &&
                error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER'
            ) {
                setOpenError(true);
                handleClose();
                console.error(error);
            } else {
                setApierror('We could not find information on the number you are trying to move');
                console.error(error);
            }
        }
    };

    // step 3
    const handleAccountInfo = () => {};

    // step 4
    const handleScheduleMove = async () => {
        try {
            const data = await scheduleMoveAPI({
                graphql,
                portIn: {
                    brand:
                        portIn.networkName === 'Meteor' ||
                        portIn.networkName === 'Eircom Mobile' ||
                        portIn.brand === 'EIR'
                            ? portIn.brand
                            : 'EIR',
                    direction: 'PORT_IN',
                    phoneNumber: portIn.phoneNumber,
                    type: portIn.type,
                    serviceId: parseInt(serviceId),
                    portStartDateTime: portIn.portStartDateTime,
                    otherNetworkId: portIn.otherNetworkId,
                    otherNetworkAccountNumber: portIn.accountNumber,
                },
                token: sessionStorage.getItem('access_token'),
            });
            if (data === 201) {
                const portDetails = await onload({
                    graphql,
                    session: sessionStorage,
                    serviceId,
                });
                if (portDetails.data) {
                    setPortData &&
                        setPortData({
                            brand: portIn.brand,
                            direction: 'PORT_IN',
                            phoneNumber: portIn.phoneNumber,
                            type: portIn.type,
                            serviceId: parseInt(serviceId),
                            portStartDateTime: portDetails.data.portStartDateTime,
                        });
                    setStep(2);
                }
            }
        } catch (error) {
            if (
                error.graphQLErrors.length > 0 &&
                (error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER' ||
                    error.graphQLErrors[0].extensions.code === 'INVALID_NETWORK_CODE')
            ) {
                setOpenError(true);
                handleClose();
                console.error(error);
            } else {
                setApierror(
                    'Oops, looks like you already have a scheduled move in progress, you cannot schedule another'
                );
                console.error(error);
            }
        }
    };

    const handleRescheduleMove = async () => {
        try {
            const data = await rescheduleMoveAPI({
                graphql,
                updatePort: {
                    brand:
                        portIn.networkName === 'Meteor' ||
                        portIn.networkName === 'Eircom Mobile' ||
                        portIn.brand === 'EIR'
                            ? portIn.brand
                            : 'EIR',
                    direction: 'PORT_IN',
                    oldId: portData.mnpId,
                    otherNetworkAccountNumber: portData.otherNetworkAccountNumber,
                    otherNetworkId: portData.otherNetworkId,
                    phoneNumber: portData.phoneNumber,
                    type: portData.type,
                    serviceId: parseInt(serviceId),
                    portStartDateTime: portIn.portStartDateTime,
                },
                token: sessionStorage.getItem('access_token'),
            });

            if (data === 200) {
                const portDetails = await onload({
                    graphql,
                    session: sessionStorage,
                    serviceId,
                });
                if (portDetails.data) {
                    setPortData &&
                        setPortData({
                            brand: portIn.brand,
                            direction: 'PORT_IN',
                            oldId: portData.mnpId,
                            otherNetworkAccountNumber: portData.otherNetworkAccountNumber,
                            otherNetworkId: portData.otherNetworkId,
                            phoneNumber: portData.phoneNumber,
                            type: portData.type,
                            serviceId: parseInt(serviceId),
                            portStartDateTime: portDetails.data.portStartDateTime,
                        });

                    setStep(2);
                }
            }
        } catch (error) {
            setOpenError(true);
            handleClose();
            console.error(error);
        }
    };

    useEffect(() => {
        portingOnload();
    }, []);

    // onload
    useEffect(() => {
        console.log('called useffect');

        updateFooterButtons();
    }, [portingStep, enable]);

    const classes = useStyle();

    const accordionTitle = index => (
        <Box className={`accordion-title ${classes.accordionTitle}`}>
            <Box>
                <span>{index + 1}</span>{' '}
                {['Validate Number', 'Input Account Info', 'Schedule Move'][index]}
            </Box>
            {portingStep > index + 1 && (
                <Box>
                    <Done style={{ color: '#43b02a', fontSize: 14 }} />
                </Box>
            )}
        </Box>
    );

    return (
        <Box id='porting-screen-2' style={{ paddingTop: '1.5rem' }}>
            {/* STEP - 0 & 1 - validate phone number */}
            <Accordion
                square
                id='porting-number-accordion'
                className={classes.accordion}
                expanded={portingStep === 0 || portingStep === 1}
            >
                {/* TODO Tick Icon when step is completed */}
                <AccordionSummary
                    className={`${classes.accordionSummary}`}
                    expandIcon={
                        <ExpandMore
                            style={portingStep >= 0 ? { color: theme.palette.primary.sunset } : {}}
                        />
                    }
                >
                    {accordionTitle(0)}
                </AccordionSummary>
                <AccordionDetails>
                    <ValidateNumber
                        portingStep={portingStep} // to check step1 (msisdn) and step2 (validate otp)
                        // handleCTA={handleCTA} // to set CTA values
                        getOtp={getOtp} // to get otp
                        // validateOtp={validateOtp} // to validate otp
                        portIn={{ value: portIn, setData }}
                        updateFooterButtons={updateFooterButtons}
                        setEnable={setEnable}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        // portData={portData}
                    />
                </AccordionDetails>
            </Accordion>

            {/* STEP - 2 - Input Account Info */}
            <Accordion
                id='porting-account-info-accordion'
                className={classes.accordion}
                disabled={portingStep < 2}
                expanded={portingStep === 2}
                square
            >
                <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                        <ExpandMore
                            style={portingStep > 1 ? { color: theme.palette.primary.sunset } : {}}
                        />
                    }
                >
                    {accordionTitle(1)}
                </AccordionSummary>
                <AccordionDetails>
                    <AccountInfo
                        brand={portIn.brand}
                        handleCTA={handleCTA}
                        portIn={{ value: portIn, setData }}
                        setEnable={setEnable}
                        handleAccountInfo={handleAccountInfo}
                    />
                </AccordionDetails>
            </Accordion>

            {/* STEP - 3 - Schedule Move */}
            <Accordion
                id='porting-schedule-move-accordion'
                className={classes.accordion}
                disabled={portingStep < 3}
                expanded={portingStep === 3}
                square
            >
                <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                        <ExpandMore
                            style={portingStep > 2 ? { color: theme.palette.primary.sunset } : {}}
                        />
                    }
                >
                    {accordionTitle(2)}
                </AccordionSummary>
                <AccordionDetails>
                    <ScheduleMove
                        handleScheduleMove={handleScheduleMove}
                        setEnable={setEnable}
                        handleCTA={handleCTA}
                        portIn={{ value: portIn, setData }}
                        portData={portData}
                        reschedule={reschedule}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default Porting;
