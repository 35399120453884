import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { authorisedUserStyle } from './authorisedUserStyle';
import CustomTextField from '../../../sharedcomponents/inputs/text-field/CustomTextField';
import CustomSelect from '../../../sharedcomponents/inputs/select/CustomSelect';

const EditAuthorisedUserInput = ({ user, securityQuestions, setdisableCTA, disableCTA }) => {
    const classes = authorisedUserStyle();
    const [firstName, setValidFirstName] = useState(user?.firstName);
    const [lastName, setValidLastName] = useState(user?.lastName);
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [answerError, setAnswerError] = useState(false);
    const [securityPhrase, setSecurityPhrase] = useState(user?.securityPhrase);
    const [securityValue, setsecurityValue] = useState('');
    const [questionType, setQuestionType] = useState();

    useEffect(() => {
        if (!firstName) {
            setFirstNameError(true);
        } else {
            validateName(setFirstNameError, firstName);
        }
        if (!lastName) {
            setLastNameError(true);
        } else {
            validateLastName(setLastNameError, lastName);
        }

        if (!securityPhrase) {
            setAnswerError(true);
        }

        for (const key in securityQuestions) {
            if (user.securityQestion === securityQuestions[key]) {
                setsecurityValue(key);
            }
        }
    }, []);

    useEffect(() => {
        if (!firstName || !lastName || !securityPhrase) {
            setdisableCTA(true);
        } else {
            if (validateFormat.test(firstName) && validateFormat.test(lastName)) {
                setdisableCTA(false);
            } else {
                setdisableCTA(true);
            }
        }
    }, [firstName, lastName, securityPhrase]);

    const validateFormat = /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒšŽ \\s`&,./()0-9-\"]+$/;
    function validateLastName(valFun1, val1) {
        if (validateFormat.test(val1)) {
            valFun1(false);

            setdisableCTA(true);
        } else {
            setdisableCTA(false);
            valFun1(true);
        }
    }
    function validateName(valFun, val) {
        if (validateFormat.test(val)) {
            valFun(false);

            setdisableCTA(true);
        } else {
            setdisableCTA(false);

            valFun(true);
        }
    }
    const handleFirstname = event => {
        validateName(setFirstNameError, event.target.value);
        setValidFirstName(event.target.value);
    };
    const handleLastname = event => {
        validateLastName(setLastNameError, event.target.value);
        setValidLastName(event.target.value);
    };

    const handleSecurityQSelection = e => {
        setsecurityValue(e.target.value);
    };

    const handleSecAnswer = e => {
        if (e.target.value === '') {
            setAnswerError(true);
        } else {
            setAnswerError(false);
        }
        setSecurityPhrase(e.target.value);
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box className={classes.textFieldContent}>
                    <CustomTextField
                        id='edit-user'
                        label='First name'
                        onChange={handleFirstname}
                        required
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        value={firstName}
                        error={firstnameError}
                        errorMessage='Please enter valid first name'
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.textFieldContent}>
                    <CustomTextField
                        id='edit-user'
                        label='Last name'
                        onChange={handleLastname}
                        required
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        value={lastName}
                        error={lastnameError}
                        errorMessage='Please enter valid last name'
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.textFieldSelect}>
                    <CustomSelect
                        id='security-question'
                        value={securityValue}
                        className={`${classes.selectField}`}
                        data={securityQuestions}
                        onChange={handleSecurityQSelection}
                        placeholder='Security question'
                        required
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.textFieldContent}>
                    <CustomTextField
                        id='security-answer'
                        label='Answer'
                        value={securityPhrase}
                        onChange={handleSecAnswer}
                        required
                        classes={{
                            container: `${classes.textField}`,
                        }}
                        error={answerError}
                        errorMessage='Please enter a security answer'
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default EditAuthorisedUserInput;
