import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Hidden } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import { createBreakpoints } from '@material-ui/core/styles/createBreakpoints';
import { useMyAccountStyle } from '../css/useMyAccountStyle';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { formatCounty } from '../addressFormat';
import MyEirChangeAddressContainer from './MyEirChangeAddressContainer';
import MyEirChangeEmail from './MyEirChangeEmail';
import MyEirChangeContactNumber from './MyEirChangeContactNumber';
import MyEirChangePasswordAndEmailToggle from './MyEirChangePasswordAndEmailToggle';
import PaymentAddCardFlow from '../payment-cards/PaymentAddCardFlow';
import MyEirAuthorisedUsersContainer from './authorised-users/MyEirAuthorisedUsersContainer';

// const theme = createMuiTheme({
//     breakpoints: {
//       keys: ['small', 'medium', 'large'],
//       values: {
//         small: 0,
//         medium: 600,
//         large: 1020
//       }
//     }
//   });

// const BREAKPOINTS = {
//     xs: 0,
//     sm: 600,
//     md: 960,
//     lg: 1020,
//     xl: 1920,
// };

// const breakpointsValues = {
//     breakpoints: {
//         values: BREAKPOINTS
//     }
// };

// const theme = createMuiTheme({ ...breakpointsValues});

function MyEirAccountDetails({ width, contactDetails, authUser, payDetails, catalogOffer }) {
    const classes = useMyAccountStyle();
    const [contact, setContact] = useState(contactDetails);
    const [contactInfo, setContactInfo] = useState({});
    const auth = authUser;
    const payData = payDetails;

    const [loading, setLoading] = useState(true);
    const [isOpen1, setIsopen1] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [apiError, setApiError] = useState('');

    const graphql = useApolloClient();

    const toggleError = () => {
        setOpenError(!openError);
    };

    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };
    const [isOpen, setIsopen] = useState(false);
    function toggle() {
        setIsopen(!isOpen);
    }

    const handleContactInfo = () => {
        const primaryContact = contactDetails?.phoneNumbers.find(ele => ele?.type == 'MOBILE');
        const secondaryContact = contactDetails?.phoneNumbers.find(ele => ele?.type == 'FIXE');
        setContactInfo(prev => ({ ...prev, primaryContact, secondaryContact }));
    };

    const onChangePassword = async () => {
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.RESET_PASSWORD,
                variables: {
                    emailAddress: sessionStorage.getItem('LoggedinUser'),
                },
            });

            if (res.data.resetPassword) {
                setEmailAddress(emailAddress);
                toggle1();
            } else {
                console.error('something went wrong while triggering forgot/reset password');
                toggleError();
            }
        } catch (err) {
            setApiError(err.message);
            toggleError();
        }
    };

    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };
    const onChangePaymentMethod = () => {
        toggle2();
    };

    useEffect(() => {
        setContact(contactDetails);
        handleContactInfo();
    }, [contactDetails]);

    return (
        // <ThemeProvider theme={theme}>
        <Grid container className={classes.DetailsWrapper}>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={width >= 1020 ? 6 : 12}
                className='details-grid-first-half'
            >
                <div className='details-topic'>
                    <div className='details-topic-title'>Full name</div>
                    <div className='details-topic-content'>
                        {contact.firstName} {contact.lastName}
                    </div>
                </div>
                {contact.emails.length > 0 && (
                    <div className='details-topic'>
                        <div className='details-topic-title-change'>
                            <div className='details-topic-title'>Login email</div>
                            {/* <div className='details-topic-change'>Change</div> */}
                            <MyEirChangeEmail loginEmail={contact.emails[0].email} />
                        </div>
                        <div className='details-topic-content'>
                            {contact.emails.length > 0 ? contact.emails[0].email : ''}
                        </div>
                    </div>
                )}
                <div className='details-topic'>
                    <div className='details-topic-title-change'>
                        <div className='details-topic-title'>Password</div>
                        <div className='details-topic-change' onClick={onChangePassword}>
                            Change
                        </div>
                    </div>
                    <div className='details-topic-content'>**********</div>
                </div>
                <div className='details-topic'>
                    <div className='details-topic-title-change'>
                        <div className='details-topic-title'>Payment method</div>
                        <div className='details-topic-change' onClick={onChangePaymentMethod}>
                            Change
                        </div>
                    </div>
                    <div className='details-topic-content'>
                        {payDetails
                            .filter(y => y.default == true)
                            .map(element =>
                                element.paymentMethodType === 'CASH'
                                    ? 'Manual'
                                    : element.paymentMethodType == 'DIRECT_DEBIT'
                                    ? element.iban.replace(/X/g, '*')
                                    : element.paymentMethodType !== 'DIRECT_DEBIT' &&
                                      element.partialDigits == null
                                    ? `**** **** **** ****`
                                    : element.partialDigits.replace(/x/g, '*')
                            )}
                    </div>

                    {/* {payDetails.map((item)=>{
                        return  (
                            <div className='details-topic-content'>                              
                                ****{item.paymentMethodId}
                            </div>)
                    })} */}
                    {/* <div className='details-topic-content'>                              
                                {payDetails.filter((item)=>item.default==true)[0].(partialDigits == null ?paymentMethodId : partialDigits )}
                    </div> */}

                    {/* {payDetails
                        .filter(y => y.default == true)
                        .map(element =>
                            element.partialDigits == null ? (
                                element.paymentMethodType == 'CASH' ? (
                                    <>
                                        <div className='details-topic-title-change'>
                                            <div className='details-topic-title'>
                                                Payment method
                                            </div>
                                            <div className='details-topic-change'>Change</div>
                                        </div>
                                        <div className='details-topic-content'>Manual</div>
                                    </>
                                ) : element.paymentMethodType == 'DIRECT_DEBIT' ? (
                                    <>
                                        <div className='details-topic-title-change'>
                                            <div className='details-topic-title'>
                                                Payment method
                                            </div>
                                            <div className='details-topic-change' onClick={onChangePaymentMethod}>Change</div>
                                        </div>
                                        <div className='details-topic-content'>Direct Debit</div>
                                    </>
                                ) : (
                                    ''
                                )
                            ) : (
                                element.partialDigits
                            )
                        )} */}
                </div>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={width >= 1020 ? 6 : 12}
                className='details-grid-second-half'
            >
                {width >= 1020 && <div className='divider'></div>}
                <div className='details-topic'>
                    <div className='details-topic-title-change'>
                        <div className='details-topic-title'>Billing address</div>
                        <div className='details-topic-change' onClick={toggle}>
                            Change
                        </div>
                    </div>
                    {contact.addresses.length > 0 ? (
                        <>
                            {contact.addresses.map(element =>
                                element.type !== 'BILLING' ? (
                                    ''
                                ) : (
                                    <>
                                        <div className='details-topic-content details-topic-content-billing-address'>
                                            {element.addressLine1}
                                        </div>
                                        <div className='details-topic-content details-topic-content-billing-address'>
                                            {element.addressLine2}
                                        </div>
                                        <div className='details-topic-content details-topic-content-billing-address'>
                                            {element.addressLine3}
                                        </div>
                                        <div className='details-topic-content details-topic-content-billing-address'>
                                            {element.town}
                                        </div>
                                        <div className='details-topic-content details-topic-content-billing-address'>
                                            {formatCounty(element.county)}
                                        </div>
                                        <div className='details-topic-content details-topic-content-billing-address'>
                                            {element.code}
                                        </div>
                                    </>
                                )
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {contactInfo?.primaryContact && (
                    <div className='details-topic'>
                        <div className='details-topic-title-change'>
                            <div className='details-topic-title'>Primary contact number</div>
                            <MyEirChangeContactNumber
                                phoneNumberId={contactInfo?.primaryContact?.id}
                                phoneNumber={contactInfo?.primaryContact?.phoneNumber}
                                phoneNumberType={contactInfo?.primaryContact?.type}
                            />
                        </div>
                        <div className='details-topic-content details-topic-content-contact-number'>
                            {contactInfo?.primaryContact?.phoneNumber}
                        </div>
                    </div>
                )}
                {contactInfo?.secondaryContact && (
                    <div className='details-topic'>
                        <div className='details-topic-title-change'>
                            <div className='details-topic-title'>Secondary contact number</div>
                            <MyEirChangeContactNumber
                                phoneNumberId={contactInfo?.secondaryContact?.id}
                                phoneNumber={contactInfo?.secondaryContact?.phoneNumber}
                                phoneNumberType={contactInfo?.secondaryContact?.type}
                            />
                        </div>
                        <div className='details-topic-content details-topic-content-contact-number'>
                            {contactInfo?.secondaryContact?.phoneNumber}
                        </div>
                    </div>
                )}
                <MyEirAuthorisedUsersContainer />
                {/* <div className='details-topic'>
                    {auth.length == 0 ? (
                        ''
                    ) : (
                        <>
                            <div className='details-topic-title-change'>
                                <div className='details-topic-title'>Authorised users</div>
                                <div className='details-topic-change'>Change</div>
                            </div>
                            {auth.map(element =>
                                element.firstName == null ? (
                                    ''
                                ) : (
                                    <>
                                        <div className='details-topic-content'>
                                            {element.firstName} {element.lastName}
                                        </div>
                                    </>
                                )
                            )}
                        </>
                    )}
                </div> */}
            </Grid>

            <MyEirChangePasswordAndEmailToggle
                toggle={toggle1}
                email={''}
                isopen={isOpen1}
                heading={'Change Password'}
                headingMobile={'Change Password'}
                newsContentText={'We’ve just sent you an email.'}
                verifiedText={''}
                verifiedTextMobile={''}
                subtext={''}
                subtextContinued={
                    'Please follow the instructions to change your password and don’t forget to check your spam email!'
                }
                buttonText={'Close'}
                footerText={''}
            />
            <MyEirChangeAddressContainer toggle={toggle} isOpen={isOpen} />
            <PaymentAddCardFlow toggle2={toggle2} isOpen2={isOpen2} />
            {openError ? <MyEirErrorHandle open={openError} setOpen={toggleError} /> : ''}
        </Grid>
        //  </ThemeProvider>
    );
}

export default MyEirAccountDetails;

// export default function CustomStyles() {
//     return (
//       <ThemeProvider theme={theme}>
//         <MyEirAccountDetails />
//       </ThemeProvider>
//     );
//   }
