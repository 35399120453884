import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { usePaymentFlowStyles } from '../css/usePaymentFlowStyles';
import { MobileContext } from '../global-context';
import PaymentIframeContainer from './PaymentIframeContainer';
import PaymentAmount from './PaymentAmount';
// import PaymentMethod from './PaymentMethod';
import PaymentStatusModal from './PaymentStatusModal';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
// import PaymentAddCard from './PaymentAddCard';

const PaymentFlowModal = ({ open, toggle, amount, buttonRequired = false,balance }) => {
    // const [openModal, setOpenModal] = useState(props.open);
    const [paymentData, setPaymentData] = useState({
        header: { title: 'Payment Summary', center: false },
        payableAmount: amount != undefined ? amount : 0,
    });
    const [step, setStep] = useState(0);
    const [status, setPaymentStatus] = useState(false);
    const [paidAmount, setPaidAmount] = useState(0);

    // const toggle = () => setOpenModal(!openModal);

    const resetStates = async () => {
        console.log('reseting states', open);
        setPaymentData({
            header: { title: 'Payment Summary', center: false },
            payableAmount: amount != undefined ? amount : 0,
            status: null,
        });
        setStep(0);
    };

    useEffect(() => {
        setAmount(amount);
    }, [amount]);
    useEffect(() => {
        if (!open) {
            // console.log('resetStates');
            resetStates();
        }
    }, [open]);
    useEffect(() => {
        if (step == 'addcard') {
            // console.log('resetStates');
            resetStates();
        }
    }, [step]);

    const classes = usePaymentFlowStyles();

    const handleBack = () => {
        if (step == 0) {
            toggle();
            return;
        }
        setStep(step - 1);
    };
    const handleNext = (jumpTo = undefined) => {
        if (jumpTo != undefined) setStep(jumpTo);
        else {
            setStep(step + 1);
        }
    };

    const setAmount = amount => setPaymentData({ ...paymentData, payableAmount: amount });
    const setAmountPaid = amount => {
        setPaymentData({ ...paymentData, payableAmount: amount });
        setPaidAmount(amount);
    };
    const setStatus = status => {
        // console.log(';setStatus () ' + status);
        // setPaymentData({ ...paymentData, status: status });
        setPaymentStatus(status);
    };
    const setHeader = (title, center = false) =>
        setPaymentData({ ...paymentData, header: { title: title, center: center } });

    const handleMultiPaymentData = data => setPaymentData({ ...paymentData, ...data });

    // const localToggle = () => {
    //     setOpenModal(!localOpen);
    // };

    return (
        <div id='payment-flow-modal'>
            {buttonRequired && <button onClick={toggle}>Payment Flow</button>}
            <Modal
                id='myeir-modal-window-make-payment'
                isOpen={open}
                toggle={toggle}
                modalTransition={{ timeout: 300 }}
                wrapClassName={classes.modalWrapper}
                modalClassName={classes.modalClassName}
                centered
            >
                <MyEirPaymentHeader
                    paymentHeaderText={paymentData.header.title}
                    handleBack={handleBack}
                    center={paymentData.header.center}
                />
                {
                    {
                        0: (
                            <PaymentAmount
                                handleNext={handleNext}
                                confirmAmount={paymentData.payableAmount}
                                setAmount={setAmount}
                                defaultAmount={amount}
                                setHeader={setHeader}
                                balance={balance}
                            />
                        ),
                        1: (
                            <PaymentIframeContainer
                                payableAmount={paymentData.payableAmount}
                                handleBack={handleBack}
                                setStatus={setStatus}
                                handleNext={handleNext}
                                setHeader={setHeader}
                                handleMultiPaymentData={handleMultiPaymentData}
                                toggle={toggle}
                                setPaidAmount={setAmountPaid}
                            />
                        ),
                        2: (
                            <PaymentStatusModal
                                success={status}
                                paidAmount={paidAmount}
                                toggle={toggle}
                                handleBack={handleBack}
                                setHeader={setHeader}
                            />
                        ),
                        // 3: (
                        //     <PaymentMethod
                        //         payableAmount={paymentData.payableAmount}
                        //         handleBack={handleBack}
                        //         handleNext={handleNext}
                        //     />
                        // ),
                        // 4: (
                        //     <PaymentAddCard
                        //         handleNext={handleNext}
                        //         handleBack={handleBack}
                        //         setHeader={setHeader}
                        //     />
                        // ),
                    }[step]
                }
            </Modal>
        </div>
    );
};

export default PaymentFlowModal;

const MyEirPaymentHeader = props => {
    const styleClass = usePaymentFlowStyles();

    return (
        <div className={styleClass.headerPaymentContainer}>
            {!props.center && (
                <ArrowBackIcon className={'payment-header-back-icon'} onClick={props.handleBack} />
            )}
            <div className='payment-header-text'>
                <p style={props.center ? { textAlign: 'center', width: '100%' } : {}}>
                    {props.paymentHeaderText}
                </p>
                <div className='payment-header-line'></div>
            </div>
        </div>
    );
};
