import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomModal from '../../eir-porting/CustomModal/CustomModal';
import MyEirAddAuthorisedUsers from './MyEirAddAuthorisedUsers';
import MyEirDeleteAuthorisedUsers from './MyEirDeleteAuthorisedUsers';
import MyEirEditAuthorisedUsers from './MyEirEditAuthorisedUsers';
import MyEirViewAuthorisedUsers from './MyEirViewAuthorisedUsers';
import { useApolloClient } from '@apollo/client';
import { authorisedUserStyle } from './authorisedUserStyle';
import { getAuthorisedUsers } from './authorisedUsers.util';

const mockData = [
    {
        id: 1,
        firstName: 'Paddy',
        lastName: 'Moloney',
        securityQestion: 'Question1',
        securityPhrase: 'Answer1',
    },
    {
        id: 2,
        firstName: 'Mary',
        lastName: 'McAleese',
        securityQestion: 'Question2',
        securityPhrase: '',
    },
    {
        id: 3,
        firstName: 'Firstname',
        lastName: undefined,
        securityQestion: 'Question3',
        securityPhrase: 'Answer3',
    },
    {
        id: 4,
        firstName: undefined,
        lastName: 'Lastname',
        securityQestion: 'Question4',
        securityPhrase: 'Answer4',
    },
];
const mockQuestions = {
    securityQestion1: 'Question1',
    securityQestion2: 'Question2',
    securityQestion3: 'Question3',
    securityQestion4: 'Question4',
    securityQestion5: 'Which is your favourite color',
    securityQestion6: 'Name of first school',
    securityQestion7: 'Where is your home town?',
};

function MyEirAuthorisedUsersContainer(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(); // initial value needs to be set to empty or undefined after api integration
    const [stepRecorder, setStepRecorder] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    const [authUsers, setAuthUsers] = useState();
    const [securityQuestions, setSecurityQuestions] = useState();

    // if we use state variables to maintain header and footer data
    // const [headerText, setHeaderText] = useState('Authorised users');
    // const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    // const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });

    const [disableCta1, setDisableCta1] = useState(false);
    const [disableCta2, setDisableCta2] = useState(false);

    const classes = authorisedUserStyle();
    const graphql = useApolloClient();

    const toggle = () => {
        console.log('modal toggled', isOpen);
        setIsOpen(!isOpen);
    };

    const handleBackButton = () => {
        if (stepRecorder.length === 1 || stepRecorder[stepRecorder.length - 1] === 'view') {
            toggle();
        } else {
            if (stepRecorder[stepRecorder.length - 2] !== undefined) {
                setStep(stepRecorder[stepRecorder.length - 2]);
            } else {
                setStep('view');
            }
        }
    };

    const getAuthorisedUserInfo = async () => {
        try {
            const res = await getAuthorisedUsers({ graphql, setAuthUsers });

            // set api response to authUsers state

            // check if number of users returned is greater than 0;
            if (authUsers?.length > 0) {
                setStep('view');
            } else {
                setStep('add');
            }
        } catch (error) {
            console.error('Error while getting authorised users info: ', error.message);
        }
    };

    const getSecurityQuestions = async () => {
        try {
            // api call here
            const res = { data: mockQuestions };
            if (res.data) {
                // set api response to securityQuestions state
                setSecurityQuestions(res.data);
            }
        } catch (error) {
            console.error('Error while getting security questions ', error.message);
        }
    };

    useEffect(() => {
        getAuthorisedUserInfo();
        getSecurityQuestions();
    }, []);

    useEffect(() => {
        // if we use state variables to maintain header and footer data
        // switch (step) {
        //     case 'view':
        //         setHeaderText('Authorised users');
        //         setCTA1({ text: '', action: () => {}, disable: false });
        //         setCTA2({ text: '', action: () => {}, disable: false });
        //         break;
        //     case 'add':
        //         setHeaderText('Add authorised user');
        //         setCTA1({ text: 'Add user', action: () => {}, disable: false });
        //         setCTA2({ text: 'Cancel', action: () => {}, disable: false });
        //         break;
        //     case 'delete':
        //         setHeaderText('Delete authorised user');
        //         setCTA1({ text: 'Delete', action: () => {}, disable: false });
        //         setCTA2({ text: 'Cancel', action: () => {}, disable: false });
        //         break;
        //     case 'edit':
        //         setHeaderText('Edit authorised user');
        //         setCTA1({ text: 'Save changes', action: () => {}, disable: false });
        //         setCTA2({ text: 'Cancel', action: () => {}, disable: false });
        //         break;
        // }

        if (step === 'view' || step === 'add' || step === 'delete' || step === 'edit') {
            console.log('updating stepRecorder');
            setStepRecorder(prev => {
                if (step === 'view') {
                    return [step];
                }
                if (prev.findIndex(ele => ele === step) !== -1) {
                    return prev.slice(0, prev.findIndex(ele => ele === step) + 1);
                }
                return [...prev, step];
            });
        }
    }, [step]);

    // modal header data
    const heading = {
        view: 'Authorised users',
        add: 'Add authorised user',
        delete: 'Delete authorised user',
        edit: 'Edit authorised user',
    };
    // modal cta1 data
    const newCta1 = {
        view: {
            text: '',
            action: () => {},
        },
        add: {
            text: 'Add user',
            action: () => {},
        },
        delete: {
            text: 'Delete',
            action: () => {},
        },
        edit: {
            text: 'Save changes',
            action: () => {},
        },
    };
    // modal cta2 data
    const newCta2 = {
        view: {
            text: '',
            action: () => {},
        },
        add: {
            text: 'Cancel',
            action: () => {},
        },
        delete: {
            text: 'Cancel',
            action: () => {},
        },
        edit: {
            text: 'Cancel',
            action: () => {},
        },
    };

    console.log('stepRecorder', step, stepRecorder);
    return (
        <Box>
            {authUsers?.length > 0 && (
                <Box className={classes.authUserTextContainer}>
                    <Box className={classes.authUserTextContainerHeader}>
                        <Typography className={classes.authUserTextContainerHeaderText}>
                            Authorised users
                        </Typography>
                        {/* <Typography
                            className={classes.authUserTextContainerHeaderLink}
                            onClick={toggle}
                        >
                            {authUsers.length > 0 ? 'Change' : 'Add'}
                        </Typography> */}
                    </Box>
                    {authUsers?.map((element, index) => (
                        <Typography
                            key={`authUser${index + 1}`}
                            className={classes.authUserTextContainerContent}
                        >
                            {!!element?.firstName && `${element.firstName} `}
                            {!!element?.lastName && `${element.lastName}`}
                        </Typography>
                    ))}
                </Box>
            )}
            <CustomModal
                open={isOpen}
                cta1={{ ...newCta1[step], disable: disableCta1 }}
                cta2={{ ...newCta2[step], disable: disableCta2 }}
                // cta1={cta1}
                // cta2={cta2}
                toggle={toggle}
                header={{
                    text: heading[step],
                    // text: headerText,
                    arrowAction: handleBackButton,
                    center: false,
                }}
            >
                {
                    {
                        view: (
                            <MyEirViewAuthorisedUsers
                                setStep={setStep}
                                authUsers={authUsers}
                                setSelectedUser={setSelectedUser}
                            />
                        ),
                        add: (
                            <MyEirAddAuthorisedUsers
                                user={`User ${authUsers?.length + 1}`}
                                securityQuestions={securityQuestions}
                                setdisableCTA={setDisableCta1}
                            />
                        ),
                        delete: (
                            <MyEirDeleteAuthorisedUsers
                                deleteUser={selectedUser}
                                setdisableCTA={setDisableCta1}
                            />
                        ),
                        edit: (
                            <MyEirEditAuthorisedUsers
                                setStep={setStep}
                                authUsers={authUsers}
                                selectedUser={selectedUser}
                                securityQuestions={securityQuestions}
                                disableCTA={disableCta1}
                                setdisableCTA={setDisableCta1}
                            />
                        ),
                    }[step]
                }
            </CustomModal>
        </Box>
    );
}

export default MyEirAuthorisedUsersContainer;
