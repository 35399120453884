import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
    modalWrapper: {
        '& .modal-dialog': {
            margin: '0 auto ',
            maxWidth: '100vw',
            '@media(max-width: 599px)': {
                padding: '8px 8px 0px 8px',
            },
        },
        '& .modal-backdrop': {
            background: theme.palette.primary.gray550,
            backdropFilter: 'blur(15px)',
        },
        '& .modal-content': {
            maxWidth: '694px',

            width: '100%',
            // minHeight: '620px',
            border: 'none',
            padding: '2rem',
            margin: '0 auto',
            borderRadius: '10px',
            '@media(max-width: 599px)': {
                // width: '100vw',
                padding: '1rem',
                border: 0,
                borderRadius: '10px 10px 0px 0',
            },
        },
    },
    modalBoxStyles: {
        background: 'white',
        borderRadius: 10,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            minHeight: 'calc(100vh - 40px)',
        },
    },
    modalClass: {
        backdropFilter: 'blur(15px)',
    },
    headerModal: {
        '& .modal-header-back-icon': {
            display: 'flex',
            marginLeft: '-4px',
            color: theme.palette.primary.sunset,
            cursor: 'pointer',

            '@media(max-width: 599px)': {
                height: '20px',
                width: '20px',
                marginLeft: '-4px',
            },
        },

        '& .modal-header-text': {
            display: 'flex',
            marginTop: '19px',
            minHeight: '47px',
            fontFamily: theme.typography.h1.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '26px',
            color: theme.palette.primary.heather,

            position: 'relative',

            '@media(max-width: 599px)': {
                paddingLeft: '8px',
                marginTop: '14px',
                minHeight: '45px',
                fontSize: theme.typography.h4.fontSize,
            },

            '& .modal-header-line': {
                position: 'absolute',
                left: '0%',
                right: '0%',
                bottom: '3px',
                border: `1px solid ${theme.palette.primary.borderColor}`,
                borderTopWidth: '0px',

                '@media(max-width: 599px)': {
                    bottom: '0px',
                },
            },
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },

    imgText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '700',
        fontSize: theme.typography.subtitle1.fontSize,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },
    sadText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        // fontWeight: '600',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        paddingBottom: '35px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    sadImg: {
        margin: '0 auto',
        paddingTop: '35px',
        paddingBottom: '23px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            width: '77px',
            paddingBottom: '30px',
            paddingTop: '30px',
            justifyContent: 'center',
        },
    },
    cancelBtn: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        background: theme.palette.primary.sunset,
        width: '100%',
        maxWidth: '200px',
        borderRadius: '4100px',
        color: theme.palette.primary.white,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('md', 'sm')]: {
            maxWidth: '200px',
            width: '100%',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '264px',
            width: '100%',
            height: '41px',
            left: '.69%',
            right: '7.69%',
        },
    },

    cancelSuccessMain: {
        marginLeft: 'auto',
        marginRight: 'auto',

        '@media(max-width: 599px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
            height: '100%',
        },
    },
    cancelWrapper: {
        '& .modal-dialog': {
            margin: '0 auto ',
            maxWidth: '100vw',
            '@media(max-width: 599px)': {
                padding: '.5rem .5rem 0',
                height: '100%',
            },
        },
        '& .modal-content': {
            width: '570px',
            minHeight: '380px',
            margin: '0 auto',

            '@media(max-width: 599px)': {
                width: '312px',
                position: 'absolute',
                height: '320px',
                left: '44px',
                borderRadius: '10px',
            },
        },
    },
    cancelMainWrapper: {
        background: 'white',
        borderRadius: '10px',
    },
    modalRoot: {
        [theme.breakpoints.down('sm')]: {
            overflow: 'overlay',
        },
        '& .MuiBackdrop-root': {
            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(30px)',
                opacity: '1',
            },

            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },
        },
    },
    closeIcon: {
        color: theme.palette.primary.sunset,
        position: 'absolute',
        left: '57.89%',
        top: '-3.6%',
        bottom: '88.75%',
        right: '-26.67%',

        [theme.breakpoints.down('sm')]: {
            left: '79.89%',
            top: '.53%',
            bottom: '88.75%',
            right: '-4.67%',
        },
    },

    sadImg1: {
        margin: '0 auto',
        paddingTop: '30px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    calendarImg: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        width: '74px',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px',
            margin: '0 auto',
        },
    },
    cancelText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },
    cancelText1: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },

    imgText1: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '700',
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },

    sadText1: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        paddingTop: '13px',
        paddingBottom: '30px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },

    textContainer: {
        paddingTop: '25px',
        paddingBottom: '30px',
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        margin: '0 auto ',
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('sm')]: {
            lineHeight: '157%',
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: '600',
            margin: '0 auto ',
            textAlign: 'center',
            marginTop: '1rem',
            color: theme.palette.primary.heather,
        },
    },
    textContainer1: {
        lineHeight: '157%',
        fontWeight: '700',
        fontFamily: theme.typography.body2.fontFamily,
        paddingBottom: '60px',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        margin: '0 auto ',
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('sm')]: {
            lineHeight: '157%',
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '700',
            margin: '0 auto ',
            textAlign: 'center',
            paddingTop: '96px',
            paddingBottom: '76px',
            color: theme.palette.primary.heather,
        },
    },
    textContext: {
        color: theme.palette.primary.sunset,
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    popupFooter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            paddingLeft: '17px',
            paddingRight: '17px',
        },
    },
    cta: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        minWidth: '184px',
        [theme.breakpoints.down('sm')]: {
            height: '41px',
            left: '.69%',
            right: '7.69%',
            borderRadius: '4100px',
            maxWidth: '296px',
            width: '100%',
        },
    },
    cta1: {
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

        color: theme.palette.primary.white,
        borderRadius: '4rem',
        background: theme.palette.primary.sunset,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
    },
    mb: {
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
        },
    },
    MB: {
        marginBottom: '18px',
        '@media(max-width: 299px)': {
            marginBottom: '0px',
        },
        '@media(min-width:300px) and (max-width: 960px)': {
            marginBottom: '40px',
        },
    },
    cta1Disable: {
        '&:hover.Mui-disabled': {
            backgroundColor: theme.palette.primary.gray550,
        },
        '&.Mui-disabled': {
            // boxShadow:
            //     '0 1px 5px 0 rgba( 208, 208, 208, 1), 0 2px 2px 0 rgba( 208, 208, 208, 1), 0 3px 1px 0 rgba( 208, 208, 208, 1)',
            color: theme.palette.primary.gray550,
            borderRadius: '4rem',
            background: theme.palette.primary.gray550,
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary.gray550,
                borderRadius: '4rem',
                background: `${theme.palette.primary.gray550} !important`,
            },
        },
    },
    cta2: {
        color: theme.palette.primary.sunset,
        textAlign: 'center',
        fontSize: theme.typography.body1.fontSize,
        // marginTop: '12px',
    },

    popUpWrapper: {
        '& .modal-dialog': {
            margin: '0 auto ',
            maxWidth: '100vw',
            [theme.breakpoints.down('sm')]: {
                padding: '.5rem .5rem 0',
                height: '40%',
            },
        },
        '& .modal-backdrop': {
            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
                opacity: '1',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },
        },

        '& .modal-content': {
            width: '570px',
            right: '-11px',
            // minHeight: '380px',
            padding: '40px',
            margin: '0 auto',
            borderRadius: '10px',
            '@media(max-width: 299px)': {
                minHeight: '320px',
                // width: '90vw',
                width: '260px',
                padding: '0',
                right: '0px',
                border: 0,
                borderRadius: '10px 10px 10px 10px',
            },
            '@media(min-width:300px) and (max-width: 599px)': {
                minHeight: '320px',
                // width: '90vw',
                width: '312px',
                padding: '0',
                right: '0px',
                border: 0,
                borderRadius: '10px 10px 10px 10px',
            },
        },
    },

    closeIconBox: {
        height: '4vh',
        top: '12px',
        position: 'absolute',
        right: '5%',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '2vh',
            top: '13px',
            position: 'absolute',
            right: ' 0',
        },
    },

    crossCloseIcon: {
        color: theme.palette.primary.sunset,
        position: 'absolute',
        bottom: '88.75%',
        right: '3%',
        top: '12px',
        zIndex: 1,
        cursor: 'pointer',
    },

    apiError: {
        padding: '0px 16px',
        color: theme.palette.primary.error,
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        marginBottom: '20px',
    },
}));

export default useStyle;
