import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import CustomModal from '../../eir-porting/CustomModal/CustomModal';
import CustomUpdatedPopUpModal from '../../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import useStyles from './styles';
import OtpInput from 'react-otp-input';
import { getTvisPinVal, updateTvisPinVal, getTvisAccountNum } from './tvisPinValue.util';
import { useApolloClient } from '@apollo/client';
import success from '../../images/Large-icon-Success.png';
import failure from '../../images/sad.png';
import EirButton from '../../../theme/EirButton';

const TvPinContainer = ({ linkName, setIsTvPin, flag }) => {
    const classes = useStyles();
    const graphql = useApolloClient();
    const [openTvModal, setOpenTvModal] = useState(false);
    const [openCustomPopup, setOpenCustomPopup] = useState(false);
    const [pinVal, setPinVal] = useState('');
    const [accUser, setAccUser] = useState();
    const [isAccUser, setIsAccUser] = useState(false);
    const [newPinVal, setNewPinVal] = useState('');
    const [isError, setIsError] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });

    const handleOpenLink = () => {
        setOpenCustomPopup(false);
        setOpenTvModal(true);
        getPinVal();
    };

    const getAccountNum = async () => {
        try {
            const data = await getTvisAccountNum({
                graphql,
            });
            setAccUser(data.getTvisAccountNumber.user);
            setIsAccUser(true);
        } catch (error) {
            setIsAccUser(false);
            console.log('TVIS error', error);
        }
    };

    const getPinVal = async () => {
        try {
            const data = await getTvisPinVal({
                graphql,
                accountId: accUser
                    ? accUser
                    : parseInt(sessionStorage.getItem('LoggedinUserAccountID')),
            });
            setPinVal(data.getTvisPin.pin);
            setIsTvPin(true);
        } catch (error) {
            setIsTvPin(false);
            console.log('TVIS error', error);
        }
    };

    const updatePinVal = async () => {
        try {
            const data = await updateTvisPinVal({
                graphql,
                accountId: accUser
                    ? accUser
                    : parseInt(sessionStorage.getItem('LoggedinUserAccountID')),
                newPin: {
                    pin: newPinVal,
                },
            });
            console.log('TVIS status', data);
        } catch (error) {
            setIsError(true);
            console.log('TVIS error', error);
        }
    };

    const handleTVCancel = () => {
        setOpenTvModal(false);
    };
    const handleTogglePopup = () => {
        setOpenCustomPopup(!openCustomPopup);
    };

    const handleAltCta = () => {
        setOpenCustomPopup(false);
        getAccountNum();
        setNewPinVal('');
    };
    const handleToggleTVModal = () => {
        setOpenTvModal(!openTvModal);
    };

    const handleChangePin = val => {
        setNewPinVal(val);
    };

    const handleSave = () => {
        updatePinVal();
        setOpenTvModal(false);
        setOpenCustomPopup(true);
    };

    useEffect(() => {
        getAccountNum();
        getPinVal();
        setNewPinVal('');
    }, []);

    return (
        <Box id='myeir-tv-pin'>
            <Box>
                <Typography
                    component='span'
                    onClick={handleOpenLink}
                    className={
                        flag && flag === 'redesign' ? classes.redesignStyle : classes.linkStyles
                    }
                >
                    {linkName}
                </Typography>
            </Box>

            <CustomModal
                open={openTvModal}
                toggle={handleToggleTVModal}
                cta1={cta1}
                cta2={cta2}
                header={{
                    text: 'Manage my eir TV PIN',
                    arrowAction: handleToggleTVModal,
                    center: false,
                }}
            >
                <Box className={classes.tvPinContainer}>
                    <Box className={classes.tvText}> Current PIN</Box>
                    <Box className={classes.tvBox}>
                        <OtpInput inputStyle={classes.tvInput} value={pinVal} numInputs={4} />
                    </Box>
                    <Box className={classes.tvText}>New PIN</Box>
                    <Box className={classes.tvBox}>
                        <OtpInput
                            inputStyle={classes.tvInput}
                            value={newPinVal}
                            onChange={handleChangePin}
                            numInputs={4}
                            isInputNum={true}
                            focusStyle={classes.focusInput}
                        />
                    </Box>
                </Box>
                <Box className={classes.buttonBox}>
                    <EirButton
                        variants='primaryButtonMedium'
                        style={{ padding: '0.5rem 2.5rem' }}
                        onClick={handleSave}
                        disabled={newPinVal.length != 4}
                        // className={`${classes.simCta}  ${
                        //     newPinVal.length === 4 ? classes.simCta1 : classes.simCta1Disable
                        // } `}
                    >
                        Save
                    </EirButton>
                    <Button
                        onClick={handleTVCancel}
                        className={`${classes.simCta} ${classes.simCta2} `}
                    >
                        Cancel
                    </Button>
                </Box>
            </CustomModal>

            <Box id='tv-pin-success-popup'>
                <CustomUpdatedPopUpModal
                    open={openCustomPopup}
                    toggle={handleTogglePopup}
                    cta1={
                        isError
                            ? { text: '', action: () => {}, disable: false }
                            : { text: 'Close', action: handleAltCta, disable: false }
                    }
                    cta2={{ text: '', action: () => {} }}
                    closeIconAction={isError ? handleAltCta : ''}
                >
                    <Box className={classes.successPopup}>
                        <Box className={classes.successImg}>
                            {isError ? (
                                <Typography component='img' src={failure} alt='failure' />
                            ) : (
                                <Typography component='img' src={success} alt='success' />
                            )}
                        </Box>
                        {isError ? (
                            <Box>
                                <Typography className={classes.imgText}>
                                    Oops Something went wrong!
                                </Typography>
                                <Typography className={classes.sadText}>
                                    Please try again later.
                                </Typography>
                            </Box>
                        ) : (
                            <Typography className={classes.imgTextAdditional}>
                                Your PIN has been updated successfully!
                            </Typography>
                        )}
                    </Box>
                </CustomUpdatedPopUpModal>
            </Box>
        </Box>
    );
};

export default TvPinContainer;
