import React, { useEffect, useState, useContext } from 'react';
import { useMyPlanStyle } from '../css/useMyPlanStyle';
import Grid from '@material-ui/core/Grid';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useApolloClient } from '@apollo/client';
import { useWindowDimension } from '../../hooks/windowDimensions';
import broadbandImage from '../images/broadbandImage.png';
import { MobileContext } from '../global-context';
import Loading from '../../components/loading/Loading';
import mobileImage from '../images/mobileImage.png';
import tvImage from '../images/tvImage.png';
import {
    getOffer,
    getEligibility,
    getPinPuk,
    getApvTVServiceId,
    checkApvEligibility,
} from './myPlan.util';
import MyPlanServiceContents from './MyPlanServiceContents';
import { DOMAINS } from '../../components/myeirconstant/myEirConstant';

function MyEirPlanContainer() {
    const classes = useMyPlanStyle();
    const graphql = useApolloClient();
    const [width, height] = useWindowDimension();
    const [active, setActive] = useState(false);
    const { state, dispatch } = useContext(MobileContext);
    const [apvCustomerType, setApvCustomerType] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [seeMore, setSeeMore] = useState(3);
    const [isContractsError, setIsContractsError] = useState(false);
    const [apvOffer, setApvOffer] = useState('');
    const [apvOfferStatus, setApvOfferStatus] = useState('');
    /* const [dualPlayArr, setDualPlayArr] = useState([]);
    const [tvArr, setTvArr] = useState([]);
    const [mobileArr, setMobileArr] = useState([]);
    const [mbbArr, setMbbArr] = useState([]); */
    const [planArr, setPlanArr] = useState([]);
    const { MOBILE, IPTV } = DOMAINS;

    useEffect(() => {
        if (width < 600) {
            if (state.currentSlideIndex != 2) {
                setActive(true);
            } else {
                setActive(false);
            }
        }
    }, [state.currentSlideIndex]);

    useEffect(() => {
        getMyPlanDetails();
        getApvDetails();
    }, []);

    const dualplayObjects = [];
    let tvObjects = [];
    let mobileObjects = [];
    let mbbObjects = [];

    /**
     * update the last child of each category array with a boolean paramter
     * @param {*} array
     * @returns
     */
    function updateLastChild(array) {
        const newState = array.map((obj, index) => {
            if (index === array.length - 1) {
                return { ...obj, lastChild: true };
            }
            return obj;
        });
        return newState;
    }

    /**
     * get the my plan details uisng the subscription and services and calling respective API for each category
     */
    async function getMyPlanDetails() {
        try {
            const subscriptions = JSON.parse(
                sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
            );
            const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
            if (subscriptions != null && services != null) {
                for (let i = 0; i < subscriptions.length; i++) {
                    for (let j = 0; j < services.length; j++) {
                        if (services[j].status !== 'TERMINATED') {
                            if (subscriptions[i].serviceGroup === 'DUALPLAY') {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    const offerName = await getOffer(
                                        graphql,
                                        subscriptions[i].catalogOfferCode
                                    );
                                    dualplayObjects.push({
                                        image: broadbandImage,
                                        title: 'Broadband & Landline',
                                        count: dualplayObjects.length,
                                        serviceGroup: subscriptions[i].serviceGroup,
                                        serviceId: services[j].id,
                                        subId: services[j].subscriptionId,
                                        sipNumber: services[j].sipNumber,
                                        dsl: services[j].dsl,
                                        offerName: offerName,
                                    });
                                }
                            } else if (subscriptions[i].serviceGroup === IPTV) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    const offerName = await getOffer(
                                        graphql,
                                        subscriptions[i].catalogOfferCode
                                    );
                                    tvObjects.push({
                                        image: tvImage,
                                        title: 'TV',
                                        count: tvObjects.length,
                                        serviceGroup: subscriptions[i].serviceGroup,
                                        serviceId: services[j].id,
                                        subId: services[j].subscriptionId,
                                        broadbandDsl: services[j].bbDsl,
                                        offerName: offerName,
                                    });
                                }
                            } else if (
                                subscriptions[i].serviceGroup === MOBILE &&
                                subscriptions[i].type !== 'PREPAY'
                            ) {
                                if (
                                    subscriptions[i].id === services[j].subscriptionId &&
                                    services[j].msisdn !== null
                                ) {
                                    const offerName = await getOffer(
                                        graphql,
                                        subscriptions[i].catalogOfferCode
                                    );
                                    const eligibility = await getEligibility(
                                        graphql,
                                        services[j].subscriptionId
                                    );
                                    const pinPuk = await getPinPuk(graphql, services[j].id);
                                    mobileObjects.push({
                                        image: mobileImage,
                                        title: 'Mobile',
                                        count: mobileObjects.length,
                                        serviceGroup: subscriptions[i].serviceGroup,
                                        serviceId: services[j].id,
                                        subId: services[j].subscriptionId,
                                        msisdn: services[j].msisdn,
                                        offerName: offerName,
                                        eligibility: eligibility,
                                        pinPuk: pinPuk,
                                    });
                                }
                            } else if (subscriptions[i].serviceGroup === 'MBB') {
                                if (
                                    subscriptions[i].id === services[j].subscriptionId &&
                                    services[j].msisdn !== null
                                ) {
                                    const offerName = await getOffer(
                                        graphql,
                                        subscriptions[i].catalogOfferCode
                                    );
                                    const eligibility = await getEligibility(
                                        graphql,
                                        services[j].subscriptionId
                                    );
                                    const pinPuk = await getPinPuk(graphql, services[j].id);
                                    mbbObjects.push({
                                        image: mobileImage,
                                        title: 'MBB',
                                        count: mbbObjects.length,
                                        serviceGroup: subscriptions[i].serviceGroup,
                                        serviceId: services[j].id,
                                        subId: services[j].subscriptionId,
                                        msisdn: services[j].msisdn,
                                        offerName: offerName,
                                        eligibility: eligibility,
                                        pinPuk: pinPuk,
                                    });
                                }
                            }
                        }
                    }
                }
                if (
                    dualplayObjects.length > 0 ||
                    tvObjects.length > 0 ||
                    mobileObjects.length > 0 ||
                    mbbObjects.length > 0
                ) {
                    setIsLoading(false);
                    let filterDualPlay = dualplayObjects.reduce((a, itm) => {
                        var filteredObject = a.filter(e => e.subId == itm.subId);
                        if (filteredObject.length > 0) {
                            if (filteredObject[0].sipNumber != null) {
                                filteredObject[0].sipNumber += ' | ' + itm.dsl;
                            } else {
                                filteredObject[0].sipNumber =
                                    itm.sipNumber + ' | ' + filteredObject[0].dsl;
                            }
                        } else {
                            a.push(itm);
                        }
                        return a;
                    }, []);
                    filterDualPlay = updateLastChild(filterDualPlay);
                    tvObjects = updateLastChild(tvObjects);
                    mobileObjects = updateLastChild(mobileObjects);
                    mbbObjects = updateLastChild(mbbObjects);
                    let tempArr = [];
                    filterDualPlay.length > 0 ? (tempArr = tempArr.concat(filterDualPlay)) : '';
                    tvObjects.length > 0 ? (tempArr = tempArr.concat(tvObjects)) : '';
                    mobileObjects.length > 0 ? (tempArr = tempArr.concat(mobileObjects)) : '';
                    mbbObjects.length > 0 ? (tempArr = tempArr.concat(mbbObjects)) : '';
                    setPlanArr(tempArr);
                } else {
                    throw new Error('No services');
                }
            } else {
                throw new Error('No subscription or services');
            }
        } catch (error) {
            setIsContractsError(true);
            console.error('error in fetching plans ', error.message);
        }
    }

    async function getApvDetails() {
        try {
            const apvServiceId = await getApvTVServiceId(graphql);
            // getApvTVServiceId returns serviceId,hasTV,hasAPV,addonId
            if (apvServiceId.serviceId) {
                const apvEligibility = await checkApvEligibility(graphql, apvServiceId.serviceId);

                /** checkApvEligibility returns
                 * offerCode: eir-prime-video-full or eir-prime-video-tv based on broadband or tv service
                 * statusCode as 200 if eligible for APV, 201 if already using/active APV or 202 if not eligible for APV
                 * status: NO_ONGOING_REQUEST, ON_GOING_ADDITION, ON_GOING_TERMINATION this tells the status of APV addon/offer for that serviceid
                 */
                if (apvEligibility.statusCode === 200) {
                    setApvOffer(apvEligibility.offerCode);
                    setApvOfferStatus(apvEligibility.status);
                    apvServiceId.hasTV === true
                        ? setApvCustomerType('TV')
                        : setApvCustomerType('Broadband');
                } else if (apvEligibility.statusCode === 201) {
                    setApvOfferStatus(apvEligibility.status);
                    setApvCustomerType('active');
                } else if (apvEligibility.statusCode === 202) {
                    console.log(`not eligible for apv`);
                }
            } else {
                console.log(`not eligible for apv`);
            }
        } catch (error) {
            console.log(`getApvDetails error: ${error}`);
        }
    }

    return (
        <div className={classes.myPlanParent} id='my-plan-container'>
            <div className={active ? classes.myPlanWrapperInactive : classes.myPlanWrapper}>
                <div className={active ? classes.myPlanContainerInactive : classes.myPlanContainer}>
                    <Grid container rowSpacing={1}>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            sm={6}
                            direction='row'
                            className={classes.titleAndIcon}
                        >
                            <span className={classes.planIcon} />
                            <div className={classes.myPlanTitle}>My plan</div>
                        </Grid>
                        {/* {catalogOffer && temp.length != 0 && contracts != 0 ? */}
                        <Grid item xs={12}>
                            <div className={classes.myPlanModalBody}>
                                {/* account number text */}
                                <div className={classes.account}>
                                    <div className={classes.accountNumber}>
                                        Account Number: &nbsp;{' '}
                                        <span className={classes.accountNum}>
                                            {sessionStorage.getItem('LoggedinUserAccountNumber')}
                                        </span>
                                    </div>
                                </div>
                                {/* all offers */}
                                <div className={classes.mobile}>
                                    {isLoading ? (
                                        <div
                                            className='contracts-loader'
                                            style={{ justifyContent: 'center' }}
                                        >
                                            {isContractsError ? (
                                                <div className='contracts-content-loading'>
                                                    Something went wrong, please try again later.
                                                    <span
                                                        className='pinPuk-retry-loading'
                                                        style={{
                                                            cursor: 'pointer',
                                                            textDecoration: 'underline',
                                                        }}
                                                        onClick={() => {
                                                            setIsContractsError(false);
                                                            getMyPlanDetails();
                                                        }}
                                                    >
                                                        Retry?
                                                    </span>
                                                </div>
                                            ) : (
                                                <Loading className='contracts-loading-animation' />
                                            )}
                                        </div>
                                    ) : (
                                        planArr.length != 0 &&
                                        planArr.slice(0, seeMore).map((item, index) => {
                                            return (
                                                <div className={classes.wrapper}>
                                                    <MyPlanServiceContents
                                                        item={item}
                                                        index={index}
                                                        apvCustomerType={apvCustomerType}
                                                        apvOfferCode={apvOffer}
                                                        apvOfferStatus={apvOfferStatus}
                                                    />
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        </Grid>
                        {/* // : <p className={classes.errorMessage}> please try again later</p>} */}
                        {/* <div className={classes.seeMore}> */}
                        {/* {catalogOffer && msisdnAndOffer.length >= 2 && contracts != 0 ? */}
                        <Grid item xs={12}>
                            {seeMore == 3 && planArr.length > 3 ? (
                                <div
                                    className={classes.moreDetails}
                                    id='seeMore'
                                    onClick={() => {
                                        setSeeMore(planArr.length);
                                    }}
                                >
                                    <p>See more</p>
                                    <div>
                                        <ArrowDownward className={classes.DownIcon} />
                                    </div>
                                </div>
                            ) : (
                                ' '
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default MyEirPlanContainer;
