import CustomUpdatedPopUpModal from './CustomModal/CustomUpdatedPopUpModal';
import { Box, Typography, Button } from '@material-ui/core';
import sadsmily from '../images/sad.png';
import useStyle from './style.js';
import { useState } from 'react';
import React from 'react';
import EirButton from '../../theme/EirButton';

const MyEirErrorHandle = props => {
    const [openPopup, setOpenPopup] = useState(props.open);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const styles = useStyle();

    const handleCloseError = async () => {
        setOpenPopup(false);
        props.setOpen(false);
    };

    return (
        <Box className='errorHandle' maxWidth='md'>
            <CustomUpdatedPopUpModal
                cssId='myeir-modal-window-error'
                open={openPopup}
                cta1={cta1}
                cta2={cta2}
                toggle={handleCloseError}
            >
                <Typography className={styles.imgText}>Oops something went wrong!</Typography>
                <Box className={styles.imgcontent}>
                    <Box component='img' src={sadsmily} alt='sad-smily' className={styles.sadImg} />
                </Box>
                <Box style={{ textAlign: 'center' }}>
                    <Typography className={styles.sadText}>
                        {props.flag === 'register'
                            ? ' Please allow some time for email to be changed or try again later'
                            : 'Please try again later.'}
                    </Typography>
                    <EirButton
                        variants='primaryButtonMedium'
                        style={{ padding: '0.5rem 2rem' }}
                        // className={styles.cancelBtn}
                        onClick={handleCloseError}
                    >
                        Close
                    </EirButton>
                </Box>
            </CustomUpdatedPopUpModal>
        </Box>
    );
};

export default MyEirErrorHandle;
