import { makeStyles } from '@material-ui/core/styles';
export const useMyBillsStyle = makeStyles(theme => ({
    MyBillsWrapper: {
        // background: "grey",
        marginTop: '40px',
        marginBottom: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',

        '@media(max-width: 1019px)': {
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        '@media(max-width: 599px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
            // marginTop: "24px",
            marginTop: '6px',
            marginBottom: '24px',
        },

        '& .myBillsContainer': {
            maxWidth: '1144px',
            // background: "#FFFFFF",
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
            borderRadius: '10px',
            margin: 'auto',

            '@media(max-width: 1019px)': {
                maxWidth: '570px',
            },

            '@media(max-width: 599px)': {
                maxWidth: 'calc(312 / 360 * 100vw)',
            },
        },

        '& .bills-accordion': {
            borderRadius: '10px',
            background: theme.palette.primary.white,
        },

        '& .bills-header': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h3.fontSize,
            lineHeight: '38px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.25px',
            color: theme.palette.primary.heather,

            minHeight: '120px',
            paddingTop: '41px',
            alignItems: 'flex-start',
            // paddingTop: "38px",

            '@media(max-width: 1019px)': {
                paddingTop: '21.25px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '16px',
                fontSize: theme.typography.h4.fontSize,
                lineHeight: '32px',
            },
        },

        '& .bills-header-block': {
            display: 'flex',

            '@media(min-width: 1020px)': {
                paddingRight: '6px',
            },
        },

        '& .bills-header-block-img': {
            marginRight: '19.5px',

            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .myBills-expand-icon': {
            width: '36px',
            height: '36px',
            color: theme.palette.primary.sunset,
        },

        '& .bills__header-expandIcon': {
            margin: '0px',
            padding: '0px',

            '@media(max-width: 1019px)': {
                display: 'none',
            },

            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .bills-header.bills__header-expanded': {
            minHeight: '90px',

            '@media(max-width: 1019px)': {
                minHeight: '80px',
            },
            '@media(max-width: 599px)': {
                minHeight: '64px',
            },
        },

        '& .bills__header-content': {
            flexGrow: '0',
            margin: '0',
        },

        '& .see-current-usage': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: '32px',
            paddingRight: '37px',
            paddingTop: '14px',
            paddingBottom: '41px',

            '@media(max-width: 1019px)': {
                justifyContent: 'center',
                paddingTop: '10px',
                paddingBottom: '15px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '7px',
                paddingBottom: '19px',
                paddingLeft: '15px',
                paddingRight: '15px',
            },
        },

        '& .hide-current-usage-padding': {
            padding: '30px 0px 0px 0px',

            '@media(max-width: 1019px)': {
                padding: '0px',
            },
            '@media(max-width: 599px)': {
                padding: '0px',
            },
        },

        '& .current-usage-button': {
            width: '204px',
            border: '3px solid #D011C9',
            boxSizing: 'border-box',
            borderRadius: '4100px',

            paddingTop: '5px',
            paddingBottom: '5px',

            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            textAlign: 'center',
            color: '#470A68',

            cursor: 'pointer',

            '&:hover': {
                color: theme.palette.primary.white,
                background: theme.palette.primary.sunset,
            },
        },

        '& .bills-summary': {
            textAlign: 'left',
            alignItems: 'flex-end',
            // justifyContent: "space-between",

            marginLeft: '32px',
            marginRight: '37px',
            marginBottom: '1px',

            paddingTop: '23px',
            paddingBottom: '39px',
            paddingLeft: '32px',
            paddingRight: '47px',

            boxShadow: '0px 1px 0px #E0E0E0, 0px -1px 0px #E0E0E0',

            // '@media (min-width: 600px) and (max-width: 1019px)': {
            //     boxShadow: "0px 1px 0px #E0E0E0",
            // },

            '@media(max-width: 1019px)': {
                boxShadow: '0px 1px 0px #E0E0E0',

                flexDirection: 'column',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingTop: '15px',
                paddingBottom: '15px',
                marginLeft: '60px',
                marginRight: '60px',
            },
            '@media(max-width: 599px)': {
                // paddingLeft: "0.5px",
                // paddingRight: "0.5px",
                paddingTop: '8px',
                paddingBottom: '15px',
                marginLeft: '16px',
                marginRight: '16px',
                marginBottom: '2px',
            },
        },

        '& :not(.bills-summary ~ .bills-summary).bills-summary': {
            // background: "pink",
            '@media(max-width: 599px)': {
                boxShadow: '0px 1px 0px #E0E0E0, 0px -1px 0px #E0E0E0',
                paddingTop: '24px',
            },
        },

        '& .issuedOn': {
            width: 'calc(136 / 996 * 100%)',

            '@media(max-width: 1019px)': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',

                paddingBottom: '8px',
            },
            '@media(max-width: 599px)': {
                paddingBottom: '8px',
            },
        },

        '& .issuedOnProp, & .amountDueProp': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '25px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.heather,

            marginTop: '-4px',

            '@media(max-width: 1019px)': {
                marginTop: '0px',
            },
            '@media(max-width: 599px)': {},
        },

        '& .issuedOnValue': {
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '28px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.heather,

            paddingTop: '9px',

            '@media(max-width: 1019px)': {
                paddingTop: '0px',
                // fontSize: "16px",
                // lineHeight: "25px",
            },
            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '25px',
            },
        },

        '& .amountDue': {
            width: 'calc(170 / 996 * 100%)',
            marginLeft: 'calc(56 / 996 * 100%)',

            '@media(max-width: 1019px)': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '0px',
            },
        },

        '& .amountDueValue': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: theme.typography.h5.fontSize,
            lineHeight: '31px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.heather,

            paddingTop: '7px',
            marginBottom: '-1px',

            '@media(max-width: 1019px)': {
                paddingTop: '0px',
                marginBottom: '0px',

                // fontSize: "16px",
                // lineHeight: "25px",
            },
            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '25px',
            },
        },

        '& .dueMessage': {
            width: 'calc(303 / 996 * 100%)',
            marginLeft: 'calc(6 / 996 * 100%)',

            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '23px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.4px',
            color: theme.palette.primary.error,

            paddingBottom: '2px',

            '@media(max-width: 1019px)': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '0px',
                // lineHeight: "20px",
                // fontSize: "12px",
                paddingBottom: '16px',
            },
            '@media(max-width: 599px)': {
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '20px',
                paddingBottom: '16px',
            },
        },
        '& .paidMessage': {
            color: '#494B4C',
        },

        '& .billDetailsPDF': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            // marginLeft: "0px",
        },

        '& .billDetails': {
            width: 'calc(149 / 996 * 100%)',
            marginLeft: 'calc(53 / 996 * 100%)',

            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '28px',
            display: 'flex',
            alignItems: 'center',
            textDecorationLine: 'underline',
            color: theme.palette.primary.sunset,
            opacity: 0.9,

            cursor: 'pointer',

            '@media(max-width: 1019px)': {
                width: 'unset',
                marginLeft: '0px',

                // fontSize: "16px",
                // lineHeight: "24px",
                opacity: 1,
            },
            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                opacity: 1,
            },
        },

        '& .pdfDownload': {
            width: 'calc(52 / 996 * 100%)',
            marginLeft: 'calc(71 / 996 * 100%)',

            cursor: 'pointer',

            '@media(max-width: 1019px)': {
                width: 'unset',
                marginLeft: '0px',
            },

            '& .pdfDownloadImage': {
                display: 'block',
                // height: "25px",
            },
        },

        '& .see-more-bill': {
            paddingTop: '35.65px',
            paddingBottom: '52.35px',

            '@media(max-width: 1019px)': {
                paddingTop: '24px',
                paddingBottom: '40px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '14px',
                paddingBottom: '35px',
            },
        },

        '& .more-bill-button, & .less-bill-button': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '24px',
            textAlign: 'center',
            color: theme.palette.primary.sunset,
            display: 'flex',
            alignItems: 'center',
            margin: 'auto',
            cursor: 'pointer',

            '&:hover': {
                color: theme.palette.primary.sunset30,
            },

            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
            },
        },

        '& .mybills-loading-animation': {
            padding: '0px!important',
            '@media(max-width: 1019px)': {
                padding: '0px!important',
            },
            '@media(max-width: 599px)': {
                padding: '0px!important',
            },
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },
    CurrentUsageWrapper: {
        background: 'white',

        '& .currentUsagemodal': {
            // background: "rgba(51, 51, 51, 0.5)",
            // backdropFilter: "blur(4.5px)",

            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },
        },

        '& .currentUsagemain': {
            marginLeft: 'auto',
            marginRight: 'auto',
            // marginTop: "140px",

            padding: '0 30px',

            maxWidth: '820px',

            '@media(max-width: 1019px)': {
                padding: '0 99px',
                maxWidth: '858px',
            },
            '@media(max-width: 599px)': {
                marginTop: '8px',
                padding: '0 8px',
            },
        },

        '& .currentUsagecontent': {
            // padding: "0 30px",
            border: 'none',
        },

        '& .currentUsagebody': {
            // background: "orange",
            minHeight: '760px',
            padding: '24px 32px',

            '@media(max-width: 599px)': {
                padding: '16px 16px 16px 16px',
            },

            '& .currentUsage-header': {
                borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

                '& .currentUsage-backButtonWrapper': {
                    '& .currentUsage-backButton': {
                        color: theme.palette.primary.sunset,
                        marginLeft: '-4px',
                        // marginTop: "-13px",
                        display: 'block',
                        cursor: 'pointer',

                        '@media(max-width: 599px)': {
                            width: '20px',
                            height: '20px',
                            // marginLeft: "-4px",
                            // marginTop: "0px",
                        },
                    },
                },

                '& .currentUsage-titleUsage': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',

                    '@media(max-width: 599px)': {
                        marginTop: '15px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    },

                    '& .currentUsage-headerTitle': {
                        fontFamily: theme.typography.body3Medium.fontFamily,
                        fontStyle: 'normal',
                        // fontWeight: 700,
                        fontSize: theme.typography.h4.fontSize,
                        lineHeight: '26px',
                        color: theme.palette.primary.heather,

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.h4.fontSize,
                        },
                    },

                    '& .currentUsage-headerUsage': {
                        textAlign: 'right',

                        '& .currentUsage-headerUsageTotal': {
                            '& .currentUsage-headerUsageValue': {
                                fontFamily: theme.typography.body1.fontFamily,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: theme.typography.body1.fontSize,
                                lineHeight: '26px',
                                color: theme.palette.primary.heather,

                                '@media(max-width: 599px)': {
                                    fontSize: theme.typography.subtitle2.fontSize,
                                },
                            },
                            '& .currentUsage-headerUsageProperty': {
                                fontFamily: theme.typography.body1.fontFamily,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: theme.typography.body1.fontSize,
                                lineHeight: '26px',
                                color: theme.palette.primary.heather,

                                '@media(max-width: 599px)': {
                                    fontSize: theme.typography.subtitle2.fontSize,
                                },
                            },
                        },

                        '& .currentUsage-headerUsageCharge': {
                            fontFamily: theme.typography.body3Medium.fontFamily,
                            fontStyle: 'normal',
                            // fontWeight: 700,
                            fontSize: theme.typography.h4.fontSize,
                            lineHeight: '26px',
                            color: theme.palette.primary.heather,

                            '@media(max-width: 599px)': {
                                fontSize: theme.typography.h4.fontSize,
                            },
                        },

                        // '& .currentUsage-headerUsageTotal': {
                        //     fontFamily: "eir",
                        //     fontStyle: "normal",

                        //     color: "rgba(50, 52, 53, 0.87)",

                        //     display: "flex",
                        //     alignItems: "baseline",

                        //     '& .currentUsage-headerUsageValue': {
                        //         fontWeight: 700,
                        //         fontSize: "24px",
                        //         lineHeight: "26px",
                        //         '@media(max-width: 599px)': {
                        //             fontSize: "20px",
                        //         },
                        //     },
                        //     '& .currentUsage-headerUsageProperty': {
                        //         fontWeight: 400,
                        //         fontSize: "16px",
                        //         lineHeight: "26px",

                        //         '@media(max-width: 599px)': {
                        //             fontSize: "14px",
                        //         },
                        //     },
                        // },

                        // '& .currentUsage-headerUsageCharge': {
                        //     fontFamily: "eir",
                        //     fontStyle: "normal",
                        //     fontWeight: 400,
                        //     fontSize: "16px",
                        //     lineHeight: "26px",

                        //     color: "rgba(50, 52, 53, 0.87)",

                        //     '@media(max-width: 599px)': {
                        //         fontSize: "14px",
                        //     },
                        // },
                    },
                },

                '& .currentUsage-headerUnbilledAndAccounts': {
                    display: 'flex',
                    justifyContent: 'space-between',

                    '@media(max-width: 1019px)': {
                        flexDirection: 'column-reverse',
                    },
                    '@media(max-width: 599px)': {
                        flexDirection: 'column-reverse',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    },

                    '& .currentUsage-headerUnbilled': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '16px',
                        color: theme.palette.primary.heather,

                        display: 'flex',
                        alignItems: 'center',

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.inputLabel.fontSize,
                        },

                        '& .currentUsage-headerUnbilledPadding': {
                            '@media(max-width: 1019px)': {
                                paddingBottom: '9px',
                            },
                        },
                    },

                    '& .currentUsage-headerAccounts': {
                        position: 'relative',
                        marginTop: '12px',
                        marginBottom: '14px',

                        '& .currentUsage-headerAccountsFor': {
                            position: 'absolute',
                            width: '29px',
                            background: theme.palette.primary.white,

                            fontFamily: theme.typography.body2.fontFamily,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: theme.typography.inputLabel.fontSize,
                            lineHeight: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'right',
                            color: theme.palette.primary.sunset,

                            left: '12px',
                            top: '-7px',

                            zIndex: '1',
                        },

                        '& .currentUsage-headerAccountsDropdown': {
                            // width: "calc(344 / 696 * 100%)",
                            width: '344px',
                            // minHeight: "56px",
                            '@media(max-width: 599px)': {
                                width: '100%',
                            },

                            '& .currentUsage-dropdownSelect': {
                                paddingRight: '43px',
                            },
                            '& .currentUsage-dropdownSelect:focus': {
                                background: theme.palette.primary.white,
                                borderRadius: '4px',
                            },

                            '& .currentUsage-dropdownIcon': {
                                marginRight: '12px',
                            },

                            '& .currentUsage-dropdownIcon.currentUsage-disabled': {
                                display: 'none',
                            },
                        },
                    },
                },
            },

            '& .currentUsage-tabs': {
                '& .currentUsage-categorySelection': {
                    display: 'flex',
                    // justifyContent: "space-between",
                    padding: '20px 8px',
                    marginBottom: '0px',
                    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,

                    // '@media(max-width: 599px)': {
                    //     padding: "11px 8px 13px 8px",
                    //     '& :last-child': {
                    //         marginRight: "0px",
                    //     },
                    // },

                    '& .currentUsage-categories': {
                        fontFamily: theme.typography.subtitle1.fontFamily,
                        fontStyle: 'normal',
                        // fontWeight: 700,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '22px',
                        textTransform: 'uppercase',
                        color: theme.palette.primary.heather,
                        padding: '0px 2.5px',
                        cursor: 'pointer',

                        listStyleType: 'none',
                        marginRight: '50px',

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.inputLabel.fontSize,
                            lineHeight: '19px',
                            marginRight: '20px',
                            padding: '0px 2.9px',
                        },
                    },

                    '& .currentUsage-categorySelected': {
                        paddingBottom: '3px',
                        borderBottom: `2px solid ${theme.palette.primary.heather}`,
                        color: theme.palette.primary.heather,

                        '@media(max-width: 599px)': {
                            paddingBottom: '2px',
                        },
                    },

                    '& :last-child': {
                        marginRight: '0px',
                    },

                    '@media(max-width: 1019px)': {
                        // padding: "11px 8px 13px 8px",
                        overflowX: 'auto',
                        '& :last-child': {
                            marginRight: '0px',
                        },
                    },
                    '@media(max-width: 599px)': {
                        padding: '11px 8px 13px 8px',
                        '& :last-child': {
                            marginRight: '0px !important',
                        },
                    },
                },

                // '& .currentUsage-categorySelection:last-child': {
                //     marginRight: "0px",
                //     '@media(max-width: 599px)': {
                //         marginRight: "0px",
                //     },
                // },

                '& .currentUsage-tabHeadings': {
                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '25px',

                    alignItems: 'center',
                    color: theme.palette.primary.heather,

                    display: 'flex',

                    padding: '20px 4px 12px 8px',
                    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                },

                '& .currentUsage-tabData': {
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '25px',

                    alignItems: 'center',
                    color: theme.palette.primary.heather,

                    display: 'flex',

                    padding: '12px 4px 12px 8px',
                    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                },

                '& .currentUsage-tabData-empty': {
                    marginTop: '32px',
                    minHeight: '80px',
                    padding: '24px',
                    // textAlign: "center",
                    alignItems: 'center',
                    background: theme.palette.primary.backgroundColor,
                    // background: `linear-gradient(0deg, ${theme.palette.primary.backgroundColor}, rgba(255, 255, 255, 0.9)), #2196F3`,
                    borderRadius: '4px',

                    display: 'flex',

                    '@media(max-width: 599px)': {},

                    '& .currentUsage-tabData-emptyIcon': {
                        width: '32px',
                        height: '32px',

                        color: theme.palette.primary.sunset,
                    },

                    '& .currentUsage-tabData-emptyText': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '18px',
                        color: theme.palette.primary.heather,
                        // letterSpacing: "0.05px",

                        minHeight: '19px',
                        height: 'fit-content',
                        paddingLeft: '35px',

                        flexGrow: '1',

                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.subtitle2.fontSize,
                        },
                    },
                },

                '& .currentUsage-tabHeadingsAndtabData': {
                    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                    paddingTop: '22px',
                    paddingBottom: '17px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    '@media(max-width: 599px)': {},

                    '& .currentUsage-mobDateAndTime,& .currentUsage-mobDestination,& .currentUsage-mobDuration,& .currentUsage-mobCost': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '2px',
                        '@media(max-width: 599px)': {},
                    },

                    '& .currentUsage-tabHeadingsAndtabData-heading': {
                        fontFamily: theme.typography.subtitle1.fontFamily,
                        fontStyle: 'normal',
                        // fontWeight: 700,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '22px',

                        alignItems: 'center',
                        color: theme.palette.primary.heather,

                        '@media(min-width: 600px)': {
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '25px',
                        },
                    },

                    '& .currentUsage-tabHeadingsAndtabData-data': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '22px',

                        alignItems: 'center',
                        color: theme.palette.primary.heather,

                        '@media(min-width: 600px)': {
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '25px',
                        },
                    },

                    '& .currentUsage-mobDestination': {
                        '& .currentUsage-tabHeadingsAndtabData-data': {
                            fontFamily: theme.typography.subtitle1.fontFamily,
                            // fontWeight: 700,
                        },
                    },
                },

                '& .currentUsage-dateTime': {
                    width: 'calc(196.5 / 684 * 100%)',
                },
                '& .currentUsage-destination': {
                    width: 'calc(196.5 / 684 * 100%)',
                    marginLeft: 'calc(10/ 684 * 100%)',

                    fontFamily: theme.typography.subtitle1.fontFamily,
                    // fontWeight: 700,
                },
                '& .currentUsage-duration': {
                    width: 'calc(189 / 684 * 100%)',
                    marginLeft: 'calc(10/ 684 * 100%)',
                },
                '& .currentUsage-cost': {
                    width: 'calc(72 / 684 * 100%)',
                    marginLeft: 'calc(10/ 684 * 100%)',
                },

                '& .currentUsage-tabHeadingsAndData': {
                    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
                    paddingTop: '45px',
                    paddingBottom: '18px',
                    '@media(max-width: 599px)': {
                        paddingTop: '21px',
                        paddingBottom: '19px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    },

                    '& .currentUsage-regularData,& .currentUsage-roamingData,& .currentUsage-dataCost': {
                        display: 'flex',
                        // justifyContent: "space-between",
                        marginBottom: '16px',
                        '@media(max-width: 1019px)': {
                            marginBottom: '2px',
                            justifyContent: 'space-between',
                        },
                        '@media(max-width: 599px)': {
                            marginBottom: '2px',
                            justifyContent: 'space-between',
                        },
                    },

                    '& .currentUsage-tabHeadingsAndData-heading': {
                        fontFamily: theme.typography.subtitle1.fontFamily,
                        fontStyle: 'normal',
                        // fontWeight: 700,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '25px',

                        alignItems: 'center',
                        color: theme.palette.primary.heather,

                        width: 'calc(196.5 / 696 * 100%)',
                        marginLeft: 'calc(24/ 684 * 100%)',

                        '@media(max-width: 1019px)': {
                            width: 'unset',
                            marginLeft: 'unset',
                        },
                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.subtitle2.fontSize,
                            lineHeight: '22px',
                            width: 'unset',
                            marginLeft: 'unset',
                        },
                    },

                    '& .currentUsage-tabHeadingsAndData-data': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '25px',

                        alignItems: 'center',
                        color: theme.palette.primary.heather,

                        width: 'calc(189 / 696 * 100%)',
                        marginLeft: 'calc(171.5/ 684 * 100%)',

                        '@media(max-width: 1019px)': {
                            width: 'unset',
                            marginLeft: 'unset',
                        },
                        '@media(max-width: 599px)': {
                            fontSize: theme.typography.subtitle2.fontSize,
                            lineHeight: '22px',
                            width: 'unset',
                            marginLeft: 'unset',
                        },
                    },

                    '& .currentUsage-regularData': {
                        '& .currentUsage-tabHeadingsAndData-data': {
                            fontFamily: theme.typography.subtitle1.fontFamily,
                            // fontWeight: 700,
                        },
                    },
                },

                '& .currentUsage-historicalUsageData': {
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '25px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                    textDecorationLine: 'underline',
                    color: theme.palette.primary.sunset,
                    opacity: 0.9,

                    position: 'absolute',
                    bottom: '60px',
                    left: '0',
                    right: '0',
                    justifyContent: 'center',
                    width: '100%',

                    '@media(max-width: 599px)': {
                        fontSize: theme.typography.inputLabel.fontSize,
                        lineHeight: '19px',
                        bottom: '24px',
                    },
                },

                '& :not(.currentUsage-tabData ~ .currentUsage-tabData).currentUsage-tabData': {
                    padding: '15px 4px 12px 8px',
                },
            },
        },
    },
    MenuItemsWrapper: {
        // background:"aqua",
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        // letterSpacing: "0.15px",
        color: theme.palette.primary.heather,

        '&:hover': {
            background: '#F3F5F9',
        },
    },
    DisplayNone: {
        display: 'none',
    },
}));
