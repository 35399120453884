import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { styles } from '../styles';

const DeviceDetailsDate = ({ deviceActivationDate, deviceLastUsedDate }) => {
    const classes = styles();
    const convertDate = date => {
        try {
            if (date) {
                return `${date.substr(8, 2)}/${date.substr(5, 2)}/${date.substr(2, 2)}`;
            }
            return '';
        } catch (error) {
            return '';
        }
    };

    return (
        <>
            <Box className={classes.deviceManagementActiveDeviceDetails}>
                <Typography className={classes.deviceManagementActiveDeviceDetailsType}>
                    Activated:
                </Typography>
                <Typography className={classes.deviceManagementActiveDeviceDetailsValue}>
                    {convertDate(deviceActivationDate)}
                </Typography>
            </Box>
            {deviceLastUsedDate && (
                <Box className={classes.deviceManagementActiveDeviceDetails}>
                    <Typography className={classes.deviceManagementActiveDeviceDetailsType}>
                        Last used:
                    </Typography>
                    <Typography className={classes.deviceManagementActiveDeviceDetailsValue}>
                        {convertDate(deviceLastUsedDate)}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default DeviceDetailsDate;
