import { useApolloClient } from '@apollo/client';
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Loading from '../../components/loading/Loading';
import ENDPOINT from '../graphql/GraphQLConstants';
import MasterCardLogo from '../images/master-card.png';
import VisaCardLogo from '../images/visa-card.png';
import DirectDebitLogo from '../images/Vector.png';
import PlusLogo from '../images/plus.png';
import { usePaymentCardMethodStyles } from '../css/usePaymentCardMethodStyles';
import PaymentCardMethodHeader from './PaymentCardMethodHeader';
import InvalidIcon from '../images/invalid.png';
import ChooseDefaultMethod from './ChooseDefaultMethod';
import SwitchDefaultCardPopUp from './SwitchDefaultCardPopUp';
import EditExpiryPaymentCard from './EditExpiryPaymentCard';
import DeletePaymentmethod from './DeletePaymentMethod';
import { getPaymentMethod } from './payment.util';
import EirButton from '../../theme/EirButton';

const PaymentCardDetails = props => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({ loading: false });
    const [isOpen2, setIsOpen2] = useState(false);
    const classes = usePaymentCardMethodStyles();
    const [value, setValue] = useState('');
    const [isEditable, setIsEditable] = useState(true);
    const [isMakeDefault, setIsMakeDefault] = useState(false);
    const [cards, setCards] = useState([]);
    const [isToggled, setIsToggled] = useState(false);
    const [isToggledDelete, setIsToggledDelete] = useState(false);
    const [isEditToggled, setIsEditToggled] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isDefaultCard, setIsDefaultCard] = useState([]);
    const [isDeleteCard, setIsDeleteCard] = useState([]);
    const [isNoCard, setIsNoCard] = useState(true);
    const [isCorrectCard, setIsCorrectCard] = useState(false);
    const [formattedPaymentTypeName, setFormattedPaymentTypeName] = useState('');
    const graphql = useApolloClient();

    const handleChange = e => {
        setValue(e.target.value);
        let editable = cards.filter(
            card =>
                card.default == false &&
                card.paymentMethodType == 'DIRECT_DEBIT' &&
                card.paymentMethodId == e.target.value
        ).length;
        editable > 0 ? setIsEditable(false) : setIsEditable(true);

        let irishSepaIban = cards.filter(card => card.paymentMethodType == 'DIRECT_DEBIT').length;
        irishSepaIban > 0 ? props.handleIbanText(true) : props.handleIbanText(false);

        let otherSectionEditable = cards.filter(
            card => card.default == false && card.paymentMethodId == e.target.value
        ).length;
        otherSectionEditable > 0 ? setIsCorrectCard(true) : setIsCorrectCard(false);

        let makeDefault = cards.filter(
            card => card.default == false && card.paymentMethodId == e.target.value
        ).length;
        makeDefault > 0 ? setIsMakeDefault(true) : setIsMakeDefault(false);

        let defaultCardSelected = cards.filter(card => card.paymentMethodId == e.target.value);
        setIsDefaultCard(defaultCardSelected[0]);

        let deleteCard = cards.filter(card => card.paymentMethodId == e.target.value);
        setIsDeleteCard(deleteCard[0]);
        let defaultSectionDelete = cards.filter(
            card => card.default == true && card.paymentMethodId == e.target.value
        ).length;
        defaultSectionDelete > 0 ? setIsNoCard(true) : setIsNoCard(false);
    };

    const toggleDefault = () => {
        setIsToggled(!isToggled);
    };
    const toggleDeleteMethod = () => {
        setIsToggledDelete(!isToggledDelete);
    };
    const toggleEdit = () => {
        setIsEditToggled(!isEditToggled);
    };

    const toggle = () => {
        props.toggle2();
    };
    const handleBack = () => {
        toggle();
    };

    // const handleNextClick =()=>{
    //     props.handleNext();
    // }

    const handleClose = () => {
        // window.location.href='/';
        props.handleBack();
    };

    const handleEdit = () => {
        console.log('handleedit');
    };

    const handleMakeDefault = () => {
        //    props.step(4);
        setIsToggled(!isToggled);
    };

    const handleDeleteClick = () => {
        setIsToggledDelete(!isToggledDelete);
    };

    const handleNoCard = () => {
        console.log('Default card cannot be deleted!');
    };
    const handleNonCard = () => {
        console.log('wrong section edit option is clicked!', isCorrectCard);
    };
    const handleEditNonCard = () => {
        console.log('others card cannot be edited in deafult section!');
    };

    const handleEditClick = () => {
        setIsEditToggled(!isEditToggled);
    };

    const toggle2 = () => {
        setIsOpen2(!isOpen2);
    };

    const handleAddDefaultMethod = () => {
        // toggle2();
        props.handleNext();
    };

    const getPaymentMethods = async () => {
        await getPaymentMethod({
            graphql,
            setLoading,
            setCards,
            setValue,
            setIsDefaultCard,
            props,
            setErrors,
        });
    };

    useEffect(() => {
        getPaymentMethods();
        props.setHeader('Payment details');
        console.log('cards total:', cards);
        setIsCorrectCard(false);
    }, [isUpdated]);

    return (
        <React.Fragment>
            {/* <Modal
                modalClassName={classes.modalClassName}
                wrapClassName={classes.modalWrapper}
                isOpen={props.isOpen2}
                toggle={props.toggle2}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <PaymentCardMethodHeader
                        paymentHeaderText='Payment details'
                        handleBack={handleBack}
                        center={false}
                    /> */}

            <ModalBody className={classes.paymentMethod}>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {errors.loading ? (
                            <p>Something went wrong </p>
                        ) : (
                            <>
                                <div
                                    className={`payment-method-cards-container`}
                                    id='payment-card-details'
                                >
                                    <div className={`payment-method-cards-header `}>
                                        <h3>Default method</h3>
                                        <h4
                                            onClick={
                                                isCorrectCard ? handleNonCard : handleEditClick
                                            }
                                        >
                                            {cards.filter(y => y.default == true)[0]
                                                ?.paymentMethodType === 'DIRECT_DEBIT' ||
                                            cards.filter(y => y.default == true)[0]
                                                ?.paymentMethodType === 'MANUAL' ||
                                            cards.filter(y => y.default == true)[0]
                                                ?.paymentMethodType === 'CASH' ? (
                                                ''
                                            ) : (
                                                <a
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Edit
                                                </a>
                                            )}
                                        </h4>
                                    </div>

                                    <div className={`payment-method-cards-list `}>
                                        {cards && cards.length !== 0 ? (
                                            <>
                                                <FormControl component={'fieldset'}>
                                                    <RadioGroup
                                                        aria-label='payment-card'
                                                        name='card'
                                                        // value={value}
                                                        onChange={handleChange}
                                                        defaultValue={`${
                                                            cards.filter(y => y.default == true)[0]
                                                                .paymentMethodId
                                                        }`}
                                                    >
                                                        {cards
                                                            .filter(y => y.default == true)
                                                            .map((card, i) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <RadioPaymentCardBlock
                                                                            card={card}
                                                                            classes={
                                                                                value ==
                                                                                `${card.paymentMethodId}`
                                                                                    ? 'selected'
                                                                                    : ''
                                                                            }
                                                                            radio={true}
                                                                        />
                                                                    }
                                                                    value={`${card.paymentMethodId}`}
                                                                />
                                                            ))}
                                                        <div className='d-flex'>
                                                            <h3
                                                                className={`payment-method-cards-add `}
                                                            >
                                                                <a onClick={handleAddDefaultMethod}>
                                                                    Add a new default method
                                                                </a>
                                                            </h3>
                                                            <a onClick={handleAddDefaultMethod}>
                                                                <span
                                                                    style={{
                                                                        marginLeft: '11px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    <img src={PlusLogo} />
                                                                </span>
                                                            </a>
                                                        </div>
                                                        {cards.filter(
                                                            card =>
                                                                card.paymentMethodType !=
                                                                    'MANUAL' ||
                                                                card.paymentMethodType != 'CASH'
                                                        ).length > 1 ? (
                                                            <>
                                                                <div
                                                                    className={`payment-method-cards-header `}
                                                                >
                                                                    <div>
                                                                        <h3>Other</h3>
                                                                    </div>

                                                                    <div
                                                                        style={{ display: 'flex' }}
                                                                    >
                                                                        <h4
                                                                            onClick={
                                                                                isMakeDefault
                                                                                    ? handleMakeDefault
                                                                                    : ''
                                                                            }
                                                                            style={
                                                                                isMakeDefault
                                                                                    ? {
                                                                                          margin:
                                                                                              '0 7px',
                                                                                          cursor:
                                                                                              'pointer',
                                                                                      }
                                                                                    : {
                                                                                          margin:
                                                                                              '0 7px',
                                                                                      }
                                                                            }
                                                                        >
                                                                            <a>Make default</a>
                                                                        </h4>
                                                                        <span>|</span>

                                                                        {isEditable ? (
                                                                            <>
                                                                                <h4
                                                                                    onClick={
                                                                                        isCorrectCard
                                                                                            ? handleEditClick
                                                                                            : handleNonCard
                                                                                    }
                                                                                    style={{
                                                                                        margin:
                                                                                            '0 7px',
                                                                                        cursor:
                                                                                            'pointer',
                                                                                    }}
                                                                                >
                                                                                    <a>Edit</a>
                                                                                </h4>
                                                                                <span>|</span>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                        {/* <h4 onClick={handleEdit} style={{margin:'0 7px'}}><a>Edit</a></h4>
                                                        <span>|</span> */}
                                                                        <h4
                                                                            onClick={
                                                                                isNoCard
                                                                                    ? handleNoCard
                                                                                    : handleDeleteClick
                                                                            }
                                                                            style={{
                                                                                margin: '0 7px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            <a>Delete</a>
                                                                        </h4>
                                                                    </div>
                                                                </div>

                                                                {cards
                                                                    .filter(y => y.default == false)
                                                                    .map((card, i) =>
                                                                        card.paymentMethodType ==
                                                                            'MANUAL' ||
                                                                        card.paymentMethodType ==
                                                                            'CASH' ? (
                                                                            ''
                                                                        ) : (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <RadioPaymentCardBlock
                                                                                        card={card}
                                                                                        classes={
                                                                                            value ==
                                                                                            `${card.paymentMethodId}`
                                                                                                ? 'selected'
                                                                                                : ''
                                                                                        }
                                                                                        radio={true}
                                                                                    />
                                                                                }
                                                                                value={`${card.paymentMethodId}`}
                                                                            />
                                                                        )
                                                                    )}
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </>
                                        ) : (
                                            'No saved card methods'
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className='payment-body-footer'>
                            <EirButton
                                // className={
                                //     'payment-body-footer-cta primary-cta' +
                                //     (true ? ' cta-enabled' : ' cta-diabled')
                                // }
                                style={{ padding: '0.5rem 1.8rem' }}
                                disabled={!true}
                                variants='primaryButtonMedium'
                                onClick={handleClose}
                            >
                                Close
                            </EirButton>
                        </div>

                        {isDefaultCard != undefined ? (
                            <SwitchDefaultCardPopUp
                                headertext={`Switch default method`}
                                toggle={toggleDefault}
                                isOpen={isToggled}
                                center={false}
                                resetStep={() => props.resetStep()}
                                defaultCard={isDefaultCard}
                                updateCard={setIsUpdated}
                                isUpdated={isUpdated}
                                contentText={`Are you sure you want to make this your default payment method?`}
                            />
                        ) : (
                            'default card is undefined'
                        )}

                        {isDefaultCard != undefined ? (
                            <DeletePaymentmethod
                                headertext={`Delete payment method`}
                                toggle={toggleDeleteMethod}
                                isOpen={isToggledDelete}
                                center={false}
                                resetStep={() => props.resetStep()}
                                defaultCard={isDeleteCard}
                                updateCard={setIsUpdated}
                                isUpdated={isUpdated}
                                contentText={`Are you sure you want to delete this payment method?`}
                            />
                        ) : (
                            'default card is undefined'
                        )}

                        <EditExpiryPaymentCard
                            headertext={`Edit expiry`}
                            toggle={toggleEdit}
                            isOpen={isEditToggled}
                            updateCard={setIsUpdated}
                            isUpdated={isUpdated}
                            center={false}
                            // step={()=>props.handleNext()}
                            resetStep={() => props.resetStep()}
                            defaultCard={isDefaultCard}
                        />
                    </>
                )}
            </ModalBody>
            {/* <ChooseDefaultMethod toggle2={toggle2} isOpen2={isOpen2} /> */}
        </React.Fragment>
    );
};

const RadioPaymentCardBlock = props => {
    const { card, radio, classes } = props;
    const paymentMethodType = card.paymentMethodType;

    let formattedCardName =
        paymentMethodType === 'DIRECT_DEBIT' || paymentMethodType === 'CREDIT_CARD'
            ? paymentMethodType.replace(/_/g, ' ').toLowerCase()
            : paymentMethodType;
    formattedCardName = formattedCardName.charAt(0).toUpperCase() + formattedCardName.slice(1);

    // console.log("Cards", card);

    const valid = card.status == 'VALID' ? true : false;

    const today = new Date();
    const toadyMonth = today.getMonth() + 1;
    const todayYear = today.getFullYear();

    const cardYear = card.expirationDate == null ? null : card.expirationDate.substr(0, 4);
    const cardMonth = card.expirationDate == null ? null : card.expirationDate.substr(5, 2);

    const exp =
        card.expirationDate == null
            ? null
            : todayYear > cardYear || (todayYear == cardYear && toadyMonth >= cardMonth)
            ? 'Invalid'
            : 'valid';

    const selected = true;
    return (
        <div className={'radio-payment-card-block ' + classes + (!valid ? ' expired' : '')}>
            {radio && <Radio value={`${card.paymentMethodId}`} />}
            {card.paymentMethodType == 'MANUAL' || card.paymentMethodType == 'CASH' ? (
                <div className='card-manual'>Manual</div>
            ) : (
                <>
                    {/* icon */}
                    <div className='card-icon'>
                        <img
                            src={
                                card.type == 'RECURRENT'
                                    ? DirectDebitLogo
                                    : card.type == 'VISA'
                                    ? VisaCardLogo
                                    : MasterCardLogo
                            }
                        />
                    </div>
                    {/* info */}
                    <div className='card-info'>
                        {/* card number */}
                        {card.paymentMethodType === 'DIRECT_DEBIT' ? (
                            <h4 className='card-number'>{card.iban.replace(/X/g, '*')}</h4>
                        ) : card.partialDigits == null ? (
                            <h4 className='card-number'>
                                <span>
                                    &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;
                                    &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;
                                </span>{' '}
                            </h4>
                        ) : (
                            <h4 className='card-number'>{card.partialDigits.replace(/x/g, '*')}</h4>
                        )}
                        {/* messages */}
                        {/* {valid ? (
                            <p className={'message ' + (card.default == true ? 'default' : '')}>
                                
                                {card.default == true ? `${formattedCardName}` : card.type == "RECURRENT" ? 'Previously saved IBAN' : 'Previously saved card'}
                            </p>
                        ) : card.expirationDate == null ? (
                            <p className='card-expired-message'>Expired</p>
                        ) : (
                            <p className='card-expired-message'>
                                Expired
                            </p> : (
                                <p className='card-expired-message'>
                                    Expired{' '}
                                    {`${card.expirationDate.substr(5, 2)}/${card.expirationDate.substr(2, 2)}`}
                                </p>
                            ))} */}

                        {exp == 'valid' ? (
                            <p className={'message ' + (card.default == true ? 'default' : '')}>
                                {/* {card.default == true ? `${formattedCardName}` : selected ? 'Previously saved IBAN' : 'My prefered method'} */}
                                {card.default == true
                                    ? `${formattedCardName}`
                                    : card.type == 'RECURRENT'
                                    ? 'Previously saved IBAN'
                                    : 'Previously saved card'}
                            </p>
                        ) : exp == null ? (
                            card.type == 'RECURRENT' ? (
                                <p className={'message ' + (card.default == true ? 'default' : '')}>
                                    Previously saved IBAN
                                </p>
                            ) : (
                                <p className='card-expired-message'>Expired</p>
                            )
                        ) : (
                            <p className='card-expired-message'>
                                Expired{' '}
                                {`${card.expirationDate.substr(5, 2)}/${card.expirationDate.substr(
                                    2,
                                    2
                                )}`}
                            </p>
                        )}
                    </div>
                    {/* {valid ? '' :
                        <div className='card-invalid-icon'>
                            <img className='card-invalid-icon-img' src={InvalidIcon} />
                        </div>} */}

                    {exp == 'valid' ? (
                        ''
                    ) : card.type == 'RECURRENT' ? (
                        ''
                    ) : (
                        <div className='card-invalid-icon'>
                            <img className='card-invalid-icon-img' src={InvalidIcon} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default PaymentCardDetails;
