export const DATE_FORMAT = 'DD/MM/YYYY';
export const SECURITY_QUESTIONS_URL = 'http://localhost:8089/security_question/';
export const EIRCODE_FINDER_URL = 'https://finder.eircode.ie/#/';
export const ADDRESS_FINDER_URL =
    'https://eir-address-finder-backend-webservice.e2e.ion.comhar.local/public/address';
export const MARKETING_PREFERENCES_NOTE =
    'Subject to preferences you express below and the eir privacy policy, eir may keep you informed about existing and future offers,products and services of eir Group Companies; and third parties both during and after termination of our agreement.';
export const MARKETING_PREFERENCES_NOTE_PREPAY = 'From time to time we’d like to contact you to keep you updated on our promotions, products, services and market research. Please let us know how you wish to be contacted.';
export const EIRCODE_LOGO_IMAGE_PATH =
    '/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/eircode.png';
export const TERMS_AND_CONDITIONS_LINK =
    'https://www.eir.ie/opencms/export/sites/default/.content/pdf/terms/Terms_and_Conditions_of_the_eir_Mobile_Service.pdf';
const EXDIRECTORY = 'Ex-Directory – Not available for Directory Enquiries and not in the Phonebook';
const LISTED = 'Listed – Available for Directory Enquiries and in the Phonebook';
const UNLISTED = 'Unlisted – Available for Directory Enquiries but not in the Phonebook';

export const DIRECTORY_PREFERENCES = {
    EXDIRECTORY,
    LISTED,
    UNLISTED,
};
export const MARKETING_PREFERENCES = [
    {
        name: 'As an existing eir customer',
        permission: 'active',
        enabled: false,
        preferenceList: [
            {
                enabled: false,
                name: 'Email',
                permission: 'ALLOW_EMAIL_CONTACT',
            },
            {
                enabled: false,
                name: 'SMS',
                permission: 'ALLOW_SMS_CONTACT',
            },
            {
                enabled: false,
                name: 'Phone',
                permission: 'ALLOW_PHONE_CONTACT',
            },
            {
                enabled: false,
                name: 'Door to door',
                permission: 'D2D',
            },

            {
                enabled: false,
                name: 'Direct mail',
                permission: 'ALLOW_DIRECT_MAIL_CONTACT',
            },
        ],
    },
    {
        name: 'No longer an eir customer',
        permission: 'inactive',
        enabled: false,
        preferenceList: [
            {
                enabled: false,
                name: 'Email',
                permission: 'ALLOW_EMAIL_CONTACT',
            },
            {
                enabled: false,
                name: 'SMS',
                permission: 'ALLOW_SMS_CONTACT',
            },
            {
                enabled: false,
                name: 'Phone',
                permission: 'ALLOW_PHONE_CONTACT',
            },
            {
                enabled: false,
                name: 'Door to door',
                permission: 'D2D',
            },

            {
                enabled: false,
                name: 'Direct mail',
                permission: 'ALLOW_DIRECT_MAIL_CONTACT',
            },
        ],
    },
    {
        name: '3rd party',
        permission: '3P',
        enabled: false,
        tooltip:
            'From time to time our partners might send you details about their products or services, are you ok with this?',
        preferenceList: [],
    },
];

export const SECURITY_QUESTIONS = {
    securityQuestions: [
        {
            code: 'MEMORABLE_DATE',
            question: 'Memorable date',
        },
        {
            code: 'MEMORABLE_WORD',
            question: 'Memorable word',
        },
    ],
};
