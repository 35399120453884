import React, {
    createContext,
    Fragment,
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard, Controller } from 'swiper';
import { useWindowDimension } from '../hooks/windowDimensions';

import HeaderContainer from '../sharedcomponents/HeaderContainer';
import FooterContainer from '../sharedcomponents/FooterContainer';
import MyBalance from './my-balance/MyBalance';
import MyEirBillContainer from './my-bills/MyEirBillContainer';
import MyPaymentContainer from './my-payment/MyPaymentContainer';
import MyEirAccountContainer from './my-account/MyEirAccountContainer';
import FAQ from './FAQ';
import { initialState, mobileReducer, MobileContext } from './global-context';
import MyEirPlanContainer from './my-plan/MyEirPlanContainer';
import MyEirRTMBanner from './MyEirRTMBanner';
import PaymentFlowModal from './payment-flow/PaymentFlowModal';
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard, Controller]);

const DesktopView = ({ expand }) => {
    return (
        <Fragment>
            <MyBalance expandOnNavClick={expand.balance} enablePayment={true} />
            <MyEirBillContainer expandOnNavClick={expand.bill} />
            <MyPaymentContainer expandOnNavClick={expand.payment} />
            <MyEirAccountContainer expandOnNavClick={expand.account} />
            <MyEirRTMBanner loginSuccess={true} />
            <MyEirPlanContainer />
            {/* based on productType either show AmazonPrimeVideo link, PinAndPuk, keep my old number and change my eir TV pin */}
            {/* <MyEirCreatePassword /> */}
            {/* rtm - banner */}
            {/* myPlan - Mydevice */}
            <FAQ />
        </Fragment>
    );
};

const MobileView = () => {
    return [
        {
            top: <MyEirAccountContainer />,
            bottom: [],
        },
        {
            top: <MyBalance enablePayment={true} />,
            bottom: [
                <MyEirBillContainer />,
                <MyPaymentContainer />,
                <MyEirRTMBanner loginSuccess={true} />,
            ],
        },
        {
            top: <MyEirPlanContainer />,
            bottom: [],
        },
    ];
};

function MobileApp(props) {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [interval, setSwiperInterval] = useState('initial');
    const [updateSwiper, setUpdateSwiper] = useState(false);
    const [swiperSpacing, setSwiperSpacing] = useState('-10.5%');
    const [width, height] = useWindowDimension();

    const [state, dispatch] = useReducer(mobileReducer, initialState);

    const defaultExpand = {
        balance: false,
        bill: false,
        payment: false,
        account: false,
    };
    const [expand, setExpand] = useState(defaultExpand);

    /**
     * 1. It's required to update the space between slide previews and active slide.
     * 2. Only for mobile view.
     */
    useEffect(() => {
        if (width < 600) {
            if (width < 360) {
                setSwiperSpacing(`-${(360 / width) * 10.5}%`);
            } else {
                setSwiperSpacing('-10.5%');
            }
        }
    }, [width]);

    /**
     * When NavLink is clicked from MobileHeader
     * 1. For Mobile : Swiper will slide based on index number.
     * 2. For Desktop : Window will scroll up/down based on index number
     */
    useEffect(() => {
        if (width < 600) {
            // console.log(' useEffect for slide change with header nav link');
            const swp = document.querySelector('.topSwiper');
            if (state.slideTo != 'reset') {
                swp != null && swp.swiper.slideTo(state.slideTo, false);
                dispatch({ type: 'slideTo', payload: 'reset' });
                window.scrollTo(0, 0);
            }
        } else {
            switch (state.scrollTo) {
                case 'reset': {
                    break;
                }
                case 4: {
                    // for MyAccount
                    const element = document.getElementById('my-eir-account');
                    if (element != null) {
                        const elementPosition = element.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.pageYOffset - 85;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: 'smooth',
                        });
                        setExpand({ ...defaultExpand, account: true });
                    } else {
                        const element = document.querySelector('#my-eir-account');
                        setExpand({ ...defaultExpand, account: true });
                        element.scrollIntoView();
                    }
                    dispatch({ type: 'scrollTo', payload: 'reset' });
                    break;
                }
                default: {
                    setExpand(defaultExpand);
                    window.scrollTo(0, 0);
                }
            }
        }
    }, [state.slideTo, state.scrollTo]);

    /**
     * 1. It's required to update the height of swiper, since autoHeight prop is not responsive.
     * 2. Only for mobile view.
     */
    useEffect(() => {
        // console.log('useEffect - 3 ', interval);
        // desktop
        if (width >= 600) {
            clearInterval(interval);
            setSwiperInterval('initial');
            // console.log('desktop');
        }

        // mobile
        if (width < 600) {
            const swp = document.querySelector('.bottomSwiper');
            swp != null && setSwiperInterval(setInterval(() => swp.swiper.update(), 100));
            setTimeout(() => clearInterval(interval), 2000);
            // console.log('mobile');
        }
        return () => clearInterval(interval);
    }, [width, updateSwiper]);

    return (
        <MobileContext.Provider value={{ state, dispatch }}>
            <div id='my-eir-mobile' className='myeir-mobile-background'>
                {/* header */}
                <HeaderContainer appname='mobile' loginSuccess={true} />
                {/* <h4>Slide to : {state.slideTo}</h4>
                <h4>Scroll to : {state.scrollTo}</h4> */}
                {/* ---- body --- */}
                <main id='my-eir-mobile-body'>
                    {props.children != undefined ? (
                        props.children
                    ) : width < 600 ? (
                        /* mobile view */
                        <Fragment>
                            <Swiper
                                className='topSwiper'
                                modules={[Controller]}
                                onSwiper={setFirstSwiper}
                                controller={{ control: secondSwiper }}
                                slidesPerView={1}
                                spaceBetween={swiperSpacing}
                                centeredSlides={true}
                                autoHeight={false}
                                initialSlide={1}
                                onSlideChange={s =>
                                    dispatch({ type: 'currentSlideIndex', payload: s.activeIndex })
                                }
                            >
                                {MobileView().map((component, i) => (
                                    <SwiperSlide key={i}>{component.top}</SwiperSlide>
                                ))}
                            </Swiper>
                            <Swiper
                                className='bottomSwiper'
                                modules={[Controller]}
                                onSwiper={setSecondSwiper}
                                controller={{ control: firstSwiper }}
                                slidesPerView={1}
                                spaceBetween={0}
                                centeredSlides={true}
                                autoHeight={true}
                                initialSlide={1}
                                onAny={() => setUpdateSwiper(!updateSwiper)}
                            >
                                {MobileView().map((component, i) =>
                                    component.bottom.length > 0 ? (
                                        <SwiperSlide key={i}> {component.bottom} </SwiperSlide>
                                    ) : (
                                        <SwiperSlide key={i}>
                                            <div
                                                className='empty-div'
                                                style={{ height: '1px' }}
                                            ></div>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                            <FAQ />
                        </Fragment>
                    ) : (
                        <DesktopView expand={expand} />
                    )}
                </main>
                {state.paymentFlow.display && <PaymentFlowModal />}
                {/* footer */}
                <FooterContainer appname={'mobile'} />
            </div>
        </MobileContext.Provider>
    );
}

export default MobileApp;
