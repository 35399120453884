import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Bill from '../images/Bill.png';
import Vector from '../images/Payment2.png';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useMyBalance } from '../css/useMyBalance';
import Loading from '../../components/loading/Loading';
import { Button } from '@material-ui/core';
import MyEirLoggedInModal from '../loggedInModal/MyEirLoggedInModal';
import { MobileContext } from '../global-context';
import { getServiceId } from '../getServiceID';
import { getBillingPeriod } from '../getBillingCycle';
import PaymentFlowModal from '../payment-flow/PaymentFlowModal';
import { Hidden } from '@material-ui/core';
import { subscribe } from 'pubsub-js';
import { getNameAndAccountID } from '../getNameAndAccountNumber';

function MyBalance(props) {
    const DT_INDEX = 0;
    const classes = useMyBalance();
    const graphql = useApolloClient();
    const [balance, setBalance] = useState('NA');
    const [billDate, setBillDate] = useState('NA');
    const [forPeriod, setForPeriod] = useState('NA');
    const [toDate, setToDate] = useState('NA');
    const [paymentDue, setPaymentDue] = useState('NA');
    const [monthlyCharges, setMonthlyCharges] = useState('NA');
    const [usage, setUsage] = useState('NA');
    const [totalBalance, setTotalBalance] = useState('NA');
    const [directDebitDate, setDirectDebitDate] = useState('NA');
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [balanceOverdue, setBalanceOverdue] = useState(false);
    const [oneoffCharges, setOneoffCharges] = useState('NA');
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [openPayment, setPaymentModal] = useState(false);
    const token = sessionStorage.getItem('access_token');
    let billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');

    const togglePayment = () => {
        console.log('toggle payment');
        setPaymentModal(!openPayment);
    };

    /*Query*/
    const getMyBalance = async () => {
        try {
            if (!billingAccountId) {
                await getNameAndAccountID(graphql);
                billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
            }
            if (token && billingAccountId) {
                const res = await graphql.query({
                    query: ENDPOINT.GET_BALANCE,
                    variables: {
                        token: token,
                        billingAccountId: billingAccountId,
                    },
                });
                if (res.data.getBalanceDetails) {
                    if (res.data.getBalanceDetails[0].nonOverdueAmount < 0) {
                        setBalanceOverdue(true);
                    }
                    setBalance(
                        res.data.getBalanceDetails[0].nonOverdueAmount +
                            res.data.getBalanceDetails[0].overdueAmount
                    );
                    sessionStorage.setItem(
                        'myBalance',
                        res.data.getBalanceDetails[0].nonOverdueAmount +
                            res.data.getBalanceDetails[0].overdueAmount
                    );
                    setIsError(false);
                }
                if (
                    window.location.href.indexOf('mytt') > 0 ||
                    window.location.href.indexOf('myeir-test') > 0 ||
                    window.location.href.indexOf('localhost') > 0 ||
                    window.location.href.indexOf('myeir-dev') > 0
                ) {
                    if (res.data.getBalanceDetails) {
                        setBillDate(
                            res.data.getBalanceDetails[0].date != null
                                ? convertDate(res.data.getBalanceDetails[0].date)
                                : 'NA'
                        );
                        setIsError(false);
                    }
                }
            }
        } catch (err) {
            setIsError(true);
        } finally {
            setLoading(false);
        }
    };

    // const usageAmount = async index => {
    //     try {
    //         const idIndex = index != undefined ? index : 0;
    //         if (
    //             sessionStorage.getItem('LoggedinServices') == undefined ||
    //             sessionStorage.getItem('LoggedinServices') == ''
    //         ) {
    //             const serviceResponse = await getServiceId(graphql);
    //         }
    //         // const res = await graphql.query({
    //         //     query: ENDPOINT.GET_USAGE_SUMMARY,
    //         //     variables: {
    //         //         token: sessionStorage.getItem('access_token'),
    //         //         billingPeriod: '202202',
    //         //         serviceType: 'POSTPAY',
    //         //         serviceId: JSON.parse(sessionStorage.getItem('LoggedinServices'))[idIndex].id,
    //         //     },
    //         // });
    //         //     console.log("usage amount", res.data)
    //         //     console.log("summary", res.data.getMyUsageSummary)
    //         // if (res.data.getMyUsageSummary) {
    //         //     console.log("usage", res.data.getMyUsageSummary.usageSummary[0].amount)
    //         //     setUsage(res.data.getMyUsageSummary.usageSummary[0].amount);
    //         //     setIsError(false);
    //         // }
    //         const res = await graphql.query({
    //             query: ENDPOINT.GET_MY_BILL_DETAILS,
    //             variables: {
    //                 token: sessionStorage.getItem('access_token'),

    //             },
    //         });
    //         let data2 = res.data
    //         if (res.data.getMyBillDetails) {
    //             setUsage(res.data.getMyBillDetails.bills.billAmount.usageAmount)
    //             setIsError(false);
    //         }
    //     } catch (err) {
    //         setIsError(true);
    //     }
    // };

    // const billingCycle = async () => {
    //     try {
    //         const res = await graphql.query({
    //             query: ENDPOINT.GET_BILLING_CYCLE,
    //             variables: {
    //                 token: sessionStorage.getItem('access_token'),
    //                 accountId: sessionStorage.getItem('LoggedinUserAccountID'),
    //             },
    //         });
    //         if (res.data.getBillingCycle) {
    //             //setForPeriod(res.data.getBillingCycle.firstInvoiceDate);
    //             setForPeriod(res.data.getBillingCycle.lastInvoiceDate);
    //             setToDate(res.data.getBillingCycle.nextInvoiceDate);
    //             sessionStorage.setItem(
    //                 'last invoice date',
    //                 res.data.getBillingCycle.lastInvoiceDate
    //             );
    //             sessionStorage.setItem(
    //                 'next invoice date',
    //                 res.data.getBillingCycle.nextInvoiceDate
    //             );
    //             setIsError(false);
    //         }
    //     } catch (err) {
    //         setIsError(true);
    //     }
    // };

    const billingCycle = async () => {
        try {
            if (
                sessionStorage.getItem('lastInvoiceDate') == undefined ||
                sessionStorage.getItem('lastInvoiceDate') == null ||
                sessionStorage.getItem('lastInvoiceDate') == '' ||
                sessionStorage.getItem('nextInvoiceDate') == undefined ||
                sessionStorage.getItem('nextInvoiceDate') == null ||
                sessionStorage.getItem('nextInvoiceDate') == ''
            ) {
                await getBillingPeriod(graphql);
            }
            if (
                sessionStorage.getItem('lastInvoiceDate') != undefined &&
                sessionStorage.getItem('lastInvoiceDate') != '' &&
                sessionStorage.getItem('nextInvoiceDate') != undefined &&
                sessionStorage.getItem('nextInvoiceDate') != null &&
                sessionStorage.getItem('nextInvoiceDate') != ''
            ) {
                setForPeriod(
                    sessionStorage.getItem('lastInvoiceDate') !== 'null'
                        ? sessionStorage.getItem('lastInvoiceDate')
                        : sessionStorage.getItem('firstInvoiceDate')
                );
                setToDate(sessionStorage.getItem('nextInvoiceDate'));
                setIsError(false);
            } else {
                // setIsError(true);
            }
        } catch (err) {
            setIsError(true);
        }
    };
    const monthlyCharge = async () => {
        try {
            // const res = await graphql.query({
            //     query: ENDPOINT.GET_SUBSCRIPTION,
            //     variables: {
            //         token: sessionStorage.getItem('access_token'),
            //     },
            // });
            // if (res.data.getMySubscriptions) {
            //     setMonthlyCharges(res.data.getMySubscriptions.content[0].charges);
            //     setIsError(false);
            // }
            if (!billingAccountId) {
                await getNameAndAccountID(graphql);
                billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
            }
            if (token && billingAccountId) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_BILLING_HISTORY,
                    variables: { token: token, accountId: billingAccountId },
                });

                let data = await response.data;
                if (data.getBillingHistory) {
                    if (data.getBillingHistory.length > 0) {
                        setBillDate(convertDate(data?.getBillingHistory[0]?.invoiceDate));
                        setPaymentDue(convertDueDate(data?.getBillingHistory[0]?.dueDate));
                    }
                }

                const res = await graphql.query({
                    query: ENDPOINT.GET_MY_BILL_DETAILS,
                    variables: {
                        token: token,
                        invoiceId: `${data?.getBillingHistory[0]?.invoiceNumber}`,
                        billingAccountId: sessionStorage.getItem('LoggedinUserAccountID'),
                    },
                });
                if (res.data.getMyBillDetails) {
                    setMonthlyCharges(
                        res.data.getMyBillDetails.bills[0].billAmount.recurringAmount
                    );
                    setUsage(res.data.getMyBillDetails.bills[0].billAmount.usageAmount);
                    setTotalBalance(res.data.getMyBillDetails.bills[0].billAmount.invoiceAmount);
                    setOneoffCharges(res.data.getMyBillDetails.bills[0].billAmount.oneOffAmount);
                    setIsError(false);
                }
            }
        } catch (err) {
            // setIsError(true);
        }
    };

    const payDue = async () => {
        try {
            if (!billingAccountId) {
                await getNameAndAccountID(graphql);
                billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
            }
            if (token && billingAccountId) {
                const res = await graphql.query({
                    query: ENDPOINT.GET_BALANCE,
                    variables: {
                        token: token,
                        billingAccountId: billingAccountId,
                    },
                });
                if (res.data.getBalanceDetails) {
                    setPaymentDue(
                        res.data.getBalanceDetails[0].date != null
                            ? convertDueDate(res.data.getBalanceDetails[0].date)
                            : 'NA'
                    );
                    setIsError(false);
                }
            }
        } catch (err) {
            setIsError(true);
        }
    };

    // const totalBalance = async () => {
    //     try {
    //         const res = await graphql.query({
    //             query: ENDPOINT.GET_MY_BILL_DETAILS,
    //             variables: {
    //                 token: sessionStorage.getItem('access_token'),

    //             },
    //         });
    //         if (res.data.getMyBillDetails) {
    //             setTotalBalance(res.data.getMyBillDetails.bills.billAmount.invoiceAmount+
    //                 res.data.getMyBillDetails.bills.billAmount.vatAmount);
    //             setIsError(false);
    //         }
    //     } catch (err) {
    //         setIsError(true);
    //     }
    // };

    function convertDateFormat(str) {
        if (str !== 'NA') {
            const dateArr = str.split('-');
            const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
            const myMonth = myDate.toLocaleString('default', { month: 'short' });
            return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
        }
    }

    function convertToDateFormat(str) {
        if (str !== 'NA') {
            const toDateArr = str.split('-');
            const myToDate = new Date(`${toDateArr[0]}-${toDateArr[1]}-${toDateArr[2]}`);
            const converted = new Date();
            converted.setTime(myToDate.getTime() - 24 * 60 * 60 * 1000);
            const toDateArrNew = converted.toString().split(' ');
            return `${toDateArrNew[2]} ${toDateArrNew[1]} ${toDateArrNew[3]}`;
        }
    }

    function convertDate(str) {
        if (str !== 'NA') {
            const extractDate = str.split('T');
            const newExtractDate = `${extractDate[0]}`;
            const dateExtract = newExtractDate.split('-');
            const myDateExtract = new Date(`${dateExtract[0]}-${dateExtract[1]}-${dateExtract[2]}`);
            const myMonthExtract = myDateExtract.toLocaleString('default', { month: 'short' });
            return `${parseInt(dateExtract[2])} ${myMonthExtract} ${dateExtract[0]}`;
        }
    }
    function convertDueDate(str) {
        if (str !== 'NA') {
            const toDateArr = str.split('-');
            const myToDate = new Date(`${toDateArr[0]}-${toDateArr[1]}-${toDateArr[2]}`);
            const converted = new Date();
            converted.setTime(myToDate.getTime() + 7 * (24 * 60 * 60 * 1000));
            const toDateArrNew = converted.toString().split(' ');
            return `${toDateArrNew[2]} ${toDateArrNew[1]} ${toDateArrNew[3]}`;
        }
    }

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();
        getMyBalance();
        //usageAmount(0);
        billingCycle();

        if (
            window.location.href.indexOf('mytt') > 0 ||
            window.location.href.indexOf('myeir-test') > 0 ||
            window.location.href.indexOf('localhost') > 0 ||
            window.location.href.indexOf('myeir-dev') > 0
        ) {
            payDue();
        }
        //totalBalance();
        subscribe('UPDATE_BALANCE', (msg, data) => {
            console.log('inside pubsub-subscriber');
            getMyBalance();
        });
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        monthlyCharge();
    }, [balance]);

    const Title = ({ classes }) => (
        <div className='mbContainer'>
            <div className={classes.headingMain}>
                <span className='titleIcon' />
                <Typography variant='h3'> My balanceMuiSelect-icon</Typography>
            </div>
        </div>
    );
    const Balancefooter = ({ classes, togglePayment, enablePayment = false }) =>
        enablePayment &&
        (balance / 100).toFixed(2) < 0 && (
            <div className='my-balance-footer'>
                <div className='bottom-component'>
                    {enablePayment
                        ? `Will be paid by direct debit on ${paymentDue}`
                        : `We are currently carrying out essential maintenance on our online payment service.
                Please call 1905 or go to your local Retail store if you wish to make a payment`}
                </div>
                <div className='make-payment' onClick={togglePayment}>
                    Make a payment
                    <img className='vectorIcon' src={Vector} alt='vector icon'></img>
                </div>
            </div>
        );

    return (
        <div className={classes.mainComponentMb}>
            <div className={classes.root} xs={3}>
                {
                    <div className={classes.mainContainer}>
                        <Title classes={classes} />
                        <div style={{ position: 'relative' }}>
                            {balance === 'NA' && !isError && <MyEirLoggedInModal />}
                            <div className={` ${classes.balanceContainer} my-balance-container`}>
                                {/* Total bill */}
                                <div className='my-balance-bill'>
                                    {!isNaN(balance) && (
                                        <>
                                            {Math.sign(balance) === 1 ? '-' : ''} €
                                            {(Math.abs(balance) / 100).toFixed(2)}{' '}
                                            {balanceOverdue && ''}{' '}
                                        </>
                                    )}
                                    {balance == 'NA' &&
                                        (!isError ? (
                                            <></>
                                        ) : (
                                            <Button onClick={getMyBalance}>
                                                Something went wrong. Click here to try again
                                            </Button>
                                        ))}
                                </div>
                                {balance !== 'NA' && Math.sign(balance) === 1 ? (
                                    <div class='my-balance-credit'>in credit</div>
                                ) : (
                                    <></>
                                )}
                                <div className='my-balance-text'>
                                    Your balance includes your latest bill plus any overdue payments
                                    or adjustments.
                                </div>

                                {/* Bill Details */}
                                <div className='my-balance-bill-details'>
                                    <Grid container style={{ justifyContent: 'center' }}>
                                        <Grid
                                            item
                                            className='details-col my-balance-details-col-1'
                                            sm={6}
                                            xs={12}
                                        >
                                            <div className='detail-row'>
                                                <div className='title'>Bill issued on</div>
                                                <div className='value'>{billDate}</div>
                                            </div>
                                            <div className='detail-row'>
                                                <div className='title'>For period</div>
                                                <div className='value'>
                                                    {convertDateFormat(forPeriod)} -{' '}
                                                    {convertToDateFormat(toDate)}
                                                </div>
                                            </div>
                                            <div className='detail-row'>
                                                <div className='title bold'>Payment due</div>
                                                <div className='value p-color'>{paymentDue}</div>
                                            </div>
                                        </Grid>
                                        {/* <Hidden xsUp> */}
                                        <Grid
                                            item
                                            className='details-col my-balance-details-col-2'
                                            sm={6}
                                            xs={12}
                                        >
                                            <div className='detail-row justify-center'>
                                                <div className='title p-color breakdown'>
                                                    Bill breakdown
                                                </div>
                                                <div className='value'></div>
                                            </div>
                                            <div className='detail-row text-align-left'>
                                                <div className='title'>
                                                    Monthly charges
                                                    {/* <br />
                                                    <p className='promotions'>
                                                        Including promotions of €46 until 20 Jun
                                                        2023
                                                    </p> */}
                                                </div>
                                                <div className='value'>
                                                    {!isNaN(monthlyCharges) && (
                                                        <>
                                                            {' '}
                                                            €
                                                            {(
                                                                Math.abs(monthlyCharges) / 100
                                                            ).toFixed(2)}{' '}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <>
                                                {usage == 'NA' || usage == 0 ? (
                                                    <></>
                                                ) : (
                                                    <div className='detail-row'>
                                                        <div className='title'>Usage</div>
                                                        <div className='value'>
                                                            {!isNaN(usage) && (
                                                                <>
                                                                    {' '}
                                                                    €
                                                                    {(
                                                                        Math.abs(usage) / 100
                                                                    ).toFixed(2)}{' '}
                                                                </>
                                                            )}
                                                        </div>{' '}
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {oneoffCharges == 'NA' || oneoffCharges == 0 ? (
                                                    <></>
                                                ) : (
                                                    <div className='detail-row'>
                                                        <div className='title'>One off charges</div>
                                                        <div className='value'>
                                                            {!isNaN(oneoffCharges) && (
                                                                <>
                                                                    {' '}
                                                                    €
                                                                    {(
                                                                        Math.abs(oneoffCharges) /
                                                                        100
                                                                    ).toFixed(2)}{' '}
                                                                </>
                                                            )}
                                                        </div>{' '}
                                                    </div>
                                                )}
                                            </>
                                            <div className='detail-row'>
                                                <div className='title bold'>Total</div>
                                                <div className='value p-color'>
                                                    {!isNaN(totalBalance) && (
                                                        <>
                                                            {' '}
                                                            €
                                                            {(Math.abs(totalBalance) / 100).toFixed(
                                                                2
                                                            )}{' '}
                                                            {balanceOverdue && ''}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Grid>
                                        {/* </Hidden> */}
                                        <Grid item className='details-col balance-footer' xs={12}>
                                            <Balancefooter
                                                enablePayment={props.enablePayment}
                                                classes={classes}
                                                togglePayment={togglePayment}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                {/* My balance footer */}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <PaymentFlowModal
                toggle={togglePayment}
                open={openPayment}
                amount={(Math.abs(balance) / 100).toFixed(2)}
            />
        </div>
    );
}

export default MyBalance;
