import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFaqStyle } from './css/useFaqStyle';
import footerData from '../JSON/footer.json';
import helpLogo from '../assets/help.png';
import helpLogoMobile from '../assets/help-mobile.png';
import phoneLogo from '../assets/call.png';
import phoneLogoMobile from '../assets/call-mobile.png';
import ENDPOINT from './graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import Loading from '../components/loading/Loading';

function FAQ() {
    const graphql = useApolloClient();

    const classes = useFaqStyle();
    const [collapse, setcollapse] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [faqLoadingError, setFaqLoadingError] = useState(false);

    const toggle = () => {
        setcollapse(!collapse);
    };

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    async function getFAQ() {
        try {
            const response = await graphql.query({
                query: ENDPOINT.GET_FAQ,
            });
            let data = response.data;
            if (data != undefined) {
                setFaqData(data.getMyFaqs);
                setLoading(false);
                setFaqLoadingError(false);
            }
        } catch (error) {
            setFaqLoadingError(true);
            // console.log('error');
            if (error.response != undefined && error.response.status != 200) {
                // setLoginError(true);
            }
            console.error(error);
        }
    }

    useEffect(() => {
        getFAQ();
    }, []);

    return (
        <div className={classes.faq}>
            <Typography variant='h3' className='faq-title'>
                Need some help?
            </Typography>
            {loading ? (
                <>
                    {faqLoadingError ? (
                        <div className='faq-content-loading'>
                            Something went wrong, please try again later.
                            <span
                                className='faq-retry-loading'
                                onClick={() => {
                                    setFaqLoadingError(false);
                                    getFAQ();
                                }}
                            >
                                Retry?
                            </span>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </>
            ) : (
                <div className='faq-accordion'>
                    {faqData.map((item, index) => {
                        return (
                            <Accordion
                                key={index}
                                className='faq-card'
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                            >
                                <AccordionSummary
                                    onClick={toggle}
                                    className='faq-card__header'
                                    classes={{
                                        content: 'faq-card__header-content',
                                        expanded: classes.expanded,
                                        expandIcon: 'faq-card__header-expandIcon',
                                    }}
                                    id={item.name + '-header'}
                                    aria-controls={item.name + '-content'}
                                    expandIcon={<ExpandMoreIcon className={'faq-expand-icon'} />}
                                >
                                    <Typography variant='body3Medium'>{item.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='faq-card__body' {...collapse}>
                                    <List className='faq-card__menu'>
                                        <ListItem className='faq-list'>
                                            <Typography variant='body3'>
                                                {item.description}
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            )}
            <div className='faq-footer'>
                <div className='faq-footer-divider'></div>
                <div
                    className='faq-help'
                    onClick={() => {
                        window.open('https://www.eir.ie/support/', '_blank');
                    }}
                >
                    <picture className='faq-image'>
                        <source media='(max-width: 599px)' srcSet={helpLogoMobile} />
                        <img src={helpLogo} alt='helpLogo' />
                    </picture>
                    <Typography variant='body3Medium' className='faq-help-text'>
                        Help & support
                    </Typography>
                </div>
                <div
                    className='faq-contact'
                    onClick={() => {
                        window.open('https://www.eir.ie/about/contact/', '_blank');
                    }}
                >
                    <picture className='faq-image'>
                        <source media='(max-width: 599px)' srcSet={phoneLogoMobile} />
                        <img src={phoneLogo} alt='phoneLogo' />
                    </picture>
                    <Typography variant='body3Medium' className='faq-contact-text'>
                        Contact us
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
