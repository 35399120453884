import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { DOMAINS } from '../../components/myeirconstant/myEirConstant';
import { useMyPlanStyle } from '../css/useMyPlanStyle';
import AmazonPrimeVideo from '../eir-amazon-prime-video/AmazonPrimeVideo';
import CancelAPVPopup from '../eir-amazon-prime-video/CancelAPVPopup';
import MyEirPortingContainer from '../eir-porting/MyEirPortingContainer';
import MyEirDisplayPINPUK from '../my-account/MyEirDisplayPINPUK';
import DeviceManagement from './manage-my-device/DeviceManagement';
import TvPinContainer from './manage-tv-pin/TvPinContainer';

function MyPlanServiceContents({ item, index, apvCustomerType, apvOfferCode, apvOfferStatus }) {
    const classes = useMyPlanStyle();
    const [isTvPin, setIsTvPin] = useState(true);
    const { MOBILE, IPTV } = DOMAINS;

    // 1. Check product type from detailsData and set it to a state - productType
    // 2. Create a object which includes links, image, title
    // 3. Make a logic to select links, image, title using productType

    function convertDate(str) {
        const extractDate = str.split('T');
        const newExtractDate = `${extractDate[0]}`;
        const dateExtract = newExtractDate.split('-');
        const myDateExtract = new Date(`${dateExtract[0]}-${dateExtract[1]}-${dateExtract[2]}`);
        const myMonthExtract = myDateExtract.toLocaleString('default', {
            month: 'short',
        });
        return `${parseInt(dateExtract[2])} ${myMonthExtract} ${dateExtract[0]}`;
    }

    return (
        <div
            className={`myPlanItem ${classes.myPlanItem} ${
                item.lastChild ? classes.myPlanItemLast : ''
            }`}
        >
            {/* TITLE */}
            {item?.count === 0 ? (
                <div className={classes.modalBroadbandImage}>
                    {/* icon */}
                    <div className={classes.wrapperClass}>
                        <img src={item?.image} alt={`${item?.title}`} />
                    </div>
                    {/* title */}
                    <div className={classes.broadbandLandline}>{item?.title}</div>
                </div>
            ) : (
                ''
            )}
            {item?.offerName && item?.offerName?.description && (
                <div className={classes.broadbandDescription}>{item?.offerName?.description}</div>
            )}
            {item?.serviceGroup === 'DUALPLAY' ? (
                <>
                    <div className={classes.bandNumber}>
                        {item?.sipNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
                    </div>
                    <div className={classes.myPlanItemLink}>
                        {apvCustomerType &&
                            (apvCustomerType === 'active' ? (
                                <span className={classes.amazonPrime}>
                                    <CancelAPVPopup linkName='Cancel Amazon Prime' />
                                </span>
                            ) : (
                                <span className={classes.amazonPrime}>
                                    <AmazonPrimeVideo
                                        apvCustomerType={apvCustomerType}
                                        apvOfferCode={apvOfferCode}
                                        apvOfferStatus={apvOfferStatus}
                                    />
                                </span>
                            ))}
                    </div>
                </>
            ) : item?.serviceGroup === IPTV ? (
                isTvPin ? (
                    <Box className={classes.myPlanTVLinks}>
                        <TvPinContainer linkName='Manage my PIN' setIsTvPin={setIsTvPin} />
                        <Typography className={classes.myPlanTVLinkSeperator}></Typography>
                        <DeviceManagement />
                    </Box>
                ) : (
                    <Box className={classes.myPlanTVLinks}>
                        <DeviceManagement />
                    </Box>
                )
            ) : item?.serviceGroup === MOBILE ? (
                <>
                    <div className={classes.bandNumber}>
                        {item?.msisdn?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
                    </div>
                    {item?.eligibility &&
                        !item?.eligibility?.inGracePeriod &&
                        !item?.eligibility?.inContract &&
                        item?.eligibility?.eligibleAt == null && (
                            <div className={classes.contractPlan}>
                                Great news, you're eligible for an upgrade. <br></br> Visit your
                                local store for our latest offers.
                            </div>
                        )}
                    {item?.eligibility &&
                        item?.eligibility?.inGracePeriod &&
                        item?.eligibility?.inContract &&
                        item?.eligibility?.eligibleAt && (
                            <div className={classes.contractPlan}>
                                Great news, you're eligible for an upgrade. <br></br> Visit your
                                local store for our latest offers.
                            </div>
                        )}
                    {item?.eligibility &&
                        !item?.eligibility?.inGracePeriod &&
                        item?.eligibility?.inContract &&
                        item?.eligibility?.eligibleAt && (
                            <div className={classes.contractPlan}>
                                Great news! You can upgrade your phone on{' '}
                                {convertDate(item?.eligibility?.eligibleAt)}
                            </div>
                        )}
                    <div className={classes.myPlanItemLink}>
                        <div className={classes.mobileLink}>
                            {item?.pinPuk &&
                            item?.pinPuk?.pin !== null &&
                            item?.pinPuk?.puk !== null ? (
                                <div className={classes.pinAndNumber}>
                                    <div className={classes.pinAndPuk}>
                                        {/* !!! Q : for each item, is there only one PIN PUK? or can be multiple??  */}
                                        {/* if only one then filter it by item.name  */}
                                        <MyEirDisplayPINPUK
                                            key={index}
                                            pin={item?.pinPuk?.pin}
                                            puk={item?.pinPuk?.puk}
                                        />
                                        <span className={classes.verticalLine}>|</span>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {/* !!! - Porting Modal */}
                            <MyEirPortingContainer
                                phoneNumber={item?.msisdn?.replace(
                                    /(\d{3})(\d{3})(\d{4})/,
                                    '$1 $2 $3'
                                )}
                                classes={{
                                    link: classes.oldNumber,
                                }}
                                serviceId={item?.serviceId}
                            />
                        </div>
                    </div>
                </>
            ) : item?.serviceGroup === 'MBB' ? (
                <>
                    <div className={classes.bandNumber}>
                        {item?.msisdn?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}
                    </div>
                    <div className={classes.myPlanItemLink}>
                        <div className={classes.mobileLink}>
                            {item?.pinPuk &&
                            item?.pinPuk?.pin !== null &&
                            item?.pinPuk?.puk !== null ? (
                                <div className={classes.pinAndNumber}>
                                    <div className={classes.pinAndPuk}>
                                        {/* !!! Q : for each item, is there only one PIN PUK? or can be multiple??  */}
                                        {/* if only one then filter it by item.name  */}
                                        <MyEirDisplayPINPUK
                                            key={index}
                                            pin={item?.pinPuk?.pin}
                                            puk={item?.pinPuk?.puk}
                                        />
                                        {/* <span className={classes.verticalLine}>|</span> */}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
        </div>
    );
}

export default MyPlanServiceContents;
