import { makeStyles } from '@material-ui/core/styles';

//styling
const useStyle = makeStyles(theme => ({
    container: {},

    mainContainer: {
        position: 'relative',
        top: '-42px',
        [theme.breakpoints.down('sm')]: {
            top: '-40px',
        },
    },

    img: {
        //  height:'60vh',
        margin: '0 -32px',
        position: 'relative',
        top: '2rem',
        marginBottom: '30px !important',

        [theme.breakpoints.down('xs')]: {
            margin: '0 -16px',
        },
    },

    overlayContent: {
        position: 'absolute',
        bottom: '0',
    },

    overlayContentAdditional: {
        position: 'absolute',
        bottom: '0',
    },

    imgAdditional: {
        // margin: '0 -32px',
        position: 'relative',
        marginTop: '-155px',
        top: '2rem',
        marginBottom: '30px !important',
        marginRight: '-32px',
        marginLeft: '-32px',

        [theme.breakpoints.only('xs')]: {
            margin: '0',
            top: '1rem',
            marginBottom: '15px !important',
        },
    },

    arrow: {
        marginLeft: '11px',
        // marginBottom: '7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    redesignLink: {
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'left',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    banner: {
        width: '100%',
        // height: '250px',
        height: 'auto',

        '@media(max-width: 379px)': {
            height: '200px',
        },
        '@media(min-width:380px) and (max-width: 599px)': {
            height: 'auto',
        },
    },

    bannerAdditional: {
        width: '100%',
        borderRadius: '10px',
        height: 'auto',

        [theme.breakpoints.only('sm')]: {
            borderRadius: '10px 10px 0 0',
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: '-88px',
            borderRadius: '10px 10px 0 0',
            height: '108px',
        },
    },

    curve: {
        position: 'absolute',
        bottom: '-1px',
        zIndex: '1',
        width: '100%',
        right: '-1px',
        left: '-0.01px',

        [theme.breakpoints.down('sm')]: {
            right: '0px',
            left: '0px',
        },
    },
    curveAdditional: {
        position: 'absolute',
        bottom: '-1px',
        zIndex: '1',
        width: '100%',
        right: '-1px',
        left: '-0.01px',

        [theme.breakpoints.down('md')]: {
            right: '0px',
            left: '0px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0',
        },
    },

    imgText: {
        width: '440px',
        left: '30%',
        textAlign: 'center',
        top: '-14px',
        position: ' relative',
        fontSize: theme.typography.subtitle1.fontSize,
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: 700,
        // lineHeight: '27px',
        letterSpacing: '0.25px',
        textAlign: 'center',
        textShadow: '0px 2px 4px rgb(0 0 0 / 25%)',

        '@media(max-width: 299px)': {
            top: '-20px',
            minWidth: '260px',
            width: '90vw',
            left: '0',
            fontSize: theme.typography.inputLabel.fontSize,
        },
        '@media(min-width:300px) and (max-width: 599px)': {
            top: '-20px',
            minWidth: '328px',
            width: '90vw',
            left: '0',
        },
    },

    imgTextOverlay: {
        width: '425px',
        left: '30%',
        textAlign: 'center',
        top: '-14px',
        position: ' relative',
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: 700,
        letterSpacing: '0.25px',
        textAlign: 'center',
        textShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
        [theme.breakpoints.down('sm')]: {
            top: '-20px',
        },

        [theme.breakpoints.only('sm')]: {
            top: '-3px',
        },
    },

    marketing: {
        paddingTop: '40px',
    },

    successPopup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    successImg: {
        marginTop: '15px',
        marginBottom: '43px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '19px',
            marginTop: '67px',
        },
    },

    imgTextAdditional: {
        top: '-25px',
        position: ' relative',
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: 700,
        letterSpacing: '0.25px',
        textAlign: 'center',
        textShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
        lineHeight: '27px',

        [theme.breakpoints.down('sm')]: {
            top: '-26px',
        },
    },

    text: {
        fontSize: theme.typography.body2.fontSize,
        // lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontSize,
        // fontWeight: '600',
        color: theme.palette.primary.heather,
        width: '440px',
        margin: '0 auto',
        textAlign: 'center',

        '@media(max-width: 299px)': {
            width: '240px',
            marginBottom: '22px !important',
            marginTop: '12px !important',
        },
        '@media(min-width:300px) and (max-width: 379px)': {
            width: '294px',
            marginTop: '12px !important',
            marginBottom: '38px !important',
            // textAlign: 'left',
        },

        '@media(min-width:380px) and (max-width: 599px)': {
            width: '294px',
            marginBottom: '22px !important',
            // textAlign: 'left',
        },
    },

    //Amazon prime video popup styles
    registerPopup: {
        width: '440px',
        margin: '0 auto',
        textAlign: 'center',
        padding: '40px 14px 27px 14px',
        // height:'38vh',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            padding: '11px 30px',
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },

        [theme.breakpoints.between('sm', 'md')]: {
            textAlign: 'center',
            padding: '30px 50px',
            width: 'auto',
        },
    },
    primeLink: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: 700,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '39px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            lineHeight: '29px',
        },
    },
    redesignCancelLink: {
        display: 'flex',
        fontSize: theme.typography.subtitle2.fontSize,
        fontStyle: 'normal',
        textAlign: 'left',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        lineHeight: '21px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    registerTextOne: {
        // width:'244px',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 600,
        color: theme.palette.primary.heather,

        '@media(max-width:370px)': {
            width: '244px',
            fontWeight: 400,
        },
        '@media(max-width:600px)': {
            fontWeight: 400,
        },
    },

    checkbox: {
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette.primary.heather,
        },
    },

    checkBoxTerms: {
        // width:'232px',
        width: '364px',
        margin: '0 auto',
        // background:'orange',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        marginTop: '30px',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            marginTop: '32px',
            marginBottom: '12px',
        },
    },

    terms: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.primary.heather,
    },
    link: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        textDecoration: 'underline',
        color: theme.palette.primary.sunset,
        marginLeft: '4px',
    },
    inactiveContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    imgSad: {
        marginTop: '43px',
        marginBottom: '39px',
        [theme.breakpoints.only('xs')]: {
            marginTop: '51.4px',
            marginBottom: '27.4px',
        },
    },
    inactiveText: {
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '700',
        color: theme.palette.primary.heather,
        marginBottom: '97px',
        width: '273px',
        textAlign: 'center',
        [theme.breakpoints.only('xs')]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },
    inactiveTextLight: {
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        textDecoration: 'underline',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        color: theme.palette.primary.heather,
        marginBottom: '25px',
        cursor: 'pointer',
        bottom: 0,
        [theme.breakpoints.only('xs')]: {
            position: 'absolute',
            bottom: 0,
            marginBottom: '34px',
        },
    },
    linkStyles: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.subtitle2.fontSize,
        cursor: 'pointer',
    },
    pipe: {
        padding: '0px 9.96px',
        color: theme.palette.primary.gray400,
        cursor: 'default',
    },
}));

export default useStyle;
