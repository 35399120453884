import React, { useState, useEffect } from 'react';
import TopNav from './TopNav';
import MegaNav from './MegaNav';
import MobileHeader from './MobileHeader';
import DashboardHeader from '../components/generic/DashboardHeader';
import headerData from '../JSON/header.json';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
const theme = createMuiTheme({
    // typography: {
    //     fontFamily: 'eir-medium',
    // },
});

function HeaderContainer(props) {
    const [headerMenuData, setheaderMenuData] = useState({});
    const [path, setPath] = useState(window.location.pathname);
    const appname = props.appname != undefined ? props.appname : 'prepay';

    useEffect(() => {
        setheaderMenuData(headerData);
    }, []);
    useEffect(() => {
        setPath(window.location.pathname);
    }, [window.location.pathname]);

    return (
        <ThemeProvider theme={theme}>
            {
                {
                    mobile: (
                        <header style={{ position: 'sticky', top: '0', zIndex: '10' }}>
                            <MobileHeader loginSuccess={props.loginSuccess} />
                        </header>
                    ),
                    prepay: (
                        <header>
                            <TopNav
                                headerSecondaryItems={headerMenuData}
                                loginSuccess={props.loginSuccess}
                            />

                            {path != '/'
                                ? !props.loginSuccess && (
                                      <MegaNav headertertiaryItems={headerMenuData} />
                                  )
                                : ''}

                            {props.loginSuccess && (
                                <DashboardHeader headerPrimaryItems={headerMenuData} />
                            )}
                        </header>
                    ),
                }[appname]
            }
        </ThemeProvider>
    );
}

export default HeaderContainer;
