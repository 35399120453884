import { useApolloClient } from '@apollo/client';
import { setCookie } from './redesign-5.2/redesign.api';
import ENDPOINT from './graphql/GraphQLConstants';

export async function getNameAndAccountID(graphql) {
    try {
        const token = sessionStorage.getItem('access_token');
        if (token) {
            const response = await graphql.query({
                query: ENDPOINT.GET_SUBSCRIPTION_ID_AND_CONTACT,
                variables: {
                    token: token,
                },
            });

            let data = response.data;

            if (data.getMyContactDetails.firstName != undefined) {
                sessionStorage.setItem('LoggedinUserFirstName', data.getMyContactDetails.firstName);
            }
            if (data.getMyContactDetails.lastName != undefined) {
                sessionStorage.setItem('LoggedinUserLastName', data.getMyContactDetails.lastName);
            }
            if (data.getMySubscriptions.content) {
                let accountArr = [];
                data.getMySubscriptions.content.map((item, index) => {
                    if (item.type == 'POSTPAY') {
                        accountArr = accountArr.concat(item);
                    }
                });
                if (accountArr[0].id != undefined) {
                    sessionStorage.setItem('LoggedinUserSubscriptionID', accountArr[0].id);
                    sessionStorage.setItem(
                        'LoggedinUserSubscriptionIDandAccountID',
                        JSON.stringify(accountArr)
                    );
                }
                if (accountArr[0].accountId != undefined) {
                    sessionStorage.setItem('LoggedinUserAccountID', accountArr[0].accountId);
                    setCookie('ION', 'ACCOUNT_ID', accountArr[0].accountId);
                    try {
                        const response2 = await graphql.query({
                            query: ENDPOINT.GET_ACCOUNT_NUMBER,
                            variables: {
                                token: token,
                                accountId: accountArr[0].accountId,
                            },
                        });
                        let data2 = response2.data;
                        if (data2.getMyAccountID.billingAccountId != undefined) {
                            sessionStorage.setItem(
                                'LoggedinUserAccountNumber',
                                data2.getMyAccountID.billingAccountId
                            );
                            // console.log("ALL DONE")
                        }
                        if (data2.getMyAccountID.invoiceDeliveryMethod != undefined) {
                            sessionStorage.setItem(
                                'LoggedinUserInvoiceDeliveryMethod',
                                data2.getMyAccountID.invoiceDeliveryMethod
                            );
                            // console.log("ALL DONE")
                        }
                        return [
                            true,
                            data.getMyContactDetails.firstName,
                            data.getMyContactDetails.lastName,
                            accountArr[0].id,
                            accountArr[0].accountId,
                            data2.getMyAccountID.billingAccountId,
                        ];
                    } catch (error) {
                        console.error('Error in fetching Account Number : ' + error.message);
                        return [false, error.message];
                    }
                }
            }
        } else {
            console.log('Unable to fetch Contact Details and Account Number : Invalid Token');
        }

        // if (data.getMySubscriptions.content[0].id != undefined) {
        //     sessionStorage.setItem('LoggedinUserSubscriptionID', data.getMySubscriptions.content[0].id);
        //     sessionStorage.setItem('LoggedinUserSubscriptionIDandAccountID',JSON.stringify(data.getMySubscriptions.content));
        // }
        // if (data.getMySubscriptions.content[0].accountId != undefined) {
        //     sessionStorage.setItem('LoggedinUserAccountID', data.getMySubscriptions.content[0].accountId);
        //     try {
        //         const response2 = await graphql.query({
        //             query: ENDPOINT.GET_ACCOUNT_NUMBER,
        //             variables: {
        //                 token: sessionStorage.getItem('access_token'),
        //                 accountId: data.getMySubscriptions.content[0].accountId,
        //             },
        //         });
        //         let data2 = response2.data;
        //         if (data2.getMyAccountID.billingAccountId != undefined) {
        //             sessionStorage.setItem('LoggedinUserAccountNumber', data2.getMyAccountID.billingAccountId);
        //             console.log("ALL DONE")
        //         }
        //         return [true,data.getMyContactDetails.firstName,data.getMyContactDetails.lastName,data.getMySubscriptions.content[0].id,data.getMySubscriptions.content[0].accountId,data2.getMyAccountID.billingAccountId];
        //     } catch (error) {
        //         console.log('error in fetching account number ', error.message);
        //         console.log(error);
        //         return [false,error.message];
        //     }
        // }
    } catch (error) {
        console.error('Error in fetching Contact Details and Account Number : ' + error.message);
        return [false, error.message];
    }
}
