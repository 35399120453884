import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import theme from '../../../theme/MyEirTheme';
import CustomTooltip from '../tooltip/Tooltip';

/**
 *
 * @param {String} margin - dense | normal |
 * @param {String} variant - outlined | contained
 * @param {String} classes - { textField, errorMessage }
 * @returns {TextField}
 */
const CustomSelect = props => {
    const {
        margin = 'normal',
        variant = 'outlined',
        className = '',
        placeholder = '',
        value = null,
    } = props;
    const useStyle = makeStyles({
        selectField: {
            width: '100%',
            margin: '0 0 1rem',
            '& .MuiInputLabel-shrink': {
                background: theme.palette.primary.white,
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
            },
            '& .MuiInputBase-input': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
            },
            // colors on focus
            '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.heather,
                    borderWidth: 2,
                },
                '&.MuiInputLabel-root': {
                    color: theme.palette.primary.heather,
                },
                '& .MuiInputBase-input': {
                    fontFamily: theme.typography.body2.fontFamily,
                    color: theme.palette.primary.heather,
                },
            },

            // helper text
            '& .MuiFormHelperText-contained': {
                fontFamily: theme.typography.body2.fontFamily,
                color: theme.palette.primary.heather,
                margin: 0,
                fontSize: 14,
                lineHeight: '20px',
            },
        },
        errorMessage: {
            fontFamily: theme.typography.body2.fontFamily,
            color: theme.palette.primary.error,
            margin: 0,
            fontSize: 14,
            lineHeight: '20px',
        },
    });

    const classes = useStyle();

    return (
        <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex' }}>
                <FormControl
                    required={Boolean(props.required)}
                    variant={variant}
                    className={`${classes.selectField} ${className}`}
                >
                    <InputLabel>{placeholder}</InputLabel>
                    <Select
                        value={value}
                        onChange={props.onChange}
                        classes={{ selectMenu: classes.selectMenu }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        {Object.keys(props.data).map((key, index) => (
                            <MenuItem
                                key={index}
                                value={key}
                                style={{
                                    fontFamily: theme.typography.body2.fontFamily,
                                    color: theme.palette.primary.boxShadow4Dark,
                                }}
                            >
                                {props.data[key]}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{props.helperText}</FormHelperText>
                </FormControl>

                {props.tooltip && (
                    <Box style={{ marginLeft: 10, marginRight: 3 }}>
                        <CustomTooltip message={props.tooltip} />{' '}
                    </Box>
                )}
            </Box>
            {props.errorMessage && (
                <Typography
                    component='p'
                    className={`${classes.errorMessage} ${props.className?.errorMessage}`}
                >
                    {props.error ? props.errorMessage : ''}
                </Typography>
            )}
        </Box>
    );
};

export default CustomSelect;
