import React, { Fragment, useState, useRef } from 'react';
import { Controller, useForm, ErrorMessage, FormData, formState } from 'react-hook-form';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import { publish } from 'pubsub-js';
import errormesages from './errormessages';
import { useChangeBillingAddress } from '../css/useChangeBillingAddress';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { formatCounty, ReFormatCounty } from '../addressFormat';
import MyEirRegisterHeader from '../../sharedcomponents/MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from '../../sharedcomponents/MyEirPostpayRegistration/reusable/MyEirRegisterFooter';
import MyEirChangeSuccessModal from './MyEirChangeSuccessModal';

function MyEirChangeBillingAddress({
    isDeliveryAddress,
    toggle,
    resetStep,
    addressData,
    isManual,
    handleBackButton,
}) {
    const {
        control,
        errors,
        handleSubmit,
        setValue,
        setError,
        clearError,
        formState: { isSubmitting, isValid, isDirty },
    } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const [isOpen, setIsOpen] = React.useState(false);
    const handleBack = () => {
        handleBackButton();
    };
    const [isOpen1, setIsopen1] = useState(false);
    const [codeStatus, setCodeStatus] = useState(false);
    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };
    const classes = useChangeBillingAddress();
    const { validation, errormessage } = errormesages;
    const graphql = useApolloClient();

    const onSubmit = FormData => {
        FormData.county = ReFormatCounty(FormData.county);
        const billing = {
            type: 'BILLING',
            ...FormData,
        };
        onChangeAddress(JSON.stringify(billing));
    };

    const onChangeAddress = async billing => {
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.UPDATE_ADDRESS,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    updatedAddress: billing,
                },
            });
            if (res.data.updateAddress === 204) {
                publish('updateAddress', billing);
                setCodeStatus(true);
                toggle1();
            } else {
                setCodeStatus(false);
                toggle1();
                console.log('else');
            }
        } catch (err) {
            setCodeStatus(false);
            toggle1();

            console.log('catch');
            console.log(err);
            return 'Something went wrong';
        }
    };

    return (
        <Fragment>
            <form
                className={classes.formBillingAddressContainer}
                onSubmit={e => {
                    e.preventDefault();
                }}
            >
                <Grid container className={classes.billingAddress}>
                    <Grid item xs={12} className={classes.billingAddressContainer}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={12} className={classes.addressTextField}>
                                <Controller
                                    as={<TextField className={classes.textField} />}
                                    control={control}
                                    fullWidth
                                    id={'addressLine1'}
                                    name={'addressLine1'}
                                    label='Address line 1'
                                    variant='outlined'
                                    size='small'
                                    disabled
                                    defaultValue={addressData.addressLine1}
                                />
                                <ErrorMessage errors={errors} name={'addressLine1'}>
                                    {({ message }) => (
                                        <p className={classes.errorMessage}>{message}</p>
                                    )}
                                </ErrorMessage>
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                className={`${classes.addressTextField} ${
                                    addressData.addressLine2 ? '' : classes.hideTextField
                                }`}
                            >
                                <Controller
                                    as={<TextField className={classes.textField} />}
                                    control={control}
                                    fullWidth
                                    id={'addressLine2'}
                                    name={'addressLine2'}
                                    label='Address line 2'
                                    variant='outlined'
                                    size='small'
                                    disabled
                                    defaultValue={addressData.addressLine2}
                                />
                                <ErrorMessage errors={errors} name={'addressLine2'}>
                                    {({ message }) => (
                                        <p className={classes.errorMessage}>{message}</p>
                                    )}
                                </ErrorMessage>
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                className={`${classes.addressTextField} ${
                                    addressData.addressLine3 ? '' : classes.hideTextField
                                }`}
                            >
                                <Controller
                                    as={<TextField className={classes.textField} />}
                                    control={control}
                                    fullWidth
                                    id={'addressLine3'}
                                    name={'addressLine3'}
                                    label='Address line 3'
                                    variant='outlined'
                                    size='small'
                                    disabled
                                    defaultValue={addressData.addressLine3}
                                />
                                <ErrorMessage errors={errors} name={'addressLine3'}>
                                    {({ message }) => (
                                        <p className={classes.errorMessage}>{message}</p>
                                    )}
                                </ErrorMessage>
                            </Grid>
                        </Grid>

                        <Grid container spacing={8} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} className={classes.townCounty}>
                                <Grid container>
                                    <Grid item xs={6} sm={6} className={classes.paddingLeftTown}>
                                        <Controller
                                            as={<TextField className={classes.textField} />}
                                            control={control}
                                            fullWidth
                                            id={'town'}
                                            name={'town'}
                                            label='Town'
                                            variant='outlined'
                                            size='small'
                                            disabled
                                            defaultValue={addressData.town}
                                        />
                                        <ErrorMessage errors={errors} name={'town'}>
                                            {({ message }) => (
                                                <p className={classes.errorMessage}>{message}</p>
                                            )}
                                        </ErrorMessage>
                                    </Grid>

                                    <Grid item xs={6} sm={6} className={classes.paddingRightCounty}>
                                        <Controller
                                            as={<TextField className={classes.textField} />}
                                            control={control}
                                            fullWidth
                                            id={'county'}
                                            name={'county'}
                                            label='County'
                                            variant='outlined'
                                            size='small'
                                            disabled
                                            defaultValue={formatCounty(addressData.county)}
                                        />
                                        <ErrorMessage errors={errors} name={'county'}>
                                            {({ message }) => (
                                                <p className={classes.errorMessage}>{message}</p>
                                            )}
                                        </ErrorMessage>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={12} className={classes.addressTextField}>
                                <Controller
                                    as={<TextField className={classes.textField} />}
                                    control={control}
                                    fullWidth
                                    id={'code'}
                                    name={'code'}
                                    label='Eircode'
                                    variant='outlined'
                                    size='small'
                                    disabled
                                    defaultValue={addressData.eircode}
                                />
                                <ErrorMessage errors={errors} name={'code'}>
                                    {({ message }) => (
                                        <p className={classes.errorMessage}>{message}</p>
                                    )}
                                </ErrorMessage>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.footerContainerAddress}>
                    <MyEirRegisterFooter
                        handleButton1Click={handleSubmit(onSubmit)}
                        button1Text='Confirm address'
                        button2Text='Back'
                        button1Enabled={true}
                        handleButton2Click={handleBack}
                    />
                </Grid>
                <MyEirChangeSuccessModal
                    toggle={toggle1}
                    isOpen={isOpen1}
                    toggle1={toggle}
                    resetStep={resetStep}
                    successText={
                        codeStatus
                            ? 'Your billing address has been updated'
                            : 'Something went wrong and the billing address wasn’t updated. Please try again later'
                    }
                    codeStatus={codeStatus}
                    buttonText='Close'
                />
            </form>
        </Fragment>
    );
}
export default MyEirChangeBillingAddress;
