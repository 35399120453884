import { makeStyles } from '@material-ui/core/styles';

const colors = {
    // primary: theme.palette.primary.heather,
    // secondary: theme.palette.primary.heather,
};
export const useMyBalance = makeStyles(theme => ({
    mbContainer: {
        padding: '0 30px',
    },
    mainComponentMb: {
        padding: '0 30px',
        marginTop: '99px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0px',
            padding: '0 24px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            marginTop: '19px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 'auto',
        },
    },
    root: {
        maxWidth: '1144px',
        position: 'relative',
        // backgroundColor: 'whitesmoke',
        margin: '0 auto',
        background: theme.palette.primary.white,
        borderRadius: '10px',
        boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '86vw',
            minHeight: '500px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            maxWidth: '570px',
        },
    },
    headingMain: {
        [theme.breakpoints.down('xs')]: {
            padding: '17px 0 2px',
        },
        padding: '41px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '17px 0 35px',
        },
        //background: `url("data:image/svg+xml,%3Csvg width='24' height='32' viewBox='0 0 24 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.6991 0H22.395C22.9294 0 23.358 0.433358 23.358 0.963018V17.3584V19.4V21.7498V23.618V30.441C23.358 30.9707 22.9246 31.404 22.395 31.404H0.963018C0.433358 31.404 0 30.9707 0 30.441V11.7536V10.0972V7.55487C0 7.27078 0.130004 7.0204 0.327422 6.85187L0.293713 6.80854L7.43929 0.924497C7.43929 0.924497 8.04118 0.982278 8.37824 1.39156C8.71529 1.80084 8.66234 2.40273 8.66234 2.40273L8.65752 2.40754H8.66234L1.92604 7.95453V10.0924V11.7488V29.4732H21.4319V23.618V21.7498V19.4V17.3584V1.92604H11.6621V9.37498C11.6621 9.90464 11.2288 10.338 10.6991 10.338H3.3561V10.3332H3.35128C3.35128 10.3332 2.92276 9.90464 2.92276 9.37498C2.92276 8.84532 3.34646 8.41678 3.34646 8.41678H3.3561V8.41196H9.7361V1.03043L9.73607 1.03023C9.73126 0.99659 9.72646 0.962951 9.72646 0.929312C9.72646 0.414098 10.1454 0 10.6558 0C10.663 0 10.6702 0.00120377 10.6775 0.00240754C10.6847 0.00361132 10.6919 0.00481509 10.6991 0.00481509V0ZM8.08927 21.0083H7.0733V19.7708H7.88223C7.86778 19.5686 7.85334 19.3519 7.85334 19.1352C7.85334 18.9185 7.86778 18.7018 7.88223 18.4996H7.0733V17.2525H8.08927C8.71042 15.0376 10.4486 13.9205 12.4036 13.9205C14.2574 13.9205 15.4612 14.8016 16.1834 16.1932C15.9379 16.6554 15.3697 16.9877 14.8785 17.0599C14.3585 16.015 13.5929 15.3361 12.4036 15.3361C11.2191 15.3361 10.232 16.015 9.74084 17.2477H13.1884V18.4948H9.45195C9.42306 18.697 9.42306 18.9137 9.42306 19.1304V19.1304V19.1304C9.42306 19.3615 9.42306 19.5637 9.45195 19.766H13.1884V21.0131H9.72639C10.2031 22.2602 11.1565 22.9247 12.3891 22.9247C13.6892 22.9247 14.3007 22.2169 14.893 20.912C15.4178 20.9553 15.9957 21.2442 16.2846 21.6342C15.5334 23.4254 14.1852 24.3403 12.3314 24.3403C10.3764 24.3403 8.68153 23.2376 8.08927 21.0083Z' fill='%23D111C9'/%3E%3C/svg%3E%0A") no-repeat`,
        '& h4': {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: '38px',
            color: theme.palette.primary.heather,
            fontFamily: theme.typography.body3Medium.fontFamily,
            display: 'inline-block',
            fontWeight: 'bold',
            fontStyle: 'normal',
            margin: 0,
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.h4.fontSize,
                lineHeight: '32px',
            },
            '@media(min-width:600px) and (max-width:1019px)': {},
        },
        '& .titleIcon': {
            width: 32,
            height: 32,
            display: 'block',
            background: `url("data:image/svg+xml,%3Csvg width='24' height='32' viewBox='0 0 24 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.6991 0H22.395C22.9294 0 23.358 0.433358 23.358 0.963018V17.3584V19.4V21.7498V23.618V30.441C23.358 30.9707 22.9246 31.404 22.395 31.404H0.963018C0.433358 31.404 0 30.9707 0 30.441V11.7536V10.0972V7.55487C0 7.27078 0.130004 7.0204 0.327422 6.85187L0.293713 6.80854L7.43929 0.924497C7.43929 0.924497 8.04118 0.982278 8.37824 1.39156C8.71529 1.80084 8.66234 2.40273 8.66234 2.40273L8.65752 2.40754H8.66234L1.92604 7.95453V10.0924V11.7488V29.4732H21.4319V23.618V21.7498V19.4V17.3584V1.92604H11.6621V9.37498C11.6621 9.90464 11.2288 10.338 10.6991 10.338H3.3561V10.3332H3.35128C3.35128 10.3332 2.92276 9.90464 2.92276 9.37498C2.92276 8.84532 3.34646 8.41678 3.34646 8.41678H3.3561V8.41196H9.7361V1.03043L9.73607 1.03023C9.73126 0.99659 9.72646 0.962951 9.72646 0.929312C9.72646 0.414098 10.1454 0 10.6558 0C10.663 0 10.6702 0.00120377 10.6775 0.00240754C10.6847 0.00361132 10.6919 0.00481509 10.6991 0.00481509V0ZM8.08927 21.0083H7.0733V19.7708H7.88223C7.86778 19.5686 7.85334 19.3519 7.85334 19.1352C7.85334 18.9185 7.86778 18.7018 7.88223 18.4996H7.0733V17.2525H8.08927C8.71042 15.0376 10.4486 13.9205 12.4036 13.9205C14.2574 13.9205 15.4612 14.8016 16.1834 16.1932C15.9379 16.6554 15.3697 16.9877 14.8785 17.0599C14.3585 16.015 13.5929 15.3361 12.4036 15.3361C11.2191 15.3361 10.232 16.015 9.74084 17.2477H13.1884V18.4948H9.45195C9.42306 18.697 9.42306 18.9137 9.42306 19.1304V19.1304V19.1304C9.42306 19.3615 9.42306 19.5637 9.45195 19.766H13.1884V21.0131H9.72639C10.2031 22.2602 11.1565 22.9247 12.3891 22.9247C13.6892 22.9247 14.3007 22.2169 14.893 20.912C15.4178 20.9553 15.9957 21.2442 16.2846 21.6342C15.5334 23.4254 14.1852 24.3403 12.3314 24.3403C10.3764 24.3403 8.68153 23.2376 8.08927 21.0083Z' fill='%23D111C9'/%3E%3C/svg%3E%0A") no-repeat`,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
            '@media(min-width:600px) and (max-width:1019px)': {
                //display: 'none'
            },
        },
    },
    balanceContainer: {
        '& .my-balance-bill': {
            fontSize: '48px',
            fontFamily: theme.typography.body3Medium.fontFamily,
            lineHeight: '48px',
            color: theme.palette.primary.heather,
            marginBottom: '20px',
            // fontWeight: '700',
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.h3.fontSize,
                lineHeight: '32px',
                marginBottom: '16px', //8px when adding payment flow
                marginTop: '8px', //to be removed when adding payment flow
            },
            '@media(min-width:600px) and (max-width:1019px)': {
                marginBottom: '19px',
            },
        },
        '& .my-balance-credit': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '400',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '28px',
            color: theme.palette.primary.heather,
            marginTop: '-25px',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                marginTop: '-20px',
            },
            '@media(min-width:600px) and (max-width:1040px)': {
                marginTop: '-25px',
            },
        },
        '& .my-balance-text': {
            fontSize: theme.typography.subtitle2.fontSize,
            fontFamily: theme.typography.body2.fontFamily,
            lineHeight: '22px',
            color: theme.palette.primary.heather,
            fontWeight: '400',
            paddingBottom: '37px',
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingBottom: '32px', //16px when adding payment flow
            },
            '@media(min-width:600px) and (max-width:1019px)': {
                paddingBottom: '16px',
                paddingLeft: '60px',
                paddingRight: '60px',
            },
        },

        '& .my-balance-bill-details': {
            marginBottom: '34px',
            '& .my-balance-details-col-1': {
                [theme.breakpoints.down('xs')]: {
                    order: 1,
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    order: 1,
                    maxWidth: '100%',
                    flexBasis: '100%',
                },
            },
            '& .my-balance-details-col-2': {
                [theme.breakpoints.down('xs')]: {
                    order: 3,
                    marginBottom: '37px',
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    order: 3,
                    marginBottom: '37px',
                    maxWidth: '100%',
                    flexBasis: '100%',
                },
            },
            '& .balance-footer': {
                [theme.breakpoints.down('xs')]: {
                    order: 2,
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    order: 2,
                    maxWidth: '100%',
                    flexBasis: '100%',
                },
            },

            '& .details-col': {
                padding: '0 70px 0 65px',
                [theme.breakpoints.down('xs')]: {
                    padding: '25px 16px',
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    padding: '0 60px',
                },
                '&:first-child': {
                    borderRight: `1px solid ${theme.palette.primary.borderColor}`,
                },
                '& .detail-row': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: theme.palette.primary.heather,
                    marginBottom: '14px',
                    [theme.breakpoints.down('xs')]: {
                        marginBottom: '4px',
                    },
                    '@media(min-width:600px) and (max-width:1019px)': {
                        marginBottom: '4px',
                    },

                    '&.justify-center': {
                        [theme.breakpoints.down('xs')]: {
                            justifyContent: 'center',
                            marginBottom: '12px',
                        },
                        '@media(min-width:600px) and (max-width:1019px)': {
                            justifyContent: 'center',
                            marginBottom: '16px',
                        },
                    },
                    '& .title': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontWeight: '600',
                        fontSize: theme.typography.body3Medium.fontSize,
                        lineHeight: '28px',
                        color: theme.palette.primary.heather,
                        [theme.breakpoints.down('xs')]: {
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '25px',
                        },
                        '@media(min-width:600px) and (max-width:1019px)': {},

                        '& p': {
                            fontSize: theme.typography.inputLabel.fontSize,
                            lineHeight: '14px',
                            margin: 0,
                            fontWeight: '400',
                            [theme.breakpoints.down('xs')]: {
                                width: '11rem',
                                marginBottom: '10px',
                            },
                            '@media(min-width:600px) and (max-width:1019px)': {
                                marginBottom: '6px',
                            },
                        },
                        '&.bold': {
                            fontFamily: theme.typography.subtitle1.fontFamily,
                            // fontWeight: '700',
                        },
                        '&.breakdown': {
                            fontFamily: theme.typography.body3Medium.fontFamily,
                            fontWeight: 'bold',
                            fontStyle: 'normal',
                            fontSize: theme.typography.h4.fontSize,
                        },
                        '& .payment': {
                            lineHeight: '35px',
                        },
                    },
                    '& .value': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontWeight: '400',
                        fontSize: theme.typography.body3Medium.fontSize,
                        lineHeight: '28px',
                        color: theme.palette.primary.heather,
                        [theme.breakpoints.down('xs')]: {
                            fontSize: theme.typography.body1.fontSize,
                            lineHeight: '24px',
                        },
                        '@media(min-width:600px) and (max-width:1019px)': {},
                    },
                    '& .p-color': {
                        color: colors.primary,
                        // fontWeight: '700',
                        fontFamily: theme.typography.subtitle1.fontFamily,
                    },
                },
            },
            '& .details-col.balance-footer': {
                marginTop: '30px',
                padding: 0,
                [theme.breakpoints.down('xs')]: {
                    marginTop: '22px',
                    marginBottom: '28px',
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    marginTop: '29px',
                    marginBottom: '32px',
                },
            },
            '& .text-align-left': {
                textAlign: 'left',
            },
            '& .my-balance-footer': {
                //display:'none',
                background: theme.palette.primary.boxShadow2Dark,
                //border: '1px solid rgba(209, 17, 201, 0.1)',
                paddingTop: '30px',
                paddingBottom: '43px',
                boxSizing: 'border-box',
                boxShadow: `0px -1px 0px ${theme.palette.primary.boxShadow2Lite}, 0px 1px 0px ${theme.palette.primary.boxShadow2Lite}`,
                [theme.breakpoints.down('xs')]: {
                    paddingTop: '13px',
                    paddingBottom: '10px',
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    paddingTop: '16px',
                    paddingBottom: '18px',
                },
            },
            '& .bottom-component': {
                paddingLeft: '65px', //to be removed while make a payment goes live
                paddingRight: '70px', //to be removed while make a payment goes live
                marginBottom: '9px',
                fontFamily: theme.typography.body2.fontFamily,
                fontWeight: '600',
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                color: theme.palette.primary.heather,
                [theme.breakpoints.down('xs')]: {
                    fontSize: theme.typography.inputLabel.fontSize,
                    lineHeight: '20px', //16px before make a payment goes live
                    marginBottom: '1px',
                    paddingLeft: '16px', //to be removed while make a payment goes live
                    paddingRight: '16px', //to be removed while make a payment goes live
                },
                '@media(min-width:600px) and (max-width:1019px)': {
                    marginBottom: '16px',
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '20px', //16px before make a payment goes live
                    paddingLeft: '60px', //to be removed while make a payment goes live
                    paddingRight: '60px', //to be removed while make a payment goes live
                },
            },
            '& .make-payment': {
                lineHeight: '24px',
                fontSize: theme.typography.body3Medium.fontSize,
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 'bold',
                fontStyle: 'normal',
                color: theme.palette.primary.sunset,
                cursor: 'pointer',
                [theme.breakpoints.down('xs')]: {
                    fontSize: theme.typography.body1.fontSize,
                },

                '& .vectorIcon': {
                    paddingLeft: '11.33px',
                    paddingBottom: '7px',
                },
            },
        },
    },
}));
