import { makeStyles } from '@material-ui/core/styles';
export const authorisedUserStyle = makeStyles(theme => ({
    authUserTextContainerHeader: {
        display: 'flex',
    },

    authUserTextContainer: {
        marginBottom: '24px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '16px',
        },
    },

    authUserTextContainerHeaderText: {
        fontFamily: theme.typography.h5.fontFamily,
        // fontWeight: 700,
        fontStyle: 'normal',
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.boxShadow4Dark,

        flexGrow: '1',
        textAlign: 'left',

        wordWrap: 'break-word',

        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '24px',
        },
    },

    authUserTextContainerHeaderLink: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.sunset,
        textDecorationLine: 'underline',
        cursor: 'pointer',

        flexGrow: '0',
        textAlign: 'left',

        paddingRight: '9px',
        '@media(max-width: 1019px)': {
            paddingRight: '0px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingRight: '0px',
            fontSize: theme.typography.inputLabel.fontSize,
            lineHeight: '19px',
            marginBottom: '17px',
        },
    },
    UserDeleteContent: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.sunset,
        textDecorationLine: 'underline',
        cursor: 'pointer',
    },

    authUserTextContainerContent: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '28px',
        color: theme.palette.primary.heather,

        textAlign: 'left',

        wordWrap: 'break-word',

        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
        },
    },
    userContainer: {
        width: '440px',
        margin: '0 auto',
        padding: '24px 0px 0px 0px',
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
            padding: '32px 0px 0px 0px',
        },
    },
    userContent: {
        height: '16px',
        [theme.breakpoints.down('xs')]: {
            height: '0px',
            marginTop: '-6px',
        },
    },

    editUserText: {
        '&.MuiTypography-body1': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: theme.typography.subtitle2.fontSize,
            color: theme.palette.primary.boxShadow4Dark,
            marginBottom: '32px',
            marginTop: '-10px',
        },
    },

    textFieldContent: {
        padding: '0px 0px 23px 0px',
        margin: 'unset',
        marginBottom: '-16px',
    },
    textFieldSelect: {
        padding: '0px 0px 7px 0px',
    },
    editContainer: {
        width: '440px',
        margin: '0 auto',
        padding: '41px 0px 0px 0px',
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
            padding: '26px 0px 0px 0px',
        },
    },
    userEditContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '13px',
    },
    userBox: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.boxShadow4Dark,
        marginBottom: '6px',
    },
    editUser: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.boxShadow4Dark,
        marginBottom: '0px',
    },
    linksWrap: {
        display: 'flex',
    },
    addUserContent: {
        color: theme.palette.primary.sunset,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: '700',
        fontFamily: theme.typography.body2.fontFamily,
        lineHeight: '25px',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
        flexGrow: '1',
        alignItems: 'center',
        padding: '24px 0px 18px 0px',
        margin: '0 auto',
        textTransform: 'none',
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            marginLeft: '8px',
            paddingTop: '32px',
        },
        '&.MuiButton-root:hover': {
            backgroundColor: 'unset',
        },
    },
    addIcon: {
        margin: '0 auto',
        justifyContent: 'center',
        paddingLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8px',
            marginLeft: '0px',
            paddingBottom: '0px',
        },
    },
    userEditBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    userEdit: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,
        opacity: 0.9,
        minWidth: '32px',
        cursor: 'pointer',
        padding: 0,
        textTransform: 'none',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '7px',
        },
        '&.MuiButton-root:hover': {
            backgroundColor: 'unset',
        },
    },
    userDelete: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        textDecorationLine: 'underline',
        color: theme.palette.primary.sunset,
        opacity: 0.9,
        minWidth: '45px',
        cursor: 'pointer',
        padding: 0,
        textTransform: 'none',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '7px',
        },
        '&.MuiButton-root:hover': {
            backgroundColor: 'unset',
        },
    },

    userEditDeleteBorder: {
        marginLeft: '3px',
        marginRight: '5px',
        color: theme.palette.primary.boxShadow2Dark,
    },

    userNumber: {
        fontFamily: theme.typography.h5.fontFamily,
        fontWeight: '700px',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.heather,
        marginTop: '41px',
        marginLeft: '114px',
        marginBottom: '-62px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '9px',
            marginTop: '27px',
        },
    },
    textFields: {
        padding: '0 7rem 0 7rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0 0 0 0.5rem',
        },
    },

    textField: {
        margin: '0',
        marginRight: '0.5rem',
        marginBottom: '1rem',
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.heather,
        '& .MuiInputBase-input': {
            fontFamily: theme.typography.body2.fontFamily,
            color: `${theme.palette.primary.heather} !important`,
        },
    },

    selectField: {
        '& .MuiInputBase-input': {
            fontFamily: theme.typography.body2.fontFamily,
            color: `${theme.palette.primary.heather} !important`,
        },
    },

    gridContainer: {
        marginTop: '4.8rem',
        marginBottom: '2rem',
    },

    deleteConfirmText: {
        fontFamily: theme.typography.h5.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: '75px',
        marginBottom: '61px',
        paddingRight: '24px',
        color: theme.palette.primary.heather,

        [theme.breakpoints.down('xs')]: {
            padding: '24px 36px 0px 14px',
        },
    },
    deleteContainer: {
        marginTop: '50px',
        marginLeft: '82px',
        width: '440px',
        height: '52px',
        [theme.breakpoints.down('xs')]: {
            width: '326px',
            padding: '57px 16px 24px 0px',
            margin: '0 auto',
        },
    },
}));
