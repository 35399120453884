import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Link, Typography } from '@material-ui/core';
import { useWindowDimension } from '../../hooks/windowDimensions';
import CustomModal from '../eir-porting/CustomModal/CustomModal';
import mobileCurve from '../images/curve-mob.svg';
import desktopCurve from '../images/curve-desk.svg';
import ArrowToRight from '../images/arrow-to-right.png';
import success from '../images/Large-icon-Success.png';
import useStyle from './styles';
import useStyleClose from '../eir-porting/CustomModal/style';
import CustomUpdatedPopUpModal from '../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import { getAmazonContents } from './amazonContents.util';
import MyEirErrorHandle from '../eir-porting/MyEirErrorHandle';
import { getTvisFbsPp } from '../my-plan/manage-tv-pin/tvisPinValue.util';
import { useApolloClient } from '@apollo/client';
import theme from '../../theme/MyEirTheme';

const AmazonPrimeVideo = ({
    apvCustomerType,
    apvOfferCode,
    apvOfferStatus,
    flag,
    isActiveTv,
    numCards,
    apvToggle,
}) => {
    const classes = useStyle();
    const closeIconStyle = useStyleClose();
    const graphql = useApolloClient();
    const [openErrorApv, setOpenErrorApv] = useState(false);
    const [contentData, setContentData] = useState({});
    const [open, setOpenModal] = useState(false);
    const [openCustom, setOpenCustom] = useState(false);
    const [openCustomSuccessPopup, setOpenCustomSuccessPopup] = useState(false);
    const [checked, setChecked] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const [width, height] = useWindowDimension();
    const [apvURL, setAPVURL] = useState('');
    const [linkStatus, setLinkStatus] = useState(false);
    const origin = window.location.origin;

    //AmazonCta action
    const handleAmazonCtaClick = async () => {
        const getAPVURL = await getFbsUrl();
        setAPVURL(getAPVURL);
        setOpenModal(false);
        setOpenCustom(true);
    };

    // handle register now cta
    // const handleAltCta = () => {
    //     console.log(' Register now is clicked!!');
    //     getFbsUrl().then(url => redirectForAPVActivation(url));
    // };

    // open porting modal
    const handleOpen = () => {
        setOpenCustom(false);
        getAmazonPrime();
        setOpenModal(true);
        setCTA1({ text: 'Get Amazon Prime', action: handleAmazonCtaClick, disable: false });
    };

    // handle back arrow action
    const backArrowAction = () => {
        setOpenModal(false);
    };

    //close icon
    const handleClose = () => {
        setOpenCustom(false);
        setChecked(false);
    };

    // checkbox action
    const handleCheckBox = () => {
        setChecked(!checked);
    };

    const handleCloseLink = () => {
        setOpenCustom(false);
    };

    const handleToggleSuccessPopup = () => {
        setOpenCustomSuccessPopup(!openCustomSuccessPopup);
    };

    const handleAltSuccessCta = () => {
        setOpenCustomSuccessPopup(false);
        setLinkStatus(false);
    };

    const getAmazonPrime = async () => {
        try {
            const ocatData = await getAmazonContents({ graphql, customerType: apvCustomerType });
            setContentData(ocatData);
        } catch (error) {
            console.log('amazon prime error:', error);
        }
    };

    const getFbsUrl = async () => {
        try {
            const data = await getTvisFbsPp({
                graphql,
                offerCode: apvOfferCode,
            });
            let url = null;
            if (data.getJWTActivationToken && data.getJWTActivationToken.token) {
                url =
                    'https://b.fortumo.eu/s/fd3b0648616674825338e8b1689e2407/?token=' +
                    data.getJWTActivationToken.token;
            }
            return url;
        } catch (error) {
            setOpenCustom(false);
            setOpenErrorApv(!openErrorApv);
            console.error('getFbsUrl error', error);
        }
    };

    const enableAPVSuccessModal = () => {
        try {
            if (linkStatus) {
                // Creating a virtual link and programatically clicking it and ensuring to do this in a SYNC function.
                // Not using window.open because Safari is blocking any call to window.open() which is made inside an async call due to security reasons.
                // let link = document.createElement('a');
                // link.href = url;
                // link.target = '_blank';
                // link.rel = 'noopener noreferrer';
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);

                setOpenCustom(false);
                setOpenCustomSuccessPopup(!openCustomSuccessPopup);
            } else {
                setOpenCustom(false);
                setOpenErrorApv(!openErrorApv);
            }
        } catch (error) {
            setOpenCustom(false);
            setOpenErrorApv(!openErrorApv);
            console.error('redirectForAPVActivation error', error);
        }
    };

    useEffect(() => {
        if (apvToggle) {
            handleOpen();
        }
    }, [apvToggle]);

    useEffect(() => {
        if (linkStatus) {
            enableAPVSuccessModal();
        }
    }, [linkStatus]);
    return (
        <Box id='amazon-prime-video'>
            <Typography
                component='span'
                className={flag && flag === 'redesign' ? classes.redesignLink : classes.primeLink}
                style={
                    isActiveTv
                        ? numCards == 2 && width > 1020
                            ? {}
                            : {
                                  width: 'max-content',
                                  position: 'absolute',
                                  height: '60px',
                                  bottom: '9px',
                              }
                        : {}
                }
            >
                {flag === 'offer' ? (
                    ''
                ) : (
                    <>
                        <Typography
                            component='span'
                            className={
                                flag && flag === 'redesign'
                                    ? classes.redesignLink
                                    : classes.primeLink
                            }
                            onClick={handleOpen}
                        >
                            Sign up for Amazon Prime
                        </Typography>
                        {isActiveTv && (
                            <Typography component='span' className={classes.pipe}>
                                |
                            </Typography>
                        )}
                    </>
                )}
                {/* <Box component='img' src={ArrowToRight} alt='arrow' className={classes.arrow} /> */}
            </Typography>

            <CustomModal
                open={open}
                cta1={cta1}
                cta2={cta2}
                header={{ text: 'Amazon Prime', arrowAction: backArrowAction, center: false }}
            >
                <Box className={classes.container}>
                    <Box className={classes.mainContainer}>
                        {width < 600 ? (
                            //mobile view
                            <Box className={classes.img}>
                                <Box className={classes.overlayContent}>
                                    <Typography
                                        className={classes.imgText}
                                        style={{
                                            color: Boolean(
                                                contentData?.getAmazonPrimeContents
                                                    ?.overlayTextColor
                                            )
                                                ? contentData?.getAmazonPrimeContents
                                                      ?.overlayTextColor
                                                : theme.palette.primary.white,
                                        }}
                                    >
                                        {contentData?.getAmazonPrimeContents?.overlayText}
                                    </Typography>
                                </Box>
                                <Box
                                    component='img'
                                    src={mobileCurve}
                                    alt='amazon-curve-mob'
                                    className={classes.curve}
                                />
                                <Box
                                    component='img'
                                    src={
                                        origin +
                                        contentData?.getAmazonPrimeContents?.mobileBanner?.imageURL
                                    }
                                    alt={
                                        contentData?.getAmazonPrimeContents?.mobileBanner
                                            ?.imageAtlText
                                    }
                                    className={classes.banner}
                                />
                            </Box>
                        ) : (
                            <Box className={classes.img}>
                                <Box className={classes.overlayContent}>
                                    <Typography
                                        className={classes.imgText}
                                        style={{
                                            color: Boolean(
                                                contentData?.getAmazonPrimeContents
                                                    ?.overlayTextColor
                                            )
                                                ? contentData?.getAmazonPrimeContents
                                                      ?.overlayTextColor
                                                : theme.palette.primary.white,
                                        }}
                                    >
                                        {contentData?.getAmazonPrimeContents?.overlayText}
                                    </Typography>
                                </Box>
                                <Box
                                    component='img'
                                    src={desktopCurve}
                                    alt='amazon-curve-desk'
                                    className={classes.curve}
                                />
                                <Box
                                    component='img'
                                    src={
                                        origin +
                                        contentData?.getAmazonPrimeContents?.desktopBanner?.imageURL
                                    }
                                    alt={
                                        contentData?.getAmazonPrimeContents?.desktopBanner
                                            ?.imageAtlText
                                    }
                                    className={classes.banner}
                                />
                            </Box>
                        )}

                        <Box
                            className={classes.marketing}
                            style={{
                                color: Boolean(
                                    contentData?.getAmazonPrimeContents?.marketingTextColor
                                )
                                    ? contentData?.getAmazonPrimeContents?.marketingTextColor
                                    : theme.palette.primary.heather,
                            }}
                        >
                            {contentData?.getAmazonPrimeContents?.marketingText &&
                                contentData.getAmazonPrimeContents.marketingText.map(
                                    (element, i) => {
                                        return (
                                            <Typography varaint='body2' className={classes.text}>
                                                {element}
                                            </Typography>
                                        );
                                    }
                                )}
                        </Box>
                    </Box>
                </Box>
            </CustomModal>

            <Box id='my-eir-pop-up'>
                {width < 600 ? (
                    <CustomUpdatedPopUpModal
                        open={openCustom}
                        toggle={() => setOpenCustom(false)}
                        link={{ text: 'Register now', value: apvURL, state: !checked }}
                        setLinkStatus={setLinkStatus}
                        cta1={{ text: '', action: () => {} }}
                        cta2={{ text: 'Close', action: handleCloseLink }}
                    >
                        <Box className={classes.imgAdditional}>
                            <Box
                                component='img'
                                src={mobileCurve}
                                alt='additional-banner'
                                className={classes.curveAdditional}
                            />
                            <Box
                                component='img'
                                src={
                                    origin +
                                    contentData?.getAmazonPrimeContents?.additionalInfoMobileBanner
                                        ?.imageURL
                                }
                                alt={
                                    contentData?.getAmazonPrimeContents?.additionalInfoMobileBanner
                                        ?.imageAtlText
                                }
                                className={classes.bannerAdditional}
                            />
                        </Box>
                        <Box className={classes.registerPopup}>
                            {contentData?.getAmazonPrimeContents?.additionalInfo &&
                                contentData.getAmazonPrimeContents.additionalInfo.map(
                                    (element, i) => {
                                        return (
                                            <Typography className={classes.registerTextOne}>
                                                {element}
                                            </Typography>
                                        );
                                    }
                                )}

                            <Box className={classes.checkBoxTerms}>
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={checked}
                                    onClick={handleCheckBox}
                                />
                                <Typography className={classes.terms}>
                                    I agree to Amazon Prime
                                    <Link
                                        href={`https://www.eir.ie/.content/pdf/terms/Amazon-TCs-010323-Updated.pdf`}
                                        target='_blank'
                                    >
                                        <Typography component='span' className={classes.link}>
                                            Terms & Conditions
                                        </Typography>
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </CustomUpdatedPopUpModal>
                ) : (
                    <CustomModal
                        open={openCustom}
                        toggle={() => setOpenCustom(false)}
                        link={{ text: 'Register now', value: apvURL, state: !checked }}
                        cta1={{ text: '', action: () => {} }}
                        cta2={{ text: '', action: () => {} }}
                        setLinkStatus={setLinkStatus}
                        header={{ text: '', arrowAction: {}, center: false }}
                        closeIconAction={handleClose}
                    >
                        <Box className={classes.imgAdditional}>
                            <Box className={classes.overlayContentAdditional}>
                                <Typography
                                    className={classes.imgTextOverlay}
                                    style={{
                                        color: Boolean(
                                            contentData?.getAmazonPrimeContents?.overlayTextColor
                                        )
                                            ? contentData?.getAmazonPrimeContents?.overlayTextColor
                                            : theme.palette.primary.white,
                                    }}
                                >
                                    {contentData?.getAmazonPrimeContents?.overlayText}
                                </Typography>
                            </Box>
                            <Box
                                component='img'
                                src={desktopCurve}
                                alt='additional-banner'
                                className={classes.curveAdditional}
                            />
                            <Box
                                component='img'
                                src={
                                    origin +
                                    contentData?.getAmazonPrimeContents?.additionalInfoDesktopBanner
                                        ?.imageURL
                                }
                                alt={
                                    contentData?.getAmazonPrimeContents?.additionalInfoDesktopBanner
                                        ?.imageAtlText
                                }
                                className={classes.bannerAdditional}
                            />
                        </Box>

                        <Box className={classes.registerPopup}>
                            {contentData?.getAmazonPrimeContents?.additionalInfo &&
                                contentData.getAmazonPrimeContents.additionalInfo.map(
                                    (element, i) => {
                                        return (
                                            <Typography className={classes.registerTextOne}>
                                                {element}
                                            </Typography>
                                        );
                                    }
                                )}

                            <Box className={classes.checkBoxTerms}>
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={checked}
                                    onClick={handleCheckBox}
                                />
                                <Typography className={classes.terms}>
                                    I agree to Amazon Prime
                                    <Link
                                        href={`https://www.eir.ie/.content/pdf/terms/Amazon-TCs-010323-Updated.pdf`}
                                        target='_blank'
                                    >
                                        <Typography component='span' className={classes.link}>
                                            Terms & Conditions
                                        </Typography>
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </CustomModal>
                )}
            </Box>
            <Box id='my-eir-pop-up'>
                <CustomUpdatedPopUpModal
                    open={openCustomSuccessPopup}
                    toggle={handleToggleSuccessPopup}
                    cta1={{ text: 'Close', action: handleAltSuccessCta, disable: false }}
                    cta2={{ text: '', action: () => {} }}
                >
                    <Box className={classes.successPopup}>
                        <Box className={classes.successImg}>
                            <Typography component='img' src={success} alt='success' />
                        </Box>

                        <Typography className={classes.imgTextAdditional}>
                            Amazon Prime has been activated
                        </Typography>
                    </Box>
                </CustomUpdatedPopUpModal>
            </Box>
            {openErrorApv ? (
                <MyEirErrorHandle
                    open={openErrorApv}
                    setOpen={setOpenErrorApv}
                    handleClose={handleClose}
                />
            ) : (
                ''
            )}
        </Box>
    );
};

export default AmazonPrimeVideo;
