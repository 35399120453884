import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import CustomPopUpFooter from './CustomPopUpFooter';
import useStyle from './style';
import CustomCloseIcon from './CustomCloseIcon';

const CustomModal = props => {
    const [open, setOpen] = useState(props.open);

    const toggle = () => {
        props.toggle();
        setOpen(!open);
    };
    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const styles = useStyle();

    return (
        <Modal
            // id={props.cssId}
            isOpen={open}
            id={props.id}
            toggle={toggle}
            modalTransition={{ timeout: 300 }}
            wrapClassName={styles.popUpWrapper}
            modalClassName={styles.popUpmodalClass}
            centered
        >
            <Box
                style={{
                    background: 'white',
                    borderRadius: 10,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'space-between',
                }}
            >
                <Box className={styles.closeIconBox}>
                    {props.closeIconAction && (
                        <CustomCloseIcon closeIconAction={props.closeIconAction} styles={styles} />
                    )}
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {props.children}
                </Box>
                <Box>
                    <CustomPopUpFooter
                        cta1={props.cta1}
                        cta2={props.cta2}
                        link={props.link}
                        setLinkStatus={props.setLinkStatus}
                        styles={styles}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default CustomModal;
