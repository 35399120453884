import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_BILLING_HISTORY: gql`
        query getBillingHistory($token: String!, $accountId: String!) {
            getBillingHistory(token: $token, accountId: $accountId) {
                amount
                dueDate
                invoiceDate
                invoiceNumber
                billingSource
            }
        }
    `,
    GET_INVOICE_PDF: gql`
        query getInvoicePDF($token: String!, $invoiceNumber: Float!, $accountId: String!) {
            getInvoicePDF(token: $token, invoiceNumber: $invoiceNumber, accountId: $accountId)
        }
    `,
    GET_MY_DETAILS: gql`
        query getMyDetails($token: String!, $serviceId: String!) {
            getMyDetails(token: $token, serviceId: $serviceId) {
                customerOfferDTO {
                    offerName
                    offerStatus
                    activationDate
                    msisdn
                }
                pin
                puk
            }
        }
    `,
    GET_PIN_PUK_DETAILS: gql`
        query getPinPukDetails($token: String!, $serviceId: String!) {
            getPinPukDetails(token: $token, serviceId: $serviceId) {
                imsiNumber
                pin1Code
                puk1Code
                serviceId
            }
        }
    `,
    GET_OFFER_DETAILS: gql`
        query getOfferDetails($catalogOfferCode: String!) {
            getOfferDetails(catalogOfferCode: $catalogOfferCode) {
                description
            }
        }
    `,
    TRIGGER_ADOBE: gql`
        mutation triggerAdobe($accountId: String!, $token: String!, $name: String!) {
            triggerAdobe(accountId: $accountId, token: $token, name: $name) {
                status
            }
        }
    `,
    UPDATE_ADOBE_OFFERS: gql`
        mutation updateAdobeOffers(
            $accountId: String!
            $token: String!
            $value: Int!
            $propositionId: String!
            $statusVal: String!
        ) {
            updateAdobeOffers(
                accountId: $accountId
                token: $token
                value: $value
                propositionId: $propositionId
                statusVal: $statusVal
            ) {
                status
            }
        }
    `,
    GET_ELIGIBILITY_FOR_MANAGE_ADDONS: gql`
        query getAccountStatusAndCollectionStatus($accountId: String!, $token: String!) {
            getAccountStatus(accountId: $accountId, token: $token) {
                status
                brand
            }
            getAccountCollectionStatus(accountId: $accountId, token: $token)
        }
    `,
};

/**
 * @name getBillingHistoryApi - calls billing histroy api logged in account id and returns response
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns
 */
export const getBillingHistoryApi = async ({ graphql }) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_BILLING_HISTORY,
            variables: {
                token: sessionStorage.getItem('access_token'),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name downloadInvoicePdfApi - calls invoice pdf api for a given invoice number and returns response
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.invoiceNumber - invoice number for which the invoice PDF needs to be retrieved
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {String} invoiceNumber - invoice number for which the invoice PDF needs to be retrieved
 * @returns
 */
export const downloadInvoicePdfApi = async ({ graphql, invoiceNumber }) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_INVOICE_PDF,
            variables: {
                token: sessionStorage.getItem('access_token'),
                invoiceNumber: parseFloat(`${invoiceNumber}`),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getMyDetailsApi - get pin and puk and offer Name for each serviceid
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.serviceId - service id for which pin and puk should we fetched
 * @returns
 */
export const getMyDetailsApi = async ({ graphql, serviceId }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_MY_DETAILS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                serviceId: serviceId.toString(),
            },
        });
        if (data.getMyDetails) {
            return data.getMyDetails;
        } else {
            throw 'getMyDetailsApi was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getPinPukApi - get pin and puk  for each serviceid
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.serviceId - service id for which pin and puk should we fetched
 * @returns
 */
export const getPinPukApi = async ({ graphql, serviceId }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_PIN_PUK_DETAILS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                serviceId: serviceId.toString(),
            },
        });
        if (data.getPinPukDetails) {
            return data.getPinPukDetails;
        } else {
            throw 'getPinPukApi was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getOfferDetailsApi - get offer description for the offer code
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.catalogOfferCode - catalog offer code for which offer description to be fetch
 * @returns
 */

export const getOfferDetailsApi = async ({ graphql, catalogOfferCode }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_OFFER_DETAILS,
            variables: {
                catalogOfferCode: catalogOfferCode,
            },
        });

        if (data.getOfferDetails) {
            return data.getOfferDetails;
        } else {
            throw 'getOfferDetailsApi was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

const MAX_LENGTH = 4000;
/**
 * @function
 * Get getAllCookies will return an object containing all the cookies.
 * @returns {string} - object
 */
export const getAllCookies = () => {
    try {
        const cookies = {};
        if (typeof document !== 'undefined') {
            const cookieData = document?.cookie?.split('; ');
            cookieData?.forEach(accumulator => {
                const [key, value] = accumulator.split('=');
                cookies[key] = value;
            });
            return cookies;
        }

        return null;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @function
 * Retrieve specific cookie by its name
 * @param {string} cookieName - attribute name
 * @returns {void} - attribute value
 */

export const getCookie = cookieName => {
    try {
        const getCookieValue = getAllCookies();
        if (getCookieValue) return getCookieValue[cookieName];
        return null;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @function
 * Set item in cookie
 * @param {string} cookieName - attribute name
 * @param {string} cookieValue - attribute value
 * @param {string} expiry - attribute expiry time (string of date in UTC format)
 * @returns {void} - attribute value
 */

export const setCookie = (CODE, cookieName, cookieValue, expiry) => {
    try {
        const expires = new Date(expiry).toUTCString();
        if (typeof document !== 'undefined') {
            document.cookie = expires
                ? `${CODE}_${cookieName.toUpperCase()}=${cookieValue};  domain=.eir.ie; expires=${expires};  path=/`
                : `${CODE}_${cookieName.toUpperCase()}=${cookieValue};  domain=.eir.ie; path=/`;
            if (expiry && !expires) {
                console.error('Error setting  cookie with expiry');
            }
        }
    } catch (error) {
        throw new Error(error);
    }
};

export const setTokenInCookies = async (token, expiry) => {
    try {
        const loops = Math.ceil(token.length / MAX_LENGTH);
        let i = 0;
        // const tokens = [];
        return (function splitToken(subToken) {
            if (subToken !== '')
                setCookie('ION', `token_${i}`.toUpperCase(), subToken.slice(0, MAX_LENGTH), expiry);
            // if (subToken !== '') tokens.push(subToken.slice(0, MAX_LENGTH));
            if (i > loops) return;
            i += 1;
            //   setCookie('token', subToken.slice(0, MAX_LENGTH), expiry);
            splitToken(subToken.slice(MAX_LENGTH), MAX_LENGTH);
        })(token);
    } catch (error) {
        throw new Error(error);
    }
};

export const getTokenFromCookies = (cookieObj = undefined) => {
    try {
        const allCookies = cookieObj || getAllCookies();
        const token = Object.keys(allCookies)
            .filter(key => key.indexOf('ION_TOKEN') === 0)
            .sort()
            .reduce((tokenChunk, next) => tokenChunk + allCookies[next], '');
        return token;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @function
 * Clears all the cookies
 * @returns {void} - attribute value
 */

export const clearAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i];
        const pos = cookie.indexOf('=');
        const cookieName = pos > -1 ? cookie.substr(0, pos) : cookie;
        document.cookie = `${cookieName.toUpperCase()}=; domain=.eir.ie; expires=${new Date(
            0
        ).toUTCString()};path=/`;
    }
};

/**
 * @name triggerAdobe - Trigger Adobe api based on certain action on components
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {string} name - name of the component
 * @returns
 */
export const triggerAdobeApi = async ({ graphql, name }) => {
    try {
        const data = await graphql.mutate({
            mutation: ENDPOINT.TRIGGER_ADOBE,
            variables: {
                token: sessionStorage.getItem('access_token'),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                name: name,
            },
        });
        return data.data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name updateAdobeOffers - update Adobe offers api based on certain action on offers
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {string} propositionId - propositionId of the offer
 * @param {Int} value - accept(3) or reject(5) the offer
 * @returns
 */
export const updateAdobeOffersApi = async ({ graphql, value, propositionId }) => {
    try {
        const data = await graphql.mutate({
            mutation: ENDPOINT.UPDATE_ADOBE_OFFERS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                value: value,
                propositionId: propositionId,
                statusVal: 'UpdateStatus',
            },
        });
        return data.data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getEligibilityForManageAddonsApi - gets eligiblity checks for managing addons
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns {AccountAndCollectionStatus} - account's & collection status
 */
export const getEligibilityForManageAddonsApi = async ({ graphql }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_ELIGIBILITY_FOR_MANAGE_ADDONS,
            variables: {
                accountId: sessionStorage.getItem('LoggedinUserAccountNumber'),
                token: sessionStorage.getItem('access_token'),
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
