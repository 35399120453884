import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';

const DirectoryOptions = ({ directoryPreferences, handleChangeNDDPreference, userStatus }) => {
    const [selectedPreference, setSelectedPreference] = useState('LISTED');

    const loop = [
        { title: 'Listed', key: 'LISTED' },
        { title: 'Unlisted', key: 'UNLISTED' },
        { title: 'Ex-directory', key: 'EXDIRECTORY' },
    ];
    const onRadioSelect = e => {
        handleChangeNDDPreference(e.target.value);
    };
    useEffect(() => {
        setSelectedPreference(directoryPreferences.NDDPreference);
    }, [directoryPreferences.NDDPreference]);
    return (
        <div className='NDD-Toggle mt-3'>
            <FormControl component='fieldset'>
                <RadioGroup
                    value={selectedPreference}
                    aria-label='directory-options'
                    name='directoryOptions'
                    onChange={onRadioSelect}
                >
                    {loop.map(title => (
                        <FormControlLabel
                            className='details-form-label'
                            control={<Radio />}
                            labelPlacement='start'
                            disabled={
                                userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                userStatus === ACCOUNT_STATUS.UNBILLABLE
                                    ? true
                                    : false
                            }
                            label={
                                <RadioDirectoryOptionsBlock
                                    title={title.title}
                                    value={title.key}
                                    classes={
                                        title.key === directoryPreferences.NDDPreference
                                            ? 'selected'
                                            : ''
                                    }
                                />
                            }
                            value={title.key}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

const RadioDirectoryOptionsBlock = ({ title, value, classes }) => {
    return (
        <div className={'radio-directory-preferences-block ' + classes}>
            <div className='details-topic-content details-topic-content-radio'>
                <div className='details-topic-content-heading'>{title}</div>
                {value === 'LISTED' && (
                    <div>
                        Available from directory enquiries <strong>and</strong> the on-line
                        phonebook
                    </div>
                )}
                {value === 'UNLISTED' && (
                    <div>
                        Available from directory enquiries but <strong>not</strong> in the on-line
                        phonebook
                    </div>
                )}
                {value === 'EXDIRECTORY' && (
                    <div>
                        <strong>Not</strong> in National Directory Database and <strong>not</strong>{' '}
                        in the Phonebook
                    </div>
                )}
            </div>
        </div>
    );
};

export default DirectoryOptions;
