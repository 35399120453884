import React, { useState, useEffect } from "react";
import Box from '@material-ui/core/Box'
import smileIcon from '../images/smileIcon.png';
import { useLoggedInStyle } from "../css/useLoggedInStyle";
import Loading from '../../components/loading/Loading';

function MyEirLoggedInModal({ title, name }) {
  const classes = useLoggedInStyle();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Box className={classes.boxx}>
        <h1>{title}</h1>
        <img src={smileIcon} alt="smileIcon" />
        <h2 className={classes.greeting}>{'Hi ' + (name || 'there')}</h2>
        <h3 className={classes.wereloading}>We're loading your account</h3>
        <div className={classes.loader}><Loading/></div>
      </Box>
    </>
  );
};


export default MyEirLoggedInModal;