import { createContext } from 'react';

export const initialState = {
    slideTo: 1,
    currentSlideIndex: 1,
    scrollTo: false,
    paymentFlow: {
        display: false,
    },
};

export const mobileReducer = (state, action) => {
    switch (action.type) {
        case 'slideTo': {
            // console.log('reducer: slideTo ', action.payload);
            return {
                ...state,
                slideTo: action.payload,
            };
        }
        case 'currentSlideIndex': {
            // console.log('reducer: slideTo ', action.payload);
            return {
                ...state,
                currentSlideIndex: action.payload,
            };
        }
        case 'scrollTo': {
            // console.log('setExpand ', action.payload);
            return {
                ...state,
                scrollTo: action.payload,
            };
        }
        case 'TOGGLE_PAYMENT_MODAL': {
            // console.log('TOGGLE_PAYMENT_MODAL ', action.payload);
            return {
                ...state,
                paymentFlow: {
                    ...state.paymentFlow,
                    display: !state.paymentFlow.display,
                },
            };
        }
        default: {
            // console.log('reducer: default');
            return state;
        }
    }
};

export const MobileContext = createContext();
