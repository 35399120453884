import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { usePaymentPopUpStyles } from '../css/usePaymentPopUpStyles';
import MasterCardLogo from '../images/master-card.png';
import VisaCardLogo from '../images/visa-card.png';
import DirectDebitLogo from '../images/Vector.png';
import InvalidIcon from '../images/invalid.png';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import MyEirChangeSuccessModal from '../my-account/MyEirChangeSuccessModal';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// import {KeyboardDatePicker} from "@material-ui/pickers";
import { DatePicker } from '@material-ui/pickers';
import { InputAdornment, useTheme } from '@material-ui/core';
import Calendar from '../images/Calendar.png';
import EirButton from '../../theme/EirButton';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const EditExpiryPaymentCard = props => {
    const classes = usePaymentPopUpStyles();
    const [error, setError] = useState(false);
    const [isPopUp1, setIsPopUp1] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const graphql = useApolloClient();
    const theme = useTheme();

    const [dateExpiryDefault, setDateExpiryDefault] = useState(new Date());

    const today = new Date();
    const toadyMonth = today.getMonth() + 1;
    const todayYear = today.getFullYear();

    const cardYear =
        props.defaultCard.expirationDate == null
            ? null
            : props.defaultCard.expirationDate.substr(0, 4);
    const cardMonth =
        props.defaultCard.expirationDate == null
            ? null
            : props.defaultCard.expirationDate.substr(5, 2);

    const exp =
        props.defaultCard.expirationDate == null
            ? null
            : todayYear > cardYear || (todayYear == cardYear && toadyMonth >= cardMonth)
            ? 'invalid'
            : 'valid';

    const invalidDateInput = val => {
        const cardYear = val == null ? null : val.substr(0, 4);
        const cardMonth = val == null ? null : val.substr(5, 2);

        const expBoolean =
            val == null
                ? true
                : todayYear > cardYear || (todayYear == cardYear && toadyMonth >= cardMonth)
                ? true
                : false;

        return expBoolean;
    };

    const handleExpiryDate = date => {
        console.log('handle date', date._d.toISOString().split('T')[0]);
        console.log('props ex date', props.defaultCard.expirationDate);
        setDateExpiryDefault(date);
        setError(invalidDateInput(date._d.toISOString().split('T')[0]));
    };

    useEffect(() => {
        setDateExpiryDefault(`${props?.defaultCard?.expirationDate}`);
        setError(invalidDateInput(props.defaultCard.expirationDate));
    }, [props.defaultCard]);

    const handleErrorEdit = () => {
        console.log('Invalid date input!!');
    };

    async function handleEditExpiry() {
        try {
            const response = await graphql.mutate({
                mutation: ENDPOINT.UPDATE_EXPIRY_DATE,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    card_id: props.defaultCard.paymentMethodId.toString(),
                    expirationDate:
                        '{ "expirationDate": "' +
                        dateExpiryDefault._d.toISOString().split('T')[0] +
                        '"}',
                    account_id: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });
            const data = response.data;
            if (data.updateExpiryDate == 204) {
                setIsSuccess(true);
                togglePopUp1();
                props.toggle();
            } else {
                setIsSuccess(false);
                togglePopUp1();
                props.toggle();
            }
        } catch (error) {
            setIsSuccess(false);
            togglePopUp1();
        }
    }

    const handleArrow = () => {
        props.toggle();
    };

    const togglePopUp1 = () => {
        setIsPopUp1(!isPopUp1);
    };

    return (
        <>
            <React.Fragment>
                <Modal
                    modalClassName={classes.modalClassName}
                    wrapClassName={classes.modalWrapper}
                    isOpen={props.isOpen}
                    toggle={props.toggle}
                    // onClosed={resetStep}
                    modalTransition={{ timeout: 300 }}
                    centered
                >
                    <div className={classes.headerPaymentContainer}>
                        {!props.center && (
                            <ArrowBackIcon
                                className={'payment-header-back-icon'}
                                onClick={handleArrow}
                            />
                        )}
                        <div className='payment-header-text'>
                            <p style={props.center ? { textAlign: 'center', width: '100%' } : {}}>
                                {props.headertext}
                            </p>
                            <div className='payment-header-line'></div>
                        </div>
                    </div>

                    <ModalBody className={classes.paymentMethod}>
                        <div className={`payment-pop-up-edit`}>
                            <div
                                className={`payment-method-cards-container`}
                                style={{ margin: '0 auto' }}
                            >
                                <div className={`payment-method-cards-list`}>
                                    <div className={'radio-payment-card-block '}>
                                        {/* icon */}
                                        <div className='card-icon'>
                                            <img
                                                src={
                                                    props.defaultCard.type == 'RECURRENT'
                                                        ? DirectDebitLogo
                                                        : props.defaultCard.type == 'VISA'
                                                        ? VisaCardLogo
                                                        : MasterCardLogo
                                                }
                                            />
                                        </div>
                                        {/* info */}
                                        <div className='card-info'>
                                            {/* card number */}
                                            {props.defaultCard.partialDigits == null ? (
                                                <h4 className='card-number'>
                                                    <span>
                                                        &#8226;&#8226;&#8226;&#8226;
                                                        &#8226;&#8226;&#8226;&#8226;
                                                        &#8226;&#8226;&#8226;&#8226;
                                                        &#8226;&#8226;&#8226;&#8226;
                                                    </span>{' '}
                                                </h4>
                                            ) : (
                                                <h4 className='card-number'>
                                                    {props.defaultCard.partialDigits.replace(
                                                        /x/g,
                                                        '*'
                                                    )}
                                                </h4>
                                            )}
                                            {props.defaultCard.expirationDate == null ||
                                            exp == null ? (
                                                <p className='card-expired-message'>Expired</p>
                                            ) : exp == 'valid' ? (
                                                <p className='message' style={{ marginTop: '5px' }}>
                                                    Expiration date{' '}
                                                    {`${props.defaultCard.expirationDate.substr(
                                                        5,
                                                        2
                                                    )}/${props.defaultCard.expirationDate.substr(
                                                        2,
                                                        2
                                                    )}`}
                                                </p>
                                            ) : (
                                                <p className='card-expired-message'>
                                                    Expired{' '}
                                                    {`${props.defaultCard.expirationDate.substr(
                                                        5,
                                                        2
                                                    )}/${props.defaultCard.expirationDate.substr(
                                                        2,
                                                        2
                                                    )}`}
                                                </p>
                                            )}
                                        </div>
                                        {exp == 'valid' ? (
                                            ' '
                                        ) : (
                                            <div className='card-invalid-icon'>
                                                <img
                                                    className='card-invalid-icon-img'
                                                    src={InvalidIcon}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={classes.expiry}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        //  clearable
                                        autoOk
                                        variant='inline'
                                        inputVariant='outlined'
                                        label='Expiry date MM/YY'
                                        format='MM/YY'
                                        views={['year', 'month']}
                                        disablePast='true'
                                        // defaultValue={dateExpiryDefault}
                                        value={dateExpiryDefault}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <img
                                                        src={Calendar}
                                                        alt='green-tick'
                                                        style={{
                                                            marginRight: '10px',
                                                            width: '22px',
                                                            height: '22px',
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={handleExpiryDate}
                                        helperText={
                                            error ? (
                                                <p style={{ color: theme.palette.primary.error }}>
                                                    Please provide valid expiry date
                                                </p>
                                            ) : (
                                                ''
                                            )
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className={classes.switchedPaymentFooter}>
                            {/* <form> */}
                            <EirButton
                                // className={
                                //     `primary-cta footer-cta ` + (error ? 'primary-disabled' : '')
                                // }
                                style={{ width: '11.4rem' }}
                                variants='primaryButtonMedium'
                                disabled={error}
                                onClick={error ? handleErrorEdit : handleEditExpiry}
                            >
                                Update card
                            </EirButton>
                            {/* </form> */}

                            <div className='secondary-cta footer-cta'>
                                <span style={{ cursor: 'pointer' }} onClick={handleArrow}>
                                    Cancel
                                </span>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <MyEirChangeSuccessModal
                    isOpen={isPopUp1}
                    //toggle={togglePopUp}
                    toggle1={togglePopUp1}
                    resetStep={() => props.resetStep()}
                    unique='Edit'
                    cardUpdate={props.updateCard}
                    isUpdated={props.isUpdated}
                    successText={
                        isSuccess
                            ? `Your card ending with ${props.defaultCard.partialDigits.replace(
                                  /x/g,
                                  '*'
                              )} has been successfully updated`
                            : 'Something went wrong. Please try again later'
                    }
                    codeStatus={isSuccess}
                    buttonText='Close'
                />
            </React.Fragment>
        </>
    );
};

export default EditExpiryPaymentCard;
