import { makeStyles } from '@material-ui/core/styles';
export const useChangeSuccessModal = makeStyles(theme => ({
    changeSuccessMain: {
        marginLeft: 'auto',
        marginRight: 'auto',
        //padding: '0 30px',
        maxWidth: '570px',

        '@media(max-width: 599px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
            height: '100%',
        },
    },

    changeSuccessMainWrapper: {
        background: 'white',
        borderRadius: '10px',
    },

    changeSuccessMainModal: {
        background: theme.palette.primary.gray550,
        backdropFilter: 'blur(30px)',
        overflowY: 'hidden !important',
        // top: '53px',
    },

    changeSuccessMainBackDrop: {},

    changeSuccessMainContent: {
        border: 'none',
        borderRadius: '10px',
        width: '570px',

        '@media(max-width: 599px)': {
            // height: '100%',
        },
    },

    changeSuccessBody: {
        // width: '60%',
        margin: '0 auto',
        paddingTop: '35px',
        textAlign: '-webkit-center',
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',

        '@media (max-width: 599px)': {
            width: '100%',
            paddingTop: '27px',
            justifyContent: 'center',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            width: '58%',
            paddingTop: '35px',
            justifyContent: 'center',
        },
    },

    tickWrapper: {
        maxWidth: '100%',
    },

    tickCircle: {},

    tick: {
        width: '64px',
    },

    changeSuccessTextWrapper: {
        paddingTop: '43px',
        // paddingBottom:'58px',

        '@media (max-width: 599px)': {
            paddingTop: '0px',
            marginTop: '19px',
            marginBottom: '19px',
            paddingBottom: '0px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            paddingTop: '38px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            paddingTop: '38px',
        },
    },

    changeSuccessText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 500,
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        lineHeight: '157%',
        minHeight: '70px',
        padding: '0 98px',
        paddingBottom: '15px',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: '500',
            minHeight: '56px',
            padding: '0 28px',
            paddingBottom: '10px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: '500',
            padding: '0 28px',
            paddingBottom: '10px',
        },
    },

    changeSuccessButton: {
        // minHeight: '80px',
        marginTop: '1rem',
        marginBottom: '2rem',

        '@media (max-width: 599px)': {
            // position: 'absolute',
            bottom: '0',
            width: '100%',
            // marginTop: '144px',
            // paddingBottom: '33px',
            marginBottom: '0.5rem',
            marginTop: '0.5rem',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            // marginTop: '3.5rem',
            // paddingBottom: '3.6rem',
        },

        '& .footer-cta': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '184px',
            borderRadius: '4100px',
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontWeight: theme.typography.subtitle1.fontWeight,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight,
            textAlign: 'center',

            '@media (min-width:0px) and (max-width: 1019px)': {
                width: '264px',
            },
        },

        '& .primary-cta': {
            minHeight: '42px',
            borderColor: 'transparent',
            color: theme.palette.primary.white,
            background: theme.palette.primary.sunset,

            '&:hover': {
                // background: '#B10071',
            },
        },

        '& .secondary-cta': {
            // minHeight: '41px',
            marginTop: '12px',
            color: theme.palette.primary.sunset,
        },
    },

    primaryCta: {
        minHeight: '42px',
        marginBottom: '3px',
        borderColor: 'transparent',
    },

    secondaryCta: {
        minHeight: '41px',
        color: theme.palette.primary.sunset,
    },
}));
