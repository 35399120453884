import React, { useState, useEffect } from 'react';
import footerData from '../JSON/footer.json';
import FooterMenu from './FooterMenu';
import MyEirFooterMenu from './MyEirFooterMenu';

function FooterContainer(props) {
    const [footerMenuData, setfooterMenuData] = useState({});
    const [path, setPath] = useState(window.location.pathname);
    const appname = props.appname != undefined ? props.appname : 'prepay'
    useEffect(() => {
        setfooterMenuData(footerData);
        setPath(window.location.pathname);
    }, [window.location.pathname]);

    return (
        <footer>
            {{
                mobile: <MyEirFooterMenu footerMenuData={footerMenuData} />,
                prepay: <FooterMenu footerMenuData={footerMenuData} />,
            }[appname]}
        </footer>
    );
}

export default FooterContainer;
