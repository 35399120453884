import React, { useEffect } from 'react';

const ImageContainer = (props) => {
    return(
    <div>
        <img
                                
         src={props.img}
         alt='KeepOldNum'
         />

    </div>
    );
    

   
};

export default ImageContainer;
