import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import { createBreakpoints } from '@material-ui/core/styles/createBreakpoints';
import { useMyAccountStyle } from '../css/useMyAccountStyle';
import Loading from '../../components/loading/Loading';
import { getContractsDetailsValues, getUANDetals } from './getContractsDetailsValues';
import MyEirDisplayUAN from './MyEirDisplayUAN';
import { useApolloClient } from '@apollo/client';
import { getAccountTypes } from '../eir-porting/porting.util';
import { DOMAINS } from '../../components/myeirconstant/myEirConstant';

function MyEirAccountContracts({ contracts }) {
    const classes = useMyAccountStyle();
    const graphql = useApolloClient();

    const [detailsData, setDetailsData] = useState([]);
    const [uanDetails, setUANDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isContractsError, setIsContractsError] = useState(false);
    const [contractInfo, setContractInfo] = useState([]);

    const { VOIP, MOBILE, FIBER, IPTV } = DOMAINS;

    async function getDetailsData() {
        const objectValues = await getContractsDetailsValues(graphql);
        const uanData = await getUANDetals(graphql);
        setUANDetails(uanData);
        setDetailsData(objectValues);

        if (objectValues.isError == false) {
            setIsLoading(false);
            // setIsContractsError(false);
        } else {
            setIsContractsError(true);
        }
    }

    useEffect(() => {
        getDetailsData();
    }, []);

    const createContractList = () => {
        setContractInfo([]); // start with empty list
        // find all VOIP and Broadband services
        try {
            const temp_VOBB_Broadband = detailsData.msisdn.filter(ele => {
                if (ele.domain === VOIP && ele.sipNumber !== null && ele.status === 'ACTIVE') {
                    const descr = detailsData.offerVal.find(
                        offerEle => offerEle.subscriptionId === ele.subscriptionId
                    );
                    const cont = contracts.find(
                        contractEle => contractEle.subscriptionId === ele.subscriptionId
                    );
                    const uan = uanDetails.find(
                        uanEle => uanEle.subscriptionId === ele.subscriptionId
                    );
                    const broad = detailsData.msisdn.find(
                        broadEle =>
                            broadEle.domain === FIBER &&
                            broadEle.subscriptionId === ele.subscriptionId &&
                            broadEle.dsl !== null
                    );
                    const newContracts = {
                        name: ele.sipNumber + (broad?.dsl !== null ? ` | ${broad.dsl}` : ''),
                        description: descr?.description,
                        startAt: cont?.startAt,
                        endedAt: cont?.endedAt,
                        subscriptionId: ele.subscriptionId,
                        status: ele.status,
                        domain: 'Broadband & Landline',
                        fiberUan: uan?.fiberUan,
                        voipUan: uan?.voipUan,
                    };
                    setContractInfo(prev => [...prev, newContracts]);
                }
            });
        } catch (err) {
            console.error('error while filtering VOIP & Broadband contracts');
        }
        // find all TV services
        try {
            const temp_TV = detailsData.msisdn.filter(ele => {
                if (ele.domain === IPTV && ele.status === 'ACTIVE') {
                    const descr = detailsData.offerVal.find(
                        offerEle => offerEle.subscriptionId === ele.subscriptionId
                    );
                    const cont = contracts.find(
                        contractEle => contractEle.subscriptionId === ele.subscriptionId
                    );
                    const newContracts = {
                        name: ele.bbdsl
                            ? `${ele.bbdsl.substring(0, 3)} ${ele.bbdsl.substring(
                                  3,
                                  6
                              )} ${ele.bbdsl.substring(6)}`
                            : '',
                        description: descr?.description,
                        startAt: cont?.startAt,
                        endedAt: cont?.endedAt,
                        subscriptionId: ele.subscriptionId,
                        status: ele.status,
                        domain: ele.domain,
                    };
                    setContractInfo(prev => [...prev, newContracts]);
                }
            });
        } catch (err) {
            console.error('error while filtering TV contracts');
        }
        // find all MOBILE services
        try {
            const temp_MOBILE = detailsData.msisdn.filter(ele => {
                if (ele.domain === MOBILE && ele.status === 'ACTIVE' && ele.msisdn !== null) {
                    const descr = detailsData.offerVal.find(
                        offerEle => offerEle.subscriptionId === ele.subscriptionId
                    );
                    const cont = contracts.find(
                        contractEle => contractEle.subscriptionId === ele.subscriptionId
                    );
                    const newContracts = {
                        name: ele.msisdn
                            ? `${ele.msisdn.substring(0, 3)} ${ele.msisdn.substring(
                                  3,
                                  6
                              )} ${ele.msisdn.substring(6)}`
                            : null,
                        description: descr?.description,
                        startAt: cont?.startAt,
                        endedAt: cont?.endedAt,
                        subscriptionId: ele.subscriptionId,
                        status: ele.status,
                        domain: ele.domain,
                    };
                    setContractInfo(prev => [...prev, newContracts]);
                }
            });
        } catch (err) {
            console.error('error while filtering Mobile contracts');
        }
        // find all FIXED services
        try {
            const temp_FIXED = detailsData.msisdn.filter(ele => {
                if (ele.domain === DSL && ele.status === 'ACTIVE' && ele.msisdn !== null) {
                    const descr = detailsData.offerVal.find(
                        offerEle => offerEle.subscriptionId === ele.subscriptionId
                    );
                    const cont = contracts.find(
                        contractEle => contractEle.subscriptionId === ele.subscriptionId
                    );
                    const newContracts = {
                        name: ele.msisdn
                            ? `${ele.msisdn.substring(0, 3)} ${ele.msisdn.substring(
                                  3,
                                  6
                              )} ${ele.msisdn.substring(6)}`
                            : null,
                        description: descr?.description,
                        startAt: cont?.startAt,
                        endedAt: cont?.endedAt,
                        subscriptionId: ele.subscriptionId,
                        status: ele.status,
                        domain: ele.domain,
                    };
                    setContractInfo(prev => [...prev, newContracts]);
                }
            });
        } catch (err) {
            console.error('error while filtering Fixed contracts');
        }
    };

    useEffect(() => {
        if (contracts.length > 0 && detailsData?.msisdn?.length > 0) {
            createContractList();
        }
    }, [detailsData, contracts]);

    function convertDateFormat(str) {
        const strArr = str.substring(0, 10);
        const dateArr = strArr.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    }

    return (
        <Grid container className={classes.ContractsWrapper}>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                // lg={width >= 1020 ? 6 : 12}
                className='contracts-grid-column'
            >
                {isLoading ? (
                    <div className='contracts-loader' style={{ justifyContent: 'center' }}>
                        {isContractsError ? (
                            <>
                                <div className='contracts-content-loading'>
                                    Something went wrong, please try again later.
                                    <span
                                        className='pinPuk-retry-loading'
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => {
                                            setIsContractsError(false);
                                            getDetailsData();
                                        }}
                                    >
                                        Retry?
                                    </span>
                                </div>
                            </>
                        ) : (
                            <Loading className='contracts-loading-animation' />
                        )}
                    </div>
                ) : (
                    contractInfo.map((item, index) => {
                        if (item.name !== null) {
                            return (
                                <div
                                    className={`contracts-topic ${
                                        contractInfo.length - 1 !== index
                                            ? 'contacts-not-Last-child'
                                            : ''
                                    }`}
                                >
                                    {' '}
                                    <div className='contracts-topic-title-change'>
                                        <div className='contracts-topic-title'>
                                            {/* {item.customerOfferDTO.msisdn.substring(0, 3)} {item.customerOfferDTO.msisdn.substring(3, 6)} {item.customerOfferDTO.msisdn.substring(6)} */}
                                            {item.description}
                                        </div>
                                        <div className='contracts-topic-content'>{item.name}</div>
                                    </div>
                                    <div className='contracts-topic-change-content'>
                                        <div className='contracts-topic-change'>
                                            <Typography component='span' className='contracts-date'>
                                                {convertDateFormat(item.startAt)} -{' '}
                                                {convertDateFormat(item.endedAt)}
                                            </Typography>
                                        </div>
                                        {(item.domain === 'Broadband & Landline' ||
                                            item.domain === 'SA_PSTN' ||
                                            item.domain === 'DUALPLAY_PSTN') &&
                                        (item.fiberUan !== null || item.voipUan !== null) ? (
                                            <MyEirDisplayUAN
                                                fiberUan={item.fiberUan}
                                                voipUan={item.voipUan}
                                                serviceGroup={item.domain}
                                            />
                                        ) : (
                                            <div className='contracts-topic-change'></div>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                    })
                )}
            </Grid>
        </Grid>
    );
}

export default MyEirAccountContracts;
