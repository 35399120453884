import ENDPOINT from '../graphql/GraphQLConstants';

export const getAmazonContents = async ({ graphql, customerType }) => {

    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_AMAZON_PRIME_CONTENTS,
            variables: {
                customerType: customerType,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    } 
};