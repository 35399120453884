import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { usePaymentPopUpStyles } from '../css/usePaymentPopUpStyles';
import PaymentCardMethodHeader from './PaymentCardMethodHeader';
import MasterCardLogo from '../images/master-card.png';
import VisaCardLogo from '../images/visa-card.png';
import DirectDebitLogo from '../images/Vector.png';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import MyEirChangeSuccessModal from '../my-account/MyEirChangeSuccessModal';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const SwitchDefaultCardPopUp = props => {
    const classes = usePaymentPopUpStyles();
    const graphql = useApolloClient();
    const [isPopUp, setIsPopUp] = useState(false);
    const [isPopUp1, setIsPopUp1] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const togglePopUp = () => {
        setIsPopUp(!isPopUp);
    };
    const togglePopUp1 = () => {
        setIsPopUp1(!isPopUp1);
    };

    async function handleSwitchDefaultPaymentMethod() {
        try {
            const response = await graphql.mutate({
                mutation: ENDPOINT.UPDATE_DEFAULT_PAYMENT_METHOD,
                variables: {
                    token: sessionStorage.getItem('access_token'),
                    paymentMethodId: props.defaultCard.paymentMethodId,
                    account_id: sessionStorage.getItem('LoggedinUserAccountID'),
                },
            });
            const data = response.data;
            if (data.updateDefaultPaymentMethod == 204) {
                console.log('card is succesfully updated');
                setIsSuccess(true);
                togglePopUp1();
                props.toggle();

                // props.resetStep();
                // props.toggle();
            } else {
                console.log('card is not updated');
                setIsSuccess(false);
                togglePopUp1();
                props.toggle();
            }
        } catch (error) {
            console.log('switch error', error);
            setIsSuccess(false);
            togglePopUp1();
        }
    }

    const handleArrow = () => {
        console.log('handle back');
        props.toggle();
    };
    useEffect(() => {}, []);
    return (
        <>
            <React.Fragment>
                <Modal
                    modalClassName={classes.modalClassName}
                    wrapClassName={classes.modalWrapper}
                    isOpen={props.isOpen}
                    toggle={props.toggle}
                    // onClosed={resetStep}
                    modalTransition={{ timeout: 300 }}
                    centered
                >
                    <div className={classes.headerPaymentContainer}>
                        {!props.center && (
                            <ArrowBackIcon
                                className={'payment-header-back-icon'}
                                onClick={handleArrow}
                            />
                        )}
                        <div className='payment-header-text'>
                            <p style={props.center ? { textAlign: 'center', width: '100%' } : {}}>
                                {props.headertext}
                            </p>
                            <div className='payment-header-line'></div>
                        </div>
                    </div>

                    <ModalBody className={classes.paymentMethod}>
                        <div className={`payment-pop-up`}>
                            <div
                                className={`payment-method-cards-container`}
                                style={{ margin: '0 auto' }}
                            >
                                <div className={`payment-method-cards-list`}>
                                    <div className={'radio-payment-card-block '}>
                                        {/* icon */}
                                        <div className='card-icon'>
                                            <img
                                                src={
                                                    props.defaultCard.type == 'RECURRENT'
                                                        ? DirectDebitLogo
                                                        : props.defaultCard.type == 'VISA'
                                                        ? VisaCardLogo
                                                        : MasterCardLogo
                                                }
                                            />
                                        </div>
                                        {/* info */}
                                        <div className='card-info'>
                                            {/* card number */}
                                            {props.defaultCard.paymentMethodType ===
                                            'DIRECT_DEBIT' ? (
                                                <h4 className='card-number'>
                                                    {props.defaultCard.iban.replace(/X/g, '*')}
                                                </h4>
                                            ) : props.defaultCard.partialDigits == null ? (
                                                <h4 className='card-number'>
                                                    <span>
                                                        &#8226;&#8226;&#8226;&#8226;
                                                        &#8226;&#8226;&#8226;&#8226;
                                                        &#8226;&#8226;&#8226;&#8226;
                                                        &#8226;&#8226;&#8226;&#8226;
                                                    </span>{' '}
                                                </h4>
                                            ) : (
                                                <h4 className='card-number'>
                                                    {props.defaultCard.partialDigits.replace(
                                                        /x/g,
                                                        '*'
                                                    )}
                                                </h4>
                                            )}
                                            <p className={'message '}>
                                                {/* {card.default == true ? `${formattedCardName}` : selected ? 'Previously saved IBAN' : 'My prefered method'} */}
                                                {props.defaultCard.type == 'RECURRENT'
                                                    ? 'Previously saved IBAN'
                                                    : 'Previously saved card'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`payment-switched-card-text`}>
                                <p>{props.contentText}</p>
                            </div>
                        </div>

                        <div className={classes.switchedPaymentFooter}>
                            {/* <form> */}
                            <button
                                className={'primary-cta footer-cta'}
                                onClick={handleSwitchDefaultPaymentMethod}
                            >
                                Confirm switch
                            </button>
                            {/* </form> */}

                            <div className='secondary-cta footer-cta'>
                                <span style={{ cursor: 'pointer' }} onClick={handleArrow}>
                                    Cancel
                                </span>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <MyEirChangeSuccessModal
                    isOpen={isPopUp1}
                    toggle={togglePopUp}
                    toggle1={togglePopUp1}
                    resetStep={() => props.resetStep()}
                    cardUpdate={props.updateCard}
                    isUpdated={props.isUpdated}
                    unique='Switch'
                    successText={
                        isSuccess
                            ? 'The default payment method has been changed successfully'
                            : "Something went wrong and the default payment method hasn't been changed. Please try again later"
                    }
                    codeStatus={isSuccess}
                    buttonText='Close'
                />
            </React.Fragment>
        </>
    );
};

export default SwitchDefaultCardPopUp;
