export default {
    validation: {
        firstnameRequired: 'Please enter your first name.',
        firstNamePattern: 'Please enter a valid first name.',
        nameMaxLength: 'This value is too long. It should have 100 characters or fewer.',
        nameMinLength: 'Please enter at least 2 characters.',
        lastNameRequired: 'Please enter your last name.',
        lastNamePattern: 'Please enter a valid last name.',
        emailAddressError: 'Please enter a valid email address in format: yourname@example.com.',
        emailAddressAlreadyExists: 'Email address is already registered',
        mobileNumberPattern: 'Number should start with 08 followed by 8 digits.',
        mobileNumberRequired: 'Please enter a valid mobile number.',
        mobilelandlineNumberRequired: 'Please enter a valid mobile/landline/broadband number.',
        mobilelandlineNumberPattern:
            'Number should start with valid prefix and should have minmum of 7 digits and max of 11 digits.',
        varificatinCodePattern: ' Must be 6 characters long.',
        varificatinCodeRequired: 'Please enter a valid varification code.',
        birthDateError: 'Please enter a valid date of birth.',
        birthDateAgeError: 'You have to be over 18 years old to register on my eir.',
        eircodeRequired: 'Please enter your eircode.',
        addressLineRequired: 'Please enter your address.',

        securityQuestionRequired: 'Please enter a security question.',
        securityAnswerRequired: 'Please enter a security answer.',
        termsNconditionsRequired:
            'Please check the box to confirm you have read and accept the Terms and Conditions.',
        addressSelectRequired: 'Please select your address.',
        serviceActiveChecked:
            'Please select at least one option for "As an existing eir customer".',
        serviceInactiveChecked:
            'Please select at least one option for "No longer an eir customer".',

        serviceActiveCheckedMarketing: 'Please select at least one option.',
        serviceInactiveCheckedMarketing: 'Please select at least one option.',
    },
    errormessage: {
        noAddressFoundforEirCode: 'No address found for this Eircode.',
        addressFinderNetworkError: 'Network error. Please try later.',
        eircodeNotConfirmed: 'Please confirm eircode.',
    },
};
