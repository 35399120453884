import { makeStyles } from '@material-ui/core/styles';
export const useChangeBillingAddress = makeStyles(theme => ({
    changeAddressWrapper: {
        background: 'white',

        '& .changeAddress-modal': {
            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(30px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(30px)',
            },

            '@media(max-width: 599px)': {
                maxWidth: '100vw',
            },
        },
        billingFormAddress: {
            maxWidth: '439px',
            margin: '0 auto',
            paddingTop: '44px',
        },
        '& .changeAddress-main': {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '690px',

            '@media(max-width: 1019px)': {
                padding: '0 99px',
                maxWidth: '858px',
            },
            '@media(max-width: 599px)': {
                marginBottom: '0px',
                padding: '0 8px',
                height: '100%',
            },
        },

        '& .changeAddress-content': {
            border: 'none',
            borderRadius: '10px',
            // height: '100%',

            '@media(max-width: 599px)': {
                height: '100%',
                borderRadius: '10px 10px 0px 0px',
                wordWrap: 'break-word',
            },
        },
        '& .changeAddress-body': {
            height: '100%',
            display: 'flex',
        },
        '& .changeAddress-body-content': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
        },
    },
    formAddressFinderContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
    },
    formBillingAddressContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
    },
    addressUniqueEircodeManual: {
        paddingTop: '32px',
    },
    townCounty: {
        display: 'flex',
        flexDirection: 'row',
    },
    paddingLeftTown: {
        paddingRight: '8px',
    },
    paddingRightCounty: {
        paddingLeft: '8px',
    },
    billingAddress: {
        maxWidth: '439px',
        margin: '0 auto',
        paddingTop: '44px',
    },
    addressTextField: {
        paddingBottom: '32px',
    },
    hideTextField: {
        opacity: '0',
        height: '0',
        zIndex: '-1',
        paddingTop: '0 !important',
    },

    billingAddressContainer: {
        paddingBottom: '33px',
    },
    textField: {
        '& label.MuiFormLabel-root': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather80,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.heather80} !important`,
        },
        '& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.heather80} !important`,
        },

        '& label.Mui-focused': {
            color: `${theme.palette.primary.heather80} !important`,
        },
        '& label.Mui-error': {
            color: theme.palette.primary.error,
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: `${theme.palette.primary.heather80} !important`,
        },
        '& .MuiOutlinedInput-root': {
            padding: '0',
            '& input:-webkit-autofill': {
                backgroundColor: `${theme.palette.primary.white} !important`,
            },

            '& .MuiInputBase-input': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                height: '20px',
                padding: '16px 12px',
                fontFamily: theme.typography.body2.fontFamily,
            },
            '& fieldset': {
                borderColor: theme.palette.primary.gray400,
            },
            // '&:hover fieldset': {
            //     borderColor: '#d011c9',

            // },
            // '&.Mui-focused fieldset': {
            //     borderColor: '#d011c9',

            // },
        },

        '& .MuiToolbar-root': {
            backgroundColor: `${theme.palette.primary.error} !important`,
        },
    },

    selectLabel: {
        fontFamily: `${theme.typography.body2.fontFamily} !important`,
        whiteSpace: 'normal',
    },

    footerContainerAddress: {
        paddingTop: '39px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        justifyContent: 'flex-end',
    },

    '& .MuiToolbar-root': {
        backgroundColor: 'red !important',
    },

    '& .MuiPickersToolbar-toolbar': {
        backgroundColor: 'red !important',
    },
    billingFormAddress: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px ',
            paddingRight: '0px',
        },
    },
    addressContainer: {
        maxWidth: '440px',
        margin: '0 auto',
        paddingBottom: '10px',
        // height: '100%',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8px ',
            paddingRight: '8px',
            maxWidth: '320px',
            // paddingBottom: '338px',
        },
    },
    addressFinderContainer: {
        maxWidth: '440px',
        margin: '0 auto',
        paddingBottom: '33px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px ',
            paddingRight: '0px',
            paddingBottom: '305px',
        },
    },
    billingAddressFinderContainer: {
        maxWidth: '440px',
        margin: '0 auto',
        paddingBottom: '33px',
        paddingTop: '44px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px ',
            paddingRight: '0px',
            paddingBottom: '305px',
        },
    },
    addressUniqueEircode: {
        paddingTop: '17px',
    },
    addressEircode: {
        marginTop: '43px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '32px',
        },
    },
    errorMessage: {
        width: '100%',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        letterSpacing: '0.4px',
        color: theme.palette.primary.error,
        marginTop: '3px',
    },
}));
