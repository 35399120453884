import { gql } from '@apollo/client';
const ENDPOINT = {
    GET_ADDRESSLIST: gql`
        query getAddress($eirCode: String!) {
            addressList(eirCode: $eirCode) {
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                    code
                }
            }
        }
    `,
    GET_OTP: gql`
        query getOTP($msisdn: String!) {
            OTP(msisdn: $msisdn) {
                otpUuid
            }
        }
    `,
    REQUEST_EMAIL_RECOVERY: gql`
        query requestEmailRecovery($msisdn: String!) {
            requestEmailRecovery(msisdn: $msisdn) {
                otpUuid
            }
        }
    `,
    VALIDATE_OTP_EMAIL_RECOVERY: gql`
        mutation validateOTPForEmailRecovery($otpUuid: String!, $otpCode: String!) {
            validateOTPForEmailRecovery(otpUuid: $otpUuid, otpCode: $otpCode) {
                email
                verified
            }
        }
    `,
    VALIDATE_OTP: gql`
        mutation validateOTP($otpUuid: String!, $otp: String!) {
            validateOTP(otpUuid: $otpUuid, otp: $otp)
        }
    `,
    VALIDATE_LOGIN: gql`
        query validateLogin($username: String!, $password: String!) {
            login(username: $username, password: $password) {
                access_token
                expires_in
                refresh_expires_in
                refresh_token
            }
        }
    `,
    EXTEND_SESSION: gql`
        query refreshToken($refresh_token: String!) {
            refreshToken(refresh_token: $refresh_token) {
                access_token
                expires_in
                refresh_expires_in
                refresh_token
            }
        }
    `,
    SERVICE_ID: gql`
        query getMyServices($token: String!, $subscriptionId: Int!) {
            getMyServices(token: $token, subscriptionId: $subscriptionId) {
                name
                id
                status
                domain
                subscriptionId
                msisdn
                phoneNumber
                pstnUan
            }
        }
    `,
    GET_USERINFO: gql`
        query getUserInfo($token: String!) {
            getUserInfo(token: $token) {
                sub
                email_verified
                name
                preferred_username
                given_name
                family_name
                email
            }
        }
    `,
    GET_PREPAY_BALANCE: gql`
        query getMyPrepayBalance($token: String!, $serviceId: String!) {
            getMyPrepayBalance(token: $token, serviceId: $serviceId) {
                balance
            }
        }
    `,
    GET_MY_DETAILS: gql`
        query getMyDetails($token: String!, $serviceId: String!) {
            getMyDetails(token: $token, serviceId: $serviceId) {
                customerOfferDTO {
                    offerName
                    offerStatus
                    activationDate
                    msisdn
                }
                pin
                puk
            }
        }
    `,
    GET_MY_CONTACT_INFO: gql`
        query getMyContactInfo($token: String!) {
            getMyContactDetails(token: $token) {
                firstName
                phoneNumbers {
                    phoneNumber
                }
            }
        }
    `,
    GET_MY_ADDON: gql`
        query getMyAddons($token: String!, $serviceId: String!) {
            getMyAddons(token: $token, serviceId: $serviceId) {
                description
            }
        }
    `,
    CONTACT_DETAILS: gql`
        query getContactDetails($token: String!) {
            getMyContactDetails(token: $token) {
                uuid
                phoneNumbers {
                    phoneNumber
                }
                emails {
                    email
                    type
                    validated
                }
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                    code
                }
                birthDate
                firstName
                lastName
            }
        }
    `,
    GET_PERMISSION: gql`
        query permission($token: String!) {
            getMyPermissionDetails(token: $token) {
                permissionGroupResponse {
                    name
                    permissions {
                        name
                        enabled
                        permission
                    }
                }
                allowThirdParty
            }
        }
    `,
    GET_NDDPREFERENCE: gql`
        query getNDDPreference($token: String!, $serviceId: Int!) {
            getMyNDDPreference(token: $token, serviceId: $serviceId) {
                nddPreference
            }
        }
    `,
    UPDATE_NDDPREFERENCE: gql`
        mutation nddPreference($token: String!, $serviceId: Int!, $nddPreference: String!) {
            updateMyNDD(token: $token, serviceId: $serviceId, nddPreference: $nddPreference)
        }
    `,

    UPDATE_BILLDELIVERYTYPE: gql`
        mutation updateBillDeliveryType(
            $token: String!
            $accountId: String!
            $invoiceDeliveryMethod: String!
        ) {
            updateBillDeliveryType(
                token: $token
                accountId: $accountId
                invoiceDeliveryMethod: $invoiceDeliveryMethod
            )
        }
    `,
    UPDATE_CONTACT_PERMISSION_GROUP: gql`
        mutation updateMyContactPermission(
            $token: String!
            $allowThirdParty: Boolean!
            $permissionGroups: [PermissionGroupsResponseInput!]
        ) {
            updateMyContactPermission(
                token: $token
                allowThirdParty: $allowThirdParty
                permissionGroups: $permissionGroups
            )
        }
    `,
    GET_VESTA_TOKEN: gql`
        query getVestaToken($msisdn: String!) {
            getVestaToken(msisdn: $msisdn) {
                vestaToken
            }
        }
    `,
    GET_SECURITYQUESTIONS: gql`
        query securityQuestions {
            securityQuestions {
                code
                question
            }
        }
    `,
    NUMBER_VALIDATION: gql`
        mutation validateUser(
            $registerWith: String!
            $registerValue: String!
            $recaptcha: String!
        ) {
            validateUser(
                registerWith: $registerWith
                registerValue: $registerValue
                recaptcha: $recaptcha
            ) {
                otpUuid
                statusCode
                mobileNumber
            }
        }
    `,
    RESET_PASSWORD: gql`
        mutation resetPassword($emailAddress: String!, $brand: BillingSystem) {
            resetPassword(emailAddress: $emailAddress, brand: $brand) {
                message
            }
        }
    `,
    SET_PASSWORD: gql`
        mutation setpassword($password: String!, $token: String!) {
            setPassword(password: $password, token: $token) {
                message
            }
        }
    `,
    GET_SUBSCRIPTION_ID_AND_CONTACT: gql`
        query getMySubscriptionsAndContactDetails($token: String!) {
            getMySubscriptions(token: $token) {
                content {
                    id
                    catalogOfferId
                    serviceGroup
                    type
                    dependsOn
                    terminatedAt
                }
            }
            getMyContactDetails(token: $token) {
                firstName
                phoneNumbers {
                    phoneNumber
                }
            }
        }
    `,

    GET_BALANCE_DETAILS_ADDONS: gql`
        query getMyPrepayBalanceMyDetailsMyAddons($token: String!, $serviceId: String!) {
            getMyPrepayBalance(token: $token, serviceId: $serviceId) {
                balance
            }
            getMyDetails(token: $token, serviceId: $serviceId) {
                customerOfferDTO {
                    offerName
                    offerStatus
                    activationDate
                    msisdn
                    name
                    status
                }
                pin
                puk
            }
            getPinPukDetails(token: $token, serviceId: $serviceId) {
                imsiNumber
                pin1Code
                puk1Code
                serviceId
            }
            getMyAddons(token: $token, serviceId: $serviceId) {
                description
                status
            }
        }
    `,
    GET_PROFILE_DETAILS: gql`
        query getProfileDetails($token: String!, $serviceId: Int!) {
            getMyContactDetails(token: $token) {
                uuid
                phoneNumbers {
                    phoneNumber
                }
                emails {
                    email
                    type
                    validated
                }
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                    code
                }
                birthDate
                firstName
                lastName
            }
            getMyNDDPreference(token: $token, serviceId: $serviceId) {
                nddPreference
            }
            getMyPermissionDetails(token: $token) {
                permissionGroupResponse {
                    name
                    permissions {
                        name
                        enabled
                        permission
                    }
                }
                allowThirdParty
            }
        }
    `,
    GET_USAGE_TYPES: gql`
        query getMyUsageTypes {
            getMyUsageTypes {
                ratingSubtotalTypes {
                    ratingSubtotalLevel
                    ratingSubtotalDisplayName
                    key
                }
            }
        }
    `,
    GET_USAGE_SUMMARY: gql`
        query getMyUsageSummary(
            $token: String!
            $billingPeriod: String!
            $serviceType: String!
            $serviceId: Int!
        ) {
            getMyUsageSummary(
                token: $token
                billingPeriod: $billingPeriod
                serviceType: $serviceType
                serviceId: $serviceId
            ) {
                serviceId
                usageSummary {
                    amount
                    duration
                    eventCount
                    measuringUnit
                    quantity
                    ratingSubtotalId
                    ratingSubtotalTypeId
                    ratingSubtotalTypeKey
                    ratingSubtotalTypeLevel
                    ratingSubtotalTypeDisplayName
                    ratingSubtotalTypeId
                }
            }
        }
    `,
    GET_USAGE_DETAILS: gql`
        query getMyUsageDetails($token: String!, $usageDetailsInput: UsageDetailsInput!) {
            getMyUsageDetails(token: $token, usageDetailsInput: $usageDetailsInput) {
                usage {
                    pageable {
                        pageNumber
                        pageSize
                    }
                    first
                    last
                    number
                    numberOfElements
                    size
                    totalPages
                    totalElements
                    sort {
                        sorted
                    }
                    content {
                        chargedUsageId
                        amount
                        chargedNumber
                        chargeStartDateTime
                        destinationCountryCode
                        duration
                        invoiceText
                        quantity
                        destinationNumber
                        measuringUnit
                    }
                }
            }
        }
    `,
    UPDATE_REGISTER_DETAILS: gql`
        mutation registration(
            $msisdn: String!
            $customerDetailForRegistrationInput: CustomerDetailForRegistrationInput!
        ) {
            registration(
                msisdn: $msisdn
                customerDetailForRegistrationInput: $customerDetailForRegistrationInput
            )
        }
    `,
    SEND_VERIFICATION_EMAIL: gql`
        mutation sendVerificationEmail($email: String!) {
            sendVerificationEmail(email: $email)
        }
    `,
    AREA_CODES: gql`
        query areacode {
            areaCodes {
                AREA_CODE
            }
        }
    `,
    GET_OPERATOR: gql`
        query getOperator($msisdn: String!) {
            operator(msisdn: $msisdn) {
                brand
                network {
                    id
                    networkName
                }
            }
        }
    `,
};

export default ENDPOINT;
