import React, { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useWindowDimension } from '../../hooks/windowDimensions';
import CustomModal from '../eir-porting/CustomModal/CustomModal';
import CustomUpdatedPopUpModal from '../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import CustomTextField from '../../sharedcomponents/inputs/text-field/CustomTextField';
import { activateSimAPI } from './simEligibilityCheck.util';
import useStyles from './style';
import success from '../images/Large-icon-Success.png';
import { useApolloClient } from '@apollo/client';
import MyEirErrorHandle from '../eir-porting/MyEirErrorHandle';
import EirButton from '../../theme/EirButton';

const ActivateSimReplacementContainer = props => {
    const classes = useStyles();
    const [open, setOpenModal] = useState(false);
    const [code, setCode] = useState('');
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [validcode, setValidcode] = useState(false);
    const [invalidOtp, setInvalidOtp] = useState('');
    const [openError, setOpenError] = useState(false);
    const [openCustomPopup, setOpenCustomPopup] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const [width, height] = useWindowDimension();
    const graphql = useApolloClient();
    const [simIn, setData] = useState({
        puk: '',
        serviceId: '',
    });

    // API Integration
    const handleActivateSim = async () => {
        try {
            const data = await activateSimAPI({
                graphql,
                activateSim: {
                    serviceId: props.serviceId,
                    puk: code,
                },
                token: sessionStorage.getItem('access_token'),
            });
            if (data === 'success') {
                setData &&
                    setData({
                        serviceId: props.serviceId,
                        puk: code,
                    });
                setOpenCustomPopup(true);
            } else {
                setOpenError(!openError);
            }
        } catch (error) {
            setOpenError(!openError);
            console.log('Sim activate error', error);
        }
    };
    // open modal

    const handleOpen = () => {
        setOpenModal(!open);
    };

    //submit action
    const handleActivateSimCta = () => {
        handleActivateSim();
        setEnableSubmit(true);
        setCode('');
        setValidcode(false);
        setOpenModal(!open);
    };

    const handleValidcode = e => {
        const codeValue = e.target.value;
        setCode(codeValue);
        setValidcode(true);
        // when input is empty
        if (codeValue === '') {
            setInvalidOtp('Please enter puk code');
            setValidcode(false);
        } else if (codeValue.length > 8 || codeValue.length < 8) {
            setInvalidOtp('Must be 8 characters long');
            setValidcode(false);
        } else {
            setInvalidOtp('');
            setCode(codeValue);
        }
    };
    // handle back arrow action
    const backArrowAction = () => {
        setOpenModal(!open);
        setCode('');
        setValidcode(false);
        setInvalidOtp('');
    };

    const handleTogglePopup = () => {
        setOpenCustomPopup(!openCustomPopup);
    };

    const handleAltCta = () => {
        setOpenCustomPopup(false);
    };

    return (
        <Box id='sim-replacement'>
            <Typography onClick={handleOpen} className={classes.activateSim}>
                Activate replacement SIM
            </Typography>
            <CustomModal
                open={open}
                header={{
                    text: 'Activate your Replacement SIM',
                    arrowAction: backArrowAction,
                    center: false,
                }}
                cta1={cta1}
                cta2={cta2}
            >
                <Typography className={classes.textContainer}>
                    Enter PUK code below. This can be found on the replacement SIM pack you
                    received.
                </Typography>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Box className={classes.textFieldotp}>
                        <CustomTextField
                            id='Your PUK code'
                            label='Your PUK code'
                            onChange={handleValidcode}
                            className={`validcode`}
                            value={code}
                            classes={{
                                container: `${classes.textField}`,
                            }}
                            // value={code}
                            inputProps={{
                                maxLength: 8,
                            }}
                            error={Boolean(invalidOtp)}
                            errorMessage={invalidOtp}
                        />
                    </Box>
                    {width < 600 ? (
                        <EirButton
                            onClick={handleActivateSimCta}
                            variants='primaryButtonMedium'
                            style={{ padding: '0.5rem 2.5rem' }}
                            // bgColor={validcode ? 'primary' : 'default'}
                            // className={`${validcode ? classes.cta1 : classes.ctaDisable1}`}
                            disabled={!validcode}
                        >
                            Activate SIM
                        </EirButton>
                    ) : (
                        <EirButton
                            onClick={handleActivateSimCta}
                            variants='primaryButtonMedium'
                            style={{ padding: '0.5rem 2.5rem' }}
                            // bgColor={validcode ? 'primary' : 'default'}
                            // className={`${validcode ? classes.cta1 : classes.ctaDisable1}`}
                            disabled={!validcode}
                        >
                            Activate SIM
                        </EirButton>
                    )}
                </Box>
            </CustomModal>
            <Box id='my-eir-pop-up'>
                <CustomUpdatedPopUpModal
                    open={openCustomPopup}
                    toggle={handleTogglePopup}
                    cta1={{ text: 'Close', action: handleAltCta, disable: false }}
                    cta2={{ text: '', action: () => {} }}
                >
                    <Box className={classes.successPopup}>
                        <Box className={classes.successImg}>
                            <Typography component='img' src={success} alt='success' />
                        </Box>

                        <Typography className={classes.imgTextAdditional}>
                            Your new SIM is activated
                        </Typography>
                    </Box>
                </CustomUpdatedPopUpModal>
            </Box>
            {openError ? <MyEirErrorHandle open={openError} setOpen={setOpenError} /> : ''}
        </Box>
    );
};

export default ActivateSimReplacementContainer;
